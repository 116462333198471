import {  FETCH_CHANGE_SET, UPDATE_CHANGE_SET, REMOVE_CHANGE_SET, PUBLISH_CHANGE_SET, DOWNLOAD_SCRIPT_CHANGE_SET } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  adminChangeSet: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHANGE_SET:
      return { ...state, adminChangeSet: action.payload };
    case UPDATE_CHANGE_SET:
      return { ...state, updateChangeSetResult: action.payload };
    case REMOVE_CHANGE_SET:
      return { ...state, removeChangeSetResult: action.payload };
    case PUBLISH_CHANGE_SET:
      return { ...state, publishChangeSetResult: action.payload };
    case DOWNLOAD_SCRIPT_CHANGE_SET:
      return { ...state, downloadChangeSetResult: action.payload };
    default:
      return state;
  }
};
