import {  FETCH_CHANGE_SETS_LIST } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  adminChangeSetSummaries: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHANGE_SETS_LIST:
      return { ...state, adminChangeSetSummaries: action.payload };
    default:
      return state;
  }
};
