// TASK MASTER ACTIONS
export const FETCH_TASK_MASTERS = 'FETCH_TASK_MASTERS';
export const UPDATE_TASK_MASTER = 'UPDATE_TASK_MASTER';
export const UPDATE_TASK_MASTER_NAME = 'UPDATE_TASK_MASTER_NAME';
export const UPDATE_TASK_MASTER_DESCRIPTION = 'UPDATE_TASK_MASTER_DESCRIPTION';
export const FETCH_TASK_MASTERS_FILTER = 'FETCH_TASK_MASTERS_FILTER';
export const FETCH_DECISION_POINT_MASTERS = 'FETCH_DECISION_POINT_MASTERS';
export const FETCH_TASK_LIBRARY_MASTERS = 'FETCH_TASK_LIBRARY_MASTERS';
export const FETCH_TASK_BUILDER_MASTERS = 'FETCH_TASK_BUILDER_MASTERS';
export const FETCH_TASK_MASTER_DEPENDENCIES = 'FETCH_TASK_MASTER_DEPENDENCIES';
export const FETCH_TASK_MASTERS_BY_SEARCHSTR = 'FETCH_TASK_MASTERS_BY_SEARCHSTR';
export const FETCH_TASK_MASTERS_BY_CODES = 'FETCH_TASK_MASTERS_BY_CODES';
export const FETCH_TASK_MASTERS_BY_CODES_RO = 'FETCH_TASK_MASTERS_BY_CODES_RO';
export const FETCH_TASK_BUILDER_MASTERS_FAILED = 'FETCH_TASK_BUILDER_MASTERS_FAILED';

export const FETCH_TASK_MASTERS_SUMMARY = 'FETCH_TASK_MASTERS_SUMMARY';

// ADMIN CHANGE CONSOLE MASTER ACTIONS
export const FETCH_ADMIN_CHANGE_MASTERS = 'FETCH_ADMIN_CHANGE_MASTERS';
export const FETCH_ADMIN_CHANGE_MASTERS_FILTERS = 'FETCH_ADMIN_CHANGE_MASTERS_FILTERS';
export const FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS = 'FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS';
export const FETCH_ADMIN_CHANGE_MASTERS_SELECTED_FILTERS = 'FETCH_ADMIN_CHANGE_MASTERS_SELECTED_FILTERS';
export const UPDATE_ADMIN_CHANGE_MASTERS = 'UPDATE_ADMIN_CHANGE_MASTERS';
export const FETCH_ADMIN_CHANGE_SETS = 'FETCH_ADMIN_CHANGE_SETS';
export const FETCH_CHANGE_SETS_LIST = "FETCH_CHANGE_SETS_LIST";
export const FETCH_CHANGE_SET = "FETCH_CHANGE_SET";
export const UPDATE_CHANGE_SET = "UPDATE_CHANGE_SET";
export const REMOVE_CHANGE_SET = "REMOVE_CHANGE_SET";
export const PUBLISH_CHANGE_SET = "PUBLISH_CHANGE_SET";
export const DOWNLOAD_SCRIPT_CHANGE_SET = "DOWNLOAD_SCRIPT_CHANGE_SET";

// CHECKPOINT MASTER ACTIONS
export const FETCH_CHECKPOINT_MASTER = 'FETCH_CHECKPOINT_MASTER';
export const FETCH_CHECKPOINT_MASTERS = 'FETCH_CHECKPOINT_MASTERS';
export const UPDATE_CHECKPOINT_MASTER_DESCRIPTION = 'UPDATE_CHECKPOINT_MASTER_DESCRIPTION';

// POLICY LIBRARY MASTER ACTIONS
export const FETCH_POLICYLIBRARY_MASTER = 'FETCH_POLICYLIBRARY_MASTER';
export const FETCH_POLICYLIBRARY_MASTERS = 'FETCH_POLICYLIBRARY_MASTERS';
export const FETCH_POLICYLIBRARY_MASTERS_STARTED = 'FETCH_POLICYLIBRARY_MASTERS_STARTED';
export const FETCH_POLICYLIBRARY_MASTERS_FAILED = 'FETCH_POLICYLIBRARY_MASTERS_FAILED';
export const FETCH_POLICY_BUILDER_MASTERS = 'FETCH_POLICY_BUILDER_MASTERS';
export const FETCH_POLICY_BUILDER_MASTERS_FAILED = 'FETCH_POLICY_BUILDER_MASTERS_FAILED';
export const FETCH_POLICY_SCHEME_CONFIGURATIONS = 'FETCH_POLICY_SCHEME_CONFIGURATIONS';
export const SET_POLICY_SCHEME_CONFIGURATIONS = 'SET_POLICY_SCHEME_CONFIGURATIONS';
export const FETCH_POLICY_SCHEME_CONFIGURATIONS_NAMES = 'FETCH_POLICY_SCHEME_CONFIGURATIONS_NAMES';
export const POLICY_SCHEME_SELECTION_SCOPE_RESOURCE_TYPE_MAPPINGS = 'POLICY_SCHEME_SELECTION_SCOPE_RESOURCE_TYPE_MAPPINGS';
export const KEY_SYSTEM_RESOURCE_POLICY_DEFINITION = 'KEY_SYSTEM_RESOURCE_POLICY_DEFINITION';

// INTELLIGENT AGENT ACTIONS
export const FETCH_INTELLIGENT_AGENT = 'FETCH_INTELLIGENT_AGENT';
export const FETCH_INTELLIGENT_AGENTS = 'FETCH_INTELLIGENT_AGENTS';
export const EDIT_INTELLIGENT_AGENT = 'EDIT_INTELLIGENT_AGENT';
export const DELETE_INTELLIGENT_AGENT = 'DELETE_INTELLIGENT_AGENT';
export const CREATE_INTELLIGENT_AGENT_SUCCESS = 'CREATE_INTELLIGENT_AGENT_SUCCESS';
export const CREATE_INTELLIGENT_AGENT_FAILURE = 'CREATE_INTELLIGENT_AGENT_ERROR';
export const INTELLIGENT_AGENT_API_REQUEST_DISPATCHED = 'INTELLIGENT_AGENT_API_REQUEST_DISPATCHED';
export const INTELLIGENT_AGENT_API_REQUEST_COMPLETED = 'INTELLIGENT_AGENT_API_REQUEST_COMPLETED';

// INTELLIGENT AGENTS EVENTS ACTIONS
export const FETCH_INTELLIGENT_AGENTS_EVENTS = 'FETCH_INTELLIGENT_AGENTS_EVENTS';
export const FETCH_INTELLIGENT_AGENT_EVENTS_BY_IA = 'FETCH_INTELLIGENT_AGENT_EVENTS_BY_IA';

// PEP ACTIONS
export const  FETCH_PEPS = 'FETCH_PEPS';
export const  FETCH_PEP = 'FETCH_PEP';

// PDP ACTIONS
export const  FETCH_PDPS = 'FETCH_PDPS';
export const  FETCH_PDP = 'FETCH_PDP';

// PIP ACTIONS
export const FETCH_ALL_PIPS = 'FETCH_ALL_PIPS';
export const FETCH_PIPS = 'FETCH_PIPS';
export const FETCH_PIP = 'FETCH_PIP';
export const FETCH_PIP_ROW = 'FETCH_PIP_ROW';
export const CANCEL_EDIT_PIP_ROW = 'CANCEL_EDIT_PIP_ROW';
export const UPDATE_PIP_ATTRIBUTES = 'UPDATE_PIP_ATTRIBUTES';
export const UPDATE_PIP_ATTRIBUTES_ERROR = 'UPDATE_PIP_ATTRIBUTES_ERROR';
export const UPDATE_PIP_ATTRIBUTES_SUCCESS = 'UPDATE_PIP_ATTRIBUTES_SUCCESS';
export const UPDATE_PIP_VALUE = 'UPDATE_PIP_VALUE';
export const UPDATE_PIP_VALUE_ERROR = 'UPDATE_PIP_VALUE_ERROR';
export const UPDATE_PIP_VALUE_SUCCESS = 'UPDATE_PIP_VALUE_SUCCESS';
export const ADD_PIP_ATTRIBUTE_VALUES = 'ADD_PIP_ATTRIBUTE_VALUES';
export const ADD_PIP_ATTRIBUTE_VALUES_SUCCESS = 'ADD_PIP_ATTRIBUTE_VALUES_SUCCESS';
export const ADD_PIP_ATTRIBUTE_VALUES_ERROR = 'ADD_PIP_ATTRIBUTE_VALUES_ERROR';
export const DELETE_PIP_ATTRIBUTE_VALUES_SUCCESS = 'DELETE_PIP_ATTRIBUTE_VALUES_SUCCESS';
export const DELETE_PIP_ATTRIBUTE_VALUES_ERROR = 'DELETE_PIP_ATTRIBUTE_VALUES_ERROR';

// Mapping Tables
export const FETCH_ALL_PRR_TAG_VALUES = 'FETCH_ALL_PRR_TAG_VALUES';
export const FETCH_ALL_PRR_CRITERIA_TAGS = 'FETCH_ALL_PRR_CRITERIA_TAGS';
export const FETCH_ALL_PRR_CRITERIA_ENTITIES = 'FETCH_ALL_PRR_CRITERIA_ENTITIES';
export const FETCH_ALL_PRR_CRITERIAS = 'FETCH_ALL_PRR_CRITERIAS';
export const FETCH_ALL_PRR_CRITERIA_CATEGORIES = 'FETCH_ALL_PRR_CRITERIA_CATEGORIES';
export const FETCH_ALL_REQUIRED_FIELDS_CHECKPOINT_TYPE_MAPPINGS = 'FETCH_ALL_REQUIRED_FIELDS_CHECKPOINT_TYPE_MAPPINGS';
export const FETCH_ALL_MANAGED_FIELDS_DECISION_POINT_TYPE_MAPPINGS = 'FETCH_ALL_MANAGED_FIELDS_DECISION_POINT_TYPE_MAPPINGS';
export const FETCH_ALL_MANAGED_FIELDS = 'FETCH_ALL_MANAGED_FIELDS';
export const FETCH_ALL_REQUIRED_FIELDS_DECISION_POINT_TYPE_MAPPINGS = 'FETCH_ALL_REQUIRED_FIELDS_DECISION_POINT_TYPE_MAPPINGS';
export const FETCH_ALL_CHANGE_TYPE_EVENT_TYPE_MAPPINGS = 'FETCH_ALL_CHANGE_TYPE_EVENT_TYPE_MAPPINGS';
export const FETCH_ALL_FUNCTIONS = 'FETCH_ALL_FUNCTIONS';
export const FETCH_ALL_EVENT_TYPES = 'FETCH_ALL_EVENT_TYPES';
export const FETCH_ALL_TABLE_MAPPINGS = 'FETCH_ALL_TABLE_MAPPINGS';
export const FETCH_NOTIFICATION_ROLE_MAPPINGS = 'FETCH_NOTIFICATION_ROLE_MAPPINGS';
export const FETCH_NOTIFICATION_FUNCTION_MAPPINGS = 'FETCH_NOTIFICATION_FUNCTION_MAPPINGS';
export const FETCH_NOTIFICATION_EVENT_MAPPINGS = 'FETCH_NOTIFICATION_EVENT_MAPPINGS';
export const FETCH_NOTIFICATION_RECIPIENT_STRATEGY_MAPPINGS = 'FETCH_NOTIFICATION_RECIPIENT_STRATEGY_MAPPINGS';
export const UPDATE_TABLE_MAPPING = 'UPDATE_TABLE_MAPPING';

// UI ACTIONS
export const API_REQUEST_DISPATCHED = 'API_REQUEST_DISPATCHED';
export const API_REQUEST_COMPLETED = 'API_REQUEST_COMPLETED';

export const FETCH_REFERENCE_DATA = 'FETCH_REFERENCE_DATA';
export const FETCH_REFERENCE_DATA_TYPES = 'FETCH_REFERENCE_DATA_TYPES';
export const REFERENCE_DATA_TYPE_SELECTED = 'REFERENCE_DATA_TYPE_SELECTED';
export const CREATE_REFERENCE_DATA_VALUE_SUCCESS = 'CREATE_REFERENCE_DATA_VALUE_SUCCESS';
export const CREATE_REFERENCE_DATA_VALUE_ERROR = 'CREATE_REFERENCE_DATA_VALUE_ERROR';
export const UPDATE_REFERENCE_DATA_VALUE_SUCCESS = 'UPDATE_REFERENCE_DATA_VALUE_SUCCESS';
export const UPDATE_REFERENCE_DATA_VALUE_ERROR = 'UPDATE_REFERENCE_DATA_VALUE_ERROR';
export const REFERENCE_DATA_TYPE_VALUE_SELECTED = 'REFERENCE_DATA_TYPE_VALUE_SELECTED';

export const UI_SHOW_MESSAGE_SUCCESS = 'UI_SHOW_MESSAGE_SUCCESS';
export const UI_SHOW_MESSAGE_ERROR = 'UI_SHOW_MESSAGE_ERROR';
export const UI_MESSAGE_CLEAR = 'UI_MESSAGE_CLEAR';

// AUTH ACTIONS
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

// Notification Master Selection Policy
export const ELIGIBLE_NOTIFICATION_MASTERS = 'ELIGIBLE_NOTIFICATION_MASTERS';
export const NOTIFICATION_MASTERS = 'NOTIFICATION_MASTERS';
export const NOTIFICATION_MASTERS_BY_CATEGORYID = 'NOTIFICATION_MASTERS_BY_CATEGORYID';
export const NOTIFICATION_MASTERS_BY_ELIGIBLE_RECIPIENTS_STRATEGY = 'NOTIFICATION_MASTERS_BY_ELIGIBLE_RECIPIENTS_STRATEGY';
export const NOTIFICATION_LIBRARY = 'NOTIFICATION_LIBRARY';
export const NOTIFICATION_LIBRARY_POLICY_CONFIGURATION = 'NOTIFICATION_LIBRARY_POLICY_CONFIGURATION';

// Project Summaries
export const PROJECT_SUMMARIES = 'PROJECT_SUMMARIES';
export const SET_ITEM = 'SET_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const GET_ALL_ITEM = 'GET_ALL_ITEM';

// Policy Rules
export const FETCH_COMPOSITE_RULES_BY_COMPOSITE_RULE = 'FETCH_COMPOSITE_RULES_BY_COMPOSITE_RULE';
export const FETCH_POLICY_RULE_VARIABLES_BY_RULE = 'FETCH_POLICY_RULE_VARIABLES_BY_RULE';
export const FETCH_POLICY_COMPOSITE_RULE_BY_ID = 'FETCH_POLICY_COMPOSITE_RULE_BY_ID';
export const ADD_RULE_TO_COMPOSITE_RULE = 'ADD_RULE_TO_COMPOSITE_RULE';
export const MODIFY_RULE_TO_COMPOSITE_RULE = 'MODIFY_RULE_TO_COMPOSITE_RULE';
export const REMOVE_RULE_TO_COMPOSITE_RULE = 'REMOVE_RULE_TO_COMPOSITE_RULE';
export const POLICY_RULE_ACTION_FAILED = 'POLICY_RULE_ACTION_FAILED';
export const UPDATE_POLICY_MASTER_DETAILS = 'UPDATE_POLICY_MASTER_DETAILS';
export const UPDATE_POLICY_SCHEME_PDP_DETAILS = 'UPDATE_POLICY_SCHEME_PDP_DETAILS';
export const CREATE_POLICY_SCHEME_PDP_DETAILS = 'CREATE_POLICY_SCHEME_PDP_DETAILS';
export const DELETE_POLICY_SCHEME_PDP_DETAILS = 'DELETE_POLICY_SCHEME_PDP_DETAILS';
export const DUPLICATE_POLICY_SCHEME = 'DUPLICATE_POLICY_SCHEME';
export const ADD_COMPOSITE_RULE_TO_POLICY = 'ADD_COMPOSITE_RULE_TO_POLICY';
export const REMOVE_COMPOSITE_RULE_FROM_POLICY = 'REMOVE_COMPOSITE_RULE_FROM_POLICY';
export const UPDATE_COMPOSITE_RULE_TO_POLICY = 'UPDATE_COMPOSITE_RULE_TO_POLICY';
export const FETCH_ENTITLEMENT_OPERATION_TYPES = 'FETCH_ENTITLEMENT_OPERATION_TYPES';
export const CREATE_POLICY_MASTER_DETAILS = 'CREATE_POLICY_MASTER_DETAILS';

// Help Text
export const FETCH_HELPTEXT_MASTERS = 'FETCH_HELPTEXT_MASTERS';

// Guidance Management
export const FETCH_GUIDANCE_FRAGMENTS = 'FETCH_GUIDANCE_FRAGMENTS';
export const FETCH_GUIDANCE_FRAGMENT_POLICY_CONFIG_SUMMARIES = 'FETCH_GUIDANCE_FRAGMENT_POLICY_CONFIG_SUMMARIES';
export const FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG = 'FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG';
export const FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG_FAILED = 'FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG_FAILED';
