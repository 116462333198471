import api from '../../api/IntelligentAgentServiceApi';
import {
  NOTIFICATION_MASTERS,
  NOTIFICATION_MASTERS_BY_ELIGIBLE_RECIPIENTS_STRATEGY,
  NOTIFICATION_MASTERS_BY_CATEGORYID,
  NOTIFICATION_LIBRARY,
  NOTIFICATION_LIBRARY_POLICY_CONFIGURATION,
  UI_SHOW_MESSAGE_SUCCESS,
  UI_SHOW_MESSAGE_ERROR
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchNotificationMasters = () => async (dispatch) => {
  try {
    const url = '/admin/notification-masters?sort=code&direction=asc';
    const response = await api.get(url);
    dispatch({ type: NOTIFICATION_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchNotificationMasterByRecipientStrategies = (params) => async (dispatch) => {
  try {
    let url = '/admin/notification-masters';
    url = `${url}/${params.notificationMasterId}/eligible-recipients?projectId=${params.projectId}`;
    const response = await api.get(url);
    dispatch({ type: NOTIFICATION_MASTERS_BY_ELIGIBLE_RECIPIENTS_STRATEGY, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchNotificationLibrary = () => async (dispatch) => {
  try {
    const url = '/admin/notification-library';
    const response = await api.get(url);
    dispatch({ type: NOTIFICATION_LIBRARY, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchNotificationMastersByCategory = (params) => async (dispatch) => {
  try {
    let url = '/admin/notification-masters';
    url = `${url}?category=${params.notificationCategoryId}`;
    const response = await api.get(url);
    dispatch({ type: NOTIFICATION_MASTERS_BY_CATEGORYID, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchNotificationLibraryByMasterCode = (params) => async (dispatch) => {
  try {
    let url = '/admin/notification/notification-scheme-configuration';
    url = `${url}/${params.notificationMasterCode}`;
    const response = await api.get(url);
    dispatch({ type: NOTIFICATION_LIBRARY_POLICY_CONFIGURATION, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
    dispatch({ type: NOTIFICATION_LIBRARY_POLICY_CONFIGURATION, payload: { notificationMaster: null, notificationMasterPolicyConfigurations: [] } });
  }
};

export const updateNotificationMaster = (code, npDataValue) => async (dispatch) => {
   try {
     let url = '/admin/notification-master';
     url = `${url}/${code}`;
     await api.put(url, npDataValue);
     dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Notification Master value updated successfully.' });
     dispatch(fetchNotificationLibraryByMasterCode({ notificationMasterCode: code }));
   } catch (error) {
     if (error.response && error.response.status === 401) {
          dispatch(signOut());
        } else {
          dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Cannot update Notification Master data value' });
        }
   }
};
