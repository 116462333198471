import React from 'react';
import uuid from 'react-uuid';

import {
 initializeIcons, DefaultButton, IconButton, Link, PrimaryButton
} from '@fluentui/react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

import Grid from '@material-ui/core/Grid';

import AuthProvider from '../../../components/auth/AuthProvider';
import fetchChangeSetList from '../../../state/actions/AdminChangeSetManagementActions';
import { fetchChangeSetById, updateChangeSet, downloadChangeSet } from '../../../state/actions/AdminChangeSetBuilderActions';
import { createFilterOptionsByChangeCategory, createFilterOptionsByPublicationType } from '../../../util/FilterOptionsUtil';
import AdminChangeItemDetail from '../ChangeControl/AdminChangeItemDetail';
import AdminChangeSet from '../ChangeControl/ChangeSet/AdminChangeSet';
import PublishChangeSetDialog from './PublishChangeSetDialog';
import RemoveChangeSetDialog from './RemoveChangeSetDialog';
import ManageChangeSetDetails from './ManageChangeSetDetails';

import Filterv2 from '../../../components/filterv2/Filterv2';
import FilterOptionTypeLiteral from '../../../constants/FilterLiterals';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';
import { getPublishedDate } from '../../../util/DateUtil';
import Tag from "../shared/Tag";
import history from '../../../history';

import '../../../images/dot.png';
import '../../../components/style.css';
import './override.css';

const isReadOnly = getReadOnlyFlagPerEnv();

class AdminChangeSetBuilder extends React.Component {

  constructor (props) {
    super(props);
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === 'CHANGE_SET')[0];
    this.state = {
      filterLiterals,

      changeSetId: this.props.match.params.changeSetId,
      changeSet: null,
      selChangeSetItem: null,

      isPublishChangeSetDialogOpen: false,
      isRemoveChangeSetDialogOpen: false,
      isEditChangeSetDetailsOpen: false,
      isAssignChangeSetDialogOpen: false,
      isShowChangeItemDetailOpen: false,

      filterOptions: FILTER_ENTRIES,
      filterSelections: [],
      filterOptionsKey: uuid(),
    };
    initializeIcons();
  }

  async componentDidMount () {
    const { changeSetId } =  this.props.match.params;
    await this.props.fetchChangeSetById(changeSetId);
  }

  /* Functions used to build section of page */

  menuListSelection = () => {
    const { classes } = this.props;
    const { changeSetId } = this.state;
    const changeSets = this.reduceByApplyingFilter();
    return changeSets.map((item, index) => {
      const { key } = item;
      const isSelected = (changeSetId === key);
       return (
        <ListItem
          key={`list-${index}`}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(key)}
          className={classes.pmItem}>
          <ListItemText
            primary={<Typography type="body2" className={classes.pmItemText}>{item.value}</Typography>}
            disableTypography
          />
        </ListItem>);
    });
  }

  buildChangeSetDetail () {
    const { classes, adminChangeSet } = this.props;
    const { description, environments, status, releaseTagName, finalised, verified } = adminChangeSet;
    return (<div className={classes.chgsetDetail}>
      <div>
        <div><span className={classes.chgsetStatus}>{status}</span></div>
        <div className={classes.chgsetSubtitle}>DESCRIPTION</div>
        <div className={classes.chgsetDesc}>{description}</div>
        <div className={classes.chgsetSubtitle}>ENVIRONMENTS</div>
        <div className={classes.chgsetDesc}>{environments}</div>
      </div>
      <div className={classes.pubDetails}>
        <div className={classes.divider1}></div>
        <div>
          <div className={classes.pubDetailsLabel}>PUBLICATION DETAILS</div>
          <div className={classes.pubDetailsLabel}>Change Set Script</div>
          <div className={classes.pubDetailsExport}>{this.displayExportScript()}</div>
          <div className={classes.pubDetailsLabel}>Finalise Change Set</div>
          <div className={classes.pubDetailsBase}>
            {getFinaliseChangeSet(finalised, classes)}
          </div>
          <div className={classes.pubDetailsLabel}>Release Name</div>
          <div className={classes.pubDetailsBase}>{releaseTagName}</div>
          <div className={classes.pubDetailsLabel}>Change Set Verification for Release</div>
          <div className={classes.pubDetailsBase}>
            {getChangeSetVerificationForRelease(verified, classes)}
          </div>
        </div>
      </div>
    </div>);
  }

  buildTableHeader () {
    const { classes } = this.props;
    return (<Stack horizontal tokens={innerStackTokens}>
      <Stack.Item grow={2} className={[classes.tblhdr, classes.tblChangeDet1].join(' ')}>
        Change Details
      </Stack.Item>
      <Stack.Item grow className={[classes.tblhdr, classes.tblPubStatus].join(' ')}>
        Publication Status
      </Stack.Item>
      <Stack.Item grow={2} className={[classes.tblhdr, classes.tblTestResults].join(' ')}>
        Test Results
      </Stack.Item>
      <Stack.Item grow={2} className={[classes.tblhdr, classes.tblVeriStatus].join(' ')}>
        Verification Status
      </Stack.Item>
      <Stack.Item grow className={[classes.tblhdr, classes.tblChangeBy].join(' ')}>
        Changed by
      </Stack.Item>
      <Stack.Item grow className={[classes.tblhdr, classes.tblChangeDate].join(' ')}>
        Change Date
      </Stack.Item>
      <Stack.Item grow className={[classes.tblhdr, classes.tblChangeSet].join(' ')}>
        Change Set
      </Stack.Item>
      <Stack.Item grow className={[classes.tblhdr, classes.tblChangeDet2].join(' ')}>
        Change Details
      </Stack.Item>
    </Stack>);
  }

  buildTableChangeSet (changeSetItem) {
    const { classes } = this.props;
    const { id, changeDescription, changeSet, changeCategory, changeType, status, testStatus, submittedDate, submittedBy, verified } = changeSetItem;
    return (<>
      <Stack key={`changeSetitem-${id}`} horizontal tokens={innerStackTokens}>
        <Stack.Item grow={2} vertical className={classes.tblChangeDet1}>
          <Stack>
            <span>
              <Tag
                  tagName={changeType.value}
                  tagType={changeType.value === "Open" ? "" : "dark"}
                />
            </span>
            <span>{changeCategory.value}</span>
            <span>{changeDescription}</span>
          </Stack>
        </Stack.Item>
        <Stack.Item grow className={classes.tblPubStatus}>
        <span style={{ justifyContent: 'right' }}>
          <Tag
            tagName={status}
            tagType={status === "Open" ? "" : "dark"}
          />
        </span>
        </Stack.Item>
        <Stack.Item grow={2} className={classes.tblTestResults}>
          { getTestResults(testStatus, classes) }
        </Stack.Item>
        <Stack.Item grow={2} className={classes.tblVeriStatus}>
          { getChangeSetVerification(verified, classes) }
        </Stack.Item>
        <Stack.Item grow className={classes.tblChangeBy}>
          {submittedBy ? submittedBy.displayName : ''}
        </Stack.Item>
        <Stack.Item grow className={classes.tblChangeDate}>
          {getPublishedDate(submittedDate)}
        </Stack.Item>
        <Stack.Item grow className={classes.tblChangeSet}>
          {changeSet.name}{AdminChangeSetBuilder.isChangeItemEditable(status) ? GetPencilIconButton(() => this.handleAssignChangeSet(changeSetItem)) : null}
        </Stack.Item>
        <Stack.Item grow className={classes.tblChangeDet2}>
          <Link onClick={() => this.handleViewChangeDetails(changeSetItem)}>View</Link>
        </Stack.Item>
      </Stack>
      <Separator style={{ fontWeight: '600' }}></Separator>
    </>);
  }

  /* Event and Action Handlers  */

  handleListItemClickLP = async (changeSetId) => {
    await this.props.fetchChangeSetById(changeSetId);
    this.setState((prevState) => ({ ...prevState, changeSetId }));
  }

  handleViewChangeDetails (selChangeSetItem) {
    const current = this.state.isShowChangeItemDetailOpen;
    this.setState((prevState) => ({ ...prevState, isShowChangeItemDetailOpen: !current, selChangeSetItem }));
  }

  handleAssignChangeSet (selChangeSetItem) {
    const current = this.state.isAssignChangeSetDialogOpen;
    this.setState((prevState) => ({ ...prevState, isAssignChangeSetDialogOpen: !current, selChangeSetItem }));
  }

  togglePublishChangeSetDialogHandler = () => {
    const current = this.state.isPublishChangeSetDialogOpen;
    this.setState((prevState) => ({ ...prevState, isPublishChangeSetDialogOpen: !current }));
  }

  toggleRemoveChangeSetDialog = () => {
    const current = this.state.isRemoveChangeSetDialogOpen;
    this.setState((prevState) => ({ ...prevState, isRemoveChangeSetDialogOpen: !current }));
  }

  toggleRemoveChangeSetDialogHandler = async (deleteInitiated) => {
    const current = this.state.isRemoveChangeSetDialogOpen;

    if (deleteInitiated) {
      const changeSets = this.reduceByApplyingFilter();
      // prepare to display the first ChangeSet (if any)
      if (changeSets.length > 0) {
        const changeSetId = changeSets[0].key;
        this.setState((prevState) => ({ ...prevState, changeSetId }));
        await this.props.fetchChangeSetById(changeSetId);
      } else {
        const CHANGE_SET_MANAGEMENT_URL_PATH = "/adminChangeConsole";
        history.push(CHANGE_SET_MANAGEMENT_URL_PATH);
      }
    }
    this.setState((prevState) => ({ ...prevState, isRemoveChangeSetDialogOpen: !current }));
  }

  toggleEditChangeSetDetailsDialogHandler = () => {
    const current = this.state.isEditChangeSetDetailsOpen;
    this.setState((prevState) => ({ ...prevState, isEditChangeSetDetailsOpen: !current }));
  }

  toggleAssignChangeSetDialogHandler = async () => {
    const current = this.state.isAssignChangeSetDialogOpen;
    const { changeSetId } = this.state;
    await this.props.fetchChangeSetById(changeSetId);
    this.setState((prevState) => ({ ...prevState, isAssignChangeSetDialogOpen: !current }));
  }

  toggleShowChangeItemDetailDialogHandler = async () => {
    const current = this.state.isShowChangeItemDetailOpen;
    const { changeSetId } = this.state;
    await this.props.fetchChangeSetById(changeSetId);
    this.setState((prevState) => ({ ...prevState, isShowChangeItemDetailOpen: !current }));
  }

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  downloadScript = async () => {
    const { adminChangeSet } = this.props;
    await this.props.downloadChangeSet(adminChangeSet.id);
    const a = document.createElement('a');
    const blob = new Blob([this.props.downloadChangeSetResult], { type: 'application/octet-stream' });
    a.href = window.URL.createObjectURL(blob);
    a.download = 'liquibase.yaml';
    a.click();
  }

  /* Helper Functions */

  isPublishChangeSetNotAllowed () {
    const { status, verified } = this.props.adminChangeSet;
    const byStatusAndVerification = (status === 'OPEN' && !verified);
    const byStatus = (status === 'PUBLISHED' || status === 'COMPLETED');
    const isPublishChangeSetNotAllowed = isReadOnly || byStatus || byStatusAndVerification;
    return isPublishChangeSetNotAllowed;
  }

  /* Filter */

  static isChangeItemEditable (status) {
    return (status === 'DRAFT' && !isReadOnly);
  }

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState((prevState) => ({ ...prevState, filterSelections }));
  }

  displayExportScript () {
    const { status } = this.props.adminChangeSet;
    if (status === 'PUBLISHED') {
      return <Link onClick={this.downloadScript}>Export Script <IconButton iconProps={{ iconName: 'Download', style: { fontSize: 14 }  }} styles={{ root: { color: 'blue' }, rootHovered: { backgroundColor: '#0078d4', color: 'blue' } }} /></Link>
    }
    return "Export Script";
  }

  createFilterOptions () {
    const { adminChangeSetSummaries } = this.props;
    if (!adminChangeSetSummaries || !adminChangeSetSummaries.data) return {};
    const filterPublicationType = createFilterOptionsByPublicationType();
    const filterChangeCategory = createFilterOptionsByChangeCategory(adminChangeSetSummaries.data.changeCategories);
    const filterEntries = {
      contentFilters: [filterPublicationType, filterChangeCategory]
    };
    return filterEntries;
  }

  /**
   * Apply filter options to change sets
   * @returns array of change sets key-value (displayed as clickable list items)
   */
  reduceByApplyingFilter () {
    const { filterSelections } = this.state;
    const { adminChangeSetSummaries } = this.props;
    const changeSets = [];
    if (!adminChangeSetSummaries || !adminChangeSetSummaries.data) return changeSets;
    const compareIfExistsByChangeSetId = (id) => changeSets.some((ch) => ch.key === id);

    // the builder uses 2 'filter options'
    if (filterSelections.length === 0 || (filterSelections[0].items.length === 0 && filterSelections[1].items.length === 0)) {
      // get all change sets 'id' and 'name'
      adminChangeSetSummaries.data.changeCategories.forEach((element) => {
        element.changeSets.forEach((cs) => {
          if (!compareIfExistsByChangeSetId(cs.id)) {
            changeSets.push({
              key: cs.id,
              value: cs.name
            });
          }
        });
      });
    } else {
      // filter change sets by applying the filter options
      const allStatus = filterSelections[0].items.length === 0;
      const allCategory = filterSelections[1].items.length === 0;
      const compareByStatus = (status) => filterSelections[0].items.some((ch) => {
        const key = ch.key.toUpperCase();
        if (status === 'OPEN') {
          return key === 'UNPUBLISHED';
        }
        return key === 'PUBLISHED';
      });
      const compareByCategoryName = (category) => filterSelections[1].items.some((ch) => ch.key === category);
      adminChangeSetSummaries.data.changeCategories.forEach((element) => {
        element.changeSets.forEach((cs) => {
          let isStatusValid = false;
          let isCategoryValid = false;
          if (allStatus || compareByStatus(cs.status)) {
            isStatusValid = true;
          }
          if (allCategory || compareByCategoryName(cs.changeCategory.id)) {
            isCategoryValid = true;
          }
          if (isStatusValid && isCategoryValid && !compareIfExistsByChangeSetId(cs.id)) {
            changeSets.push({
              key: cs.id,
              value: cs.name
            });
          }
        });
      });
    }
    changeSets.sort((a, b) => (a.value).localeCompare(b.value));

    return changeSets;
  }

  /* Render */

  render () {
    const { classes, adminChangeSet, adminChangeSetSummaries } = this.props;
    const { name, status, publishedDate, changeItems } = adminChangeSet;
    const { filterType, title, subTitle } = this.state.filterLiterals;
    const { filterOptionsKey, filterSelections } = this.state;
    const { isPublishChangeSetDialogOpen, isRemoveChangeSetDialogOpen, isEditChangeSetDetailsOpen, isAssignChangeSetDialogOpen, isShowChangeItemDetailOpen } = this.state;
    const { selChangeSetItem } = this.state;
    const isRemoveChangeSetNotAllowed = isReadOnly || (status === 'PUBLISHED' || status === 'COMPLETED');

    return (
      <React.Fragment>
        <Box display="flex">
          <Box>
            <Card variant='outlined' className={classes.filterCard}>
              <CardContent>
                <Typography variant="h6" className={classes.filterCardHeader}>
                  {title}
                </Typography>

                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{subTitle}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    { adminChangeSetSummaries && adminChangeSetSummaries.data ?
                    <Filterv2 key={filterOptionsKey} filterType={filterType} filterOptions={this.createFilterOptions()} applyFilterSelectionsHandler={this.applyFilterSelectionsHandler} initialSelections={filterSelections} showSelectedItems={true}  ></Filterv2> : null }
                  </AccordionDetails>
                </Accordion>
                {this.menuListSelection()}
              </CardContent>
            </Card>
          </Box>
          <Box flexGrow={4}>
            <Card variant='outlined'>
              <CardContent>
                <div className={classes.headerMainSection}>
                  <Box display="flex">
                    <Box flexGrow={1} >
                      <div className={classes.headerMainTitle}>Change Set Builder</div>
                    </Box>
                    <Box display="flex" flexDirection="column"  >
                      <DefaultButton text="Remove Set" onClick={this.toggleRemoveChangeSetDialog} disabled={isRemoveChangeSetNotAllowed} />
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ paddingLeft: '20px' }} >
                      <PrimaryButton text="Publish" onClick={this.togglePublishChangeSetDialogHandler} disabled={this.isPublishChangeSetNotAllowed()} />
                    </Box>
                  </Box>
                </div>
                <div className={`${classes.chgsetSection}`}>
                  <Box display="flex" className={classes.chgsetBox}>
                    <Box flexGrow={1} >
                      <div>
                        <div className={classes.chgsetTitleName}>{name}</div>
                        <div className={classes.chgsetUpdatedDate}>Last Updated: {getPublishedDate(publishedDate)}</div>
                      </div>
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ alignItems: 'center', justifyContent: 'center', height: '50px' }} >
                      <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 23 }  }} styles={{ root: { color: 'white' }, rootHovered: { backgroundColor: '#0078d4', color: 'white' } }} onClick={this.toggleEditChangeSetDetailsDialogHandler} />
                    </Box>
                  </Box>
                  { this.buildChangeSetDetail() }
                </div>
                <Box>
                  <Grid item xs={12}>
                  <Typography variant='h6' align='left' className={classes.changeSetTitle}>
                    {name}
                  </Typography>
                  </Grid>
                </Box>
                { this.buildTableHeader() }
                {changeItems ? changeItems.map((adminChangeSetItem) => (
                  this.buildTableChangeSet(adminChangeSetItem)
                )) : null}
              </CardContent>
            </Card>
          </Box>
        </Box>

        { isPublishChangeSetDialogOpen ? <PublishChangeSetDialog adminChangeSet={adminChangeSet} onClose={this.togglePublishChangeSetDialogHandler} isReadOnly={isReadOnly} /> : null }

        { isRemoveChangeSetDialogOpen ? <RemoveChangeSetDialog adminChangeSet={adminChangeSet} onClose={this.toggleRemoveChangeSetDialogHandler} isReadOnly={isReadOnly} /> : null }

        { isEditChangeSetDetailsOpen ? <ManageChangeSetDetails onClose={this.toggleEditChangeSetDetailsDialogHandler}changeSet={adminChangeSet} open={isEditChangeSetDetailsOpen} isReadOnly={isReadOnly} /> : null }

        { isAssignChangeSetDialogOpen ? <AdminChangeSet changeItem={selChangeSetItem} isReadOnly={isReadOnly} hideAdminChangeSetPopupHandler={this.toggleAssignChangeSetDialogHandler} /> : null }

        { isShowChangeItemDetailOpen ? <AdminChangeItemDetail changeItem={selChangeSetItem} open={isShowChangeItemDetailOpen} onClose={this.toggleShowChangeItemDetailDialogHandler} keyResourceTitle={'TBA-keyResourceTitle'} changeCategoryName={selChangeSetItem.changeCategory.value}></AdminChangeItemDetail> : null }
      </React.Fragment>
    );
  }
}

const styles = () => ({

  root: {
    flexGrow: 1
  },
  headerMainSection: {
    padding: '16px 0'
  },
  headerMainTitle: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'PharmaCond',
    color: '#323130'
  },
  chgsetSection: {
    border: '2px solid lightgrey',
    height: '470px'
  },
  chgsetBox: {
    backgroundColor: '#0078d4',
    height: '50px',
    color: '#fff',
    padding: '16px 33px'
  },
  chgsetTitleName: {
    fontSize: '20px',
    fontWeight: '600'
  },
  chgsetUpdatedDate: {
    padding: '8px 0'
  },
  chgsetStatus: {
    border: 'solid 1px #006ed2;',
    padding: '3px 12px 3px 11px',
  },
  chgsetSubtitle: {
    width: '700px',
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
    padding: '20px 0 0 0',
  },
  filterCard: {
    backgroundColor: '#f3f2f1',
    width: 300,
    margin: 0
  },
  filterCardHeader: {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'SegoeUI',
    marginBottom: '1rem'
  },
  menuListItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
  menuListItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  chgsetDetail: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridTemplateColumns: '7fr 3fr',
    gridGap: '20px',
    padding: '16px 0 0 16px',
  },

  tblhdr: {
    fontWeight: 'bold',
  },
  tblChangeDet1: {
    maxWidth: '20rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblPubStatus: {
    maxWidth: '10rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblTestResults: {
    maxWidth: '8rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblVeriStatus: {
    maxWidth: '15rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblChangeBy: {
    maxWidth: '15rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblChangeDate: {
    maxWidth: '8rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblChangeSet: {
    maxWidth: '15rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },
  tblChangeDet2: {
    maxWidth: '5rem',
    alignItems: 'flex-start',
    display: 'flex',
    height: 30,
  },

  pubDetailsLabel: {
    width: '300px',
    height: '20px',
    margin: '20px 0 0 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#323130',
  },
  pubDetailsExport: {
    width: '200px',
    height: '20px',
    margin: '5px 0 0 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#888',
  },
  pubDetailsBase: {
    width: '200px',
    height: '20px',
    margin: '5px 0 0 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#323130',
  },
  pubDetails: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 9fr',
    borderLeft: "solid 1px #979797",
  },
  divider1: {
    width: '1px',
    border: 'solid 0px #cdcdcd',
    backgroundColor: '#f3f2f1',
  },
  filterTitle: {
    color: '#323130'
  },
  chgsetDesc: {
    padding: '5px 0',
  },
  verificationCompletionIcon: {
    border: "0",
    backgroundColor: "null",
    color: "#00aa23",
    fontSize: "14px",
    cursor: "grab",
    float: "left",
    marginRight: "6px",
  },
  verificationInCompletionIcon: {
    border: "0",
    backgroundColor: "null",
    color: "black",
    fontSize: "14px",
    cursor: "grab",
    float: "left",
    marginRight: "6px",
  },
  changeSetTitle: {
    height: '24px',
    // margin: 0 855px 16px 0;
    fontFamily: 'SegoeUI',
    fontSize: '16px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#323130'
  },
  pmItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  pmItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
});

const innerStackTokens = {
  padding: 10,
};

const getChangeSetVerification = (verified, classes) => {
  return verified ? (
    <>
      <CheckCircleIcon className={classes.verificationCompletionIcon} />
      Verified for publication
    </>
  ) : (
    <>
      <Cancel className={classes.verificationInCompletionIcon} /> To be verified
    </>
  );
};

const getTestResults = (testStatus, classes) => {
  return testStatus === 'PASSED' ? (
    <>
      <CheckCircleIcon className={classes.verificationCompletionIcon} />
      Passed
    </>
  ) : (
    <>
      <Cancel className={classes.verificationInCompletionIcon} /> To be verified
    </>
  );
};

const getFinaliseChangeSet = (finalized, classes) => {
  return finalized ? (
    <>
      <CheckCircleIcon className={classes.verificationCompletionIcon} />
      Verified for publication
    </>
  ) : (
    <>
      <Cancel className={classes.verificationInCompletionIcon} /> To be verified
    </>
  );
};

const getChangeSetVerificationForRelease = (verified, classes) => {
  return verified ? (
    <>
      <CheckCircleIcon className={classes.verificationCompletionIcon} />
      Verified for publication
    </>
  ) : (
    <>
      <Cancel className={classes.verificationInCompletionIcon} /> To be verified
    </>
  );
};

const GetPencilIconButton = (handleClick) => (
  <IconButton
    iconProps={{ iconName: "Edit" }}
    onClick={() => handleClick()}
    styles={{
      root: { height: "auto" },
      icon: { fontSize: "20px", margin: "0 0 0 0" },
      rootHovered: { backgroundColor: "#fff" },
    }}
  ></IconButton>
);

const FILTER_ENTRIES = {
	contentFilters: [
		{
      filterType: 'Policy Type',
		  entries: [
        {
          key: 'Approval Policies',
          value: 'Approval Policies'
        },
        {
          key: 'User Attribute Policies',
          value: 'User Attribute Policies'
        },
        {
          key: 'Regional Policies',
          value: 'Regional Policies'
        },
        {
          key: 'Verifcation Plan Policies',
          value: 'Verifcation Plan Policies'
        },
        {
          key: 'Project Lifecycle Policies',
          value: 'Project Lifecycle Policies'
        },
      ]
	},
  {
    filterType: 'Notification Type',
    entries: [
      {
        key: 'Notify Approval Policies',
        value: 'Notify Approval Policies'
      },
      {
        key: 'Notify User Attribute Policies',
        value: 'Notify User Attribute Policies'
      },
      {
        key: 'Notify Regional Policies',
        value: 'Notify Regional Policies'
      },
      {
        key: 'Notify Verifcation Plan Policies',
        value: 'Notify Verifcation Plan Policies'
      },
      {
        key: 'Notify Project Lifecycle Policies',
        value: 'Notify Project Lifecycle Policies'
      },
    ]
  }
]
};

AdminChangeSetBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    adminChangeSetSummaries: state.changSetsSummariesData.adminChangeSetSummaries,
    adminChangeSet: state.changeSetData.adminChangeSet,
    updateChangeSetResult: state.changeSetData.updateChangeSetResult,
    downloadChangeSetResult: state.changeSetData.downloadChangeSetResult,
  };
};

const connected = connect(mapStateToProps, {
  fetchChangeSetList,
  fetchChangeSetById,
  updateChangeSet,
  downloadChangeSet
})(AuthProvider(AdminChangeSetBuilder));

export default withStyles(styles)(connected);
