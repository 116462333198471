import React from 'react';
import PolicyLibraryMasterCard from './PolicyLibraryMasterCard';

class PolicyLibraryMasterGrid extends React.Component {
  renderCards () {
  return this.props.policyLibraryData.policyLibraryCategory ? this.props.policyLibraryData.policyLibraryCategory.map((ia, index) => (
      <PolicyLibraryMasterCard
          key={`pls-${index}`}
          index={index}
          policySchemeTypeId={ia.policyLibrarySummary.policySchemeTypeId}
          policySummaryList={ia.policyLibrarySummary}
          totalnoOfSchemes={ia.policyLibrarySummary.totalnoOfSchemes}
          title={ia.policyLibrarySummary.policySchemeType}
          policySchemesList={ia.policyScheme}
          policyDescription={ia.policyLibrarySummary.description}
      />
  )) : '';
  }

  render () {
    return (
      this.renderCards()
    );
  }
}

export default PolicyLibraryMasterGrid;
