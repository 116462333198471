export default function checkIfFiltered (selectedFilterOptionArray, atIndex, targetValue) {
  if (!selectedFilterOptionArray.length || !selectedFilterOptionArray[atIndex]) {
    return true;
  }
  const selectedFilterStringArray = selectedFilterOptionArray[atIndex].items.map((item) => item.value);
  return !selectedFilterStringArray.length || selectedFilterStringArray.some((filterValue) => filterValue === targetValue);
}

export function createFilterOptionsForChangeSetManagement (adminChangeCategories) {
    const contentFilters = [];
    const changeSetStatusOptions = {
      filterType: "Publication Type",
      entries: [
        { key: 1, value: "PUBLISHED" },
        { key: 2, value: "OPEN" },
      ],
    };
    contentFilters.push(changeSetStatusOptions);

    const changeCategoriesEntries = adminChangeCategories.map((category, index) => ({ key: index, value: category.changeCategoryName }));
    if (changeCategoriesEntries.length) {
      contentFilters.push({ filterType: "Change Category", entries: changeCategoriesEntries });
    }

    const taggedReleaseEntries = adminChangeCategories
    .flatMap(
      (category) => category.changeSets.filter((changeSet) => changeSet.releaseTagName).map((changeSet) => ({ key: changeSet.id, value: changeSet.releaseTagName }))
    )
    .reduce((prev, curr) => (prev.find((a) => a.value === curr.value) ? prev : prev.push(curr) && prev), [])
    .sort((a, b) =>  a.value.localeCompare(b.value));

    if (taggedReleaseEntries.length) {
      contentFilters.push({ filterType: "Tagged Release", entries: taggedReleaseEntries });
    }
    return { contentFilters };
}
