import api from '../../api/IntelligentAgentServiceApi';
import {
  API_REQUEST_COMPLETED,
  API_REQUEST_DISPATCHED, CANCEL_EDIT_PIP_ROW,
  FETCH_PIP,
  FETCH_PIP_ROW,
  FETCH_ALL_PIPS,
  FETCH_PIPS, UI_SHOW_MESSAGE_ERROR, UI_SHOW_MESSAGE_SUCCESS,
  UPDATE_PIP_ATTRIBUTES_SUCCESS,
  UPDATE_PIP_VALUE_SUCCESS,
  ADD_PIP_ATTRIBUTE_VALUES_SUCCESS,
  DELETE_PIP_ATTRIBUTE_VALUES_SUCCESS
} from '../../constants/ActionTypes';

export const fetchPIPs = (pdpId) => async (dispatch) => {
  dispatch({ type: API_REQUEST_DISPATCHED });
  const response = await api.get(`/admin/policy-engine/policy-information-point/search/${pdpId}`);
  dispatch({ type: FETCH_PIPS, payload: response.data });
  dispatch({ type: API_REQUEST_COMPLETED });
};

export const fetchAllPIPs = () => async (dispatch) => {
  dispatch({ type: API_REQUEST_DISPATCHED });
  const response = await api.get(`/admin/policy-engine/policy-information-point/search`);
  dispatch({ type: FETCH_ALL_PIPS, payload: response.data });
  dispatch({ type: API_REQUEST_COMPLETED });
};

export const fetchPIP = (pipId) => async (dispatch) => {
  const response = await api.get(`/pips/${pipId}`);
  dispatch({ type: FETCH_PIP, payload: response.data });
};

export const fetchPipRow = (pipId, displayRowOrder) => async (dispatch) => {
  const response = await api.get(`/admin/policy-engine/policy-information-point/${pipId}/pip-attribute-value/search?displayRowOrder=${displayRowOrder}`);
  dispatch({ type: FETCH_PIP_ROW, payload: response.data });
};

export const cancelEditPipRow = () => async (dispatch) => {
  dispatch({ type: CANCEL_EDIT_PIP_ROW });
};

export const updatePipAttributes = (pipId, displayRowOrder, formValues) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: UPDATE_PIP_ATTRIBUTES_SUCCESS });
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Policy Information Point updated successfully' });
    return success;
  }
  function onError(error) {
    dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Error updating Policy Information Point' });
    return error;
  }
  try {
    const success = await api.put(`/admin/policy-engine/policy-information-point-attribute-values?pipId=${pipId}`, formValues);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addPipAttributes = (pipId, formValues) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: ADD_PIP_ATTRIBUTE_VALUES_SUCCESS });
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Policy Information Point Attribute Value added successfully' });
    return success;
  }
  function onError(error) {
    dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Error adding Policy Information Point Attribute Value' });
    return error;
  }
  try {
    const success = await api.post(`/admin/policy-engine/policy-information-point/${pipId}/attribute-values`, formValues);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const deletePipValues = (pipId, displayOrderRowNo) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: DELETE_PIP_ATTRIBUTE_VALUES_SUCCESS });
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Policy Information Point Attribute Value deleted successfully' });
    return success;
  }
  function onError(error) {
    dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Error deleting Policy Information Point Attribute Value' });
    return error;
  }
  try {
    const success = await api.delete(`/admin/policy-engine/policy-information-point/${pipId}/attribute-value/${displayOrderRowNo}`);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
}

export const updatePipValue = (pipId, formValues) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: UPDATE_PIP_VALUE_SUCCESS });
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Policy Information Point updated successfully' });
    return success;
  }
  function onError(error) {
    dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Error updating Policy Information Point' });
    return error;
  }
  try {
    const success = await api.put(`/admin/policy-engine/policy-information-point-values/${pipId}`, formValues);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};