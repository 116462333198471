import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Filter from "./filter";

const useStyles = makeStyles({
  dialog: {
    position: "absolute", left: "25%", top: "40%", transform: "translate(-75%,-50%)"
  }
});

export default function FilterDialog (props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle>{props.source === "TASK" ? "Task Filter" : "Policy Filter"}</DialogTitle>
      <Filter source={props.source} origin={props.origin}></Filter>
    </Dialog>
  );
}

FilterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
