import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../components/style.css';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
 DefaultButton, PrimaryButton, initializeIcons,
   FontWeights, getTheme, IconButton,  Modal,  TextField
 } from '@fluentui/react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { fetchTaskMasters, createTaskMaster } from '../../state/actions/TaskMasterActions';
import AuthProvider from '../../components/auth/AuthProvider';
import renderHTMLContent from '../../util/ConvertUtil';
import getReadOnlyFlagPerEnv from  '../../util/EnvUtil';
import history from '../../history';

const isReadOnly = getReadOnlyFlagPerEnv();

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

const requirements = [
  { key: '1', text: 'MANDATORY' },
  { key: '2', text: 'RECOMMENDED' },
  { key: '3', text: 'OPTIONAL' }
];

const priorities = [
  { key: '1', text: 'BLOCKER' },
  { key: '2', text: 'CRITICAL' },
  { key: '3', text: 'MAJOR' },
  { key: '4', text: 'MINOR' },
  { key: '5', text: 'TRIVIAL' }
];

const threshold = [
  { key: '1', text: 'MEDIUM' },
  { key: '2', text: 'HIGH' },
  { key: '3', text: 'OVERDUE' }
];

class CreateTaskMaster extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showPopup: true,
      inConfirmation: false,
      currentDesc: '',
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the description Box</p>',
      open: false,
      taskItem: '',
      taskCode: '',
      taskName: '',
      taskDescription: '',
      taskRequirement: '',
      selectedRequirement: '',
      taskPriority: '',
      taskThreshold: '',
      showMsg: true,
      showCodeMsg: true,
      showDesc: 'This is not a unique task code. Update to continue.',
      showNameDesc: 'This is required. Update to continue.',
      showTaskDesc: 'This is required. Update to continue.',
      showNameMsg: true,
    };
    initializeIcons();
  }

  async componentDidMount () {
    this.setState({ show: this.props.open });
    const selectedRequirement = requirements.filter((item) => item.text === 'MANDATORY');
    this.setState({
      currentDesc: '',
      isBtnActivated: true,
      taskRequirement: selectedRequirement[0],
    });
  }

  handleTaskCodeChange=(event) => {
    this.setState({ taskCode: event.target.value });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.taskCode = event.target.value;
    if (event.target.value === '') {
      this.setState({ showCodeMsg: true, showDesc: 'This is not a unique task code. Update to continue.' });
    } else {
      const taskcode = event.target.value.toLowerCase().trim();
      const taskData = this.props.taskMasterData.filter((task) =>  task.code.toLowerCase().trim() === taskcode)[0];
      if (taskData) {
        this.setState({ showCodeMsg: true, showDesc: 'This is not a unique task code. Update to continue.' });
      } else {
        this.setState({ showCodeMsg: false, showDesc: 'This is a valid task code.' });
      }
    }

    if (this.isValidTask()) {
      this.setState({ isBtnActivated: true });
    } else {
      this.setState({ isBtnActivated: false });
    }
  }

  handleTaskNameChange=(event) => {
    this.setState({ taskName: event.target.value });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.taskName = event.target.value;
    if (event.target.value === '') { this.setState({ showNameMsg: true, showNameDesc: 'This is required. Update to continue.' }); } else { this.setState({ showNameMsg: false, showNameDesc: '' }); }
    if (this.isValidTask()) { this.setState({ isBtnActivated: true }); } else { this.setState({ isBtnActivated: false }); }
  }

  handleTaskRequirementChange=(event, item) => {
    this.setState({ taskRequirement: item });
  }

  handleTaskPriorityChange=(event, item) => {
    this.setState({ taskPriority: item.text });
  }

  handleTaskThresholdChange=(event, item) => {
    this.setState({ taskThreshold: item.text });
  }

  handleViewTaskBuilder () {
    this.setState({ newCode: this.props.taskMasterData.filter((item) => (item.code === this.state.taskCode))[0].id });
    const path = `/taskLibraryMasterConsole/taskMasterConsole/taskbuilder/${this.props.taskMasterData.filter((item) => (item.code === this.state.taskCode))[0].id}`;
    history.push(path);
  }

  isValidTask () {
    if (this.state.taskCode !== '' && this.state.taskName !== '' && this.state.taskDescription !== '') {
      this.setState({ isBtnActivated: false });
      return false;
    }
      this.setState({ isBtnActivated: true });
      return true;
  }

  showAddTaskMasterPopup () {
    const { classes } = this.props;
    const currentDesc = this.state.currentDesc ? this.state.currentDesc : '';
    return (
      <Modal
        isOpen={this.state.showPopup}
        onDismiss={this.hideAddTaskMasterHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep1}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Create Task Master </span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddTaskMasterHandler}
              />
            </div>
            <Stack className={classes.taskDetail}>
              <TextField label="Task Code*" value={this.state.taskCode} onChange={this.handleTaskCodeChange} readOnly={isReadOnly} />
              <Stack horizontal>
                {this.state.showCodeMsg
                  ? <div className={classes.statusError}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} className={classes.iconError} /><span className={classes.statusMsg}>{this.state.showDesc}</span></div>
                  : <div className={classes.statusSuccess}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} className={classes.iconSuccess} /><span className={classes.statusMsg}>{this.state.showDesc}</span></div>
                  }
              </Stack>
            </Stack>
            <Stack className={classes.taskDetail}>
              <TextField label="Task Name*" value={this.state.taskName} onChange={this.handleTaskNameChange} readOnly={isReadOnly} />
              <Stack horizontal>
                {this.state.showNameMsg
                  ? <div className={classes.statusError}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} className={classes.iconError} /><span className={classes.statusMsg}>{this.state.showNameDesc}</span></div>
                  : '' }
              </Stack>
            </Stack>
            <Stack className={classes.taskDetail}>
              <span className={classes.fieldName}>Task Description*</span>
              {!isReadOnly
              ? <>
                <Editor
                apiKey={APIKeys.EditorKey}
                value={this.state.taskDescription}
                init={{
                  branding: false,
                  height: 200,
                  browser_spellcheck: true,
                  content_style: 'body { color:#605e5c; }',
                  selector: 'textarea',
                  allow_unsafe_link_target: true,
                  element_format: 'xhtml',
                  plugins: [
                    'wordcount advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help'
                  ],
                  paste_preprocess (plugin, args) {
                  },
                  toolbar:
                    'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
                  init_instance_callback: (editor) => {
                    this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                  }
                }}
                onEditorChange={(content, editor) => {
                  if (content === '') {
                    this.setState({
                      showMsg: true,
                      showTaskDesc: 'This is required. Update to continue.',
                      taskDescription: content,
                      editorCount: editor.plugins.wordcount.body.getCharacterCount()
                    });
                    this.isValidTask();
                  } else {
                  this.setState({
                      showMsg: false,
                      showTaskDesc: '',
                      taskDescription: content,
                      editorCount: editor.plugins.wordcount.body.getCharacterCount()
                    });
                    this.isValidTask();
                  }
                }}
                onBlur={(event, editor) => {
                  this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                }}
              />
              <br/>
              <Stack horizontal>
                {this.state.showMsg
                  ? <div className={classes.statusError}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} className={classes.iconError} /><span className={classes.statusMsg}>{this.state.showTaskDesc}</span></div>
                  : '' }
              </Stack>
              </> : renderHTMLContent(currentDesc)}
            </Stack>
            <Stack className={classes.taskDetail}>
              <Dropdown label="Task Priority"
                selectedKey={this.state.selectedPriority ? this.state.selectedPriority.key : undefined}
                onChange={this.handleTaskPriorityChange}
                placeholder="Select Priority"
                options={priorities}
                styles={this.dropdownStyles}
              />
            </Stack>
            <Stack className={classes.taskDetail}>
              <Dropdown label="Requirement"
                selectedKey={this.state.taskRequirement ? this.state.taskRequirement.key : undefined}
                onChange={this.handleTaskRequirementChange}
                placeholder="Select Requirement"
                options={requirements}
                styles={this.dropdownStyles}
              />
            </Stack>
            <Stack className={classes.taskDetail}>
              <Dropdown label="Threshold"
                selectedKey={this.state.selectedThreshold ? this.state.selectedThreshold.key : undefined}
                onChange={this.handleTaskThresholdChange}
                placeholder="Select Threshold"
                options={threshold}
                styles={this.dropdownStyles}
              />
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl}>
              <DefaultButton text="Cancel" onClick={this.hideAddTaskMasterHandler} />
              <PrimaryButton text="Create" onClick={this.submitAddTaskMasterRequest} disabled={this.state.isBtnActivated && !isReadOnly}/>
            </Stack>
          </Stack>
        </Stack>
        <Separator></Separator>
      </Modal>
    );
  }

  confirmation () {
    const { classes } = this.props;
    const OliLogo = (this.props.result === 'SUCCESS') ? require('../../images/oli-success.svg') : require('../../images/oli-fail.svg');
    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideAddTaskMasterHandler}
        isModeless={true}
        containerClassName={classes.containerConfirmation}
      >
        <Stack verticalAlign="space-between" styles={stackStylesConfirmation}>
          <Stack>
            <div className={classes.header}>
              <img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /><span className={classes.title}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddTaskMasterHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.confirmation}>
                <Stack style={{ paddingTop: '15px', fontFamily: 'SegoeUI' }}>
                  <span>{this.props.message}: </span>
                  <span className={classes.successmsg}>{this.state.taskCode}-{this.state.taskName}</span>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl}>
              <DefaultButton text="Close" onClick={this.hideAddTaskMasterHandler} />
              <PrimaryButton text="View my new task" onClick={() => this.handleViewTaskBuilder()} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  hideAddTaskMasterHandler = () => {
    this.props.hideAddTaskMasterHandler();
    this.setState((prevState) => ({ ...prevState, showPopup: false, inConfirmation: false }));
  }

  submitAddTaskMasterRequest = async () => {
    let mediumThreshold = 0;
    let highThreshold = 0;
    let overdueThreshold = 0;

    if (this.state.taskThreshold === "MEDIUM") { mediumThreshold = 1; }
    if (this.state.taskThreshold === "HIGH") { highThreshold = 1; }
    if (this.state.taskThreshold === "OVERDUE") { overdueThreshold = 1; }

    const thresholdObject = {
      mediumThreshold,
      highThreshold,
      overdueThreshold
    };

    // eslint-disable-next-line react/no-direct-mutation-state
    if (this.state.taskPriority === "") { this.state.taskPriority = "MINOR"; }
    const taskItem = {
      code: this.state.taskCode,
      name: this.state.taskName,
      label: this.state.taskName,
      description: this.state.taskDescription,
      requirement: this.state.taskRequirement.text,
      priority: this.state.taskPriority,
      thresholds: thresholdObject
    };
    this.props.createTaskMaster(taskItem);
    this.setState({ inConfirmation: true, showPopup: false });
  };

  render () {
    return (
      <>
        {this.showAddTaskMasterPopup()}
        {this.confirmation()}
      </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '550px',
    height: '850px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '550px',
    height: '250px',
  },
  header: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '15px 12px 14px 24px',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  statusError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px'
  },
  statusSuccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px'
  },
  iconError: {
    color: 'red'
  },
  iconSuccess: {
    color: 'green'
  },
  statusMsg:
  {
    fontSize: '12px'
  },
  title: {
    fontSize: '20px',
    color: '#323130'
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  successmsg: {
   fontWeight: 'bold'
  },
  btnCtrl: {
     padding: '0 25px 5px',
  },
  dropdownStyles: {
    dropdown: {
      width: 300
    }
  },
  taskDetail: {
    padding: '10px 20px 10px 20px',
    paddingBottom: '0.25rem'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  fieldName: {
    color: '#323130',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '600',
   fontFamily: "Segoe UI"
   },
  fieldNameInfo: {
    color: 'red'
  }
});

const theme = getTheme();
const cancelIcon = { iconName: 'Cancel' };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 5,
  },
};

const stackStylesStep1 = {
  root: {
    height: 500,
  },
};

const stackStylesConfirmation = {
  root: {
    height: 250,
  },
};

const mapStateToProps = (state) => ({
    taskMasterData: state.taskMastersData.taskMasters.masters,
    result: state.ui.result,
    message: state.ui.message,
  });

  CreateTaskMaster.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  fetchTaskMasters,
  createTaskMaster
})(AuthProvider(CreateTaskMaster));

export default withStyles(styles)(connected);
