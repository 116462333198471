import React from 'react';
import TaskLibraryMasterCard from './TaskLibraryMasterCard';

class TaskLibraryMasterGrid extends React.Component {
  renderCards () {
    return this.props.TaskLibraryData ? this.props.TaskLibraryData.map((ia, index) => (
      <TaskLibraryMasterCard
        key={`pls-${index}`}
        index={index}
        taskSummaryList="{ia.summaryname}"
        id={ia.id}        
        title={ia.value}       
      />
    )) : '';
  }

  render () {
    return (
      this.renderCards()
    );
  }
}

export default TaskLibraryMasterGrid;
