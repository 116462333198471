import React from "react";
import { Box } from '@material-ui/core';
import GuidanceFragmentTypesList from "./GuidanceFragmentTypesList";
import GuidanceFragmentPolicyContent from "./GuidanceFragmentPolicyContent";
import AuthProvider from "../../../../components/auth/AuthProvider";

const GuidanceFragmentBuilderWrapper = (props) => (
  <>
    <Box display="flex">
      <GuidanceFragmentTypesList />
      <Box flexGrow={4}>
        <GuidanceFragmentPolicyContent />
      </Box>
    </Box>
  </>
);

export default AuthProvider(GuidanceFragmentBuilderWrapper);
