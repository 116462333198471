import {
  FETCH_GUIDANCE_FRAGMENTS,
  FETCH_GUIDANCE_FRAGMENT_POLICY_CONFIG_SUMMARIES

} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  guidanceFragments: [],
  error: null,
  guidanceFragmentsSummary: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GUIDANCE_FRAGMENTS:
      {
      return { ...state, guidanceFragments: action.payload };
      }
    case FETCH_GUIDANCE_FRAGMENT_POLICY_CONFIG_SUMMARIES:
      return { ...state, guidanceFragmentsSummary: action.payload };

    default:
      return state;
  }
};
