import {
  DefaultButton,
  FontWeights,
  getTheme,
  IconButton,
  initializeIcons,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
} from "@fluentui/react";
import { withStyles } from "@material-ui/styles";
import React from "react";
import "../../images/dot.png";

const DeleteConfirmDialog = ({
  classes,
  isOpen,
  messageTitle,
  onDissmissHandler,
  onDeleteHandler,
  dataValue,
}) => {
  initializeIcons();
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDissmissHandler}
      isModeless={false}
      containerClassName={classes.containerStep1}
    >
      <Stack verticalAlign="space-between" styles={modalBaseStyle}>
        <Stack>
          <div className={classes.modalHeader}>
            <span className={classes.modalTitle}>
              {messageTitle}
            </span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              onClick={onDissmissHandler}
            />
          </div>
          <Stack>
            <div className={classes.modalMessage}>
              Are you sure you want to delete this item "{dataValue}"?
            </div>
          </Stack>
        </Stack>
        <Stack>
          <Separator />
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={classes.actionCtrl}>
            <DefaultButton text="Cancel" onClick={onDissmissHandler} />
            <PrimaryButton text="Confirm" onClick={onDeleteHandler} />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
const styles = (theme) => ({
  containerStep1: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    width: "510px",
    height: "226px",
  },
  modalHeader: {
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    fontWeight: FontWeights.semibold,
    padding: "15px 12px 14px 24px",
  },
  modalTitle: {
    fontSize: "20px",
    color: "#323130",
  },
  modalMessage: {
    fontSize: "14px",
    color: "#323130",
    padding: "15px 12px 14px 24px",
  },
  actionCtrl: {
    padding: "0 25px",
  },
});

const theme = getTheme();

const cancelIcon = { iconName: "Cancel" };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
    fontSize: "25px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const modalBaseStyle = {
  root: {
    height: 200,
  },
};

export default withStyles(styles)(DeleteConfirmDialog);
