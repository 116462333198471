import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
 DefaultButton, FontWeights, getTheme, IconButton, initializeIcons, Modal, PrimaryButton, Separator, Stack 
} from '@fluentui/react';

import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../../components/auth/AuthProvider';
import { fetchPolicySchemeConfiguration } from '../../../state/actions/PolicyLibraryMasterActions';
import { removeChangeSet } from '../../../state/actions/AdminChangeSetBuilderActions';

import '../../../images/dot.png';
import './override.css';

class RemoveChangeSetDialog extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // policyToDuplicate: this.props.policyToDuplicate,
      deleteInitiated: false,

      // windows
      inPromptUserSelection: true,
      inConfirmation: false,

      // selected change set
      changeSet: null,
    };
    initializeIcons();
  }

  // async componentDidMount () {
  // }

  /* Functions used to build section of page */

  promptUserSelection () {
    const { classes, isReadOnly, adminChangeSet } = this.props;
    // if (!this.state.changeSet) return null;

    return (
      <Modal
        isOpen={this.state.inPromptUserSelection}
        onDismiss={this.hideDialogHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={modalBaseStyle}>
          <Stack>
            <div className={classes.modalHeader}>
              <span className={classes.modalTitle}>Remove Change Set</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                onClick={this.hideDialogHandler}
              />
            </div>
            <Stack>
              <div className={classes.modalMessage}>Confirm the removal of {adminChangeSet.name}?</div>
            </Stack>
          </Stack>
          <Stack>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.actionCtrl}>
              <DefaultButton text="Cancel" onClick={this.hideDialogHandler} />
              <PrimaryButton text="Remove" onClick={this.removeChangeSet} disabled={isReadOnly} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  confirmation () {
    const { classes, adminChangeSet } = this.props;

    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideDialogHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={modalBaseStyle}>
          <Stack>
            <div className={classes.modalHeader}>
              <span className={classes.modalTitle}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                onClick={this.hideDialogHandler}
              />
            </div>
            <Stack>
              <div className={classes.modalMessage}>{adminChangeSet.name} has been been removed.</div>
            </Stack>
          </Stack>
          <Stack>
            <Separator />
            <Stack horizontal horizontalAlign="end" className={classes.actionCtrl}>
              <DefaultButton text="Okay" onClick={this.hideDialogHandler} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  /* Event and Action Handlers  */

  hideDialogHandler = () => {
    const { deleteInitiated } = this.state;
    this.props.onClose(deleteInitiated);
    this.setState((prevState) => ({ ...prevState, inPromptUserSelection: false, inConfirmation: false }));
  }

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  removeChangeSet = async () => {
    const { adminChangeSet } = this.props;
    await this.props.removeChangeSet(adminChangeSet.id);
    this.setState((prevState) => ({ ...prevState, inPromptUserSelection: false, inConfirmation: true, deleteInitiated: true }));
  }

  /* Helper Functions */

  /* Render */

  render () {
    return (
      <>
        {this.promptUserSelection()}
        {this.confirmation()}
       </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '226px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '226px',
  },
  modalHeader: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '15px 12px 14px 24px',
  },
  modalTitle: {
    fontSize: '20px',
    color: '#323130',
  },
  modalMessage: {
    fontSize: '14px',
    color: '#323130',
    padding: '15px 12px 14px 35px',
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  actionCtrl: {
    padding: '0 25px',
  },
});

const theme = getTheme();

const cancelIcon = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const modalBaseStyle = {
  root: {
    height: 200,
  },
};

const mapStateToProps = (state) => ({
    result: state.ui.result,
  });

  RemoveChangeSetDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  fetchPolicySchemeConfiguration,
  removeChangeSet
})(AuthProvider(RemoveChangeSetDialog));

export default withStyles(styles)(connected);
