import { Stack } from "@fluentui/react";
import { withStyles } from "@material-ui/styles";
import React from "react";
import RefDataTypeValue from "./RefDataTypeValue";

const RefDataTypeValueList = ({ dataList, classes }) => (
  <>
    <Stack horizontal horizontalAlign="start" className={classes.stackHeader}>
      <Stack.Item className={classes.valueSection}>Value</Stack.Item>
      <Stack.Item className={classes.sortOrderSection}>Sort Order</Stack.Item>
      <Stack.Item className={classes.valueAvalabilitySection}>Value Availability</Stack.Item>
      <Stack.Item className={classes.editSection}>Edit</Stack.Item>
    </Stack>
    <Stack>
      {dataList?.map((item, index) => (
        <RefDataTypeValue item={item} key={index}/>
      ))}
    </Stack>
  </>
);

const styles = () => ({
  stackHeader: {
    "& .ms-StackItem": {
      fontWeight: "bold",
    },
  },
  valueSection: {
    width: "35%",
  },
  sortOrderSection: {
    width: "20%",
  },
  valueAvalabilitySection: {
    width: "35%",
  },
  editSection: {
    width: "10%",
  },
});

export default withStyles(styles)(RefDataTypeValueList);
