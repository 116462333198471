/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { initializeIcons, DefaultButton } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';

import AuthProvider from '../../components/auth/AuthProvider';
import PolicyLibraryScheme from './PolicyLibraryScheme';
import { fetchPolicyLibraryMasters, fetchPolicySchemeConfigurations } from '../../state/actions/PolicyLibraryMasterActions';

import getReadOnlyFlagPerEnv from  '../../util/EnvUtil';

import './PolicyLibrarySchemeDetails.css';
import CreatePolicyScheme from './policySchemes/CreatePolicyScheme';
import '../../components/style.css';
import FilterCallout from '../../components/filterv2/FilterCallout';
import FilterOptionTypeLiteral from '../../constants/FilterLiterals';
import { createFilterOptionsByPolicyScheme } from '../../util/FilterOptionsUtil';

const isReadOnly = getReadOnlyFlagPerEnv();

class PolicyLibrarySchemeDetails extends React.Component {
  constructor (props) {
    super(props);
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === 'POLICY_LIBRARY')[0];
    this.state = {
      filterLiterals,
      policySchemeTypeId: this.props.match.params.policySchemeTypeId,
      policyScheme: null,
      selSchemes: [],
      toggleFilterCallout: false,
      showAddPolicyScheme:false,
      filterSelections: [],
      filterOptionsKey: uuid(),
    };
    initializeIcons();
  }

  async componentDidMount () {
    document.title = "Policy Library";
    const { policySchemeTypeId } = this.state;
    await this.props.fetchPolicyLibraryMasters();
    await this.props.fetchPolicySchemeConfigurations(policySchemeTypeId);

    if (this.props.policyLibraryData && this.props.policyLibraryData.policyLibraryCategory) {
      const policySchemes = this.findPolicyCategoryById(policySchemeTypeId);
      if (policySchemes && policySchemes.length > 0) {
        this.setState((prevState) => ({...prevState, policyScheme: policySchemes[0] }));
      }
    }
  }

  addNewPolicyScheme= ()=> {
    this.setState({ showAddPolicyScheme: true });
  }

  /* Functions used to build section of page */
  buildPolicyLibraryDetails () {
    const { classes } = this.props;
    const { policyScheme } = this.state;
    const description = policyScheme ? this.prepareForDisplayPolicyDescription(policyScheme.description) : "";
    const policySchemeType = policyScheme ? policyScheme.policyLibrarySummary.policySchemeType : "";  
    return (
      <Card
      className={classes.cardPolicy}
      variant="outlined"
      style={{ width: "95%" }}
    >
      <div className={classes.grid1}>
        <div className={classes.cardHeader}>{policySchemeType}</div>
        <div className={classes.headerSection}>
           <DefaultButton  className={classes.btnHeaderSection} text="Add New Policy" onClick={this.addNewPolicyScheme} disabled={isReadOnly} />
        </div>
      </div>
      <div className={classes.grid2}>
        <div>
          <div className={classes.statsPolicySegments}>
            TBA Segments | TBA Decisions | TBA Rules
          </div>
          <div className={classes.subtitle}>Description</div>
          <div className={classes.fieldRow}>
            <>{description}</>
          </div>
        </div>
        <div className={classes.grid3}>
          <div className={classes.divider1}></div>
          <div>
            <div className={classes.policySchemesInUse}>
              MASTER POLICY SUMMARY
            </div>
            <div className={classes.policySchemesInUse}>
              TBA Policy Schemes in use
            </div>
            <div className={classes.policySchemesInUse}>
              TBA Segments in use
            </div>
            <div className={classes.policySchemesInUse}>
              TBA Rules in use
            </div>
          </div>
        </div>
      </div>
    </Card>
    );
  }

  /* Event and Action Handlers */

  // used to toggle display of filter dialog
  toggleFilterCalloutHandler = () => {
    this.setState((prevState) => ({ ...prevState, toggleFilterCallout: !this.state.toggleFilterCallout }));
  };

  // remove selected item from 'filterSelections'
  handleSelectedTypeToggle = (item) => () => {
    const { filterSelections } = this.state;
    const newselValues = filterSelections[0].items.filter((ch) => ch.key !== item.key);
    const newfilterSelections = filterSelections;
    newfilterSelections[0].items = newselValues;
    this.setState((prevState) => ({ ...prevState, filterSelections: [...newfilterSelections] }));
  };

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  /* Helper Functions */

  findPolicyCategoryById (policySchemeTypeId) {
    const result = [];
    this.props.policyLibraryData.policyLibraryCategory.forEach((policy) => {
      if (
        policy.policyLibrarySummary.policySchemeTypeId === policySchemeTypeId
      ) {
        result.push(policy);
      }
    });

    return result;
  }

  prepareForDisplayPolicyDescription (description) {
    const val = description || "TODO. No policy schemes summary description from API";
    if (val.length > 175) {
      return `${val.substring(0, 175)}...`;
    }
    return val;
  }

  // move accordion cards left or right
  slide (direction) {
    const container = document.getElementById("container");
    if (direction === "left") {
      container.scrollLeft -= 250;
    } else {
      container.scrollLeft += 250;
    }
  }

  /* Filter */

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState((prevState) => ({ ...prevState, filterSelections }));
  }

  createFilterOptions () {
    const { policySchemeConfigurations } = this.props;
    if (!policySchemeConfigurations) return {};
    const filter = createFilterOptionsByPolicyScheme(policySchemeConfigurations);
    const filterEntries = {
      contentFilters: [filter]
    };
    return filterEntries;
  }

  // if 'filterSelections' is set, only display those policy schemes
  reduceByApplyingFilter () {
    const { filterSelections } = this.state;
    const { policySchemeConfigurations } = this.props;
    let policySchemes = [];

    if (filterSelections.length === 0 || filterSelections[0].items.length === 0) {
      policySchemes = [...policySchemeConfigurations];
    } else {
      const compareIfPolicySchemeExists = (policySchemeId) => filterSelections[0].items.some((ch) => ch.key === policySchemeId);
      policySchemes = policySchemeConfigurations.filter((psc, index) => compareIfPolicySchemeExists(psc.policyScheme.id));
    }
    return policySchemes;
  }

  async refreshPolicyScheme() {
    document.title = "Policy Library";
    const { policySchemeTypeId } = this.state;
    await this.props.fetchPolicyLibraryMasters();
    await this.props.fetchPolicySchemeConfigurations(policySchemeTypeId);

    if (this.props.policyLibraryData && this.props.policyLibraryData.policyLibraryCategory) {
      const policySchemes = this.findPolicyCategoryById(policySchemeTypeId);
      if (policySchemes && policySchemes.length > 0) {
        this.setState((prevState) => ({...prevState, policyScheme: policySchemes[0] }));
      }
    }
  }

  handleAddPolicySchemeClose = () => {
    this.setState({ showAddPolicyScheme: false });
    this.refreshPolicyScheme();
  };

  displaySelection () {
    const { filterSelections } = this.state;
    return Array.isArray(filterSelections) && filterSelections.length > 0;
  }

  /* Render */

  render () {
    const { classes, policySchemeConfigurations } = this.props;
    const { filterType } = this.state.filterLiterals;
    const {
      toggleFilterCallout,
      filterOptionsKey,
      filterSelections,
      policySchemeTypeId
    } = this.state;

    return (
      <Box m={1}>
        { this.buildPolicyLibraryDetails() }
        <Card
          className={classes.cardLibrary}
          variant="outlined"
          style={{ width: "95%" }}
        >
          <div className={classes.grid1}>
            <div className={classes.cardHeader}>Policy Library</div>
            <div className={classes.grid5}>
              <div>
                <Toggle defaultChecked disabled={isReadOnly} />
              </div>
              <div className={classes.toggleRuntimePolicy}>
                Toggle Policy Runtime
              </div>
            </div>
          </div>
          <div className={classes.grid1}>
            <div id="FilterCallout" className={classes.filtersButton} onClick={this.toggleFilterCalloutHandler}>
              Filters
            </div>
            { policySchemeConfigurations ? <FilterCallout openDialog={toggleFilterCallout} toggleFilterCalloutHandler={this.toggleFilterCalloutHandler} key={filterOptionsKey} filterType={filterType} filterOptions={this.createFilterOptions()} applyFilterSelectionsHandler={this.applyFilterSelectionsHandler} initialSelections={filterSelections} showSelectedItems={false} ></FilterCallout>
              : null }
            <div className={classes.grid4}>
              <div className={classes.searchText}>
                <TextField id="SearchTxt" placeholder="Enter Search Term" />
              </div>
              <div className={classes.searchmagnifyButton}>
                Search
                <Icon
                  iconName="Search"
                  className={classes.iconStyle}
                  style={{ color: "#252423" }}
                />
              </div>
            </div>
          </div>
          <hr className={classes.sepLine} />
          <Grid item xs={12} sm container>
            { this.displaySelection()
              ? <div>
                {filterSelections[0].items.map((item) => (
                  <Button
                    className={classes.filterButtonLabel}
                    name="selectedTypeValuesBtn"
                  >
                    {item.value}
                    <ClearIcon
                      className={classes.buttonClearIcon}
                      onClick={this.handleSelectedTypeToggle(item)}
                    ></ClearIcon>
                  </Button>
                ))}
              </div>
              : null }
          </Grid>
          <div className={classes.grid6}>
            <Link
              to={`/policyLibraryMasterConsole/editPolicy/${policySchemeTypeId}`}
              style={{ textDecoration: "none" }}
            >
              <div className={classes.editPolicy}>
                <Icon iconName="Edit" className={classes.iconStyle} />
                <span className={classes.editPolicyText}>Edit Policy</span>
              </div>
            </Link>
            <div className={classes.grid7}>
              <div style={{ paddingLeft: "3em" }}>
                <Icon
                  iconName="ChevronLeftMed"
                  className={classes.iconStyle}
                  onClick={() => this.slide("left")}
                />
              </div>
              <div>
                <Icon
                  iconName="ChevronRightMed"
                  className={classes.iconStyle}
                  onClick={() => this.slide("right")}
                />
              </div>
            </div>
          </div>
          <CardContent>
            <div>
              <div id="container" className={classes.scrollablePaneWrapper}>
                {policySchemeConfigurations ? (
                  <PolicyLibraryScheme
                    schemes={this.reduceByApplyingFilter()}
                  />
                ) : null}
              </div>
            </div>
          </CardContent>
        </Card>
        {
          this.state.showAddPolicyScheme ? <CreatePolicyScheme isReadOnly={isReadOnly} policySchemeData= {this.props.policyLibraryData} policySchemeTypeId={this.state.policySchemeTypeId} hideAddPolicySchemeHandler={this.handleAddPolicySchemeClose}
          /> : null
        }
      </Box>
    );
  }
}

const styles = (theme) => ({

    scrollablePaneWrapper: {
        height: '100vh',
        backgroundColor: 'white',
        overflowX: 'scroll',
        wordBreak: 'initial',
        display: 'flex',
    },

    horizontalWrapper: {
        float: 'left',
    },
    pmHeader: {
      fontSize: '24px',
      fontWeight: '700',
      fontFamily: 'PharmaCond',
      color: '#323130'
    },
    headerSection: {
      padding: '5px 0px 0px 5px'
    },
    btnHeaderSection: {
      marginRight: '25px',
      width: '150px'
    },
    grid1: {
        width: '100%',
        display: 'grid',
        margin: '0 auto',
        gridTemplateColumns: '9fr 1fr',
        gridGap: '20px',
        padding: '10px 15px 0 15px',
    },

    cardHeader: {
        fontFamily: 'PharmaCond',
        fontSize: '24px',
        color: '#323130',
        fontWeight: '600',
    },

    header1: {
        margin: 0,
        backgroundColor: 'white',
    },

    cardHeaderSection: {
        padding: '16px 0 0 16px',
    },

    cardPolicy: {
        borderColor: blue[200],
        width: 200,
        height: 250,
        color: '#000',
        margin: '20px 20px 20px 30px'
    },

    cardLibrary: {
        borderColor: blue[200],
        width: 200,
        height: 1200,
        color: '#000',
        margin: '20px 20px 20px 30px'
    },

    statsPolicySegments: {
        width: '500px',
        height: '20px',
        margin: '20px 0px 30px 0px',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#555555',
    },

    subtitle: {
        width: '700px',
        height: '19px',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#323130',
    },

    fieldRow: {
        padding: '8px 0',
    },

    sepLine: {
        margin: '15px 15px 0 15px',
        color: '#bdbdbd'
    },

    editButtonBlock: {
        fontFamily: 'PharmaCond',
        backgroundColor: '#0078d4',
        width: '138px',
        height: '32px',
        margin: '0 50px 0 0',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '300',
        cursor: 'pointer',
        borderRadius: '2',
    },

    editButton: {
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        color: '#ffffff',
        padding: '20px 0 0 0',
    },

    grid2: {
        display: 'grid',
        width: '100%',
        margin: '0 auto',
        gridTemplateColumns: '7fr 3fr',
        gridGap: '20px',
        padding: '16px 0 0 16px',
    },

    policySchemesInUse: {
        width: '200px',
        height: '20px',
        margin: '20px 0 0 0px',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#323130',
    },

    grid3: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 9fr',
    },

    divider1: {
        height: '160px',
        width: '1px',
        border: 'solid 1px #cdcdcd',
        backgroundColor: '#f3f2f1',
    },

    toggleRuntimePolicy: {
        fontFamily: 'SegoeUI',
        width: '138px',
        height: '32px',
        margin: '0 50px 0 0',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '300',
        cursor: 'pointer',
        borderRadius: '2',
    },

    filtersButton: {
        width: '40px',
        height: '18px',
        padding: '5px 19px 7px',
        borderRadius: '2px',
        border: 'solid 1px #8a8886',
        backgroundColor: '#ffffff',
    },

    grid4: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr',
        padding: '0px 30px 0px 0px',
    },

    searchText: {
        width: '200px',
        height: '20px',
    },

    searchmagnifyButton: {
        width: '70px',
        height: '18px',
        padding: '5px 10px 7px 10px',
        border: 'solid 1px #8a8886',
    },

    filterPolicy: {
        width: '50px',
        height: '18px',
        padding: '5px 19px 7px',
        borderRadius: '2px',
        border: 'solid 1px #8a8886',
        backgroundColor: '#f3f2f1',
    },

    grid5: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr',
    },

    grid6: {
        width: '100%',
        display: 'grid',
        margin: '0 auto',
        gridTemplateColumns: '9fr 1fr',
        gridGap: '20px',
        padding: '10px 15px 0 15px',
    },

    grid7: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },

    editPolicy: {
        width: '90px',
        height: '18px',
        padding: '5px 15px 10px 15px',
        borderRadius: '2px',
        border: 'solid 1px #0078d4',
        backgroundColor: '#fffff',
    },

    iconStyle: {
        width: '20px',
        height: '18px',
        // margin: '24px 0 16px 39px',
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0078d4',
    },

    editPolicyText: {
        width: '64px',
        height: '18px',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#006ed2',
        display: 'inline-block'
    },

    buttonStyle: {
        padding: '7px 19px',
        margin: '0 16px',
        backgroundColor: '#fff',
        color: '#323130',
        borderRadius: '2px',
        border: 'solid 1px #8a8886',
        cursor: 'pointer'
    },
    dialog: {
        position: 'absolute',
        left: 0,
        top: 0
    },
    filterButtonLabel: {
        textTransform: 'capitalize',
        backgroundColor: '#EAEAEA',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: 'auto',
        marginRight: '1rem'
    },
});

PolicyLibrarySchemeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  policyLibraryData: state.policyLibraryMasterData.policyLibraryMasters,
  policySchemeConfigurations: state.policyLibraryBuilderData.policySchemeConfigurations,
});

const connected = connect(mapStateToProps, {
  fetchPolicyLibraryMasters,
  fetchPolicySchemeConfigurations,
})(AuthProvider(PolicyLibrarySchemeDetails));

export default withStyles(styles)(connected);
