import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import SearchBar from './shared/SearchBar';
import { msalApp, signOut } from './auth/AuthUtils';

const logo = require('../images/Oli-icon.svg');
const ham = require('../images/ham.png');

// eslint-disable-next-line import/prefer-default-export
export const Header = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let { pathname } = location;
  if (location.pathname.indexOf('taskbuilder') > 0) {
    pathname = `${location.pathname.substring(0, pathname.lastIndexOf('/'))}/:id`;
  } else if (location.pathname.indexOf('policyLibraryMasterConsole/library') > 0) {
    pathname = `${location.pathname.substring(0, pathname.lastIndexOf('/'))}/:policySchemeTypeId`;
  } else if (location.pathname.indexOf('policyLibraryMasterConsole/editPolicy') > 0) {
    pathname = `${location.pathname.substring(0, pathname.lastIndexOf('/'))}/:policySchemeTypeId`;
  } else if (location.pathname.indexOf('notificationLibraryConsole/policybuilder') > 0) {
    pathname = `${location.pathname.substring(0, pathname.lastIndexOf('/'))}/:notificationCategoryId`;
  } else if (location.pathname.indexOf('adminChangeConsole/changeSetBuilder') > 0) {
    pathname = `${location.pathname.substring(0, pathname.lastIndexOf('/'))}/:changeSetId`;
  } else if (location.pathname.indexOf('referenceDataConsole/guidanceBuilder') > 0) {
    pathname = `${location.pathname.substring(0, pathname.lastIndexOf('/'))}/:guidanceFragmentId`;
  }

  const res = props.path.filter((x) => x.path === pathname);

  return (
    <div className="Rectangle">
      <Box display="flex">
        <Box>
          <img className="img-s1" src={ham} onClick={handleClick} alt='' />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem disabled={false} component={Link} to="/secure" refresh="true" onClick={handleClose}>Home</MenuItem>
            <MenuItem component={Link} to="/referenceDataConsole" refresh="true" onClick={handleClose}>Reference Data</MenuItem>
            <MenuItem component={Link} to="/checkPointMasterConsole" refresh="true" onClick={handleClose}>Checkpoint Master</MenuItem>
            <MenuItem component={Link} to="/taskLibraryMasterConsole" refresh="true" onClick={handleClose}>Task Master</MenuItem>
            <MenuItem component={Link} to="/policyLibraryMasterConsole" refresh="true" onClick={handleClose}>Policy Library Master</MenuItem>
            <MenuItem component={Link} to="/notificationLibraryConsole" refresh="true" onClick={handleClose}>Notification Library</MenuItem>
            <MenuItem component={Link} to="/validationConsole" refresh="true" onClick={handleClose}>Validation</MenuItem>
            <MenuItem component={Link} to="/adminChangeConsole" refresh="true" onClick={handleClose}> Admin Change Console</MenuItem>
          </Menu>
        </Box>
        <div className="OLi-Admin-Portal">
          OLi Admin Portal
        </div>
        <div className="Admin-Master">
          {pathname !== '/' ? `/${res[0].parent}` : ''}
        </div>
        <div className="AdminMasterSearchContainer">
          <SearchBar placeholderText="Search for masters" handleSearch={() => { }} />
        </div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="Admin-btn-o" > <img className="img-s" src={logo} alt='' />
          Ask Oli
        </Button>
        {msalApp.getAccount() ? <Button variant="contained" color="primary" size="small" onClick={signOut} className="Admin-btn" >
          Sign out
        </Button> : null}
      </Box>
    </div>
  );
};
