import React from "react";
import { Box } from '@material-ui/core';
import RefDataTypesList from "./RefDataTypesList";
import RefDataOperations from "./RefDataOperations";
import AuthProvider from "../../../components/auth/AuthProvider";

const ReferenceDataBuilderWrapper = (props) => (
  <>
    <Box display="flex">
      <RefDataTypesList />
      <Box flexGrow={4}>
        <RefDataOperations />
      </Box>
    </Box>
  </>
);

export default AuthProvider(ReferenceDataBuilderWrapper);
