import _ from 'lodash';
import { FETCH_PEP, FETCH_PEPS } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  peps: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PEP:
      return {
        ...state,
        peps: { ...state.peps, [action.payload.id]: action.payload },
        error: null
      };
    case FETCH_PEPS:
      return {
        ...state,
        peps: { ..._.mapKeys(action.payload, 'id') },
        error: null
      };
    default:
      return state;
  }
};
