import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
    Checkbox, DefaultButton, IconButton, initializeIcons, Label, PrimaryButton, Text, TextField
} from '@fluentui/react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';

import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../../components/auth/AuthProvider';
import { updateChangeSet } from '../../../state/actions/AdminChangeSetBuilderActions';
import './override.css';
import renderHTMLContent from '../../../util/ConvertUtil';

const APIKeys = {
    EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

class ManageChangeSetDetails extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            changeSet: null,
            isCurrentlyInSelectionMode: true, // 2 modes, Editor (true) and Confirmation (false)
            isSubmitInProgress: false,
            withSubmitError: false,
            editorCount: 0,
        };
        initializeIcons();
    }

    async componentDidMount () {
        document.title = DialogTitle;
        this.prepareFormContent();
    }

    /* Functions used to build section of page */

    buildChangeSetDetailsEditor () {
        if (!this.state.changeSet) return null;

        const { classes, isReadOnly } = this.props;
        const {
            status, name, description, finalised, releaseTagName, verified, verificationComment, verificationCommentsRequired
        } = this.state.changeSet;
        const changeSetDescLeft = description ? (500 - description.length) : 0;
        const tobeDisabled = isReadOnly || status !== 'OPEN';
        const markAsDoneDisabled = isReadOnly || (status === 'OPEN' || status === 'COMPLETED');
        return (
            <>
                <Stack horizontal tokens={stackTokens} className={classes.actions}>
                    <DefaultButton text="Cancel" onClick={this.onDismissDialog} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
                    <PrimaryButton text="Save" onClick={this.updateChangeSet} disabled={this.isNotAllowedToUpdate()} />
                </Stack>
                <Separator></Separator>
                <Stack>
                    <div className={classes.sectionTitle}>CHANGE SET DETAILS</div>
                </Stack>
                <Stack className={classes.inputArea}>
                    <TextField label="Change Set Name" value={name} onChange={this.onChangeSetName} maxLength={500} disabled={tobeDisabled} />
                </Stack>
                <Stack tokens={stackTokens}>
                    <Stack className={classes.inputArea}>
                        <TextField label="Change Set Description" value={description} onChange={this.onChangeSetDesc} multiline={true} style={{ height: '110px' }} maxLength={500} disabled={tobeDisabled} />
                        <Text style={{ fontSize: '12px' }}>You have {changeSetDescLeft} characters left</Text>
                    </Stack>
                </Stack>
                <Stack className={classes.inputArea}>
                    <Label htmlFor={'finalised'}>Finalise Change Set</Label>
                    <Checkbox id={'finalised'} label="Mark as done" checked={finalised} onChange={() => this.toggleMarkAsDone(finalised)} disabled={markAsDoneDisabled} />
                </Stack>
                <Stack className={classes.inputArea}>
                    <TextField label="Release Name" value={releaseTagName} onChange={this.onChangeReleaseName} disabled={tobeDisabled} />
                </Stack>
                <Stack className={classes.inputArea}>
                    <Label htmlFor={'verified'}>Change Set Verification for Release</Label>
                    <Checkbox id={'verified'} label="Change Set Verified" checked={verified} onChange={() => this.toggleChangeSetVerified(verified)} disabled={tobeDisabled} />
                    <Stack horizontal>
                        {verificationCommentsRequired ? this.createValidationStatus('Please add Verification comments below.', false) : null}
                    </Stack>
                </Stack>
                <Stack tokens={stackTokens}>
                    <Stack className={classes.inputArea}>
                        {/* <TextField label="Change Set Verification for Release" value={verificationComment} onChange={this.onChangeSetComments} multiline={true} style={{ height: '110px' }} maxLength={500} disabled={tobeDisabled} /> */}
                        <Label htmlFor={'VerificationComment'}>Change Set Verification Comments</Label>
                        <Stack.Item id={'VerificationComment'} horizontal className={classes.testResultComments}>
                            {!isReadOnly ? <Editor
                            apiKey={APIKeys.EditorKey}
                            value={verificationComment}
                            init={{
                                branding: false,
                                height: 300,
                                browser_spellcheck: true,
                                content_style: 'body { color:#605e5c; }',
                                selector: 'textarea',
                                allow_unsafe_link_target: true,
                                menubar: 'insert',
                                element_format: 'xhtml',
                                plugins: [
                                'wordcount advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help'
                                ],
                                paste_preprocess (plugin, args) {
                                },
                                toolbar:
                                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
                                init_instance_callback: (editor) => {
                                this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                                }
                            }}
                            onEditorChange={(content, editor) => this.onChangeSetCommentsRich(content, editor)}
                            // onEditorChange={(content, editor) => {
                            //     if ((content !== verificationComment) && ReactHtmlParser(content) !== ReactHtmlParser(verificationComment)) {
                            //     this.setState({
                            //         verificationComment: content,
                            //         editorCount: editor.plugins.wordcount.body.getCharacterCount()
                            //     });
                            //     }
                            // }}
                            onBlur={(event, editor) => {
                                this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                            }}
                            /> : renderHTMLContent(verificationComment)}
                        </Stack.Item>
                    </Stack>
                </Stack>
            </>
        );
    }

    buildConfirmationScreen () {
        const { classes } = this.props;
        return (
            <div className={classes.card} style={{ textAlign: 'center' }} >
                <Stack>
                    <div className={classes.todo}><img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /></div>
                </Stack>
                <Stack>
                    <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>Success</div>
                </Stack>
                <Stack>
                    <div className={classes.todo}>Change set details has been updated</div>
                </Stack>
                <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
                    <DefaultButton text="Close" onClick={this.onDismissDialog} onRenderText={(p) => this.onRenderButtonText(p, 'Close')} />
                </Stack>
            </div>
        );
    }

    /* Event and Action Handlers  */

    onDismissDialog = () => {
        this.props.onClose();
    }

    onChangeSetName = (event) => {
        const newVal = (event.target.value ? event.target.value : '');
        const val = this.state.changeSet;
        val.name = newVal;
        val.nameIsValid = this.validateChangeSetName(newVal.trim());
        this.setState((prevState) => ({ ...prevState, changeSet: val }));
    }

    onChangeSetDesc = (event) => {
        const newVal = (event.target.value ? event.target.value : '');
        const val = this.state.changeSet;
        val.description = newVal;
        this.setState((prevState) => ({ ...prevState, changeSet: val }));
    }

    toggleMarkAsDone = (finalised) => {
        const val = this.state.changeSet;
        val.finalised = !finalised;
        this.setState((prevState) => ({ ...prevState, changeSet: val }));
    }

    onChangeReleaseName = (event) => {
        const newVal = (event.target.value ? event.target.value : '');
        const val = this.state.changeSet;
        val.releaseTagName = newVal;
        val.releaseTagNameIsValid = this.validateReleaseName(newVal.trim());
        this.setState((prevState) => ({ ...prevState, changeSet: val }));
    }

    toggleChangeSetVerified = (verified) => {
        const val = this.state.changeSet;
        val.verified = !verified;
        val.verificationIsValid = this.validateVerification(val.verified, val.verificationComment);
        val.verificationCommentsRequired = val.verified && this.isBlank(val.verificationComment);
        this.setState((prevState) => ({ ...prevState, changeSet: val }));
    }

    onChangeSetComments = (event) => {
        const newVal = (event.target.value ? event.target.value : '');
        const val = this.state.changeSet;
        val.verificationComment = newVal;
        val.verificationCommentsRequired = val.verified && this.isBlank(val.verificationComment);
        this.setState((prevState) => ({ ...prevState, changeSet: val }));
    }

    onChangeSetCommentsRich = (content, editor) => {
        const newVal = content;
        const val = this.state.changeSet;
        val.verificationComment = newVal;
        val.verificationCommentsRequired = val.verified && this.isBlank(val.verificationComment);
        this.setState((prevState) => ({ ...prevState, changeSet: val, editorCount: editor.plugins.wordcount.body.getCharacterCount() }));
    }
                            // onEditorChange={(content, editor) => {
                            //     if ((content !== verificationComment) && ReactHtmlParser(content) !== ReactHtmlParser(verificationComment)) {
                            //     this.setState({
                            //         verificationComment: content,
                            //         editorCount: editor.plugins.wordcount.body.getCharacterCount()
                            //     });
                            //     }
                            // }}
    onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

    /* Validation handler */

    // TODO
    validateChangeSetName (name) {
        let valid = true;
        return valid;
    }

    // TODO
    validateReleaseName (releaseTagName) {
        let valid = true;
        return valid;
    }

    validateVerification (verified, comments) {
        let valid = true;
        if (verified) {
            if (comments && comments.trim() === "") {
                valid = false;
            }
        }
        return valid;
    }

    /* Processor (Edit/Save/Delete) */

    updateChangeSet = async () => {
        const {
            id, name, description, finalised, releaseTagName, verified, verificationComment
        } = this.state.changeSet;
        const updatedChangeSet = {
            name,
            description,
            finalised,
            releaseTagName,
            verified,
            verificationComment
        };

        await this.props.updateChangeSet(id, updatedChangeSet);
        this.setState((prevState) => ({ ...prevState, isCurrentlyInSelectionMode: false, isSubmitInProgress: false, withSubmitError: false }));
    }

    /* Helper Functions */

    async prepareFormContent () {
        const {
            id, name, description, finalised, releaseTagName, verified, verificationComment, status
        } = this.props.changeSet;
        const changeSet = {
            id,
            name,
            nameIsValid: true,
            description,
            finalised,
            releaseTagName,
            releaseTagNameIsValid: true,
            verified,
            verificationIsValid: false,
            verificationCommentsRequired: false,
            verificationComment,
            status
        };
        this.setState((prevState) => ({ ...prevState, changeSet }));
    }

    createValidationStatus (message, status) {
        const { classes } = this.props;
        return (<div className={classes.statusErrorFull}>
                <IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} />
            <span style={{ fontSize: '12px' }}>{message}</span>
            </div>);
    }

    isNotAllowedToUpdate () {
        const { status, verificationCommentsRequired } = this.state.changeSet;
        const { isReadOnly } = this.props;
        const byStatus = status === 'COMPLETED';
        const isNotAllowedToUpdate = isReadOnly || byStatus || verificationCommentsRequired;
        return isNotAllowedToUpdate;
    }

    isBlank (str) {
        return (!str || /^\s*$/.test(str));
    }
    /* Render */

    render () {
        // eslint-disable-next-line no-return-assign
        return (
            <Panel
                isOpen={this.props.open}
                onDismiss={this.onDismissDialog}
                type={PanelType.custom}
                customWidth={'650px'}
                closeButtonAriaLabel="Close"
                headerText={DialogTitle}
            >
                <Separator></Separator>
                {this.state.isCurrentlyInSelectionMode
                ? this.buildChangeSetDetailsEditor()
                : this.buildConfirmationScreen()
                }
            </Panel>
        );
    }
}

const OliLogo = require('../../../images/oli-success.svg');

const stackTokens = { childrenGap: 40 };

const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: '#f3f2f1'
    },
    root: {
        flexGrow: 1
    },
    title: {
        padding: '60px 0 60px 0',
        color: '#605e5c',
        backgroundColor: '#fff',
    },
    inputArea: {
        padding: '0 10px 10px 0',
        borderRadius: '2px',
    },

    instruction: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '16px 0 0 0'
    },
    catStyle: {
        padding: '0 0 0 0'
    },
    basicStyles: {
        maxWidth: '600',
        border: '0px solid  #dcdcdc',
        margin: '0'
    },
    tagPickerClass: {
        inlineSize: '450px',
        overflowWrap: 'break-word',
    },
    compositeTextStyle: {
        fontSize: '16px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 0 0'
    },
    compositeTextStyle2: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
    },
    sectionTitle: { // used
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 15px 0',
    },
    selectStyle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '0 0 5px 0',
    },
    actions: {
        padding: '20px 0 20px 0'
    },
    selectedRuleBlock: {
        padding: '20px 0 0 0'
    },
    previewPart: {
        backgroundColor: '#f6f6f6'
    },
    stackItemInfo: {
        alignItems: 'center',
        display: 'block',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    stackItemDelete: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        maxWidth: 80,
        minWidth: 80,
        borderLeft: '2px solid lightgrey',
    },
    whiteBtn: {
        backgroundColor: 'transparent',
        border: '0px',
        padding: '10px 0 10px 0'
    },
    card: { // used
        padding: '15px', /* JUST TO LOOK COOL */
        border: '1px solid #eee',  /* JUST TO LOOK COOL */
        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
        transition: 'all .3s ease-in-out',
    },
    withSubmitError: { // used
        color: 'red',
    },
    statusErrorFull: { // used
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25px'
    },
});

const DialogTitle = 'Edit Change Set Details';

ManageChangeSetDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
        updatePolicyMasterResult: state.policyLibraryMasterData.updatePolicyMasterResult,
    });

const connected = connect(mapStateToProps, {
    updateChangeSet
})(AuthProvider(ManageChangeSetDetails));

export default withStyles(styles)(connected);
