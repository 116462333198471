import api from '../../api/IntelligentAgentServiceApi';
import {
  FETCH_GUIDANCE_FRAGMENTS,
  FETCH_GUIDANCE_FRAGMENT_POLICY_CONFIG_SUMMARIES,
  UI_SHOW_MESSAGE_ERROR,
  UI_SHOW_MESSAGE_SUCCESS,
  FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG, FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG_FAILED
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchGuidanceFragments = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/guidance-fragments');
    dispatch({ type: FETCH_GUIDANCE_FRAGMENTS, payload: response.data.records });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const updateGuidanceFragment = (guidanceFragment) => async (dispatch) => {
  try {
    const apiObject = {
      name: guidanceFragment.name,
      text: guidanceFragment.text
    };
    if (guidanceFragment.archived !== null) {
      apiObject.archived = guidanceFragment.archived;
    }
    const url = `admin/guidance-fragments/${guidanceFragment.id}`;
    await api.put(url, apiObject);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Guidance master updated successfully' });
    dispatch(fetchGuidanceFragments());
  } catch (error) {
     if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Guidance master updation failure' });
    }
  }
};

export const createGuidanceFragment = (guidanceItem) => async (dispatch) => {
  try {
    const url = `/admin/guidance-fragments`;
    await api.post(url, guidanceItem);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'You have successfully created a new Guidance' });
    dispatch(fetchGuidanceFragmentPolicyConfigSummaries());
  } catch (error) {
     if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Guidance creation failure' });
    }
  }
};

export const fetchGuidanceFragmentPolicyConfigSummaries =  () => async (dispatch) => {
  try {
    const response = await api.get("/admin/guidance/guidance-fragments-policy-configuration-summaries");
    dispatch({ type: FETCH_GUIDANCE_FRAGMENT_POLICY_CONFIG_SUMMARIES, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchSingleGuidanceFragmentPolicyConfig =  (guidanceFragmentId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/guidance/guidance-fragment-policy-configuration/${guidanceFragmentId}`);
    dispatch({ type: FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG_FAILED });
      dispatch(signOut());
    }
  }
};
