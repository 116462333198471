import * as React from "react";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    loadingSpinner: {
        fontFamily: 'SegoeUI',
    }
});

export default function LoadingSpinner (props) {
    const classes = useStyles();
    return (
        <div className={classes.loadingSpinner} >
            <div>
              <Spinner label={props.loadingText} ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} />
            </div>
        </div >
    );
}
