/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { Box, Card, CardHeader, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import FilterAndSearchPageHeader from "../shared/FilterAndSearchPageHeader";
import ChangeCategoryList from "./ChangeCategoryList";
import fetchChangeSetList from "../../../state/actions/AdminChangeSetManagementActions";
import AuthProvider from "../../../components/auth/AuthProvider";
import { createFilterOptionsForChangeSetManagement } from "../shared/FilterUtil";

// eslint-disable-next-line react/react-in-jsx-scope
const ChangeSetManagementWrapper = (props) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { classes } = props;

  const adminChangeSetSummaries = useSelector(
    (state) => state.changSetsSummariesData.adminChangeSetSummaries
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchChangeSetList());
  }, [dispatch]);

  const adminChangeCategories = adminChangeSetSummaries.data?.changeCategories;
  useEffect(() => {
    if (adminChangeCategories) {
      setFilterOptions(createFilterOptionsForChangeSetManagement(adminChangeCategories));
    }
  }, [adminChangeCategories]);

  const removeSingleFilter = (parentIndex, childrenIndex) => {
    selectedFilters[parentIndex].items.splice(childrenIndex, 1);
    setSelectedFilters([...selectedFilters]);
  };

  return (
    <Box display="flex">
      <Card variant="outlined" className={classes.rootBackground}>
        <CardHeader
          className={classes.cardHeaderSection}
          title={
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={5}>
                <Typography className={classes.cardHeader}>
                  Change Set Management
                </Typography>
              </Grid>
            </Grid>
          }
        />
        <FilterAndSearchPageHeader filterOptions={filterOptions} selectedFilters={selectedFilters}
          filterOnclickHandler={(filterSelections) => setSelectedFilters(filterSelections)}
          clearAllFilterHandler={() => setSelectedFilters([])}
          removeSingleFilterHandler={(parentIndex, childrenIndex) => removeSingleFilter(parentIndex, childrenIndex)}/>
        <div className={classes.changeSetManagementBody}>
          {adminChangeCategories && adminChangeCategories.length > 0
          && adminChangeCategories.map((changeCategoryItem, index) => (
              <ChangeCategoryList
                key={`changeCategory-${index}`}
                changeCategoryItem={changeCategoryItem}
                selectedFilterOptions={selectedFilters}
              />
            ))}
        </div>
      </Card>
    </Box>
  );
};

const styles = (theme) => ({
  rootBackground: {
    height: "100%",
    backgroundColor: "white",
    width: "100%",
    margin: "1rem",
    marginLeft: "5rem",
    marginRight: "5rem",
  },
  cardHeaderSection: {
    padding: "16px 0 0 0",
  },
  cardHeader: {
    fontSize: "27px",
    fontFamily: "PharmaCond",
    paddingLeft: "1rem",
    color: "#323130",
    fontWeight: "700",
    titleTypographyProps: { variant: "h1" },
    title: {
      titleTypographyProps: { variant: "h1" },
      fontSize: "12px",
    },
  },
  changeSetManagementBody: {
    marginBottom: "16px",
  },
});

ChangeSetManagementWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

ChangeSetManagementWrapper.defaultProps = {};

export default withStyles(styles)(AuthProvider(ChangeSetManagementWrapper));
