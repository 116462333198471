import { AdminPortalEnvironment, AdminPortalSites } from '../constants/Constants';

const getEnvironment = () => {
  const siteUrl = window.location.href;

  const foundItem = AdminPortalSites.find((item) => { return siteUrl.toLowerCase().includes(item.url.toLowerCase()); });

  return foundItem ? foundItem.name : "";
};

export default function getReadOnlyFlagPerEnv() {
  const env = getEnvironment();

  switch (env) {
    case AdminPortalEnvironment.SIT:
      return true;
    case AdminPortalEnvironment.UAT:
      return false;
    case AdminPortalEnvironment.PreProd:
      return true;
    case AdminPortalEnvironment.Prod:
      return true;
    default:
      return false;
  }
}
