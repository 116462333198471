/* eslint-disable object-curly-newline */
/* eslint-disable space-before-function-paren */
/* eslint-disable array-callback-return */
import React from 'react';
import { DefaultButton, IconButton, Separator } from '@fluentui/react';
import uuid from 'react-uuid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Grid from '@material-ui/core/Grid';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';

import RulePicker from '../../components/rules/rulePicker/RulePicker';
import RuleConfigValue from '../../components/rules/ruleConfigValue/RuleConfigValue';
import { ActionLiteralType } from '../../constants/ActionTypeObj';
import AuthProvider from '../../components/auth/AuthProvider';
import {
  fetchPolicySchemeConfiguration, fetchPolicySchemeConfigurations, setPolicyBuilderConfiguration, fetchPolicyLibraryMasters, fetchPolicySchemeSelectionScopeMappings
} from '../../state/actions/PolicyLibraryMasterActions';
import { fetchPolicyRuleVariablesByRule, removeRuleFromCompositeRule } from '../../state/actions/PolicyRuleActions';
import { fetchTaskMasters, fetchTaskMastersByCodes } from '../../state/actions/TaskMasterActions';
import { fetchReferenceData, referenceDataTypeSelected } from '../../state/actions/ReferenceDataActions';
import { fetchNotificationMasters } from '../../state/actions/NotificationMastersAction';
import DuplicatePolicy from './duplicate/DuplicatePolicy';
import MasterDetails from './masterDetails/MasterDetails';
import PdpDetails from './pdpDetails/PdpDetails';
import CompositeRuleDetails from './compositeRuleDetails/CompositeRuleDetails';
import FilterOptionTypeLiteral from '../../constants/FilterLiterals';
import Filterv2 from '../../components/filterv2/Filterv2';
import getReadOnlyFlagPerEnv from '../../util/EnvUtil';
import CreatePolicyScheme from './policySchemes/CreatePolicyScheme';

import '../../images/dot.png';
import '../../components/style.css';
import './policy.css';
import RulePickerTask from '../../components/rules/rulePickerTask/RulePickerTask';
import RuleConfigMultiValue from '../../components/rules/ruleConfigMultiValue/RuleConfigMultiValue';

const isReadOnly = getReadOnlyFlagPerEnv();

const FIRST_SLOT_IN_LIST = 0;

class PolicyBuilder extends React.Component {
  constructor(props) {
    super(props);
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === 'POLICY_BUILDER')[0];
    this.state = {
      filterLiterals,

      policySchemeConfigurationTypeId: this.props.match.params.policySchemeTypeId,
      policySchemeWithEntitlements: null,
      linkedPolicyLibraries: null,
      policySchemeId: null,

      // used by rule picker
      isRulePickerOpen: false,
      isRuleTaskPickerOpen: false,
      actionForRulePicker: null,

      selCompositePolicy: null,
      selExistingRuleIds: null,
      selRuleForEdit: null,
      selRuleForDeletion: null,
      selPolicyDecisionPoint: null,
      selPolicyCompositeRule: null,
      policyCompositeRule: null,

      isDuplicatePolicyModalOpen: false,
      isMasterDetailsPanelOpen: false,
      isPolicySchemePdpDetailsPanelOpen: false,
      showAddPolicyScheme: false,
      filterSelections: [],
      filterOptionsKey: uuid(),
      isPolicySchemeCompositeRuleDetailsPanelOpen: false,
      compositeRuleMode: '',
      selPdp: '',
      entitlementRuleId: '',
      pdpId: '',
      pepId: '',
      isCreateNewPdpPanelOpen: false
    };
  }

  /* Functions used to build section of page */

  async componentDidMount() {
    let initialPick = null;
    document.title = 'Policy Builder';
    await this.props.fetchReferenceData();
    await this.props.fetchTaskMasters();
    await this.props.fetchPolicySchemeSelectionScopeMappings();
    await this.props.fetchNotificationMasters();
    this.props.fetchPolicySchemeConfigurations(this.props.match.params.policySchemeTypeId);
    if (this.props.policySchemeConfigurations) {
      if (this.state.policySchemeId) {
        initialPick = this.state.policySchemeId;
      } else {
        initialPick = this.props.policySchemeConfigurations[FIRST_SLOT_IN_LIST].policyScheme.id;
      }

      const policySchemeId = initialPick;
      await this.props.fetchPolicySchemeConfiguration(policySchemeId); // fetch policy scheme
      await this.fetchTaskMasterForDisplay();

      if (this.props.policySchemeConfigurations?.length) {
        this.setState((prevState) => ({ ...prevState, policySchemeId }));
      }
    } else {
      this.setState({});
    }
  }

  /* Event and Action Handlers  */

  async handleListItemClickLP(policySchemeId) {
    this.props.fetchPolicySchemeConfiguration(policySchemeId);
    this.fetchTaskMasterForDisplay();
    this.setState((prevState) => ({ ...prevState, policySchemeId }));
  }

  async fetchTaskMasterForDisplay() {
    const { entitlementRules } = this.props;
    // get all task codes (unique)
    const selPolicy = entitlementRules?.map((policy, index) => policy);
    const selCompositePolicyRules = selPolicy?.map((policy) => policy.compositePolicyRules);
    const selABC = selCompositePolicyRules?.map((policy) => policy[0]);
    // const selRuleConfigValues = [];
    const selRuleConfigValues = new Set();
    selABC.forEach((policy) => {
      policy.policyRules.map((aa1) => {
        if (aa1.rule.ruleCode === "PDP.RULE.TASK_MASTER_CODES") {
          const values = aa1.compositePolicyRuleInstanceConfigurations[0].configurationValue.split(',').sort();
          values.forEach((v) => {
            if (v !== "NONE") {
              selRuleConfigValues.add(v);
            }
          });
        }
      });
    });
    await this.props.fetchTaskMastersByCodes(Array.from(selRuleConfigValues), true);
  }

  listPolicyLibraryLP = () => {
    const policySchemes = this.reduceByApplyingFilter();
    return policySchemes.map((ps, index) => {
      const isSelected = (this.state.policySchemeId === index);
      return (
        <ListItem
          key={ps.policyScheme.id}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(ps.policyScheme.id)}
          className={this.props.classes.pmItem}>
          <ListItemText
            primary={<Typography type="body2" className={this.props.classes.pmItemText}>{ps.policyScheme.name}</Typography>}
            disableTypography
          />
        </ListItem>);
    });
  }

  toggleRulePickerPanelForAdd = (selCompositePolicy) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: true, selCompositePolicy, selExistingRuleIds, actionForRulePicker: 'add'
    }));
  }

  toggleRulePickerPanelForEdit = (selCompositePolicy, selRuleForEdit) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);
    let rulePickerOpen = false;
    let ruleTaskPickerOpen = false;
    if (selRuleForEdit.rule.ruleCode !== 'PDP.RULE.TASK_MASTER_CODES') {
      rulePickerOpen = true;
      ruleTaskPickerOpen = false;
    } else {
      rulePickerOpen = false;
      ruleTaskPickerOpen = true;
    }
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: rulePickerOpen, isRuleTaskPickerOpen: ruleTaskPickerOpen, selCompositePolicy, selExistingRuleIds, selRuleForEdit, actionForRulePicker: 'edit', selRuleForDeletion: selRuleForEdit
    }));
  }

  dismissRulePickerPanelHandler = () => {
    this.refreshSelCompositeRulesHandler();
    this.setState((prevState) => ({ ...prevState, isRulePickerOpen: false, isRuleTaskPickerOpen: false }));
  }

  openDeleteModal = (selCompositePolicy, rRule) => {
    this.setState((prevState) => ({ ...prevState, selCompositePolicy, selRuleForDeletion: rRule }));
  }

  closeDeleteModal = () => {
    this.setState((prevState) => ({ ...prevState, selCompositePolicy: null, selRuleForDeletion: null }));
  }

  handleDeleteRuleFromComposite = async () => {
    // build rule object
    const rule = this.adoptSelectionToRule();
    await this.props.removeRuleFromCompositeRule(this.state.selCompositePolicy.id, rule);
    this.refreshSelCompositeRulesHandler();
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: false, selCompositePolicy: null, selRuleForDeletion: null
    }));
  }

  toggleDuplicatePolicyModal = () => {
    const { policyScheme, entitlementRules } = this.props;
    const policySchemeWithEntitlements = { policyScheme, entitlementRules };
    this.setState((prevState) => ({ ...prevState, isDuplicatePolicyModalOpen: true, policySchemeWithEntitlements }));
  }

  hideDuplicatePolicyPanelHandler = () => {
    this.refreshPolicyScheme();
    this.setState((prevState) => ({ ...prevState, isDuplicatePolicyModalOpen: false }));
  }

  viewNewPolicySchemeHandler = (newPolicySchemeId) => {
    this.refreshToDisplayNewPolicyScheme(newPolicySchemeId);
    this.setState((prevState) => ({ ...prevState, isDuplicatePolicyModalOpen: false }));
  }

  toggleMasterDetails = () => {
    const { policyScheme, entitlementRules, linkedPolicyLibraries, linkedResources } = this.props;
    const policySchemeWithEntitlements = { policyScheme, entitlementRules, linkedPolicyLibraries, linkedResources };
    this.setState((prevState) => ({ ...prevState, isMasterDetailsPanelOpen: true, policySchemeWithEntitlements }));
  }

  hideMasterDetailsPanelHandler = () => {
    this.refreshPolicyScheme();
    this.setState((prevState) => ({ ...prevState, isMasterDetailsPanelOpen: false }));
  }

  togglePolicySchemePdpDetails = (selPolicyDecisionPoint, policyEntitlementOperation) => {
    // eslint-disable-next-line no-param-reassign
    selPolicyDecisionPoint.policyEntitlementOperation = policyEntitlementOperation;
    this.setState((prevState) => ({ ...prevState, isPolicySchemePdpDetailsPanelOpen: true, selPolicyDecisionPoint, isCreateNewPdpPanelOpen: false }));
  }

  togglePolicySchemeCompositeRuleDetails = (policy, mode, pol) => {
    this.setState((prevState) => ({
      ...prevState, isPolicySchemeCompositeRuleDetailsPanelOpen: true, policyCompositeRule: policy.compositePolicyRules, entitlementRuleId: policy.id, pdpId: policy.policyDecisionPoint.id, pepId: policy.policyEnforcementPoint.id, compositeRuleMode: mode, selPolicyCompositeRule: pol
    }));
  }

  toggleCreateNewPDPPanel = () => {
    this.setState({ isCreateNewPdpPanelOpen: true });
  }

  hidePolicySchemePdpDetailsPanelHandler = () => {
    this.refreshPolicyScheme();
    this.setState((prevState) => ({ ...prevState, isPolicySchemePdpDetailsPanelOpen: false, isCreateNewPdpPanelOpen: false }));
  }

  hidePolicySchemeCompositeRuleDetailsPanelHandler = () => {
    this.refreshPolicyScheme();
    this.setState((prevState) => ({ ...prevState, isPolicySchemeCompositeRuleDetailsPanelOpen: false }));
  }

  handleAddPolicySchemeClose = () => {
    this.setState({ showAddPolicyScheme: false });
    this.refreshPolicyScheme();
  }

  handleEditPDPDetailsAfterCreation = (newPdp) => {
    this.refreshPolicyScheme();
    this.togglePolicySchemePdpDetails(newPdp, newPdp.policyEntitlementOperation);
  }

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  /* Helper Functions */

  adoptSelectionToRule() {
    const rule = {
      ruleId: this.state.selRuleForDeletion.rule.id,
    };

    return rule;
  }

  async refreshSelCompositeRulesHandler() {
    const { policySchemeId } = this.state;
    await this.props.fetchPolicySchemeConfigurations(this.props.match.params.policySchemeTypeId); // fetch policy configurations
    await this.props.fetchPolicySchemeConfiguration(policySchemeId); // fetch policy scheme
  }

  async refreshPolicyScheme() {
    const { policySchemeId } = this.state;
    await this.props.fetchPolicySchemeConfiguration(policySchemeId); // fetch policy scheme
    this.handleListItemClickLP(policySchemeId);
    this.refreshSelCompositeRulesHandler();
  }

  async refreshToDisplayNewPolicyScheme(newPolicySchemeId) {
    this.setState((prevState) => ({ ...prevState, policySchemeId: newPolicySchemeId }));
    await this.props.fetchPolicySchemeConfiguration(newPolicySchemeId); // fetch policy scheme
    this.handleListItemClickLP(newPolicySchemeId);
    this.refreshSelCompositeRulesHandler();
  }

  static withCompleteRuleConfiguration(rule) {
    const { compositePolicyRuleInstanceConfigurations } = rule;
    return compositePolicyRuleInstanceConfigurations?.length;
  }

  static displayLinkedLibrary(classes, linkedPolicyLibraries) {
    if (linkedPolicyLibraries?.length) {
      return (<>
        {linkedPolicyLibraries.map((library, index) => <span className={classes.linkedLibrary}>Policy: <span style={{ color: '#006ed2' }}>{library.policySchemeType}</span></span>)}
      </>);
    }
    return <></>;
  }

  displayLinkedResources() {
    const { classes, linkedResources, policySchemeSelectionScopeMappings } = this.props;
    const { policySchemeId } = this.state;
    const display = [];

    if (linkedResources?.length && policySchemeId) {
      // const lbCol = linkedResources.filter((node) => node.policySchemeId === policySchemeId);
      if (linkedResources?.length) {
        // const sel = lbCol[0].id;
        let selectionScopes = [];
        if (linkedResources?.length) {
          const tmp = linkedResources[0];
          if (tmp?.resourceType?.startsWith('NOTIFICATION')) {
            const refds1 = policySchemeSelectionScopeMappings.filter((node) => node.selectionScope === tmp.selectionScope && node.resourceType === 'NOTIFICATION');
            selectionScopes = [...new Set(refds1.map((item) => item.id))];
          } else {
            const refds1 = policySchemeSelectionScopeMappings.filter((node) => node.selectionScope === tmp.selectionScope && node.resourceType === tmp.resourceType);
            selectionScopes = [...new Set(refds1.map((item) => item.id))];
          }
        }
        const sel = selectionScopes?.length ? selectionScopes[0] : "";
        const found = policySchemeSelectionScopeMappings.filter((node) => node.id === sel);
        if (found?.length) {
          const refds = this.resolveRefData(found[0].resourceType);
          // const refds = refdata.filter((node) => node.type === lbCol[0].resourceType && node.enabled);
          // refds.sort((a, b) => a.sortOrder - b.sortOrder);
          refds.forEach((ref) => {
            const foundScheme = linkedResources.filter((node) => node.policySchemeId === policySchemeId && node.resourceId === ref.id);
            if (foundScheme?.length) {
              display.push(ref.value);
            }
          });
        }
      }
    }

    if (display?.length) {
      return (<>
        {display.map((resource, index) => <span className={classes.linkedLibrary}><span style={{ color: '#006ed2' }}>{resource}</span></span>)}
      </>);
    }
    return <></>;
  }

  displayPolicySchemeStatus() {
    const { linkedResources } = this.props;
    let status = "";
    if (linkedResources?.length) {
      status = 'Active';
    } else {
      status = 'Inactive';
    }
    return status;
  }

  resolveRefData(resourceType) {
    const { refdata, notificationMasters } = this.props;
    let refds = [];
    if (resourceType && !(resourceType === 'NOTIFICATION_MASTER' || resourceType === 'NOTIFICATION')) {
      refds = refdata.filter((node) => node.type === resourceType && node.enabled);
      refds.sort((a, b) => a.sortOrder - b.sortOrder);
    } else {
      // eslint-disable-next-line array-callback-return
      notificationMasters.masters.map((p) => {
        refds.push({
          id: p.code,
          value: `${p.code} (${p.name})`,
        });
      });
      refds.sort((a, b) => a.id - b.id);
    }
    return refds;
  }

  addNewPolicyScheme = () => {
    this.setState({ showAddPolicyScheme: true });
  }

  composeRuleConfigValue(rule) {
    if (rule.ruleCode === "PDP.RULE.TASK_MASTER_CODES") {
      return <RuleConfigMultiValue mode="read" ruleConfigValues={rule.compositePolicyRuleInstanceConfigurations[0].configurationValue} refdata={this.props.refdata} xxruleVariables={rule.rule.ruleVariables} taskMasterDataRef={this.props.taskMasterData} taskMasterByCodesROResult={this.props.taskMasterByCodesROResult} />;
    }
    return <RuleConfigValue mode="read" ruleConfigValues={rule.compositePolicyRuleInstanceConfigurations[0].configurationValue} refdata={this.props.refdata} xxruleVariables={rule.rule.ruleVariables} taskMasterDataRef={this.props.taskMasterData} taskMasterByCodesROResult={this.props.taskMasterByCodesROResult} />;
  }

  /* Filter */

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState((prevState) => ({ ...prevState, filterSelections }));
  }

  createFilterOptions() {
    const { policySchemeConfigurations } = this.props;
    const schemes = [];
    policySchemeConfigurations.map((config) => {
      const { name } = config.policyScheme;
      schemes.push({
        key: config.policyScheme.id,
        value: name,
      });
    });
    const filterEntries = {
      contentFilters: [{
        filterType: 'Policy Scheme',
        entries: [...schemes]
      }]
    };
    return filterEntries;
  }

  reduceByApplyingFilter() {
    const { filterSelections } = this.state;
    let policySchemes = [];

    if (filterSelections.length === 0 || filterSelections[0].items.length === 0) {
      policySchemes = [...this.props.policySchemeConfigurations];
    } else {
      const compareIfPolicySchemeExists = (policySchemeId) => filterSelections[0].items.some((ch) => ch.key === policySchemeId);
      policySchemes = this.props.policySchemeConfigurations.filter((psc, index) => compareIfPolicySchemeExists(psc.policyScheme.id));
    }
    return policySchemes;
  }

  /* Render */

  render() {
    const { classes, policyScheme, entitlementRules, linkedPolicyLibraries } = this.props;
    const { filterType, title, subTitle } = this.state.filterLiterals;
    const { policySchemeWithEntitlements, filterOptionsKey, policySchemeConfigurationTypeId, filterSelections } = this.state;
    return (
      <Box display="flex">
        <Box>
          <Card variant='outlined' className={classes.card}>
            <CardContent>
              <Typography variant="subtitle1" className={classes.listHeader}>
                {title}
              </Typography>
              <Accordion className="searchlist">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >{subTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filterv2 key={filterOptionsKey} filterType={filterType} filterOptions={this.createFilterOptions()} applyFilterSelectionsHandler={this.applyFilterSelectionsHandler} initialSelections={filterSelections} showSelectedItems={true} ></Filterv2>
                </AccordionDetails>
              </Accordion>
              {this.listPolicyLibraryLP()}
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={4}>
          <Card variant='outlined'>
            <CardContent>
              <div className={classes.headerSection}>
                <Box display="flex">
                  <Box flexGrow={1} >
                    <div className={classes.pmHeader}>Policy Builder</div>
                  </Box>
                  <Box display="flex" flexDirection="column"  >
                    <DefaultButton text="Duplicate" onClick={this.toggleDuplicatePolicyModal} disabled={isReadOnly} />
                  </Box>
                  <Box display="flex" flexDirection="column" >
                    <DefaultButton className={classes.btnHeaderSection} text="Add New Policy" onClick={this.addNewPolicyScheme} disabled={isReadOnly} />
                  </Box>
                </Box>
              </div>
              <div className={`${classes.boxed} ${classes.descBottom}`}>
                <Box display="flex" className={classes.titleSection}>
                  <Box flexGrow={1} >
                    <div>
                      <div className={classes.titleHeaderRow}>{policyScheme.name}</div>
                      <div className={classes.titleRow}>Last Updated: 19 Aug 2020 | Ver 2.1</div>
                    </div>
                  </Box>
                  <Box display="flex" flexDirection="column" style={{ alignItems: 'center', justifyContent: 'center', height: '50px' }} >
                    <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 23 } }} styles={{ root: { color: 'white' }, rootHovered: { backgroundColor: '#0078d4', color: 'white' } }} onClick={this.toggleMasterDetails} />
                  </Box>
                </Box>
                <div className={classes.grid2}>
                  <div>
                    <div><span className={classes.policyStatus}>{this.displayPolicySchemeStatus()}</span></div>
                    <div className={classes.subtitle}>SCHEME TYPE</div>
                    <div className={classes.subContent}>{policyScheme.schemeType}</div>
                    <div className={classes.subtitle}>DESCRIPTION</div>
                    <div className={classes.subContent}>{policyScheme.description}</div>
                    <Separator style={{ fontWeight: '600' }}></Separator>
                    <div className={classes.subtitle}>LINKED LIBRARIES</div>
                    <div className={classes.linkedBox}>
                      {PolicyBuilder.displayLinkedLibrary(classes, linkedPolicyLibraries)}
                    </div>
                    <div className={classes.subtitle}>POLICY LINKING</div>
                    <div className={classes.linkedBox}>
                      {this.displayLinkedResources()}
                    </div>
                  </div>
                  <div className={classes.grid3}>
                    <div className={classes.divider1}></div>
                    <div>
                      <div className={classes.policySchemesInUse}> POLICY SUMMARY</div>
                      <div className={classes.policySchemesInUse}>23% All Policy Activities</div>
                      <div className={classes.policySchemesInUse}>17 Segments in use</div>
                      <div className={classes.policySchemesInUse}>61 Rules in use</div>
                    </div>
                  </div>
                </div>
              </div>
              <Box>
                <Grid item xs={12}>
                  <button className={classes.whiteBtn} onClick={() => this.toggleCreateNewPDPPanel()}>
                    <AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}>
                    </AddCircleOutlineIcon>Add New PDP
                  </button>
                </Grid>
              </Box>

              {entitlementRules?.map((policy, index) => (
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.icon} />} aria-controls="panel1a-content" id="panel1a-header" className={classes.accordionHeader}>
                    <Box flexGrow={1} >
                      <div>
                        <div className={classes.accHeading}>{policy.policyDecisionPoint.pdpTitle} ({policy.policyDecisionPoint.pdpCode})</div>
                        <div className={classes.accSubHeading}>Last Updated: 19 Aug 2020</div>
                      </div>
                    </Box>
                    <Box style={{ alignItems: 'center', justifyContent: 'center', height: '50px' }} >
                      <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 18 } }} styles={{ root: { color: 'white' }, rootHovered: { backgroundColor: '#99c5ed', color: 'white' } }} onClick={() => this.togglePolicySchemePdpDetails(policy.policyDecisionPoint, policy.policyEntitlementOperation)} />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box>
                      <Card variant='outlined'>
                        {policy.compositePolicyRules ? policy.compositePolicyRules.map((pol) => (
                          <CardContent>
                            <Card className={classes.policyHeading}>
                              <CardContent>
                                <div className={classes.gridcr}>
                                  <div>
                                    <div>Policy: {pol.policyTitle}</div>
                                  </div>
                                  <div className={classes.grid3}>
                                    <div >
                                      <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 18, color: 'black', paddingBottom: '10px' } }} styles={{ root: { color: 'black' }, rootHovered: { backgroundColor: 'transparent', color: 'black' } }} onClick={() => this.togglePolicySchemeCompositeRuleDetails(policy, 'EDIT', pol)} />
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>

                            <Card variant='outlined' className={classes.carddetailbkg}>
                              <CardContent>
                                {pol.policyRules.map((rule) => (
                                  <div className={classes.ruleBox}>
                                    <Grid container>
                                      <Grid item xs={10}>
                                        <div>
                                          <Typography className={classes.accHeading2}>Rule: {rule.rule.ruleTitle}</Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={2} >
                                        {PolicyBuilder.withCompleteRuleConfiguration(rule) ? <SettingsRoundedIcon onClick={() => this.toggleRulePickerPanelForEdit(pol, rule)} className={classes.viewMoreLink} ></SettingsRoundedIcon> : null}
                                      </Grid>
                                      {PolicyBuilder.withCompleteRuleConfiguration(rule) ? this.composeRuleConfigValue(rule) : null}
                                      {/* {PolicyBuilder.withCompleteRuleConfiguration(rule) ? <RuleConfigValue mode="read" ruleConfigValues={rule.compositePolicyRuleInstanceConfigurations[0].configurationValue} refdata={this.props.refdata} xxruleVariables={rule.rule.ruleVariables} taskMasterDataRef={this.props.taskMasterData} taskMasterByCodesResult={this.props.taskMasterByCodesResult} /> : null} */}
                                    </Grid>
                                    <br />
                                  </div>
                                ))}

                                <div>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                      <button className={classes.whiteBtn} onClick={() => this.toggleRulePickerPanelForAdd(pol)} ><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add Rule</button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardContent>
                            </Card>
                          </CardContent>
                        )) : null}
                      </Card>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <button className={classes.whiteBtn} onClick={() => this.togglePolicySchemeCompositeRuleDetails(policy, 'ADD', '')}><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon} ></AddCircleOutlineIcon>Add Composite Rule</button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        </Box>

        {this.state.isRulePickerOpen ? <RulePicker action={this.state.actionForRulePicker} compositePolicy={this.state.selCompositePolicy} existingRuleIds={this.state.selExistingRuleIds} selRuleForEdit={this.state.selRuleForEdit} isRulePickerPanelOpen={this.state.isRulePickerOpen} dismissRulePickerPanel={this.dismissRulePickerPanelHandler} literalType={ActionLiteralType.POLICY} refreshSelCompositeRules={this.refreshSelCompositeRulesHandler} refdata={this.props.refdata} handleDeleteRuleFromComposite={this.handleDeleteRuleFromComposite} isReadOnly={isReadOnly} taskMasterDataRef={this.props.taskMasterData} /> : null}

        {this.state.isRuleTaskPickerOpen ? <RulePickerTask action={this.state.actionForRulePicker} compositePolicy={this.state.selCompositePolicy} existingRuleIds={this.state.selExistingRuleIds} selRuleForEdit={this.state.selRuleForEdit} isRulePickerPanelOpen={this.state.isRuleTaskPickerOpen} dismissRulePickerPanel={this.dismissRulePickerPanelHandler} literalType={ActionLiteralType.POLICY} refreshSelCompositeRules={this.refreshSelCompositeRulesHandler} refdata={this.props.refdata} handleDeleteRuleFromComposite={this.handleDeleteRuleFromComposite} isReadOnly={isReadOnly} taskMasterDataRef={this.props.taskMasterData} /> : null}

        {this.state.isDuplicatePolicyModalOpen ? <DuplicatePolicy hideDuplicatePolicyPanelHandler={this.hideDuplicatePolicyPanelHandler} viewNewPolicySchemeHandler={this.viewNewPolicySchemeHandler} policySchemeTypeId={policySchemeConfigurationTypeId} policyToDuplicate={policySchemeWithEntitlements} isReadOnly={isReadOnly} /> : null}

        {this.state.isMasterDetailsPanelOpen ? <MasterDetails isMasterDetailsPanelOpen={this.state.isMasterDetailsPanelOpen} hideMasterDetailsPanelHandler={this.hideMasterDetailsPanelHandler} policySchemeToUpdate={policySchemeWithEntitlements} literalType={ActionLiteralType.POLICY} isReadOnly={isReadOnly} refdata={this.props.refdata} /> : null}

        {this.state.isPolicySchemePdpDetailsPanelOpen ? <PdpDetails isPolicySchemePdpDetailsPanelOpen={this.state.isPolicySchemePdpDetailsPanelOpen}
          policySchemeId={this.state.policySchemeId} hidePolicySchemePdpDetailsPanelHandler={this.hidePolicySchemePdpDetailsPanelHandler} policyDecisionPoint={this.state.selPolicyDecisionPoint}
          literalType={ActionLiteralType.POLICY} isReadOnly={isReadOnly} headerText={"Edit Policy PDP"} isDisableDeleteFunction={entitlementRules?.length === 1} /> : null}

        {this.state.isPolicySchemeCompositeRuleDetailsPanelOpen ? <CompositeRuleDetails isPolicySchemeCompositeRuleDetailsPanelOpen={this.state.isPolicySchemeCompositeRuleDetailsPanelOpen} hidePolicySchemeCompositeRuleDetailsPanelHandler={this.hidePolicySchemeCompositeRuleDetailsPanelHandler} policyCompositeRule={this.state.policyCompositeRule} selPolicyCompositeRule={this.state.selPolicyCompositeRule} policySchemeId={this.state.policySchemeId} pepId={this.state.pepId} pdpId={this.state.pdpId} entitlementRulesId={this.state.entitlementRuleId} literalType={this.state.compositeRuleMode === 'ADD' ? ActionLiteralType.COMPOSITERULEADD : ActionLiteralType.COMPOSITERULEEDIT} isReadOnly={isReadOnly} mode={this.state.compositeRuleMode} /> : null}

        {this.state.showAddPolicyScheme ? <CreatePolicyScheme isReadOnly={isReadOnly} policySchemeData={this.props.policyLibraryData} policySchemeTypeId={this.props.match.params.policySchemeTypeId} hideAddPolicySchemeHandler={this.handleAddPolicySchemeClose} /> : null}

        {this.state.isCreateNewPdpPanelOpen && <PdpDetails addNewPdp={true} isPolicySchemePdpDetailsPanelOpen={this.state.isCreateNewPdpPanelOpen}
          policySchemeId={this.state.policySchemeId} hidePolicySchemePdpDetailsPanelHandler={this.hidePolicySchemePdpDetailsPanelHandler} policyDecisionPoint={null}
          literalType={ActionLiteralType.POLICY} isReadOnly={isReadOnly} headerText={"Add New PDP"}
          editNewPdpHandlder={(pdp) => this.handleEditPDPDetailsAfterCreation(pdp)} />}
      </Box>
    );
  }
}

const styles = (theme) => ({
  card: {
    backgroundColor: '#f3f2f1',
    width: 300,
    margin: 0
  },
  root: {
    flexGrow: 1
  },
  headerSection: {
    padding: '16px 0'
  },
  btnHeaderSection: {
    marginRight: '25px',
    marginLeft: '25px',
  },
  listHeader: {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'SegoeUI',
    marginBottom: '1rem'
  },
  pmItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  pmItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
  titleSection: {
    backgroundColor: '#0078d4',
    height: '50px',
    color: '#fff',
    padding: '16px 33px'
  },
  titleHeaderRow: {
    fontSize: '20px',
    fontWeight: '600'
  },
  crIcon: {
    float: 'right'
  },

  titleRow: {
    padding: '8px 0'
  },

  pmHeader: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'PharmaCond',
    color: '#323130'
  },
  editControlSectionHeader: {
    color: 'black',
    fontWeight: '200',
    padding: '0 1.5em 1em 1em',
    width: '100%',
    display: 'inline-block',
    margin: '1px',
    height: '140px'
  },
  boxed: {
    border: '2px solid lightgrey',
    height: '470px'
  },

  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '10px 0 10px 0',
    color: '#323130',
    paddingRight: '1rem',
    cursor: "pointer"
  },

  accordionHeader: {
    backgroundColor: '#99c5ed',
    color: 'black',
    // flexDirection: 'row-reverse',
    flexDirection: 'row-reverse',
    direction: 'ltr',
    display: 'flex',
    justifyContent: 'flex-start',
    height: '84px'
  },
  icon: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    fontSize: 'large !important'
  },
  accHeading: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: '#323130'
  },
  policyHeading: {
    width: 'auto',
    height: '74px',
    marginTop: '0rem',
    borderRadius: '2px',
    backgroundColor: '#cce2f6'
  },
  accSubHeading: {
    width: 'auto',
    height: '20px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#555555'
  },
  accHeading2: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: '#323130'
  },
  carddetailbkg: {
    backgroundColor: '#f3f2f1',
    color: 'black',
    marginTop: '1rem'
  },
  descBottom: {
    paddingBottom: '10px'
  },

  ruleBox: {
    marginTop: '2rem',
    borderBottom: 'solid 1px #bdbdbd',
    marginBottom: '2rem'
  },
  addTaskLinkIcon: {
    border: '0',
    backgroundColor: null,
    color: 'black',
    fontSize: '8px',
    cursor: 'grab',
    float: 'left',
    marginRight: '1rem'
  },
  grid2: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridTemplateColumns: '7fr 3fr',
    gridGap: '20px',
    padding: '16px 0 0 16px',
  },

  gridcr: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridTemplateColumns: '10fr 0.5fr',
    gridGap: '20px',
    padding: '16px 0 0 0',
  },

  policySchemesInUse: {
    width: '200px',
    height: '20px',
    margin: '20px 0 0 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#323130',
  },

  grid3: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 9fr',
  },

  divider1: {
    height: '160px',
    width: '1px',
    border: 'solid 1px #cdcdcd',
    backgroundColor: '#f3f2f1',
  },
  subtitle: {
    width: '700px',
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
    padding: '20px 0 0 0',
  },
  subtitle1: {
    color: '#323130'
  },
  subContent: {
    padding: '5px 0',
  },
  policyStatus: {
    border: 'solid 1px #006ed2;',
    padding: '3px 12px 3px 11px',
  },
  linkedBox: {
    padding: '15px 0',
  },
  linkedLibrary: {
    border: 'solid 1px #979797;',
    padding: '6px 13px 6px 16px',
    borderRadius: '23.5px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
  },

  statsPolicySegments: {
    width: '500px',
    height: '20px',
    margin: '20px 0px 30px 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#555555',
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '60%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  containierBox: {
    display: 'flex',
    justifyContent: 'space-around'
  }
});

PolicyBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  policySchemeConfigurations: state.policyLibraryBuilderData.policySchemeConfigurations,
  policyScheme: state.policyLibraryBuilderData.policyScheme,
  entitlementRules: state.policyLibraryBuilderData.entitlementRules,
  linkedPolicyLibraries: state.policyLibraryBuilderData.linkedPolicyLibraries,
  linkedResources: state.policyLibraryBuilderData.linkedResources,
  policyLibraryData: state.policyLibraryMasterData.policyLibraryMasters,
  result: state.ui.result,
  refdata: state.refData.referenceData,
  selectedListValues: state.sharedMasterData.selectedItems,
  referenceDataTypes: state.refData.referenceDataTypes,
  ruleVariables: state.policyRuleData.ruleVariables,
  taskMasterData: state.taskMastersData.taskMasters,
  policySchemeSelectionScopeMappings: state.policyLibraryBuilderData.policySchemeSelectionScopeMappings,
  notificationMasters: state.notificationMastersData.notificationMasters,
  taskMasterByCodesROResult: state.taskMastersData.taskMastersByCodesRO,
});

const connected = connect(mapStateToProps, {
  fetchPolicySchemeConfigurations,
  fetchPolicySchemeConfiguration,
  fetchPolicyLibraryMasters,
  fetchReferenceData,
  referenceDataTypeSelected,
  setPolicyBuilderConfiguration,
  removeRuleFromCompositeRule,
  fetchPolicyRuleVariablesByRule,
  fetchTaskMasters,
  fetchPolicySchemeSelectionScopeMappings,
  fetchNotificationMasters,
  fetchTaskMastersByCodes,
})(AuthProvider(PolicyBuilder));

export default withStyles(styles)(connected);
