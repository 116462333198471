import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  IconButton, FocusTrapCallout
} from '@fluentui/react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';

import Filterv2 from './Filterv2';
import AuthProvider from '../auth/AuthProvider';
import FilterOptionTypeLiteral from '../../constants/FilterLiterals';

class FilterCallout extends React.Component {
  constructor (props) {
    super(props);
    const { filterType } = this.props;
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === filterType)[0];
    this.state = {
      filterLiterals
    };
  }

  /* Functions used to build section of page */
  buildFilterDialog () {
    const {
      classes,
      openDialog,
      filterType,
      filterOptionsKey,
      filterOptions,
      applyFilterSelectionsHandler,
      toggleFilterCalloutHandler,
      initialSelections,
      showSelectedItems,
      getMastersWithFilters
    } = this.props;
    const { title, subTitle } = this.state.filterLiterals;

    return (<>
      {openDialog ? (
      <FocusTrapCallout
        role="alertdialog"
        onDismiss={toggleFilterCalloutHandler}
        target="#FilterCallout"
        setInitialFocus
        focusTrapProps={{
          forceFocusInsideTrap: false,
          isClickableOutsideFocusTrap: false,
          focusPreviouslyFocusedInnerElement: false
        }}
        >
        <Box>
          <Card variant='outlined' className={classes.card}>
            <CardContent>
              <div style={{ display: 'flex' }}>
              <Typography variant="subtitle1" className={classes.listHeader}>
                {title}
              </Typography>
              <div><IconButton
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={toggleFilterCalloutHandler}
              /></div>
              </div>
              <Accordion className="searchlist" defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >{subTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filterv2 getMastersWithFilters={getMastersWithFilters} key={filterOptionsKey} filterType={filterType} filterOptions={filterOptions} applyFilterSelectionsHandler={applyFilterSelectionsHandler} initialSelections={initialSelections} showSelectedItems={showSelectedItems} ></Filterv2>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Box>
      </FocusTrapCallout>
      ) : null}
      </>
    );
  }

  /* Event and Action Handlers */

  closeDialogHandler = () => {
    this.setState((prevState) => ({ ...prevState, toggleOpen: false }));
  }

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  /* Helper Functions */

  /* Render */

  render () {
    return (
      <React.Fragment>
        {this.buildFilterDialog()}
      </React.Fragment>
    );
  }
}

FilterCallout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto'
  },
  card: {
    backgroundColor: '#f3f2f1',
    margin: 0,
  },
  listHeader: {
    flex: 1,
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'SegoeUI',
    marginBottom: '1rem',
  },
});

const cancelIcon = { iconName: 'Cancel' };

const mapStateToProps = (state) => {
  return {};
};

const connected = connect(mapStateToProps, null)(AuthProvider(FilterCallout));

export default withStyles(styles)(connected);
