import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import history from "../../../history";
import "../reference.css";

const TableMappingList = ({ pipList, type }) => (
  <Card className={"accordCardBackground"}>
    <CardContent>
      <Grid container spacing={4}>
        <Grid item xs={12} sm container className={"sectionheader"}>
          <Grid item xs={5}>
            <Typography gutterBottom variant="subtitle1" className={"heading"}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle1" className={"heading"}>
              Description
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={"lastHeading"}
            >
              Edit Set
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {pipList.map((pip, index) => (
        <Grid container spacing={4} key={`g-${index}`}>
          <Grid item xs={12} sm container className={"listContext"}>
            <Grid item xs={5}>
              <Typography
                variant="body2"
                gutterBottom
                className={"mappingListTableName"}
              >
                {type === "pips" ? pip.name : pip.configValue}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={"mappingListTableName"}
              >
                {type === "pips" ? pip.pipCode : pip.configKey}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                {pip.description}
              </Typography>
            </Grid>

            <Grid item xs={1} className={"lastHeading"}>
              <EditIcon
                onClick={() => handleEditTable(pip, type)}
                className={"viewItemLink"}
              ></EditIcon>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </CardContent>
  </Card>
);

// eslint-disable-next-line space-before-function-paren
function handleEditTable(selectedPip, type) {
  const builderPath = type === "pips" ? "pipList" : "tablesList";
  const path = type === "pips"
      ? `/referenceDataConsole/${builderPath}/pipTableMappingBuilder?selectedPipId=${selectedPip.id}&mappingType=${type}`
      : `/referenceDataConsole/${builderPath}/tableMappingBuilder?selectedPipId=${selectedPip.id}&mappingType=${type}`;
  history.push(path);
}

TableMappingList.defaultProps = {};

export default TableMappingList;
