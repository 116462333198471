import { FETCH_REFERENCE_DATA_TYPES, REFERENCE_DATA_TYPE_SELECTED, FETCH_REFERENCE_DATA, REFERENCE_DATA_TYPE_VALUE_SELECTED} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  referenceDataTypes: [],
  backendResult: null,
  referenceData: [],
  referenceDataTypeValues:[],
  referenceDataTypesSelectedValues:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA:
      return { ...state, referenceData:action.payload}
    case FETCH_REFERENCE_DATA_TYPES:
      return { ...state, referenceDataTypes: action.payload };
    case REFERENCE_DATA_TYPE_VALUE_SELECTED:
      return { ...state, referenceDataTypesSelectedValues: action.payload };  
    case REFERENCE_DATA_TYPE_SELECTED:
      return {
          ...state,
          selectedRefDataType: action.payload.refDataType,
          referenceDataTypeValues: action.payload.refDataValues
      };
    default:
      return state;
  }
};