import api from '../../api/IntelligentAgentServiceApi';
import {
  FETCH_COMPOSITE_RULES_BY_COMPOSITE_RULE,
  FETCH_POLICY_RULE_VARIABLES_BY_RULE,
  FETCH_POLICY_COMPOSITE_RULE_BY_ID,
  ADD_RULE_TO_COMPOSITE_RULE,
  REMOVE_RULE_TO_COMPOSITE_RULE,
  POLICY_RULE_ACTION_FAILED,
  ADD_COMPOSITE_RULE_TO_POLICY,
  REMOVE_COMPOSITE_RULE_FROM_POLICY,
  UPDATE_COMPOSITE_RULE_TO_POLICY,
  // SET_POLICY_SCHEME_CONFIGURATIONS
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchCompatibleRulesByCompositeRule = (compositeRuleId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy-composite-rule/${compositeRuleId}/rules`);
    dispatch({ type: FETCH_COMPOSITE_RULES_BY_COMPOSITE_RULE, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const fetchPolicyRuleVariablesByRule = (ruleId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy-rule/${ruleId}/rule-variables`);
    dispatch({ type: FETCH_POLICY_RULE_VARIABLES_BY_RULE, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const fetchPolicyRuleVariablesByRuleDirect = async (ruleId) => {
  const response = await api.get(`/admin/policy-rule/${ruleId}/rule-variables`);
  return response;
};

export const fetchPolicyCompositeRuleById = (compositeRuleId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy-composite-rule/${compositeRuleId}`);
    dispatch({ type: FETCH_POLICY_COMPOSITE_RULE_BY_ID, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const addRuleToCompositeRule = (compositeRuleId, rule) => async (dispatch) => {
  try {
    const response = await api.post(`/admin/ccr/policy-composite-rule/${compositeRuleId}/add-rule`, rule);
    dispatch({ type: ADD_RULE_TO_COMPOSITE_RULE, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: ADD_RULE_TO_COMPOSITE_RULE, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const removeRuleFromCompositeRule = (compositeRuleId, rule) => async (dispatch) => {
  try {
    const response = await api.post(`/admin/ccr/policy-composite-rule/${compositeRuleId}/remove-rule`, rule);
    dispatch({ type: REMOVE_RULE_TO_COMPOSITE_RULE, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
    dispatch({ type: REMOVE_RULE_TO_COMPOSITE_RULE, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
  }
};

export const modifyRuleToCompositeRule = (compositeRuleId, rule) => async (dispatch) => {
  try {
    const response = await api.post(`/admin/ccr/policy-composite-rule/${compositeRuleId}/modify-rule`, rule);
    dispatch({ type: ADD_RULE_TO_COMPOSITE_RULE, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: ADD_RULE_TO_COMPOSITE_RULE, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const addCompositeRuleToPolicy = (compositeRule) => async (dispatch) => {
  try {
    const response = await api.post(`/admin/policy-composite-rule/`, compositeRule);
    dispatch({ type: ADD_COMPOSITE_RULE_TO_POLICY, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: ADD_COMPOSITE_RULE_TO_POLICY, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const removeCompositeRuleFromPolicy = (compositeRuleId) => async (dispatch) => {
  try {
    const response = await api.delete(`/admin/policy-composite-rule/${compositeRuleId}`);
    dispatch({ type: REMOVE_COMPOSITE_RULE_FROM_POLICY, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
    dispatch({ type: REMOVE_COMPOSITE_RULE_FROM_POLICY, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
  }
};

export const modifyCompositeRuleToPolicy = (compositeRule, id) => async (dispatch) => {
  try {
    const response = await api.put(`/admin/policy-composite-rule/${id}`, compositeRule);
    dispatch({ type: UPDATE_COMPOSITE_RULE_TO_POLICY, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: UPDATE_COMPOSITE_RULE_TO_POLICY, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};
