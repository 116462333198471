import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import '../../components/style.css';
import { connect } from 'react-redux';
import { fetchReferenceData,referenceDataTypeValueSelected } from '../../state/actions/ReferenceDataActions';
import AuthProvider from '../../components/auth/AuthProvider';
import history from '../../history';

class TaskLibraryMasterCard extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            id: props.id,            
            title: props.title,
            plsIndex: props.index,          
            classes: PropTypes.object.isRequired,
            open: false,
            show: false
        };
    }
 
    onHide = () => {
        this.setState({ show: false });
    }

    onShow = () => {
        this.setState({ show: true });
    }

    handleClick = (event, index) => {
        this.setState((state) => ({ open: !state.open, [index]: true }));
    };

    onViewTaskLibrary=(event,index)=>{
        this.props.referenceDataTypeValueSelected(this.state.id);      
        history.push('/taskLibraryMasterConsole/taskMasterConsole');
    }

    render () {
        const { classes} = this.props;
        return (
            <Box m={1}>
                <Card className={classes.card} variant='outlined' style={{ width: '350px', height: '410px' }}>
                    <CardHeader className={this.props.classes.cardHeaderSection}
                        title={<Typography className={classes.cardHeader}>
                            Task Library- {this.state.title}
                        </Typography>} />
                    <CardContent>
                        <button onClick={this.onViewTaskLibrary} className={classes.viewMoreLink}> View Task Library</button>                    
                        <hr className={classes.sepLine} />
                        <div className={classes.subtitle}>LIBRARY SUMMARY</div>
                        <div className={classes.fieldRow}>
                            <span className={classes.fieldName}> TBA Total Tasks</span>
                        </div>
                        <div className={classes.fieldRow}>
                            <span className={classes.fieldName}> TBA Checkpoint Tasks</span>
                        </div>
                        <div className={classes.fieldRow}>
                            <span className={classes.fieldName}> TBA DecisionPoint Tasks</span>
                        </div>
                        <div className={classes.fieldRow}>
                            <span className={classes.fieldName}> TBA Inactive Tasks</span>
                        </div>
                        <hr className={classes.sepLine} />
                        <div className={classes.viewLink}>
                            <button onClick={this.onShow} className={classes.viewMoreLink}>View Full Summary (TBA)</button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        );
    }
}

const styles = (theme) => ({
    cardHeader: {
        fontFamily: 'PharmaCond',
        fontSize: '24px',
        color: '#323130',
        fontWeight: '600',
    },
    cardHeaderSection: {
        padding: '16px 0 0 16px',
    },
    card: {
        borderColor: blue[200],
        width: 200,
        height: 320,
        color: '#000',
        margin: '8px 16px'
    },
    subtitle: {
        padding: '16px 0',
        fontWeight: '600',
        fontFamily: 'SegoeUI'
    },

    fieldRow: {
        padding: '8px 0',
    },

    fieldName: {
        fontWeight: '600'
    },

    fieldRowDescription: {
        borderRadius: '2px',
        border: 'solid 1px #a19f9d',
        padding: '8px 0',
    },

    fieldDescription: {
    },

    listView: {
        padding: '18px 5',
        backgroundColor: '#DCDCDC',
    },
    sepLine: {
        margin: '16px 0',
        color: '#bdbdbd'
    },

    viewLink: {
        color: '#006ed2'

    },

    modal: {
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '65%',
        padding: '16px 32px',
        boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
        backgroundColor: '#fff',
        zIndex: '1000',
        overflow: 'auto'
    },

    viewTaskLibraryButton: {
        backgroundColor: '#0078d4',
        border: 'none',
        color: '#fff',
        padding: '5px 12px 7px 16px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '600',
        cursor: 'pointer',
        borderRadius: '2px'
    },

    closeButton: {
        backgroundColor: '#fff',
        border: '0',
        color: '#605e5c',
        fontSize: '18px',
        padding: '0 16px',
        marginRight: '16px',
        cursor: 'pointer'
    },

    popUpTitle: {
        fontSize: '20px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        color: '#323130',
    },

    viewMoreLink: {
        border: '0',
        backgroundColor: '#fff',
        color: '#006ed2',
        fontSize: '14px'
    }

});

TaskLibraryMasterCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {   
    return {    
      refdata: state.refData.referenceData,
      refDataTypeValues: state.refData.referenceDataTypeValues,
      referenceDataSelected :state.refData.referenceDataTypesSelectedValues
    };
  };
  
  const connected = connect(mapStateToProps, {  
    fetchReferenceData,
    referenceDataTypeValueSelected    
  })(AuthProvider(TaskLibraryMasterCard));
  
export default withStyles(styles)(connected);
