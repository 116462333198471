import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
    DefaultButton, PrimaryButton, initializeIcons, IconButton, Text, TextField, Checkbox
} from '@fluentui/react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Separator } from '@fluentui/react/lib/Separator';

import { withStyles } from '@material-ui/styles';

import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import AuthProvider from '../../../components/auth/AuthProvider';
import {
 fetchPolicyConfigurationNames, updatePolicyMasterDetails, fetchPolicySchemeSelectionScopeMappings, fetchPolicyKeySystemResourcePolicyDefinition
} from '../../../state/actions/PolicyLibraryMasterActions';
import { fetchNotificationMasters } from '../../../state/actions/NotificationMastersAction';
import { PolicyMasterDetailsActionTypeLiteral } from '../../../constants/ActionTypeObj';
import './MasterDetails.css';

const options = [
    { key: 'MAJOR', text: 'Major Update   ' },
    { key: 'MINOR', text: 'Minor Update' },
  ];

class MasterDetails extends React.Component {
    constructor (props) {
        super(props);
        const literalType = PolicyMasterDetailsActionTypeLiteral.filter((value) => value.type === this.props.literalType)[0];
        this.state = {
            literalType,

            // policySchemeToUpdate: this.props.policySchemeToUpdate,
            policyScheme: null,
            isCurrentlyInSelectionMode: true, // 2 modes, Editor (true) and Confirmation (false)
            isSubmitInProgress: false,
            withSubmitError: false,
            isArchiveDisable: false,
            isArchive: false,

            availLibraries: [],
            availResourceTypes: [],
            availSelectionScopes: [],
            linkedLibraries: [],
            linkedResources: [],
            selectionScopes: [],
            selectedScopeResourceType: [],

            pepCode: "",
        };
        initializeIcons();
    }

    async componentDidMount () {
        const { literalType } = this.state;
        document.title = literalType.title;
        await this.props.fetchPolicyConfigurationNames();
        await this.props.fetchPolicySchemeSelectionScopeMappings();
        await this.props.fetchPolicyKeySystemResourcePolicyDefinition();
        await this.props.fetchNotificationMasters();
        this.prepareFormContent();
        if (this.props.policySchemeToUpdate?.policyScheme?.configurationStatus === "CANCELLED") { this.setState({ isArchive: true }); } else { this.setState({ isArchive: false }); }
        if (this.props.policySchemeToUpdate?.linkedPolicyLibraries.length > 0) { this.setState({ isArchiveDisable: true }); } else { this.setState({ isArchiveDisable: false }); }
    }

    async prepareFormContent () {
        const {
 policyScheme, linkedResources, linkedPolicyLibraries, entitlementRules
} = this.props.policySchemeToUpdate;
        const {
 id, name, schemeType, description, configurationStatus
} = policyScheme;
        const { refdata, policySchemeSelectionScopeMappings } = this.props;
        const policySchemeTmp = {
            id,
            name,
            nameIsValid: true,
            schemeType,
            schemeTypeIsValid: true,
            description,
            status: true,
            configurationStatus,
        };

        const availLibraries = [];
        const refds = refdata.filter((node) => node.type === 'POLICY_SCHEME_TYPE' && node.enabled);
        refds.sort((a, b) => a.sortOrder - b.sortOrder);
        refds.forEach((ref) => {
            availLibraries.push({
                key: ref.id,
                text: ref.value,
                disabled: false
            });
        });

        const linkedLibraries = [];
        linkedPolicyLibraries.forEach((ref) => {
            linkedLibraries.push(ref.policySchemeTypeId);
        });

        const linkedResourcesTmp = [];
        linkedResources.forEach((ref) => {
            if (ref.policySchemeId === id) {
                linkedResourcesTmp.push(ref.resourceId);
            }
        });

        let availResourceTypes = [];
        if (linkedResources?.length && policySchemeSelectionScopeMappings) {
            const { resourceType, selectionScope } = linkedResources[0];
            availResourceTypes =  this.getAvailableResourceTypes(selectionScope, resourceType, id);
        }

        const availSelectionScopes = [];
        policySchemeSelectionScopeMappings.forEach((ref) => {
            availSelectionScopes.push({
                key: ref.id,
                text: `Scope: ${ref.selectionScope} - (${ref.resourceType})`,
            });
        });

        // create a unique entry of selection scope
        let selectionScopes = [];
        if (linkedResources?.length) {
            const tmp = linkedResources[0];
            if (tmp?.resourceType?.startsWith('NOTIFICATION')) {
                const refds1 = policySchemeSelectionScopeMappings.filter((node) => node.selectionScope === tmp.selectionScope && node.resourceType === 'NOTIFICATION');
                selectionScopes = [...new Set(refds1.map((item) => item.id))];
            } else {
                const refds1 = policySchemeSelectionScopeMappings.filter((node) => node.selectionScope === tmp.selectionScope && node.resourceType === tmp.resourceType);
                selectionScopes = [...new Set(refds1.map((item) => item.id))];
            }
        }

        let pepCode = "";
        if (entitlementRules?.length) {
            pepCode = entitlementRules[0].policyEnforcementPoint.pepCode;
        }

        this.setState((prevState) => ({
 ...prevState, policyScheme: policySchemeTmp, availLibraries, availResourceTypes, availSelectionScopes, linkedLibraries, linkedResources: linkedResourcesTmp, pepCode, selectionScopes
}));
    }

    onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

    /* Step and Window handler */

    onDismissPolicyMasterDetailsPanel = () => {
        this.props.hideMasterDetailsPanelHandler();
    }

    onChangePolicyName = (event) => {
        const newVal = (event.target.value ? event.target.value : '');
        const val = this.state.policyScheme;
        val.name = newVal;
        val.nameIsValid = this.validatePolicySchemeName(newVal.trim());
        this.setState((prevState) => ({ ...prevState, policyScheme: val }));
    }

    onChangePolicyDescription = (event) => {
        const newVal = (event.target.value ? event.target.value : '');
        const val = this.state.policyScheme;
        val.description = newVal;
        this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyScheme: val } }));
    }

    onChangePolicySchemeType = (event) => {
        const newVal = (event.target.value ? event.target.value.trim() : '');
        const val = this.state.policyScheme;
        val.schemeType = newVal;
        val.schemeTypeIsValid = true; // this.validatePolicySchemeType(newVal);
        this.setState((prevState) => ({ ...prevState, policyScheme: val }));
    }

    onChangeLinkLibraries = (event, item) => {
        let updatedList = [];
        updatedList = [item.key];
        this.setState((prevState) => ({ ...prevState, linkedLibraries: updatedList }));
    }

    onChangeLinkResources = (event, item) => {
        let updatedList = [];
        if (item.selected) {
            updatedList = [...this.state.linkedResources, item.key];
        } else {
            updatedList = this.state.linkedResources.filter((key) => key !== item.key);
        }
        this.setState((prevState) => ({ ...prevState, linkedResources: updatedList }));
    }

    onChangeSelectionScope = (event, item) => {
        const { policySchemeToUpdate, policySchemeSelectionScopeMappings } = this.props;
        const { id } = policySchemeToUpdate.policyScheme;
        const updatedList = [item.key];
        const refds1 = policySchemeSelectionScopeMappings.filter((node) => node.id === item.key);
        const { selectionScope, resourceType } = refds1[0];
        const availResourceTypes = this.getAvailableResourceTypes(selectionScope, resourceType, id);
        this.setState((prevState) => ({ ...prevState, selectionScopes: updatedList, availResourceTypes }));
    }

    handleArchiveChange = (event) => {
        this.setState({ isArchive: !this.state.isArchive });
        const newVal = (event.target.checked === true ? 'CANCELLED' : '');
        const val = this.state.policyScheme;
        if (newVal !== '') { val.configurationStatus = newVal; }
        this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyScheme: val } }));
    }

    /* Validation handler */

    validatePolicySchemeName (pname) {
        let valid = true;
        const { name } = this.props.policySchemeToUpdate.policyScheme;

        if (name === pname) return valid;

        this.props.policySchemeConfigurationsNames.schemeNames.forEach((scheme) => {
        if (scheme.trim() === pname || pname.length === 0) {
            valid = false;
        }
        });
        return valid;
    }

    validatePolicySchemeType (schemeType) {
        let valid = true;
        this.props.policySchemeConfigurationsNames.policyScheme.forEach((scheme) => {
        if (scheme.schemeType.trim() === schemeType || schemeType.length === 0) {
            valid = false;
        }
        });
        return valid;
    }

    /* Processor (Edit/Save/Delete) */

    saveChangesToPolicy = async () => {
        const {
 id, name, description, configurationStatus
} = this.state.policyScheme;
        const { pepCode, linkedResources, selectionScopes } = this.state;
        const sel = selectionScopes?.length ? selectionScopes[0] : "";
        const found = this.props.policySchemeSelectionScopeMappings.filter((node) => node.id === sel);
        const library = this.state.linkedLibraries?.length ? this.state.linkedLibraries[0] : "";

        const resourceIds = [];
        linkedResources.forEach((ref) => {
            resourceIds.push({
                id: ref,
                resourceType: found?.length ? found[0].resourceType : "",
            });
        });
        const newScheme = {
            name,
            description,
            configurationStatus,
            pepCode
        };

        newScheme.policySchemeTypeId = library;

        if (found?.length) {
            newScheme.selectionScope = found[0].selectionScope;
            newScheme.resourceIds = resourceIds;
        }

        await this.props.updatePolicyMasterDetails(newScheme, id);
        this.setState((prevState) => ({
 ...prevState, isCurrentlyInSelectionMode: false, isSubmitInProgress: false, withSubmitError: false
}));
    }

    /* Utilities */

    static createValidationStatus (fieldName, status) {
        if (status) {
            return (
                <div style={{
 display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px'
}}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} styles={{ root: { color: 'green' } }} /><span style={{ fontSize: '12px' }}>This is a valid {fieldName}.</span></div>);
        }
        return (
            <div style={{
 display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px'
}}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} /><span style={{ fontSize: '12px' }}>This not yet a valid and unique {fieldName}. Update to continue.</span></div>
        );
    }

    displayLinkedLibrary (classes) {
        const { linkedLibraries } = this.state;
        const refds = this.props.refdata.filter((node) => node.type === 'POLICY_SCHEME_TYPE' && node.enabled);
        refds.sort((a, b) => a.sortOrder - b.sortOrder);
        const display = [];
        refds.forEach((ref) => {
            if (linkedLibraries.includes(ref.id)) {
                display.push(ref.value);
            }
        });

        if (display?.length) {
          return (<>
            {display.map((library, index) => <span className={classes.linkedLibrary}><span style={{ color: '#006ed2' }}>{library}</span></span>)}
          </>);
        }
        return <></>;
      }

    displayLinkedResources (classes) {
        const { linkedResources, selectionScopes } = this.state;
        const { policySchemeSelectionScopeMappings } = this.props;
        const display = [];

        if (linkedResources?.length) {
            const sel = selectionScopes?.length ? selectionScopes[0] : "";
            const found = policySchemeSelectionScopeMappings.filter((node) => node.id === sel);
            if (found?.length) {
                const refds = this.resolveRefData(found[0].resourceType);
                refds.forEach((ref) => {
                    if (linkedResources.includes(ref.id)) {
                        display.push(ref.value);
                    }
                });
            }
        }

        if (display?.length) {
          return (<>
            {display.map((resource, index) => <span className={classes.linkedLibrary}><span style={{ color: '#006ed2' }}>{resource}</span></span>)}
          </>);
        }
        return <></>;
    }

    getAvailableResourceTypes (selectionScope, resourceType, id) {
        const { linkedResources } = this.props.policySchemeToUpdate;

        const availResourceTypes = [];
        const refds1 = this.resolveRefData(resourceType);
        refds1.forEach((ref) => {
            const result = linkedResources.filter((node) => node.policySchemeId !== id && node.resourceId === ref.id);

            let scope = "";
            if (result?.length) {
                scope = result[0].selectionScope;
                availResourceTypes.push({
                    key: ref.id,
                    text: `${ref.value} (${scope})`
                });
            } else if (linkedResources?.length) {
                    const descForScope = linkedResources.filter((node) => node.policySchemeId === id && node.resourceId === ref.id);
                    if (descForScope?.length) {
                        scope = descForScope[0].selectionScope;
                        availResourceTypes.push({
                            key: ref.id,
                            text: `${ref.value} (${scope})`
                        });
                    } else {
                        availResourceTypes.push({
                            key: ref.id,
                            text: `${ref.value} (${selectionScope})`,
                        });
                    }
            } else {
                availResourceTypes.push({
                    key: ref.id,
                    text: `${ref.value} (${selectionScope})`,
                });
            }
        });
        return availResourceTypes;
    }

    resolveRefData (resourceType) {
        const { refdata, notificationMasters } = this.props;
        let refds = [];
        if (resourceType && !(resourceType === 'NOTIFICATION_MASTER' || resourceType === 'NOTIFICATION')) {
            refds = refdata.filter((node) => node.type === resourceType && node.enabled);
            refds.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            // eslint-disable-next-line array-callback-return
            notificationMasters.masters.map((p) => {
                refds.push({
                    id: p.code,
                    value: `${p.code} (${p.name})`,
                });
            });
            refds.sort((a, b) => a.id - b.id);
        }
        return refds;
    }

    /* Screens */

    buildPolicyMasterEditor () {
        if (!this.state.policyScheme) return null;

        const { classes, isReadOnly } = this.props;
        const {
            name, nameIsValid, schemeType, description
        } = this.state.policyScheme;
        const {
            availLibraries, availResourceTypes, availSelectionScopes, linkedLibraries, linkedResources, selectionScopes
        } = this.state;

        const charsLeft = (500 - description.length);
        return (
            <>
                <Stack horizontal tokens={stackTokens} className={classes.actions}>
                    <DefaultButton text="Cancel" onClick={this.onDismissPolicyMasterDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
                    <PrimaryButton text="Save" onClick={this.saveChangesToPolicy} disabled={isReadOnly} />
                </Stack>
                <Separator></Separator>
                <Stack>
                    <div className={classes.sectionTitle}>POLICY DETAILS</div>
                </Stack>
                <Stack className={classes.inputArea}>
                  <span className={classes.headerField}>Policy Status</span>
                    {this.props.policySchemeToUpdate?.linkedPolicyLibraries.length > 0
                    ? <Stack horizontal className={classes.informational}>
                        <IconButton iconProps={{ iconName: 'Info' }} />
                        <p className={classes.message}> Please remove all linkages to archive this item </p>
                      </Stack>
                    : ''}
                    <Stack horizontal>
                        <Checkbox className={classes.archiveCheckbox} label="Archive" disabled={isReadOnly || (!isReadOnly && this.state.isArchiveDisable)}
                            onChange={this.handleArchiveChange} checked = {this.state.isArchive}/>
                    </Stack>
                </Stack>

                <Stack tokens={stackTokens}>
                    <Stack className={classes.inputArea}>
                        <span className={classes.headerField}> Policy Name </span>
                        <TextField  className={classes.headerField} value={name} onChange={this.onChangePolicyName} maxLength={500} readOnly={isReadOnly} />
                        <Stack horizontal>
                        {MasterDetails.createValidationStatus('policy name', nameIsValid)}
                        </Stack>
                    </Stack>
                </Stack>

                <Stack tokens={stackTokens}>
                    <Stack className={classes.inputArea}>
                       <span className={classes.headerField}>Version Number  </span>
                        <Stack horizontal>
                            TBA
                        </Stack>
                        <Stack horizontal tokens={stackTokens}>
                            <ChoiceGroup styles={{ flexContainer: { display: "flex" } }} options={options} onChange={this.onChangeVersion} required={true} />
                        </Stack>
                    </Stack>
                </Stack>

                <Stack tokens={stackTokens}>
                    <Stack className={classes.inputArea}>
                        <span className={classes.headerField}>Scheme Type</span>
                        <TextField value={schemeType} readOnly />
                    </Stack>
                </Stack>

                <Stack tokens={stackTokens}>
                    <Stack className={classes.inputArea}>
                        <span className={classes.headerField}> Policy Description</span>
                        <TextField value={description} onChange={this.onChangePolicyDescription} multiline={true} style={{ height: '110px' }} maxLength={500} readOnly={isReadOnly} />
                        <Text style={{ fontSize: '12px' }}>You have {charsLeft} characters left</Text>
                    </Stack>
                </Stack>

                <Stack>
                    <div className={classes.sectionTitle}>Linked Libraries</div>
                </Stack>
                <Stack>
                    <div className={classes.selectStyle}>Select the Policy Libraries where this policy will be available</div>
                </Stack>
                <Stack>
                    <div>
                        <Dropdown
                                placeholder="Select options"
                                defaultSelectedKey={linkedLibraries}
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={this.onChangeLinkLibraries}
                                options={availLibraries}
                                className={classes.dropdownStyles}
                        />
                    </div>
                </Stack>
                <Stack>
                    <div className={classes.sectionTitle}>Resource Selection Scope</div>
                </Stack>
                <Stack>
                    <div className={classes.selectStyle}>Type the selection scope to search to associate the resource to a policy definition.</div>
                </Stack>
                <Stack>
                    <div>
                        <Dropdown
                            placeholder="Select options"
                            defaultSelectedKey={selectionScopes}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.onChangeSelectionScope}
                            options={availSelectionScopes}
                            className={classes.dropdownStyles}
                        />
                    </div>
                </Stack>
                <Stack>
                    <div className={classes.sectionTitle}>Policy Linking</div>
                </Stack>
                <Stack>
                    <div className={classes.selectStyle}>Type the policy name, or related role, task, Decision Point or Checkpoint name to search for available linking</div>
                </Stack>
                <Stack>
                    <div>
                        <Dropdown
                            placeholder="Select options"
                            selectedKeys={linkedResources}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.onChangeLinkResources}
                            multiSelect
                            options={availResourceTypes}
                            className={classes.dropdownStyles}
                        />
                    </div>
                    <div className={classes.linkedBox}>{this.displayLinkedResources(classes)}</div>
                </Stack>
            </>
        );
    }

    buildConfirmationScreen () {
        const { classes } = this.props;
        return (
            <div className={classes.card} style={{ textAlign: 'center' }} >
                <Stack>
                    <div className={classes.todo}><img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /></div>
                </Stack>
                <Stack>
                    <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>Success</div>
                </Stack>
                <Stack>
                    <div className={classes.todo}>Policy master details has been updated</div>
                </Stack>
                <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
                    <DefaultButton text="Close" onClick={this.onDismissPolicyMasterDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Close')} />
                </Stack>
            </div>
        );
    }

    render () {
        const { literalType } = this.state;

        // eslint-disable-next-line no-return-assign
        return (
            <Panel
                isOpen={this.props.isMasterDetailsPanelOpen}
                onDismiss={this.onDismissPolicyMasterDetailsPanel}
                type={PanelType.custom}
                customWidth={'650px'}
                closeButtonAriaLabel="Close"
                headerText={literalType.title}
            >
                <Separator></Separator>
                {this.state.isCurrentlyInSelectionMode
                ? this.buildPolicyMasterEditor()
                : this.buildConfirmationScreen()
                }
            </Panel>
        );
    }
}

const OliLogo = require('../../../images/oli-success.svg');

// Example formatting
const stackTokens = { childrenGap: 40 };

const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: '#f3f2f1'
    },
    root: {
        flexGrow: 1
    },
    informational: {
        margin: '0 15px 15px 15px',
        padding: '15px',
        borderRadius: '2px',
        backgroundColor: '#f4f4f4'
      },
      archiveCheckbox: {
        marginLeft: '1rem'
      },
    title: {
        padding: '60px 0 60px 0',
        color: '#605e5c',
        backgroundColor: '#fff',
    },
    inputArea: {
        padding: '0 10px 10px 10px',
        borderRadius: '2px',
    },
    headerField: {
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgb(50, 49, 48)',
        padding: '5px 0px',
    },
    message: {
        marginTop: '0.5rem'
    },
    instruction: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '16px 0 0 0'
    },
    catStyle: {
        padding: '0 0 0 0'
    },
    basicStyles: {
        maxWidth: '600',
        border: '0px solid  #dcdcdc',
        margin: '0'
    },
    tagPickerClass: {
        inlineSize: '450px',
        overflowWrap: 'break-word',
    },
    compositeTextStyle: {
        fontSize: '16px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 0 0'
    },
    compositeTextStyle2: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
    },
    sectionTitle: { // used
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 15px 0',
    },
    selectStyle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '0 0 5px 0',
    },
    actions: {
        padding: '20px 0 20px 0'
    },
    selectedRuleBlock: {
        padding: '20px 0 0 0'
    },
    previewPart: {
        backgroundColor: '#f6f6f6'
    },
    stackItemInfo: {
        alignItems: 'center',
        display: 'block',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    stackItemDelete: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        maxWidth: 80,
        minWidth: 80,
        borderLeft: '2px solid lightgrey',
    },
    whiteBtn: {
        backgroundColor: 'transparent',
        border: '0px',
        padding: '10px 0 10px 0'
    },
    card: { // used
        padding: '15px', /* JUST TO LOOK COOL */
        border: '1px solid #eee',  /* JUST TO LOOK COOL */
        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
        transition: 'all .3s ease-in-out',
    },
    withSubmitError: { // used
        color: 'red',
    },
    button: {
        fontWeight: '400',
    },
    dropdownStyles: {
        maxWidth: 400,
        minWidth: 400,
    },
    linkedLibrary: {
        border: 'solid 1px #979797;',
        padding: '6px 13px 6px 16px',
        borderRadius: '23.5px',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        display: 'inline-block'
      },
      linkedBox: {
        padding: '15px 0',
      },
});

MasterDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
        policySchemeConfigurationsNames: state.policyLibraryBuilderData.policySchemeConfigurationsNames,
        updatePolicyMasterResult: state.policyLibraryMasterData.updatePolicyMasterResult,
        policySchemeSelectionScopeMappings: state.policyLibraryBuilderData.policySchemeSelectionScopeMappings,
        keySystemResourcePolicyDefinitionMappings: state.policyLibraryBuilderData.keySystemResourcePolicyDefinitionMappings,
        notificationMasters: state.notificationMastersData.notificationMasters,
    });

const connected = connect(mapStateToProps, {
    fetchPolicyConfigurationNames,
    updatePolicyMasterDetails,
    fetchPolicySchemeSelectionScopeMappings,
    fetchPolicyKeySystemResourcePolicyDefinition,
    fetchNotificationMasters,
})(AuthProvider(MasterDetails));

export default withStyles(styles)(connected);
