import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import '../../components/style.css';

class PolicyLibraryMasterCard extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            totalnoOfSchemes: props.totalnoOfSchemes,
            title: props.title,
            plsIndex: props.index,
            policySchemeTypeId: props.policySchemeTypeId,
            policySummaryList: props.policySummaryList,
            policySchemesList: props.policySchemesList,
            classes: PropTypes.object.isRequired,
            open: false,
            show: false
        };
    }

    onHide = () => {
        this.setState({ show: false });
    }

    onShowFullSummary = () => {
        this.setState({ show: true });
    }

    handleClick = (event, index) => {
        this.setState((state) => ({ open: !state.open, [index]: true }));
    };

    renderListItemsDetails () {
        const { classes } = this.props;
        return this.props.policySchemesList.map((policies, index) => {
            return (<div key={`d1-${policies.id}`}>
                    <ListItem
                        key={`li-${policies.id}-${index}`}
                        button
                        onClick={() => this.handleClick(index)}>
                        <ListItemText className={classes.listView}>{policies.name}
                            {this.state.open ? <ExpandLess/> : <ExpandMore/>}</ListItemText>
                    </ListItem>
                    <Collapse in={this.state.open} timeout="auto"
                              unmountOnExit>
                        <div className={classes.fieldRow} key={`dt1-${policies.id}`}>
                            Policy Scheme ID : {policies.id}
                        </div>
                        <div className={classes.fieldRow} key={`dt2-${policies.id}`}>
                            Client ID : {policies.clientId}
                        </div>
                        <div className={classes.fieldRow} key={`dt3-${policies.id}`}>
                            Description : {policies.description}
                        </div>
                    </Collapse>
                </div>
            );
        });
    }

    prepareForDisplayPolicyDescription (policyDescription) {
        let val = (policyDescription ? policyDescription : 'TODO. No policy schemes summary description from API');
        if (val.length > 175) {
            return val.substring(0, 175) + '...';
        } else {
            return val;
        }
    }

    render () {
        const { classes, policyDescription } = this.props;
        const description = this.prepareForDisplayPolicyDescription(policyDescription);

        return (
        <Box m={1}>
        <Card className={classes.card} variant='outlined' style={{ width:'412px' }}>
            <CardActionArea component="div">
                <CardHeader className={this.props.classes.cardHeaderSection}
                            title={<Typography className={classes.cardHeader}>
                                {this.state.title}
                            </Typography>}/>
                <CardContent>
                <Link className={classes.editButton} to={`/policyLibraryMasterConsole/library/${this.state.policySchemeTypeId}`} style={{ textDecoration: 'none' }}>
                View Policy Library
                </Link>
                    <hr className={classes.sepLine}/>
                    <div className={classes.subtitle}>POLICY SUMMARY</div>
                    {/* <div className={classes.fieldRow}>
                        <>{description}</>
                    </div> */}
                    <div className={classes.fieldRow}>
                        <span className={classes.fieldName}>{this.state.totalnoOfSchemes} policy Schemes</span>
                    </div>
                    <div className={classes.viewLink}>
                        <button onClick={this.onShowFullSummary} className={classes.viewMoreLink}>View Full Summary</button>
                    </div>
                    {this.state.show && (
                        <div className={classes.modal}>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <div></div>
                                </Box>
                                <Box>
                                    <div></div>
                                </Box>
                                <Box>
                                    <button onClick={this.onHide} className={classes.closeButton}>X</button>
                                </Box>
                                <Box>

                                </Box>
                            </Box>
                            <div className={classes.popUpTitle}>{this.state.title} Summary</div>
                            <hr className={classes.sepLine}/>
                            <div className={classes.fieldRow}>
                                <span className={classes.fieldName}>Description</span>
                            </div>
                            <div className={classes.fieldRow}>
                                <span>{description}</span>
                            </div>
                            <div className={classes.fieldRow}>
                                <span className={classes.fieldName}>{this.state.totalnoOfSchemes} policy Scheme</span>
                            </div>
                            <div className={classes.subtitle}>Policy Scheme(s) Details</div>
                                {this.renderListItemsDetails()}
                        </div>
            )}
                </CardContent>
            </CardActionArea>
        </Card>
        </Box>
        );
    }
}

const styles = (theme) => ({
  cardHeader: {
    fontFamily:'PharmaCond',
    fontSize: '24px',
    color:'#323130',
    fontWeight: '600',
    },
   cardHeaderSection: {
       padding: '16px 0 0 16px',    
   },
  card: {
    borderColor: blue[200],
    width: 200,
    height: 320,
    color: '#000',
    margin: '8px 16px'
  },
    subtitle: {
        padding: '16px 0',
        fontWeight: '600',
        fontFamily: 'SegoeUI'
    },

    fieldRow: {
        padding: '8px 0',
    },

    fieldName: {
        fontWeight: '600'
    },

    fieldRowDescription: {
        borderRadius: '2px',
        border: 'solid 1px #a19f9d',
        padding: '8px 0',
    },

    fieldDescription: {
    },

    listView: {
        padding: '18px 5',
        backgroundColor: '#DCDCDC',
    },
    sepLine: {
        margin: '16px 0',
        color: '#bdbdbd'
    },

    viewLink: {
        color: '#006ed2'

    },

    modal: {
        position: 'fixed',
        top:  '0',
        right: '0',
        bottom: '0',
        left: '65%',
        padding: '16px 32px',
        boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
        backgroundColor: '#fff',
        zIndex:'1000',
        overflow: 'auto'
  },

  viewPolicyLibraryButton:{
    backgroundColor: '#0078d4',
    border: 'none',
    color: '#fff',
    padding: '5px 12px 7px 16px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    borderRadius: '2px'
  },

  closeButton:{
      backgroundColor: '#fff',
      border:'0',
      color:'#605e5c',
      fontSize:'18px',
      padding:'0 16px',
      marginRight:'16px',
      cursor:'pointer'
  },

  popUpTitle: {
      fontSize: '20px',
      fontWeight: '600',
      fontStretch: 'normal',
      fontStyle: 'normal',
      color: '#323130',
   
  },

  viewMoreLink:{
      border: '0',
      backgroundColor:'#fff',
      color:'#006ed2',
      fontSize: '14px'
  },

  editButton: {
    backgroundColor: '#0078d4',
    border: 'none',
    color: '#fff',
    padding: '5px 12px 7px 16px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    borderRadius:'2'
  },

});

PolicyLibraryMasterCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PolicyLibraryMasterCard);
