/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';

import Select from 'react-select';

import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';

import { fetchProjectSummaries } from '../../../state/actions/ProjectSummariesAction';
import { fetchNotificationMasters, fetchNotificationMasterByRecipientStrategies } from '../../../state/actions/NotificationMastersAction';
import AuthProvider from '../../../components/auth/AuthProvider';

import './NotificationRecipientResolutionPolicy.css';

const REFDATA_NOTIFICATION_RECIPIENT_STRATEGY_TYPE = [
  { text: 'All Project Owners Strategy', key: '2377e024-49af-41f0-8dca-09e5ce67ac81' },
  { text: 'All Team Members Strategy', key: '31cca2a4-3814-46d6-9c2e-e2db899ad617' },
  { text: 'Approver Strategy', key: 'ae829314-cdf8-4b4a-b45a-b8c0c504d4cd' },
  { text: 'Checkpoint Responsible Strategy', key: '4467a7fb-8fc5-4514-9792-3867e1800fe3' },
  { text: 'Function Resolution Strategy', key: '57fe78b4-3569-488c-9e86-91d598117658' },
  { text: 'Project Owner Strategy', key: '0f53ea5f-3864-4418-8646-5211e42b908b' },
  { text: 'Task Assignee Strategy', key: '850d471d-1d07-490f-9a73-c6ab96e63462' },
  { text: 'Task Mentioned User Strategy', key: '36380263-f252-4811-bb71-e09e8cc4bec6' },
  { text: 'Team Member Strategy', key: '795cf096-4571-4b94-89f8-de5fcef698b8' },
  { text: 'Verification Plan Checkpoint Supervisor Strategy', key: '42c2b5e8-257f-41fa-bd89-e0432b3ec631' },
];

class NotificationRecipientResolutionPolicy extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            notificationMasters: [],
            projects: [],
            searchResults: [],

            attrNotificationMaster: null,
            attrProjectId: null,
        };
    }

    async componentDidMount () {
      await this.props.fetchProjectSummaries();
      await this.props.fetchNotificationMasters();

      if (this.props.notificationMastersData && this.props.notificationMastersData.masters) {
        const notificationMasters = [];
        this.props.notificationMastersData.masters.map((p, idx) => {
          notificationMasters.push({ label: `${p.name} (${p.code})`, value: p.id, key: p.name });
        });

        // sort by value
        notificationMasters.sort((a, b) => {
          if (a.key < b.key) { return -1; }
          if (a.key > b.key) { return 1; }
          return 0; // default return value (no sorting)
        });

        this.setState((prevState) => ({
          ...prevState,
          notificationMasters,
        }));
      }

      if (this.props.projectSummariesData && this.props.projectSummariesData.summaries) {
        const projects = [];
        this.props.projectSummariesData.summaries.map((p, idx) => {
          projects.push({ label: p.projectName, value: p.projectId, key: p.projectName.toLowerCase() });
        });

        // sort by value
        projects.sort((a, b) => {
          if (a.key < b.key) // sort string ascending
              { return -1; }
          if (a.key > b.key) return 1;
          return 0; // default return value (no sorting)
        });

        this.setState((prevState) => ({ ...prevState, projects }));
      }
    }

    render () {
        const isDisabled = this.isDisabledValidateTrigger();

        return (
            <Card variant='outlined' className={this.props.classes.rootBackground}>
                <Box component="span" display="block" className={this.props.classes.cardContentBox}>
                    <CardContent className={this.props.classes.cardContent}>
                      <div className={this.props.classes.dataPanel}>
                        <div className={this.props.classes.inputColumnA}>
                              Search for notification type<Select
                                styles={customProjectStyles}
                                options={this.state.notificationMasters}
                                onChange={this.changeNotificationHandler}
                              />
                          </div>
                          <div className={this.props.classes.inputColumnA}>
                              Search for project name<Select
                                styles={customProjectStyles}
                                options={this.state.projects}
                                onChange={this.changeProjectHandler}
                              />
                          </div>
                      </div>
                    </CardContent>

                    <br />

                    <div className={this.props.classes.fetchControl}>
                        <PrimaryButton text="Validate" onClick={this.fetchValidationData} allowDisabledFocus disabled={isDisabled} />
                    </div>
                </Box>
                <br />
                { this.state.searchResults && this.state.searchResults.length > 0
                  ? (<div className={this.props.classes.resultContentBox}>
                    {this.presentSearchResult()}
                  </div>)
                :  <div>No result yet</div>
                }
            </Card>
        );
    }

    changeNotificationHandler = (newValue, actionMeta) => {
      this.setState((prevState) => ({
        ...prevState,
        attrNotificationMaster: newValue.value
      }));
    }

    changeProjectHandler = (newValue, actionMeta) => {
      this.setState((prevState) => ({
        ...prevState,
        attrProjectId: newValue.value
      }));
    }

    isDisabledValidateTrigger () {
      return (!(this.state.attrNotificationMaster && this.state.attrProjectId));
    }

    fetchValidationData = async () => {
        const params = {
          notificationMasterId: this.state.attrNotificationMaster,
          projectId: this.state.attrProjectId,
        };
        await this.props.fetchNotificationMasterByRecipientStrategies(params);

        // prepare search result
        const searchResults = [];
        this.props.recipientStrategiesData.recipientStrategies.map((m, idx) => {
          // assign arrays
          const recipientStrategy = NotificationRecipientResolutionPolicy.getRecipientStrategy(m.recipientStrategy);

          const recipients = [];
          m.recipients.map((s, idx2) =>  {
            recipients.push({
              // id: s.id,
              displayName: s.displayName,
              officeLocation: s.officeLocation,
            });
          });

          searchResults.push({ recipientStrategy, recipients });
        });

        this.setState((prevState) => ({ ...prevState, searchResults }));
    }

    presentSearchResult = () => {
      const searchResults = (this.state.searchResults.length > 0) ? this.state.searchResults : [];

      return searchResults.map((res, idx) => (
          <div key={`d0-${res.recipientStrategy}`}>
            <div id={`d1-${res.recipientStrategy}`} key={`d1-${res.recipientStrategy}`} className={this.props.classes.resultContainer}>
              <div key={`d2-${res.recipientStrategy}`} className={this.props.classes.rectangleGroupHeader1}>
                <label className={this.props.classes.groupHeader1}>{res.recipientStrategy}</label>
              </div>
              <DetailsList
                items={res.recipients}
                compact={false}
                columns={RESULT_COLUMNS}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
              />
              </div>
          </div>
        ));
    }

    static getRecipientStrategy (id) {
      const res = REFDATA_NOTIFICATION_RECIPIENT_STRATEGY_TYPE.filter((v) => v.key === id);
      if (res.length > 0) {
        return res[0].text;
      }
      return `UNKNOWN*** - ${id}`;
    }
}

const RESULT_COLUMNS = [
  {
    key: 'column1',
    name: 'Name',
    fieldName: 'Name',
    minWidth: 210,
    maxWidth: 300,
    isRowHeader: true,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel: 'Sorted A to Z',
    sortDescendingAriaLabel: 'Sorted Z to A',
    data: 'string',
    isPadded: true,
  },
  {
    key: 'column2',
    name: 'Office Location',
    fieldName: 'Office Location',
    minWidth: 210,
    maxWidth: 300,
    isRowHeader: true,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel: 'Sorted A to Z',
    sortDescendingAriaLabel: 'Sorted Z to A',
    data: 'string',
    isPadded: true,
  },
];

const styles = (theme) => ({
    rootBackground: {
        // height: '100%',
        height: '100vh',
        backgroundColor: 'white'
    },
    root: {
        flexGrow: 1
    },
    cardHeaderSection: {
        padding: '16px 0 16px 23px'
    },
    cardHeader: {
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        padding: '16px',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: { variant: 'h1' },
        title: {
            titleTypographyProps: { variant: 'h1' },
            fontSize: '12px'
        },

    },
    cardContentBox: {
        backgroundColor: '#f4f4f4',
        padding: '50px 30px 0 30px',
        margin: '0 60px 0 60px',
    },
    cardContent: {
        display: 'flex',
        flexFlow: 'row wrap',
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        padding: '0 0 0 0',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: { variant: 'h1' },
        title: {
            titleTypographyProps: { variant: 'h1' },
            fontSize: '12px'
        },
    },
    fetchControl: {
        padding: '0 0 10px 0',
    },
    inputColumnA: {
        flex: '33%',
    },
    inputColumnB: {
        flex: '66%',
    },
    validationHeader: {
        fontSize: '16px',
        fontWeight: '600',
    },
    dataPanel: {
        padding: '0 0 0 0',
    },
    resultContentBox: {
      backgroundColor: 'white',
      padding: '0 30px 0 30px',
      margin: '0 60px 0 60px',
    },
    resultContainer: {
      cursor: 'pointer',
      position: 'relative',
      padding: '20px 0 0 0',
    },
    tableColumn: {
      flex: '33%',
    },
    seeDetailsBox: {
      backgroundColor: '#f4f4f4',
      padding: '5px 0 5px 0',
    },
    PDPLOABUSINESS001: {
      fontFamily: 'SegoeUI',
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#006ed2',
    },
    seeDetails: {
      fontFamily: 'SegoeUI',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#888888',
    },
    panelRect: {
      padding: '0 0',
      // backgroundColor: 'white',
      display: 'none',
      overflow: 'hidden',
      backgroundColor: '#f4f4f4',
      margin: '10px 0 0 0',
    },
    rectInnerHeader: {
      display: 'flex',
      flexFlow: 'row wrap',
      backgroundColor: 'white',
      border: 'solid 1px #dcdcdc',
      padding: '5px'
    },
    rectInnerContent: {
      display: 'flex',
      flexFlow: 'row wrap',
      backgroundColor: 'white',
      border: 'solid 1px #dcdcdc',
    },
    tableInnerColumnHeader: {
      flex: '33%',
      fontWeight: 'bold',
    },
    tableInnerColumn: {
      flex: '33%',
    },
    tableTextInnerColumn: {
      margin: '0 0 0 18px',
    },
    labelDecor: {
      fontWeight: 'bold',
      width: '10%',
      float: 'left',
    },
    rectangleGroupHeader1: {
      width: '150px',
      height: '20px',
      margin: '0 1002px 8px 0',
      padding: '2px 11px 2px 8px',
      borderRadius: '2px',
      backgroundColor: '#dcdcdc',
    },
    groupHeader1: {
      width: '108px',
      height: '16px',
      fontFamily: 'SegoeUI',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.6',
      letterSpacing: 'normal',
      color: '#323130',
    },

});

const customProjectStyles = {
  container: (provided) => ({
    ...provided,
    width: 550
  })
};

NotificationRecipientResolutionPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    projectSummariesData: state.projectSummariesData.projectSummaries,
    notificationMastersData: state.notificationMastersData.notificationMasters,
    recipientStrategiesData: state.notificationMastersData.recipientStrategies,
  });

const connected = connect(mapStateToProps, {
  fetchNotificationMasters,
  fetchProjectSummaries,
  fetchNotificationMasterByRecipientStrategies,
 })(AuthProvider(NotificationRecipientResolutionPolicy));

export default withStyles(styles)(connected);
