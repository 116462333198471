import {
  FETCH_ADMIN_CHANGE_MASTERS, FETCH_ADMIN_CHANGE_SETS, FETCH_ADMIN_CHANGE_MASTERS_FILTERS, FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS, FETCH_ADMIN_CHANGE_MASTERS_SELECTED_FILTERS
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  changeMasters: [],
  error: null,
  changeItem: {},
  changeSets: [],
  changeMastersFilters: [],
  changeWithFilters: [],
  changeSelectedFilters:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ADMIN_CHANGE_MASTERS:
      return { ...state, changeMasters: action.payload };
    case FETCH_ADMIN_CHANGE_SETS:
      return { ...state, changeSets: action.payload };
    case FETCH_ADMIN_CHANGE_MASTERS_FILTERS:
      return { ...state, changeMastersFilters: action.payload };
    case FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS:
      return { ...state, changeWithFilters: action.payload };
    case FETCH_ADMIN_CHANGE_MASTERS_SELECTED_FILTERS:
      return { ...state, changeSelectedFilters: action.payload };
    default:
      return state;
  }
};
