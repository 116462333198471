import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import { withStyles } from '@material-ui/styles';
import history from '../history';
import ReferenceDataConsole from '../pages/referenceData/ReferenceDataConsole';
import TaskMaster from '../pages/taskMaster/TaskMasterConsole';
import CheckpointMaster from '../pages/checkPointMaster/CheckPointMasterConsole';
import AuthProvider from './auth/AuthProvider';

class SecuredApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardItems: ['Reference Data', 'Decision Point Master', 'Checkpoint Master', 'Task Master', 'Notification Masters', 'Policy Library Master']

    };
  }

  clickMaster = (title) => {
    let targetLibrary;

    switch (title) {
      case 'Reference Data':
        targetLibrary = '/referenceDataConsole';
        break;
      case 'Checkpoint Master':
        targetLibrary = '/checkPointMasterConsole';
        break;
      case 'Task Master':
        targetLibrary = '/taskLibraryMasterConsole';
        break;
      case 'Policy Library Master':
        targetLibrary = '/policyLibraryMasterConsole';
        break;
      case 'Notification Masters':
        targetLibrary = '/notificationLibraryConsole';
        break;
      default:
        targetLibrary = '/secure';
    }

    history.push(targetLibrary);

  }

  renderCards() {
    const { classes } = this.props;
    let isDisabled = false;

    return this.state.cardItems.map((ia) => {

      if (ia === 'Decision Point Master') {
        isDisabled = true;
      }
      else {
        isDisabled = false;
      }

      return (<Box m={1} >
        <Card className={classes.card} variant='outlined' style={{ width: '626px' }}>
          <CardActionArea component="div">
            <CardHeader className={classes.cardHeaderSection}
              title={<Typography gutterBottom className={classes.cardHeader}>
                {ia}
              </Typography>} />
            <CardContent className={classes.cardContent}>
              <hr className={classes.sepLine} />
              <button className={isDisabled ? classes.masterButtonDisabled : classes.masterButton} disabled={isDisabled}
                onClick={() => { this.clickMaster(ia) }}>View {ia} Libraries</button>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>)
    });
  }

  render() {
    const { classes } = this.props;

    if (!this.props.accountInfo) return <div></div>;

    return <div>
      <Router history={history}>
        <Switch>
          <Route path="/referenceDataConsole" exact component={ReferenceDataConsole} />
          <Route path="/taskLibraryMasterConsole" exact component={TaskMaster} />
          <Route path="/checkPointMasterConsole" exact component={CheckpointMaster} />
        </Switch>
      </Router>

      <Box display="flex" alignItems="center" justifyContent="center">
        <Card variant='outlined' className={classes.rootBackground}>
          <CardHeader className={classes.cardHeaderTitle}
            title="Master Libraries" />
          <CardContent className={classes.rootCardContent}>
            <Box mt={1} container="true" display="flex" flexDirection="row" justify-content="center"
              flexWrap="wrap" alignContent="center" width={1} styles={{ alignItem: "center" }}>
              {this.renderCards()}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  }
};

const styles = (theme) => ({
  rootBackground: {
    height: '100%',
    backgroundColor: '#f3f2f1'
  },

  root: {
    flexGrow: 1
  },
  cardHeaderTitle: {
    fontFamily: 'PharmaCond',
    fontSize: '27px',
    color: '#323130',
    fontWeight: '600',
    padding: '16px 44px'
  },

  cardHeader: {
    fontFamily: 'PharmaCond',
    fontSize: '24px',
    color: '#323130',
    fontWeight: '600'

  },

  cardHeaderSection: {
    padding: '16px 0 0 0'
  },

  card: {
    borderColor: 'rgba(0, 0, 0, 0.13)',
    padding: '16px 25px 0 24px',
    borderRadius: '2px',
    boxShadow: '0 0.3px 0.9px 0 rgba(0, 0, 0, 0.11), 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13)',
    width: '626px',
    color: '#000',
    margin: '8px 16px'
  },

  cardContent: {
    padding: '16px 0'
  },

  rootCardContent: {
    padding: '0 16px'
  },

  sepLine: {
    margin: "16px 0",
    color: '#bdbdbd'
  },

  breadcrumb: {
    fontSize: '15px'
  },

  masterButton: {
    padding: '5px 19px 7px',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    backgroundColor: '#ffffff',
    color: '#323130',
    cursor: 'pointer'
  },

  masterButtonDisabled: {
    padding: '5px 19px 7px',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    backgroundColor: '#c8c8c8',
    color: '#605e5c',
    cursor: 'not-allowed'
  }

});

SecuredApp.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(AuthProvider(SecuredApp));
