import React from 'react';

import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
    DefaultButton, PrimaryButton, initializeIcons, TagPicker, Dialog, DialogType, DialogFooter
} from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner } from '@fluentui/react/lib/Spinner';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import AuthProvider from '../../auth/AuthProvider';
import { fetchCompatibleRulesByCompositeRule, fetchPolicyRuleVariablesByRule, addRuleToCompositeRule, modifyRuleToCompositeRule } from '../../../state/actions/PolicyRuleActions';
import { RulePickerActionTypeLiteral } from '../../../constants/ActionTypeObj';
import RuleConfigValue from '../ruleConfigValue/RuleConfigValue';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';

import './rulePicker.css';

const isReadOnly = getReadOnlyFlagPerEnv();

class RulePicker extends React.Component {
    constructor (props) {
        super(props);
        const literalType = RulePickerActionTypeLiteral.filter((value) => value.type === this.props.literalType)[0];
        this.state = {
            literalType,
            rules: [],
            options: [],
            compositePolicy: [],
            compatibleCompositeRules: [],
            pickerOptions: [],
            selRule: null,
            selRuleConfigValues: null,
            isTagPickerDisabled: false,
            isAddRuleInProgress: false,
            withSubmitError: false,
            withValidationError: false,
            isCurrentlyInSelectionMode: true, // 2 modes, Selection (true) and Confirmation (false)
            hideDialogDeleteRule: true
        };
        initializeIcons();
    }

    async componentDidMount () {
        this.prepareFormContent();
        document.title = 'Rule Picker';
    }

    async prepareFormContent () {
        await this.props.fetchCompatibleRulesByCompositeRule(this.props.compositePolicy.id);
        if (this.props.action === 'edit') await this.props.fetchPolicyRuleVariablesByRule(this.props.selRuleForEdit.rule.id);
        const validOptions = this.filterAndAdoptRulesAsOptions(this.props.compatibleCompositeRules);
        let selRule = null;
        let selRuleConfigValues = null;
        if (this.props.action === 'edit') {
            selRule = this.props.selRuleForEdit.rule;
            selRuleConfigValues = this.props.selRuleForEdit.compositePolicyRuleInstanceConfigurations[0].configurationValue.split(',');
        } else {
            selRuleConfigValues = [""];
        }
        this.setState((prevState) => ({ ...prevState, compatibleCompositeRules: this.props.compatibleCompositeRules, pickerOptions: validOptions, selRule, selRuleConfigValues }));
    }

    filterAndAdoptRulesAsOptions (compatibleCompositeRules) {
        const validOptions = [];
        let emptySelectionId = true;
        if (this.props.existingRuleIds.length > 0) {
            emptySelectionId = false;
        }
        compatibleCompositeRules.forEach((element) => {
            let compatible = true;
            if (this.props.existingRuleIds.length > 0) {
                const result = this.props.existingRuleIds.filter((id) => id === element.id);
                compatible = result.length > 0; // when found
            }
            if (!compatible || emptySelectionId) {
                validOptions.push({
                    key: element.id,
                    name: element.ruleTitle,
                    rule: element,
                });
            }
        });
        return validOptions;
    }

    onDismissRulePickerPanel = () => {
        this.props.dismissRulePickerPanel();
    }

    _handleClearRule = () => {
    }

    filterSuggestedTagsReal = (filterText, tagList) => (filterText
        ? this.state.pickerOptions.filter(
            (tag) => tag.name.toLowerCase().includes(filterText.toLowerCase()) && !listContainsTagList(tag, tagList),
        )
        : []);

    onEmptySuggestedTagsReal = (tagList) => {
        if (tagList && tagList.length === 0) {
            return this.state.pickerOptions;
        }
        return [];
    };

    getTextFromItemReal = (item) => {
        return item.name;
    };

    // eslint-disable-next-line no-sequences
    onItemSelected = (item) => {
        // fetch rule variables
        // await this.props.fetchPolicyRuleVariablesByRule(item.rule.id);
        this.props.fetchPolicyRuleVariablesByRule(item.rule.id);
        this.setState((prevState) => ({ ...prevState, selRule: item.rule, isTagPickerDisabled: true }));
        return null;
    };

    // eslint-disable-next-line no-sequences
    toggleEnableTagPicker = () => {
        this.setState((prevState) => ({ ...prevState, selRule: null, isTagPickerDisabled: false, withSubmitError: false, withValidationError: false, selRuleConfigValues: null }));
    };

    // eslint-disable-next-line no-sequences
    toggleDeleteRule = () => {
        this.setState((prevState) => ({ ...prevState, hideDialogDeleteRule: false }));
    };

    // eslint-disable-next-line no-sequences
    createDeleteRuleDialog () {
        return (
        <>
            <Dialog
                hidden={this.state.hideDialogDeleteRule}
                onDismiss={this.cancelDeleteRule}
                dialogContentProps={dialogContentProps}
                modalProps={{ isBlocking: true, styles: modalPropsStyles }}
            >
                <DialogFooter>
                <DefaultButton onClick={this.cancelDeleteRule} text="Cancel" />
                <PrimaryButton onClick={this.confirmDeleteRule} text="Delete" />
                </DialogFooter>
            </Dialog>
        </>);
    };

    confirmDeleteRule = () => {
        this.setState((prevState) => ({ ...prevState, hideDialogDeleteRule: true }));
        this.props.handleDeleteRuleFromComposite();
    };

    cancelDeleteRule = () => {
        this.setState((prevState) => ({ ...prevState, hideDialogDeleteRule: true }));
    };

    // eslint-disable-next-line no-sequences
    processRule = async () => {
        if (this.selectionIsValid()) {
                this.setState((prevState) => ({ ...prevState, isAddRuleInProgress: true }));

            // fetch rule variables
            await this.props.fetchPolicyRuleVariablesByRule(this.state.selRule.id);

            // build rule object
            const rule = this.adoptSelectionToRule();

            const { action } = this.props;

            if (action === 'add') {
                // add rule
                await this.props.addRuleToCompositeRule(this.props.compositePolicy.id, rule);
            } else {
                // add rule
                await this.props.modifyRuleToCompositeRule(this.props.compositePolicy.id, rule);
            }

            const { status } = this.props.modifyRuleResult;
            if (status && (status === '409' || status === '500')) {
                this.prepareFormContent();
                this.setState((prevState) => ({ ...prevState, isCurrentlyInSelectionMode: true, isAddRuleInProgress: false, withSubmitError: true }));
            } else {
                this.setState((prevState) => ({ ...prevState, isCurrentlyInSelectionMode: false, isAddRuleInProgress: false, withSubmitError: false }));
            } 
        } else {
            this.setState((prevState) => ({ ...prevState, withValidationError: true }));
        }
    };

    selectionIsValid () {
        return this.state.selRuleConfigValues?.length || this.state.selRuleConfigValues.length === 0 || this.state.selRuleConfigValues.length === '';
    }

    adoptSelectionToRule () {
        const ruleVariables = [];
        let configVal = this.state.selRuleConfigValues.length === 1 ? ''.concat(this.state.selRuleConfigValues[0]) : this.state.selRuleConfigValues.join();
        configVal = configVal.replace(/^(,)/, '');
        configVal = configVal.replace(/^(NONE)/, '');
        configVal = configVal.replace(/^(,)/, '');
        configVal = configVal === '' ? 'NONE' : configVal;

        this.props.ruleVariables.forEach((item) => {
            ruleVariables.push({
                variableId: item.id,
                variableName: item.variableName,
                configurationValue: configVal
            });
        });

        const rule = {
            ruleId: this.state.selRule.id,
            ruleVariables
        };

        return rule;
    }

    updateForRuleConfigValues = (newSelRuleConfigValues) => {
        this.setState((prevState) => ({ ...prevState, selRuleConfigValues: newSelRuleConfigValues }));
    }

    withValidRuleValue () {
        return !(this.state.selRuleConfigValues && this.state.selRuleConfigValues.length > 0 && !this.state.isTagPickerDisabled);
    }

    onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

    render () {
        const { classes, compositePolicy, action } = this.props;
        const { literalType } = this.state;
        const title = action === 'add' ? literalType.title : literalType.edit;

        // eslint-disable-next-line no-return-assign
        return (
            <Panel
                isOpen={this.props.isRulePickerPanelOpen}
                onDismiss={this.onDismissRulePickerPanel}
                type={PanelType.custom}
                customWidth={'650px'}
                closeButtonAriaLabel="Close"
                headerText={title}
            >
                <Separator></Separator>
                {this.state.isCurrentlyInSelectionMode
                ? <>
                    { !this.state.isAddRuleInProgress
                    ? <>
                        <Stack>
                            <div className={classes.compositeTextStyle}>Composite Rule: {compositePolicy.policyTitle}</div>
                        </Stack>
                        { action === 'add'
                        ? <>
                            <Stack>
                                <div className={classes.sectionTitle}>SEARCH RULE</div>
                            </Stack>
                            <Stack>
                                <div className={classes.selectStyle}>Type rule name to see available options</div>
                            </Stack>
                            <Stack>
                                <div>
                                    <TagPicker
                                        removeButtonAriaLabel="Remove"
                                        selectionAriaLabel="Selected colors"
                                        onResolveSuggestions={this.filterSuggestedTagsReal}
                                        onEmptyResolveSuggestions={this.onEmptySuggestedTagsReal}
                                        getTextFromItem={this.getTextFromItemReal}
                                        pickerSuggestionsProps={pickerSuggestionsProps}
                                        itemLimit={1}
                                        pickerCalloutProps={{ doNotLayer: false }}
                                        onItemSelected={(item) => this.onItemSelected(item)}
                                        disabled={this.state.isTagPickerDisabled || isReadOnly}
                                    />
                                </div>
                            </Stack>
                        </> : null }

                        {this.state.selRule
                        ? <>
                            <Stack horizontal tokens={innerStackTokens}>
                                <Stack.Item grow className={classes.stackItemInfo}>
                                    <div className={classes.compositeTextStyle}>Rule: {this.state.selRule.ruleTitle}</div>
                                    <div className={classes.todo}>{this.state.selRule.ruleCode}</div>
                                    <div className={classes.compositeTextStyle2}>{this.state.selRule.ruleDescription}</div>
                                </Stack.Item>
                                { action === 'add'
                                ? <>
                                    <Stack.Item grow disableShrink className={classes.stackItemDelete}>
                                        <button className={classes.whiteBtn} disabled={isReadOnly} onClick={() => this.toggleEnableTagPicker()} ><Icon iconName="Delete" className={classes.iconStyle} /></button>
                                    </Stack.Item>
                                </> : null }
                                { action === 'edit'
                                ? <>
                                    <Stack.Item grow disableShrink className={classes.stackItemDelete}>
                                        <button className={classes.whiteBtn} disabled={isReadOnly} onClick={() => this.toggleDeleteRule()} ><Icon iconName="Delete" className={classes.iconStyle} /></button>
                                    </Stack.Item>
                                </> : null }
                            </Stack>
                            <Stack>
                                <Stack>
                                    <div className={classes.sectionTitle}>CONFIGURATION VALUE</div>
                                </Stack>
                                {this.props.ruleVariables ? <RuleConfigValue mode="edit" rule={this.state.selRule}
                                isReadOnly={isReadOnly}
                                ruleConfigValues={this.state.selRuleConfigValues} updateForRuleConfigValues={this.updateForRuleConfigValues} refdata={this.props.refdata} xxruleVariables={this.props.ruleVariables} taskMasterDataRef={this.props.taskMasterDataRef} /> : null }
                            </Stack>
                        </>
                        : null}

                        {!this.state.hideDialogDeleteRule ? this.createDeleteRuleDialog() : null }

                        {this.state.withValidationError
                        ? <Stack className={classes.previewPart}>
                            <Stack>
                                <div className={classes.withSubmitError}>Please enter value or select from the given options.</div>
                            </Stack>
                        </Stack>
                        : null }
                        <Stack horizontal tokens={stackTokens} className={classes.actions}>
                            <DefaultButton text="Cancel" onClick={this.onDismissRulePickerPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
                            <PrimaryButton disabled text="Preview" />
                            { action === 'add' ? <PrimaryButton text="Add Rule" disabled={!this.state.isTagPickerDisabled || isReadOnly} onClick={this.processRule} /> : null}
                            { action === 'edit' ? <PrimaryButton text="Update Rule" disabled={isReadOnly} onClick={this.processRule} /> : null}
                        </Stack>
                        {this.state.withSubmitError
                        ? <Stack className={classes.previewPart}>
                            <Stack>
                                <div className={classes.withSubmitError}>{this.props.modifyRuleResult.message}</div>
                            </Stack>
                        </Stack>
                        : null }
                        <Separator></Separator>
                        <Stack className={classes.previewPart}>
                            <div className={classes.sectionTitle}>CHANGE PREVIEW</div>
                            <Stack>
                                <div className={classes.todo}>Add a rule and select preview to see policy changes</div>
                            </Stack>
                        </Stack>
                    </>
                    : <Stack horizontalAlign="center" >
                        <Spinner label="Add new rule in progress..." />
                    </Stack>
                    }
                  </>
                : <div className={classes.card} style={{ textAlign: 'center' }} >
                    <Stack>
                        <div className={classes.todo}><img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /></div>
                    </Stack>
                    <Stack>
                        <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>Success</div>
                    </Stack>
                    <Stack>
                        <div className={classes.todo}>A new rule has been created</div>
                    </Stack>
                    <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
                        <DefaultButton text="Close" onClick={this.onDismissRulePickerPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Close')} />
                    </Stack>
                </div>
                }
            </Panel>
        );
    }
}

const OliLogo = require('../../../images/oli-success.svg');

const innerStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const pickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested rules',
    noResultsFoundText: 'No rules tags found',
};

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete Rule',
    subText: 'Do you want to delete this rule?',
  };

const modalPropsStyles = { main: { maxWidth: 450 } };

// const modalProps = React.useMemo(
//     () => ({
//         isBlocking: true,
//         styles: modalPropsStyles,
//         }), [],
//     );

const listContainsTagList = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
};

// Example formatting
const stackTokens = { childrenGap: 40 };

const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: '#f3f2f1'
    },
    root: {
        flexGrow: 1
    },
    title: {
        padding: '60px 0 60px 0',
        color: '#605e5c',
        backgroundColor: '#fff',
    },
    instruction: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '16px 0 0 0'
    },
    catStyle: {
        padding: '0 0 0 0'
    },
    basicStyles: {
        maxWidth: '600',
        border: '0px solid  #dcdcdc',
        margin: '0'
    },
    tagPickerClass: {
        inlineSize: '450px',
        overflowWrap: 'break-word',
    },
    compositeTextStyle: {
        fontSize: '16px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 0 0'
    },
    compositeTextStyle2: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
    },
    sectionTitle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '20px 0 20px 0',
    },
    selectStyle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '0 0 5px 0',
    },
    actions: {
        padding: '20px 0 20px 0'
    },
    selectedRuleBlock: {
        padding: '20px 0 0 0'
    },
    previewPart: {
        backgroundColor: '#f6f6f6'
    },
    stackItemInfo: {
        alignItems: 'center',
        display: 'block',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    stackItemDelete: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        maxWidth: 80,
        minWidth: 80,
        borderLeft: '2px solid lightgrey',
    },
    whiteBtn: {
        backgroundColor: 'transparent',
        border: '0px',
        padding: '10px 0 10px 0'
    },
    card: {
        padding: '15px', /* JUST TO LOOK COOL */
        border: '1px solid #eee',  /* JUST TO LOOK COOL */
        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
        transition: 'all .3s ease-in-out',
    },
    withSubmitError: {
        color: 'red',
    },
    button: {
        fontWeight: '400',
    }
});

RulePicker.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        compatibleCompositeRules: state.policyRuleData.compatibleCompositeRules,
        ruleVariables: state.policyRuleData.ruleVariables,
        compositeRule: state.policyRuleData.compositeRule,
        modifyRuleResult: state.policyRuleData.modifyRuleResult,
        removeRuleResult: state.policyRuleData.removeRuleResult,
    };
};

const connected = connect(mapStateToProps, {
    fetchCompatibleRulesByCompositeRule,
    addRuleToCompositeRule,
    modifyRuleToCompositeRule,
    fetchPolicyRuleVariablesByRule,
})(AuthProvider(RulePicker));

export default withStyles(styles)(connected);