import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import SuccessfulMessage from '../../components/Dialog/SuccessfullMessage';
import { fetchCheckPointMasters, updateCheckPointMasterDesc } from '../../state/actions/CheckPointMasterActions';
import '../../components/style.css';
import renderHTMLContent from '../../util/ConvertUtil';
import envReadOnlyFlagContext from '../../context/adminContext';
var isReadOnly = false;

const APIKeys = {
          EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
      };
var editorCharLeft = 0;

class CheckPointMasterEdit extends React.Component {

  static contextType = envReadOnlyFlagContext;

  constructor (props) {
    super(props);
    this.state = {
      code: this.props.location.state.code,
      checkPointList: [],
      checkPoint: null,
      isTabDivVisible: [],
      menuItems: [
        'Global',
        'AMER',
        'ASIA',
        'AUST',
        'EUROPE'
      ],
      selectedIndex: 0,
      contentDesc: null,
      contentGuid: null,
      currentDesc: null,
      initialDesc: '',
      isBtnActivated: true,
      hideDialog: true,
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the Summary Box</p>'
    }; 
  }

  componentDidMount () {

    this.props.fetchCheckPointMasters();
  }

  componentDidUpdate (prevProps, prevState) {

    if (prevState.checkPointList !== this.props.checkPointData.masters) {
      this.getCheckPoint();
    }
  }

  getCheckPoint = () => {

    const selectedIndex = this.props.checkPointData.masters.findIndex((cp) => { return cp.code === this.state.code; });

    const isTabDivVisibleArray = Array(this.state.menuItems.length).fill(false);

    isTabDivVisibleArray[0] = true;

    this.setState({
      isTabDivVisible: isTabDivVisibleArray,
      selectedIndex,
      checkPoint: this.props.checkPointData.masters[selectedIndex],
      checkPointList: this.props.checkPointData.masters,
      currentDesc: this.props.checkPointData.masters.length > 0 ?
        this.props.checkPointData.masters[selectedIndex].description : ''
    });

    document.title = 'Checkpoint Master Builder';

  }

  handleListItemClick (event, index, rdt) {
    const checkPointList = this.props.checkPointData.masters ? this.props.checkPointData.masters : [];
    const description = checkPointList[index] ? checkPointList[index].description : '';
    this.setState({
      selectedIndex: index,
      code: checkPointList[index].code,
      checkPoint: checkPointList[index],
      isBtnActivated: true,
      currentDesc: description
    });
  }

  listCheckPointMasters = () => {
    const checkPointList = this.props.checkPointData.masters ? this.props.checkPointData.masters : this.props.location.state.cpList;
    return checkPointList.map((cp, index) => {
      const isSelected = (this.state.selectedIndex === index);
      return (
        <ListItem
          key={cp.code}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClick(event, index, cp)}
          className={this.props.classes.cpItem}>
          <ListItemText
            primary={<Typography type="body2" className={this.props.classes.cpItemText}>{cp.title}</Typography>}
            disableTypography
          />
        </ListItem>);
    });
  }

  handleItems = (item, index) => {

  }

  getMenuItems = () => {
    return (
      this.state.menuItems.map((item, index) => {
        return <div className={this.props.classes.tablinks}
          onClick={() => this.handleItems(item, index)}
          style={{ borderBottom: this.state.isTabDivVisible[index] ? "1px solid #0078d4" : "", fontWeight: this.state.isTabDivVisible[index] ? "bold" : "normal" }} >
          {item}
        </div>;
      })
    );

  }

  cancelCp = () => {
    this.setState({ contentGuid: '', currentDesc: this.state.checkPoint.description, isBtnActivated: true });
  }

  updateCP = () => {
    this.props.updateCheckPointMasterDesc(this.state.code, this.state.currentDesc);
    const cp = this.state.checkPoint;
    cp.description = this.state.currentDesc;
    this.setState({ isBtnActivated: true, checkPoint: cp, hideDialog: false });
  }

  closeDialog = () => {
    this.setState({ hideDialog: true });
  }

  wordCount = (currentCount) => {
    const editorCharMax = 600;
    const editorCharCalc = editorCharMax - currentCount;
    editorCharLeft = (editorCharCalc <= 0) ? 0 : editorCharCalc;  
    return (
      <div className={editorCharLeft === 0 ? `${styles.editorChar  } ${  styles.editorCharError}` : styles.editorChar}>
        You have {editorCharLeft} characters left.
      </div>
    );
  }


  render () {
    const { classes } = this.props;

    isReadOnly=this.context.isReadOnly;

    if (!this.state.checkPoint) return <div></div>;

    const savedBtnClass = this.state.isBtnActivated ? '' : 'activateButton';

    const OliLogo = (this.props.result === "SUCCESS") ? require('../../images/oli-success.svg') : require('../../images/oli-fail.svg');

    return (
      <Box display="flex">
        <Box>
          <Card variant='outlined' className={classes.card}>
            <CardContent>
              <Typography variant="subtitle1" className={classes.listHeader}>
                Select Checkpoint Master to edit
              </Typography>
              {this.listCheckPointMasters()}
            </CardContent>
          </Card>
        </Box>

        <Box flexGrow={4}>
          <Card variant='outlined'>
            <CardContent>
              <div className={classes.headerSection}>
                <Box display="flex">
                  <Box flexGrow={1} >
                    <div className={classes.cpHeader}>Checkpoint Builder</div>
                  </Box>
                  <Box>
                    <button className={classes.buttonStyle} onClick={() => { this.cancelCp(); }}>Cancel</button>
                  </Box>
                  <Box>
                    <button className={`${classes.buttonSubmit  } ${  savedBtnClass}`} disabled={this.state.isBtnActivated || isReadOnly}
                      onClick={() => { this.updateCP(); }}>Save</button>
                  </Box>
                  <Box>

                  </Box>
                </Box>
              </div>
              <hr className={classes.sepLine} />
              <div className={classes.titleSection}>
                <div className={classes.titleHeaderRow}>{this.state.checkPoint.title}</div>
                <div className={classes.titleRow}>Phase: </div>
                <div className={classes.titleRow}>Related Decision Point: </div>
                <div className={classes.titleRow}>Last Updated: </div>
              </div>
              <div className={classes.tab} ref="tabNav">
                {this.getMenuItems()}
              </div>
              <div className={classes.editSection}>

                <div>
                  <div className={classes.editHeader}>CHECKPOINT DESCRIPTION</div>
                  <div className={classes.editControlSection}>
                    <div className={classes.editControlSectionHeader}>Add Checkpoint Description </div>
                   {!isReadOnly? <Editor
                      apiKey={APIKeys.EditorKey}
                      value={this.state.currentDesc}
                      init={{
                        branding: false,
                        height: 300,
                        menubar: false,
                        plugins: [
                          'wordcount advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help'
                        ],
                        paste_preprocess: function (plugin, args) {
                          // replace copied text with empty string
                          if (editorCharLeft === 0) {
                            args.content = '';
                          }
                        },
                        toolbar:
                          'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | wordcount | help',
                        init_instance_callback: (editor) => {
                          this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                        }
                      }}
                      onEditorChange={(content, editor) => {

                        this.setState({ currentDesc: content, editorCount: editor.plugins.wordcount.body.getCharacterCount() });

                        if (content !== this.state.checkPoint.description) {
                          this.setState({ isBtnActivated: false });
                        }
                      }}
                      onBlur={(event, editor) => {
                        this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                      }}
                    />:renderHTMLContent(this.state.currentDesc)
                    }
                  </div>

                </div>
                <div>&nbsp;</div>
                <div>
                  <div className={classes.editHeader}>CHECKPOINT GUIDANCE</div>
                  <div className={classes.editControlSection}>
                    <div className={classes.editControlSectionHeader}>Add Checkpoint Guidance </div>
                    {!isReadOnly?<Editor
                      apiKey={APIKeys.EditorKey}
                      init={{
                        branding: false,
                        height: 300,
                        menubar: false,
                        plugins: [
                          'wordcount advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help'
                        ],
                        paste_preprocess: function (plugin, args) {

                        },
                        toolbar:
                          'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | wordcount | help',
                        init_instance_callback: (editor) => {

                        }
                      }}
                      onEditorChange={(content, editor) => {

                      }}
                      onBlur={(event, editor) => {
                        console.log("blur change guidance");
                      }}
                    />:""}
                  </div>
                </div>
              </div>
              {this.props.result && (!this.state.hideDialog) ?
                <SuccessfulMessage dialogState={false} errorTitle={this.props.result}
                  errorContentArray={[this.props.message, 'Click Okay to continue.']}
                  hidden={this.state.hideDialog}
                  closeDialog={this.closeDialog}
                  cancelDialog={this.closeDialog}
                  OliLogo={OliLogo}
                />
                : ''
              }

            </CardContent>
          </Card>
        </Box>
      </Box>

    );
  }
}

const styles = (theme) => ({
 card:{
   backgroundColor: '#f3f2f1',
   width: 300,
   margin:0
 },

 cpHeader:{
   fontSize: '24px',
   fontWeight: '700',
   fontFamily:'PharmaCond',
   color:'#323130'
  },

headerSection:{
 padding: '16px 0'
},

titleSection:{
   backgroundColor:'#0078d4',
   height: '132px',
   color: '#fff',
   padding: '16px 33px',
   margin:'16px 0'
},
titleHeaderRow:{
fontSize:'20px',
fontWeight:'600'
//fontFamily: 'PharmaCond'
},

titleRow:{
padding:'8px 0'
},
 buttonStyle:{
   padding: '7px 19px',
   margin: '0 16px',
   backgroundColor:'#fff',
   color:'#323130',
   borderRadius: '2px',
  border: 'solid 1px #8a8886',
  cursor: 'pointer'
  },

 buttonSubmit:{
   padding: '7px 19px',
   margin: '0 16px',
   backgroundColor:'#c8c8c8',
   color: '#605e5c',
   fontWeight:'600',
   orderRadius: '2px',
   border: 'none',
   cursor: 'pointer'
  },

   sepLine:{
    margin: "16px 0",
    color: '#bdbdbd'
    },

editSection:{
margin: '30px 0'
},

editHeader:{
    backgroundColor: '#d8d8d8',
    padding: '23px 24px',
    color: '#555555',
    fontWeight:'600',
    fontSize: '14px'
},
editControlSection:{
    backgroundColor:'#f6f6f6',
    padding: '24px'
},
editControlSectionHeader:{
    color: '#323130',
    fontWeight:'600',
    paddingBottom:'5px'
},
cpItemText:{
   padding: '0 16px',
   fontWeight:'600',
   fontSize: '14px',
   fontFamily: 'SegoeUI'
},

tablinks:{
 backgroundColor: 'inherit',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '12px 0',
    marginRight: '32px',
    fontSize: '14px'
},

tab:{
    display: 'flex',
    flexWrap: 'nowrap',
    borderBottom: '1px solid #dcdcdc',
    paddingLeft: '16px'
  },

  tabcontent: {
      display:'none',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      padding: '6px 12px 0 12px'
      },

cpList:{

},
   cpItem:{
   color: '#006ed2',
   fontSize: '14px',
   fontWeight: '600',
   backgroundColor: '#ffffff',
   margin: '16px 0'
   },

   listHeader:{
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
   }

});

CheckPointMasterEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {

 return {
 checkPointData: state.checkpointMasterData.checkpointMasters,
   message: state.ui.message,
   result: state.ui.result
 };
};

const connected =connect(mapStateToProps, {
   fetchCheckPointMasters,
   updateCheckPointMasterDesc
 })(CheckPointMasterEdit);

export default withStyles(styles)(connected);