import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import { Stack } from '@fluentui/react/lib/Stack';

class ChangeValues extends React.Component {
    render () {
        return (
            <div>
                <Stack className={this.props.classes.verification}>
                    <Stack.Item className={this.props.classes.changeValueDetail}>
                      <span  className={this.props.classes.fieldName}>{this.props.title}</span>
                    </Stack.Item>
                    <Stack.Item >
                      <span  className={this.props.classes.updateValues}>Updated: TBA</span>
                    </Stack.Item>
                </Stack>
                {this.props.changeprops.map((item) => <Stack >
                        <Stack.Item className={this.props.classes.updateheadingValues}>
                          <span >{item.label.toUpperCase()}</span>
                        </Stack.Item>
                        {this.props.source === 'new'
                            ? <Stack.Item >
                                {item.name.toUpperCase() !== 'CONFIGURATIONVALUE'
                                ? <span  className={this.props.classes.updateChangeValues}>{item.newValue}</span>
                                :                                <>
                                {item.newValue.split(',') === 1
                                    ? <span  className={this.props.classes.updateChangeValues}> {item.newValue}</span>
                                    :                                    <>
                                        {item.newValue.split(',').sort().map((idx) => <><span className={this.props.classes.updateChangeValues}> {this.props.refdata?.filter((ref) => ref.id === idx)[0]?.value} </span>
                                            <br/></>)}
                                    </>
                                    }
                                </>
                                }
                            </Stack.Item>
                            :                            <Stack.Item >
                                {item.name.toUpperCase() !== 'CONFIGURATIONVALUE'
                                  ? <span  className={this.props.classes.updateChangeValues}>{item.oldValue}</span>
                                  :                                  <>
                                    { item.oldValue.split(',') === 1
                                        ? <span  className={this.props.classes.updateChangeValues}> {item.oldValue}</span>
                                        :                                        <>
                                            {item.oldValue.split(',').sort().map((idx) => <><span className={this.props.classes.updateChangeValues}> {this.props.refdata?.filter((ref) => ref.id === idx)[0]?.value} </span>
                                                <br/></>)}
                                        </>
                                    }
                                 </>
                                }
                            </Stack.Item>
                        }
                        <br/>
                    </Stack>)}
            </div>
        );
    }
}

const styles = (theme) => ({
    operationheading: {
        border: 'solid 1px #0b8a00',
        fontSize: '12px',
        padding: '3px 8px 3px 8px'
      },
    heading: {
        height: '3rem',
        backgroundColor: 'f6f6f6'
    },
    fieldName: {
        fontWeight: '600',
        color: '#323130',
        marginBottom: '5px',
        paddingTop: '1rem',
        marginLeft: '1rem',
    },
    verification: {
        backgroundColor: '#f6f6f6',
        height: '5rem',
    },
    changeValueDetail: {
        paddingTop: '1rem',
    },
    updateValues: {
      marginLeft: '1rem',
    },
    updateheadingValues: {
        marginLeft: '1rem',
        marginTop: '1rem',
        fontSize: '14px',
        fontWeight: '600'
    },
    updateChangeValues: {
        marginLeft: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    }
});

ChangeValues.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangeValues);
