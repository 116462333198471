import api from '../../api/IntelligentAgentServiceApi';
import { signOut } from '../../components/auth/AuthUtils';
import { FETCH_CHANGE_SETS_LIST } from '../../constants/ActionTypes';

const fetchChangeSetList = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/ccr/change-set-summaries');
    dispatch({ type: FETCH_CHANGE_SETS_LIST, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export default fetchChangeSetList;
