/* eslint-disable no-param-reassign */
import * as React from 'react';
import { DefaultButton, initializeIcons } from "@fluentui/react";
import './ViewChangeSelector.css';

export class ViewChangeSelector extends React.Component {
    constructor (props) {
        super(props);
        this.state = { displayMenuItems: this.props.menuItems, toggleIcon: "ChevronDownMed", currentSelectedIndex: this.props.initialSelectedIndex };
        this.state.displayMenuItems.items.forEach((item) => {
            const originalFunction = item.onClick;
            // Keep track of the original onClick functionality assigned to this item by the parent component
            item.onClick = () => {
                this.setState({ currentSelectedIndex: this.state.displayMenuItems.items.indexOf(item) });
                originalFunction();
            };
        });
        this.state.displayMenuItems.styles = { root: { backgroundColor: "#FFFFFF" } };
        this.state.displayMenuItems.directionalHintFixed = false;
        initializeIcons();
    }

    toggleSelectorIcon = () => {
        this.setState({ toggleIcon: this.state.toggleIcon === "ChevronDownMed" ? "ChevronUpMed" : "ChevronDownMed" });
    }

    setToggleIconDown = () => {
        this.setState({ toggleIcon: "ChevronDownMed" });
    }

    _setMenuItemAvailability = () => {
        this.state.displayMenuItems.items.forEach((item) => {
            if (this.state.currentSelectedIndex === this.state.displayMenuItems.items.indexOf(item)) {
                item.disabled = true;
            }
            item.disabled = false;
        });
    }

    render () {
        const { id, label } = this.props;
        return (
            <div>
                {this.setMenuItemAvailability}
                <DefaultButton
                    className="contextMenuLabel"
                    id={id}
                    text={label}
                    menuProps={this.state.displayMenuItems}
                    menuIconProps={{ iconName: this.state.toggleIcon }}
                    onAfterMenuDismiss={this.setToggleIconDown}
                    onMenuClick={this.toggleSelectorIcon}
                />
            </div>
        );
    }
}

export default ViewChangeSelector;
