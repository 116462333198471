import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import '../../../components/style.css';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdminChangeMasterItem from './AdminChangeMasterItem';

class AdminChangeMasterListItem extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      keySystemResources: props.keySystemResources,
    };
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.keySystemResources !== nextProps.keySystemResources) {
      return { keySystemResources: nextProps.keySystemResources };
    }
    return null;
  }

  render () {
    const { classes } = this.props;
    return (
      <Box m={1}>
        {this.state.keySystemResources?.map((item , index) => <>
        <Accordion >
          <AccordionSummary
            key={index}
            expandIcon={<ExpandMoreIcon className={this.props.classes.icon}></ExpandMoreIcon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={this.props.classes.accordionHeader}
          >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container className={this.props.classes.subheader}>
              <Grid item xs container direction="column" spacing={4}>
                <Grid item xs={12} sm container className={this.props.classes.subheader}>
                  <Typography className={classes.accHeading}>
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <Typography className={classes.subheaderstatus}>
                  TBA
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <br/>
            <AdminChangeMasterItem changeItems={item.changeItems} keyResourceTitle={item.title} changeCategoryName={this.props.changeCategoryName} filterSelections={this.props.filterSelections}></AdminChangeMasterItem>
          </AccordionDetails>
        </Accordion>
        </>)}
      </Box>
    );
  }
}

const styles = (theme) => ({
  accordionHeader: {
    backgroundColor: '#f1f2f3 !important',
    flexDirection: 'row-reverse',
  },
  accHeading: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    lineHeight: '20px'
  },
  icon: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    fontSize: 'large !important',
    color: '#000000'
  },
  subheader: {
    fontFamily: 'SegoeUI',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#0078d4',
  },
  subheaderstatus: {
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#000000',
  },
});

AdminChangeMasterListItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminChangeMasterListItem);
