import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { v4 as uuid } from 'uuid';

import '../../components/style.css';
import AuthProvider from '../../components/auth/AuthProvider';
import TaskMasterItemCard from './TaskMasterItemCard';
import { fetchTaskMasterDependencies } from '../../state/actions/TaskMasterActions';

class TaskMasterList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      taskMasters: [],
    };
  }

  async componentDidMount () {
    const taskMasters = [];
    // eslint-disable-next-line no-unused-expressions
    // eslint-disable-next-line
    this.props.taskMastersList?.map((tM, index) => {
      taskMasters.push({
        id: tM.id,
        itemsKey: null,
        dependencies: null,
        task: tM
      });
    });
    this.setState((prevState) => ({ ...prevState, taskMasters }));
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('bottom');
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);
      const delayInMilliseconds = 1000; //1 second
      setTimeout(function() { 
        // added to allow the eventListener time to be removed
      }, delayInMilliseconds);
      await this.props.fetchOnScroll();
    }
  };

  isBottom (el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  toggleFetchDataHandler = async (taskId) => {
    await this.props.fetchTaskMasterDependencies(taskId);
    if (this.props.taskMasterDependencies?.policySchemeDependencies.length > 0) {
      const taskMaster = this.state.taskMasters.find((ch) => ch.id === taskId);
      taskMaster.dependencies = this.props.taskMasterDependencies?.policySchemeDependencies;
      taskMaster.itemsKey = uuid();
      this.setState((prevState) => {
        const list = prevState.taskMasters.map((item, j) => {
          if (item.id === taskId) {
            return taskMaster;
          }
          return item;
        });
        return {
          list,
        };
      });
    }
  }

  render () {
    return <div>
        {this.state.taskMasters?.map((tM, index) => (
        <Box display="flex">
            <TaskMasterItemCard task={tM.task} key={tM.id} itemsKey={tM.itemsKey} dependencies={tM.dependencies} fetchDataDetail={this.toggleFetchDataHandler} isReadOnly={this.props.isReadOnly}></TaskMasterItemCard>
        </Box>
        ))}
        <div id="bottom" >Bottom of page</div>
      </div>
  }
}

const styles = (theme) => ({
});

TaskMasterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.ui.result,
  taskMasterDependencies: state.taskMastersData.taskMasterDependencies,
});

const connected = connect(mapStateToProps, {
  fetchTaskMasterDependencies,
})(AuthProvider(TaskMasterList));

export default withStyles(styles)(connected);
