import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { Dialog, DialogType } from '@fluentui/react';

const useStyles = makeStyles({
  dialog: {
    position: "absolute", right: "0.5%", top: "40%", transform: "translate(-75%,-50%)",height:'450px',width:'450px'
  },
  subheader: {
    fontWeight: 'bold',   
    marginLeft:'10px',
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    marginTop: '10px',
    marginLeft:'10px',
    marginRight:'10px'
  },
  ruleValue:{
    marginLeft:'10px',
    marginBottom:'20px',
    marginRight:'10px',
  },
  closeButton: {
    backgroundColor: '#fff',
    border: '0',
    color: '#605e5c',
    fontSize: '18px',
    padding: '0 16px',
    marginRight: '16px',
    cursor: 'pointer',   
  },
  taskTriggerDialog:{
    minWidth: '500px'
  }
});

export default function TriggerPopup(props) {
  const classes = useStyles();
  const { onClose,  open } = props;

  const handleClose = () => {
    onClose();
  };
  initializeIcons();
  const dialogContentProps = {
    type: DialogType.normal,
    title: ' Task Trigger',
    closeButtonAriaLabel: 'Close',
    showCloseButton: true,    
  };

  return (   
    <Dialog
      hidden={!open}
      onDismiss={handleClose}
      dialogContentProps={dialogContentProps}
      modalProps={{ isBlocking: true, containerClassName: classes.taskTriggerDialog}}
    >
     <Typography className={classes.ruleValue} >Rules which will trigger the creation of task </Typography>  
      <Typography className={classes.subheader}>{props.selectedTask.taskLocation}</Typography>
      <hr className={classes.sepLine} />
      <br/>
      {props.selectedTask.taskTriggers?.filter(item=>item.ruleCode !== 'PDP.RULE.REGIONS_FOR_DP' && item.ruleCode !== 'PDP.RULE.TASK_MASTER_CODES').map((item,index)=>{
      return(
      <>
        <Typography variant="subtitle1" className={classes.subheader}>{item.ruleTitle}</Typography>
        <Typography variant="body2" gutterBottom className={classes.ruleValue} > {item.ruleCode} - {item.ruleTitle} </Typography>
        <br/>      
      </>
      )
    })}        
  </Dialog>
  );
}

TriggerPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
