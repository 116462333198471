import React from 'react';
 import { connect } from 'react-redux';
 import PropTypes from 'prop-types';

 import { Panel, PanelType } from '@fluentui/react/lib/Panel';
 import {
     DefaultButton, PrimaryButton, initializeIcons, IconButton, Text, TextField, Checkbox
 } from '@fluentui/react';

 import { Stack } from '@fluentui/react/lib/Stack';
 import { Separator } from '@fluentui/react/lib/Separator';
 import { Dropdown } from '@fluentui/react/lib/Dropdown';

 import { withStyles } from '@material-ui/styles';

 import AuthProvider from '../../../components/auth/AuthProvider';
 import { fetchNotificationLibraryByMasterCode, updateNotificationMaster } from '../../../state/actions/NotificationMastersAction';
 import SuccessfulMessage from '../../../components/Dialog/SuccessfullMessage';

 class MasterDetails extends React.Component {
     constructor (props) {
         super(props);
         this.state = {
             notificationMaster: null,
             dropDownOptions: [],
             isCurrentlyInSelectionMode: true, // 2 modes, Editor (true) and Confirmation (false)
             isSubmitInProgress: false,
             withSubmitError: false,
             isArchiveDisable: false,
             isArchive: false,
             isUpdated: true
         };
         initializeIcons();
     }

     async componentDidMount () {
        if (!this.props.notificationMaster) return;

         this.prepareFormContent();
         if (this.props.notificationMaster?.status === "ARCHIVED") { this.setState({ isArchive: true }); } else { this.setState({ isArchive: false }); }
         const policyConfiguration = this.props.policyConfigurations?.filter((item) => item.linkedPolicyLibraries.length > 0);
         if (policyConfiguration && policyConfiguration[0]) {
            this.setState({ isArchiveDisable: true });
          } else {
              this.setState({ isArchiveDisable: false });
         }
     }

     async prepareFormContent () {
         const {
             name,
             code,
             description,
             status,
             category
         } = this.props.notificationMaster;
         const notificationMaster = {
             code,
             name,
             description,
             status,
             category
         };

        const filterOptions = this.props.refdata.filter((ref) => ref.type === 'NOTIFICATION_CATEGORY');
        const dropDownOptions = [];

        if (filterOptions && filterOptions.length > 0) {
           filterOptions.forEach((option) => {
             dropDownOptions.push({ text: option.value.replaceAll("_", " "), key: option.id });
           });
        }

         this.setState({ notificationMaster, dropDownOptions });
     }

     onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

     /* Step and Window handler */

     onDismissDetailsPanel = () => {
         this.props.hideMasterDetailsPanelHandler();
     }

     onChangeDescription = (event) => {
         const newVal = (event.target.value ? event.target.value : '');
         const val = this.state.notificationMaster;
         val.description = newVal;
         this.setState({ notificationMaster: val, isUpdated: false });
     }

     /* Processor (Edit/Save/Delete) */

     saveChanges = async () => {
         const { code, description, status } = this.state.notificationMaster;
         const newMaster = {
             description,
             status
         };

         await this.props.updateNotificationMaster(code, newMaster);
         this.setState((prevState) => ({
  ...prevState, isCurrentlyInSelectionMode: false, isSubmitInProgress: false, withSubmitError: false, isUpdated: true
 }));
     }

     /* Utilities */
     handleArchiveChange = (event) => {
         this.setState({ isArchive: !this.state.isArchive });
         const newVal = (event.target.checked === true ? 'ARCHIVED' : '');
         const val = this.state.notificationMaster;
         if (newVal !== '') { val.status = newVal; }

         this.setState({ notificationMaster: val });
       }

     handleChangeType = (event, item) => {
        const newVal = (item.key ? item.key : '');
             const val = this.state.notificationMaster;
             val.category = newVal;
             this.setState({ notificationMaster: val });
     }

     getReferenceValueForType = (typeValue) => {
       const filterRef = this.props.refdata.filter((ref) => ref.id === typeValue);

       if (filterRef && filterRef.length > 0) return filterRef[0].value;

       return "";
     }

     /* Screens */

     buildEditor = () => {
         if (!this.state.notificationMaster) return null;

         const { classes, isReadOnly } = this.props;
         const { isUpdated, dropDownOptions } = this.state;
         const { name, description, category } = this.state.notificationMaster;
         const charsLeft = (500 - description.length);
         return (
             <>
                 <Stack horizontal tokens={stackTokens} className={classes.actions}>
                     <DefaultButton text="Cancel" onClick={this.onDismissDetailsPanel}
                       onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
                     <PrimaryButton text="Save" onClick={this.saveChanges} disabled={isUpdated || isReadOnly} />
                 </Stack>
                 <Separator></Separator>
                 <Stack className={classes.inputArea}>
                   <span className={classes.headerField}>Notification Master Status</span>
                     { this.state.isArchiveDisable
                       ? <Stack horizontal className={classes.informational}>
                           <IconButton iconProps={{ iconName: 'Info' }} />
                           <p className={classes.message}> Please remove all linkages to archive this item </p>
                         </Stack>
                       : ''}
                     <Stack horizontal>
                         <Checkbox className={classes.archiveCheckbox} label="Archive" disabled={isReadOnly || (!isReadOnly && this.state.isArchiveDisable)}
                             onChange={this.handleArchiveChange} checked = {this.state.isArchive}/>
                     </Stack>
                 </Stack>

                 <Stack tokens={stackTokens}>
                     <Stack className={classes.inputArea}>
                         <span className={classes.headerField}> Notification Master Name </span>
                         <div>{name}</div>
                      </Stack>
                 </Stack>

                  <Stack tokens={stackTokens}>
                     <Stack className={classes.inputArea}>
                         <span className={classes.headerField}> Notification Master Description</span>
                         <TextField value={description} onChange={this.onChangeDescription} multiline={true} style={{ height: '110px' }} maxLength={500} readOnly={isReadOnly} />
                         <Text style={{ fontSize: '12px' }}>You have {charsLeft} characters left</Text>
                     </Stack>
                 </Stack>

                   <Stack tokens={stackTokens}>
                         <Stack className={classes.inputArea}>
                             <span className={classes.headerField}>Notification Master Type </span>
                            <Stack className={classes.taskDetail}>
                                <Dropdown label=""
                                  disabled
                                  selectedKey={category}
                                  onChange={this.handleChangeType}
                                  placeholder="Select Type"
                                  options={dropDownOptions}
                                  styles={this.dropdownStyles}
                                />
                             </Stack>
                          </Stack>
                   </Stack>
             </>
         );
     }

     buildConfirmationScreen = () => {
        // eslint-disable-next-line global-require
        const OliLogo = (this.props.result === "SUCCESS") ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');
        return (
          <SuccessfulMessage dialogState={false} errorTitle={this.props.result}
            errorContentArray={[this.props.message, 'Click Okay to continue.']}
            hidden={this.state.isCurrentlyInSelectionMode}
            closeDialog={this.onDismissDetailsPanel}
            cancelDialog={this.onDismissDetailsPanel}
            OliLogo={OliLogo}
          />
        );
     }

     render () {
         return (
             <Panel
                 isOpen={this.props.isMasterDetailsPanelOpen}
                 onDismiss={this.onDismissDetailsPanel}
                 type={PanelType.custom}
                 customWidth={'650px'}
                 closeButtonAriaLabel="Close"
                 headerText={"Edit Notification Master Details"}
             >
                 <Separator></Separator>
                 {this.state.isCurrentlyInSelectionMode
                 ? this.buildEditor()
                 : this.buildConfirmationScreen()
                 }
             </Panel>
          );
     }
 }

 // Example formatting
 const stackTokens = { childrenGap: 40 };

 const styles = (theme) => ({
     rootBackground: {
         height: '100%',
         backgroundColor: '#f3f2f1'
     },
     root: {
         flexGrow: 1
     },
     informational: {
         margin: '0 15px 15px 15px',
         padding: '15px',
         borderRadius: '2px',
         backgroundColor: '#f4f4f4'
       },
       archiveCheckbox: {
         marginLeft: '1rem'
       },
     title: {
         padding: '60px 0 60px 0',
         color: '#605e5c',
         backgroundColor: '#fff',
     },
     inputArea: {
         padding: '0 10px 10px 10px',
         borderRadius: '2px',
     },
     headerField: {
         fontSize: '14px',
         fontWeight: '600',
         color: 'rgb(50, 49, 48)',
         padding: '5px 0px',
     },
     message: {
         marginTop: '0.5rem'
     },
     instruction: {
         fontSize: '14px',
         fontFamily: 'SegoeUI',
         fontWeight: '600',
         padding: '16px 0 0 0'
     },
     catStyle: {
         padding: '0 0 0 0'
     },
     basicStyles: {
         maxWidth: '600',
         border: '0px solid  #dcdcdc',
         margin: '0'
     },
     tagPickerClass: {
         inlineSize: '450px',
         overflowWrap: 'break-word',
     },
     compositeTextStyle: {
         fontSize: '16px',
         fontFamily: 'SegoeUI',
         fontWeight: '600',
         padding: '15px 0 0 0'
     },
     compositeTextStyle2: {
         fontSize: '14px',
         fontFamily: 'SegoeUI',
         fontWeight: '600',
     },
     sectionTitle: { // used
         fontSize: '14px',
         fontFamily: 'SegoeUI',
         fontWeight: '600',
         padding: '15px 0 15px 0',
     },
     selectStyle: {
         fontSize: '14px',
         fontFamily: 'SegoeUI',
         fontWeight: '600',
         padding: '0 0 5px 0',
     },
     actions: {
         padding: '20px 0 20px 0'
     },
     selectedRuleBlock: {
         padding: '20px 0 0 0'
     },
     previewPart: {
         backgroundColor: '#f6f6f6'
     },
     stackItemInfo: {
         alignItems: 'center',
         display: 'block',
         justifyContent: 'center',
         overflow: 'hidden',
     },
     stackItemDelete: {
         alignItems: 'center',
         display: 'flex',
         justifyContent: 'center',
         overflow: 'hidden',
         maxWidth: 80,
         minWidth: 80,
         borderLeft: '2px solid lightgrey',
     },
     whiteBtn: {
         backgroundColor: 'transparent',
         border: '0px',
         padding: '10px 0 10px 0'
     },
     card: { // used
         padding: '15px', /* JUST TO LOOK COOL */
         border: '1px solid #eee',  /* JUST TO LOOK COOL */
         boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
         transition: 'all .3s ease-in-out',
     },
     withSubmitError: { // used
         color: 'red',
     },
     button: {
         fontWeight: '400',
     }
 });

 MasterDetails.propTypes = {
     classes: PropTypes.object.isRequired,
 };

 const mapStateToProps = (state) => {
  return {
      notificationMaster: state.notificationMastersData.notificationMaster,
      policyConfigurations: state.notificationMastersData.policyConfigurations,
      refdata: state.refData.referenceData,
      message: state.ui.message,
      result: state.ui.result
    };
 };

 const connected = connect(mapStateToProps, {
   fetchNotificationLibraryByMasterCode,
   updateNotificationMaster
 })(AuthProvider(MasterDetails));

export default withStyles(styles)(connected);
