export const  SUCCESS = 'SUCCESS';
export const  ERROR = 'ERROR';

export const AdminPortalSites = [
    { name: "Dev", url: "http://localhost:3000" },
    { name: "SIT", url: "https://admin-portal.sit.lendleaseoli.com" },
    { name: "UAT", url: "https://admin-portal.uat.lendleaseoli.com" },
    { name: "PreProd", url: "https://admin-portal.preprod.lendleaseoli.com" },
    { name: "Prod", url: "https://admin-portal.prod.lendleaseoli.com" }
];

export const AdminPortalEnvironment = {
     SIT: 'SIT',
     UAT: 'UAT',
     PreProd: 'PreProd',
     Prod: 'Prod'
 };
