export const RulePickerActionTypeLiteral = [
    {
        type: 'Task',
        title: 'Task Master - Rule Picker',
        desc: 'TODO',
        edit: 'Task Master Changes Summary '
    },
    {
        type: 'Policy',
        title: 'Policy Master - Rule Picker',
        desc: 'TODO',
        edit: 'Policy Master Changes Summary '
    },
    {
        type: 'Notification',
        title: 'Notification Master - Rule Picker',
        desc: 'TODO',
        edit: 'Notification Master Changes Summary '
    },
    {
        type: 'GuidanceFragment',
        title: 'Guidance Fragment - Rule Picker',
        desc: 'TODO',
        edit: 'Guidance Fragment Changes Summary '
    },
];

export const RuleDeleteActionTypeLiteral = [
    {
        type: 'Task',
        title: 'Task Builder - Delete Rule',
        desc: 'TODO'
    },
    {
        type: 'Policy',
        title: 'Policy Builder - Delete Rule',
        desc: 'TODO'
    },
    {
        type: 'Notification',
        title: 'Notification Builder - Delete Rule',
        desc: 'TODO'
    },
    {
        type: 'GuidanceFragment',
        title: 'Guidance Fragment Builder - Delete Rule',
        desc: 'TODO'
    },
];

export const ActionLiteralType = {
    TASK: 'Task',
    POLICY: 'Policy',
    NOTIFICATION: 'Notification',
    GUIDANCEFRAGMENT: 'GuidanceFragment',
    COMPOSITERULEADD: 'CompositeRuleAdd',
    COMPOSITERULEEDIT: 'CompositeRuleEdit',
};

export const PolicyMasterDetailsActionTypeLiteral = [
    {
        type: 'Task',
        title: 'Edit Task Master Details',
    },
    {
        type: 'Policy',
        title: 'Edit Policy Master Details',
    },
    {
        type: 'Notification',
        title: 'Edit Notification Master Details',
    },
    {
        type: 'GuidanceFragment',
        title: 'Edit Guidance Fragment Details',
    }
];

export const PolicySchemePdpDetailsActionTypeLiteral = [
    {
        type: 'Task',
        title: 'Edit Task PDP Details',
    },
    {
        type: 'Policy',
        title: 'Edit PDP Details',
    },
    {
        type: 'Notification',
        title: 'Edit Notification PDP Details',
    },
    {
        type: 'GuidanceFragment',
        title: 'Edit Guidance Fragment PDP Details',
    },
    {
        type: 'CompositeRuleAdd',
        title: 'Add New Composite Rule',
    },
    {
        type: 'CompositeRuleEdit',
        title: 'Edit Composite Rule',
    }
];
