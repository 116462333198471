import React , { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Box, Typography } from "@material-ui/core";
import "../reference.css";
import TableMappingCard from '../TableMappings/TableMappingCard';
import FilterAndSearchPageHeader from "../../adminChangeConsole/shared/FilterAndSearchPageHeader";
import { fetchAllPIPs } from '../../../state/actions/PIPActions';
import { fetchReferenceData } from '../../../state/actions/ReferenceDataActions';

const PipList = (props) =>  {

  const pips = useSelector(
    (state) => Object.values(state.pips.pips)
  );

 const refData = useSelector(
      (state) => state.refData.referenceData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllPIPs());
    dispatch(fetchReferenceData());
  }, [dispatch]);


 const listTables = () => {
    const newPips = pips.reduce((pip, x) => {
      // eslint-disable-next-line no-param-reassign
       (pip[x.categoryId] = pip[x.categoryId] || []).push(x);
       return pip;
    }, {});

    return Object.values(newPips).map((ia, index) => {
      return <TableMappingCard key={`pipList-${index}`} subjectMatter={ia[0].categoryId} pips={ia} type={"pips"} refData={refData} />
    });
  }

    return (
      <Box className={"boxOutline"}>
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography className={"cardHeader"}>
                Policy Information Point Management
              </Typography>
            }
          />
          <CardContent>

            <FilterAndSearchPageHeader filterOptions={[]} selectedFilters={[]}
              filterOnclickHandler={() => { }}
              clearAllFilterHandler={() => { }}
              removeSingleFilterHandler={() => { }} />
            <div>
              {listTables()}
            </div>
          </CardContent>
        </Card>
      </Box>
    );
  }

export default PipList;