import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReferenceDataGrid from './ReferenceDataGrid';
import './reference.css';
import AuthProvider from '../../components/auth/AuthProvider';

const ReferenceDataSections = [
  { title: "Reference Data Management", enabled: true },
  { title: "Help Text Management", enabled: true },
  { title: "Guidance Management", enabled: true },
  { title: "Mapping Table Management", enabled: true },
  { title: "Policy Information Point Management", enabled: true }
];

class ReferenceDataConsole extends React.Component {

  // eslint-disable-next-line class-methods-use-this
  componentDidMount () {
    document.title = 'Reference Data Admin';
  }

  // eslint-disable-next-line class-methods-use-this
  render () {
    return (
      <Card variant='outlined' className={'rootBackground'}>
        <CardHeader className='cardHeaderSection'
          title={<Typography className='cardHeader'>
            Reference Data Admin
          </Typography>} />
        <CardContent>
          <Box mt={1} container="true" display="flex" flexDirection="row" justify-content="space-around"
            flexWrap="wrap" alignContent="center" width={1} styles={{ alignItem: 'center' }}>
            <ReferenceDataGrid ReferenceDataSections={ReferenceDataSections} />
          </Box>
        </CardContent>
      </Card>
    );
  }
}

export default (AuthProvider(ReferenceDataConsole));
