import _ from 'lodash';
import {
  CANCEL_EDIT_PIP_ROW,
  FETCH_PIP,
  FETCH_PIP_ROW,
  FETCH_PIPS,
  FETCH_ALL_PIPS,
  UPDATE_PIP_ATTRIBUTES,
  UPDATE_PIP_ATTRIBUTES_ERROR, UPDATE_PIP_ATTRIBUTES_SUCCESS
} from '../../constants/ActionTypes';
import { SUCCESS } from '../../constants/Constants';

const INITIAL_STATE = {
  pips: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PIP:
      return {
        ...state,
        pips: { ...state.pips, [action.payload.id]: action.payload },
        error: null
      };
    case FETCH_PIP_ROW:
      return {
        ...state,
        pipRow: action.payload,
        error: null
      };
    case CANCEL_EDIT_PIP_ROW:
      return {
        ...state,
        pipRow: null,
        error: null
      };
    case FETCH_PIPS:
      return {
        ...state,
        pips: { ..._.mapKeys(action.payload, 'id') },
        error: null
      };
    case FETCH_ALL_PIPS:
      return {
        ...state,
        pips: { ...action.payload },
        error: null
      };
    case UPDATE_PIP_ATTRIBUTES:
      return {
        ...state,
        pips: { ..._.mapKeys(action.payload, 'id') },
        backendResult: null
      };
    case UPDATE_PIP_ATTRIBUTES_ERROR:
      return {
        ...state,
        backendResult: action.payload
      };
    case UPDATE_PIP_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        pipRow: null,
        backendResult: SUCCESS
      };
    default:
      return state;
  }
};
