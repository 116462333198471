/* eslint-disable array-callback-return */
export function createFilterOptionsByPolicyScheme (policySchemeConfigurations) {
  const entries = [];
  policySchemeConfigurations.map((config) => {
    const { name } = config.policyScheme;
    entries.push({
      key: config.policyScheme.id,
      value: name,
    });
  });
  const filterEntries = {
    filterType: 'Policy Scheme',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByPublicationType () {
  const entries = [];
  entries.push({
    key: 'PUBLISHED',
    value: 'Published',
  });
  entries.push({
    key: 'UNPUBLISHED',
    value: 'Unpublished',
  });

  const filterEntries = {
    filterType: 'Publication Type',
    entries: [...entries]
  };
  return filterEntries;
}

/**
 * This function works on the schema returned from API '/change-set-summaries'
 */
export function createFilterOptionsByChangeCategory (changeCategories) {
  const entries = [];
  changeCategories.map((entry) => {
    const { changeCategoryName } = entry;
    const { id } = entry.changeSets[0].changeCategory;
    const exist = entries.some((ch) => ch.key === changeCategoryName);
    if (!exist) {
      entries.push({
        key: id,
        value: changeCategoryName,
      });
    }
  });
  const filterEntries = {
    filterType: 'Change Category',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByEntity (entities) {
  const entries = [];
  entities.map((entity, index) => {
    entries.push({
    key: entity,
    value: entity,
  });
 });

  const filterEntries = {
    filterType: 'Entity',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByRegion (regions) {
  const entries = [];
  regions.map((region, index) => {
    entries.push({
    key: region,
    value: region,
  });
 });

  const filterEntries = {
    filterType: 'Region',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByEntityType () {
  const entries = [];
  entries.push({
    key: 'DECISION_POINT',
    value: 'DECISION_POINT',
  });

  entries.push({
    key: 'CHECKPOINT',
    value: 'CHECKPOINT',
  });

  const filterEntries = {
    filterType: 'Entity Type',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByChangeCategoryForControl (categories) {
  const entries = [];
  categories.map((category, index) => {
    entries.push({
    // key: category,
    // value: category,
    key: category.id,
    value: category.value,
  });
 });

  const filterEntries = {
    filterType: 'Category',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByStatus () {
  const entries = [];
  entries.push({
    key: 'DRAFT',
    value: 'DRAFT',
  });

  entries.push({
    key: 'VERIFIED',
    value: 'VERIFIED',
  });

  entries.push({
    key: 'PUBLISHED',
    value: 'PUBLISHED',
  });

  const filterEntries = {
    filterType: 'Change Item Status',
    entries: [...entries]
  };
  return filterEntries;
}

export function createFilterOptionsByVerification () {
  const entries = [];
  entries.push({
    key: 'TRUE',
    value: 'Verified for publication',
  });

  entries.push({
    key: 'FALSE',
    value: 'To be Verified',
  });

  const filterEntries = {
    filterType: 'Verification Status',
    entries: [...entries]
  };
  return filterEntries;
}