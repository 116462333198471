import {
  ELIGIBLE_NOTIFICATION_MASTERS,
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  eligibleNotificationMasters: [],
  error: null
};

export default (state = INITIAL_STATE, action) => {
 switch (action.type) {
   case ELIGIBLE_NOTIFICATION_MASTERS:
        return {...state, eligibleNotificationMasters: action.payload };
   default:
      return state;
  }
};
