/* eslint-disable arrow-body-style */
/* eslint-disable class-methods-use-this */
import React from 'react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { TextField } from '@fluentui/react/lib/TextField';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import { RulePickerActionTypeLiteral } from '../../../constants/ActionTypeObj';
import AuthProvider from '../../auth/AuthProvider';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';

import './RuleConfigValue.css';

const isReadOnly = getReadOnlyFlagPerEnv();

const themedSmallStackTokens = {
    childrenGap: 's1',
    padding: 's1',
  };

class RuleConfigValue extends React.Component {
    constructor (props) {
        super(props);
        const literalType = RulePickerActionTypeLiteral.filter((value) => value.type === this.props.literalType)[0];
        this.state = {
            literalType,
            withError: false,
            ruleConfigValues: this.props.ruleConfigValues,
        };
    }

    componentDidMount () {
        document.title = 'Rule Configuration Value';
    }

    prepareFormContent () {
        const { xxruleVariables, mode } = this.props;
        const ruleVar = xxruleVariables && xxruleVariables.length > 0 ? xxruleVariables[0] : null;

        if (mode === 'read') {
            if (ruleVar) {
                return this.adaptToViewPolicyValue(ruleVar);
            }
            return <div style={{ color: 'red' }}>There is no Rule Configuration Value defined for this rule variable.</div>;
        }
        if (ruleVar) {
            if (ruleVar.variableType === 'SINGLE_VALUE_STRING') {
                return this.createConfigValueWithSingleValueFromList(ruleVar);
            }
            if (ruleVar.variableType === 'SINGLE_VALUE_NUMBER') {
                return this.createConfigValueWithSingleValueWithNumber(ruleVar);
            }
            if (ruleVar.variableType === 'MULTI_VALUE_SET') {
                return this.createConfigValueOfTypeMultiSet(ruleVar);
            }
        }
        return <div style={{ color: 'red' }}>There is no Rule Configuration Value defined for this rule variable.</div>;
    }

    adaptToViewPolicyValue (ruleVar) {
        const { classes } = this.props;
        const options = [];

        if (ruleVar.variableSource === 'REFERENCE_DATA') {
            const refds = this.props.refdata.filter((node) => node.type === ruleVar.referenceDataType && node.enabled);
            refds.sort((a, b) => a.sortOrder - b.sortOrder);
            const isChecked = (id) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(id) : false);

            refds.forEach((ref) => {
                if (isChecked(ref.id)) {
                    options.push(ref.value);
                }
            });
        } else if (ruleVar.variableSource === 'TASK_MASTER') { // this part is for reference only !!! there is a othe component that will display task codes
            const sortedOptions = this.props.taskMasterDataRef.masters.sort((a, b) => (a.code).localeCompare(b.code));
            const filteredOptions = sortedOptions.filter((val) => val.resourceState !== 'ARCHIVED');
            const values = this.props.ruleConfigValues.split(',').sort();
            const isTaskSelected = (id) => (values ? values.includes(id) : false);

            filteredOptions.forEach((ref) => {
                if (isTaskSelected(ref.code)) {
                    options.push(`${ref.code} - ${ref.label}`);
                }
            });
        } else if (ruleVar.variableSource === 'RISK_RATING_CRITERIA') {
            const refds = ruleVar.values.sort((a, b) => (a).localeCompare(b));
            const values = this.props.ruleConfigValues.split(',').sort();
            const isTaskSelected = (id) => (values ? values.includes(id) : false);

            refds.forEach((ref) => {
                if (isTaskSelected(ref)) {
                    options.push(`${ref}`);
                }
            });
        } else if (ruleVar.variableType === 'SINGLE_VALUE_NUMBER') {
            const n = parseFloat(this.props.ruleConfigValues).toFixed(5);
            const dspval = Number(n).toLocaleString('en');
            options.push(dspval);
        } else {
            const values = this.props.ruleConfigValues.split(',').sort();
            values.forEach((ref) => {
                options.push(this.formatText(ref));
            });
        }

        if (this.ruleVarWithEmptyValue(options)) {
            options[0] = this.prepareEmptyValueForDisplayOnly();
        }

        return (
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item>
                        {options.map((val, _index) => (
                            <div>
                                <button className={classes.whiteBtn} disabled={isReadOnly}><FiberManualRecordRoundedIcon className={classes.addTaskLinkIcon}></FiberManualRecordRoundedIcon>{val}</button>
                            </div>
                        ))}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        );
    }

    formatText (value) {
        if (value === undefined) {
            return '';
        }
        return value.replaceAll('_', ' ');
    }

    prepareEmptyValueForDisplayOnly () {
        return '[NONE]';
    }

    ruleVarWithEmptyValue (options) {
        if (options?.length && options?.length === 1 && (options[0] === '' || options[0] === 'NONE')) {
            return true;
        }
        return options.length === 0;
    }

    adoptRuleVariableAsChoiceOption (ruleVar) {
        const options = [];

        if (ruleVar.variableSource === 'ENUM_Y_N' || ruleVar.variableSource === 'ENUM_YES_NO') {
            ruleVar.values.forEach((value) => {
                if (value === 'Y' || value === 'Yes') {
                    options.push({
                        key: 'Yes', text: 'Yes',
                    });
                } else {
                    options.push({
                        key: 'No', text: 'No',
                    });
                }
            });
        } else if (ruleVar.variableSource === 'REFERENCE_DATA') {
            const refds = this.props.refdata.filter((node) => node.type === ruleVar.referenceDataType && node.enabled);
            refds.sort((a, b) => a.sortOrder - b.sortOrder);

            refds.forEach((ref) => {
                options.push({
                    key: ref.id, text: ref.value,
                });
            });
        } else {
            ruleVar.values.forEach((value) => {
                options.push({
                    key: value, text: this.formatText(value),
                });
        });
        }
        return options;
    }

    onChangeChoiceOption = (event, option) => {
        // eslint-disable-next-line no-console
        const values = [option.key];
        this.props.updateForRuleConfigValues(values);
        this.setState((prevState) => ({ ...prevState, ruleConfigValues: values, withError: false }));
    }

    createConfigValueWithSingleValueFromList (ruleVar) {
        const { mode } = this.props;
        const selected = this.state.ruleConfigValues && this.state.ruleConfigValues.length > 0 ? this.state.ruleConfigValues[0] : '';
        switch (mode) {
            case 'view':
                return <div>{this.props.ruleConfigValues}</div>;
            case 'add':
                return <ChoiceGroup selectedKey={selected} disabled={isReadOnly} options={this.adoptRuleVariableAsChoiceOption(ruleVar)} onChange={this.onChangeChoiceOption} label="Pick one" required={true} />;
            case 'edit':
                return <ChoiceGroup selectedKey={selected} disabled={isReadOnly} options={this.adoptRuleVariableAsChoiceOption(ruleVar)} onChange={this.onChangeChoiceOption} label="Pick one" />;
            default:
                return <div>SINGLE_VALUE_STRING</div>;
        }
    }

    onChangeSingleValueFromList = (event) => {
        // eslint-disable-next-line no-console
        const values = [event.target.value];
        this.props.updateForRuleConfigValues(values);
        this.setState((prevState) => ({ ...prevState, ruleConfigValues: values, withError: false }));
    }

    createConfigValueWithSingleValueWithNumber (ruleVar) {
        const { mode } = this.props;
        const selected = this.state.ruleConfigValues && this.state.ruleConfigValues.length > 0 ? this.state.ruleConfigValues[0] : '';

        switch (mode) {
            case 'view': {
                return <div>{selected}</div>;
            }
            case 'add':
                return <TextField placeholder="Please enter text here" readOnly={isReadOnly} onChange={this.onChangeSingleValueFromList} required={true} type='number' />;
            case 'edit':
                return <TextField placeholder="Please enter text here" readOnly={isReadOnly} onChange={this.onChangeSingleValueFromList} value={selected} required={true} type='number' />;
            default:
                return <div>SINGLE_VALUE_STRING</div>;
        }
    }

    onChangeMultiSet = (id) => {
        const exists = this.state.ruleConfigValues ? this.state.ruleConfigValues.includes(id) : false;
        let newVal = [];
        if (exists) {
            newVal = this.state.ruleConfigValues.filter((curVal) => curVal !== id);
        } else if (this.state.ruleConfigValues) {
            newVal = [...this.state.ruleConfigValues, id];
        } else {
            newVal = [id];
        }
        this.props.updateForRuleConfigValues(newVal);
        this.setState((prevState) => ({ ...prevState, ruleConfigValues: newVal, withError: false }));
    }

    adoptRuleVariableAsMultiSetOption (ruleVar) {
        if (ruleVar.variableSource === 'REFERENCE_DATA') {
            const options = this.props.refdata.filter((node) => node.type === ruleVar.referenceDataType && node.enabled);
            options.sort((a, b) => a.sortOrder - b.sortOrder);
            const isChecked = (id) => (this.state.ruleConfigValues ? this.state.ruleConfigValues.includes(id) : false);

            const itemList = options.map((val, _index) => {
                return <div><Checkbox key={val.id} disabled={isReadOnly} label={val.value} onChange={() => this.onChangeMultiSet(val.id)} checked={isChecked(val.id)} /></div>;
              });
            return (
                <Stack tokens={themedSmallStackTokens}>
                    {itemList}
                </Stack>
            );
        }

        if (ruleVar.variableSource === 'TASK_MASTER') {
            const sortedOptions = this.props.taskMasterDataRef.masters.sort((a, b) => (a.code).localeCompare(b.code));
            const filteredOptions = sortedOptions.filter((val) => val.resourceState !== 'ARCHIVED');
            // const values = this.props.ruleConfigValues.split(',').sort();
            const isTaskSelected = (code) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(code) : false);

            const itemList = filteredOptions.map((val, _index) => {
                return <div><Checkbox key={val.code} disabled={isReadOnly} label={`${val.code} - ${val.label}`} onChange={() => this.onChangeMultiSet(val.code)} checked={isTaskSelected(val.code)} /></div>;
              });

            return (
                <Stack tokens={themedSmallStackTokens} style={{ maxHeight: 300, overflow: 'auto' }}>
                    {itemList}
                </Stack>
            );
        }

        if (ruleVar.variableSource === 'RISK_RATING_CRITERIA' || ruleVar.variableSource === 'GUIDANCE_FRAGMENT') {
            const options = ruleVar.values.sort((a, b) => (a).localeCompare(b));
            const isTaskSelected = (code) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(code) : false);

            const itemList = options.map((val, _index) => {
                return <div><Checkbox key={val} label={`${val}`} disabled={isReadOnly} onChange={() => this.onChangeMultiSet(val)} checked={isTaskSelected(val)} /></div>;
              });
            return (
                <Stack tokens={themedSmallStackTokens} style={{ maxHeight: 300, width: 400, overflow: 'auto' }}>
                    {itemList}
                </Stack>
            );
        }
        return null;
    }

    createConfigValueOfTypeMultiSet (ruleVar) {
        const { mode } = this.props;

        switch (mode) {
            case 'view':
                return <div>{this.props.ruleConfigValues}</div>;
            case 'add':
                return this.adoptRuleVariableAsMultiSetOption(ruleVar);
            case 'edit':
                return this.adoptRuleVariableAsMultiSetOption(ruleVar);
            default:
                return <div>MULTI_VALUE_SET</div>;
        }
    }

    render () {
        const { classes } = this.props;

        return (
            <>
                {this.props.mode === 'read'
                ? this.prepareFormContent()
                : <Stack horizontal tokens={innerStackTokens}>
                    {this.prepareFormContent()}
                </Stack>
                }

                {this.state.withError
                ? <Stack className={classes.previewPart}>
                    <Stack>
                        <div className={classes.withError}>{this.props.modifyRuleResult.message}</div>
                    </Stack>
                </Stack>
                : null }
            </>
        );
    }
}

const innerStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: '#f3f2f1'
    },
    root: {
        flexGrow: 1
    },
    title: {
        padding: '60px 0 60px 0',
        color: '#605e5c',
        backgroundColor: '#fff',
    },
    instruction: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '16px 0 0 0'
    },
    catStyle: {
        padding: '0 0 0 0'
    },
    basicStyles: {
        maxWidth: '600',
        border: '0px solid  #dcdcdc',
        margin: '0'
    },
    tagPickerClass: {
        inlineSize: '450px',
        overflowWrap: 'break-word',
    },
    compositeTextStyle: {
        fontSize: '16px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 0 0'
    },
    compositeTextStyle2: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
    },
    sectionTitle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '20px 0 20px 0',
    },
    selectStyle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '0 0 5px 0',
    },
    actions: {
        padding: '20px 0 20px 0'
    },
    selectedRuleBlock: {
        padding: '20px 0 0 0'
    },
    previewPart: {
        backgroundColor: '#f6f6f6'
    },
    stackItemInfo: {
        alignItems: 'center',
        display: 'block',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    stackItemDelete: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        maxWidth: 80,
        minWidth: 80,
        borderLeft: '2px solid lightgrey',
    },
    card: {
        padding: '15px', /* JUST TO LOOK COOL */
        border: '1px solid #eee',  /* JUST TO LOOK COOL */
        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
        transition: 'all .3s ease-in-out',
    },
    withError: {
        color: 'red',
    },
    button: {
        fontWeight: '400',
    },
    whiteBtn: {
        backgroundColor: 'transparent',
        border: '0px',
        padding: '10px 0 10px 0',
        color: '#323130',
        paddingRight: '1rem'
    },
    addTaskLinkIcon: {
        border: '0',
        backgroundColor: null,
        color: 'black',
        fontSize: '8px',
        cursor: 'grab',
        float: 'left',
        marginRight: '1rem'
    },
    scwrapper: {
        height: '40vh',
        position: 'relative',
    },
});

RuleConfigValue.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

const connected = connect(mapStateToProps, null)(AuthProvider(RuleConfigValue));

export default withStyles(styles)(connected);
