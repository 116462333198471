import {
  FETCH_DECISION_POINT_MASTERS
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  decisionpointMasters: [],
  error: null
};

export default (state = INITIAL_STATE, action) => {
 switch (action.type) {
   case FETCH_DECISION_POINT_MASTERS:
        return {...state, decisionpointMasters: action.payload }; 
 default:
      return state;
  }
};
