import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from '../history';
import ReferenceDataConsole from '../pages/referenceData/ReferenceDataConsole';
import ReferenceDataBuilderWrapper from '../pages/referenceData/referenceDataBuilder/ReferenceDataBuilderWrapper';
import PipList from '../pages/referenceData/policyInfoPointMgm/pipList';
import TablesList from '../pages/referenceData/TableMappings/TableMappingSummaryList';
import TableMappingBuilder from '../pages/referenceData/TableMappings/TableMappingBuilder';
import PipTableMappingBuilder from '../pages/referenceData/TableMappings/PipTableMappingBuilder';
import HelpTextBuilder from '../pages/referenceData/helpTextManagement/HelpTextBuilder';
import AdminChangeConsole from '../pages/adminChangeConsole/ChangeControl/AdminChangeConsole';
import CheckPointMasterConsole from '../pages/checkPointMaster/CheckPointMasterConsole';
import PolicyLibraryMasterConsole from '../pages/policyLibraryMaster/PolicyLibraryMasterConsole';
import PolicyLibrarySchemeDetails from '../pages/policyLibraryMaster/PolicyLibrarySchemeDetails';
import CheckPointMasterEdit from '../pages/checkPointMaster/CheckPointMasterEdit';
import TaskMasterConsole from '../pages/taskMaster/TaskMasterConsole';
import TaskMasterBuilder from '../pages/taskMaster/TaskMasterBuilder';
import PolicyBuilder from '../pages/policyLibraryMaster/PolicyBuilder';
import { Header } from './Header';
import { msalApp, signOut } from './auth/AuthUtils';
import Breadcrumbs from './shared/breadcrumb';
import SecuredApp from './SecuredApp';
import Validation from '../pages/validation/ValidationConsole';
import NotificationLibraryConsole from '../pages/notification/NotificationLibraryConsole';
import NotificationBuilder from '../pages/notification/NotificationBuilder';
import TaskLibraryMasterConsole from '../pages/taskMaster/TaskLibraryMasterConsole';
import './style.css';
import ChangeSetManagementWrapper from '../pages/adminChangeConsole/changeSetManagement/ChangeSetManagementWrapper';
import AdminChangeSetBuilder from '../pages/adminChangeConsole/builder/AdminChangeSetBuilder';
import envReadOnlyFlagContext from '../context/adminContext';
import getReadOnlyFlagPerEnv from '../util/EnvUtil';
import GuidanceFragmentConsole from '../pages/referenceData/guidanceManagement/GuidanceFragmentConsole';
import GuidanceManagementBuilderWrapper from "../pages/referenceData/guidanceManagement/builder/GuidanceFragmentBuilderWrapper";

const isReadOnlyValue = getReadOnlyFlagPerEnv();

const routes = [
{
 path: '/', name: 'OLI Admin Portal', Component: SecuredApp, parent: ''
},
{
 path: '/checkPointMasterConsole', name: 'CheckPoint Masters', Component: CheckPointMasterConsole, parent: 'CheckPoint Masters'
},
{
 path: '/checkPointMasterConsole/checkPointMasterEdit', name: 'CheckPointMaster Edit', Component: CheckPointMasterEdit, parent: 'CheckPoint Masters'
},
{
 path: '/secure', name: 'SecuredApp', Component: SecuredApp, parent: 'SecuredApp'
},
{
 path: '/referenceDataConsole', name: 'Reference Data Console', Component: ReferenceDataConsole, parent: 'Reference Data Console'
},
{
 path: '/referenceDataConsole/referenceDataBuilder', name: 'Reference Data Builder', Component: ReferenceDataBuilderWrapper, parent: 'Reference Data Console'
},
{
 path: '/referenceDataConsole/pipList', name: 'Policy Information Point Management', Component: PipList, parent: 'Reference Data Console'
},
{
  path: '/referenceDataConsole/pipList/pipTableMappingBuilder', name: 'PIP Table Builder', Component: PipTableMappingBuilder, parent: 'Policy Information Point Management'
 },
{
 path: '/referenceDataConsole/tablesList', name: 'Mapping Table List', Component: TablesList, parent: 'Reference Data Console'
},
{
 path: '/referenceDataConsole/tablesList/tableMappingBuilder', name: 'Table Mapping Builder', Component: TableMappingBuilder, parent: 'Mapping Table List'
},
{
 path: '/referenceDataConsole/helpTextMgmBuilder', name: 'Help Text Management', Component: HelpTextBuilder, parent: 'ReferenceData Console'
},
{
 path: '/referenceDataConsole/guidanceMgmt', name: 'Guidance Fragment Management', Component: GuidanceFragmentConsole, parent: 'ReferenceData Console'
},
{
  path: '/referenceDataConsole/guidanceBuilder/:guidanceFragmentId', name: 'Guidance Fragment Builder', Component: GuidanceManagementBuilderWrapper, parent: 'ReferenceData Console'
},
{
 path: '/policyLibraryMasterConsole', name: 'Policies Master', Component: PolicyLibraryMasterConsole, parent: 'Policies Master'
},
{
 path: '/policyLibraryMasterConsole/library/:policySchemeTypeId', name: 'Policies Library', Component: PolicyLibrarySchemeDetails, parent: 'Policy Library'
},
{
 path: '/policyLibraryMasterConsole/editPolicy/:policySchemeTypeId', name: 'Policies Library', Component: PolicyBuilder, parent: 'Policy Builder'
},
{
 path: '/policyLibraryMasterConsole/policybuilder/:id', name: 'Policy Builder', Component: PolicyBuilder, parent: 'Policies Master'
},
{
 path: '/taskLibraryMasterConsole', name: 'Task Masters Library', Component: TaskLibraryMasterConsole, parent: 'Task Masters'
},
{
 path: '/taskLibraryMasterConsole/taskMasterConsole', name: 'Task Masters', Component: TaskMasterConsole, parent: 'Task Masters Library'
},
{
 path: '/taskLibraryMasterConsole/taskMasterConsole/taskbuilder/:id', name: 'Task Builder', Component: TaskMasterBuilder, parent: 'Task Masters'
},
{
 path: '/validationConsole', name: 'Validation', Component: Validation, parent: 'Validation'
},
{
 path: '/notificationLibraryConsole', name: 'Notification Masters', Component: NotificationLibraryConsole, parent: 'Notification Masters'
},
{
 path: '/notificationLibraryConsole/policybuilder/:notificationCategoryId', name: 'Notification Builder', Component: NotificationBuilder, parent: 'Notification Builder'
},
{
 path: '/adminChangeConsole', name: 'Admin Change Console', Component: AdminChangeConsole, parent: 'Admin Change Console'
},
{
 path: '/adminChangeConsole/changeSetManagement', name: 'Change Set Management', Component: ChangeSetManagementWrapper, parent: 'Admin Change Console'
},
{
 path: '/adminChangeConsole/changeSetBuilder/:changeSetId', name: 'Change Set Builder', Component: AdminChangeSetBuilder, parent: 'Change Set Management'
}
];

const App = (props) => (
  <div>
    <envReadOnlyFlagContext.Provider value={{ isReadOnly: isReadOnlyValue }}>
      <Router history={history}>
        {msalApp.getAccount() ? <Header onSignOut={signOut} path={routes} /> : null}
        <Switch>
          {routes.map(({ path, name, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              refresh='true'
              // eslint-disable-next-line no-shadow
              component={(props) => {
                const crumbs = routes
                  // eslint-disable-next-line no-shadow
                  .filter(({ path }) => props.match.path.includes(path))
                  // eslint-disable-next-line no-shadow
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                        // eslint-disable-next-line no-shadow
                        (path, param) => path.replace(
                          `:${param}`, props.match.params[param]
                        ), path
                      )
                      : path,
                    ...rest
                  }));

                // eslint-disable-next-line no-shadow
                return (
                  <div style={{ backgroundColor: '#f3f2f1' }}>
                    <div>
                      <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                    <Component {...props} />
                  </div>
                );
              }}
            />
          ))}
        </Switch>
      </Router>
    </envReadOnlyFlagContext.Provider>
  </div>
);

export default App;
