/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-expressions */
/* eslint-disable space-before-function-paren */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton, initializeIcons, IconButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';

import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../../components/auth/AuthProvider';
import { updatePolicySchemePdpDetails, createNewPolicySchemePdp, deletePolicySchemePdpDetails } from '../../../state/actions/PolicyLibraryMasterActions';
import { PolicySchemePdpDetailsActionTypeLiteral } from '../../../constants/ActionTypeObj';

import './PdpDetails.css';
import PdpForm from './PdpForm';

class PdpDetails extends React.Component {
  constructor(props) {
    super(props);
    const literalType = PolicySchemePdpDetailsActionTypeLiteral.filter((value) => value.type === this.props.literalType)[0];
    this.state = {
      literalType,
      policyDecisionPoint: null,
      isSubmitInProgress: false,
      withSubmitError: false,
      isCreateNewPdpSuccess: false,
      isUpdatePdpSuccess: false,
      isSelectedPdpToDelete: false,
      isDeletePdpSuccess: false,
      isDeletePdpButtonClicked: false,
    };
    initializeIcons();
  }

  async componentDidMount() {
    const { literalType } = this.state;
    document.title = literalType.title;
  }

  onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  /* Step and Window handler */

  onDismissPolicySchemePdpDetailsPanel = () => {
    this.props.hidePolicySchemePdpDetailsPanelHandler();
  }

  /* Processor */
  updatePdp = async ({ pdpCode, pdpReference, pdpTitle, pdpDescription, activationConditionExpression, policyEntitlementOperation }) => {
    const updatedPdpDetails = {
      policySchemeId: this.props.policySchemeId,
      pdpCode,
      pdpReference,
      pdpTitle,
      pdpDescription,
      activationConditionExpression,
      policyEntitlementOperation,
    };

    await this.props.updatePolicySchemePdpDetails(updatedPdpDetails, this.props.policyDecisionPoint.pdpCode);
    const { status } = this.props.updatePolicySchemePdpResult;
    if (status && status !== '200') {
      this.setState((prevState) => ({
        ...prevState, isUpdatePdpSuccess: true, isSubmitInProgress: false, withSubmitError: true, isCreateNewPdpSuccess: false
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState, isUpdatePdpSuccess: true, isSubmitInProgress: false, withSubmitError: false, isCreateNewPdpSuccess: false
      }));
    }
  }

  createNewPdp = async ({ pdpCode, pdpReference, pdpTitle, pdpDescription, activationConditionExpression, policyEntitlementOperation }) => {
    const newPdpDetails = {
      pdpCode,
      pdpReference,
      pdpTitle,
      pdpDescription,
      activationConditionExpression,
      policyEntitlementOperation,
    };
    await this.props.createNewPolicySchemePdp(newPdpDetails, this.props.policySchemeId);
    const { status } = this.props.newPolicySchemePdpResult;
    if (status && status !== '201') {
      this.setState((prevState) => ({
        ...prevState, isUpdatePdpSuccess: false, isSubmitInProgress: false, withSubmitError: true, isCreateNewPdpSuccess: true
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState, isUpdatePdpSuccess: false, isSubmitInProgress: false, withSubmitError: false, isCreateNewPdpSuccess: true
      }));
    }
  }

  deletePdp = async () => {
    await this.props.deletePolicySchemePdpDetails(this.props.policyDecisionPoint.pdpCode);
    const { status } = this.props.deletePolicySchemePdpResult;
    if (status && status !== '200') {
      this.setState((prevState) => ({ ...prevState, isSelectedPdpToDelete: false, isDeletePdpSuccess: true, withSubmitError: true }));
    } else {
      this.setState((prevState) => ({ ...prevState, isSelectedPdpToDelete: false, isDeletePdpSuccess: true, withSubmitError: false }));
    }
  }

  /* Screens */
  buildPdpEditor = () => {
    const { classes, isReadOnly, isDisableDeleteFunction } = this.props;
    return (
      <>
        {!this.props.addNewPdp &&
          <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign="space-between" className={classes.actionsTop}>
            <Stack>
              <div className={classes.pdpTitle}>{this.props.policyDecisionPoint.pdpTitle}</div>
              <br />
              <div className={classes.pdpCode}>{this.props.policyDecisionPoint.pdpCode}</div>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
              <div className={classes.divider1}></div>
              <div>
                <IconButton
                  iconProps={{ iconName: "Delete" }}
                  onClick={() => this.setState({ isSelectedPdpToDelete: true })}
                  styles={{
                    root: { height: "auto" },
                    icon: { fontSize: "28px", margin: "30px 20px 30px 20px", color: "grey" },
                    rootHovered: { backgroundColor: "#fff" },
                  }}
                  disabled={isDisableDeleteFunction || isReadOnly}
                ></IconButton>
              </div>
            </Stack>
          </Stack>
        }
        <PdpForm policyDecisionPoint={this.props.policyDecisionPoint} isReadOnly={isReadOnly} dismissPanelHandler={this.onDismissPolicySchemePdpDetailsPanel}
          saveChangesHandler={(data) => (this.props.addNewPdp ? this.createNewPdp(data) : this.updatePdp(data))} />
      </>
    );
  }

  buildCreateUpdateDeletePdpCompleteScreen = () => {
    const { classes } = this.props;
    return (
      <div className={classes.card} style={{ textAlign: 'center' }} >
        <Stack>
          <div className={classes.todo}><img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /></div>
        </Stack>
        <Stack>
          <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>{!this.state.withSubmitError ? "Success" : "Failed"}</div>
        </Stack>
        <Stack>
          {this.state.isUpdatePdpSuccess &&
            (!this.state.withSubmitError ? <div className={classes.todo}>You have successfully updated PDP</div> : <div className={classes.withSubmitError}>{this.props.updatePolicySchemePdpResult.message}</div>)}
          {this.state.isDeletePdpSuccess &&
            (!this.state.withSubmitError ? <div className={classes.todo}>You have successfully deleted PDP</div> : <div className={classes.withSubmitError}>{this.props.deletePolicySchemePdpResult.message}</div>)}
          {this.state.isCreateNewPdpSuccess &&
            (!this.state.withSubmitError ? <div className={classes.todo}>You have successfully created PDP</div> : <div className={classes.withSubmitError}>{this.props.newPolicySchemePdpResult.message}</div>)}
          <div className={classes.todo}><b>{this.props.policyDecisionPoint?.pdpTitle || this.props.newPolicySchemePdpResult.pdpTitle}</b></div>
        </Stack>
        <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
          <DefaultButton text="Close" onClick={this.onDismissPolicySchemePdpDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Close')} />
          {this.state.isCreateNewPdpSuccess &&
            <DefaultButton text="Edit PDP" onClick={() => this.props.editNewPdpHandlder(this.props.newPolicySchemePdpResult)} onRenderText={(p) => this.onRenderButtonText(p, 'Edit PDP')} />
          }
        </Stack>
      </div>
    );
  }

  buildDeletePdpConfirmationScreen() {
    const { classes, isReadOnly } = this.props;
    return (
      <div className={classes.card} style={{ textAlign: 'center' }} >
        <Stack>
          <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>Confirm Deletion of PDP</div>
        </Stack>
        <Stack>
          <div className={classes.msg}>Confirm the deletion of:</div>
          <div className={classes.polText}><b>{this.props.policyDecisionPoint.pdpTitle}</b></div><br />
        </Stack>
        <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
          <DefaultButton text="Cancel" onClick={() => this.setState({ isSelectedPdpToDelete: false })} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
          <DefaultButton text="Delete" onClick={() => { this.setState({ isDeletePdpButtonClicked: true }); this.deletePdp(); }}
            onRenderText={(p) => this.onRenderButtonText(p, 'Delete')} disabled={isReadOnly || this.state.isDeletePdpButtonClicked} />
        </Stack>
      </div>
    );
  }

  render() {
    return (
      <Panel
        isOpen={this.props.isPolicySchemePdpDetailsPanelOpen}
        onDismiss={this.onDismissPolicySchemePdpDetailsPanel}
        type={PanelType.custom}
        customWidth={'650px'}
        closeButtonAriaLabel="Close"
        headerText={this.props.headerText}
      >
        <Separator></Separator>
        {(this.state.isUpdatePdpSuccess || this.state.isDeletePdpSuccess || this.state.isCreateNewPdpSuccess) && this.buildCreateUpdateDeletePdpCompleteScreen()}
        {this.state.isSelectedPdpToDelete && this.buildDeletePdpConfirmationScreen()}
        {!this.state.isCreateNewPdpSuccess && !this.state.isUpdatePdpSuccess
          && !this.state.isSelectedPdpToDelete && !this.state.isDeletePdpSuccess && this.buildPdpEditor()}
      </Panel>
    );
  }
}

const OliLogo = require('../../../images/oli-success.svg');

// Example formatting
const stackTokens = { childrenGap: 40 };

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  sectionTitle: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '20px 0 24px 0',
  },
  selectStyle: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '0 0 5px 0',
  },
  actions: {
    padding: '20px 0 20px 0'
  },
  actionsTop: {
    padding: '20px 0 6px 0'
  },
  selectedRuleBlock: {
    padding: '20px 0 0 0'
  },
  previewPart: {
    backgroundColor: '#f6f6f6'
  },
  card: { // used
    padding: '15px', /* JUST TO LOOK COOL */
    border: '1px solid #eee',  /* JUST TO LOOK COOL */
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
    transition: 'all .3s ease-in-out',
  },
  withSubmitError: { // used
    color: 'red',
  },
  button: {
    fontWeight: '400',
  },
  pdpTitle: {
    fontSize: '16px',
    fontWeight: '600'
  },
  pdpCode: {
    fontSize: '16px',
    fontWeight: 'normal'
  },
  divider1: {
    width: '1px',
    border: 'solid 0px #cdcdcd',
    backgroundColor: '#f3f2f1',
  },
});

PdpDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

PdpDetails.defaultProps = {
  addNewPdp: false,
  isDisableDeleteFunction: false,
};

const mapStateToProps = (state) => ({
  policySchemeConfigurationsNames: state.policyLibraryBuilderData.policySchemeConfigurationsNames,
  updatePolicySchemePdpResult: state.policyLibraryMasterData.updatePolicySchemePdpResult,
  newPolicySchemePdpResult: state.policyLibraryMasterData.newPolicySchemePdpResult,
  deletePolicySchemePdpResult: state.policyLibraryMasterData.deletePolicySchemePdpResult,
});

const connected = connect(mapStateToProps, {
  updatePolicySchemePdpDetails,
  createNewPolicySchemePdp,
  deletePolicySchemePdpDetails
})(AuthProvider(PdpDetails));

export default withStyles(styles)(connected);
