/* eslint-disable no-else-return */
/* eslint-disable no-extra-semi */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-expressions */

import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { DefaultButton, PrimaryButton, IconButton, TextField } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { fetchPolicyConfigurationNames } from '../../../state/actions/PolicyLibraryMasterActions';
import POLICY_OPERATION_CONDITION from '../constants/PdpDetailConstants';

const PdpForm = (props) => {
  const { classes, policyDecisionPoint } = props;
  const [pdpCode, setPdpCode] = useState(policyDecisionPoint ? policyDecisionPoint.pdpCode : "");
  const [pdpTitle, setPdpTitle] = useState(policyDecisionPoint ? policyDecisionPoint.pdpTitle : "");
  const [pdpDescription, setPdpDescription] = useState(policyDecisionPoint ? policyDecisionPoint.pdpDescription : "");
  const [pdpReference, setPdpReference] = useState(policyDecisionPoint ? policyDecisionPoint.pdpReference : "");
  const [activationConditionExpression, setActivationConditionExpression] = useState(policyDecisionPoint ? policyDecisionPoint.activationConditionExpression : "");
  const [policyEntitlementOperation, setPolicyEntitlementOperation] = useState(policyDecisionPoint ? policyDecisionPoint.policyEntitlementOperation : "ALL_TRUE");
  const [pdpCodeIsValid, setPdpCodeIsValid] = useState(!!policyDecisionPoint);
  const [pdpReferenceIsValid, setPdpReferenceIsValid] = useState(!!policyDecisionPoint);
  const [pdpTitleIsValid, setPdpTitleIsValid] = useState(!!policyDecisionPoint);
  const [pdpDescriptionIsValid, setPdpDescriptionIsValid] = useState(!!policyDecisionPoint);
  const [isSavePdpButtonClicked, setIsSavePdpButtonClicked] = useState(false);
  const stackTokens = { childrenGap: 40 };

  const policySchemeConfigurationsNames = useSelector(
    (state) => state.policyLibraryBuilderData.policySchemeConfigurationsNames
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPolicyConfigurationNames());
  }, [dispatch]);

  const onChangePdpCode = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    setPdpCodeIsValid(validatePdpCode(newVal.trim()));
    setPdpCode(newVal);
  };

  const onChangePdpReference = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    setPdpReferenceIsValid(newVal.trim());
    setPdpReference(newVal);
  };

  const onChangePdpTitle = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    setPdpTitleIsValid(newVal.trim());
    setPdpTitle(newVal);
  };

  const onChangePdpDescription = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    setPdpDescriptionIsValid(!!newVal);
    setPdpDescription(newVal);
  };

  const onChangeActivationCondition = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    setActivationConditionExpression(newVal);
  };

  const onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  const validatePdpCode = (opdpCode) => checkIfValueIsValidAndUnique(policyDecisionPoint?.pdpCode, opdpCode, policySchemeConfigurationsNames.pdpValues.pdpCodes);

  const checkIfValueIsValidAndUnique = (originalValue, newValue, arraysToCheckAgainst) => {
    if (!newValue) {
      return false;
    } else if (originalValue && originalValue === newValue) {
      return true;
    }
    return !arraysToCheckAgainst.includes(newValue);
  };

  const createValidationStatus = (fieldName, status) => {
    if (status) {
      return (
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px'
        }}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} styles={{ root: { color: 'green' } }} /><span style={{ fontSize: '12px' }}>This is a valid {fieldName}.</span></div>);
    }
    return (
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px'
      }}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} /><span style={{ fontSize: '12px' }}>This not yet a valid and unique {fieldName}. Update to continue.</span></div>
    );
  };

  return (
    <>
      <Stack horizontal tokens={stackTokens} className={classes.actions}>
        <DefaultButton text="Cancel" onClick={props.dismissPanelHandler} onRenderText={(p) => onRenderButtonText(p, 'Cancel')} />
        <PrimaryButton text="Save" onClick={() => {
          setIsSavePdpButtonClicked(true);
          props.saveChangesHandler({ pdpCode, pdpReference, pdpTitle, pdpDescription, activationConditionExpression, policyEntitlementOperation });
        }}
          disabled={props.isReadOnly || !pdpCodeIsValid || !pdpTitleIsValid || !pdpReferenceIsValid || !pdpDescriptionIsValid || isSavePdpButtonClicked} />
      </Stack>
      <Separator></Separator>
      <Stack>
        <div className={classes.sectionTitle}>PDP DETAILS</div>
      </Stack>
      <Stack tokens={stackTokens}>
        <Stack className={classes.inputArea}>
          <TextField label="PDP Code" value={pdpCode} onChange={onChangePdpCode} disabled={props.isReadOnly || policyDecisionPoint} style={{ paddingLeft: '8px' }} />
          <Stack horizontal>
            {createValidationStatus('PDP code', pdpCodeIsValid)}
          </Stack>
        </Stack>
      </Stack>
      <Stack tokens={stackTokens}>
        <Stack className={classes.inputArea}>
          <TextField label="PDP Name" value={pdpTitle} onChange={onChangePdpTitle} readOnly={props.isReadOnly} style={{ paddingLeft: '8px' }} />
        </Stack>
      </Stack>
      <Stack tokens={stackTokens}>
        <Stack className={classes.inputArea}>
          <TextField label="PDP Description" value={pdpDescription} onChange={onChangePdpDescription} readOnly={props.isReadOnly} style={{ paddingLeft: '8px' }} />
        </Stack>
      </Stack>
      <Stack tokens={stackTokens}>
        <Stack className={classes.inputArea}>
          <TextField label="PDP Reference" value={pdpReference} onChange={onChangePdpReference} readOnly={props.isReadOnly} style={{ paddingLeft: '8px' }} />
        </Stack>
      </Stack>
      <Stack tokens={stackTokens}>
        <Stack className={classes.inputArea}>
          <Dropdown label="Policy Operation Condition"
            selectedKey={policyEntitlementOperation}
            onChange={(e, i) => setPolicyEntitlementOperation(i.key)}
            options={POLICY_OPERATION_CONDITION}
          />
        </Stack>
      </Stack>
      <Stack tokens={stackTokens}>
        <Stack className={classes.inputArea}>
          <TextField label="Activation Condition(Optional)" value={activationConditionExpression} onChange={onChangeActivationCondition} readOnly={props.isReadOnly} style={{ paddingLeft: '8px' }} />
        </Stack>
      </Stack>
    </>
  );
};

const styles = () => ({
  sectionTitle: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '20px 0 24px 0',
  },
  inputArea: {
    height: '86px',
    padding: '0 10px 10px 0px',
    borderRadius: '2px',
    marginBottom: '10px',
  },
  actions: {
    padding: '20px 0 20px 0'
  },
});

PdpForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

PdpForm.defaultProps = {};

export default withStyles(styles)(PdpForm);
