/* eslint-disable space-before-function-paren */
import api from "../../api/IntelligentAgentServiceApi";
import {
  FETCH_ALL_TABLE_MAPPINGS,
  FETCH_NOTIFICATION_ROLE_MAPPINGS,
  FETCH_ALL_FUNCTIONS,
  FETCH_ALL_EVENT_TYPES,
  FETCH_ALL_CHANGE_TYPE_EVENT_TYPE_MAPPINGS,
  FETCH_ALL_REQUIRED_FIELDS_DECISION_POINT_TYPE_MAPPINGS,
  FETCH_ALL_MANAGED_FIELDS_DECISION_POINT_TYPE_MAPPINGS,
  FETCH_ALL_REQUIRED_FIELDS_CHECKPOINT_TYPE_MAPPINGS,
  FETCH_ALL_MANAGED_FIELDS,
  FETCH_ALL_PRR_CRITERIA_CATEGORIES,
  FETCH_ALL_PRR_CRITERIAS,
  FETCH_ALL_PRR_CRITERIA_ENTITIES,
  FETCH_ALL_PRR_CRITERIA_TAGS,
  UI_SHOW_MESSAGE_ERROR,
  UI_SHOW_MESSAGE_SUCCESS,
} from "../../constants/ActionTypes";

export const fetchAllTableMappings = () => async (dispatch) => {
  const response = await api.get(`/admin/mapping-tables-config`);
  dispatch({ type: FETCH_ALL_TABLE_MAPPINGS, payload: response.data });
};

export const fetchNotificationMsaterRoleMappings = () => async (dispatch) => {
  const response = await api.get(
    `/admin/notification-masters/role-associations`
  );
  dispatch({ type: FETCH_NOTIFICATION_ROLE_MAPPINGS, payload: response.data });
};

export const fetchAllEventTypes = () => async (dispatch) => {
  const response = await api.get(`/admin/event-types`);
  dispatch({ type: FETCH_ALL_EVENT_TYPES, payload: response.data.summaries });
};

export const updateMappingTableValue = (configKey, formValues) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Mapping Table updated successfully",
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error updating Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.put(
      `/admin/mapping-tables-config/${configKey}`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addNotificationFunctionMappings = (notificationMasterId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Notification Function Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Notification Function Mapping Table updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Notification Function mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/notification-masters/${notificationMasterId}/functions`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delNotificationFunctionMappings = (notificationMasterId, functionId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Notification Function Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Notification Function Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/notification-masters/${notificationMasterId}/functions/${functionId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addNotificationRoleMappings = (notificationMasterId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Notification Role Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Notification Role Mapping Table updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Notification Role mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/notification-masters/${notificationMasterId}/roles`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delNotificationRoleMappings = (notificationMasterId, roleId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Notification Role Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Notification Role Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/notification-masters/${notificationMasterId}/roles/${roleId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addNotificationRecipientStrategyMappings = (notificationMasterId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Notification Recipient Strategy Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Notification Recipient Strategy Mapping Table updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Notification Recipient Strategy mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/notification-masters/${notificationMasterId}/recipient-strategies`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delNotificationRecipientStrategyMappings = (notificationMasterId, recipientStrategyId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Notification Recipient Strategy Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Notification Recipient Strategy Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/notification-masters/${notificationMasterId}/recipient-strategies/${recipientStrategyId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addNotificationAssociatedEventTypeMappings = (notificationMasterId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Notification Associated Event Type Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Notification Associated Event Type Mapping Table updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg = "Error: this Notification Associated Event Type mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/notification-masters/${notificationMasterId}/associated-event-types`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delNotificationAssociatedEventTypeMappings = (notificationMasterId, eventType) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Notification Associated Event Type Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message:
        "Error deleting Notification Associated Event Type Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/notification-masters/${notificationMasterId}/associated-event-types/${eventType}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllFunctions = () => async (dispatch) => {
  const response = await api.get(
    `/admin/user-functions?sort=code&direction=asc`
  );
  dispatch({ type: FETCH_ALL_FUNCTIONS, payload: response.data });
};

export const updateNotificationFunction = (functionId, functionName, formValues) => async(dispatch) => {
   // eslint-disable-next-line space-before-function-paren
   function onSuccess(success) {
     dispatch({
       type: UI_SHOW_MESSAGE_SUCCESS,
       message:
         "Risk Notification Function " + functionName + " is updated successfully",
     });
     return success;
   }
   // eslint-disable-next-line space-before-function-paren
   function onError(error) {
     dispatch({
       type: UI_SHOW_MESSAGE_ERROR,
       message: "Error updating Notification Function " + functionName,
     });
     return error;
   }
   try {
     const success = await api.put(
       `/admin/user-functions/${functionId}`,
       formValues
     );
     return onSuccess(success);
   } catch (error) {
     return onError(error);
   }

}

export const addNotificationFunction = (formValues) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Notification Function added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error adding Notification Function",
    });
    return error;
  }
  try {
    const success = await api.post(`/admin/user-functions`, formValues);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const deleteNotificationFunction = (functionId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Notification Function added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error adding Notification Function",
    });
    return error;
  }
  try {
    const success = await api.delete(`/admin/user-functions/${functionId}`);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllChangeTypeEventTypeMappings = () => async (dispatch) => {
  const response = await api.get(
    `/admin/ccr/change-item-types/event-types-associations`
  );
  dispatch({
    type: FETCH_ALL_CHANGE_TYPE_EVENT_TYPE_MAPPINGS,
    payload: response.data,
  });
};

export const addChangeTypeEventTypeMapping = (changeTypeId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Change Type Event Type Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Change Type Event Type Mapping Table updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg = "Error: this Change Type Event Type mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/ccr/change-item-types/${changeTypeId}/associated-event-types`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delChangeTypeEventTypeMapping = (changeTypeId, eventType) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Change Type Event Type Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Change Type Event Type Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/ccr/change-item-types/${changeTypeId}/associated-event-types/${eventType}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllRequiredFieldsDecisionPointTypeMappings = () => async (dispatch) => {
  const response = await api.get(
    `/admin/fields/required-decision-point-types-associations`
  );
  dispatch({
    type: FETCH_ALL_REQUIRED_FIELDS_DECISION_POINT_TYPE_MAPPINGS,
    payload: response.data,
  });
};

export const fetchAllManagedFields = () => async (dispatch) => {
  const response = await api.get(`/admin/fields-summaries`);
  dispatch({
    type: FETCH_ALL_MANAGED_FIELDS,
    payload: response.data,
  });
};

export const addRequiredFieldsDecisionPointTypeMapping = (managedFieldId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Required Field Decision Point Type Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Required Field Decision Point Type Mapping Table added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg = "Error: this Required Field Decision Point Type mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/fields/${managedFieldId}/required-associated-decision-point-types`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delRequiredFieldsDecisionPointTypeMapping = (managedFieldId, decisionPointTypeId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Required Field Decision Point Type Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message:
        "Error deleting Required Field Decision Point Type Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/fields/${managedFieldId}/required-associated-decision-point-types/${decisionPointTypeId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllManagedFieldsDecisionPointTypeMappings = () => async (dispatch) => {
  const response = await api.get(
    `admin/fields/managed-decision-point-types-associations`
  );
  dispatch({
    type: FETCH_ALL_MANAGED_FIELDS_DECISION_POINT_TYPE_MAPPINGS,
    payload: response.data,
  });
};

export const delManagedFieldsDecisionPointTypeMapping = (managedFieldId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Managed Field Decision Point Type Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message:
        "Error deleting Managed Field Decision Point Type Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/fields/${managedFieldId}/managed-associated-decision-point-types`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addManagedFieldsDecisionPointTypeMapping = (managedFieldId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Managed Field Decision Point Type Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Managed Field Decision Point Type Mapping Table added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Managed Field Decision Point Type mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/fields/${managedFieldId}/managed-associated-decision-point-types`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllRequiredFieldsCheckpointTypeMappings = () => async (dispatch) => {
  const response = await api.get(
    `admin/fields/required-checkpoint-types-associations`
  );
  dispatch({
    type: FETCH_ALL_REQUIRED_FIELDS_CHECKPOINT_TYPE_MAPPINGS,
    payload: response.data,
  });
};

export const addRequiredFieldsCheckpointTypeMapping = (managedFieldId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Required Field Decision Point Type Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Required Field Decision Point Type Mapping Table added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg = "Error: this Required Field Decision Point Type mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/fields/${managedFieldId}/required-associated-checkpoint-types`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delRequiredFieldsCheckpointTypeMapping = (managedFieldId, checkpointTypeId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Required Field Decision Point Type Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message:
        "Error deleting Required Field Decision Point Type Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/fields/${managedFieldId}/required-associated-checkpoint-types/${checkpointTypeId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllPRRCriteriaCategories = () => async (dispatch) => {
  const response = await api.get(
    `/admin/risk-rating/criteria-categories-associations`
  );
  dispatch({
    type: FETCH_ALL_PRR_CRITERIA_CATEGORIES,
    payload: response.data,
  });
};

export const fetchAllPRRCriterias = (sortColumn) => async (dispatch) => {
  const response = await api.get(`/admin/risk-rating-criterias?enabled=true&sort=${sortColumn}&direction=asc`);
  dispatch({
    type: FETCH_ALL_PRR_CRITERIAS,
    payload: response.data,
  });
};

export const fetchAllPRRCriteriaEntities = () => async (dispatch) => {
  const response = await api.get(
    `/admin/risk-rating/criteria-entities-associations`
  );
  dispatch({
    type: FETCH_ALL_PRR_CRITERIA_ENTITIES,
    payload: response.data,
  });
};

export const addPRRCriteriaCategoryMapping = (criteriaId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Risk Rating Criteria Category Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Criteria Category Mapping Table added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg = "Error: this Risk Rating Criteria and Category mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/risk-rating/${criteriaId}/criteria-categories`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delPRRCriteriaCategoryMapping = (criteriaId, riskCategoryType) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Criteria Category Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Risk Rating Criteria Category Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/risk-rating/${criteriaId}/criteria-categories/${riskCategoryType}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addPRRCriteriaEntityMapping = (criteriaId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Risk Rating Criteria Entity Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Risk Rating Criteria Entity Mapping Table added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg = "Error: this Risk Rating Criteria and Entity mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/risk-rating/${criteriaId}/criteria-entities`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delPRRCriteriaEntityMapping = (criteriaId, entityId) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Required Field Risk Rating Criteria Entity Mapping Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Risk Rating Criteria Entity Mapping Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/risk-rating/${criteriaId}/criteria-entities/${entityId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const fetchAllPRRCriteriaTags = () => async (dispatch) => {
  const response = await api.get(`/admin/risk-rating-restriction-tags`);
  dispatch({
    type: FETCH_ALL_PRR_CRITERIA_TAGS,
    payload: response.data,
  });
};

export const addPRRCriteriaTagMapping = (criteriaId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Risk Rating Criteria Tag Mapping Table";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Risk Rating Criteria Tag Mapping Table added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Risk Rating Criteria and tag name mapping already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/risk-rating/${criteriaId}/criteria-restriction-tags`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delPRRCriteriaTagMapping = (tagId, tagValue) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Restriction Tag Value Table is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Risk Rating Tag Value Table",
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/criteria-restriction-tags/${tagId}/values/${tagValue}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delPRRCriteria = (criteriaId, criteriaTitle) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Restriction Criteria " + criteriaTitle + " is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Risk Rating Criteria " + criteriaTitle,
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/risk-rating-criterias/${criteriaId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addPRRCriteria = (formValues) => async (dispatch) => {
  let errMsg = "Error adding Risk Rating Criteria";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Risk Rating Criteria added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Risk Rating Criteria already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/risk-rating-criterias`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const updatePRRCriteria = (criteriaId, criteriaTitle, formValues) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Restriction Criteria " + criteriaTitle + " is updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error updating Risk Rating Criteria " + criteriaTitle,
    });
    return error;
  }
  try {
    const success = await api.put(
      `/admin/risk-rating-criterias/${criteriaId}`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const updateCriteriaOption = (criteriaOptionId, criteriaOption, formValues) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Restriction Criteria Option " + criteriaOption + " is updated successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error updating Risk Rating Criteria Option " + criteriaOption,
    });
    return error;
  }
  try {
    const success = await api.put(
      `/admin/risk-rating-criteria-options/${criteriaOptionId}`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const addCriteriaOption = (criteriaId, formValues) => async (dispatch) => {
  let errMsg = "Error adding Risk Rating Criteria Option";
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message: "Risk Rating Criteria Option added successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    if (error.response.status === 409) {
      errMsg =
        "Error: this Risk Rating Criteria Option already exist. Mapping Table Values need to be unique.";
    }
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: errMsg,
    });
    return error;
  }
  try {
    const success = await api.post(
      `/admin/risk-rating-criterias/${criteriaId}/options`,
      formValues
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};

export const delCriteriaOption = (criteriaOptionId, criteriaOption) => async (dispatch) => {
  // eslint-disable-next-line space-before-function-paren
  function onSuccess(success) {
    dispatch({
      type: UI_SHOW_MESSAGE_SUCCESS,
      message:
        "Risk Rating Restriction Criteria Option " + criteriaOption + " is deleted successfully",
    });
    return success;
  }
  // eslint-disable-next-line space-before-function-paren
  function onError(error) {
    dispatch({
      type: UI_SHOW_MESSAGE_ERROR,
      message: "Error deleting Risk Rating Criteria Option " + criteriaOption,
    });
    return error;
  }
  try {
    const success = await api.delete(
      `/admin/risk-rating-criteria-options/${criteriaOptionId}`
    );
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }
};
