/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import uuid from 'react-uuid';
import { fetchGuidanceFragmentPolicyConfigSummaries, fetchGuidanceFragments } from '../../../state/actions/GuidanceFragmentActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import GuidanceFragmentList from './GuidanceFragmentList';
import CreateGuidanceFragment from './CreateGuidanceFragment';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';
import FilterCallout from '../../../components/filterv2/FilterCallout';
import {
  createFilterOptionsByEntity, createFilterOptionsByRegion, createFilterOptionsByEntityType
} from '../../../util/FilterOptionsUtil';
import FilterOptionTypeLiteral from '../../../constants/FilterLiterals';

const isReadOnly = getReadOnlyFlagPerEnv();

class GuidanceFragmentConsole extends React.Component {
  constructor (props) {
    super(props);
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === 'GUIDANCE')[0];
    this.state = {
      filterLiterals,
      open: false,
      showAddGuidanceFragment: false,
      filterSelections: [],
      filterOptionsKey: uuid(),
      toggleFilterCallout: false,
    };
  }

  async componentDidMount () {
    await this.props.fetchGuidanceFragments();
    await this.props.fetchGuidanceFragmentPolicyConfigSummaries();
    document.title = 'Guidance Fragments Library';
  }

  handleChange = (event) => {
    this.setState({ pageView: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (value) => {
    this.setState({ open: false });
  };

  listGuidanceFragments = () => {
   const guidanceFragmentsList = this.reduceByApplyingFilter();
    return (
      <GuidanceFragmentList guidanceFragmentsList = {guidanceFragmentsList} isReadOnly={isReadOnly}></GuidanceFragmentList>
    );
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.guidanceFragmentData !== nextProps.guidanceFragmentData) {
      return { guidanceFragmentData: nextProps.guidanceFragmentData };
    }
    return null;
  }

  refreshGuidanceFragment =() => {
    this.props.fetchGuidanceFragmentPolicyConfigSummaries();
    document.title = 'Guidance Fragments Library';
  }

  handleAddGuidanceFragmentShow = () => {
    this.setState({ showAddGuidanceFragment: true });
  };

  handleAddGuidanceFragmentClose = () => {
    this.setState({ showAddGuidanceFragment: false });
    this.refreshGuidanceFragment();
  };

  toggleFilterCalloutHandler = () => {
    this.setState((prevState) => ({ ...prevState, toggleFilterCallout: !this.state.toggleFilterCallout }));
  };

  findAllByKey=(obj, keyToFind) => Object.entries(obj)
      // eslint-disable-next-line no-nested-ternary
      .reduce((acc, [key, value]) => ((key === keyToFind)
        ? acc.concat(value)
        : (typeof value === 'object')
        ? acc.concat(this.findAllByKey(value, keyToFind))
        : acc),
       [])

  createFilterOptions () {
    if (!this.props.guidanceFragmentsSummaryData) return {};
    const regionlist = this.findAllByKey(this.props.guidanceFragmentsSummaryData, 'region');
    const regionlistdistinct = [...new Set(regionlist.map((p) => p))];

    const entitylist = this.findAllByKey(this.props.guidanceFragmentsSummaryData, 'entity');
    const entitylistdistinct = [...new Set(entitylist.map((p) => p))];

    const filterEntity = createFilterOptionsByEntity(entitylistdistinct);
    const filterRegion = createFilterOptionsByRegion(regionlistdistinct);
    const filterEntityType = createFilterOptionsByEntityType();

    const filterEntries = {
      contentFilters: [filterEntity, filterRegion, filterEntityType]
    };
    return filterEntries;
  }

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState((prevState) => ({ ...prevState, filterSelections }));
  }

  displaySelection () {
    const { filterSelections } = this.state;
    return Array.isArray(filterSelections) && filterSelections.length > 0;
  }

  reduceByApplyingFilter () {
    const { filterSelections } = this.state;
    let guidanceList = [];

    const filterlist = filterSelections.map((filterInd, index) => filterInd.items.map((item) => item));
    const filter = [].concat(...filterlist);

    if (filter.length === 0) {
      guidanceList = [...this.props.guidanceFragmentsSummaryData];
    } else {
        guidanceList = this.props.guidanceFragmentsSummaryData;
        if (filterSelections[0].items.length > 0) {
          const compareIfEntityExists = (entity) => filterSelections[0].items.some((ch) => ch.key === entity);
          guidanceList = guidanceList.map((result) => {
            result.policyConfigurations = result.policyConfigurations.filter((psc) => compareIfEntityExists(psc.entity));
                return result;
          });
        }

        if (filterSelections[1].items.length > 0) {
          const compareIfRegionExists = (region) => filterSelections[1].items.some((ch) => ch.key === region);
          guidanceList = guidanceList.map((result) => {
            result.policyConfigurations = result.policyConfigurations.filter((psc) => compareIfRegionExists(psc.region));
                return result;
          });
        }
        if (filterSelections[2].items.length > 0) {
          const compareIfEntityTypeExists = (entity) => filterSelections[2].items.some((ch) => ch.key === entity);
          guidanceList = guidanceList?.filter((psc, index) => compareIfEntityTypeExists(psc.entityType));
        }
    }
    return guidanceList;
  }

  handleSelectedTypeToggle = (item) => () => {
    const { filterSelections } = this.state;
    const newselValues = filterSelections.map((selectedValue, index) => {
      selectedValue.items = selectedValue.items.filter((ch) => ch.key !== item.key);
        return selectedValue;
      });

     const newfilterSelections = newselValues;
    this.setState((prevState) => ({ ...prevState, filterSelections: [...newfilterSelections] }));
  };

  render () {
    const { classes } = this.props;
    const { filterType } = this.state.filterLiterals;
    const {
      toggleFilterCallout,
      filterOptionsKey,
      filterSelections,
    } = this.state;

    if (!this.props.guidanceFragmentsSummaryData) return <div></div>;
    return (
      <Box display="flex">
        <Card variant='outlined' className={this.props.classes.rootBackground}>
          <CardHeader className={this.props.classes.cardHeaderSection}
            title={
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={8} >
                  <Typography className={this.props.classes.cardHeader}>
                    Guidance Management
                  </Typography>
                </Grid>
                <Grid item xs={4} >
                  <button className={classes.buttonSubmit} disabled={isReadOnly} onClick={() => this.handleAddGuidanceFragmentShow()}>Add Guidance</button>
                </Grid>
              </Grid>
            }
          />

          <CardContent>
            <Box mt={1} container="true" justify-content="space-around"
              flexWrap="wrap" width={1} >
              <>
                 <div className={classes.grid1}>
                  <div id="FilterCallout" className={classes.filtersButton} onClick={this.toggleFilterCalloutHandler}>
                    Filters
                  </div>
                  <FilterCallout openDialog={toggleFilterCallout} toggleFilterCalloutHandler={this.toggleFilterCalloutHandler} key={filterOptionsKey} filterType={filterType} filterOptions={this.createFilterOptions()} applyFilterSelectionsHandler={this.applyFilterSelectionsHandler} initialSelections={filterSelections} showSelectedItems={false} ></FilterCallout>

                </div>
                <hr className={this.props.classes.sepLine} />
                <Grid item xs={12} sm container>
                  { this.displaySelection()
                    ? <div>
                      {filterSelections.map((f) => f.items.map((item) => (
                        <Button
                          className={classes.filterButtonLabel}
                          name="selectedTypeValuesBtn"
                        >
                          {item.value}
                          <ClearIcon
                            className={classes.buttonClearIcon}
                            onClick={this.handleSelectedTypeToggle(item)}
                          ></ClearIcon>
                        </Button>
                      )))}
                    </div>
                    : null }
                </Grid>

                <div style={{ marginTop: '1rem' }}>
                  {this.listGuidanceFragments()}
                </div>
              </>
            </Box>
          </CardContent>
        </Card>
        {
          this.state.showAddGuidanceFragment ? <CreateGuidanceFragment isReadOnly={isReadOnly} guidanceFragmentDataList= {this.props.guidanceFragmentsSummaryData} hideAddGuidanceFragmentHandler={this.handleAddGuidanceFragmentClose}
          /> : null
        }
      </Box>
    );
  }
}

const styles = (theme) => ({
  rootBackground: {
    height: '100%',
    backgroundColor: 'white',
    width: '100%',
    margin: '1rem',
    marginLeft: '5rem',
    marginRight: '5rem'
  },
  filtersButton: {
    width: '40px',
    height: '18px',
    padding: '5px 19px 7px',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    backgroundColor: '#ffffff',
  },
  cardHeaderSection: {
    padding: '16px 0 0 0'
  },
  cardHeader: {
    fontSize: '27px',
    fontFamily: 'PharmaCond',
    paddingLeft: '1rem',
    color: '#323130',
    fontWeight: '700',
    titleTypographyProps: { variant: 'h1' },
    title: {
      titleTypographyProps: { variant: 'h1' },
      fontSize: '12px'
    },
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  filterButtonLabel: {
    textTransform: 'capitalize',
    backgroundColor: '#EAEAEA',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'auto',
    marginRight: '1rem'
  },
  buttonSubmit: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#006ed2',
    color: 'white',
    fontWeight: '500',
    orderRadius: '2px',
    border: 'none',
    cursor: 'pointer',
    float: 'right'
  },
});

GuidanceFragmentConsole.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  guidanceFragmentsSummaryData: state.guidanceFragmentSummaryData.guidanceFragmentsSummary.records,
  guidanceFragmentDataList: state.guidanceFragmentSummaryData.guidanceFragments,
  result: state.ui.result,
});

const connected = connect(mapStateToProps, {
  fetchGuidanceFragmentPolicyConfigSummaries,
  fetchGuidanceFragments,
})(AuthProvider(GuidanceFragmentConsole));

export default withStyles(styles)(connected);
