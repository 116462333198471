import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, CardContent } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link } from "@fluentui/react/lib/Link";
import { IconButton } from "@fluentui/react/lib/Button";
import SearchBar from "../../../components/shared/SearchBar";
import FilterCallout from "../../../components/filterv2/FilterCallout";

// eslint-disable-next-line react/react-in-jsx-scope
const FilterAndSearchPageHeader = (props) => {
  const { classes, filterOptions, selectedFilters, filterOnclickHandler, clearAllFilterHandler, removeSingleFilterHandler } = props;
  const [open, setOpen] = useState(false);
  return (
    <CardContent>
      <Box
        mt={1}
        container="true"
        justify-content="space-around"
        flexWrap="wrap"
        width={1}
      >
        <Grid item xs={12} sm container>
          <Grid item xs={6}>
            <button
              className={classes.filtersButton}
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
              id="FilterCallout"
            >
              Filters
            </button>
            <FilterCallout
              openDialog={open}
              toggleFilterCalloutHandler={() => setOpen(false)}
              filterType={"CHANGE_SET"}
              filterOptions={filterOptions}
              applyFilterSelectionsHandler={(filterSelections) => filterOnclickHandler(filterSelections)}
              initialSelections={selectedFilters}
              showSelectedItems={false}
            />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <SearchBar
              handleSearch={() => {}}
              placeholderText="Enter Search Term"
            />
          </Grid>
        </Grid>
        <hr className={classes.sepLine} />
        <Grid item xs={12} sm container>
          {selectedFilters && selectedFilters.length > 0 && (
            <>
              <Grid item xs={10} container>
                {selectedFilters?.map((filter, index1) => {
                  return filter.items.map((item, index2) => (
                  <span
                    key={`selectedFilters${index1}${index2}`}
                    className={classes.filterButtonLabel}
                  >
                    <span>{item.value}</span>
                    <IconButton
                      iconProps={{ iconName: "Clear" }}
                      className={classes.buttonClearIcon}
                      styles={{
                        icon: {
                          fontSize: "10px",
                          paddingLeft: "12px",
                          margin: "0px",
                        },
                        rootHovered: { backgroundColor: "#EAEAEA" },
                      }}
                     onClick={() => removeSingleFilterHandler(index1, index2)}
                    />
                  </span>
                ))})
              }
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <Link
                  className={classes.clearAllFiltersLink}
                  onClick={(e) => clearAllFilterHandler()}
                >
                  Clear all filters
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </CardContent>
  );
};

const styles = (theme) => ({
  rootBackground: {
    height: "100%",
    backgroundColor: "white",
    width: "100%",
    margin: "1rem",
    marginLeft: "5rem",
    marginRight: "5rem",
  },
  root: {
    flexGrow: 1,
  },
  cardHeaderSection: {
    padding: "16px 0 0 0",
  },
  cardHeader: {
    fontSize: "27px",
    fontFamily: "PharmaCond",
    paddingLeft: "1rem",
    color: "#323130",
    fontWeight: "700",
    titleTypographyProps: { variant: "h1" },
    title: {
      titleTypographyProps: { variant: "h1" },
      fontSize: "12px",
    },
  },
  sepLine: {
    margin: "16px 0",
    borderBottom: "solid 1px #bdbdbd",
    borderTop: "solid 0px #dcdcdc",
    borderLeft: "solid 0px #dcdcdc",
    borderRight: "solid 0px #dcdcdc",
    height: "0px",
  },
  filterButtonLabel: {
    textTransform: "capitalize",
    backgroundColor: "#EAEAEA",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "auto",
    marginRight: "1rem",
    padding: "5px 19px 7px 19px",
    borderRadius: "2px",
    border: "solid 1px #8a8886",
  },
  filtersButton: {
    height: "32px",
    padding: "5px 19px",
    backgroundColor: "#fff",
    color: "#323130",
    borderRadius: "2px",
    border: "solid 1px #8a8886",
    cursor: "pointer",
  },
  dialog: {
    position: "absolute",
    left: 10,
    top: 50,
  },
  buttonClearIcon: {
    color: "#605e5c",
    width: "12px",
    height: "12px",
  },
  clearAllFiltersLink: {
    whiteSpace: "nowrap",
    textDecoration: "underline",
    color: "#323130",
  },
});

FilterAndSearchPageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  filterOptions: PropTypes.object,
  selectedFilters: PropTypes.array, 
  filterOnclickHandler: PropTypes.func.isRequired,
  clearAllFilterHandler: PropTypes.func.isRequired,
  removeSingleFilterHandler: PropTypes.func.isRequired,
};

FilterAndSearchPageHeader.defaultProps = {
  filterOptions: {},
  selectedFilters: [],
};

export default withStyles(styles)(FilterAndSearchPageHeader);
