import {
  API_REQUEST_DISPATCHED,
  API_REQUEST_COMPLETED,
  UI_SHOW_MESSAGE_SUCCESS,
  UI_MESSAGE_CLEAR, UI_SHOW_MESSAGE_ERROR
} from '../../constants/ActionTypes';
import { ERROR, SUCCESS } from '../../constants/Constants';

const INITIAL_STATE = {
  isFetching: false,
  messageOpen: false,
  message: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_REQUEST_DISPATCHED:
      return {...state, isFetching: true};
    case API_REQUEST_COMPLETED:
      return {...state, isFetching: false};
    case UI_SHOW_MESSAGE_SUCCESS:
      return {
        ...state,
        uiMessageOpen: true,
        message: action.message,
        result: SUCCESS
      };
    case UI_SHOW_MESSAGE_ERROR:
      return {
        ...state,
        uiMessageOpen: true,
        message: action.message,
        result: ERROR
      };
    case UI_MESSAGE_CLEAR:
      return {
        ...state,
        uiMessageOpen: false,
        message: null
      };
    default:
      return state;
  }
};
