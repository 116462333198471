import api from '../../api/IntelligentAgentServiceApi';
import { signOut } from '../../components/auth/AuthUtils';
import { FETCH_CHANGE_SET, UPDATE_CHANGE_SET, REMOVE_CHANGE_SET, PUBLISH_CHANGE_SET, DOWNLOAD_SCRIPT_CHANGE_SET } from '../../constants/ActionTypes';
import fetchChangeSetList from './AdminChangeSetManagementActions';

export const fetchChangeSetById = (changeSetId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/ccr/change-set/${changeSetId}`);
    dispatch({ type: FETCH_CHANGE_SET, payload: response.data });
    dispatch(fetchChangeSetList());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const updateChangeSet = (changeSetId, changeSet) => async (dispatch) => {
  try {
    const response = await api.put(`/admin/ccr/change-set/${changeSetId}`, changeSet);
    dispatch({ type: UPDATE_CHANGE_SET, payload: response.data });
    dispatch(fetchChangeSetById(changeSetId));
    dispatch(fetchChangeSetList());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const removeChangeSet = (changeSetId) => async (dispatch) => {
  try {
    const response = await api.delete(`/admin/ccr/change-set/${changeSetId}`);
    dispatch({ type: REMOVE_CHANGE_SET, payload: response.data });
    dispatch(fetchChangeSetList());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const publishChangeSet = (changeSetId) => async (dispatch) => {
  try {
    const changeSet = {};
    const response = await api.post(`/admin/ccr/change-set/${changeSetId}/publish`, changeSet);
    dispatch({ type: PUBLISH_CHANGE_SET, payload: response.data });
    dispatch(fetchChangeSetById(changeSetId));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const downloadChangeSet = (changeSetId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/ccr/change-set/${changeSetId}/download-script`);
    dispatch({ type: DOWNLOAD_SCRIPT_CHANGE_SET, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const downloadChangeSetSync = async (changeSetId) => {
    const result = await api.get(`/admin/ccr/change-set/${changeSetId}/download-script`);
    return result;
};
