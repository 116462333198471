import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

import '../../components/style.css';

const REFDATA_NOTIFICATION_CATEGORY = [
    { text: 'Approval Notification', key: 'fb1f8295-452b-459c-a788-f9be10de26d7' },
    { text: 'Change Control Notification', key: '8cb6e16a-cde2-41eb-ae2b-d8ae1b162911' },
    { text: 'Checkpoint Notification', key: '0fd7ca4c-2c23-4022-a32f-c6bde704f00f' },
    { text: 'Decision Point Notification', key: 'ec41de52-610c-465c-bdfc-bfbbb408a61f' },
    { text: 'Entitlement Notification', key: 'bc2ca397-f085-4e14-a580-3b1a9236c3a5' },
    { text: 'General Notification', key: '8d99bf9d-d36a-463c-b983-af88357d867a' },
    { text: 'Integration Notification', key: '81ee5c90-55b8-4514-9c4e-3742ff6a3a42' },
    { text: 'Project Notification', key: 'cf11b04e-6ade-40b3-8da6-e5a363e3b560' },
    { text: 'Subject Matter Notification', key: 'db66567e-c0ce-4043-8b28-3a65320bed48' },
    { text: 'Task Notification', key: '0757a0e1-394b-4348-944b-d2927e989133' },
    { text: 'Users Notification', key: '3093870f-e9f6-428d-bf83-636836c2dfa6' },
  ];

class NotificationLibraryCard extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            category: props.category,
            totalNumberOfSchemes: props.totalNumberOfSchemes,
            classes: PropTypes.object.isRequired,
        };
    }

    render () {
        const { classes } = this.props;

        return (
        <Box m={1}>
        <Card className={classes.card} variant='outlined' style={{ width:'412px' }}>
            <CardActionArea component="div">
                <CardHeader className={this.props.classes.cardHeaderSection}
                            title={<Typography className={classes.cardHeader}>
                                {this.getNotificationCategory(this.state.category)}
                            </Typography>}/>
                <CardContent>
                <Link className={classes.editButton} to={`/notificationLibraryConsole/policybuilder/${this.state.category}`} style={{ textDecoration: 'none' }}>
                Edit Master
                </Link>
                    <hr className={classes.sepLine}/>
                    <div className={classes.subtitle}>NOTIFICATION MASTER SUMMARY</div>
                    <div className={classes.fieldRow}>
                    Updated: 10 Nov 2020
                    </div>
                    <div className={classes.fieldRow}>
                        <span className={classes.fieldName}>{this.state.totalNumberOfSchemes} Notification Masters</span>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
        </Box>
        );
    }

    getNotificationCategory (id) {
        let res = REFDATA_NOTIFICATION_CATEGORY.filter(v => v.key === id);
        if (res.length > 0) {
          return res[0].text;
        } else {
          return "UNKNOWN***";
        }
      }
}

const styles = (theme) => ({
  cardHeader: {
    fontFamily:'PharmaCond',
    fontSize: '24px',
    color:'#323130',
    fontWeight: '600',
    },
   cardHeaderSection: {
       padding: '16px 0 0 16px',
   },
  card: {
    borderColor: blue[200],
    width: 200,
    height: 320,
    color: '#000',
    margin: '8px 16px'
  },
    subtitle: {
        padding: '16px 0',
        fontWeight: '600',
        fontFamily: 'SegoeUI'
    },

    fieldRow: {
        padding: '8px 0',
    },

    fieldName: {
        fontWeight: '600'
    },

    fieldRowDescription: {
        borderRadius: '2px',
        border: 'solid 1px #a19f9d',
        padding: '8px 0',
    },

    fieldDescription: {
    },

    listView: {
        padding: '18px 5',
        backgroundColor: '#DCDCDC',
    },
    sepLine: {
        margin: "16px 0",
        color: '#bdbdbd'
    },

    viewLink: {
        color: '#006ed2'

    },

    modal: {
        position: 'fixed',
        top:  '0',
        right: '0',
        bottom: '0',
        left: '65%',
        padding: '16px 32px',
        boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
        backgroundColor: '#fff',
        zIndex:'1000',
        overflow: 'auto'
  },

  viewPolicyLibraryButton:{
    backgroundColor: '#0078d4',
    border: 'none',
    color: '#fff',
    padding: "5px 12px 7px 16px",
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    borderRadius: '2px'
  },

  closeButton:{
      backgroundColor: "#fff",
      border:'0',
      color:'#605e5c',
      fontSize:'18px',
      padding:'0 16px',
      marginRight:'16px',
      cursor:'pointer'
  },

  popUpTitle: {
      fontSize: '20px',
      fontWeight: '600',
      fontStretch: 'normal',
      fontStyle: 'normal',
      color: '#323130',
  },

  viewMoreLink:{
      border: '0',
      backgroundColor:'#fff',
      color:'#006ed2',
      fontSize: '14px'
  },

  editButton: {
    backgroundColor: '#0078d4',
    border: 'none',
    color: '#fff',
    padding: "5px 12px 7px 16px",
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    borderRadius:'2'
  },

});

NotificationLibraryCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationLibraryCard);
