import _ from 'lodash';
import {
  FETCH_INTELLIGENT_AGENTS,
  FETCH_INTELLIGENT_AGENT,
  FETCH_INTELLIGENT_AGENTS_EVENTS,
  FETCH_INTELLIGENT_AGENT_EVENTS_BY_IA
} from '../../constants/ActionTypes';


const INITIAL_STATE = {
  intelligentAgents: {},
  intelligentAgentsEvents: {},
  intelligentAgentEvents: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INTELLIGENT_AGENTS:
      return {
 ...state,
intelligentAgents: { ...state.intelligentAgents, ..._.mapKeys(action.payload, 'id') },
        error: null
};
    case FETCH_INTELLIGENT_AGENT:
      return {
 ...state,
intelligentAgents: { ...state.intelligentAgents, [action.payload.id]: action.payload },
        error: null
};
    case FETCH_INTELLIGENT_AGENTS_EVENTS:
      return {
 ...state,
intelligentAgentsEvents: { ...state.intelligentAgentsEvents, ..._.mapKeys(action.payload, 'id') },
        error: null
};
    case FETCH_INTELLIGENT_AGENT_EVENTS_BY_IA:
      return {
 ...state,
intelligentAgentEvents: { ..._.mapKeys(action.payload, 'id') },
        error: null
};
    default:
      return state;
  }
};
