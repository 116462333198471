import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import UIReducer from './UIReducer';
import IntelligentAgentReducer from './IntelligentAgentReducer';
import PEPReducer from './PEPReducer';
import PDPReducer from './PDPReducer';
import PIPReducer from './PIPReducer';
import ReferenceDataReducer from './ReferenceDataReducer';
import CheckPointMasterReducer from './CheckPointMasterReducer';
import DecisionPointMasterReducer from './DecisionPointMasterReducer';
import TaskMasterReducer from './TaskMasterReducer';
import TaskLibraryBuilderReducer from './TaskLibraryBuilderReducer';
import PolicyLibraryMasterReducer from './PolicyLibraryMasterReducer';
import PolicyLibraryBuilderReducer from './PolicyLibraryBuilderReducer';
import SharedMasterReducer from './SharedMasterReducer';
import ProjectSummariesReducer from './ProjectSummariesReducer';
import NotificationMastersReducer from './NotificationMastersReducer';
import EligibleNotificationMastersReducer from './EligibleNotificationMastersReducer';
import PolicyRuleReducer from './PolicyRuleReducer';
import AdminChangeMasterReducer from './AdminChangeMasterReducer';
import ChangeSetManagementReducer from './AdminChangeSetManagementReducer';
import HelpTextReducer from './HelpTextReducer';
import AdminChangeSetBuilderReducer from './AdminChangeSetBuilderReducer';
import GuidanceFragmentSummaryReducer from './GuidanceFragmentSummaryReducer';
import MappingTablesReducer from './MappingTablesReducer';
import GuidanceFragmentBuilderReducer from './GuidanceFragmentBuilderReducer';

export default combineReducers({
  form: FormReducer,
  ui: UIReducer,
  intelligentAgents: IntelligentAgentReducer,
  peps: PEPReducer,
  pdps: PDPReducer,
  pips: PIPReducer,
  refData: ReferenceDataReducer,
  checkpointMasterData: CheckPointMasterReducer,
  decisionpointMasterData: DecisionPointMasterReducer,
  taskMastersData: TaskMasterReducer,
  taskMastersSummaryData: TaskMasterReducer,
  taskLibraryBuilderData: TaskLibraryBuilderReducer,
  policyLibraryMasterData: PolicyLibraryMasterReducer,
  policyLibraryBuilderData: PolicyLibraryBuilderReducer,
  sharedMasterData: SharedMasterReducer,
  projectSummariesData: ProjectSummariesReducer,
  notificationMastersData: NotificationMastersReducer,
  eligibleNotificationMastersData: EligibleNotificationMastersReducer,
  policyRuleData: PolicyRuleReducer,
  changeMastersData: AdminChangeMasterReducer,
  changeItemData: AdminChangeMasterReducer,
  changeSets: AdminChangeMasterReducer,
  changSetsSummariesData: ChangeSetManagementReducer,
  helpTextData: HelpTextReducer,
  changeSetData: AdminChangeSetBuilderReducer,
  guidanceFragmentSummaryData: GuidanceFragmentSummaryReducer,
  mappingTables: MappingTablesReducer,
  guidanceFragmentBuilderData: GuidanceFragmentBuilderReducer,
  changeMasterDataFilter: AdminChangeMasterReducer,
  changeMastersWithFiltersData: AdminChangeMasterReducer,
  changeMastersSelectedFiltersData: AdminChangeMasterReducer,
});
