import { FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG, FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG_FAILED } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  guidanceFragmentType: {},
  policySchemeConfigs: [],
  error: null,
  isloading: false,
  linkedPolicyLibraries: [],
  linkedResourceTypes: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG:
      return {
        ...state,
        guidanceFragmentType: action.payload.guidanceFragmentType,
        policySchemeConfigs: action.payload.policySchemeConfigurations,
        linkedPolicyLibraries: action.payload.policySchemeConfigurations.linkedPolicyLibraries,
        linkedResourceTypes: action.payload.policySchemeConfigurations.linkedResourceTypes,
        isloading: false
      };
    case FETCH_SINGLE_GUIDANCE_FRAGMENT_POLICY_CONFIG_FAILED:
      return { ...state, isloading: true };
    default:
      return state;
  }
};
