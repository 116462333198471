import api from '../../api/IntelligentAgentServiceApi';
import { tasklibrary } from './data/tasklibrary';

import {
  FETCH_TASK_MASTERS,
  FETCH_TASK_MASTERS_FILTER,
  FETCH_TASK_MASTERS_SUMMARY,
  UI_SHOW_MESSAGE_ERROR,
  UI_SHOW_MESSAGE_SUCCESS,
  FETCH_DECISION_POINT_MASTERS,
  FETCH_TASK_LIBRARY_MASTERS,
  FETCH_TASK_BUILDER_MASTERS,
  FETCH_TASK_BUILDER_MASTERS_FAILED,
  FETCH_TASK_MASTER_DEPENDENCIES,
  FETCH_TASK_MASTERS_BY_SEARCHSTR,
  FETCH_TASK_MASTERS_BY_CODES,
  FETCH_TASK_MASTERS_BY_CODES_RO
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchTaskMasters = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/task-masters?resourceState=ACTIVE&resourceState=INACTIVE&sort=code&direction=asc');

    dispatch({ type: FETCH_TASK_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskMastersWithFilter = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/task-masters?resourceState=ACTIVE&resourceState=INACTIVE&sort=code&direction=asc&count=50');
    dispatch({ type: FETCH_TASK_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskMastersWithFilterAndPageRef = (alreadyFetched, pageNo) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/task-masters?resourceState=ACTIVE&resourceState=INACTIVE&sort=code&direction=asc&count=50&pageNo=${pageNo}`);
    const results = alreadyFetched.concat(response.data.masters);
    const m = { masters: results, totalRecords: response.data.totalRecords, totalInPage: response.data.totalInPage };
    dispatch({ type: FETCH_TASK_MASTERS, payload: m });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskMastersBySearchStr = (searchStr) => async (dispatch) => {
  try {
    let pUrl = "/admin/task-masters?resourceState=ACTIVE&resourceState=INACTIVE&sort=code&direction=asc";
    if (searchStr && searchStr.toString().trim() !== "") {
        pUrl = `${pUrl}&searchString=${searchStr}`;
    }
    const response = await api.get(pUrl);

    dispatch({ type: FETCH_TASK_MASTERS_BY_SEARCHSTR, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskMastersByCodes = (codeInList, readOnly) => async (dispatch) => {
  try {
    let pUrl = "/admin/task-masters?resourceState=ACTIVE&resourceState=INACTIVE&sort=code&direction=asc";
    if (codeInList && codeInList.length > 0) {
      let tmp = "";
      codeInList.forEach((c) => {
        tmp = `${tmp}&codesIn=${c}`;
      });
      pUrl = `${pUrl}${tmp}`;
    }
    const response = await api.get(pUrl);

    if (readOnly) {
      dispatch({ type: FETCH_TASK_MASTERS_BY_CODES_RO, payload: response.data });
    } else {
      dispatch({ type: FETCH_TASK_MASTERS_BY_CODES, payload: response.data });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskMastersSummary = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/task/task-master-policy-configuration-summaries?sort=code&direction=asc');

    dispatch({ type: FETCH_TASK_MASTERS_SUMMARY, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskLibraryMasters = () => async (dispatch) => {
  try {
    const response = tasklibrary;
    dispatch({ type: FETCH_TASK_LIBRARY_MASTERS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchTaskMastersFiltered = (param) => async (dispatch) => {
  try {
    let parameters = '';
    let i;
    for (i = 0; i < param.length; i += 1) {
      if (param[i].code.substring(0, 2) === 'cp') {
        parameters = `${parameters}checkpointMasterId=${param[i].id}`;
      }
      if (param[i].code.substring(0, 2) === 'dp') {
        parameters = `${parameters}decisionPointMasterId=${param[i].id}`;
      }
      if (param[i].code.substring(0, 2) === 'rs') {
        parameters = `${parameters}resourceState=${param[i].id}`;
      }
      if (i !== param.length - 1) parameters = `${parameters}&`;
    }

    if (param.length > 0) {
      parameters = `${parameters}&sort=code&direction=asc&count=50`;
    } else {
      parameters = `sort=code&direction=asc&count=50`;
    }

    const response = await api.get(`/admin/task-masters?${parameters}`);
    dispatch({ type: FETCH_TASK_MASTERS_FILTER, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchDecisionPointMasters = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/decision-point-masters');
    dispatch({ type: FETCH_DECISION_POINT_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const updateTaskMaster = (taskMaster) => async (dispatch) => {
  try {
    const apiObject = {
      code: taskMaster.code,
      description: taskMaster.description,
      id: taskMaster.id,
      label: taskMaster.label,
      name: taskMaster.name,
      priority: taskMaster.priority,
      requirement: taskMaster.requirement,
      status: taskMaster.status,
    };

    const url = `admin/task-masters/${taskMaster.id}`;
    await api.put(url, apiObject);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Task master updated successfully' });
    dispatch(fetchTaskMastersWithFilter());
  } catch (error) {
     if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Task master updated failure' });
    }
  }
};

export const fetchTaskBuilderMasters = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/task/task-master-policy-configuration/${id}`);
    dispatch({ type: FETCH_TASK_BUILDER_MASTERS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_TASK_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const createTaskMaster = (taskItem) => async (dispatch) => {
  try {
    const url = `/admin/task-masters`;
    await api.post(url, taskItem);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'You have successfully created a new Task' });
    dispatch(fetchTaskMasters());
  } catch (error) {
     if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Task creation failure' });
    }
  }
};

export const postUserActionToTaskMasterResourceStatus = (action, taskMasterId) => async (dispatch) => {
  try {
    const apiObject = {
      action
    };

    const url = `admin/task-masters/${taskMasterId}/action`;
    const response = await api.post(url, apiObject);
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: response.data });
    dispatch(fetchTaskMasters());
  } catch (error) {
     if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Task master updated failure' });
    }
  }
};

export const fetchTaskMasterDependencies = (taskMasterId) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/task-masters/${taskMasterId}/dependencies`);
    dispatch({ type: FETCH_TASK_MASTER_DEPENDENCIES, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};
