import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import {
 Box, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Card, CardContent
} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { initializeIcons, IconButton } from '@fluentui/react';
import { useSelector, useDispatch } from "react-redux";
import RulePicker from '../../../rules/rulePicker/RulePicker';
import RuleConfigValue from '../../../rules/ruleConfigValue/RuleConfigValue';
import { fetchReferenceData } from '../../../../state/actions/ReferenceDataActions';
import getReadOnlyFlagPerEnv from  '../../../../util/EnvUtil';
import { removeRuleFromCompositeRule } from '../../../../state/actions/PolicyRuleActions';
import CompositeRuleDetails from '../../../../pages/policyLibraryMaster/compositeRuleDetails/CompositeRuleDetails';
import { ActionLiteralType } from '../../../../constants/ActionTypeObj';

const GeneralPolicySchemeConfigWrapper = ({
 classes, policySchemeConfig, resourceCode, refreshCompositeRules, literalType
}) => {
  const isReadOnly = getReadOnlyFlagPerEnv();
  const [isRulePickerOpen, setIsRulePickerOpen] = useState(false);
  const [actionForRulePicker, setActionForRulePicker] = useState(null);
  const [selCompositePolicy, setSelCompositePolicy] = useState(null);
  const [selExistingRuleIds, setSelExistingRuleIds] = useState(null);
  const [selRuleForEdit, setSelRuleForEdit] = useState(null);
  const [selRuleForDeletion, setSelRuleForDeletion] = useState(null);
  const [isCompositeRuleDetailsPanelOpen, setIsCompositeRuleDetailsPanelOpen] = useState(false);
  const [compositeRuleEditingMode, setCompositeRuleEditingMode] = useState("");
  const [selPolicyCompositeRule, setSelPolicyCompositeRule] = useState("");
  const [entitlementRules, setEntitlementRules] = useState({});

  const referenceData = useSelector(
    (state) => state.refData.referenceData
  );
  initializeIcons();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchReferenceData());
  }, [dispatch]);

  const checkIfThisRuleContainsResourceCode = (ruleCode) => ruleCode.includes(resourceCode);

  const toggleRulePickerPanelForAdd = (selCompositePolicyData) => {
    setSelCompositePolicy(selCompositePolicyData);
    setSelExistingRuleIds(selCompositePolicyData.policyRules.map((rule, _index) => rule.rule.id));
    setIsRulePickerOpen(true);
    setActionForRulePicker("add");
  };

  const toggleRulePickerPanelForEdit = (selCompositePolicyData, selRule) => {
    setSelCompositePolicy(selCompositePolicyData);
    setSelExistingRuleIds(selCompositePolicyData.policyRules.map((rule, _index) => rule.rule.id));
    setIsRulePickerOpen(true);
    setActionForRulePicker("edit");
    setSelRuleForEdit(selRule);
    setSelRuleForDeletion(selRule);
  };

  const dismissRulePickerPanelHandler = () => {
    refreshCompositeRules();
    setIsRulePickerOpen(false);
  };

  const handleDeleteRuleFromComposite = async () => {
    await dispatch(removeRuleFromCompositeRule(selCompositePolicy.id, { ruleId: selRuleForDeletion.rule.id }));
    refreshCompositeRules();
    setIsRulePickerOpen(false);
    setSelCompositePolicy(null);
    setSelRuleForDeletion(null);
  };

  const togglePolicySchemeCompositeRuleDetails = (mode, tempSelPolicyCompositeRule, tempEntitlementRules) => {
    setIsCompositeRuleDetailsPanelOpen(true);
    setCompositeRuleEditingMode(mode);
    setSelPolicyCompositeRule(tempSelPolicyCompositeRule);
    setEntitlementRules(tempEntitlementRules);
  };

  const hideCompositeRuleDetailsPanel = () => {
    refreshCompositeRules();
    setIsCompositeRuleDetailsPanelOpen(false);
  };

  return (
    <Box m={0} className={classes.mainContent}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.icon} />} aria-controls="panel1a-content" id="panel1a-header" className={classes.accordionHeader}>
          <Grid container className={classes.accSection}>
            <Grid item xs={12}>
              <Typography className={classes.accHeading}>{policySchemeConfig.policyScheme.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.accCode}>{policySchemeConfig.entitlementRules[0].policyEnforcementPoint.pepCode}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
        {policySchemeConfig.entitlementRules.map((entitle, _index2) => (
          <Box>
            <Card className={classes.policyHeading}>
              <CardContent className={classes.policyHeadingContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.accHeading2}>Decision: {entitle.policyDecisionPoint.pdpReference}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.accCode}>{entitle.policyDecisionPoint.pdpCode}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {entitle.compositePolicyRules.map((pol, _index3) => (
              <Card className={classes.carddetailbkg}>
                <CardContent className={classes.policyHeadingContent}>
                  <Grid container>
                    <Grid item xs={10}>
                      <div className={classes.ruleDetail}>
                        <Typography className={classes.compHeader}>Composite Rule: {pol.policyTitle}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 18, color: 'black', marginLeft: "0px" }  }}
                        styles={{ root: { color: 'black', paddingLeft: "0px !important", justifyContent: "flex-start" }, rootHovered: { backgroundColor: 'transparent', color: 'black' } }}
                        onClick={() => togglePolicySchemeCompositeRuleDetails('EDIT', pol, entitle)}
                      />
                    </Grid>
                  </Grid>
                  {pol.policyRules.map((rule, index) => (
                    <div className={classes.ruleBox}>
                      <Grid container>
                        <Grid item xs={10}>
                          <div className={classes.ruleDetail}>
                            <Typography className={classes.accHeading2}>Rule: {rule.rule.ruleTitle}</Typography>
                          </div>
                        </Grid>
                        <Grid item xs={2} >
                          { !checkIfThisRuleContainsResourceCode(rule.rule.ruleCode)
                            ? <SettingsRoundedIcon onClick={() => toggleRulePickerPanelForEdit(pol, rule)} className={classes.viewMoreLink} ></SettingsRoundedIcon>
                            : null
                          }
                        </Grid>
                        <RuleConfigValue mode="read" ruleConfigValues={rule.compositePolicyRuleInstanceConfigurations[0].configurationValue}
                          refdata={referenceData}
                          xxruleVariables={rule.rule.ruleVariables}
                        />
                      </Grid>
                      <br />
                    </div>
                  ))}
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className={classes.ruleDetail}>
                        <button className={classes.whiteBtn} onClick={() => toggleRulePickerPanelForAdd(pol)} ><AddCircleOutlineIcon className={classes.addLinkIcon}></AddCircleOutlineIcon>Add Rule</button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <button className={classes.whiteBtn} onClick={() => togglePolicySchemeCompositeRuleDetails('ADD', "", entitle)}><AddCircleOutlineIcon className={classes.addLinkIcon}></AddCircleOutlineIcon>Add Composite Rule</button>
                </Grid>
              </Grid>
          </Box>
        ))}
        </AccordionDetails>
      </Accordion>
      {isRulePickerOpen
      ? <RulePicker action={actionForRulePicker} compositePolicy={selCompositePolicy}
        existingRuleIds={selExistingRuleIds} selRuleForEdit={selRuleForEdit}
        isRulePickerPanelOpen={isRulePickerOpen} dismissRulePickerPanel={dismissRulePickerPanelHandler}
        literalType={literalType} refreshSelCompositeRules={refreshCompositeRules}
        refdata={referenceData} handleDeleteRuleFromComposite={handleDeleteRuleFromComposite} isReadOnly={isReadOnly}
      /> : null}
      {isCompositeRuleDetailsPanelOpen
      ? <CompositeRuleDetails
        isPolicySchemeCompositeRuleDetailsPanelOpen={isCompositeRuleDetailsPanelOpen}
        hidePolicySchemeCompositeRuleDetailsPanelHandler={hideCompositeRuleDetailsPanel}
        policyCompositeRule={entitlementRules.compositePolicyRules}
        selPolicyCompositeRule={selPolicyCompositeRule}
        policySchemeId={policySchemeConfig.policyScheme.id}
        pepId={entitlementRules.policyEnforcementPoint.id}
        pdpId={entitlementRules.policyDecisionPoint.id}
        entitlementRulesId={policySchemeConfig.entitlementRules[0].id}
        literalType={compositeRuleEditingMode === 'ADD' ? ActionLiteralType.COMPOSITERULEADD : ActionLiteralType.COMPOSITERULEEDIT }
        isReadOnly={isReadOnly} mode ={compositeRuleEditingMode}
      /> : null}
    </Box>
  );
};

const styles = () => ({
  mainContent: {
    marginBottom: "3px",
  },
  addLinkIcon: {
    border: '0',
    backgroundColor: null,
    color: 'black',
    fontSize: '8px',
    cursor: 'pointer',
    float: 'left',
    marginRight: '1rem'
  },
  viewMoreLink: {
    cursor: 'pointer',
    marginLeft: '2px',
  },
  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '10px 0 10px 0',
    color: '#323130',
    paddingRight: '1rem'
  },
  accordionHeader: {
    backgroundColor: '#99c5ed',
    borderRadius: "2px",
    color: 'black',
    flexDirection: 'row-reverse',
    height: '84px',
    paddingLeft: "0px",
  },
  icon: {
    fontSize: '20px !important',
    fontWeight: "normal !important"
  },
  accHeading: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: '#323130'
  },
  policyTitle: {
    fontSize: '1rem',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
  },
  policyHeading: {
    width: 'auto',
    marginTop: '16px',
    borderRadius: '2px',
    backgroundColor: '#cce2f6',
  },
  policyHeadingContent: {
    paddingLeft: "24px",
    paddingTop: "20px",
  },
  compositeRuleContent: {
    marginBottom: "100px",
  },
  accHeading2: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
  },
  accRuleCodeSection: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#555",
  },
  carddetailbkg: {
    backgroundColor: '#f3f2f1',
    color: 'black',
    marginTop: '16px',
    marginBottom: "16px",
  },
  ruleDetail: {
    marginLeft: '0px'
  },
  ruleBox: {
    marginTop: '2rem',
    borderBottom: 'solid 1px #bdbdbd',
    marginBottom: '2rem'
  },
  compHeader: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
  },
  accSection: {
    marginLeft: "10px"
  },
  accCode: {
    border: '0',
    backgroundColor: null,
    color: '#555',
    fontSize: '14px',
  },
});

export default withStyles(styles)(GeneralPolicySchemeConfigWrapper);
