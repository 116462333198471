import { Stack, IconButton } from "@fluentui/react";
import { withStyles } from "@material-ui/styles";
import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toggle } from '@fluentui/react/lib/Toggle';
import envReadOnlyFlagContext from "../../../context/adminContext";
import {
  updateReferenceDataValue
 } from "../../../state/actions/ReferenceDataActions";

const RefDataTypeValue = ({ item, classes }) => {
  const context = useContext(envReadOnlyFlagContext);
  const { isReadOnly } = context;
  const [isEditing, setIsEditing] = useState(false);
  const [refDataValue, setRefDataValue] = useState("");
  const [refDataSortOrder, setRefDataSortOrder] = useState(null);
  const [refDataEnabled, setRefDataEnabled] = useState("");

  useEffect(() => {
    setRefDataValue(item.value);
    setRefDataSortOrder(item.sortOrder ? parseInt(item.sortOrder) : null);
    setRefDataEnabled(item.enabled);
  }, [item.enabled, item.id, item.sortOrder, item.value]);

  const dispatch = useDispatch();

  const selectedRefDataType = useSelector(
    (state) => state.refData.selectedRefDataType
  );

  const iconStyle = {
     fontSize: 23, color: "#555", padding: '16px 0 0 16px'
  };

  const editingAndSavingRefDataValueHandler = () => {
    if (isEditing) {
      const newItem = item;
      newItem.value = refDataValue;
      newItem.enabled = refDataEnabled;
      if (refDataSortOrder) {
        newItem.sortOrder = refDataSortOrder;
      }
      dispatch(updateReferenceDataValue(newItem, selectedRefDataType));
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const updateRefDataEnabling = (checked) => {
    setRefDataEnabled(checked);
  };


  return (
    <>
      <Stack key={item.id}>
        <Stack
          horizontal
          horizontalAlign="start"
          className={classes.stackBody}
          verticalAlign="center"
        >
          <Stack.Item className={classes.valueSection}>
            { isEditing
              ? <input onChange={(e) => setRefDataValue(e.target.value)}
                value={refDataValue}
                className={classes.refDataInput}
              />
              : item.value
            }
          </Stack.Item>
          <Stack.Item className={classes.sortOrderSection}>
            { isEditing
              ? <input onChange={(e) => setRefDataSortOrder(parseInt(e.target.value))}
                value={refDataSortOrder}
                className={classes.refDataInput}
                type="number"
              />
              : item.sortOrder || "N/A"
            }
          </Stack.Item>
          <Stack className={classes.valueAvalabilitySection} horizontal horizontalAlign="start" tokens={ { childrenGap: 6 } } verticalAlign="center">
            <Stack.Item className={isEditing ? "" : classes.nonEditingFont}>
              Inactive
            </Stack.Item>
            <Stack.Item>
              <Toggle className={classes.toggleSection} checked={refDataEnabled} disabled={!isEditing} onChange={(event, checked) => updateRefDataEnabling(checked)}/>
            </Stack.Item>
            <Stack.Item className={isEditing ? "" : classes.nonEditingFont}>
              Active
            </Stack.Item>
          </Stack>
          <Stack className={classes.editSection} horizontal>
            <Stack.Item>
              <IconButton iconProps={{ iconName: 'Edit', style: iconStyle }}
               onClick={editingAndSavingRefDataValueHandler} disabled={isReadOnly}/>
            </Stack.Item>
           </Stack>
        </Stack>
        <hr className={classes.dividerLine} />
      </Stack>
    </>
  );
};

const styles = () => ({
  stackBody: {
    fontWeight: "normal",
    padding: "16px 0px",
  },
  valueSection: {
    width: "35%",
  },
  sortOrderSection: {
    width: "20%",
  },
  valueAvalabilitySection: {
    width: "35%",
  },
  editSection: {
    borderLeft: "solid 2px #bdbdbd",
    height: "56px",
    width: "10%",
  },
  dividerLine: {
    borderBottom: "solid 1px #dcdcdc",
    borderTop: "solid 0px #dcdcdc",
    borderLeft: "solid 0px #dcdcdc",
    borderRight: "solid 0px #dcdcdc",
    height: "0px",
  },
  toggleSection: {
    paddingTop: "10px",
  },
  nonEditingFont: {
    color: "#a19f9d",
  },
  refDataInput: {
    height: "20px",
    width: "60%",
    padding: " 5px 8px 7px",
    borderRadius: "2px",
    border: "solid 1px #a19f9d",
    backgroundColor: "#fff",
  }
});

export default withStyles(styles)(RefDataTypeValue);
