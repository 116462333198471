import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import AuthProvider from '../../../components/auth/AuthProvider';
import GuidanceFragmentItemCard from './GuidanceFragmentItemCard';

class GuidanceFragmentList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return this.props.guidanceFragmentsList?.map((tM, index) => (
      <Box display="flex">
        <GuidanceFragmentItemCard guidance={tM} isReadOnly={this.props.isReadOnly} guidanceFragmentsList={this.props.guidanceFragmentsList}></GuidanceFragmentItemCard>
      </Box>
    ));
  }
}

const styles = (theme) => ({
});

GuidanceFragmentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
})(AuthProvider(GuidanceFragmentList));

export default withStyles(styles)(connected);
