/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// eslint-disable-next-line import/no-duplicates
import { Box, Divider } from "@material-ui/core";
import "../../../components/style.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { initializeIcons, IconButton } from "@fluentui/react";

import { fetchAllTableMappings } from "../../../state/actions/MappingTablesActions";
import { fetchNotificationMasters } from "../../../state/actions/NotificationMastersAction";
import {
  fetchReferenceData,
  fetchReferenceDataTypes,
  referenceDataTypeSelected,
} from "../../../state/actions/ReferenceDataActions";
// eslint-disable-next-line import/no-useless-path-segments
import "./../reference.css";
import AuthProvider from "../../../components/auth/AuthProvider";
import TableDetails from "./TableDetails/TableDetails";
import TableMappingDataProcessing from "./TableMappingDataProcessing";
import FilterOptionTypeLiteral from "../../../constants/FilterLiterals";
import Filterv2 from "../../../components/filterv2/Filterv2";
import envReadOnlyFlagContext from "../../../context/adminContext";

let isReadOnly = false;

const queryString = require("query-string");

class TableMappingBuilder extends React.Component {
  static contextType = envReadOnlyFlagContext;

  // eslint-disable-next-line space-before-function-paren
  constructor(props) {
    super(props);

    this.state = {
      filterLiterals: "",
      filterSelections: [],
      filterOptionsKey: uuid(),
      selectedIndex: 0,
      selectedPipId: "",
      hideDialog: true,
      disableAddValueButton: true,
      pipsCategories: [],
      selectedPip: null,
      isTableDetailsPanelOpen: false,
      reducedPips: [],
      maxDisplayOrderKey: 0,
      categories: [],
      isLoading: false,
    };
    initializeIcons();
  }

  // eslint-disable-next-line space-before-function-paren
  async componentDidMount() {
    document.title = "Table Mappings Builder";
    const queryParams = queryString.parse(this.props.location.search);
    const { selectedPipId } = queryParams;

    const filterLiterals = FilterOptionTypeLiteral.filter(
      (value) => value.filterType === "TABLE_MAPPINGS"
    )[0];

    await this.props.fetchReferenceData();
    await this.props.fetchReferenceDataTypes();
    await this.props.fetchNotificationMasters();
    this.getTableList(selectedPipId);

    this.setState({ filterLiterals, selectedPipId });
  }

  getTableList = async (selectedPipId) => {
    await this.props.fetchAllTableMappings();

    const selectedTable = this.props.tables.filter(
      (item) => item.id === selectedPipId
    );
    const categories = this.getReferenceValueForCategory();

    this.setState({
      selectedPip: selectedTable ? selectedTable[0] : this.props.tables[0],
      categories,
    });
  };

  // eslint-disable-next-line space-before-function-paren
  reduceByApplyingFilter() {
    const { filterSelections } = this.state;
    let items;

    if (
      filterSelections.length === 0 ||
      filterSelections[0].items.length === 0
    ) {
      items = this.props.tables ? this.props.tables : [];
    } else {
      const compareItemsExists = (categoryId) => filterSelections[0].items.some((ch) => ch.key === categoryId);
      // eslint-disable-next-line no-nested-ternary
      const itemList = this.props.tables;
      items = itemList.filter((item) => compareItemsExists(item.categoryId));
    }
    return items;
  }

  getReferenceValueForAttrValue = (attrValue) => {
    const filterRef = this.props.refdata.filter((ref) => ref.id === attrValue);

    if (filterRef && filterRef.length > 0) return filterRef[0].value;

    return "";
  };

  getReferenceValueForCategory = () => {
    const categories = [];
    const filterRef = this.props.refdata.filter(
      (ref) => ref.type.toUpperCase() === "Admin_Mapping_Tables".toUpperCase()
    );

    if (filterRef && filterRef.length > 0) {
      filterRef.forEach((ref) => {
        categories.push({ text: ref.value.replaceAll("_", " "), key: ref.id });
      });

      return categories;
    }

    return [];
  };

  /* Filter */

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState({ filterSelections });
  };

  // eslint-disable-next-line space-before-function-paren
  createFilterOptions() {
    const selectionOptions = [];
    const options = this.getReferenceValueForCategory();

    options.forEach((item) => {
      selectionOptions.push({
        key: item.key,
        value: item.text,
      });
    });

    const filterEntries = {
      contentFilters: [
        {
          // eslint-disable-next-line no-nested-ternary
          filterType: "Mapping Tables",
          entries: [...selectionOptions],
        },
      ],
    };
    return filterEntries;
  }

  toggleTableDetails = () => {
    const isTableDetailsPanelOpen = !this.state.isTableDetailsPanelOpen;
    this.setState({ isTableDetailsPanelOpen });
  };

  hideDetailsPanelHandler = () => {
    const isTableDetailsPanelOpen = false;
    this.setState({ isTableDetailsPanelOpen });
  };

  reducePips = (pips, selectedPip) => {
    if (!pips || pips.length === 0) {
      return [];
    }

    /* eslint-disable no-param-reassign */
    // eslint-disable-next-line func-names
    const groupBy = function (xs, key) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    return groupBy(
      selectedPip.policyInformationPointAttributeValues,
      "displayRowOrder"
    );
  };

  listMappingTableLP = () => {
    const mappingList = this.reduceByApplyingFilter();

    return mappingList.map((tM, index) => {
      const isSelected = this.state.selectedPip
        ? this.state.selectedPip.id === tM.id
        : 0;
      return (
        <ListItem
          key={`index-${tM.id}`}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(event, index, tM)}
          className={"tmItem"}
        >
          <ListItemText
            key={`index-${tM.code}text`}
            primary={
              <Typography type="body2" className={"tmItemText"}>
                {tM.configValue}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
      );
    });
  };

  handleListItemClickLP = async (event, index, tM) => {
    this.cleanUpValues();
    this.setState({
      selectedPip: tM,
      selectedIndex: index,
      isAddFreeText: false,
    });
  };

  cleanUpValues = () => {
    this.setState({
      newPipAttrValues: [],
      disableAddValueButton: true,
      newValueControlsCount: 0,
    });
  };

  getCategoryValue = (categoryId) => {
    const filterRef = this.props.refdata.filter((ref) => ref.id === categoryId);

    if (filterRef && filterRef.length > 0) return filterRef[0].value.replaceAll("_", " ");

    return "";
  };

  // eslint-disable-next-line space-before-function-paren
  render() {
    const {
      selectedPip,
      isTableDetailsPanelOpen,
      categories,
      filterOptionsKey,
      filterSelections
    } = this.state;

    const { filterType, subTitle } = this.state.filterLiterals;

    isReadOnly = this.context.isReadOnly;

    return (
      <Box display="flex">
        <Box>
          <Card variant="outlined" className={`${"listCard"} ${"listTable"}`}>
            <CardContent>
              <Typography variant="subtitle1" className={"tbllistHeader"}>
                Mapping Table Type
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{subTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filterv2
                    key={filterOptionsKey}
                    filterType={filterType}
                    filterOptions={this.createFilterOptions()}
                    applyFilterSelectionsHandler={
                      this.applyFilterSelectionsHandler
                    }
                    initialSelections={filterSelections}
                    showSelectedItems={true}
                  ></Filterv2>
                </AccordionDetails>
              </Accordion>
              <div style={{ marginTop: "1rem" }}>
                {selectedPip ? this.listMappingTableLP() : ""}
              </div>
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={4} style={{ maxWidth: "1200px" }}>
          <Card>
            <CardContent style={{ padding: "24px 32px" }}>
              <div className={"headerSection"}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <div className={"tmHeader"}>Table Mapping Builder</div>
                  </Box>
                </Box>
                <hr className={"sepLine"} />
              </div>
              <div className={`${"boxed"} ${"descBottom"}`}>
                <Box display="flex" className={"titleSection"}>
                  <Box flexGrow={1}>
                    <div>
                      <div className={"titleHeaderRow"}>
                        {selectedPip ? selectedPip.configKey : ""}
                      </div>
                      <div className={"titleRow"}>
                        Last Updated: 19 Aug 2021
                      </div>
                    </div>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                    }}
                  >
                    {!isReadOnly ? (
                      <IconButton
                        iconProps={{
                          iconName: "Settings",
                          style: { fontSize: 23 },
                        }}
                        styles={{
                          root: { color: "white" },
                          rootHovered: {
                            backgroundColor: "#0078d4",
                            color: "white",
                          },
                        }}
                        onClick={this.toggleTableDetails}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <div className="builderDescriptionSection">
                  <div className={"descAlign"}>
                    <div className="col-sm-4">
                      <div className={"descHeader"}>
                        MAPPING TABLE DESCRIPTION
                      </div>
                      <div className={"upperSpace"}>
                        <div
                          className={"editControlSectionHeader"}
                          dangerouslySetInnerHTML={{
                            __html: selectedPip ? selectedPip.description : "",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="mappingDescDivider">
                    <div className="col-sm-4">
                      <div className={"tableSummaryHeader"}>
                        MAPPING TABLE SUMMARY
                      </div>
                      <div className={"mappingCategory"}>MAPPING CATEGORY</div>
                      <div className={"upperSpace"}>
                        {selectedPip
                          ? this.getCategoryValue(selectedPip.categoryId)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    style={{
                      minHeight: "inherit",
                      color: "black",
                      width: "25px",
                    }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          <TableMappingDataProcessing
            selectedPipId={queryString.parse(this.props.location.search)}
            selectedTable={this.state.selectedPip}
          />
        </Box>
        {isTableDetailsPanelOpen ? (
          <TableDetails
            isTableDetailsPanelOpen={isTableDetailsPanelOpen}
            hideDetailsPanelHandler={this.hideDetailsPanelHandler}
            selectedPip={selectedPip}
            isReadOnly={isReadOnly}
            dropDownOptions={categories}
          />
        ) : (
          ""
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  refdata: state.refData.referenceData,
  enableReinitialize: true,
  backendResult: state.pips.backendResult,
  message: state.ui.message,
  result: state.ui.result,
  tables: Object.values(state.mappingTables.tables)
});

const connected = connect(mapStateToProps, {
  fetchReferenceData,
  fetchReferenceDataTypes,
  referenceDataTypeSelected,
  fetchAllTableMappings,
  fetchNotificationMasters
})(AuthProvider(TableMappingBuilder));

export default connected;
