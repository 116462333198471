import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { fetchAdminChangeMasters, fetchAdminChangeMastersPageRef, fetchAdminChangeMastersFilters, fetchAdminChangeMastersWithFilters, fetchAdminChangeMastersSelectedFilters, fetchAdminChangeMastersWithFiltersScrollData, fetchAdminChangeMastersScrollData } from '../../../state/actions/AdminChangeMasterActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import AdminChangeMasterList from './AdminChangeMasterList';
import history from '../../../history';
import FilterCallout from '../../../components/filterv2/FilterCallout';
import { createFilterOptionsByStatus, createFilterOptionsByChangeCategoryForControl, createFilterOptionsByVerification } from '../../../util/FilterOptionsUtil';
import FilterOptionTypeLiteral from '../../../constants/FilterLiterals';
import uuid from 'react-uuid';
import LoadingSpinner from '../../../components/loading/LoadingSpinner';

class AdminChangeConsole extends React.Component {
  constructor(props) {
    super(props);
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === 'CHANGE_CONTROL')[0];
    const filterOptions = []
    this.state = {
      filterLiterals,
      open: false,
      hideDialog: true,
      selectedTypeValues: '',
      filterSelections: [],
      filterOptionsKey: uuid(),
      toggleFilterCallout: false,
      changeCategoryListData: [],
      pageNo: 0,
      filterOptions,
      selectedFilter: [],
      filterSelectionsItems: [],
      showInpageItems: []
    };
  }

  async componentDidMount() {
    await this.props.fetchAdminChangeMastersFilters();
    document.title = 'Admin Change Console';
    this.getFilterOptions();
    this.addFilterOptions();
    await this.getMastersWithFilters();
    document.addEventListener('scroll', this.trackScrolling);
  }

  async componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedFilter !== undefined) {
      if (prevState.selectedFilter !== this.state.selectedFilter) {

        this.setState({ selectedFilter: this.state.selectedFilter });
        // return { selectedFilter: this.state.selectedFilter };
      }
    }
    if (prevState.pageNo !== this.state.pageNo) {
      document.addEventListener('scroll', this.trackScrolling);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedTypeValues !== nextProps.selectedTypeValues) {
      return { selectedTypeValues: nextProps.selectedTypeValues };
    }
    return null;
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('bottom');
    if (this.props.changeMastersWithFiltersData.masters.changeCategories.length > 0) {
      this.setState((prevState) => ({ ...prevState, showInpageItems: this.props.changeMastersWithFiltersData.totalInPage }));
    }

    // if (this.props.changeMastersSelectedFiltersData[2]?.items?.length > 0) {
    if (this.props.changeMastersWithFiltersData.totalRecords > this.props.changeMastersWithFiltersData.totalInPage) {
      if (this.isBottom(wrappedElement)) {
        document.removeEventListener('scroll', this.trackScrolling);

        if (this.props.changeMastersWithFiltersData.masters.changeCategories.length > 0) {
          this.fetchOnScrollFilterData();
        }
      }
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  addFilterOptions = async () => {
    const options = this.props.changeMasterDataFilter?.data;
    options.map((item, index) => {
      if (item.changeCategoryName === "Reference Data" || item.changeCategoryName === "Task Master") {
        return this.applyFilterHandler(index, 2, options);
      } else {
        return ""
      }
    });
  };

  applyFilterHandler = (index, idx2, option) => {
    // const entry = option.entries[idx2];
    const entry = option[index];
    const selectedFilter = this.selectedFilter;
    const newselectedFilter = selectedFilter;
    const found = selectedFilter[idx2].items.some((ch) => ch.key === entry.key);
    let newselValues = [];
    if (found) {
      // remove from selectedFilter
      newselValues = selectedFilter[index].items.filter((ch) => ch.key !== entry.key);
      newselectedFilter[index].items = newselValues;
    } else {
      // add to selectedFilter
      newselValues = [...selectedFilter[idx2].items, { key: entry.categoryId, value: entry.changeCategoryName }];
      newselectedFilter[idx2].items = newselValues;
    }
    // this.setState((prevState) => ({ ...prevState, selectedFilter: [...newselectedFilter] }));
    this.selectedFilter = newselectedFilter;
    this.applyFilterSelectionsHandler([...newselectedFilter]);
  }

  getFilterOptions = () => {
    const selectedFilter = [];
    const { filterSelections } = this.state;
    this.filterOptions.contentFilters.forEach((element, index) => {
      const filterType = filterSelections && filterSelections.length > 0 ? filterSelections[index].filterType : element.filterType;
      const items = filterSelections && filterSelections.length > 0 ? [...filterSelections[index].items] : [];
      selectedFilter.push({ filterType, items });
    });
    this.selectedFilter = selectedFilter;
    this.props.fetchAdminChangeMastersSelectedFilters(selectedFilter);
  }

  getMastersWithFilters = async () => {
    const { filterSelections } = this.state;

    const filterOptions = {
      adminCcrItemStatus: [],
      verificationStatusQuery: [],
      categoryIdsQuery: []
    };

    if (filterSelections && filterSelections?.length > 0) {
      const data = filterSelections?.map((item) => {
        if (item.filterType === "Change Item Status") {
          item.items.forEach(change => {
            filterOptions.adminCcrItemStatus.push(change?.key);
          });
        } else if (item.filterType === "Verification Status") {
          item.items.forEach(verification => {
            let vfStatus = verification?.key.toLowerCase();
            filterOptions.verificationStatusQuery.push(vfStatus);
          });
        }
        else if (item.filterType === "Category") {
          item.items.forEach(cat => {
            filterOptions.categoryIdsQuery.push(cat?.key);
          });
        } else {
          // items.push(item?.key);
        }
        return filterOptions;
      })

      this.setState({ filterSelectionsItems: filterOptions, pageNo: 0 });
      await this.props.fetchAdminChangeMastersWithFilters(filterOptions);
      return data
    }
    else {
      this.setState({ filterSelectionsItems: filterOptions, pageNo: 0 });
      await this.props.fetchAdminChangeMastersWithFilters(filterOptions);
    }
  }

  findAllByKey = (obj, keyToFind) => Object.entries(obj)
    // eslint-disable-next-line no-nested-ternary
    .reduce((acc, [key, value]) => ((key === keyToFind)
      ? acc.concat({ value, id: obj.categoryId })
      : (typeof value === 'object')
        ? acc.concat(this.findAllByKey(value, keyToFind))
        : acc),
      [])



  createFilterOptions() {

    if (!this.props.changeMasterDataFilter?.data?.length > 0) return {};
    // const categorylist = this.findAllByKey(this.props.changeMasterDataFilter?.data, 'changeCategoryName');
    const categorylist = this.findAllByKey(this.props.changeMasterDataFilter?.data, 'changeCategoryName', 'categoryId');
    const categorylistdistinct = [...new Set(categorylist.map((p) => p))];

    const filterVerification = createFilterOptionsByVerification();
    const filterStatus = createFilterOptionsByStatus();
    const filterCategory = createFilterOptionsByChangeCategoryForControl(categorylistdistinct);

    const filterEntries = {
      contentFilters: [filterStatus, filterVerification, filterCategory]
    };

    this.filterOptions = filterEntries;
    return filterEntries;
  }

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState((prevState) => ({ ...prevState, filterSelections }));
  }

  displaySelection() {
    const { filterSelections } = this.state;
    return Array.isArray(filterSelections) && filterSelections.length > 0;
  }

  reduceByApplyingFilter() {
    const { filterSelections } = this.state;
    let changeCategoryList = [];
    // changeCategoryList = this.props.changeMasterData?.data?.changeCategories;

    changeCategoryList = this.props.changeMastersWithFiltersData?.masters?.changeCategories;

    if (changeCategoryList) {
      let response = [];
      if (filterSelections && filterSelections[0]?.items?.length > 0) {
        const compareIfEntityExists = (status) => filterSelections[0].items.some((ch) => ch.key === status);
        changeCategoryList && changeCategoryList.map(i => {
          return i.keySystemResources.map(j => {
            if (j.changeItems.filter((curr) => compareIfEntityExists(curr.status)).length > 0) {
              if (!response.includes(i))
                response.push(i);
            }
            return response
          })
        })
        changeCategoryList = response;
      }
      if (filterSelections && filterSelections[1]?.items?.length > 0) {
        const compareIfEntityExists = (verified) => filterSelections[1].items.some((ch) => ch.key === verified);
        changeCategoryList && changeCategoryList.map(i => {
          return i.keySystemResources.map(j => {
            if (j.changeItems.filter((curr) => compareIfEntityExists(curr.verified.toString().toUpperCase())).length > 0) {
              if (!response.includes(i))
                response.push(i);
            }
            return response
          })
        })
        changeCategoryList = response;
      }
      if (filterSelections && filterSelections[2]?.items?.length > 0) {
        // const compareIfCatgeoryExists = (type) => filterSelections[2].items.some((ch) => ch.key === type);
        const compareIfCatgeoryExists = (changeCategoryId) => filterSelections[2].items.some((ch) => ch.key === changeCategoryId);

        changeCategoryList && changeCategoryList.filter(i => {
          if (i.keySystemResources.filter((curr) => compareIfCatgeoryExists(curr.changeCategoryId)).length > 0) {
            if (!response.includes(i))
              response.push(i);
          }
          return response
        })
        changeCategoryList = response;
      }
      return changeCategoryList;
    }
  }

  toggleFilterCalloutHandler = () => {
    this.setState((prevState) => ({ ...prevState, toggleFilterCallout: !this.state.toggleFilterCallout }));
  };

  handleSelectedTypeToggle = (item) => () => {
    const { filterSelections } = this.state;
    const newselValues = filterSelections.map((selectedValue, index) => {
      selectedValue.items = selectedValue.items.filter((ch) => ch.key !== item.key);
      return selectedValue;
    });

    const newfilterSelections = newselValues;
    this.setState((prevState) => ({ ...prevState, filterSelections: [...newfilterSelections] }));
    this.getMastersWithFilters();
  };

  removeSingleFilter = (parentIndex, childrenIndex) => {
    const newState = this.state.selectedFilters[parentIndex].items.splice(childrenIndex, 1);
    this.setState((prevState) => ({ ...prevState, selectedFilters: newState }));
  };

  onClose = (value) => {
    this.setState({ open: false });
  };

  listAdminChangeMasters = () => {
    const changeCategoryList = this.reduceByApplyingFilter();
    return changeCategoryList?.map((changeCategoryItem, index) => (
      <>
        <div>
          <AdminChangeMasterList
            key={`adm-${index}`}
            index={index}
            changeCategoryItem={changeCategoryItem}
            filterSelections={this.state.filterSelections}
            fetchOnScroll={this.fetchOnScrollHandler}
          // fetchOnScrollFilterData={this.fetchOnScrollFilterData}
          ></AdminChangeMasterList>
        </div>
      </>
    ));
  }

  closeDialog = () => {
    this.setState({ hideDialog: true });
  }

  fetchOnScrollHandler = async () => {
    const { pageNo } = this.state;
    const pg = pageNo + 1;
    // if (this.props.totalRecordsData > this.props.taskMasterFilteredData.length) {
    if (this.props.changeMasterData !== undefined) {
      await this.props.fetchAdminChangeMastersPageRef(this.props.changeMasterData, pg);
      this.setState((prevState) => ({ ...prevState, pageNo: pg }));
    }
  };

  fetchOnScrollFilterData = async () => {
    const { pageNo } = this.state;
    const pg = pageNo + 1;

    if (this.props.changeMastersWithFiltersData !== undefined) {
      await this.props.fetchAdminChangeMastersWithFiltersScrollData(this.props.changeMastersWithFiltersData, this.state.filterSelectionsItems, pg);
      this.setState((prevState) => ({ ...prevState, pageNo: pg }));
    }

  };


  render() {
    const { classes } = this.props;
    const CHANGE_SET_MANAGEMENT_URL_PATH = "/adminChangeConsole/changeSetManagement"
    const { filterType } = this.state.filterLiterals;
    const {
      toggleFilterCallout,
      filterOptionsKey,
      filterSelections,
    } = this.state;

    if (!this.props.changeMasterData) return <div></div>;
    if (!this.props.changeMastersWithFiltersData) return <div><LoadingSpinner loadingText="Change Masters data still loading..." /></div>;
    return (
      <Box display="flex">``
        <Card variant='outlined' className={this.props.classes.rootBackground}>
          <CardHeader className={this.props.classes.cardHeaderSection}
            title={
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6} >
                  <Typography className={this.props.classes.cardHeader}>
                    Admin Change Console
                  </Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  <DefaultButton
                    className={classes.changeSetManagementButton}
                    styles={{ label: { fontWeight: "normal" } }}
                    primary={false}
                    onClick={() => history.push(CHANGE_SET_MANAGEMENT_URL_PATH)}
                    text="Change Set Management"
                  />
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Box mt={1} container="true" justify-content="space-around"
              flexWrap="wrap" width={1} >
              <>
                <div className={classes.grid1}>
                  <div id="FilterCallout" className={classes.filtersButton} onClick={this.toggleFilterCalloutHandler}>
                    Filters
                  </div>
                  <FilterCallout getMastersWithFilters={this.getMastersWithFilters} openDialog={toggleFilterCallout} toggleFilterCalloutHandler={this.toggleFilterCalloutHandler} key={filterOptionsKey} filterType={filterType} filterOptions={this.createFilterOptions()} applyFilterSelectionsHandler={this.applyFilterSelectionsHandler} initialSelections={filterSelections} showSelectedItems={false} ></FilterCallout>
                </div>
                <hr className={this.props.classes.sepLine} />
                <Grid item xs={12} sm container>
                  {this.displaySelection()
                    ? <div>
                      {filterSelections && filterSelections.map((f) => f.items.map((item) => (
                        <Button
                          className={classes.filterButtonLabel}
                          name="selectedTypeValuesBtn"
                        >
                          {item.value}
                          <ClearIcon
                            className={classes.buttonClearIcon}
                            onClick={this.handleSelectedTypeToggle(item)}
                          ></ClearIcon>
                        </Button>
                      )))}
                    </div>
                    : null}
                </Grid>
                <div style={{ marginTop: '1rem' }}>
                  {this.listAdminChangeMasters()}

                  {this.props.changeMastersWithFiltersData?.totalInPage !== undefined ?
                    <div><b>Showing</b> {this.props.changeMastersWithFiltersData?.totalInPage} <b>out of</b> {this.props.changeMastersWithFiltersData?.totalRecords} <b>Change Items</b></div> :
                    ""}

                  {this.props.changeMastersWithFiltersData?.totalRecords > this.props.changeMastersWithFiltersData?.totalInPage ?
                    <div id="bottom" >Bottom of page</div> : ""
                  }

                </div>
              </>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

const styles = (theme) => ({
  rootBackground: {
    height: '100%',
    backgroundColor: 'white',
    width: '100%',
    margin: '1rem',
    marginLeft: '5rem',
    marginRight: '5rem'
  },
  root: {
    flexGrow: 1
  },
  cardHeaderSection: {
    padding: '16px 0 0 0'
  },
  cardHeader: {
    fontSize: '27px',
    fontFamily: 'PharmaCond',
    paddingLeft: '1rem',
    color: '#323130',
    fontWeight: '700',
    titleTypographyProps: { variant: 'h1' },
    title: {
      titleTypographyProps: { variant: 'h1' },
      fontSize: '12px'
    },
  },
  filtersButton: {
    width: '40px',
    height: '18px',
    padding: '5px 19px 7px',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    backgroundColor: '#ffffff',
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  filterButtonLabel: {
    textTransform: 'capitalize',
    backgroundColor: '#EAEAEA',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'auto',
    marginRight: '1rem'
  },
  buttonStyle: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#fff',
    color: '#323130',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    cursor: 'pointer'
  },
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50
  },
  changeSetManagementButton: {
    height: "32px",
    marginRight: "16px",
    borderRadius: "2px",
    backgroundColor: "#fff",
    marginTop: "5px",
    border: "solid 1px #8a8886 !important",
  },
});

AdminChangeConsole.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  changeMasterData: state.changeMastersData?.changeMasters,
  changeMasterDataFilter: state.changeMasterDataFilter?.changeMastersFilters,
  changeMastersWithFiltersData: state.changeMastersWithFiltersData?.changeWithFilters,
  changeMastersSelectedFiltersData: state.changeMastersSelectedFiltersData?.changeSelectedFilters,

  result: state.ui.result,
});

const connected = connect(mapStateToProps, {
  fetchAdminChangeMasters,
  fetchAdminChangeMastersPageRef,
  fetchAdminChangeMastersFilters,
  fetchAdminChangeMastersWithFilters,
  fetchAdminChangeMastersSelectedFilters,
  fetchAdminChangeMastersWithFiltersScrollData,
  fetchAdminChangeMastersScrollData
})(AuthProvider(AdminChangeConsole));

export default withStyles(styles)(connected);
