import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import "./filter.css";
import "../style.css";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClearIcon from '@material-ui/icons/Clear';
import { deleteItem, setItem, getAllSelectedItems } from '../../state/actions/SharedMasterActions';
import AuthProvider from '../auth/AuthProvider';
import { fetchCheckPointMasters } from '../../state/actions/CheckPointMasterActions';
import {
 fetchPolicyLibraryMasters, fetchPolicyBuilderMasters, fetchPolicySchemeConfigurations, setPolicyBuilderConfiguration
} from '../../state/actions/PolicyLibraryMasterActions';
import { fetchTaskMasters, fetchTaskMastersFiltered, fetchDecisionPointMasters } from '../../state/actions/TaskMasterActions';

const RESOURCE_STATE = [
  { id: 'ACTIVE', code: 'rs_ACTIVE', title: 'Active' },
  { id: 'INACTIVE', code: 'rs_INACTIVE', title: 'Inactive' },
  { id: 'ARCHIVED', code: 'rs_ARCHIVED', title: 'Archived' },
];

class Filter extends React.Component {
 constructor (props) {
    super(props);

      this.state = {
         selectedIndex: 0,
         currentDesc: '',
         currentName: '',
         checked: [],
         selectedTypeValues: [],
         selectedRegionValues: [],
         selectedValues: [],
         selectedStatusValues: [],
         page: 'Task',
         openCheck: false,
         openDecision: false,
         openPolicy: false,
         openResourceState: false,
         open: false,
         };
         this.initialState = { ...this.state };
         this.handleSelectedTypeToggle = this.handleSelectedTypeToggle.bind(this);
   }

  componentDidMount () {
    this.props.fetchDecisionPointMasters();
    this.props.fetchCheckPointMasters();

    if (this.props.selectedListValues.length === 0) { this.props.getAllSelectedItems(); }

      if (this.props.source === "TASK") {
        // this.props.fetchTaskMasters();
        this.props.fetchTaskMastersFiltered(this.props.selectedListValues);
        document.title = 'Task Masters Library';
      }
      if (this.props.source === "POLICY") {
        this.props.fetchPolicyBuilderMasters(this.state.currentId);
        document.title = 'Policy Masters Library';
      }
    }

    handleClick = () => {
      if (this.state.open === true) {
      this.setState({ open: false });
      } else {
        this.setState({ open: true });
      }
    };

    handleClickCheck = () => {
      if (this.state.openCheck === true) {
      this.setState({ openCheck: false });
      } else {
        this.setState({ openCheck: true });
      }
    };

    handleClickResourceState = () => {
      if (this.state.openResourceState === true) {
      this.setState({ openResourceState: false });
      } else {
        this.setState({ openResourceState: true });
      }
    };

    handleClickDecision = () => {
      if (this.state.openDecision === true) {
      this.setState({ openDecision: false });
      } else {
        this.setState({ openDecision: true });
      }
    };

    handleClickPolicy = () => {
      if (this.state.openPolicy === true) {
      this.setState({ openPolicy: false });
      } else {
        this.setState({ openPolicy: true });
      }
    };

    handleTypeToggle = (value) => () => {
      const currentIndex = this.state.selectedTypeValues.indexOf(value);
      if (currentIndex !== -1) {
        this.state.selectedTypeValues.splice(currentIndex, 1);
        this.props.deleteItem(this.state.selectedTypeValues);
      } else {
        this.state.selectedTypeValues.push(value);
        this.props.setItem(this.state.selectedTypeValues);
      }
      this.setState({ selectedValues: this.props.selectedListValues });
      if (this.props.selectedListValues?.length > 0) {
        this.props.setPolicyBuilderConfiguration(this.props.policyLibraryBuilderData?.policySchemeConfigurations, this.state.selectedTypeValues); 
      } else {
        this.props.setPolicyBuilderConfiguration(this.props.policyLibraryBuilderData?.policySchemeConfigurations, this.props.policyLibraryBuilderData?.policySchemeConfigurations);
      }
    };

    handleSelectedTypeToggle = (value) => () => {
      const currentIndex = this.state.selectedTypeValues.indexOf(value);
      if (currentIndex !== -1) {
        this.state.selectedTypeValues.splice(currentIndex, 1);
        this.props.deleteItem(this.state.selectedTypeValues);
      } else {
        this.state.selectedTypeValues.push(value);
        this.props.setItem(this.state.selectedTypeValues);
      }
      this.setState({ selectedValues: this.props.selectedListValues });
      if (this.props.selectedListValues?.length > 0) { this.props.setPolicyBuilderConfiguration(this.props.policyLibraryBuilderData?.policySchemeConfigurations, this.state.selectedTypeValues); } else { this.props.setPolicyBuilderConfiguration(this.props.policyLibraryBuilderData?.policySchemeConfigurations, this.props.policyLibraryBuilderData?.policySchemeConfigurations); }
    };

    handleRegionToggle = (value) => () => {
      const currentIndex = this.state.selectedRegionValues.indexOf(value);
      if (currentIndex !== -1) {
        this.state.selectedRegionValues.splice(currentIndex, 1);
      } else {
        this.state.selectedRegionValues.push(value);
      }
    };

    handleStatusToggle = (value) => () => {
      const currentIndex = this.state.selectedStatusValues.indexOf(value);
      if (currentIndex !== -1) {
        this.state.selectedStatusValues.splice(currentIndex, 1);
      } else {
        this.state.selectedStatusValues.push(value);
      }
    };

    FilterPage=() => {
      if (this.props.source === "TASK") { this.props.fetchTaskMastersFiltered(this.state.selectedTypeValues); }
      if (this.props.source === "POLICY" && this.props.origin !== "ps") {
        this.props.setPolicyBuilderConfiguration(this.props.policyLibraryBuilderData?.policySchemeConfigurations, this.state.selectedTypeValues);
      }
    }

    RefreshPage=() => {
      this.setState(this.initialState);
      this.props.getAllSelectedItems();
      this.setState({ selectedValues: [] });
      this.setState({ selectedTypeValues: [] });

      if (this.props.source === "TASK") {
        this.props.fetchTaskMasters();
        document.title = 'Task Masters Library';
      }
      if (this.props.source === "POLICY") {
        this.props.setPolicyBuilderConfiguration(this.props.policyLibraryBuilderData?.policySchemeConfigurations, this.props.policyLibraryBuilderData?.policySchemeConfigurations);
        document.title = 'Policy Masters Library';
      }
    }

    componentWillMount () {
      this.initialState = this.state;
    }

render () {
  const { classes } = this.props;
  const checkPointMastersList =  this.props.checkPointData.masters ? this.props.checkPointData.masters : [];
  const decisionPointMastersList = this.props.decisionPointData.masters ? this.props.decisionPointData.masters : [];
  const policyLibraryConfigurationList = this.props.policyLibraryBuilderData?.policySchemeConfigurations;
  const { source } = this.props;
    return (
      <div>
      { source === "TASK"
      ? <div className="searchlist">
        <Accordion className="searchlist">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
          <Typography> Task Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <ListItem button onClick={this.handleClickDecision}>
            <ListItemText primary="Decision Point" className={classes.filterTextLabel}/>
            {this.state.openDecision ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openDecision} timeout="auto" unmountOnExit>
          <List>
          {decisionPointMastersList.map((value) => {
          const labelId = value.id;
          return (
            <ListItem key={value.id} role={undefined} dense button onClick={this.handleTypeToggle(value)}>
            <ListItemIcon>
              <Checkbox className={classes.checkboxStyle}
                edge="start"
                name="selectedTypeValues"
                checked={!!this.props.selectedListValues?.find((ch) => ch.id === value.id)}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.title} />
          </ListItem>
          );
          })}

          </List>
          </Collapse>

          <ListItem button onClick={this.handleClickCheck}>
            <ListItemText primary="CheckPoint Type" />
            {this.state.openCheck ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openCheck} timeout="auto" unmountOnExit>
          <List>
          {checkPointMastersList.map((value) => {
          const labelId = value.id;
          return (
            <ListItem key={value.id} role={undefined} dense button onClick={this.handleTypeToggle(value)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                name="selectedTypeValuesCheck"
                checked={!!this.props.selectedListValues?.find((ch) => ch === value)}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.title} />
          </ListItem>
          );
          })}
          </List>
          </Collapse>

          <ListItem button onClick={this.handleClickResourceState}>
            <ListItemText primary="Resource State" />
            {this.state.openResourceState ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openResourceState} timeout="auto" unmountOnExit>
          <List>
          {RESOURCE_STATE.map((value) => {
          const labelId = value.id;
          return (
            <ListItem key={value.id} role={undefined} dense button onClick={this.handleTypeToggle(value)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                name="selectedTypeValuesResourceState"
                checked={!!this.props.selectedListValues?.find(({ id }) => id === value.id)}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.title} />
          </ListItem>
          );
          })}
          </List>
          </Collapse>

          </AccordionDetails>
        </Accordion>
        <Box className={classes.filterButtons}>
          {this.state.selectedValues !== ""
            ? (
            <ButtonGroup fullWidth
            aria-label="full width outlined button group"
            className={classes.root}
            classes={{ groupedOutlined: classes.groupedOutlined }}>
            {this.props.selectedListValues?.map((value) => (
            <Button className={classes.filterButtonLabel} name="selectedTypeValuesBtn" >{value.title}<ClearIcon className={classes.buttonClearIcon} onClick={this.handleSelectedTypeToggle(value)} ></ClearIcon></Button>
            ))}
          </ButtonGroup>)
          :          <div></div>
          }
        </Box>
        <Box>
          <button className={classes.buttonSubmit} onClick={() => { this.FilterPage(); }}>Apply</button>
          <button className={classes.buttonSubmit} onClick={() => { this.RefreshPage(); }}>Clear All Filters</button>
        </Box>
      </div>
    : <div></div>}
    { source === "POLICY"
    ? <>
      <List>
      {policyLibraryConfigurationList?.map((value) => {
      const labelId = value.policyScheme.id;
      return (
        <ListItem key={value.policyScheme.id} role={undefined} dense button onClick={this.handleTypeToggle(value)}>
        <ListItemIcon>
          <Checkbox className={classes.checkboxStyle}
            edge="start"
            name="selectedTypeValues"
            checked={!!this.props.selectedListValues?.find((ch) => ch.policyScheme.id === value.policyScheme.id)}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={value.policyScheme.description} />
      </ListItem>
      );
      })}

      </List>
      <Box className={classes.filterButtons}>
      {this.state.selectedValues !== ""
        ? (
        <ButtonGroup fullWidth
        aria-label="full width outlined button group"
        className={classes.root}
        classes={{ groupedOutlined: classes.groupedOutlined }}>
        {this.props.selectedListValues?.map((value) => (
        <Button className={classes.filterButtonLabel} name="selectedTypeValuesBtn" >{value.policyScheme.description}<ClearIcon className={classes.buttonClearIcon} onClick={this.handleSelectedTypeToggle(value)} ></ClearIcon></Button>
        ))}
      </ButtonGroup>)
      :      <div></div>
      }
    </Box>
    <Box>
      <button className={classes.buttonSubmit} onClick={() => { this.RefreshPage(); }}>Clear All Filters</button>
    </Box>
    </>
    : <div></div>}
    </div>
      );
}
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 250,
    margin: "0 auto"
  },
  groupedOutlined: {
    "&:not(:first-child)": {
      marginLeft: -1
    },
    "&:not(:last-child)": {
      borderRightColor: "rgba(0, 0, 0, 0.23)"
    }
  },
  searchlist: {
     backgroundColor: '#d8d8d8',
     padding: '23px 24px',
     color: '#555555',
     fontWeight: '600',
     fontSize: '14px',
     width: '250px'
 },
 buttonSubmit: {
  padding: '7px 19px',
  margin: '0 16px',
  backgroundColor: '#c8c8c8',
  color: 'black',
  fontWeight: '500',
  orderRadius: '2px',
  border: 'none',
  cursor: 'pointer',
  marginTop: '10px',
  marginLeft: '5%',
  marginRight: '5%',
  marginBottom: '1rem'
 },
 filterButtons: {
   marginLeft: '16px'
 },
 filterTextLabel: {
  fontweight: 'bold !important'
 },
 filterButtonLabel: {
  textTransform: 'capitalize',
  backgroundColor: '#EAEAEA',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 'auto'
 },
 buttonClearIcon: {
  margin: '3px 0 0 10px'
 } ,
 checkboxStyle:{
   fontSize: '0.75rem' 
 }
 });

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {

  return { 
    checkPointData: state.checkpointMasterData.checkpointMasters,
    taskMasterData:state.taskMastersData.taskMasters,    
    policyMasterData:state.policyMasterData,
    decisionPointData: state.decisionpointMasterData.decisionpointMasters,
    checkPointMasters :state.checkPointMasterData,
    selectedListValues : state.sharedMasterData.selectedItems,
    policySchemeConfigurations: state.policyLibraryBuilderData.policySchemeConfigurations,    
    policyLibraryBuilderData: state.policyLibraryBuilderData,
    result: state.ui.result
  };
  };
  
  const connected = connect(mapStateToProps, {
    fetchTaskMasters,
    fetchTaskMastersFiltered,
    fetchDecisionPointMasters,
    fetchCheckPointMasters,
    fetchPolicyLibraryMasters,
    fetchPolicyBuilderMasters,
    deleteItem,
    setItem,
    getAllSelectedItems,
    fetchPolicySchemeConfigurations,
    setPolicyBuilderConfiguration
   })(AuthProvider(Filter));

export default withStyles(styles)(connected);
