/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable one-var */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable no-case-declarations */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// eslint-disable-next-line import/no-duplicates
import { Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import "../../../components/style.css";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Grid from "@material-ui/core/Grid";
import { initializeIcons } from "@fluentui/react";
import { HeaderTableCell, theme } from "./TableStyling";
import AddTableMappings from "./AddTableMappings";
import UpdateTableMappings from "./UpdateTableMappings";
import {
  fetchAllChangeTypeEventTypeMappings,
  fetchAllTableMappings,
  fetchAllFunctions,
  fetchNotificationMsaterRoleMappings,
  fetchAllEventTypes,
  fetchAllRequiredFieldsDecisionPointTypeMappings,
  fetchAllManagedFields,
  fetchAllManagedFieldsDecisionPointTypeMappings,
  fetchAllRequiredFieldsCheckpointTypeMappings,
  fetchAllPRRCriteriaCategories,
  fetchAllPRRCriterias,
  fetchAllPRRCriteriaEntities,
  fetchAllPRRCriteriaTags,
} from "../../../state/actions/MappingTablesActions";
import { fetchNotificationMasters } from "../../../state/actions/NotificationMastersAction";
import {
  fetchReferenceData,
  fetchReferenceDataTypes,
  referenceDataTypeSelected,
} from "../../../state/actions/ReferenceDataActions";
// eslint-disable-next-line import/no-useless-path-segments
import "./../reference.css";
import AuthProvider from "../../../components/auth/AuthProvider";
import envReadOnlyFlagContext from "../../../context/adminContext";

let isReadOnly = false;

class TableMappingDataProcessing extends React.Component {
  static contextType = envReadOnlyFlagContext;

  // eslint-disable-next-line space-before-function-paren
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      selectedPipId: "",
      hideDialog: true,
      disableAddValueButton: true,
      selectedPip: null,
      showAddValueSection: false,
      selectedDropdownValues: [],
      isSaved: false,
      showDelDialog: false,
      delAttrValue: "",
      tableColNames: [],
      tableMappings: [],
      tblDropDownOptions: [],
      newTblColValues: {},
      delConfirmedMappingTblValue: "",
      isAddFreeText: false,
      isAddNewValue: true,
      isLoading: false,
      isUnique: false,
      isSelectAll: false,
    };
    initializeIcons();
  }

  // eslint-disable-next-line space-before-function-paren
  async componentDidMount() {
    const { selectedPipId } = this.props.selectedPipId;

    await this.props.fetchReferenceData();
    await this.props.fetchReferenceDataTypes();
    await this.props.fetchAllManagedFields();
    await this.props.fetchAllEventTypes();

    this.getTableList(selectedPipId);
  }

  // eslint-disable-next-line space-before-function-paren
  async componentDidUpdate(preProps) {
    const { selectedTable } = this.props;

    if (selectedTable && selectedTable !== preProps.selectedTable) {
      this.processSelectedTable(selectedTable);
    }
  }

  getTableList = async (selectedPipId) => {
    await this.props.fetchAllTableMappings();

    const selectedTable = this.props.tables.filter(
      (item) => item.id === selectedPipId
    );

    this.setState({
      selectedPip: selectedTable ? selectedTable[0] : this.props.tables[0],
    });

    await this.refreshData(selectedTable[0]);
    this.processSelectedTable(selectedTable[0]);
  };

  processSelectedTable = async (selectedTable) => {
    const selectedDropdownValues = [];

    switch (selectedTable.configValue) {
      case "Notification Function":
        // eslint-disable-next-line no-case-declarations
        const funcListMappings = [];

        this.props.functions.forEach((func) => {
          funcListMappings.push([
            {
              colId: func.id,
              colValue: func.code,
              colName: "Function Code",
              RefType: "",
            },
            {
              colId: func.id,
              colValue: func.name,
              colName: "Function Name",
              RefType: "",
            },
            {
              colId: func.id,
              colValue: func.description,
              colName: "Description",
              RefType: "",
            },
          ]);
        });

        selectedDropdownValues.push(
          { key: "code", value: "" },
          { key: "name", value: "" },
          { key: "description", value: "" }
        );

        this.setState({
          tableColNames: ["Function Code", "Function Name", "Description"],
          tableMappings: funcListMappings,
          selectedDropdownValues,
          isAddFreeText: true,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        break;
      case "Notification Master Recipient Strategy Mapping":
        // eslint-disable-next-line no-case-declarations
        const recipStrategyMappings = [];

        this.props.notificationMasters.forEach((nM) => {
          if (nM.recipientStrategies) {
            nM.recipientStrategies.forEach((func) => {
              recipStrategyMappings.push([
                {
                  colId: nM.id,
                  colValue: nM.code,
                  colName: "NotificationMaster",
                  RefType: "",
                },
                {
                  colId: func,
                  colValue: this.getReferenceValueForAttrValue(func),
                  colName: "Notification Recipient Strategy",
                  RefType: "NOTIFICATION_RECIPIENT_STRATEGY_TYPE",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: [
            "NotificationMaster",
            "Notification Recipient Strategy",
          ],
          tableMappings: recipStrategyMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(recipStrategyMappings);
        break;
      case "Notification Master Role Mapping":
        // eslint-disable-next-line no-case-declarations
        const roleMappings = [];

        this.props.roleMappings.forEach((roleMapping) => {
          roleMapping.notificationRoles.forEach((role) => {
            roleMappings.push([
              {
                colId: roleMapping.notificationMasterId,
                colValue: roleMapping.notificationMasterCode,
                colName: "NotificationMaster",
                RefType: "",
              },
              {
                colId: role.id,
                colValue: role.name,
                colName: "Role Name",
                RefType: "APPROVER_ROLE",
              },
            ]);
          });
        });

        this.setState({
          tableColNames: ["NotificationMaster", "Role Name"],
          tableMappings: roleMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(roleMappings);
        break;
      case "Notification Master Function Mapping":
        // eslint-disable-next-line no-case-declarations
        const funcMappings = [];
        this.props.notificationMasters.forEach((nM) => {
          if (nM.functions) {
            nM.functions.forEach((func) => {
              funcMappings.push([
                {
                  colId: nM.id,
                  colValue: nM.code,
                  colName: "NotificationMaster",
                  RefType: "",
                },
                {
                  colId: func.id,
                  colValue: func.code,
                  colName: "Notification Function",
                  RefType: "",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["NotificationMaster", "Notification Function"],
          tableMappings: funcMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(funcMappings);
        break;
      case "Notification Master Event Mapping":
        // eslint-disable-next-line no-case-declarations
        const eventMappings = [];
        this.props.notificationMasters.forEach((nM) => {
          if (nM.associatedEventTypes) {
            nM.associatedEventTypes.forEach((func) => {
              eventMappings.push([
                {
                  colId: nM.id,
                  colValue: nM.code,
                  colName: "NotificationMaster",
                  RefType: "",
                },
                {
                  colId: func,
                  colValue: func,
                  colName: "Oli Event Type",
                  RefType: "",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["NotificationMaster", "Oli Event Type"],
          tableMappings: eventMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(eventMappings);
        break;
      case "Admin Change Item Type Event Mapping":
        // eslint-disable-next-line no-case-declarations
        const changeEventMappings = [];

        this.props.changeEventTypes.forEach((nM) => {
          if (nM.associatedEventTypes) {
            nM.associatedEventTypes.forEach((type) => {
              changeEventMappings.push([
                {
                  colId: nM.changeTypeId,
                  colValue: this.getReferenceValueForAttrValue(nM.changeTypeId),
                  colName: "Change Type",
                  RefType: "ADMIN_CCR_CHANGE_TYPE",
                },
                {
                  colId: type,
                  colValue: type,
                  colName: "Oli Event Type",
                  RefType: "",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["Change Type", "Oli Event Type"],
          tableMappings: changeEventMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(changeEventMappings);
        break;
      case "Required Field Decision Point Type Mapping":
        const requiredFieldDPMappings = [];

        this.props.requiredFieldsDPTypes.forEach((nM) => {
          if (nM.decisionPointTypes) {
            nM.decisionPointTypes.forEach((type) => {
              requiredFieldDPMappings.push([
                {
                  colId: nM.managedFieldId,
                  colValue: this.getManagedFieldNameById(nM.managedFieldId),
                  colName: "Managed Field",
                  RefType: "",
                },
                {
                  colId: type.id,
                  colValue: type.value,
                  colName: "Decision Point Type",
                  RefType: "DECISION_POINT_TYPE",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["Managed Field", "Decision Point Type"],
          tableMappings: requiredFieldDPMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(requiredFieldDPMappings);
        break;
      case "Managed Field Decision Point Type Mapping":
        const managedFieldDPMappings = [];

        this.props.dpTypeManagedFields.forEach((nM) => {
          if (nM.managedFields) {
            nM.managedFields.forEach((type) => {
              managedFieldDPMappings.push([
                {
                  colId: nM.decisionPointType.id,
                  colValue: nM.decisionPointType.value,
                  colName: "Decision Point Type",
                  RefType: "DECISION_POINT_TYPE",
                },
                {
                  colId: type.fieldId,
                  colValue: type.fieldName,
                  colName: "Managed Field",
                  RefType: "",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["Decision Point Type", "Managed Field"],
          tableMappings: managedFieldDPMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(managedFieldDPMappings);
        break;
      case "Required Field Checkpoint Type Mapping":
        const requiredFieldCPMappings = [];

        this.props.cpTypeRequiredFields.forEach((nM) => {
          if (nM.checkPointTypes) {
            nM.checkPointTypes.forEach((type) => {
              requiredFieldCPMappings.push([
                {
                  colId: nM.managedFieldId,
                  colValue: this.getManagedFieldNameById(nM.managedFieldId),
                  colName: "Managed Field",
                  RefType: "",
                },
                {
                  colId: type.id,
                  colValue: type.value,
                  colName: "Checkpoint Type",
                  RefType: "CHECKPOINT_TYPE",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["Managed Field", "Checkpoint Type"],
          tableMappings: requiredFieldCPMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(requiredFieldCPMappings);
        break;
      case "Risk Rating Criteria Categories":
        const riskCriteriaCategoryMappings = [];

        this.props.criteriaCategories.forEach((nM) => {
          if (nM.riskCategories) {
            nM.riskCategories.forEach((type) => {
              riskCriteriaCategoryMappings.push([
                {
                  colId: nM.criteriaId,
                  colValue: this.getPRRCriteriaNameById(nM.criteriaId),
                  colName: "Risk Rating Criteria",
                  RefType: "",
                },
                {
                  colId: type,
                  colValue: type,
                  colName: "Risk Category Type",
                  RefType: "",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["Risk Rating Criteria", "Risk Category Type"],
          tableMappings: riskCriteriaCategoryMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(riskCriteriaCategoryMappings);
        break;
      case "Platform Table Configuration":
        const platformTables = [];

        this.props.tables.forEach((nM, index) => {
          platformTables.push([
            {
              colId: nM.id,
              colValue: nM.configValue,
              colName: "Table Name",
              RefType: "",
            },
            {
              colId: nM.categoryId,
              colValue: this.getReferenceValueForAttrValue(
                nM.categoryId
              ).replaceAll("_", " "),
              colName: "Category",
              RefType: "Category",
            },
            {
              colId: index,
              colValue: String(nM.isUpdatable),
              colName: "Modified Rows",
              RefType: "",
            },
          ]);
        });

        this.setState({
          tableColNames: ["Table Name", "Category", "Modified Rows"],
          tableMappings: platformTables,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        break;
      case "Risk Rating Criteria Entity Mapping":
        const riskCriteriaEntityMappings = [];

        this.props.criteriasEntities.forEach((nM) => {
          if (nM.llEntities) {
            nM.llEntities.forEach((type) => {
              riskCriteriaEntityMappings.push([
                {
                  colId: nM.criteriaId,
                  colValue: this.getPRRCriteriaNameById(nM.criteriaId),
                  colName: "Risk Rating Criteria",
                  RefType: "",
                },
                {
                  colId: type.id,
                  colValue: type.value,
                  colName: "Risk Rating Entity",
                  RefType: "LL_ENTITY",
                },
              ]);
            });
          }
        });

        this.setState({
          tableColNames: ["Risk Rating Criteria", "Risk Rating Entity"],
          tableMappings: riskCriteriaEntityMappings,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(riskCriteriaEntityMappings);
        break;
      case "Risk Rating Criteria":
        const riskCriterias = [];

        this.props.criterias.forEach((nM, index) => {
          riskCriterias.push([
            {
              colId: `p${index}`,
              colValue: nM.prrRef,
              colName: "Prr Ref",
              RefType: "",
            },
            {
              colId: nM.criteriaId,
              colValue: nM.title,
              colName: "Risk Rating Criteria",
              RefType: "",
            },
            {
              colId: `q${index}`,
              colValue: nM.question,
              colName: "Question",
              RefType: "Question",
            },
            {
              colId: `o${index}`,
              colValue: nM.displayOrder,
              colName: "Sort Order",
              RefType: "",
            },
          ]);
        });

        selectedDropdownValues.push(
          { key: "ref", value: "" },
          { key: "criteria", value: "" },
          { key: "question", value: "" },
          { key: "order", value: "" },
          { key: "category", value: "" }
        );

        this.setState({
          tableColNames: [
            "Prr Ref",
            "Risk Rating Criteria",
            "Question",
            "Sort Order",
          ],
          tableMappings: riskCriterias,
          selectedDropdownValues,
          isAddFreeText: true,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        break;
      case "Risk Rating Criteria Option":
        const criteriaOptions = [];

        this.props.criterias.forEach((nM, index) => {
          if (nM.options) {
            nM.options.forEach((option) => {
              criteriaOptions.push([
                {
                  colId: nM.criteriaId,
                  colValue: nM.title,
                  colName: "Criteria",
                  RefType: "",
                },
                {
                  colId: option.criteriaOptionId,
                  colValue: option.criteriaOption,
                  colName: "Option",
                  RefType: "",
                },
                {
                  colId: `v${index}`,
                  colValue: option.value,
                  colName: "Value",
                  RefType: "",
                },
                {
                  colId: `o${index}`,
                  colValue: option.sortOrder,
                  colName: "Sort Order",
                  RefType: "",
                },
              ]);
            });
          }
        });

        if (criteriaOptions.length > 0) {
          selectedDropdownValues.push({
            key: criteriaOptions[0][0].colId,
            value: "",
          });
        }

        selectedDropdownValues.push(
          { key: "option", value: "" },
          { key: "Value", value: "" },
          { key: "order", value: "" }
        );

        const tblDropDownOptions = [];
        const listOptions = [];
        this.props.criterias.forEach((item, ind) => {
          listOptions.push({ text: item.title, key: item.criteriaId });
        });

        tblDropDownOptions.push(listOptions);

        this.setState({
          tableColNames: ["Criteria", "Option", "Value", "Sort Order"],
          tableMappings: criteriaOptions,
          selectedDropdownValues,
          tblDropDownOptions,
          isAddFreeText: true,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        break;
      case "Prr Restriction Tag":
        const riskTags = [];

        this.props.criteriasTags.forEach((nM, index) => {
          if (nM.tags) {
            nM.tags.forEach((type) => {
              type.tagValues.forEach((value) => {
                riskTags.push([
                  {
                    colId: nM.criteriaId,
                    colValue: this.getPRRCriteriaNameById(nM.criteriaId),
                    colName: "Risk Rating Criteria",
                    RefType: "",
                  },
                  {
                    colId: type.id,
                    colValue: type.tagName,
                    colName: "Tag Name",
                    RefType: " ",
                  },
                  {
                    colId: value.id,
                    colValue: value.tagValue,
                    colName: "Tag Value",
                    RefType: " ",
                  },
                ]);
              });
            });
          }
        });

        this.setState({
          tableColNames: ["Risk Rating Criteria", "Tag Name", "Tag Value"],
          tableMappings: riskTags,
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
        this.processTableDropDownOptions(riskTags);
        break;
      default:
        this.setState({
          tableColNames: [],
          tableMappings: [],
          selectedDropdownValues: [],
          isAddFreeText: false,
          selectedPip: selectedTable,
          isSelectAll: false,
          isAddNewValue: true,
        });
    }
  };

  getReferenceValueForAttrValue = (attrValue) => {
    const filterRef = this.props.refdata.filter((ref) => ref.id === attrValue);

    if (filterRef && filterRef.length > 0) return filterRef[0].value;

    return "";
  };

  getManagedFieldNameById = (fieldId) => {
    const filterField = this.props.managedFields.filter(
      (field) => field.fieldId === fieldId
    );
    if (filterField && filterField.length > 0) return filterField[0].fieldName;

    return "";
  };

  getPRRCriteriaNameById = (fieldId) => {
    const filterField = this.props.criterias.filter(
      (field) => field.criteriaId === fieldId
    );
    if (filterField && filterField.length > 0) return filterField[0].title;

    return "";
  };

  getRefDatasByType = (type) => this.props.refdata.filter(
    (ref) => ref.type.toUpperCase() === type.toUpperCase()
  );

  processTableDropDownOptions = (tableMappings) => {
    const dropDownOptions = [];
    const selectedDropdownValues = [];
    const refDataColumns = [
      "Role Name",
      "Notification Recipient Strategy",
      "Change Type",
      "Decision Point Type",
      "Checkpoint Type",
      "Risk Rating Entity",
    ];

    let filterRef;

    if (tableMappings.length === 0) return;

    tableMappings[0].forEach((col, index) => {
      const listOptions = [];

      if (refDataColumns.includes(col.colName)) {
        filterRef = this.getRefDatasByType(col.RefType);

        if (filterRef && filterRef.length > 0) {
          filterRef.forEach((ref) => {
            listOptions.push({
              key: ref.id,
              text: ref.value,
            });
          });
        }
      } else {
        switch (col.colName) {
          case "NotificationMaster":
            // eslint-disable-next-line no-case-declarations
            const notificationMasters = this.props.notificationMasters
              ? this.props.notificationMasters
              : [];

            notificationMasters.forEach((item) => {
              listOptions.push({ text: item.code, key: item.id });
            });
            break;
          case "Notification Function":
            this.props.functions.forEach((item) => {
              listOptions.push({ text: item.code, key: item.id });
            });
            listOptions.sort((a, b) => a.code - b.code);
            break;
          case "Oli Event Type":
            this.props.eventTypes.forEach((item, ind) => {
              listOptions.push({ text: item, key: ind });
            });
            break;
          case "Managed Field":
            this.props.managedFields.forEach((item, ind) => {
              listOptions.push({ text: item.fieldName, key: item.fieldId });
            });
            break;
          case "Risk Category Type":
            listOptions.push({ text: "GMR", key: "GMR" });
            listOptions.push({ text: "RAF", key: "RAF" });
            break;
          case "Risk Rating Criteria":
            this.props.criterias.forEach((item, ind) => {
              listOptions.push({ text: item.title, key: item.criteriaId });
            });
            break;
          case "Tag Name":
            listOptions.push({
              text: "MANDATORY_IN_PHASE",
              key: "MANDATORY_IN_PHASE",
            });
            listOptions.push({
              text: "AVAILABLE_IN_PHASE",
              key: "AVAILABLE_IN_PHASE",
            });
            listOptions.push({
              text: "UNKNOWN_OPTION_ALLOWED_IN_PHASE",
              key: "UNKNOWN_OPTION_ALLOWED_IN_PHASE",
            });
            break;
          case "Tag Value":
            listOptions.push({ text: "OPPORTUNITY", key: "OPPORTUNITY" });
            listOptions.push({ text: "ORIGINATION", key: "ORIGINATION" });
            listOptions.push({ text: "CONVERSION", key: "CONVERSION" });
            listOptions.push({ text: "DELIVERY", key: "DELIVERY" });
            break;
          default:
        }
      }

      dropDownOptions.push(listOptions);
      selectedDropdownValues.push({ key: col.colId, value: "" });
    });

    this.setState({
      tblDropDownOptions: dropDownOptions,
      selectedDropdownValues,
    });
  };

  deleteButtonClicked = async (id, delAttrValue) => {
    this.setState({ showDelDialog: true, delAttrValue });
  };

  refreshData = async (selectedTable) => {
    let sortColumn;

    await this.props.fetchAllFunctions();
    await this.props.fetchNotificationMsaterRoleMappings();
    await this.props.fetchAllChangeTypeEventTypeMappings();
    await this.props.fetchAllRequiredFieldsDecisionPointTypeMappings();
    await this.props.fetchAllManagedFieldsDecisionPointTypeMappings();
    await this.props.fetchAllRequiredFieldsCheckpointTypeMappings();
    await this.props.fetchAllPRRCriteriaCategories();
    await this.props.fetchAllManagedFields();
    await this.props.fetchAllPRRCriteriaEntities();

    if (selectedTable.configValue === "Risk Rating Criteria") {
      sortColumn = "displayOrder";
    } else {
      sortColumn = "title";
    }

    await this.props.fetchAllPRRCriterias(sortColumn);
    await this.props.fetchAllPRRCriteriaTags();
  };

  showAddValue = () => {
    const showAddValueSection = !this.state.showAddValueSection;
    this.setState({ showAddValueSection });
  };

  // eslint-disable-next-line space-before-function-paren
  render() {
    const {
      selectedPip,
      showAddValueSection,
      tableColNames,
      tableMappings,
      tblDropDownOptions,
      selectedDropdownValues,
      isAddFreeText,
    } = this.state;

    isReadOnly = this.context.isReadOnly;

    return (
      <Box flexGrow={4} style={{ maxWidth: "1200px" }}>
        <Card>
          <CardContent>
            <Box>
              <Card>
                <CardContent>
                  <div className={"mappingListSubHeader"}>
                    Mapping Table Values
                  </div>
                  <TableContainer component={Paper} style={{ height: "500px" }}>
                    <ThemeProvider theme={theme}>
                      <Table stickyHeader>
                        <TableHead style={{ height: "35px" }}>
                          <TableRow>
                            {selectedPip
                              ? tableColNames.map((colName, index) => (
                                <HeaderTableCell
                                  align="left"
                                  key={`${index}`}
                                >
                                  {colName}
                                </HeaderTableCell>
                              ))
                              : ""}
                            {!isReadOnly ? (
                              <HeaderTableCell align="left">
                                {selectedPip && selectedPip.isUpdatable
                                  ? "Edit"
                                  : "Remove"}
                              </HeaderTableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedPip && tableMappings.length > 0 ? (
                            <UpdateTableMappings
                              tableMappings={tableMappings}
                              processSelectedTable={this.processSelectedTable}
                              selectedPip={selectedPip}
                              isReadOnly={isReadOnly}
                              refreshData={this.refreshData}
                            />
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </ThemeProvider>
                  </TableContainer>
                </CardContent>
              </Card>
              {!isReadOnly ? (
                <div className={"addNewMappingSection"}>
                  <div className={"mappingListSubHeader"}>
                    Add New Mapping Table Values
                  </div>
                  <Grid item xs={12}>
                    <button
                      className={"whiteBtn"}
                      onClick={() => {
                        this.showAddValue();
                      }}
                    >
                      {!showAddValueSection ? (
                        <AddCircleOutlineIcon
                          className={"addValueLinkIcon"}
                        ></AddCircleOutlineIcon>
                      ) : (
                        <RemoveCircleOutlineIcon
                          className={"addValueLinkIcon"}
                        ></RemoveCircleOutlineIcon>
                      )}
                      Add New Value
                    </button>
                  </Grid>
                  {showAddValueSection ? (
                    <div>
                      {selectedPip ? (
                        <AddTableMappings
                          tableMappings={tableMappings}
                          tableColNames={tableColNames}
                          tblDropDownOptions={tblDropDownOptions}
                          processSelectedTable={this.processSelectedTable}
                          isAddFreeText={isAddFreeText}
                          selectedPip={selectedPip}
                          isReadOnly={isReadOnly}
                          selectedDropdownValues={selectedDropdownValues}
                          refreshData={this.refreshData}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  refdata: state.refData.referenceData,
  enableReinitialize: true,
  backendResult: state.pips.backendResult,
  message: state.ui.message,
  result: state.ui.result,
  tables: Object.values(state.mappingTables.tables),
  eventTypes: state.mappingTables.eventTypes
    ? Object.values(state.mappingTables.eventTypes)
    : [],
  roleMappings: state.mappingTables.roleMappings
    ? Object.values(state.mappingTables.roleMappings)
    : [],
  functions: state.mappingTables.functions
    ? state.mappingTables.functions.masters
    : [],
  notificationMasters: state.notificationMastersData.notificationMasters
    ? state.notificationMastersData.notificationMasters.masters
    : [],
  changeEventTypes: state.mappingTables.changeEventTypes
    ? state.mappingTables.changeEventTypes.associations
    : [],
  requiredFieldsDPTypes: state.mappingTables.requiredFieldsDPTypes
    ? state.mappingTables.requiredFieldsDPTypes.associations
    : [],
  managedFields: state.mappingTables.managedFields
    ? Object.values(state.mappingTables.managedFields.summaries)
    : [],
  dpTypeManagedFields: state.mappingTables.dpTypeManagedFields
    ? state.mappingTables.dpTypeManagedFields.associations
    : [],
  cpTypeRequiredFields: state.mappingTables.cpTypeRequiredFields
    ? state.mappingTables.cpTypeRequiredFields.associations
    : [],
  criteriaCategories: state.mappingTables.criteriaCategories
    ? state.mappingTables.criteriaCategories.criteriaCategories
    : [],
  criterias: state.mappingTables.criterias
    ? state.mappingTables.criterias.criterias
    : [],
  criteriasEntities: state.mappingTables.criteriasEntities
    ? state.mappingTables.criteriasEntities.criteriaEntities
    : [],
  criteriasTags: state.mappingTables.criteriasTags
    ? state.mappingTables.criteriasTags.tags
    : [],
  tagValues: state.mappingTables.tagValues
    ? state.mappingTables.tagValues.summaries
    : [],
});

const connected = connect(mapStateToProps, {
  fetchAllChangeTypeEventTypeMappings,
  fetchReferenceData,
  fetchReferenceDataTypes,
  referenceDataTypeSelected,
  fetchAllTableMappings,
  fetchNotificationMsaterRoleMappings,
  fetchNotificationMasters,
  fetchAllFunctions,
  fetchAllEventTypes,
  fetchAllRequiredFieldsDecisionPointTypeMappings,
  fetchAllManagedFields,
  fetchAllManagedFieldsDecisionPointTypeMappings,
  fetchAllRequiredFieldsCheckpointTypeMappings,
  fetchAllPRRCriteriaCategories,
  fetchAllPRRCriterias,
  fetchAllPRRCriteriaEntities,
  fetchAllPRRCriteriaTags,
})(AuthProvider(TableMappingDataProcessing));

export default connected;
