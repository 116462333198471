import React from 'react';
import NotificationLibraryCard from './NotificationLibraryCard';

class NotificationLibraryGrid extends React.Component {
  renderCards () {
  return this.props.notificationLibraryData && this.props.notificationLibraryData.notificationLibraryCategories ? this.props.notificationLibraryData.notificationLibraryCategories.map((ia, index) => (
      <NotificationLibraryCard
          key={`pls-${index}`}
          index={index}
          category={ia.category}
          totalNumberOfSchemes={ia.notificationLibrarySummary.totalNumberOfSchemes}
      />
  )) : '';
  }

  render () {
    return (
      this.renderCards()
    );
  }
}

export default NotificationLibraryGrid;
