import React from 'react';
import CheckPointMasterCard from './CheckPointMasterCard';

class CheckPointMasterGrid extends React.Component {
  componentDidMount () {
    // this.props.fetchCheckPointMasters();
  }

  renderCards () {
    return this.props.checkpointData.masters ? this.props.checkpointData.masters.map((ia) => (
      <CheckPointMasterCard checkPointList={this.props.checkpointData.masters} code={ia.code}
        checkPoint={ia} title={ia.title} description={ia.description} key={ia.code}
      />
    )) : '';
  }

  render () {
    return (
      this.renderCards()
    );
  }
}

export default CheckPointMasterGrid;
