/* eslint-disable array-callback-return */
import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
 DefaultButton, FontWeights, getTheme, IconButton, initializeIcons, Modal, PrimaryButton, Separator, Stack, Text, TextField
} from '@fluentui/react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../../components/auth/AuthProvider';
import {
  fetchPolicySchemeConfiguration, fetchPolicySchemeConfigurations, fetchPolicyConfigurationNames, duplicatePolicy
} from '../../../state/actions/PolicyLibraryMasterActions';
import { fetchReferenceData } from '../../../state/actions/ReferenceDataActions';

import '../../../images/dot.png';
import './DuplicatePolicy.css';

class DuplicatePolicy extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // policyToDuplicate: this.props.policyToDuplicate,

      // windows
      inStep1: true,
      inStep2: false,
      inConfirmation: false,

      // validations
      inStep2Valid: true,

      // policy
      duplicatedPolicy: null,
      newPolicySchemeId: null,
    };
    initializeIcons();
  }

  async componentDidMount () {
    this.createDuplicatePolicy();
    await this.props.fetchPolicyConfigurationNames();
  }

  /* Functions used to build section of page */

  step1 () {
    const { classes } = this.props;
    if (!this.state.duplicatedPolicy) return null;
    const { name, nameIsValid, description, descriptionIsValid } = this.state.duplicatedPolicy.policyScheme;
    const { pepCode, pepCodeIsValid, pepTitle, pepTitleIsValid } = this.state.duplicatedPolicy.policyEnforcementPoint;
    const nextButtonDisabled = !(nameIsValid && descriptionIsValid && pepCodeIsValid && pepTitleIsValid);
    const charsLeft = (100 - description.length);

    return (
      <Modal
        isOpen={this.state.inStep1}
        onDismiss={this.hideDuplicatePolicyHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep1}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Duplicate Policy - Step 1 out of 2</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideDuplicatePolicyHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack horizontal className={classes.informational}>
                <IconButton iconProps={{ iconName: 'Info' }} />
                <p>
                  Please create a unique name for your new policy.  Once finalised you can update the policy name and description from the policy builder screen if desired.
                </p>
              </Stack>
            </Stack>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.inputArea}>
                <TextField label="Policy Name" value={name} onChange={this.onChangePolicyName} />
                <Stack horizontal>
                  {DuplicatePolicy.createValidationStatus('policy name', nameIsValid)}
                </Stack>
              </Stack>
            </Stack>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.inputArea}>
                <TextField label="Policy Description" value={description} onChange={this.onChangePolicyDescription} multiline={true} style={{ height: '110px' }} maxLength={100} />
                <Text style={{ fontSize: '12px' }}>You have {charsLeft} characters left</Text>
              </Stack>
            </Stack>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.inputArea}>
                <TextField label="PEP Code" value={pepCode} onChange={this.onChangePepCode} />
                <Stack horizontal>
                  {DuplicatePolicy.createValidationStatus('PEP code', pepCodeIsValid)}
                </Stack>
              </Stack>
            </Stack>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.inputArea}>
                <TextField label="PEP Title" value={pepTitle} onChange={this.onChangePepTitle} />
                <Stack horizontal>
                  {DuplicatePolicy.createValidationStatus('PEP title', pepTitleIsValid)}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl1}>
              <DefaultButton text="Cancel" onClick={this.hideDuplicatePolicyHandler} />
              <PrimaryButton text="Next" onClick={this.moveToPage2Handler} disabled={nextButtonDisabled} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  step2 () {
    const { classes, isReadOnly } = this.props;
    if (!this.state.duplicatedPolicy) return null;
    const { policyDecisionPoint } = this.state.duplicatedPolicy;
    const nextButtonDisabled = !(DuplicatePolicy.isPdpValuesValid(this.state.duplicatedPolicy.policyDecisionPoint, isReadOnly));

    return (
      <Modal
        isOpen={this.state.inStep2}
        onDismiss={this.hideDuplicatePolicyHandler}
        isModeless={true}
        containerClassName={classes.containerStep2}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep2}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Duplicate Policy - Step 2 out of 2</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideDuplicatePolicyHandler}
              />
            </div>
            <Stack style={{ height: 593, overflow: 'auto' }}>
              <Stack tokens={tokens.sectionStack}>
                <Stack horizontal className={classes.informational}>
                  <IconButton iconProps={{ iconName: 'Info' }} />
                  <p>
                    Please create a unique name for your new policy.  Once finalised you can update the policy name and description from the policy builder screen if desired.
                  </p>
                </Stack>
              </Stack>
              <Stack tokens={tokens.sectionStack}>
                <Stack className={classes.accorMain}>
                { policyDecisionPoint.map((pdp, idx1) => {
                const { pdpTitle, pdpTitleIsValid, pdpReference, pdpCode, pdpCodeIsValid, panelExpanded, pdpDescription } = pdp;
                const charsLeftPdpReference = (200 - pdpReference.length);
                const charsLeftPdpDescription = (200 - pdpDescription.length);

                return (
                <Accordion key={`panel${idx1}-accor`} expanded={panelExpanded} onChange={(e) => this.onHandlePanelExpand(e, idx1)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${idx1}-AccordionSummary`}
                    id={`panel${idx1}-AccordionSummary`}>
                    <Typography className={classes.heading}>{pdpTitle}</Typography>
                  </AccordionSummary>
                  <AccordionDetails key={`panel${idx1}-AccordionDetails`}>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Title" value={pdpTitle} id={`pdpTitle${idx1}`} onChange={(evt) => this.onChangePdpTitle(evt, idx1)} />
                      <Stack horizontal>
                        {DuplicatePolicy.createValidationStatus('PDP Title', pdpTitleIsValid)}
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Reference" value={pdpReference} id={`pdpReference${idx1}`} onChange={(evt) => this.onChangePdpReference(evt, idx1)} maxLength={200} multiline={true} style={{ height: '120px' }} />
                      <Stack horizontal>
                        <Text style={{ fontSize: '12px' }}>You have {charsLeftPdpReference} characters left</Text>
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Code" value={pdpCode} id={`pdpCode${idx1}`} onChange={(evt) => this.onChangePdpCode(evt, idx1)} />
                      <Stack horizontal>
                        {DuplicatePolicy.createValidationStatus('PDP Code', pdpCodeIsValid)}
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Description" value={pdpDescription} id={`pdpDescription${idx1}`} onChange={(evt) => this.onChangePdpDescription(evt, idx1)} maxLength={200} multiline={true} style={{ height: '120px' }} />
                      <Stack horizontal>
                        <Text style={{ fontSize: '12px' }}>You have {charsLeftPdpDescription} characters left</Text>
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </Accordion>);
                }) }
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl1}>
              <DefaultButton text="Cancel" onClick={this.hideDuplicatePolicyHandler} />
              <DefaultButton text="Back" onClick={this.moveToPage1Handler} />
              <PrimaryButton text="Create Policy" onClick={this.submitDuplicatePolicyRequest} disabled={nextButtonDisabled} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  confirmation () {
    const { classes } = this.props;
    if (!this.state.duplicatedPolicy) return null;
    const { name } = this.state.duplicatedPolicy.policyScheme;
    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideDuplicatePolicyHandler}
        isModeless={true}
        containerClassName={classes.containerConfirmation}
      >
        <Stack verticalAlign="space-between" styles={stackStylesConfirmation}>
          <Stack>
            <div className={classes.header}>
            <img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /><span className={classes.title}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideDuplicatePolicyHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.confirmation}>
                <Stack horizontal style={{ paddingTop: '15px', fontFamily: 'SegoeUI' }}>
                  You have successfully created a new policy:
                </Stack>
                <Stack horizontal style={{ fontWeight: '600', paddingTop: '15px' }}>
                  {name}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl1}>
              <DefaultButton text="Close" onClick={this.hideDuplicatePolicyHandler} />
              <PrimaryButton text="View my new policy" onClick={this.viewNewPolicySchemeHandler} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  /* Step and Window handler */

  onChangePolicyName = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyScheme;
    val.name = newVal;
    val.nameIsValid = this.validateStep1Name(newVal.trim());
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyScheme: val } }));
  }

  onChangePolicyDescription = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyScheme;
    val.description = newVal;
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyScheme: val } }));
  }

  onChangePepCode = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyEnforcementPoint;
    val.pepCode = newVal;
    val.pepCodeIsValid = this.validateStep1PepCode(newVal.trim());
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyEnforcementPoint: val } }));
  }

  onChangePepTitle = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyEnforcementPoint;
    val.pepTitle = newVal;
    val.pepTitleIsValid = this.validateStep1PepTitle(newVal.trim());
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyEnforcementPoint: val } }));
  }

  onChangePdpTitle = (event, idx) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyDecisionPoint;
    val[idx].pdpTitle = newVal;
    val[idx].pdpTitleIsValid = this.validateStep2PdpTitle(newVal.trim(), this.state.duplicatedPolicy.policyDecisionPoint, idx);
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyDecisionPoint: val } }));
  }

  onChangePdpReference = (event, idx) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyDecisionPoint;
    val[idx].pdpReference = newVal;
    val[idx].pdpReferenceIsValid = true; // this.validateStep2PdpReference(newVal, this.state.duplicatedPolicy.policyDecisionPoint, idx);
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyDecisionPoint: val } }));
  }

  onChangePdpCode = (event, idx) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyDecisionPoint;
    val[idx].pdpCode = newVal;
    val[idx].pdpCodeIsValid = this.validateStep2PdpCode(newVal.trim(), this.state.duplicatedPolicy.policyDecisionPoint, idx);
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyDecisionPoint: val } }));
  }

  onChangePdpDescription = (event, idx) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.duplicatedPolicy.policyDecisionPoint;
    val[idx].pdpDescription = newVal;
    val[idx].pdpDescriptionIsValid = true; // this.validateStep2PdpReference(newVal, this.state.duplicatedPolicy.policyDecisionPoint, idx);
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyDecisionPoint: val } }));
  }

  onHandlePanelExpand = (event, idx) => {
    const val = this.state.duplicatedPolicy.policyDecisionPoint;
    val[idx].panelExpanded = !(val[idx].panelExpanded);
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: { ...this.state.duplicatedPolicy, policyDecisionPoint: val } }));
  }

  /* Step and Window handler */

  hideDuplicatePolicyHandler = () => {
    this.props.hideDuplicatePolicyPanelHandler();
    this.setState((prevState) => ({ ...prevState, inStep1: false, inStep2: false, inConfirmation: false }));
  }

  viewNewPolicySchemeHandler = () => {
    this.props.viewNewPolicySchemeHandler(this.state.newPolicySchemeId);
    this.setState((prevState) => ({ ...prevState, inStep1: false, inStep2: false, inConfirmation: false }));
  }

  moveToPage1Handler = () => {
    this.setState((prevState) => ({ ...prevState, inStep1: true, inStep2: false, inConfirmation: false }));
  }

  moveToPage2Handler = () => {
    this.setState((prevState) => ({ ...prevState, inStep1: false, inStep2: true, inConfirmation: false }));
  }

  /* Validation handler */

  validateStep1Name (name) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.schemeNames.forEach((scheme) => {
      if (scheme.trim() === name || name.length === 0) {
        valid = false;
      }
    });
    return valid;
  }

  validateStep1PepCode (pepCode) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pepValues.pepCodes.forEach((pep) => {
      if (pep.trim() === pepCode || pepCode.length === 0) {
        valid = false;
      }
    });
    return valid;
  }

  validateStep1PepTitle (pepTitle) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pepValues.pepTitles.forEach((pep) => {
      if (pep.trim() === pepTitle || pepTitle.length === 0) {
        valid = false;
      }
    });
    return valid;
  }

  validateStep2PdpTitle (pdpTitle, newPDPs, idx) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pdpValues.pdpTitles.forEach((pdp) => {
      if (pdp.trim() === pdpTitle || pdpTitle.length === 0) {
        valid = false;
      }
    });

    newPDPs.forEach((newPdp, i) => {
      if (i !== idx && newPdp.pdpTitle.trim() === pdpTitle) {
        valid = false;
      }
    });
    return valid;
  }

  validateStep2PdpReference (pdpReference, newPDPs, idx) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pdpValues.pdpReferences.forEach((pdp) => {
      if (pdp.trim() === pdpReference || pdpReference.length === 0) {
        valid = false;
      }
    });

    newPDPs.forEach((newPdp, i) => {
      if (i !== idx && newPdp.pdpReference.trim() === pdpReference) {
        valid = false;
      }
    });
    return valid;
  }

  validateStep2PdpCode (pdpCode, newPDPs, idx) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pdpValues.pdpCodes.forEach((pdp) => {
      if (pdp.trim() === pdpCode || pdpCode.length === 0) {
        valid = false;
      }
    });

    newPDPs.forEach((newPdp, i) => {
      if (i !== idx && newPdp.pdpCode.trim() === pdpCode) {
        valid = false;
      }
    });
    return valid;
  }

  /* Processor (Edit/Save/Delete) */

  submitDuplicatePolicyRequest = async () => {
    const { policySchemeTypeId } = this.props;
    const policy = this.props.policyToDuplicate;
    const { name, description } = this.state.duplicatedPolicy.policyScheme;
    policy.policyScheme.name = name;
    policy.policyScheme.description = description;
    const { pepCode, pepTitle } = this.state.duplicatedPolicy.policyEnforcementPoint;

    const entitlementRules = [];
    policy.entitlementRules.map((ent, idx) => {
      const tmpPep = ent.policyEnforcementPoint;
      tmpPep.pepCode = pepCode;
      tmpPep.pepTitle = pepTitle;
      const {
        pdpTitle, pdpReference, pdpCode, pdpDescription
      } = this.state.duplicatedPolicy.policyDecisionPoint[idx];
      const tmpPdp = ent.policyDecisionPoint;
      tmpPdp.pdpTitle = pdpTitle;
      tmpPdp.pdpReference = pdpReference;
      tmpPdp.pdpCode = pdpCode;
      tmpPdp.pdpDescription = pdpDescription;
      entitlementRules.push({
        ...ent,
        policyEnforcementPoint: tmpPep,
        policyDecisionPoint: tmpPdp
      });
    });
    policy.entitlementRules = [...entitlementRules];
    const policySchemeConfiguration = {
      policySchemeTypeId,
      policySchemeConfiguration: policy
    };

    await this.props.duplicatePolicy(policySchemeConfiguration);
    const { id } = this.props.result;

    if (id) {
      this.setState((prevState) => ({
        ...prevState, inStep1: false, inStep2: false, inConfirmation: true, newPolicySchemeId: id
      }));
    }
  }

  /* Helper Functions */

  createDuplicatePolicy () {
    const { name, description } = this.props.policyToDuplicate.policyScheme;
    const { pepCode, pepTitle } = this.props.policyToDuplicate.entitlementRules[0].policyEnforcementPoint;
    const newPolicy = {
      policyScheme: {
        name,
        nameIsValid: false,
        description,
        descriptionIsValid: true,
      },
      policyEnforcementPoint: {
        pepCode,
        pepCodeIsValid: false,
        pepTitle,
        pepTitleIsValid: false
      },
      policyDecisionPoint: this.copyPdpValues()
    };
    this.setState((prevState) => ({ ...prevState, duplicatedPolicy: newPolicy }));
  }

  copyPdpValues () {
    const pdp = [];
    this.props.policyToDuplicate.entitlementRules.forEach((erl) => {
      const {
        pdpTitle,
        pdpReference,
        pdpCode,
        pdpDescription
      } = erl.policyDecisionPoint;
      pdp.push({
        pdpTitle,
        pdpTitleIsValid: false,
        pdpReference,
        pdpReferenceIsValid: true,
        pdpCode,
        pdpCodeIsValid: false,
        pdpDescription,
        pdpDescriptionIsValid: true,
        panelExpanded: false
      });
    });

    return pdp;
  }

  static createValidationStatus (fieldName, status) {
    if (status) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px' }}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} styles={{ root: { color: 'green' } }} /><span style={{ fontSize: '12px' }}>This is a valid {fieldName}.</span></div>);
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px' }}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} /><span style={{ fontSize: '12px' }}>This not yet a valid and unique {fieldName}. Update to continue.</span></div>
    );
  }

  static isPdpValuesValid (newPDPs, isReadOnly) {
    let valid = true;
    if (isReadOnly) return false;

    newPDPs.forEach((newPdp) => {
      const { pdpTitleIsValid, pdpReferenceIsValid, pdpCodeIsValid } = newPdp;
      if (!(pdpTitleIsValid && pdpReferenceIsValid && pdpCodeIsValid)) {
        valid = false;
      }
    });
    return valid;
  }

  /* Render */

  render () {
    return (
      <>
        {this.step1()}
        {this.step2()}
        {this.confirmation()}
      </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '750px',
  },
  containerStep2: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '750px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '750px',
  },
  header: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '15px 12px 14px 24px',
    },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  title: {
    fontSize: '20px',
    color: '#323130'
  },
  informational: {
    margin: '0 25px 25px 25px',
    padding: '16px',
    borderRadius: '2px',
    backgroundColor: '#f4f4f4'
  },
  policyName: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  inputArea: {
    padding: '0 25px 10px 25px',
    borderRadius: '2px',
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  btnCtrl1: {
    padding: '0 25px',
  },
  accorMain: {
    margin: '0 16px 0 16px ',
  },
  accorOpen: {
    margin: '0 16px 0 16px ',
  },
  heading: {
    fontSize: '18px',
  }
});

const theme = getTheme();

const cancelIcon = { iconName: 'Cancel' };

const OliLogo = require('../../../images/oli-success.svg');

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    // color: '#323130',
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 5,
  },
};

const stackStylesStep1 = {
  root: {
    height: 500,
  },
};

const stackStylesStep2 = {
  root: {
    height: 732,
  },
};

const stackStylesConfirmation = {
  root: {
    height: 732,
  },
};

const mapStateToProps = (state) => ({
    policySchemeConfigurations: state.policyLibraryBuilderData.policySchemeConfigurations,
    result: state.policyLibraryMasterData.duplicatePolicySchemeResult,
    refdata: state.refData.referenceData,
    policySchemeConfigurationsNames: state.policyLibraryBuilderData.policySchemeConfigurationsNames
  });

DuplicatePolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  fetchPolicySchemeConfiguration,
  fetchReferenceData,
  fetchPolicySchemeConfigurations,
  fetchPolicyConfigurationNames,
  duplicatePolicy,
})(AuthProvider(DuplicatePolicy));

export default withStyles(styles)(connected);
