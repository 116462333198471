import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
 import { IconButton } from '@fluentui/react';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Grid from '@material-ui/core/Grid';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import RulePicker from '../../components/rules/rulePicker/RulePicker';
import { ActionLiteralType } from '../../constants/ActionTypeObj';
import RuleConfigValue from '../../components/rules/ruleConfigValue/RuleConfigValue';

import AuthProvider from '../../components/auth/AuthProvider';
import { fetchNotificationMastersByCategory, fetchNotificationLibraryByMasterCode } from '../../state/actions/NotificationMastersAction';
import { fetchPolicySchemeConfiguration, fetchPolicySchemeConfigurations, setPolicyBuilderConfiguration } from '../../state/actions/PolicyLibraryMasterActions';
import { fetchPolicyRuleVariablesByRule, removeRuleFromCompositeRule } from '../../state/actions/PolicyRuleActions';
import { fetchReferenceData, referenceDataTypeSelected } from '../../state/actions/ReferenceDataActions';
import Filter from '../../components/shared/filter';
import MasterDetails from './masterDetails/MasterDetails';
import getReadOnlyFlagPerEnv from '../../util/EnvUtil';

import '../../images/dot.png';
import '../../components/style.css';
import './policy.css';

const isReadOnly = getReadOnlyFlagPerEnv();

const REFDATA_NOTIFICATION_CATEGORY = [
  { text: 'Approval Notification', key: 'fb1f8295-452b-459c-a788-f9be10de26d7' },
  { text: 'Change Control Notification', key: '8cb6e16a-cde2-41eb-ae2b-d8ae1b162911' },
  { text: 'Checkpoint Notification', key: '0fd7ca4c-2c23-4022-a32f-c6bde704f00f' },
  { text: 'Decision Point Notification', key: 'ec41de52-610c-465c-bdfc-bfbbb408a61f' },
  { text: 'Entitlement Notification', key: 'bc2ca397-f085-4e14-a580-3b1a9236c3a5' },
  { text: 'General Notification', key: '8d99bf9d-d36a-463c-b983-af88357d867a' },
  { text: 'Integration Notification', key: '81ee5c90-55b8-4514-9c4e-3742ff6a3a42' },
  { text: 'Project Notification', key: 'cf11b04e-6ade-40b3-8da6-e5a363e3b560' },
  { text: 'Subject Matter Notification', key: 'db66567e-c0ce-4043-8b28-3a65320bed48' },
  { text: 'Task Notification', key: '0757a0e1-394b-4348-944b-d2927e989133' },
  { text: 'Users Notification', key: '3093870f-e9f6-428d-bf83-636836c2dfa6' },
];

class NotificationBuilder extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isBtnActivated: true,

      selNotificationMaster: '',
      selNotificationMasterDesc: '',
      selNotificationMasterCode: '',
      notificationCategoryId: this.props.match.params.notificationCategoryId,
      policySchemeTypeId: this.props.match.params.policySchemeTypeId,
      policySchemeConfigurationId: null,
      policySchemeConfigurationSelectedIndex: 0,

      CurrentDate: '',

      // used by rule picker
      isRulePickerOpen: false,
      selCompositePolicy: null,
      selExistingRuleIds: null,
      actionForRulePicker: null,
      selRuleForEdit: null,

      selRuleForDeletion: null,
      isMasterDetailsPanelOpen: false
    };
  }

  getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    this.setState({ CurrentDate: `${mm}/${dd}/${yyyy}` });
  }

  async componentDidMount () {
    document.title = 'Notification Builder';
    const params = {
      notificationCategoryId: this.state.notificationCategoryId,
    };

    await this.props.fetchNotificationMastersByCategory(params);
    this.getCurrentDate();
    await this.props.fetchReferenceData();

    let selNotificationMasterCode = '';

    if (this.props.notificationMasters && this.props.notificationMasters.length > 0) {
      selNotificationMasterCode = this.props.notificationMasters[0].code;

      const params2 = {
        notificationMasterCode: selNotificationMasterCode,
      };
      await this.props.fetchNotificationLibraryByMasterCode(params2);
    }

    this.setCommonValues(selNotificationMasterCode);
  }

  async handleListItemClickLP (event, selMasterCode) {
    const params = {
      notificationMasterCode: selMasterCode,
    };
    await this.props.fetchNotificationLibraryByMasterCode(params);

    this.setCommonValues(selMasterCode);
  }

  listNotificationMasters = () => {
    const notificationMasters = this.props.notificationMasters ? this.props.notificationMasters : [];
    return notificationMasters.map((master, index) => {
      const isSelected = (this.state.selNotificationMasterCode === master.code);
      return (
        <ListItem
          key={master.id}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(event, master.code)}
          className={this.props.classes.pmItem}>
          <ListItemText
            primary={<Typography type="body2" className={this.props.classes.pmItemText}>{master.code} - {master.title}</Typography>}
            disableTypography
          />
        </ListItem>);
    });
  }

  setCommonValues (selNotificationMasterCode) {
    const name = this.props.notificationMaster && this.props.notificationMaster.name ? this.props.notificationMaster.name : 'TBA';
    const desc = this.props.notificationMaster && this.props.notificationMaster.description ? this.props.notificationMaster.description : 'TBA';

    this.setState((prevState) => ({
      ...prevState,
      selNotificationMaster: name,
      selNotificationMasterDesc: desc,
      selNotificationMasterCode,
      isBtnActivated: true,
    }));
  }

  /* Rule Picker */

  toggleRulePickerPanelForAdd = (selCompositePolicy) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: true, selCompositePolicy, selExistingRuleIds, actionForRulePicker: 'add'
    }));
  }

  toggleRulePickerPanelForEdit = (selCompositePolicy, selRuleForEdit) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: true, selCompositePolicy, selExistingRuleIds, selRuleForEdit, actionForRulePicker: 'edit', selRuleForDeletion: selRuleForEdit
    }));
  }

  dismissRulePickerPanelHandler = () => {
    this.refreshSelCompositeRulesHandler();
    this.setState((prevState) => ({ ...prevState, isRulePickerOpen: false }));
  }

  openDeleteModal = (selCompositePolicy, rRule) => {
    this.setState((prevState) => ({ ...prevState, selCompositePolicy, selRuleForDeletion: rRule }));
  }

  closeDeleteModal = () => {
    this.setState((prevState) => ({ ...prevState, selCompositePolicy: null, selRuleForDeletion: null }));
  }

  handleDeleteRuleFromComposite = async () => {
    // build rule object
    const rule = this.adoptSelectionToRule();
    await this.props.removeRuleFromCompositeRule(this.state.selCompositePolicy.id, rule);
    this.refreshSelCompositeRulesHandler();
    this.setState((prevState) => ({
                          ...prevState,
                          isRulePickerOpen: false,
                          selCompositePolicy: null,
                          selRuleForDeletion: null
                        }));
  }

  adoptSelectionToRule () {
    const rule = {
        ruleId: this.state.selRuleForDeletion.rule.id,
    };

    return rule;
  }

  async refreshSelCompositeRulesHandler () {
    const params = {
      notificationMasterCode: this.state.selNotificationMasterCode,
    };
    await this.props.fetchNotificationLibraryByMasterCode(params);
  }

  // eslint-disable-next-line class-methods-use-this
  getNotificationCategory (id) {
    const res = REFDATA_NOTIFICATION_CATEGORY.filter((v) => v.key === id);
    if (res.length > 0) {
      return res[0].text;
    }
      return 'UNKNOWN***';
  }

  toggleDetails = () => {
       this.setState({ isMasterDetailsPanelOpen: true });
     }

     hideMasterDetailsPanelHandler = () => {
       this.setState({ isMasterDetailsPanelOpen: false });
       this.setCommonValues(this.state.notificationMasterCode);
     }

  render () {
    const { classes } = this.props;

    const policyEntitlementRules = this.props.policyConfigurations && this.props.policyConfigurations.length > 0
        ? this.props.policyConfigurations[0].entitlementRules : [];

    return (
      <Box display="flex">
        <Box>
          <Card variant='outlined' className={classes.card}>
            <CardContent>
              <Typography variant="subtitle1" className={classes.listHeader}>
                Select Notification Master to edit
              </Typography>

              <Accordion className="searchlist">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Notification Master Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filter source="NOTIFICATION" policySchemeId={this.state.notificationCategoryId}></Filter>
                </AccordionDetails>
              </Accordion>
              {this.listNotificationMasters()}
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={4}>
          <Card variant='outlined'>
            <CardContent>
              <div className={classes.headerSection}>
                <Box display="flex">
                  <Box flexGrow={1} >
                    <div className={classes.pmHeader}>Notification Master Builder</div>
                  </Box>
                </Box>
              </div>
              <div className={`${classes.boxed} ${classes.descBottom}`}>
               <Box display="flex" className={classes.titleSection}>
              	 <Box flexGrow={1} >
                <div>
                  <div className={classes.titleHeaderRow}>{this.state.selNotificationMaster}</div>
                  <div className={classes.titleRow}>Last Updated: 19 Aug 2020</div>
                </div>
                 </Box>
                 <Box display="flex" flexDirection="column"
                 style={{ alignItems: 'center', justifyContent: 'center', height: '50px' }} >
                     <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 23 }  }}
                     styles={{ root: { color: 'white' }, rootHovered: { backgroundColor: '#0078d4', color: 'white' } }}
                     onClick={this.toggleDetails} />
                 </Box>
               </Box>
                <div className={classes.grid2}>
                  <div>
                    <div className={classes.statsPolicySegments}>56 DECISIONS | 102 Rules</div>
                    <div className={classes.subtitle}>Description</div>
                    <div className={classes.fieldRow}>
                      <div className={classes.editControlSectionHeader}>{this.state.selNotificationMasterDesc}</div>
                    </div>
                  </div>
                  <div className={classes.grid3}>
                    <div className={classes.divider1}></div>
                    <div>
                      <div className={classes.policySchemesInUse}> NOTIFICATION MASTER SUMMARY</div>
                      <div><label className={classes.notificationType}>Notification Type :</label> {this.getNotificationCategory(this.state.notificationCategoryId)}</div>
                      <div className={classes.policySchemesInUse}>23% All Policy Activities</div>
                      <div className={classes.policySchemesInUse}>17 Segments in use</div>
                      <div className={classes.policySchemesInUse}>61 Rules in use</div>
                    </div>
                  </div>
                </div>
              </div>
              <Box>
                <Grid item xs={12}>
                  <button className={classes.whiteBtn} disabled={isReadOnly} ><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add New PDP</button>
                </Grid>
              </Box>

              {policyEntitlementRules?.map((policy, index) => (
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.icon} />} aria-controls="panel1a-content" id="panel1a-header" className={classes.accordionHeader}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.accHeading}>{policy.policyDecisionPoint.pdpCode}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.accSubHeading}>{policy.policyDecisionPoint.pdpTitle}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box>
                      <Card variant='outlined'>
                        {policy.compositePolicyRules.map((pol) => (
                          <CardContent>
                            <Card className={classes.policyHeading}>
                              <CardContent>
                                <div>
                                  <label className='Policy-Europe-Busin'>Policy: {pol.policyTitle}</label>
                                </div>
                              </CardContent>
                            </Card>

                            <Card variant='outlined' className={classes.carddetailbkg}>
                              <CardContent>
                                {pol.policyRules.map((rule) => (
                                  <div className={classes.ruleBox}>
                                    <Grid container>
                                      <Grid item xs={10}>
                                        <div>
                                          <Typography className={classes.accHeading2}>Rule: {rule.rule.ruleTitle}</Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={2} >
                                        <SettingsRoundedIcon onClick={() => this.toggleRulePickerPanelForEdit(pol, rule)} className={classes.viewMoreLink} ></SettingsRoundedIcon>
                                      </Grid>
                                      <RuleConfigValue mode="read" ruleConfigValues={rule.compositePolicyRuleInstanceConfigurations[0].configurationValue} refdata={this.props.refdata} xxruleVariables={rule.rule.ruleVariables} />
                                    </Grid>
                                    <br />
                                  </div>
                                ))}

                                <div>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                      <button className={classes.whiteBtn} disabled={isReadOnly} onClick={() => this.toggleRulePickerPanelForAdd(pol)} ><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add Rule</button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardContent>
                            </Card>
                          </CardContent>
                        ))}
                      </Card>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <button className={classes.whiteBtn} disabled={isReadOnly} ><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add Decision</button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        </Box>
        {this.state.isRulePickerOpen ? <RulePicker
          action={this.state.actionForRulePicker}
          compositePolicy={this.state.selCompositePolicy}
          existingRuleIds={this.state.selExistingRuleIds}
          selRuleForEdit={this.state.selRuleForEdit}
          isRulePickerPanelOpen={this.state.isRulePickerOpen}
          dismissRulePickerPanel={this.dismissRulePickerPanelHandler}
          literalType={ActionLiteralType.NOTIFICATION}
          refreshSelCompositeRules={this.refreshSelCompositeRulesHandler}
          refdata={this.props.refdata}
          handleDeleteRuleFromComposite={this.handleDeleteRuleFromComposite}
          isReadOnly={isReadOnly}
        /> : null}
        {this.state.isMasterDetailsPanelOpen && this.props.notificationMaster
                 ? <MasterDetails isMasterDetailsPanelOpen={this.state.isMasterDetailsPanelOpen}
                 hideMasterDetailsPanelHandler={this.hideMasterDetailsPanelHandler}
        	         notificationMaster={this.props.notificationMaster}
                 policyConfigurations={this.props.policyConfigurations}
                 isReadOnly={isReadOnly} /> : null}
      </Box>
    );
  }
}

const styles = (theme) => ({
  card: {
    backgroundColor: '#f3f2f1',
    width: 300,
    margin: 0
  },
  root: {
    flexGrow: 1
  },
  headerSection: {
    padding: '16px 0'
  },
  listHeader: {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'SegoeUI',
    marginBottom: '1rem'
  },
  pmItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  pmItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
  titleSection: {
    backgroundColor: '#0078d4',
    height: '50px',
    color: '#fff',
    padding: '16px 33px'
  },
  titleHeaderRow: {
    fontSize: '20px',
    fontWeight: '600'
  },

  titleRow: {
    padding: '8px 0'
  },

  pmHeader: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'PharmaCond',
    color: '#323130'
  },
  editControlSectionHeader: {
    color: 'black',
    fontWeight: '200',
    padding: '0 1.5em 1em 1em',
    width: '100%',
    display: 'inline-block',
    margin: '1px',
    height: '140px'
  },
  boxed: {
    border: '2px solid lightgrey',
    height: '300px'
  },

  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '10px 0 10px 0',
    color: '#323130',
    paddingRight: '1rem'
  },

  accordionHeader: {
    backgroundColor: '#99c5ed',
    color: 'black',
    flexDirection: 'row-reverse',
    height: '84px'
  },
  icon: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    fontSize: 'large !important'
  },
  accHeading: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: '#323130'
  },
  policyHeading: {
    width: 'auto',
    height: '74px',
    marginTop: '0rem',
    borderRadius: '2px',
    backgroundColor: '#cce2f6'
  },
  accSubHeading: {
    width: 'auto',
    height: '20px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#555555'
  },
  accHeading2: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: '#323130'
  },
  carddetailbkg: {
    backgroundColor: '#f3f2f1',
    color: 'black',
    marginTop: '1rem'
  },
  descBottom: {
    paddingBottom: '10px'
  },

  ruleBox: {
    marginTop: '2rem',
    borderBottom: 'solid 1px #bdbdbd',
    marginBottom: '2rem'
  },
  addTaskLinkIcon: {
    border: '0',
    backgroundColor: null,
    color: 'black',
    fontSize: '8px',
    cursor: 'grab',
    float: 'left',
    marginRight: '1rem'
  },
  grid2: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridTemplateColumns: '7fr 3fr',
    gridGap: '20px',
    padding: '16px 0 0 16px',
  },

  policySchemesInUse: {
    width: '250px',
    height: '20px',
    margin: '20px 0 0 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#323130',
  },

  grid3: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 9fr',
  },

  divider1: {
    height: '160px',
    width: '1px',
    border: 'solid 1px #cdcdcd',
    backgroundColor: '#f3f2f1',
  },
  subtitle: {
    width: '700px',
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
  },
  subtitle1: {
    color: '#323130'
  },
  fieldRow: {
    padding: '8px 0',
  },

  statsPolicySegments: {
    width: '500px',
    height: '20px',
    margin: '20px 0px 30px 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#555555',
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '60%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
});

NotificationBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    notificationMasters: state.notificationMastersData.notificationMasters,
    notificationMaster: state.notificationMastersData.notificationMaster,
    policyConfigurations: state.notificationMastersData.policyConfigurations,

    policySchemeConfigurations: state.policyLibraryBuilderData.policySchemeConfigurations,
    result: state.ui.result,
    refdata: state.refData.referenceData,
    selectedListValues: state.sharedMasterData.selectedItems,
    policySchemeConfigurationsFiltered: state.policyLibraryBuilderData.policySchemeConfigurationsFiltered,
    referenceDataTypes: state.refData.referenceDataTypes,
    ruleVariables: state.policyRuleData.ruleVariables
  });

const connected = connect(mapStateToProps, {
  fetchNotificationMastersByCategory,
  fetchNotificationLibraryByMasterCode,

  fetchPolicySchemeConfiguration,
  fetchReferenceData,
  referenceDataTypeSelected,
  fetchPolicySchemeConfigurations,
  setPolicyBuilderConfiguration,
  removeRuleFromCompositeRule,
  fetchPolicyRuleVariablesByRule
})(AuthProvider(NotificationBuilder));

export default withStyles(styles)(connected);
