import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { blue, lightBlue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import history from '../../history';
import '../../components/style.css';

class CheckPointMasterCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      code: props.code,
      title: props.title,
      description: props.description,
      show: false
    };
  }

  openEditPage = () => {
    history.push('/checkPointMasterConsole/checkPointMasterEdit', { code: this.state.code });
  }

  onHide = () => {
    this.setState({ show: false });
  }

  onShow = () => {
    this.setState({ show: true });
  }

  render () {
    const { classes } = this.props;
    let maxWords = 50;
    let { description } = this.state;

    if (this.props.description && this.state.description.split(' ').length >= maxWords) {
      description = this.state.description.split(' ').filter((word, idx) => idx < maxWords).join(' ');
      description += '...';
    }

    return (
      <Box m={1}>

        <Card className={classes.card} variant='outlined' style={{ width: '412px' }}>
          <CardActionArea component="div">
            <CardHeader className={this.props.classes.cardHeaderSection}
              title={<Typography className={classes.cardHeader}>
                {this.state.title}
              </Typography>} />
            <CardContent>

              <button className={classes.editButton} onClick={() => { this.openEditPage() }}>Edit Master</button>
              <div className={classes.subtitle}>CHECKPOINT MASTER SUMMARY</div>
              <div className={classes.fieldRow}>
                <span className={classes.fieldName}>Updated:</span>
              </div>
              <div className={classes.fieldRow}>
                <span className={classes.fieldName}>Project Phase:</span>
              </div>
              <div className={classes.fieldRow}>
                <span className={classes.fieldName}>Related Decision Point:</span>
              </div>
              <hr className={classes.sepLine} />
              <div className={classes.subtitle}>GLOBAL DESCRIPTION</div>
              <Typography className={classes.descriptionField} color="textSecondary" gutterBottom>
                {ReactHtmlParser(description)}
              </Typography>
              <hr className={classes.sepLine} />
              <div className={classes.viewLink}><button onClick={this.onShow} className={classes.viewMoreLink}>View Full Summary</button></div>
              {this.state.show && (
                <div className={classes.modal}>
                  <Box display="flex">
                    <Box flexGrow={1} >
                      <div></div>
                    </Box>
                    <Box>
                      <div></div>
                    </Box>
                    <Box>
                      <button onClick={this.onHide} className={classes.closeButton}>X</button>
                    </Box>
                    <Box>

                    </Box>
                  </Box>
                  <div className={classes.popUpTitle}>{this.state.title}</div>
                  <hr className={classes.sepLine} />
                  <div className={classes.subtitle}>CHECKPOINT MASTER SUMMARY</div>
                  <div className={classes.fieldRow}>
                    <span className={classes.fieldName}>Updated:</span>
                  </div>
                  <div className={classes.fieldRow}>
                    <span className={classes.fieldName}>Project Phase:</span>
                  </div>
                  <div className={classes.fieldRow}>
                    <span className={classes.fieldName}>Related Decision Point:</span>
                  </div>
                  <hr className={classes.sepLine} />
                  <div className={classes.subtitle}>GLOBAL DESCRIPTION</div>
                  <Typography gutterBottom>
                    {ReactHtmlParser(this.state.description)}
                  </Typography>

                </div>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    );
  }
}

const styles = (theme) => ({
  root: {
    // maxWidth: 500,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: '#ffffff',
    color: lightBlue[500],
  },
  cardHeader: {
    fontFamily: 'PharmaCond',
    fontSize: '24px',
    color: '#323130',
    fontWeight: '600',
    },
   cardHeaderSection: {
     padding: '16px 0 0 16px'
   },
  card: {
    borderColor: blue[200],
    width: 200,
    color: '#000',
    margin: '8px 16px'
  },
  editButton: {
    backgroundColor: '#0078d4',
    border: 'none',
    color: '#fff',
    padding: '5px 12px 7px 16px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    borderRadius: '2'
  },

  subtitle: {
    padding: '16px 0',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },

  fieldRow: {
   padding: '8px 0',
  },

  fieldName: {
  fontWeight: '600'
  },

  sepLine: {
     margin: '16px 0',
     color: '#bdbdbd'
  },

  viewLink: {
  color: '#006ed2'

  },

  descriptionField: {
  color: '#000000',
  listStylePosition: 'inside',
  height: '152px',
  paddingBottom: '10px',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },

  closeButton: {
      backgroundColor: '#fff',
      border: '0',
      color: '#605e5c',
      fontSize: '18px',
      padding: '0 16px',
      marginRight: '16px',
      cursor: 'pointer'
  },

  popUpTitle: {
    color: '#323130',
    fontSize: '20px',
    fontWeight: '600'
  },

  viewMoreLink: {
      border: '0',
      backgroundColor: '#fff',
      color: '#006ed2',
      fontSize: '14px'

 }

});

CheckPointMasterCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckPointMasterCard);
