import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AuthProvider from "../../auth/AuthProvider";
import FilterOptionTypeLiteral from "../../../constants/FilterLiterals";
import Filterv2 from "../../filterv2/Filterv2";
/**
 * (1) requestedFilterType: enum for filtering, string literal
 * (2) filterOptions: whole filter selection options,
 * {contentFilters:[
    { filterType: "Entity", items: [] },
    { filterType: "Region", items: [] },
    { filterType: "Entity Type", items: [] }]
  }
 * (3) selectedFilters: current filter selection,
    { filterType: "Entity", items: [] },
    { filterType: "Region", items: [] },
    { filterType: "Entity Type", items: [] }]
 *
 * (4) itemList: all items after filtering
 * (5) selectedItem: item passed in this builder screen
 */
const LeftPanelFilterAndDataList = ({
  classes,
  requestedFilterType,
  filterOptions,
  filterOnclickHandler,
  selectedFilters,
  itemList,
  listItemClickHandler,
  selectedItem,
}) => {
  const filterLiterals = FilterOptionTypeLiteral.filter(
    (value) => value.filterType === requestedFilterType
  )[0];

  const [dupSelectedItem, setDupSelectedItem] = useState(selectedItem || null);
  const hasDataToFilter = itemList && itemList.length > 0;

  const itemClickHandler = (item) => {
    setDupSelectedItem(item);
    listItemClickHandler(item);
  };

  return (
    <Box display="flex">
      <Box>
        <Card variant="outlined" className={classes.filterCard}>
          <CardContent>
            <Typography variant="h6" className={classes.filterCardHeader}>
              {filterLiterals.title}
            </Typography>
            <Accordion defaultExpanded={false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{filterLiterals.subTitle}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Filterv2
                  filterType={filterLiterals.filterType}
                  filterOptions={filterOptions}
                  applyFilterSelectionsHandler={(filterSelections) => filterOnclickHandler(filterSelections)}
                  initialSelections={selectedFilters}
                  showSelectedItems={true}
                />
              </AccordionDetails>
            </Accordion>
            {hasDataToFilter && itemList.map((item, index) => {
              const isSelected = selectedItem ? (dupSelectedItem.id === item.id) : false;
              return (
                <ListItem
                  key={`list-${index}`}
                  button
                  selected={isSelected}
                  onClick={() => itemClickHandler(item)}
                  className={classes.pmItem}
                >
                  <ListItemText
                    primary={
                      <Typography type="body2" className={classes.pmItemText}>
                        {item.value ? item.value : item.type}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
              );
            })}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

const styles = () => ({
  filterCard: {
    backgroundColor: "#f3f2f1",
    width: 300,
    margin: 0,
  },
  filterCardHeader: {
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "SegoeUI",
    marginBottom: "1rem",
  },
  pmItemText: {
    padding: "0 16px",
    fontWeight: "600",
    fontSize: "14px",
    fontFamily: "SegoeUI",
    wordWrap: "break-word",
  },
  pmItem: {
    color: "#006ed2",
    fontSize: "14px",
    fontWeight: "600",
    backgroundColor: "#ffffff",
    margin: "16px 0",
  },
});

LeftPanelFilterAndDataList.propTypes = {
  classes: PropTypes.object.isRequired,
  requestedFilterType: PropTypes.string,
  filterOptions: PropTypes.object,
  filterOnclickHandler: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  itemList: PropTypes.array,
  listItemClickHandler: PropTypes.func.isRequired,
};

export default withStyles(styles)(AuthProvider(LeftPanelFilterAndDataList));
