/* eslint-disable global-require */
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  Box, CardHeader
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  initializeIcons, IconButton
} from '@fluentui/react';
import FilterOptionTypeLiteral from '../../../constants/FilterLiterals';
import HelpTextDetail from './HelpTextDetail';
import AuthProvider from '../../../components/auth/AuthProvider';
import { fetchHelpTextMasters } from '../../../state/actions/HelpTextActions';
import Filterv2 from '../../../components/filterv2/Filterv2';
import getReadOnlyFlagPerEnv from '../../../util/EnvUtil';

let isReadOnly = false;
class HelpTextBuilder extends React.Component {
  constructor(props) {
    super(props);
    const filterLiterals = FilterOptionTypeLiteral.filter((value) => value.filterType === 'HELP_TEXT')[0];
    this.state = {
      filterLiterals,
      selectedIndex: 0,
      selectedhelpText: null,
      isHelpTextDetailsPanelOpen: false,
      isSaved: false,
      filterSelections: [],
      selectedhelpTextDetails: null,
    };
    initializeIcons();
  }

  async componentDidMount() {
    document.title = 'Help Text Builder';
    await this.props.fetchHelpTextMasters();
  }

  listHelpTextLP = () => {
    const helpTextList = this.reduceByApplyingFilter();
    helpTextList.sort((a, b) => (a.subjectMatter - b.subjectMatter));
    return helpTextList.map((helpText, index) => {
      const isSelected = (this.state.selectedhelpText ? this.state.selectedhelpText.id === helpText.id : 0);
      return (
        <ListItem
          key={`index-${helpText.id}`}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(event, index, helpText)}
          className={"tmItem"}>
          <ListItemText
            key={`index-${helpText.id}text`}
            primary={<Typography type="body2" className={"tmItemText"}>{helpText.fieldName}-{helpText.formName}</Typography>}
            disableTypography
          />
        </ListItem>);
    });
  }

  createFilterOptionsForHelpText = (helpTextData) => {
    const entries = [];
    // eslint-disable-next-line array-callback-return
    helpTextData.map((item) => {
      const findItem = entries.find((x) => x.value === item.formName);
      if (!findItem) {
        entries.push({
          key: item.id,
          value: item.formName,
        });
      }
    });
    const filterEntries = {
      filterType: 'Form Name',
      entries: [...entries]
    };
    return filterEntries;
  }

  createFilterOptions() {
    if (!this.props.helpTextData) return {};
    const filter = this.createFilterOptionsForHelpText(this.props.helpTextData);
    const filterEntries = {
      contentFilters: [filter]
    };
    return filterEntries;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.helpTextData !== nextProps.helpTextData) {
      return { helpTextData: nextProps.helpTextData };
    }
    return null;
  }

  reduceByApplyingFilter() {
    const { filterSelections } = this.state;
    let helpTexts = [];
    if (filterSelections.length === 0 || filterSelections[0].items.length === 0) {
      helpTexts = [];
      this.setState({ filterSelections: [] });
    } else {
      const compareIfHelpTextExists = (helpTextId) => filterSelections[0].items.some((ch) => ch.value === helpTextId);
      helpTexts = this.props.helpTextData.filter((help, index) => compareIfHelpTextExists(help.formName));
    }
    return helpTexts;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (value) => {
    this.setState({ open: false });
  };

  handleListItemClickLP = (event, index, helpText) => {
    this.setState({ selectedhelpText: helpText, selectedIndex: index });
  }

  closeDialog = () => {
    this.setState({ isSaved: false });
  }

  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  handleHelpTextDetails = () => {
    this.setState({ isHelpTextDetailsPanelOpen: true, selectedhelpTextDetails: this.state.selectedhelpText });
  }

  hideDetailsPanelHandler = () => {
    this.setState({ isHelpTextDetailsPanelOpen: false });
    this.props.fetchHelpTextMasters();
    const updateHelpText = this.props.helpTextData.filter((item) => item.id === this.state.selectedhelpTextDetails?.id)[0];
    this.setState({ selectedhelpText: updateHelpText });
  }

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState((prevState) => ({ ...prevState, filterSelections }));
  }

  render() {
    const { classes } = this.props;
    const { selectedhelpText, isHelpTextDetailsPanelOpen, filterOptionsKey, filterSelections } = this.state;
    const { filterType, subTitle } = this.state.filterLiterals;

    let currentHelpText = '';
    if (filterSelections.length > 0) {
      if (this.state.selectedhelpText === null) { currentHelpText = this.props.helpTextData?.filter((item) => item.formName === filterSelections[0]?.items[0]?.value)[0]; }
      else { currentHelpText = selectedhelpText; }
    }

    const boxtitle = `${currentHelpText?.fieldName}  |  ${currentHelpText?.formName}`;
    const OliLogoSuccess = require('../../../images/oli-success.svg');
    isReadOnly = getReadOnlyFlagPerEnv();

    return (
      <Box display="flex">
        <Box >
          <Card variant='outlined' className={classes.card}>
            <CardHeader className={classes.cardHeaderSection}
              title={"Help Text Type"}   >
            </CardHeader>
            <CardContent>
              <br />
              <Accordion className={classes.filterlist} expanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{subTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filterv2 key={filterOptionsKey} filterType={filterType} filterOptions={this.createFilterOptions()} applyFilterSelectionsHandler={this.applyFilterSelectionsHandler} initialSelections={filterSelections} showSelectedItems={true} ></Filterv2>
                </AccordionDetails>
              </Accordion>
              <hr className={"sepLine"} />
              {this.state.filterSelections.length > 0
                ? <div style={{ marginTop: '1rem' }}>
                  {this.listHelpTextLP()}
                </div>
                : ''}
            </CardContent>
          </Card>
        </Box>

        <Box flexGrow={4} style={{ maxWidth: "1200px" }}>
          <Card variant='outlined'>
            <CardContent>
              <div className={"headerSection"}>
                <Box display="flex">
                  <Box flexGrow={1} >
                    <div className={"tmHeader"}>Help Text Builder</div>
                  </Box>
                </Box>
                <hr className={"sepLine"} />
              </div>
              {this.state.filterSelections.length > 0
                ? <div className={`${"boxed"} ${"descBottom"}`}>
                  <Box display="flex" className={"titleSection"}>
                    <Box flexGrow={1} >
                      <div>
                        <div className={"titleHeaderRow"}>{currentHelpText ? boxtitle : ""}</div>
                        <div className={"titleRow"}>Last Updated: TBA</div>
                      </div>
                    </Box>
                    <Box display="flex" flexDirection="column"
                      style={{ alignItems: 'center', justifyContent: 'center', height: '50px' }} >
                      <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 23 } }}
                        styles={{
                          root: { color: 'white' },
                          rootHovered: { backgroundColor: '#0078d4', color: 'white' }
                        }}
                        onClick={this.handleHelpTextDetails} />
                    </Box>
                  </Box>
                  <div className="row">
                    <div className={"descAlign"}>
                      <div className="col-sm-4">
                        <div className={"descHeader"}>HELP TEXT</div>
                        <div className={"upperSpace"}>
                          <div className={classes.editControlSectionHeader} dangerouslySetInnerHTML={{ __html: currentHelpText ? currentHelpText.guidanceText : "" }}></div>
                        </div>
                      </div>
                    </div>
                    <div className={"notificationAlign"}>
                      <div className="col-sm-4">
                        <br />
                        <div >HELP TEXT SUMMARY</div>
                        <div>
                          <div>
                            <p className="subtitle" > Field Reference #</p>
                            <p variant="subtitle1" > {currentHelpText?.fieldRef} </p>
                          </div>
                          <div >
                            <p className="subtitle" >Form Name</p>
                            <p variant="subtitle1" > {currentHelpText?.formName} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <div className={classes.msgcontainer}>
                  <Box display="flex">
                    <Box>
                      <img className='errorDialogHeaderLogo' src={OliLogoSuccess} alt={'olilogo'} />
                    </Box>
                    <Box>
                      <p className={classes.subtitleMsg}>You have no Help Text Selected</p>
                    </Box>
                  </Box>
                  <br />
                  <div >
                    <p className={classes.subtitle} >Handy Hints:</p>
                    <div>
                      <ul style={{ paddingLeft: '20px' }} >
                        <li>Use the left hand filter menu to select a Form Name to see the available help texts</li>
                        <br />
                        <li>Multiple filter options can be selected</li>
                        <br />
                        <li>Different Forms can contain Help Texts with the same name</li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </CardContent>
          </Card>
        </Box>
        {isHelpTextDetailsPanelOpen ? <HelpTextDetail open={isHelpTextDetailsPanelOpen}
          onClose={this.hideDetailsPanelHandler}
          selectedHelpText={this.state.selectedhelpTextDetails}
          isReadOnly={isReadOnly}
        /> : ""}
      </Box>
    );
  }
}

const styles = (theme) => ({
  card: {
    backgroundColor: '#f3f2f1',
    width: 320,
    margin: 0
  },
  rootBackground: {
    height: '100%',
    backgroundColor: '#f3f2f1'
  },
  root: {
    flexGrow: 1
  },
  cardHeaderSection: {
    padding: '16px 0 0 20px',
    fontSize: '20px',
    fontFamily: 'PharmaCond',
    fontWeight: '700',
  },
  filterlist: {
    width: '290px !important'
  },
  subtitleMsg: {
    width: '700px',
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '20px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px'
  },
  msgcontainer: {
    paddingLeft: '10px'
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  subtitle: {
    color: '#323130',
    padding: '16px 0',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },
  headerSection: {
    padding: '16px 0'
  },
  tmItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  tmItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
  titleSection: {
    backgroundColor: '#0078d4',
    height: '50px',
    color: '#fff',
    padding: '16px 33px'
  },
  titleHeaderRow: {
    fontSize: '20px',
    fontWeight: '600'
  },

  titleRow: {
    padding: '8px 0'
  },

  tmHeader: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'PharmaCond',
    color: '#323130'
  },
  descAlign: {
    width: '60%',
    float: 'left',
    overflowWrap: 'break-word',
    height: '120px'
  },
  upperSpace: {
    paddingTop: '10px',
    height: '100%'
  },
  descHeader: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '18px',
    padding: '20px 0 0 10px'
  },
  editControlSectionHeader: {
    color: 'black',
    fontWeight: '200',
    padding: '0 1.5em 1em 1em',
    display: 'inline-block',
    margin: '1px',
    height: '140px',
    fontFamily: 'SegoeUI',
    width: '100%'
  },
  editHeader: {
    padding: '20px 0px 0px 10px',
    color: 'black',
    fontWeight: '600',
    fontSize: '18px'
  },
  boxed: {
    border: '2px solid lightgrey',
    height: '300px'
  },
  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '10px 0 10px 0',
    color: '#323130',
    paddingRight: '1rem'
  },
  accordionHeader: {
    backgroundColor: '#99c5ed',
    color: 'black',
    flexDirection: 'row-reverse',
    height: '84px'
  },
  icon: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    fontSize: 'large !important'
  },
  carddetailbkg: {
    backgroundColor: '#f3f2f1',
    color: 'black',
    marginTop: '1rem'
  },
  descBottom: {
    paddingBottom: '10px'
  },
});

HelpTextBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  helpTextData: state.helpTextData.helpTextMasters,
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  fetchHelpTextMasters,
})(AuthProvider(HelpTextBuilder));

export default withStyles(styles)(connected);
