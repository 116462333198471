import React from "react";
import { withStyles } from "@material-ui/styles";

const DescriptionContent = ({ classes, descriptionTitle, descriptionText }) => (
  <div className={classes.descriptionSection}>
    <div className={classes.descriptionTitle}>{descriptionTitle}</div>
    <div className={classes.descriptionText}>{descriptionText}</div>
  </div>
);

const styles = () => ({
  descriptionSection: {
    padding: "24px 15px",
  },
  descriptionTitle: {
    fontWeight: "600",
  },
  descriptionText: {
    paddingTop: "16px",
    width: "500px",
  }
});

export default withStyles(styles)(DescriptionContent);
