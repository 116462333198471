import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import "../../../components/style.css";
import { Stack, initializeIcons, IconButton, Link } from "@fluentui/react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Tag from "../shared/Tag";
import history from '../../../history';
import checkIfFiltered from '../shared/FilterUtil'
import { downloadChangeSetSync } from '../../../state/actions/AdminChangeSetBuilderActions';

const GetPencilIconButton = (handleClick) => (
  <IconButton
    iconProps={{ iconName: "Edit" }}
    onClick={() => handleClick()}
    styles={{
      root: { height: "auto" },
      icon: { fontSize: "20px", margin: "0 0 0 0" },
      rootHovered: { backgroundColor: "#fff" },
    }}
  ></IconButton>
);

const GetScriptLabel = (status, handleClick) => {
  return status !== 'OPEN' ? (
    <Link onClick={handleClick}>Export Script 
      <IconButton 
        iconProps={{ iconName: 'Download', style: { fontSize: 14 }  }} 
        styles={{ 
          root: { color: 'blue' }, 
          rootHovered: { backgroundColor: '#0078d4', color: 'blue' } 
        }} />
    </Link>
  ) : (
    <>N/A</>
  );
};

const getChangeSetVerification = (verified, classes) => {
  return verified ? (
    <>
      <CheckCircleIcon className={classes.verificationCompletionIcon} />
      Verified for publication
    </>
  ) : (
    <>
      <Cancel className={classes.verificationInCompletionIcon} /> To be verified
    </>
  );
};

const getPublishedDate = (publishedDateSting) => {
  return publishedDateSting ? formatDate(new Date(publishedDateSting), {
    day: "numeric",
    month: "short",
    year: "numeric",
  }) : "TBD";
};

const formatDate = (date, format) => {
  return date.toLocaleDateString("en-AU", format);
};

const ChangeSetManagemenDetails = (props) => {
  const { classes, changeSets, selectedFilterOptions } = props;
  
  const download = async (changeSetid) => {
    const result = await downloadChangeSetSync(changeSetid)
    const a = document.createElement('a');
    const blob = new Blob([result.data], { type: 'application/octet-stream' });
    a.href = window.URL.createObjectURL(blob);
    a.download = 'liquibase.yaml';
    a.click();
  }

  const CHANGE_SET_BUILDER_URL = "/adminChangeConsole/changeSetBuilder/"
  initializeIcons();
  return (
    <>
      <Stack horizontal horizontalAlign="start" className={classes.stackHeader}>
        <Stack.Item className={classes.changeSetName}>
          Change Set Name
        </Stack.Item>
        <Stack.Item className={classes.changeSetStatus}>Status</Stack.Item>
        <Stack.Item className={classes.changeSetEnv}>Environments</Stack.Item>
        <Stack.Item className={classes.changeSetPublicationDate}>
          Publication Date
        </Stack.Item>
        <Stack.Item className={classes.changeSetReleaseTag}>
          Release Tag
        </Stack.Item>
        <Stack.Item className={classes.changeSetVerification}>
          Change Set Verification
        </Stack.Item>
        <Stack.Item className={classes.changeSetScript}>Script</Stack.Item>
        <Stack.Item className={classes.changeSetLinkToBuilder}>
        </Stack.Item>
      </Stack>
      {changeSets?.map((changeItem, index) => (
        checkIfFiltered(selectedFilterOptions, 0, changeItem.status) && checkIfFiltered(selectedFilterOptions, 2, changeItem.releaseTagName) &&  
        <Stack key={`changeItems${changeItem.id}`}>
          <Stack
            horizontal
            horizontalAlign="start"
            className={classes.stackBody}
          >
            <Stack.Item className={classes.changeSetName}>
              {changeItem.name}
            </Stack.Item>
            <Stack.Item className={classes.changeSetStatus}>
              <Tag
                tagName={changeItem.status}
                tagType={changeItem.status === "OPEN" ? "dark" : ""}
              />
            </Stack.Item>
            <Stack.Item className={classes.changeSetEnv}>TBD</Stack.Item>
            <Stack.Item className={classes.changeSetPublicationDate}>
              {getPublishedDate(changeItem.publishedDate)}
            </Stack.Item>
            <Stack.Item className={classes.changeSetReleaseTag}>
              {changeItem.releaseTagName ? changeItem.releaseTagName : "TBD"}
            </Stack.Item>
            <Stack.Item className={classes.changeSetVerification}>
              {getChangeSetVerification(changeItem.verified, classes)}
            </Stack.Item>
            <Stack.Item className={classes.changeSetScript}>
              {GetScriptLabel(changeItem.status, () => download(changeItem.id))}
            </Stack.Item>
            <Stack.Item className={classes.changeSetLinkToBuilder}>
              {GetPencilIconButton(() => history.push(`${CHANGE_SET_BUILDER_URL+changeItem.id}`))}
            </Stack.Item>
          </Stack>
          <hr className={classes.sepLine} />
        </Stack>
      ))}
    </>
  );
};

const styles = (theme) => ({
  stackHeader: {
    padding: "24px 16px 8px 16px",
    "& .ms-StackItem": {
      fontWeight: "bold",
    },
  },
  stackBody: {
    fontWeight: "normal",
    padding: "18px 16px 20px 16px",
  },
  sepLine: {
    borderBottom: "solid 1px #dcdcdc",
    borderTop: "solid 0px #dcdcdc",
    borderLeft: "solid 0px #dcdcdc",
    borderRight: "solid 0px #dcdcdc",
    height: "0px",
  },
  verificationCompletionIcon: {
    border: "0",
    backgroundColor: "null",
    color: "#00aa23",
    fontSize: "14px",
    cursor: "grab",
    float: "left",
    marginRight: "6px",
  },
  verificationInCompletionIcon: {
    border: "0",
    backgroundColor: "null",
    color: "black",
    fontSize: "14px",
    cursor: "grab",
    float: "left",
    marginRight: "6px",
  },
  changeSetName: {
    width: "10%",
  },
  changeSetStatus: {
    width: "10%",
  },
  changeSetEnv: {
    width: "20%",
  },
  changeSetPublicationDate: {
    width: "10%",
  },
  changeSetReleaseTag: {
    width: "10%",
  },
  changeSetVerification: {
    width: "20%",
  },
  changeSetScript: {
    width: "15%",
  },
  changeSetLinkToBuilder: {
    width: "5%",
  },
});

ChangeSetManagemenDetails.propTypes = {
  changeSets: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  selectedFilterOptions: PropTypes.array.isRequired,
};

export default withStyles(styles)(ChangeSetManagemenDetails);
