import {
  FETCH_ALL_TABLE_MAPPINGS,
  FETCH_ALL_FUNCTIONS,
  FETCH_ALL_EVENT_TYPES,
  FETCH_NOTIFICATION_ROLE_MAPPINGS,
  FETCH_ALL_CHANGE_TYPE_EVENT_TYPE_MAPPINGS,
  FETCH_ALL_REQUIRED_FIELDS_DECISION_POINT_TYPE_MAPPINGS,
  FETCH_ALL_MANAGED_FIELDS,
  FETCH_ALL_MANAGED_FIELDS_DECISION_POINT_TYPE_MAPPINGS,
  FETCH_ALL_REQUIRED_FIELDS_CHECKPOINT_TYPE_MAPPINGS,
  FETCH_ALL_PRR_CRITERIA_CATEGORIES,
  FETCH_ALL_PRR_CRITERIAS,
  FETCH_ALL_PRR_CRITERIA_ENTITIES,
  FETCH_ALL_PRR_CRITERIA_TAGS,
  FETCH_ALL_PRR_TAG_VALUES
} from "../../constants/ActionTypes";

const INITIAL_STATE = {
  tables: [],
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TABLE_MAPPINGS:
      return {
        ...state,
        tables: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_FUNCTIONS:
      return {
        ...state,
        functions: { ...action.payload },
        error: null,
      };
    case FETCH_NOTIFICATION_ROLE_MAPPINGS:
      return {
        ...state,
        roleMappings: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_EVENT_TYPES:
      return {
        ...state,
        eventTypes: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_CHANGE_TYPE_EVENT_TYPE_MAPPINGS:
      return {
        ...state,
        changeEventTypes: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_REQUIRED_FIELDS_DECISION_POINT_TYPE_MAPPINGS:
      return {
        ...state,
        requiredFieldsDPTypes: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_MANAGED_FIELDS:
      return {
        ...state,
        managedFields: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_MANAGED_FIELDS_DECISION_POINT_TYPE_MAPPINGS:
      return {
        ...state,
        dpTypeManagedFields: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_REQUIRED_FIELDS_CHECKPOINT_TYPE_MAPPINGS:
      return {
        ...state,
        cpTypeRequiredFields: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_PRR_CRITERIA_CATEGORIES:
      return {
        ...state,
        criteriaCategories: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_PRR_CRITERIAS:
      return {
        ...state,
        criterias: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_PRR_CRITERIA_ENTITIES:
      return {
        ...state,
        criteriasEntities: { ...action.payload },
        error: null,
      };
    case FETCH_ALL_PRR_CRITERIA_TAGS:
      return {
        ...state,
        criteriasTags: { ...action.payload },
        error: null,
       };
    case FETCH_ALL_PRR_TAG_VALUES:
       return {
       ...state,
       tagValues: { ...action.payload },
       error: null,
      };
    default:
      return state;
  }
};
