import {
 IconButton, Stack, DefaultButton, PrimaryButton
} from "@fluentui/react";
import { withStyles } from "@material-ui/styles";
import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { createReferenceDataValue } from "../../../state/actions/ReferenceDataActions";
import envReadOnlyFlagContext from "../../../context/adminContext";

const AddNewRefDataTypeValueRecord = ({ refDataType, classes }) => {
  const context = useContext(envReadOnlyFlagContext);
  const { isReadOnly } = context;
  const [addNewValue, setAddNewValue] = useState(false);
  const [newRefDataValue, setNewRefDataValue] = useState("");
  const [newRefDataSortOrder, setNewDataSortOrder] = useState("");
  const dispatch = useDispatch();

  const cancelAddingNewRefDataHandler = () => {
    setAddNewValue(false);
    setNewRefDataValue("");
    setNewDataSortOrder("");
  };

  const addNewRefDataHandler = () => {
    dispatch(createReferenceDataValue(newRefDataValue, refDataType, newRefDataSortOrder));
    cancelAddingNewRefDataHandler();
  };

  const addIconButtonStyle = {
    root: { paddingLeft: "0px" },
    icon: { marginLeft: "0px", fontSize: 23, color: "#555" }
 };

  return (
    <Stack className={classes.newRefDataValue}>
      <Stack className={classes.title}>Add New Reference Data Value</Stack>
      {!addNewValue ? (
        <Stack horizontal horizontalAlign="start" className={classes.stackBody} verticalAlign="center">
            <Stack.Item>
              <IconButton iconProps={{ iconName: 'AddTo' }} styles={addIconButtonStyle} onClick={() => setAddNewValue(true)} disabled={isReadOnly}/>
            </Stack.Item>
            <Stack.Item>Add New Value</Stack.Item>
        </Stack>
      ) : (
        <Stack className={classes.newRefDataInputSection}>
          <Stack horizontal horizontalAlign="start" gap={"5%"} className={classes.inputTableTitle}>
            <Stack.Item className={classes.newRefDataTableField}>Reference Data Value</Stack.Item>
            <Stack.Item className={classes.newRefDataTableField}>Add a Sort Order</Stack.Item>
          </Stack>
          <Stack horizontal horizontalAlign="start" gap={"5%"}>
            <Stack.Item className={classes.newRefDataTableField}>
              <input className={classes.newRefDataInput}
                placeholder="Enter Data Value"
                onChange={(e) => setNewRefDataValue(e.target.value)}
                value={newRefDataValue}
              />
            </Stack.Item>
            <Stack.Item className={classes.newRefDataTableField}>
              <input className={classes.newRefDataInput}
                placeholder="Enter a Sort Order(number)"
                onChange={(e) => setNewDataSortOrder(parseInt(e.target.value))}
                type="number"
                value={newRefDataSortOrder}
              />
            </Stack.Item>
          </Stack>
          <hr className={classes.dividerLine} />
          <Stack horizontal horizontalAlign="end" gap={16}>
            <Stack.Item>
              <DefaultButton text="Cancel" onClick={cancelAddingNewRefDataHandler}/>
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton text="Add Value" onClick={addNewRefDataHandler}/>
            </Stack.Item>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const styles = () => ({
  newRefDataValue: {
    margin: "0 0 35px 0",
    padding: "16px 16px 5px 16px",
    borderRadius: "2px",
    backgroundColor: "#f6f6f6",
    width: "100%",
  },
  title: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#323130",
  },
  stackBody: {
    height: "46px",
    marginTop: "20px !important",
  },
  newRefDataHeader: {
    "& .ms-StackItem": {
      fontWeight: "bold",
    },
  },
  newRefDataInputSection: {
    marginTop: "16px !important",
    marginBottom: "19px !important",
  },
  inputTableTitle: {
    "& .ms-StackItem": {
      fontWeight: "bold",
    },
  },
  newRefDataTableField: {
    width: "30%"
  },
  newRefDataInput: {
    width: "100%",
    height: "20px",
    marginTop: "5px",
    padding: "5px 8px 7px",
    borderRadius: "2px",
    border: "solid 1px #a19f9d",
    backgroundColor: "#fff",
  },
  dividerLine: {
    borderBottom: "solid 1px #dcdcdc",
    borderTop: "solid 0px #dcdcdc",
    borderLeft: "solid 0px #dcdcdc",
    borderRight: "solid 0px #dcdcdc",
    margin: "16px 0px !important",
    height: "0px",
  },
});

export default withStyles(styles)(AddNewRefDataTypeValueRecord);
