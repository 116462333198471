import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
 DefaultButton, FontWeights, getTheme, IconButton, initializeIcons, Modal, PrimaryButton, Separator, Stack, TextField
} from '@fluentui/react';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import Typography from '@material-ui/core/Typography';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { withStyles } from '@material-ui/styles';
import Cancel from '@material-ui/icons/Cancel';
import AuthProvider from '../../../../components/auth/AuthProvider';
import '../../../../images/dot.png';
import './AdminChangeSet.css';
import {
 fetchAdminChangeSet, fetchAdminChangeMasters, addChangeSet, addChangeItemToChangeSet, removeChangeItemFromChangeSet
} from '../../../../state/actions/AdminChangeMasterActions';

import getReadOnlyFlagPerEnv from  '../../../../util/EnvUtil';

const options = [
  { key: 'CREATE', text: 'Create a new Change Set' },
  { key: 'ADD', text: 'Add to an existing Change Set' },
  { key: 'REMOVE', text: 'Remove from Change Set' }
];

const stackTokens = { childrenGap: 50 };
const columnProps = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 300 } },
};
const isReadOnly = getReadOnlyFlagPerEnv();

class AdminChangeSet extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showPopup: true,
      inConfirmation: false,
      changeSets: null,
      showNextChangeSetItem: true,
      selectedItem: '',
      selectedOption: '',
      changeSetName: '',
      changeSetList: [],
      changeSetId: '',
      confirmButtonDisabled: true,
      changesetstatus: ''
    };
    this.handleSelectedOption = this.handleSelectedOption.bind(this);
    initializeIcons();
  }

  async componentDidMount () {
    await this.props.fetchAdminChangeSet();
    const changesetselected = this.props.changeSetData?.changeSets.filter((item) => item.id === this.props.changeItem?.changeSet?.id)[0];
    this.setState({ changesetstatus: changesetselected });
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.changeSetList !== nextProps.changeSetList) {
      return { changeSetList: nextProps.changeSetList };
    }
    return null;
  }

  handleSelectedOption (ev, option) {
    this.setState((prevState) => ({ ...prevState, selectedOption: option, showNextChangeSetItem: false }));
    if (option.key === 'REMOVE' && this.props.changeItem?.changeSet !== undefined && this.state.changesetstatus?.status === 'OPEN') { this.setState((prevState) => ({ ...prevState, confirmButtonDisabled: false })); }
  }

  handleSelectedChangeSet = (event, item) => {
    this.setState({
      selectedItem: item, changeSetName: item.text, changeSetId: item.key, showNextChangeSetItem: false, confirmButtonDisabled: false
      });
  };

  handleChangeSetName =(event) => {
    this.setState({
      changeSetName: event.target.value, showNextChangeSetItem: false, confirmButtonDisabled: false
    });
    if (event.target.value === '') {
      this.setState({
        changeSetName: event.target.value, showNextChangeSetItem: false, confirmButtonDisabled: true
      });
    }
  }

  getChangeSetList () {
    const changeSetlist = [];
    return  this.props.changeSetData?.changeSets.map((item) => {
      if (item.changeCategory.id === this.props.changeItem.changeCategory.id && item.status === 'OPEN') {
        if (item.name !== '') {
          changeSetlist.push({
            key: item.id,
            text: item.name
        });
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.changeSetList = changeSetlist;
        }
      }
      return null;
    });
  }

  getChangeSetStatus () {
    if (this.state.changesetstatus.status === 'OPEN') { return true; }
    return false;
  }

  showChangesetPopup () {
    this.getChangeSetList();
    const { classes } = this.props;
    return (
      <Modal
        isOpen={this.state.showPopup}
        onDismiss={this.hideAdminChangeSetHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep1}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Change Set - {this.props.changeItem.changeCategory.value} </span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAdminChangeSetHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack horizontal className={classes.informational}>
                <IconButton iconProps={{ iconName: 'Info' }} />
                <p>
                  Add an update to a Change Set. Add your update to an existing change set or create new change set.
                </p>
              </Stack>
            </Stack>
            <Stack tokens={tokens.sectionStack}>
              <Stack horizontal className={classes.choicegroup}>
                <ChoiceGroup options={options} onChange={this.handleSelectedOption} label="Select Change Set type" required={true} />
              </Stack>
            </Stack>
            <hr className={classes.sepLine} />
            { !this.state.showNextChangeSetItem
            ? <>
             { this.state.selectedOption.key !== 'REMOVE'
              ? <Stack tokens={tokens.sectionStack}>
                 <label className={classes.enterDetailTitle} >Enter Change Set name</label>
                  { this.state.selectedOption.key === 'CREATE'
                    ? <Stack horizontal tokens={stackTokens} className={classes.choicegroup} >
                        <Stack {...columnProps}>
                          <TextField value={this.state.changeSetName} disabled= {isReadOnly} onChange={(evt) => this.handleChangeSetName(evt)} />
                        </Stack>
                      </Stack>
                    : <Stack horizontal tokens={stackTokens} className={classes.choicegroup} >
                        <Stack {...columnProps}>
                          <Dropdown
                            selectedKey={this.state.selectedItem ? this.state.selectedItem.key : undefined}
                            onChange={this.handleSelectedChangeSet}
                            placeholder="Select change set"
                            options={this.state.changeSetList}
                            styles={this.dropdownStyles}
                          />
                        </Stack>
                      </Stack>
                  }
                </Stack>
              : <Stack horizontal tokens={stackTokens} className={classes.removeChange} >
                  { this.getChangeSetStatus()
                    ? <Stack {...columnProps}>
                      <Typography className={classes.removeTitle} >This selection will remove your update from the Change Set</Typography>
                    </Stack>
                    :   <Stack horizontal><Cancel className={this.props.classes.verificationInCompleteIcon}></Cancel>
                        <Typography variant="subtitle1"> This item cannot be removed as the Change Set has been published or closed against further updates.</Typography>
                      </Stack>
                  }
                </Stack>
              }
              </>
            : <>
              <hr className={classes.sepLine} />
            </>
            }

          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl}>
              <DefaultButton text="Cancel" onClick={this.hideAdminChangeSetHandler} />
              <PrimaryButton text="Confirm" onClick={this.submitAdminChangeSetRequest} disabled={this.state.confirmButtonDisabled && !isReadOnly}/>
            </Stack>
          </Stack>
        </Stack>
       </Modal>
    );
  }

  confirmation () {
    const { classes } = this.props;
    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideAdminChangeSetHandler}
        isModeless={true}
        containerClassName={classes.containerConfirmation}
      >
        <Stack verticalAlign="space-between" styles={stackStylesConfirmation}>
          <Stack>
            <div className={classes.header}>
            <img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /><span className={classes.title}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAdminChangeSetHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.confirmation}>
                <Stack horizontal style={{ paddingTop: '15px', fontFamily: 'SegoeUI' }}>
                  {this.props.changeItem.changeCategory.value} - {this.props.message}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.okbtn}>
              <DefaultButton text="Okay" onClick={this.hideAdminChangeSetHandler} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  hideAdminChangeSetHandler = () => {
    this.props.hideAdminChangeSetPopupHandler();
    this.setState((prevState) => ({
 ...prevState, showPopup: false, inConfirmation: false
    }));
  }

  submitAdminChangeSetRequest = async () => {
    const changeItem = {
      changeItemId: this.props.changeItem.id
    };
    if (this.state.selectedOption.key === "CREATE") {
      const changeSet = {
        name: this.state.changeSetName,
        changeItemId: this.props.changeItem.id
      };
      if (this.state.changeSetName !== '') {
        this.props.addChangeSet(changeSet);
        this.setState({ inConfirmation: true, showPopup: false });
      }
    } else {
        if (this.state.selectedOption.key === "ADD" && this.state.changeSetId !== '') {
          const { changeSetId } = this.state;
          this.props.addChangeItemToChangeSet(changeSetId, changeItem);
          this.setState({ inConfirmation: true, showPopup: false });
        }

      if (this.state.selectedOption.key === "REMOVE" && this.props.changeItem?.changeSet !== undefined) {
        this.props.removeChangeItemFromChangeSet(this.props.changeItem.changeSet.id, changeItem);
        this.setState({ inConfirmation: true, showPopup: false });
      }
    }
  }

  render () {
    return (
      <>
        {this.showChangesetPopup()}
        {this.confirmation()}
      </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '520px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '350px',
    height: '250px',
  },
  header: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '15px 12px 14px 24px',
    },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  title: {
    fontSize: '20px',
    color: '#323130'
  },
  enterDetailTitle: {
    margin: '5px 25px 0px 25px',
    padding: '2px 10px 0px 10px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgb(50, 49, 48)',
    fontFamily: "Segoe UI"
  },
  removeTitle: {
    margin: '15px 5px 15px 5px',
    width: 'max-content',
    padding: '16px',
  },
  removeChange: {
    margin: '0 25px 0px 25px',
    padding: '5px',
    width: "auto"
  },
  informational: {
    margin: '0 25px 25px 25px',
    padding: '16px',
    borderRadius: '2px',
    backgroundColor: '#f4f4f4'
  },
  choicegroup: {
    margin: '0 25px 25px 25px',
    padding: '5px',
    borderRadius: '2px',
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  btnCtrl: {
     padding: '0 25px 5px',
  },
  okbtn: {
    padding: '0 5px 5px 25px',
    marginLeft: '15rem',
    marginBottom: '1rem'
  },
  heading: {
    fontSize: '20px',
  }
});

const theme = getTheme();

const cancelIcon = { iconName: 'Cancel' };

const OliLogo = require('../../../../images/oli-success.svg');

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 5,
  },
};

const stackStylesStep1 = {
  root: {
    height: 500,
  },
};

const stackStylesConfirmation = {
  root: {
    height: 250,
  },
};

const mapStateToProps = (state) => ({
    changeMasterData: state.changeMastersData?.changeMasters,
    changeSetData: state.changeSets.changeSets?.data,
    result: state.ui.result,
    message: state.ui.message,
  });

AdminChangeSet.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  fetchAdminChangeSet,
  fetchAdminChangeMasters,
  addChangeSet,
  addChangeItemToChangeSet,
  removeChangeItemFromChangeSet,
})(AuthProvider(AdminChangeSet));

export default withStyles(styles)(connected);
