export const tasklibrary = [
     {
        id: '2ef0a0fa-a7eb-4f96-ac06-cde7b3e6f06a',
        code: 'tsk-055-dp',
        name: 'Task Library - Construction',
        total: 'TBA',
        checkpoint: 'TBA',
        decisionpoint: 'TBA',
        inactive: 'TBA'
    },
    {
        id: '7e996c3d-23cd-4676-ad42-f28e9f239cea',
        code: 'tsk-056-dp',
        name: 'Task Library - Development',
        total: 'TBA',
        checkpoint: 'TBA',
        decisionpoint: 'TBA',
        inactive: 'TBA'
    }
]