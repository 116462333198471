import { withStyles } from "@material-ui/styles";
import TableCell from "@material-ui/core/TableCell";
import { createMuiTheme } from "@material-ui/core";

export const HeaderTableCell = withStyles((theme) => ({
  head: {
    fontSize: 14,
    fontWeigtht: 600,
    color: "#323130",
    borderStyle: "none",
    paddingBottom: "12px",
    height: "35px",
  },
  body: {
    fontSize: 14,
    borderStyle: "none",
  },
}))(TableCell);

export const BodyTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    padding: "19px 0 16px 10px",
    color: "#323130",
    maxWidth: '200px',
  },
}))(TableCell);

export const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 8px",
      },
    },
  },
});
