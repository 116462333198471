import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import '../../../components/style.css';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
 DefaultButton, PrimaryButton, initializeIcons, TextField
} from '@fluentui/react';
import { fetchHelpTextMasters, updateHelpText } from '../../../state/actions/HelpTextActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import SuccessfulMessage from '../../../components/Dialog/SuccessfullMessage';
import renderHTMLContent from '../../../util/ConvertUtil';

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

const stackTokens = { childrenGap: 40 };

class HelpTextDetail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      show: this.props.open,
      currentDesc: '',
      currentName: '',
      hideDialog: true,
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the description Box</p>',
      open: false
    };
  }

  componentDidMount () {
    this.props.fetchHelpTextMasters();
    this.setState({ show: this.props.open });
    this.setState({
      currentName: this.props.selectedHelpText?.fieldName,
      currentDesc: this.props.selectedHelpText?.guidanceText,
      isBtnActivated: true
    });
    initializeIcons();
  }

  handleFieldNameChange = (event) => {
    this.setState({ currentName: event.target.value, isBtnActivated: false });
  }

  closeDialog = () => {
    this.handleHelpTextDetailEdit();
    this.setState({ hideDialog: true });
  }

  cancelDialog = () => {
    this.setState({ hideDialog: true });
  }

  handleShowHelpTextDetailEdit = () => {
    this.setState({ hideDialog: false });
  }

  handleHelpTextDetailEdit = () => {
    const helpText = this.props.helpTextData?.filter((item) => (item.id === this.props.selectedHelpText?.id))[0];
    if (helpText) {
      helpText.guidanceText = this.state.currentDesc;
      helpText.fieldName = this.state.currentName;
      this.props.updateHelpText(helpText);
      this.setState({ isBtnActivated: true, hideDialog: true });
    }
  }

  onDismissTaskDetailPanel = () => {
    this.props.dismissTaskDetailPanel();
  }

  onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  render () {
    const { classes, isReadOnly } = this.props;
    if (!this.props.helpTextData) return <div></div>;
    const currentDesc = this.state.currentDesc ? this.state.currentDesc : this.props.selectedHelpText?.guidanceText;
    const currentName = this.state.currentName ? this.state.currentName : this.props.selectedHelpText?.fieldName;
    // eslint-disable-next-line global-require
    const OliLogo = (this.props.result === 'SUCCESS') ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');
    return (
     <Panel
      isOpen={this.props.open}
      onDismiss={this.props.onClose}
      type={PanelType.custom}
      customWidth={'550px'}
      closeButtonAriaLabel="Close"
      headerText= "Edit Help Text Summary"
      >
        <Separator></Separator>
        <Stack horizontal tokens={stackTokens} className={classes.actions}>
          <DefaultButton text="Cancel"  className={classes.inputbox} onClick={this.props.onClose} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
          <PrimaryButton text="Save"  className={classes.inputbox} disabled={isReadOnly} onClick={() => this.handleShowHelpTextDetailEdit()}/>
        </Stack>
        <Stack className={classes.helpDetail}>
          <TextField label="Field Name" value={currentName} onChange={(event) => this.handleFieldNameChange(event)} readOnly={isReadOnly} />
        </Stack>
        <Stack className={classes.helpDetail}>
          <span className={classes.fieldName}>Help Text</span>
          {!isReadOnly
          ? <Editor
            apiKey={APIKeys.EditorKey}
            value={currentDesc}
            init={{
              branding: false,
              height: 300,
              browser_spellcheck: true,
              content_style: 'body { color:#605e5c; }',
              selector: 'textarea',
              allow_unsafe_link_target: true,
              element_format: 'xhtml',
              plugins: [
                'wordcount advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help'
              ],
              paste_preprocess (plugin, args) {
              },
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
              init_instance_callback: (editor) => {
                this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
              }
            }}
            onEditorChange={(content, editor) => {
              if ((content !== this.props.selectedHelpText?.guidanceText) && ReactHtmlParser(content) !== ReactHtmlParser(this.props.selectedHelpText?.guidanceText)) {
                this.setState({
                  isBtnActivated: false,
                  currentDesc: content,
                  editorCount: editor.plugins.wordcount.body.getCharacterCount()
                });
              }
            }}
            onBlur={(event, editor) => {
              this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
            }}
          /> : renderHTMLContent(currentDesc)}
        </Stack>

        {!this.state.hideDialog
            ? <SuccessfulMessage dialogState={false} errorTitle={this.props.result}
              errorContentArray={[this.props.message, 'Click Okay to continue.']}
              hidden={this.state.hideDialog}
              closeDialog={this.closeDialog}
              cancelDialog={this.cancelDialog}
              OliLogo={OliLogo}
            />
            : ''
        }
      </Panel>
    );
  }
}

const styles = (theme) => ({
  actions: {
    padding: '20px 0 20px 0'
  },
  helpDetail: {
    padding: '10px 0 10px 0',
  },
  inputbox: {
    border: '1px solid'
  },
  fieldName: {
    fontWeight: '600',
    color: '#323130',
    marginBottom: '5px'
  },
});

HelpTextDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  helpTextData: state.helpTextData.helpTextMasters,
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  fetchHelpTextMasters,
  updateHelpText
})(AuthProvider(HelpTextDetail));

export default withStyles(styles)(connected);
