/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';

import Select from 'react-select';

import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

import { Icon } from '@fluentui/react/lib/Icon';
import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';

import { fetchNotificationMasters } from '../../../state/actions/NotificationMastersAction';
import { fetchProjectSummaries } from '../../../state/actions/ProjectSummariesAction';
import { fetchEligibleNotificationMasterByDecisionPoint } from '../../../state/actions/EligibleNotificationMastersAction';
import AuthProvider from '../../../components/auth/AuthProvider';

import './NotificationMasterSelectionPolicy.css';

const REFDATA_EVENT_TYPES = [
    { key: 'pathway', text: 'Pathway', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.PATHWAY.001', text: 'Pathway - created', type: 'PATHWAY NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PATHWAY.002', text: 'Pathway - updated', type: 'PATHWAY NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PATHWAY.003', text: 'Pathway - deleted', type: 'PATHWAY NOTIFICATION' },
    { key: 'phase', text: 'Phase', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.PHASE.001', text: 'Phase - created', type: 'PHASE NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PHASE.002', text: 'Phase - updated', type: 'PHASE NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PHASE.003', text: 'Phase - deleted', type: 'PHASE NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PHASE.004', text: 'Phase - Status Change', type: 'PHASE NOTIFICATION' },
    { key: 'decision-point', text: 'Decision-point', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.DP.001', text: 'Decision-point - created', type: 'DECISION POINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.DP.002', text: 'Decision-point - updated', type: 'DECISION POINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.DP.003', text: 'Decision-point - status-change', type: 'DECISION POINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.DP.004', text: 'Decision-point - approval-status-change', type: 'DECISION POINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.DP.005', text: 'Decision-point - deleted', type: 'DECISION POINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.DP.008', text: 'Decision-point - ready-for-submission', type: 'DECISION POINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.DP.009', text: 'Decision-point - ready-for-completion', type: 'DECISION POINT NOTIFICATION' },
    { key: 'approval', text: 'Approval', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.001', text: 'Approval - created', type: 'APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.002', text: 'Approval - updated', type: 'APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.006', text: 'Approval - due-date-updated', type: 'APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.003', text: 'Approval - approval-status-change', type: 'APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.004', text: 'Approval - approver-change', type: 'APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.005', text: 'Approval - deleted', type: 'APPROVAL NOTIFICATION' },
    { key: 'reminder100', text: 'Reminder', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.APPROVAL.REMINDER.001', text: 'Reminder - approval-due', type: 'APPROVAL REMINDER NOTIFICATION' },
    { key: 'approval-step', text: 'Approval-Step', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.AS.001', text: 'Approval-Step - created', type: 'APPROVAL STEP NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.AS.002', text: 'Approval-Step - updated', type: 'APPROVAL STEP NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.AS.005', text: 'Approval-Step - due-date-updated', type: 'APPROVAL STEP NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.AS.003', text: 'Approval-Step - approval-status-change', type: 'APPROVAL STEP NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.AS.004', text: 'Approval-Step - deleted', type: 'APPROVAL STEP NOTIFICATION' },
    { key: 'approval-route', text: 'Approval-Route', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.AR.001', text: 'Approval-Route - created', type: 'APPROVAL ROUTE NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.AR.002', text: 'Approval-Route - updated', type: 'APPROVAL ROUTE NOTIFICATION' },
    { key: 'checkpoint', text: 'Checkpoint', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.CP.001', text: 'Checkpoint - created', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CP.002', text: 'Checkpoint - updated', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CP.003', text: 'Checkpoint - status-change', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CP.007', text: 'Checkpoint - due-date-updated', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CP.004', text: 'Checkpoint - approval-status-change', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CP.005', text: 'Checkpoint - responsible-change', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CP.006', text: 'Checkpoint - deleted', type: 'CHECKPOINT NOTIFICATION' },
    { key: 'checkpoint-approval', text: 'Checkpoint-Approval', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.CPA.001', text: 'Checkpoint-Approval - created', type: 'CHECKPOINT APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CPA.002', text: 'Checkpoint-Approval - updated', type: 'CHECKPOINT APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CPA.006', text: 'Checkpoint-Approval - due-date-updated', type: 'CHECKPOINT APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CPA.003', text: 'Checkpoint-Approval - approval-status-change', type: 'CHECKPOINT APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CPA.004', text: 'Checkpoint-Approval - approver-change', type: 'CHECKPOINT APPROVAL NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CPA.005', text: 'Checkpoint-Approval - deleted', type: 'CHECKPOINT APPROVAL NOTIFICATION' },
    { key: 'reminder101', text: 'Reminder', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.CPA.REMINDER.001', text: 'Checkpoint-Approval-Reminder - approval-due', type: 'CHECKPOINT APPROVAL REMINDER NOTIFICATION' },
    { key: 'subject-matter', text: 'Subject-Matter', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.SM.001', text: 'Subject-Matter - created', type: 'SUBJECT MATTER NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.SM.002', text: 'Subject-Matter - updated', type: 'SUBJECT MATTER NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.SM.003', text: 'Subject-Matter - status-change', type: 'SUBJECT MATTER NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.SM.004', text: 'Subject-Matter - deleted', type: 'SUBJECT MATTER NOTIFICATION' },
    { key: 'task', text: 'Task', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.TASK.001', text: 'Task - created', type: 'TASK NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.002', text: 'Task - status-change', type: 'TASK NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.009', text: 'Task - requirement-change', type: 'TASK NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.003', text: 'Task - updated', type: 'TASK NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.004', text: 'Task - assign-change', type: 'TASK NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.008', text: 'Task - deleted', type: 'TASK NOTIFICATION' },
    { key: 'task-comment', text: 'Task-Comment', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.TASK.005', text: 'Task-Comment - added', type: 'TASK COMMENT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.006', text: 'Task-Comment - updated', type: 'TASK COMMENT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.TASK.007', text: 'Task-Comment - deleted', type: 'TASK COMMENT NOTIFICATION' },
    { key: 'project', text: 'Project', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.PRJ.001', text: 'Project - created', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.002', text: 'Project - deleted', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.003', text: 'Project - submission-delay', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.004', text: 'Project - updated', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.005', text: 'Project - status-change', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.006', text: 'Project - enablon-id-received', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.007', text: 'Project - integration-updates', type: 'PROJECT NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRJ.008', text: 'Project - owner-changed', type: 'PROJECT NOTIFICATION' },
    { key: 'risk', text: 'Project - Risk', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.PRR.001', text: 'Project Risk - rating-selection-added', type: 'PROJECT RISK RATING NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRR.002', text: 'Project Risk - rating-selection-changed', type: 'PROJECT RISK RATING NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRR.003', text: 'Project Risk - management-strategy-change', type: 'PROJECT RISK RATING NOTIFICATION' },
    { key: 'team-member', text: 'Project Team-Member', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.PRT.001', text: 'Project Team-Member - added', type: 'PROJECT TEAM MEMBER NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.PRT.002', text: 'Project Team-Member - deleted', type: 'PROJECT TEAM MEMBER NOTIFICATION' },
    { key: 'change-control-request:', text: 'change-control-request:', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.CCR.001', text: 'Change Cntrl Request - created', type: 'CHANGE CONTROL REQUEST NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CCR.002', text: 'Change Cntrl Request - updated', type: 'CHANGE CONTROL REQUEST NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CCR.003', text: 'Change Cntrl Request - status-change', type: 'CHANGE CONTROL REQUEST NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.CCR.004', text: 'Change Cntrl Request - approval-status-change', type: 'CHANGE CONTROL REQUEST NOTIFICATION' },
    { key: 'region', text: 'Region', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.RGN.001', text: 'Region - access-updated', type: 'REGION NOTIFICATION' },
    { key: 'regionalBU', text: 'RegionalBU', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.RBU.001', text: 'RegionalBU - access-updated', type: 'REGIONAL BU NOTIFICATION' },
    { key: 'strategicBU', text: 'StrategicBU', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.SBU.001', text: 'StrategicBU - access-updated', type: 'STRATEGIC BU NOTIFICATION' },
    { key: 'integration', text: 'Integration', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.MuleSoftIntegrationRequest', text: 'Integration - mulesoft', type: 'INTEGRATION NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.TriggerInitialMuleSoftIntegrationRequest', text: 'Integration - trigger-initial-mulesoft', type: 'INTEGRATION NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.TriggerUpdateMuleSoftIntegrationRequest', text: 'Integration - trigger-update-mulesoft', type: 'INTEGRATION NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.TriggerADAccessRefresh', text: 'Integration - trigger-ad-access-refresh', type: 'INTEGRATION NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.TriggerADGroupSubscriptions', text: 'Integration - trigger-ad-group-subscriptions', type: 'INTEGRATION NOTIFICATION' },
    { key: 'users', text: 'Users', itemType: DropdownMenuItemType.Header },
    { key: 'com.lendleaseoli.oli.EV.USER.001', text: 'Users - access-granted', type: 'USERS NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.USER.002', text: 'Users - access-revoked', type: 'USERS NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.USER.003', text: 'Users - access-validation', type: 'USERS NOTIFICATION' },
    { key: 'com.lendleaseoli.oli.EV.USER.004', text: 'Users - access-forbidden', type: 'USERS NOTIFICATION' },
  ];

const REFDATA_DECISION_POINT_TYPE = [
  { text: 'Default', key: '9c4eeb98-983b-46e6-9c6f-b7a20739412a' },
  { text: 'Commencement Authorisation', key: '15a842e8-3c36-4a5c-8d1e-53a589fb97f6' },
  { text: 'Commitment Review', key: 'f91a651e-093b-4251-972a-32232e1ac49d' },
  { text: 'Conversion Authorisation', key: 'ecb75ca5-11f0-4640-b1cd-ce26a1035bf3' },
  { text: 'Deal Shift', key: 'ce4b6b8d-41a0-4406-acd2-961f29d3c67f' },
  { text: 'Opportunity', key: '67b59c8d-28ce-4515-a6b9-8764f5e54bd4' },
  { text: 'Final Phase', key: '3f288002-09a8-498f-bfd1-be77a73d55eb' },
  { text: 'Project Completion', key: '1ca15d2d-ec8b-4d13-8a59-a56e4320bae9' },
];

const REFDATA_DECISION_POINT_APPROVAL_STATUS = [
  { text: 'Not Submitted', key: 'NOT_SUBMITTED' },
  { text: 'Submitted', key: 'SUBMITTED' },
  { text: 'Completed', key: 'COMPLETED' },
];

const REFDATA_DECISION_POINT_STATUS = [
  { text: 'Not Started', key: 'NOT_STARTED' },
  { text: 'In Progress', key: 'IN_PROGRESS' },
  { text: 'Pending Approval', key: 'PENDING_APPROVAL' },
  { text: 'Approved', key: 'APPROVED' },
  { text: 'Declined', key: 'DECLINED' },
  { text: 'Not Required', key: 'NOT_REQUIRED' },
];

const REFDATA_NOTIFICATION_CATEGORY = [
  { text: 'Approval Notification', key: 'fb1f8295-452b-459c-a788-f9be10de26d7' },
  { text: 'Change Control Notification', key: '8cb6e16a-cde2-41eb-ae2b-d8ae1b162911' },
  { text: 'Checkpoint Notification', key: '0fd7ca4c-2c23-4022-a32f-c6bde704f00f' },
  { text: 'Decision Point Notification', key: 'ec41de52-610c-465c-bdfc-bfbbb408a61f' },
  { text: 'Entitlement Notification', key: 'bc2ca397-f085-4e14-a580-3b1a9236c3a5' },
  { text: 'General Notification', key: '8d99bf9d-d36a-463c-b983-af88357d867a' },
  { text: 'Integration Notification', key: '81ee5c90-55b8-4514-9c4e-3742ff6a3a42' },
  { text: 'Project Notification', key: 'cf11b04e-6ade-40b3-8da6-e5a363e3b560' },
  { text: 'Subject Matter Notification', key: 'db66567e-c0ce-4043-8b28-3a65320bed48' },
  { text: 'Task Notification', key: '0757a0e1-394b-4348-944b-d2927e989133' },
  { text: 'Users Notification', key: '3093870f-e9f6-428d-bf83-636836c2dfa6' },
];

const REFDATA_NOTIFICATION_CONTENT_BUILDER_TYPE = [
  { text: 'Approval Notification Content Builder', key: '455870a3-c5d9-4d61-b038-32ae7a27c017' },
  { text: 'Approval Reminder Notification Content Builder', key: 'dad5e42d-b917-4782-914f-79ab9f15d758' },
  { text: 'Approval Retract Notification Content Builder', key: '827fbf6f-4701-4615-b1d1-d88c7b556730' },
  { text: 'Checkpoint Approval Notification Content Builder', key: 'ae4901a5-5df3-4cfc-8b36-49bef953db74' },
  { text: 'Checkpoint Approval Pending Notification Content Builder', key: 'aebd45fb-8d53-46e2-a124-fbcdb291163a' },
  { text: 'Checkpoint Approval Reminder Notification Content Builder', key: 'a0939381-9605-4aa9-b2b7-d18c9a7ab38c' },
  { text: 'Checkpoint Notification Content Builder', key: 'a8ae4f49-95eb-4da3-8a12-89b8b73b6709' },
  { text: 'Decision Point Approved Notification Content Builder', key: '7669676a-f481-4937-9665-28a365ec2502' },
  { text: 'Decision Point Declined Notification Content Builder', key: 'f34e2f1b-1027-4de3-9cd7-4cbf4b9e2645' },
  { text: 'Decision Point Notification Content Builder', key: '0c5ceb6d-b990-429b-872c-60a45144ae4c' },
  { text: 'Decision Point Started Notification Content Builder', key: '390a92da-f880-431b-a63c-13dc2e1ef937' },
  { text: 'Decision Point Submitted Content Builder', key: '51984565-4835-4e87-bf95-23f95dd25a87' },
  { text: 'Enablon Notification Content Builder', key: 'c85fcc08-96e8-46ab-aeaf-b24b4525e72d' },
  { text: 'Project Closed Notification Content Builder', key: '0597f3d8-7fea-45b5-98b9-2be905512e08' },
  { text: 'Project Notification Content Builder', key: 'a2255352-029f-47c4-b9c5-3cb914fa08f5' },
  { text: 'Task Comment Notification Content Builder', key: '217161f7-fcd3-4a12-8f4d-da5b78992c5e' },
  { text: 'Task Notification Content Builder', key: '1eef404d-75c8-4557-bc9f-65478af9a333' },
  { text: 'Team Member Notification Content Builder', key: '65ca828b-7f6d-4271-923a-7d463c1c7dfc' },
  { text: 'Verification Plan Set Up Checkpoint Notification Content Builder', key: '53b807d5-a2c8-49c7-ad7e-bd799153f4b0' },
];

const REFDATA_NOTIFICATION_RECIPIENT_STRATEGY_TYPE = [
  { text: 'All Project Owners Strategy', key: '2377e024-49af-41f0-8dca-09e5ce67ac81' },
  { text: 'All Team Members Strategy', key: '31cca2a4-3814-46d6-9c2e-e2db899ad617' },
  { text: 'Approver Strategy', key: 'ae829314-cdf8-4b4a-b45a-b8c0c504d4cd' },
  { text: 'Checkpoint Responsible Strategy', key: '4467a7fb-8fc5-4514-9792-3867e1800fe3' },
  { text: 'Function Resolution Strategy', key: '57fe78b4-3569-488c-9e86-91d598117658' },
  { text: 'Project Owner Strategy', key: '0f53ea5f-3864-4418-8646-5211e42b908b' },
  { text: 'Task Assignee Strategy', key: '850d471d-1d07-490f-9a73-c6ab96e63462' },
  { text: 'Task Mentioned User Strategy', key: '36380263-f252-4811-bb71-e09e8cc4bec6' },
  { text: 'Team Member Strategy', key: '795cf096-4571-4b94-89f8-de5fcef698b8' },
  { text: 'Verification Plan Checkpoint Supervisor Strategy', key: '42c2b5e8-257f-41fa-bd89-e0432b3ec631' },
];

const KEY_EVENT_TYPE = 'EVENT_TYPE';
const KEY_EVENT_SUBMITTER_ID = 'EVENT_SUBMITTER_ID';
// const KEY_PROJECT_ID = 'PROJECT_ID';
const KEY_DECISION_POINT_TYPE = 'DECISION_POINT_TYPE';
const KEY_DECISION_POINT_APPROVAL_STATUS = 'DECISION_POINT_APPROVAL_STATUS';
const KEY_DECISION_POINT_STATUS = 'DECISION_POINT_STATUS';
const KEY_DECISION_POINT_PREVIOUS_STATUS = 'DECISION_POINT_PREVIOUS_STATUS';
const KEY_CHECKPOINT_APPROVAL_STATUS = 'CHECKPOINT_APPROVAL_STATUS';

class NotificationMasterSelectionPolicy extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            projects: [],
            searchResults: [],

            attrEventType: null,
            attrEventSubmitterId: null,
            attrProjectId: null,
            attrDecisionPointType: null,
            attrDecisionPointApprovalStatus: null,
            attrDecisionPointStatus: null,
            attrDecisionPointPreviousStatus: null,
            attrCheckpointApprovalStatus: null,
        };
    }

    async componentDidMount () {
        await this.props.fetchNotificationMasters();
        await this.props.fetchProjectSummaries();

        if (this.props.notificationMastersData && this.props.notificationMastersData.masters) {
          const _keys = [];
          const _eventTypes = [];
          this.props.notificationMastersData.masters.map((p, idx) => {
            p.associatedEventTypes.map((a, pos) => {
                if (!_keys.includes(a)) {
                  _keys.push(a);
                  _eventTypes.push({ text: a, key: a });
                }
            });
          });
          this.setState((prevState) => ({
            ...prevState,
            eventTypes: _eventTypes,
          }));
        }

        if (this.props.projectSummariesData && this.props.projectSummariesData.summaries) {
          const _projects = [];
          this.props.projectSummariesData.summaries.map((p, idx) => {
            _projects.push({ label: p.projectName, value: p.projectId });
          });
          this.setState((prevState) => ({
            ...prevState,
            projects: _projects,
          }));
        }
    }

    render () {
        const isDisabled = this.isDisabledValidateTrigger();

        return (
            <Card variant='outlined' className={this.props.classes.rootBackground}>

                <Box component="span" display="block" className={this.props.classes.cardContentBox}>
                    <CardContent className={this.props.classes.cardContent}>
                        <div className={this.props.classes.inputColumnA}>
                            <Dropdown
                                id={KEY_EVENT_TYPE}
                                placeholder="Select Event"
                                label="Select Event"
                                options={REFDATA_EVENT_TYPES}
                                required
                                styles={dropdownStyles}
                                onRenderCaretDown={onRenderCaretDown}
                                onChange={(e, i) => this.changeInputHandler(e, i, KEY_EVENT_TYPE)}
                            />
                        </div>
                    </CardContent>

                    <div className={this.props.classes.dataPanel}>
                        <label htmlFor="validation-types" className={this.props.classes.validationHeader}></label>
                        <CardContent className={this.props.classes.cardContent}>
                            <div className={this.props.classes.inputColumnA}>
                                <Dropdown
                                    id={KEY_DECISION_POINT_TYPE}
                                    placeholder="Select.."
                                    label="Decision Point Type"
                                    options={REFDATA_DECISION_POINT_TYPE}
                                    required
                                    styles={dropdownStyles}
                                    onRenderCaretDown={onRenderCaretDown}
                                    onChange={(e, i) => this.changeInputHandler(e, i, KEY_DECISION_POINT_TYPE)}
                                />
                            </div>
                            <div className={this.props.classes.inputColumnB}>
                                <Dropdown
                                    id={KEY_DECISION_POINT_APPROVAL_STATUS}
                                    placeholder="Search.."
                                    label="Decision Point Approval Status"
                                    options={REFDATA_DECISION_POINT_APPROVAL_STATUS}
                                    required
                                    styles={dropdownStyles}
                                    onRenderCaretDown={onRenderCaretDown}
                                    onChange={(e, i) => this.changeInputHandler(e, i, KEY_DECISION_POINT_APPROVAL_STATUS)}
                                />
                            </div>
                            <div className={this.props.classes.inputColumnA}>
                                <Dropdown
                                    id={KEY_DECISION_POINT_STATUS}
                                    placeholder="Select.."
                                    label="Decision Point Status"
                                    options={REFDATA_DECISION_POINT_STATUS}
                                    required
                                    styles={dropdownStyles}
                                    onRenderCaretDown={onRenderCaretDown}
                                    onChange={(e, i) => this.changeInputHandler(e, i, KEY_DECISION_POINT_STATUS)}
                                />
                            </div>
                            <div className={this.props.classes.inputColumnB}>
                                <Dropdown
                                    id={KEY_DECISION_POINT_PREVIOUS_STATUS}
                                    placeholder="Search.."
                                    label="Decision Point Previous Status"
                                    options={REFDATA_DECISION_POINT_STATUS}
                                    required
                                    styles={dropdownStyles}
                                    onRenderCaretDown={onRenderCaretDown}
                                    onChange={(e, i) => this.changeInputHandler(e, i, KEY_DECISION_POINT_PREVIOUS_STATUS)}
                                />
                            </div>
                            <div className={this.props.classes.inputColumnA}>
                              <TextField
                                required
                                id="EventSubmitterId"
                                styles={textfieldStyles}
                                onChange={(e, i) => this.changeInputHandler(e, i, KEY_EVENT_SUBMITTER_ID)}
                                label="Event submitter Id"
                                placeholder="Please enter text (UUID) here" />
                            </div>
                            <div className={this.props.classes.inputColumnB}>
                                <Dropdown
                                    id={KEY_CHECKPOINT_APPROVAL_STATUS}
                                    placeholder="Search.."
                                    label="Checkpoint Approval Status"
                                    options={REFDATA_DECISION_POINT_APPROVAL_STATUS}
                                    required
                                    styles={dropdownStyles}
                                    onRenderCaretDown={onRenderCaretDown}
                                    onChange={(e, i) => this.changeInputHandler(e, i, KEY_CHECKPOINT_APPROVAL_STATUS)}
                                />
                            </div>
                            <div className={this.props.classes.inputColumnA}>
                             Search for project name<Select
                                styles={customProjectStyles}
                                options={this.state.projects}
                                // onChange={(e, i) => this.changeProjectHandler(i, KEY_PROJECT_ID)}
                                onChange={this.changeProjectHandler}
                              />
                            </div>
                        </CardContent>
                    </div>
                    <div className={this.props.classes.fetchControl}>
                        <PrimaryButton text="Validate" onClick={this.fetchValidationData} allowDisabledFocus disabled={isDisabled} />
                    </div>
                </Box>

                <br />

                { this.state.searchResults && this.state.searchResults.length > 0
                  ? (<div className={this.props.classes.resultContentBox}>
                    {this.presentSearchResult()}
                  </div>)
                :                <div>No result yet</div>
                }
            </Card>
        );
    }

    isDisabledValidateTrigger () {
      return (!(this.state.attrEventType && this.state.attrEventSubmitterId
        && this.state.attrProjectId && this.state.attrDecisionPointType
        && this.state.attrDecisionPointApprovalStatus && this.state.attrDecisionPointStatus
        && this.state.attrDecisionPointPreviousStatus && this.state.attrCheckpointApprovalStatus));
    }

    changeProjectHandler = (newValue, actionMeta) => {
      this.setState((prevState) => ({
        ...prevState,
        attrProjectId: newValue.value
      }));
    }

    changeInputHandler = (e, i, control) => {
      switch (control) {
        case KEY_EVENT_TYPE:
          this.setState((prevState) => ({
            ...prevState,
            attrEventType: i.key
          }));
          break;
        case KEY_EVENT_SUBMITTER_ID:
          this.setState((prevState) => ({
            ...prevState,
            attrEventSubmitterId: e.target.value
          }));
          break;
        case KEY_DECISION_POINT_TYPE:
          this.setState((prevState) => ({
            ...prevState,
            attrDecisionPointType: i.key
          }));
          break;
        case KEY_DECISION_POINT_APPROVAL_STATUS:
          this.setState((prevState) => ({
            ...prevState,
            attrDecisionPointApprovalStatus: i.key
          }));
          break;
        case KEY_DECISION_POINT_STATUS:
          this.setState((prevState) => ({
            ...prevState,
            attrDecisionPointStatus: i.key
          }));
          break;
        case KEY_DECISION_POINT_PREVIOUS_STATUS:
          this.setState((prevState) => ({
            ...prevState,
            attrDecisionPointPreviousStatus: i.key
          }));
          break;
        case KEY_CHECKPOINT_APPROVAL_STATUS:
          this.setState((prevState) => ({
            ...prevState,
            attrCheckpointApprovalStatus: i.key
          }));
          break;
        default:
          console.log(`Sorry, unknown control >>> ${control}.`);
      }
    }

    fetchValidationData = async () => {
        const params = {
          eventType: this.state.attrEventType,
          eventSubmitterId: this.state.attrEventSubmitterId,
          projectId: this.state.attrProjectId,
          decisionPointType: this.state.attrDecisionPointType,
          decisionPointApprovalStatus: this.state.attrDecisionPointApprovalStatus,
          decisionPointStatus: this.state.attrDecisionPointStatus,
          decisionPointPreviousStatus: this.state.attrDecisionPointPreviousStatus,
          checkpointApprovalStatus: this.state.attrCheckpointApprovalStatus,
        };
        await this.props.fetchEligibleNotificationMasterByDecisionPoint(params);

        // prepare search result
        const searchResults = [];
        this.props.eligibleNotificationMastersData.masters.map((m, idx) => {
          // assign arrays
          const associatedEventTypes = [];
          m.associatedEventTypes.map((id, idx1) =>  {
            associatedEventTypes.push(NotificationMasterSelectionPolicy.getAssociatedEventType(id));
          });

          const recipientStrategies = [];
          m.recipientStrategies.map((id, idx2) =>  {
            recipientStrategies.push(NotificationMasterSelectionPolicy.getRecipientStrategy(id));
          });

          searchResults.push({
            id: m.id,
            code: m.code,
            name: m.name,
            description: m.description,
            title: m.title,
            category: NotificationMasterSelectionPolicy.getNotificationCategory(m.category),
            contentBuilderType: NotificationMasterSelectionPolicy.getContentBuilderType(m.contentBuilderType),
            associatedEventTypes,
            notificationFunctions: m.notificationFunctions,
            recipientStrategies,
          });
        });

        this.setState((prevState) => ({
          ...prevState,
          searchResults,
        }));
    }

    presentSearchResult = () => {
      const searchResults = (this.state.searchResults.length > 0) ? this.state.searchResults : [];

      return searchResults.map((res, idx)=>{
        return (
          <div key={`d0-${res.id}`}>
            <div id={`d1-${res.id}`} key={`d1-${res.id}`} className={this.props.classes.resultContainer} onClick={() => NotificationMasterSelectionPolicy.toggleRow(res.id)}>
              <div key={`d2-${res.id}`} className={this.props.classes.rectangleGroupHeader1}>
                <label className={this.props.classes.groupHeader1}>{res.category}</label>
              </div>
              <div key={`d3-${res.id}`}>{res.code} - {res.name} - {res.title}</div>
              <div key={`d4-${res.id}`} className={this.props.classes.PDPLOABUSINESS001}>{res.description}</div>
              <div key={`d5-${res.id}`} className={this.props.classes.seeDetailsBox}>&nbsp;&nbsp;&nbsp;<span className={this.props.classes.seeDetails}>See Details</span> &#62;</div>
            </div>
            <div id={`panel-${res.id}`} key={`panel-${res.id}`} className={this.props.classes.panelRect}>
              <div><span className={this.props.classes.labelDecor}>ID</span>{res.id}</div>
              <div><span className={this.props.classes.labelDecor}>Content Builder</span>{res.contentBuilderType}</div>
              <div key={`panel2-${res.id}`} className={this.props.classes.rectInnerHeader}>
                <div className={this.props.classes.tableInnerColumnHeader}>Associated Event Types</div>
                <div className={this.props.classes.tableInnerColumnHeader}>Notification Functions</div>
                <div className={this.props.classes.tableInnerColumnHeader}>Recipient Strategies</div>
              </div>
              <div key={`panel3-${res.id}`} className={this.props.classes.rectInnerContent}>
                <div className={this.props.classes.tableInnerColumn}>
                  <ul className={this.props.classes.tableTextInnerColumn}>
                  {res.associatedEventTypes && res.associatedEventTypes.length > 0
                    ? res.associatedEventTypes.map((val, i) => {
                      return <li key={`li1-${res.id}-${i}`}>{val}</li>;
                    }) : null
                  }
                  </ul>
                </div>
                <div key={`panel4-${res.id}`} className={this.props.classes.tableInnerColumn}>
                  <ul className={this.props.classes.tableTextInnerColumn}>
                  {res.notificationFunctions && res.notificationFunctions.length > 0
                    ? res.notificationFunctions.map((val, i) => {
                      return <li key={`li2-${res.id}-${i}`}>{val.name}</li>;
                    }) : null
                  }
                  </ul>
                </div>
                <div key={`panel5-${res.id}`} className={this.props.classes.tableInnerColumn}>
                  <ul className={this.props.classes.tableTextInnerColumn}>
                  {res.recipientStrategies && res.recipientStrategies.length > 0
                    ? res.recipientStrategies.map((val, i) => {
                      return <li key={`li3-${res.id}-${i}`}>{val}</li>;
                    }) : null
                  }
                  </ul>
                </div>
              </div>
            </div>
            <hr className="sepLine" />
            <br/>
          </div>
        );
      });
    }

    static toggleRow (id) {
      const docid = `d1-${id}`;
      const doc = document.getElementById(docid);
      const panel = doc.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    }

    static getNotificationCategory (id) {
      const res = REFDATA_NOTIFICATION_CATEGORY.filter((v) => v.key === id);
      if (res.length > 0) {
        return res[0].text;
      }
      return "UNKNOWN***";
    }

    static getContentBuilderType (id) {
      const res = REFDATA_NOTIFICATION_CONTENT_BUILDER_TYPE.filter((v) => v.key === id);
      if (res.length > 0) {
        return res[0].text;
      }
      return `UNKNOWN*** - ${id}`;
    }

    static getAssociatedEventType (id) {
      const res = REFDATA_EVENT_TYPES.filter((v) => v.key === id);
      if (res.length > 0) {
        return res[0].text;
      }
      return `UNKNOWN*** - ${id}`;
    }

    static getRecipientStrategy (id) {
      const res = REFDATA_NOTIFICATION_RECIPIENT_STRATEGY_TYPE.filter((v) => v.key === id);
      if (res.length > 0) {
        return res[0].text;
      }
      return `UNKNOWN*** - ${id}`;
    }

}

const onRenderCaretDown = () => {
    return <Icon iconName="ChevronDownMed" />;
};

const dropdownStyles = { dropdown: { width: 300 } };
const textfieldStyles = { root: { width: 300 } };

const styles = (theme) => ({
    rootBackground: {
        // height: '100%',
        height: '100vh',
        backgroundColor: 'white'
    },
    root: {
        flexGrow: 1
    },
    cardHeaderSection: {
        padding: '16px 0 16px 23px'
    },
    cardHeader: {
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        padding: '16px',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: {variant: 'h1'},
        title: {
            titleTypographyProps: {variant: 'h1'},
            fontSize: '12px'
        },

    },
    cardContentBox: {
        backgroundColor: '#f4f4f4',
        padding: '50px 30px 0 30px',
        margin: '0 60px 0 60px',
    },
    cardContent: {
        display: 'flex',
        flexFlow: 'row wrap',
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        padding: '0 0 0 0',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: {variant: 'h1'},
        title: {
            titleTypographyProps: {variant: 'h1'},
            fontSize: '12px'
        },
    },
    fetchControl: {
        padding: '0 0 10px 0',
    },
    inputColumnA: {
        flex: '33%',
    },
    inputColumnB: {
        flex: '66%',
    },
    validationHeader: {
        fontSize: '16px',
        fontWeight: '600',
    },
    dataPanel: {
        padding: '40px 0 0 0',
    },
    resultContentBox: {
      backgroundColor: 'white',
      padding: '0 30px 0 30px',
      margin: '0 60px 0 60px',
    },
    resultContainer: {
      cursor: 'pointer',
      position: 'relative',
    },
    tableColumn: {
      flex: '33%',
    },
    seeDetailsBox: {
      backgroundColor: '#f4f4f4',
      padding: '5px 0 5px 0',
    },
    PDPLOABUSINESS001: {
      fontFamily: 'SegoeUI',
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#006ed2',
    },
    seeDetails: {
      fontFamily: 'SegoeUI',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#888888',
    },
    panelRect: {
      padding: '0 0',
      // backgroundColor: 'white',
      display: 'none',
      overflow: 'hidden',
      backgroundColor: '#f4f4f4',
      margin: '10px 0 0 0',
    },
    rectInnerHeader: {
      display: 'flex',
      flexFlow: 'row wrap',
      backgroundColor: 'white',
      border: 'solid 1px #dcdcdc',
      padding: '5px'
    },
    rectInnerContent: {
      display: 'flex',
      flexFlow: 'row wrap',
      backgroundColor: 'white',
      border: 'solid 1px #dcdcdc',
    },
    tableInnerColumnHeader: {
      flex: '33%',
      fontWeight: 'bold',
    },
    tableInnerColumn: {
      flex: '33%',
    },
    tableTextInnerColumn: {
      margin: '0 0 0 18px',
    },
    labelDecor: {
      fontWeight: 'bold',
      width: '10%',
      float: 'left',
    },
    rectangleGroupHeader1: {
      width: '150px',
      height: '20px',
      margin: '0 1002px 8px 0',
      padding: '2px 11px 2px 8px',
      borderRadius: '2px',
      backgroundColor: '#dcdcdc',
    },
    groupHeader1: {
      width: '108px',
      height: '16px',
      fontFamily: 'SegoeUI',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.6',
      letterSpacing: 'normal',
      color: '#323130',
    },
});

const customProjectStyles = {
  container: (provided) => ({
    ...provided,
    width: 300
  })
};

NotificationMasterSelectionPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    projectSummariesData: state.projectSummariesData.projectSummaries,
    notificationMastersData: state.notificationMastersData.notificationMasters,
    eligibleNotificationMastersData: state.eligibleNotificationMastersData.eligibleNotificationMasters,
  };
};

const connected = connect(mapStateToProps, {
  fetchNotificationMasters,
  fetchProjectSummaries,
  fetchEligibleNotificationMasterByDecisionPoint,
 })(AuthProvider(NotificationMasterSelectionPolicy));

export default withStyles(styles)(connected);
