import {
  initializeIcons
} from "@fluentui/react";
import {
  Box, Card, CardContent, Snackbar
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { withStyles } from '@material-ui/styles';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BuilderNameBanner, DescriptionContent, DescriptionHeader } from "../../../components/shared/builder";
import { ERROR, SUCCESS } from "../../../constants/Constants";
import clearUiMessage from "../../../state/actions/UIActions";
import AddNewRefDataTypeValueRecord from './AddNewRefDataTypeValueRecord';
import RefDataTypeValueList from './RefDataTypeValueList';

const RefDataOperations = ({ classes }) => {
  const dispatch = useDispatch();
  const refDataTypeValues = useSelector(
    (state) => state.refData.referenceDataTypeValues
  );
  const selectedRefDataType = useSelector(
    (state) => state.refData.selectedRefDataType
  );
  const uiMessageOpen = useSelector((state) => state.ui.uiMessageOpen);
  const message = useSelector((state) => state.ui.message);
  const result = useSelector((state) => state.ui.result);
  initializeIcons();

  const renderSuccessAlert = () => (
    <Alert
      severity="success"
      onClose={() => {
        dispatch(clearUiMessage());
      }}
    >
      {message}
    </Alert>
  );

  const renderErrorAlert = () => (
    <Alert
      severity="error"
      onClose={() => {
        dispatch(clearUiMessage());
      }}
    >
      <AlertTitle>Error</AlertTitle>
      {message}
    </Alert>
  );

  const renderAlert = () => {
    if (result === SUCCESS) {
      return renderSuccessAlert();
    }
    if (result === ERROR) {
      return renderErrorAlert();
    }
    return null;
  };

  return (
    refDataTypeValues && refDataTypeValues.length > 0 && (
      <Box flexGrow={4}>
        <Card variant="outlined">
          <CardContent className={classes.cardContentBody}>
            <BuilderNameBanner builderName="Reference Data Builder"/>
            <hr className={classes.dividerLine} />
            <div className={classes.headerSection}>
              <DescriptionHeader
                itemName={selectedRefDataType}
                toggleEditDesciptionDetailsDialogHandler={() => {}}
                lastUpdatedDate={"19 NOV 2021"}
              />
              <DescriptionContent descriptionText={selectedRefDataType} descriptionTitle={"REFERENCE DATA DESCRIPTION"}/>
            </div>
            <div className={classes.refDataTypeValuesSection}>
              <div className={classes.refDataTypeValuesSectionTitle}>Reference Data Values</div>
              <RefDataTypeValueList dataList={refDataTypeValues}/>
            </div>
            <div>
              <AddNewRefDataTypeValueRecord refDataType={selectedRefDataType}/>
            </div>
            <Snackbar
              open={uiMessageOpen}
              autoHideDuration={6000}
              onClose={() => {
                dispatch(clearUiMessage());
              }}
            >
              {result && renderAlert()}
            </Snackbar>
          </CardContent>
        </Card>
      </Box>
    )
  );
};

const styles = () => ({
  headerSection: {
    border: '2px solid lightgrey',
    height: '230px',
    borderRadius: "2px",
  },
  dividerLine: {
    borderBottom: "solid 1px #bdbdbd",
    borderTop: "solid 0px #bdbdbd",
    borderLeft: "solid 0px #bdbdbd",
    borderRight: "solid 0px #bdbdbd",
    height: "0px",
    marginBottom: "21px",
  },
  refDataTypeValuesSection: {
    padding: "24px 0px",
  },
  refDataTypeValuesSectionTitle: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#323130",
    paddingBottom: "16px",
  },
  cardContentBody: {
    padding: "16px 32px",
  }
});

export default withStyles(styles)(RefDataOperations);
