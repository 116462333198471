import {
  FETCH_TASK_MASTERS,
  FETCH_TASK_MASTERS_FILTER,
  FETCH_TASK_LIBRARY_MASTERS,
  FETCH_TASK_MASTERS_SUMMARY,
  FETCH_TASK_MASTER_DEPENDENCIES,
  FETCH_TASK_MASTERS_BY_SEARCHSTR,
  FETCH_TASK_MASTERS_BY_CODES,
  FETCH_TASK_MASTERS_BY_CODES_RO

} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  taskMasters: [],
  taskMastersFiltered: [],
  taskMastersBySearchStr: [],
  error: null,
  taskMastersLibrary: [],
  taskMastersSummary: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TASK_MASTERS:
      return { ...state, taskMasters: action.payload, taskMastersFiltered: action.payload };
    case FETCH_TASK_MASTERS_FILTER:
      return { ...state, taskMastersFiltered: action.payload };
    case FETCH_TASK_MASTERS_BY_SEARCHSTR:
      return { ...state, taskMastersBySearchStr: action.payload };
    case FETCH_TASK_MASTERS_BY_CODES:
      return { ...state, taskMastersByCodes: action.payload };
    case FETCH_TASK_MASTERS_BY_CODES_RO:
      return { ...state, taskMastersByCodesRO: action.payload };
      case FETCH_TASK_LIBRARY_MASTERS:
      return { ...state, taskMastersLibrary: action.payload };
    case FETCH_TASK_MASTERS_SUMMARY:
      return { ...state, taskMastersSummary: action.payload };
    case FETCH_TASK_MASTER_DEPENDENCIES:
      return { ...state, taskMasterDependencies: action.payload };
    default:
      return state;
  }
};
