import React from "react";
import { Box } from "@material-ui/core";
import { IconButton, initializeIcons } from "@fluentui/react";
import { withStyles } from '@material-ui/styles';

const DescriptionHeader = ({
 classes, itemName, toggleEditDesciptionDetailsDialogHandler, lastUpdatedDate
}) => {
  initializeIcons();
  return (
    <Box display="flex" className={classes.headerBox}>
      <Box flexGrow={1}>
        <div>
          <div className={classes.titleName}>{itemName}</div>
          <div className={classes.updatedDate}>Last Updated: {lastUpdatedDate}</div>
        </div>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.iconSection}
      >
        <IconButton
          iconProps={{ iconName: "Settings", style: { fontSize: 23 } }}
          styles={{
            root: { color: "white" },
            rootHovered: { backgroundColor: "#0078d4", color: "white" },
          }}
          onClick={() => toggleEditDesciptionDetailsDialogHandler()}
        />
      </Box>
    </Box>
  );
};

const styles = () => ({
  headerBox: {
    backgroundColor: '#0078d4',
    height: '50px',
    color: '#fff',
    padding: '16px 33px',
    borderRadius: "2px",
  },
  titleName: {
    fontSize: '20px',
    fontWeight: '600'
  },
  updatedDate: {
    padding: '8px 0'
  },
  iconSection: {
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
  }
});

export default withStyles(styles)(DescriptionHeader);
