import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import '../../../components/style.css';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
 Checkbox, DefaultButton, PrimaryButton, initializeIcons
} from '@fluentui/react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AuthProvider from '../../../components/auth/AuthProvider';
import SuccessfulMessage from '../../../components/Dialog/SuccessfullMessage';
import { updateChangeItemDetail } from '../../../state/actions/AdminChangeMasterActions';
import ChangeValues from './ChangeValues';
import renderHTMLContent from '../../../util/ConvertUtil';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';
import { fetchReferenceData } from '../../../state/actions/ReferenceDataActions';

const isReadOnly = getReadOnlyFlagPerEnv();

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

const stackTokens = { childrenGap: 40 };

class AdminChangeItemDetail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      show: this.props.open,
      changeDetail: this.props.changeItem,
      keyResourceTitle: this.props.keyResourceTitle,
      isVerified: this.props.changeItem.verified,
      currentDesc: '',
      currentName: '',
      hideDialog: true,
      editorCountReady: false,
      editorCount: 0,
      currentComment: '',
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the description Box</p>',
      open: false,
      showRequiredMsg: false,
      isSaveActivated: true
    };
  }

 async componentDidMount () {
    this.setState({ isVerified: this.state.changeDetail.verified });
    this.setState({ show: this.props.open });
    if (this.state.changeDetail.changeDescription) { this.setState({ currentDesc: this.state.changeDetail.changeDescription }); } else { this.setState({ currentDesc: ' ' }); }

    if (this.state.changeDetail.verificationComment) {
      this.setState({
      currentComment: this.state.changeDetail.verificationComment
      });
    } else { this.setState({ currentComment: ' ' }); }

    initializeIcons();
    await this.props.fetchReferenceData();
  }

  onHandleUpdateChangeItemDetail = () => {
    const changeItem = this.state.changeDetail;
    if (changeItem) {
      changeItem.verified = this.state.isVerified;

      if (this.state.isVerified === true && this.state.currentComment === '') {
        this.setState({ showRequiredMsg: true });
        this.setState({ isSaveActivated: false });
      } else {
        this.setState({ showRequiredMsg: false });
        this.setState({ isSaveActivated: true });
        if (this.state.currentDesc !== '') { changeItem.changeDescription = this.state.currentDesc; } else { changeItem.changeDescription = ''; }
        if (this.state.currentComment !== '') { changeItem.verificationComment = this.state.currentComment; } else { changeItem.verificationComment = ''; }
        this.props.updateChangeItemDetail(changeItem);
      }
    }
  }

  closeDialog = () => {
    this.onHandleUpdateChangeItemDetail();
    this.setState({ hideDialog: true });
  }

  cancelDialog = () => {
    this.setState({ hideDialog: true });
  }

  onHandleVerificationChange = () => {
    this.setState({ isVerified: !this.state.isVerified });
    if (!this.state.isVerified === true && this.state.currentComment === '') {
        this.setState({ showRequiredMsg: true });
        this.setState({ isSaveActivated: true });
    } else {
      this.setState({ showRequiredMsg: false });
      this.setState({ isSaveActivated: false });
    }
  }

  onHandleAddComments = () => {

  }

  handleShowChangeDetailEdit = () => {
    this.setState({ hideDialog: false });
  }

  handleChangeDescription =(event) => {
    this.setState({ currentDesc: event.target.value, isSaveActivated: false });
  }

  onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  render () {
    const { classes } = this.props;
    if (!this.props.changeItem) return <div></div>;
    // eslint-disable-next-line global-require
    // const OliLogo = (this.props.result === 'SUCCESS') ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');
    const OliLogo = require('../../../images/oli-success.svg');
    const selecteChange = '';
    const header = `${this.props.changeCategoryName} Change Summary`;

    return (
      <Panel
      isOpen={this.props.open}
      onDismiss={this.props.onClose}
      type={PanelType.custom}
      customWidth={'650px'}
      closeButtonAriaLabel="Close"
      headerText= {header}
      >
        <Separator></Separator>
        <Stack>
          <Stack.Item className={classes.changeDetail}>
            <span className={classes.fieldNameSubHeading}>{this.props.keyResourceTitle}</span>
          </Stack.Item>
          <Stack.Item  className={classes.viewPolicyBuilder}>
            <button className={classes.viewMoreLink}> View in Builder</button>
          </Stack.Item>
          <Stack.Item className={classes.viewChangeCategory}>
            <span className={classes.fieldNameSubHeading}>{this.props.changeItem.changeCategory.value}</span>
          </Stack.Item>

          <Stack.Item horizontal tokens={stackTokens} className={classes.actions}>
            <DefaultButton text="Cancel"  className={classes.inputbox} onClick={this.props.onClose} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
            <PrimaryButton text="Save"  className={classes.savebtn}
            onClick={() => this.handleShowChangeDetailEdit()} disabled={this.state.isSaveActivated || isReadOnly}/>
          </Stack.Item>
          <Separator></Separator>

          <Stack.Item>
            <span className={classes.fieldNameSubHeading}>Change Description</span>
            <div className={classes.inputbox}>
              <input type="text" value={ this.state.currentDesc} readOnly={isReadOnly}
               onChange={(event) => this.handleChangeDescription(event)}  />
            </div>
          </Stack.Item>

          <Stack className={classes.changeDetail}>
            <Stack.Item>
              <ChangeValues title="UPDATED VALUES" changeprops = {this.props.changeItem?.propertyChanges} source="new" refdata={this.props.refdata}></ChangeValues>
            </Stack.Item>
            <br/>
            <Stack.Item>
              <ChangeValues title="PREVIOUS VALUES" changeprops = {this.props.changeItem?.propertyChanges} source="old" refdata={this.props.refdata}></ChangeValues>
            </Stack.Item>
          </Stack>
          <Separator></Separator>
          <Stack className={classes.verification}>
            <Stack.Item className={classes.changeDetail}>
              <span className={classes.fieldName}>VERIFICATION STATUS</span>
            </Stack.Item>

            <Stack.Item horizontal>
                <Checkbox className={classes.verifiedCheckbox} label="Verified for publication" disabled={isReadOnly}
                 onChange={this.onHandleVerificationChange} checked = {this.state.isVerified}/>
            </Stack.Item>

            <Stack.Item className={classes.changeDetail}>
              <Stack.Item horizontal className={classes.testResultComments}>
                <span >Test result comments</span>
                {this.state.showRequiredMsg ? <span  className={classes.fieldNameInfo} > Comments are Required </span> : ''}
              </Stack.Item>
              <Stack.Item horizontal className={classes.testResultComments}>
                {!isReadOnly ? <Editor
                  apiKey={APIKeys.EditorKey}
                  value={this.state.currentComment}
                  init={{
                    branding: false,
                    height: 300,
                    browser_spellcheck: true,
                    content_style: 'body { color:#605e5c; }',
                    selector: 'textarea',
                    allow_unsafe_link_target: true,
                    element_format: 'xhtml',
                    plugins: [
                      'wordcount advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help'
                    ],
                    paste_preprocess (plugin, args) {
                    },
                    toolbar:
                      'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
                    init_instance_callback: (editor) => {
                      this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                    }
                  }}
                  onEditorChange={(content, editor) => {
                    if ((content !== selecteChange.verificationComment) && ReactHtmlParser(content) !== ReactHtmlParser(selecteChange.verificationComment)) {
                      this.setState({
                        isSaveActivated: false,
                        showRequiredMsg: false,
                        currentComment: content,
                        editorCount: editor.plugins.wordcount.body.getCharacterCount()
                      });
                    }
                  }}
                  onBlur={(event, editor) => {
                    this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                  }}
                /> : renderHTMLContent(this.state.currentComment)}</Stack.Item>
            </Stack.Item>
            <Separator></Separator>

            <Stack.Item className={classes.changeDetail}>
              <span className={classes.fieldName}>TEST RESULTS</span>
            </Stack.Item>

            <Stack.Item>
              <span className={classes.fieldName}>TBA</span>
            </Stack.Item>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={this.props.classes.icon}></ExpandMoreIcon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={this.props.classes.accordionHeader}
              >
                <Typography className={classes.subheader}>
                  More change details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                TBA
              </AccordionDetails>
            </Accordion>

            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={this.props.classes.icon}></ExpandMoreIcon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={this.props.classes.accordionHeader}
              >
                <Typography className={classes.subheader}>
                  Comments
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack.Item horizontal tokens={stackTokens} className={classes.actions}>
                  <DefaultButton text="Add" disabled={isReadOnly} className={classes.inputbox} onClick={this.onHandleAddComments}/>
                </Stack.Item>

                <Stack.Item horizontal tokens={stackTokens}>
                  TBA
                </Stack.Item>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
        {!this.state.hideDialog
            ? <SuccessfulMessage dialogState={false} errorTitle=''
              errorContentArray={[this.props.message, 'Click Okay to continue.']}
              hidden={this.state.hideDialog}
              closeDialog={this.closeDialog}
              cancelDialog={this.cancelDialog}
              OliLogo={OliLogo}
            />
            : ''
        }
      </Panel>
    );
  }
}

const styles = (theme) => ({
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  closeButton: {
    backgroundColor: '#fff',
    border: '0',
    color: '#605e5c',
    fontSize: '18px',
    padding: '0 16px',
    marginRight: '16px',
    cursor: 'pointer',
  },
  actions: {
    padding: '20px 0 20px 0'
  },
  changeDetail: {
    padding: '10px 0 10px 0',
  },
  verifiedCheckbox: {
    marginLeft: '1rem'
  },
  testResultComments: {
   fontWeight: '600',
   color: '#323130',
   marginBottom: '1rem !important',
   marginTop: '1rem !important',
   marginLeft: '1rem !important',
   marginRight: '1rem !important'
  },
  viewPolicyBuilder: {
    float: 'left',
    paddingTop: '1rem'
  },
  viewChangeCategory: {
    float: 'left',
    paddingTop: '2rem'
  },
  inputbox: {
    border: '1px solid',
    marginTop: '1rem'
  },
  savebtn: {
    marginLeft: '2rem'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  fieldRow: {
    padding: '8px 0',
  },
  labeltext: {
    display: 'inline'
  },
  verification: {
    backgroundColor: '#f6f6f6'
  },
  viewTaskCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  viewTaskInCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#555555',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  fieldName: {
    fontWeight: '600',
    color: '#323130',
    marginBottom: '1rem',
    marginTop: '1rem',
    marginLeft: '1rem'
  },
  fieldNameSubHeading: {
    fontWeight: '600',
    color: '#323130',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  fieldNameInfo: {
    fontWeight: '600',
    color: 'red',
    marginBottom: '5px',
    marginLeft: '15px'
  },
  popUpTitle: {
    color: '#323130',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },
  buttonStyle: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#fff',
    color: '#323130',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    cursor: 'pointer'
  },
  headerSection: {
    paddingLeft: '0px'
  },
  buttonSubmit: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#006ed2',
    color: 'white',
    fontWeight: '500',
    orderRadius: '2px',
    border: 'none',
    cursor: 'pointer'
  },
  viewMoreLink: {
    border: '0',
    backgroundColor: '#fff',
    color: '#006ed2',
    fontSize: '14px'
}
});

AdminChangeItemDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
   changeMasterData: state.changeMastersData?.changeMasters,
   changeItemData: state.changeItemData?.changeItem,
   refdata: state.refData.referenceData,
   result: state.ui.result
});

const connected = connect(mapStateToProps, {
    updateChangeItemDetail,
    fetchReferenceData
})(AuthProvider(AdminChangeItemDetail));

export default withStyles(styles)(connected);
