import api from '../../api/IntelligentAgentServiceApi';
import {
  FETCH_REFERENCE_DATA,
  FETCH_REFERENCE_DATA_TYPES,
  REFERENCE_DATA_TYPE_SELECTED,
  UI_SHOW_MESSAGE_ERROR,
  UI_SHOW_MESSAGE_SUCCESS,
  REFERENCE_DATA_TYPE_VALUE_SELECTED,
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchReferenceData = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/reference-data');
    dispatch({ type: FETCH_REFERENCE_DATA, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const referenceDataTypeValueSelected = (refDataTypeValue) => async (dispatch) => {
  try {
    dispatch({ type: REFERENCE_DATA_TYPE_VALUE_SELECTED, payload: refDataTypeValue });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchReferenceDataTypes = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/reference-data/reference-data-types');
    dispatch({ type: FETCH_REFERENCE_DATA_TYPES, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const referenceDataTypeSelected = (refDataType) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/reference-data?type=${refDataType}`);
    const payloadToSend = { refDataValues: response.data, refDataType };
    dispatch({ type: REFERENCE_DATA_TYPE_SELECTED, payload: payloadToSend });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const createReferenceDataValue = (refDataValue, refDataType, refDataSortOrder) => async (dispatch) => {
  try {
    const apiObject = {
      value: refDataValue, type: refDataType, enabled: true
    };
    if (refDataSortOrder) {
      apiObject.sortOrder = refDataSortOrder;
    }
    await api.post('/admin/reference-data', apiObject);
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Reference data value created successfully' });
    dispatch(fetchReferenceDataTypes());
    dispatch(referenceDataTypeSelected(refDataType));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Cannot create reference data value' });
    }
  }
};

export const updateReferenceDataValue = (refDataValue, refDataType) => async (dispatch) => {
  try {
    const apiObject = { ...refDataValue, type: refDataType };
    await api.put('/admin/reference-data', apiObject);
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Reference data value updated successfully' });
    dispatch(fetchReferenceDataTypes());
    dispatch(referenceDataTypeSelected(refDataType));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Cannot update reference data value' });
    }
  }
};

export const deleteReferenceDataValue = (refDataId, refDataType) => async (dispatch) => {
  try {
    await api.delete(`/admin/reference-data/${refDataId}`, refDataId);
    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Reference data value deleted successfully' });
    dispatch(fetchReferenceDataTypes());
    dispatch(referenceDataTypeSelected(refDataType));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Cannot delete reference data value' });
    }
  }
};
