import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
 DefaultButton, PrimaryButton, initializeIcons,
   FontWeights, getTheme, IconButton,  Modal,  TextField
 } from '@fluentui/react';
import { createGuidanceFragment } from '../../../state/actions/GuidanceFragmentActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import renderHTMLContent from '../../../util/ConvertUtil';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';

const isReadOnly = getReadOnlyFlagPerEnv();

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

class CreateGuidanceFragment extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showPopup: true,
      inConfirmation: false,
      currentDesc: '',
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the description Box</p>',
      open: false,
      guidanceItem: '',
      guidanceCode: '',
      guidanceName: '',
      guidanceText: '',
      selectedRequirement: '',
      showMsg: true,
      showCodeMsg: true,
      showDesc: 'This is not a unique guidance code. Update to continue.',
      showNameDesc: 'This is required. Update to continue.',
      showGuidanceDesc: 'This is required. Update to continue.',
      showNameMsg: true,
    };
    initializeIcons();
  }

  async componentDidMount () {
    this.setState({ show: this.props.open });

    this.setState({
      currentDesc: '',
      isBtnActivated: true,
    });
  }

  handleGuidanceCodeChange=(event) => {
    this.setState({ guidanceCode: event.target.value });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.guidanceCode = event.target.value;
    if (event.target.value === '') {
      this.setState({ showCodeMsg: true, showDesc: 'This is not a unique guidance code. Update to continue.' });
    } else {
      const guidancecode = event.target.value.toLowerCase().trim();
      const guidanceData = this.props.guidanceFragmentDataList.filter((guidance) =>  guidance.guidanceFragmentCode.toLowerCase().trim() === guidancecode)[0];
      if (guidanceData) {
        this.setState({ showCodeMsg: true, showDesc: 'This is not a unique guidance code. Update to continue.' });
      } else {
        this.setState({ showCodeMsg: false, showDesc: 'This is a valid guidance code.' });
      }
    }

    if (this.isValidGuidance()) {
      this.setState({ isBtnActivated: true });
    } else {
      this.setState({ isBtnActivated: false });
    }
  }

  handleGuidanceNameChange=(event) => {
    this.setState({ guidanceName: event.target.value });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.guidanceName = event.target.value;
    if (event.target.value === '') { this.setState({ showNameMsg: true, showNameDesc: 'This is required. Update to continue.' }); } else { this.setState({ showNameMsg: false, showNameDesc: '' }); }
    if (this.isValidGuidance()) { this.setState({ isBtnActivated: true }); } else { this.setState({ isBtnActivated: false }); }
  }

  isValidGuidance () {
    if (this.state.guidanceCode !== '' && this.state.guidanceName !== '' && this.state.guidanceDescription !== '') {
      this.setState({ isBtnActivated: false });
      return false;
    }
      this.setState({ isBtnActivated: true });
      return true;
  }

  showAddGuidanceFragmentPopup () {
    const { classes } = this.props;
    const currentDesc = this.state.currentDesc ? this.state.currentDesc : '';
    return (
      <Modal
        isOpen={this.state.showPopup}
        onDismiss={this.hideAddGuidanceFragmentHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep1}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Create Guidance Fragment </span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddGuidanceFragmentHandler}
              />
            </div>
            <Stack className={classes.guidanceDetail}>
              <TextField label="Guidance Reference Code*" value={this.state.guidanceCode} onChange={this.handleGuidanceCodeChange} readOnly={isReadOnly} />
              <Stack horizontal>
                {this.state.showCodeMsg
                  ? <div className={classes.statusError}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} className={classes.iconError} /><span className={classes.statusMsg}>{this.state.showDesc}</span></div>
                  : <div className={classes.statusSuccess}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} className={classes.iconSuccess} /><span className={classes.statusMsg}>{this.state.showDesc}</span></div>
                  }
              </Stack>
            </Stack>
            <Stack className={classes.guidanceDetail}>
              <TextField label="Guidance Fragment Name*" value={this.state.guidanceName} onChange={this.handleGuidanceNameChange} readOnly={isReadOnly} />
              <Stack horizontal>
                {this.state.showNameMsg
                  ? <div className={classes.statusError}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} className={classes.iconError} /><span className={classes.statusMsg}>{this.state.showNameDesc}</span></div>
                  : '' }
              </Stack>
            </Stack>
            <Stack className={classes.guidanceDetail}>
              <span className={classes.fieldName}>Guidance Text</span>
              {!isReadOnly
              ? <>
                <Editor
                apiKey={APIKeys.EditorKey}
                value={this.state.guidanceText}
                init={{
                  branding: false,
                  height: 200,
                  browser_spellcheck: true,
                  content_style: 'body { color:#605e5c; }',
                  selector: 'textarea',
                  allow_unsafe_link_target: true,
                  element_format: 'xhtml',
                  plugins: [
                    'wordcount advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help'
                  ],
                  paste_preprocess (plugin, args) {
                  },
                  toolbar:
                    'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
                  init_instance_callback: (editor) => {
                    this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                  }
                }}
                onEditorChange={(content, editor) => {
                  if (content === '') {
                    this.setState({
                      showMsg: true,
                      showGuidanceDesc: 'This is required. Update to continue.',
                      guidanceText: content,
                      editorCount: editor.plugins.wordcount.body.getCharacterCount()
                    });
                    this.isValidGuidance();
                  } else {
                  this.setState({
                      showMsg: false,
                      showGuidanceDesc: '',
                      guidanceText: content,
                      editorCount: editor.plugins.wordcount.body.getCharacterCount()
                    });
                    this.isValidGuidance();
                  }
                }}
                onBlur={(event, editor) => {
                  this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                }}
              />
              <br/>
              </> : renderHTMLContent(currentDesc)}
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl}>
              <DefaultButton text="Cancel" onClick={this.hideAddGuidanceFragmentHandler} />
              <PrimaryButton text="Create" onClick={this.submitAddGuidanceFragmentRequest} disabled={this.state.isBtnActivated && !isReadOnly}/>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  confirmation () {
    const { classes } = this.props;
    const OliLogo = (this.props.result === 'SUCCESS') ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');
    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideAddGuidanceFragmentHandler}
        isModeless={true}
        containerClassName={classes.containerConfirmation}
      >
        <Stack verticalAlign="space-between" styles={stackStylesConfirmation}>
          <Stack>
            <div className={classes.header}>
              <img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /><span className={classes.title}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddGuidanceFragmentHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.confirmation}>
                <Stack style={{ paddingTop: '15px', fontFamily: 'SegoeUI' }}>
                  <span>{this.props.message}: </span>
                  <span className={classes.successmsg}>{this.state.guidanceCode}-{this.state.guidanceName}</span>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl}>
              <DefaultButton text="Close" onClick={this.hideAddGuidanceFragmentHandler} />
              <PrimaryButton text="View my new guidance" />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  hideAddGuidanceFragmentHandler = () => {
    this.props.hideAddGuidanceFragmentHandler();
    this.setState((prevState) => ({ ...prevState, showPopup: false, inConfirmation: false }));
  }

  submitAddGuidanceFragmentRequest = async () => {
     // eslint-disable-next-line react/no-direct-mutation-state
    const guidanceItem = {
      code: this.state.guidanceCode,
      name: this.state.guidanceName,
      text: this.state.guidanceText
    };
    this.props.createGuidanceFragment(guidanceItem);
    this.setState({ inConfirmation: true, showPopup: false });
  };

  render () {
    return (
      <>
        {this.showAddGuidanceFragmentPopup()}
        {this.confirmation()}
      </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '550px',
    height: '650px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '550px',
    height: '250px',
  },
  header: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '15px 12px 14px 24px',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  statusError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px'
  },
  statusSuccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px'
  },
  iconError: {
    color: 'red'
  },
  iconSuccess: {
    color: 'green'
  },
  statusMsg:
  {
    fontSize: '12px'
  },
  title: {
    fontSize: '20px',
    color: '#323130'
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  successmsg: {
   fontWeight: 'bold'
  },
  btnCtrl: {
     padding: '0 25px 5px',
  },
  dropdownStyles: {
    dropdown: {
      width: 300
    }
  },
  guidanceDetail: {
    padding: '10px 20px 10px 20px',
    paddingBottom: '0.25rem'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  fieldName: {
    color: '#323130',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '600',
   fontFamily: "Segoe UI"
   },
});

const theme = getTheme();
const cancelIcon = { iconName: 'Cancel' };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 5,
  },
};

const stackStylesStep1 = {
  root: {
    height: 300,
  },
};

const stackStylesConfirmation = {
  root: {
    height: 250,
  },
};

const mapStateToProps = (state) => ({
    result: state.ui.result,
    message: state.ui.message,
  });

  CreateGuidanceFragment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  createGuidanceFragment
})(AuthProvider(CreateGuidanceFragment));

export default withStyles(styles)(connected);
