import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchReferenceDataTypes, referenceDataTypeSelected } from '../../../state/actions/ReferenceDataActions';
import LeftPanelFilterAndDataList from '../../../components/shared/builder/LeftPanelFilterAndDataList';

const RefDataTypesList = (props) => {
  const referenceItems = useSelector(
    (state) => state.refData.referenceDataTypes
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchReferenceDataTypes());
  }, [dispatch]);

  const handleListItemClick = (item) => {
    dispatch(referenceDataTypeSelected(item.type));
  };

  return (
    <div>
      {referenceItems.length > 0
        && <LeftPanelFilterAndDataList requestedFilterType="CHANGE_SET"
          filterOptions={{ contentFilters: [] }}
          filterOnclickHandler={() => {}}
          selectedFilters={{}}
          itemList={referenceItems}
          listItemClickHandler={(item) => handleListItemClick(item)}
      />}
    </div>
  );
};

export default RefDataTypesList;
