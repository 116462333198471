import { UserAgentApplication } from 'msal';
import authConfig from './AuthConfiguration';

const scopes = ['openid', 'profile', 'user.read'];

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes
  }
};

export const msalApp = new UserAgentApplication(authConfig.msal);

export const getToken = () => {
  const account = msalApp.getAccount();
  const token = acquireToken({...authConfig.requestObject, loginHint: account.userName});
  return token;
};

export const acquireToken = (request) => {
  return new Promise((resolve) => {
    msalApp.acquireTokenSilent(request).then((response) => {
      if (response.accessToken) {
        resolve(response.accessToken);
      }
    }).catch((_error) => {
      msalApp.acquireTokenRedirect(request);
    });
  });
};

/**
 * Action for initiating a sign-out.
 */
export const signOut = () => {
  msalApp.logout();
};
