import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
 DefaultButton, FontWeights, getTheme, IconButton, initializeIcons, Link, Modal, PrimaryButton, Separator, Stack 
} from '@fluentui/react';

import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../../components/auth/AuthProvider';
import { fetchPolicySchemeConfiguration } from '../../../state/actions/PolicyLibraryMasterActions';
import { publishChangeSet, downloadChangeSet } from '../../../state/actions/AdminChangeSetBuilderActions';

import '../../../images/dot.png';
import './override.css';

class PublishChangeSetDialog extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      publishInitiated: false,

      // windows
      inPromptUserSelection: true,
      inConfirmation: false,

      // selected change set
      changeSet: null,
    };
    initializeIcons();
  }

  /* Functions used to build section of page */

  promptUserSelection () {
    const { classes, isReadOnly, adminChangeSet } = this.props;

    return (
      <Modal
        isOpen={this.state.inPromptUserSelection}
        onDismiss={this.hideDialogHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={modalBaseStyle}>
          <Stack>
            <div className={classes.modalHeader}>
              <span className={classes.modalTitle}>Publish Change Set</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                onClick={this.hideDialogHandler}
              />
            </div>
            <Stack>
              <div className={classes.modalMessage}>Confirm the publication of {adminChangeSet.name} to SIT?</div>
            </Stack>
          </Stack>
          <Stack>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.actionCtrl}>
              <DefaultButton text="Cancel" onClick={this.hideDialogHandler} />
              <PrimaryButton text="Publish" onClick={this.publishChangeSet} disabled={isReadOnly} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  confirmation () {
    const { classes, adminChangeSet } = this.props;

    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideDialogHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={modalBaseStyle}>
          <Stack>
            <div className={classes.modalHeader}>
              <span className={classes.modalTitle}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                onClick={this.hideDialogHandler}
              />
            </div>
            <Stack>
              <div className={classes.modalMessage}>{adminChangeSet.name} has been been published to SIT.  You can now export the script below.</div>
              <div className={classes.modalMessage}>{this.displayExportScript()}</div>
            </Stack>
          </Stack>
          <Stack>
            <Separator />
            <Stack horizontal horizontalAlign="end" className={classes.actionCtrl}>
              <DefaultButton text="Okay" onClick={this.hideDialogHandler} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  /* Event and Action Handlers  */

  hideDialogHandler = () => {
    this.props.onClose();
    this.setState((prevState) => ({ ...prevState, inPromptUserSelection: false, inConfirmation: false }));
  }

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  publishChangeSet = async () => {
    const { adminChangeSet } = this.props;
    await this.props.publishChangeSet(adminChangeSet.id);
    this.setState((prevState) => ({ ...prevState, inPromptUserSelection: false, inConfirmation: true }));
  }

  downloadScript = async () => {
    const { adminChangeSet } = this.props;
    await this.props.downloadChangeSet(adminChangeSet.id);
    const a = document.createElement('a');
    const blob = new Blob([this.props.downloadChangeSetResult], { type: 'application/octet-stream' });
    a.href = window.URL.createObjectURL(blob);
    a.download = 'liquibase.yaml';
    a.click();
  }

  /* Helper Functions */

  displayExportScript () {
    return <Link onClick={this.downloadScript}>Export Script <IconButton iconProps={{ iconName: 'Download', style: { fontSize: 14 }  }} styles={{ root: { color: 'blue' }, rootHovered: { backgroundColor: '#0078d4', color: 'blue' } }} /></Link>;
  }

  /* Render */

  render () {
    return (
      <>
        {this.promptUserSelection()}
        {this.confirmation()}
      </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '260px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '510px',
    height: '260px',
  },
  modalHeader: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '15px 12px 14px 24px',
  },
  modalTitle: {
    fontSize: '20px',
    color: '#323130',
  },
  modalMessage: {
    fontSize: '14px',
    color: '#323130',
    padding: '15px 12px 14px 35px',
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  actionCtrl: {
    padding: '0 25px',
  },
});

const theme = getTheme();

const cancelIcon = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const modalBaseStyle = {
  root: {
    height: 200,
  },
};

const mapStateToProps = (state) => ({
    result: state.ui.result,
    downloadChangeSetResult: state.changeSetData.downloadChangeSetResult,
  });

PublishChangeSetDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  fetchPolicySchemeConfiguration,
  publishChangeSet,
  downloadChangeSet
})(AuthProvider(PublishChangeSetDialog));

export default withStyles(styles)(connected);
