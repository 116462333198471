import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import '../../../components/style.css';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Stack } from '@fluentui/react/lib/Stack';
import AdminChangeMasterListItem from './AdminChangeMasterListItem';

class AdminChangeMasterList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      changeItem: props.changeCategoryItem,
    };
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.changeItem !== nextProps.changeCategoryItem) {
      return { changeItem: nextProps.changeCategoryItem };
    }
    return null;
  }

  render () {
    const { classes } = this.props;
    let show = true;
    // const compareIfCatgeoryExists = (type) => this.props.filterSelections[2]?.items.some((ch) => ch.key === type);
    const compareIfCatgeoryExists = (changeCategoryId) => this.props.filterSelections[2]?.items.some((ch) => ch.key === changeCategoryId);
    if (this.props.filterSelections && this.props.filterSelections[2]?.items.length > 0) {
      // if (this.props.changeCategoryItem.keySystemResources.filter((curr) => compareIfCatgeoryExists(curr.type)).length > 0) { show = true; } else { show = false; }
      if (this.props.changeCategoryItem.keySystemResources.filter((curr) => compareIfCatgeoryExists(curr.changeCategoryId)).length > 0) { show = true; } else { show = false; }
    }
    return (
      <>
        { show
          ? <Box m={1}>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={this.props.classes.icon}></ExpandMoreIcon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={this.props.classes.accordionHeader}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm container className={this.props.classes.subheader}>
                    <Grid item xs container direction="column" spacing={4}>
                      <Grid item xs={12} sm container className={this.props.classes.subheader}>
                        <Stack>
                          <Typography className={classes.accHeading}>
                            {this.state.changeItem?.changeCategoryName}
                          </Typography>
                          <Typography>Last Updated TBA</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.subheader}>
                        TBA
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <AdminChangeMasterListItem
                  keySystemResources={this.state.changeItem?.keySystemResources} changeCategoryName={this.state.changeItem?.changeCategoryName} filterSelections={this.props.filterSelections}></AdminChangeMasterListItem>
              </AccordionDetails>
            </Accordion>
          </Box>
          : ''}
      </>
    );
  }
}

const styles = (theme) => ({
  accHeading: {
    fontSize: '18px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: 'white'
  },
  icon: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    fontSize: 'large !important',
    color: 'white'
  },
  accordionHeader: {
    flexDirection: 'row-reverse',
    backgroundColor: '#0078d4 !important',
    fontSize: '3rem',
    color: 'white',
    height: '84px'
  },
  subheader: {
    fontFamily: 'SegoeUI',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
  },
});

AdminChangeMasterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminChangeMasterList);
