const FilterOptionTypeLiteral = [
    {
        filterType: 'CHANGE_SET',
        title: 'Select filters',
        subTitle: 'Change Set Filters',
    },
    {
        filterType: 'POLICY_BUILDER',
        title: 'Select Policy to edit',
        subTitle: 'Policy Master Filters',
    },
    {
        filterType: 'POLICY_LIBRARY',
        title: 'Select Policy to view',
        subTitle: 'Policy Master Filters',
    },
    {
        filterType: 'HELP_TEXT',
        title: 'Select Name',
        subTitle: 'Help Text Filters',
    },
    {
        filterType: 'GUIDANCE',
        title: 'Select Guidance',
        subTitle: 'Guidance Filters',
    },
    {
        filterType: 'TABLE_MAPPINGS',
        title: 'Select Name',
        subTitle: 'Table Mappings Filters',
    },
    {
        filterType: 'PIP_MAPPINGS',
        title: 'Select Name',
        subTitle: 'PiP Mappings Filters',
    },
    {
        filterType: 'GUIDANCE_FRAGMENT',
        title: 'Select Guidance Fragment to edit',
        subTitle: 'Guidance Fragment Filters',
     },
     {
        filterType: 'CHANGE_CONTROL',
        title: 'Select filters',
        subTitle: 'Change Control Filters',
    },
];

export default FilterOptionTypeLiteral;
