/* eslint no-unused-vars: 0 */  // --> turn rule OFF in favour of clarity
/* eslint no-eval: 0 */  // --> turn rule OFF in favour of clarity

import axios from 'axios';
import { getToken, msalApp } from '../components/auth/AuthUtils';

const local = {
  baseURL: 'http://localhost:8080/intelliagent/v1',
  headers: {
    authorisedUser: msalApp.getAccount() ? JSON.stringify({
      id: '90316b0a-30aa-435f-94fd-e04a64286093',
      userPrincipalName: 'Graeme Smith'
    }) : {}
  }
};

const dev = {
  baseURL: 'https://oli-dev.api.lendlease.com/oli-api',
};

const sit = {
  baseURL: 'https://oli-sit.api.lendlease.com/oli-api',
};

const uat = {
  baseURL: 'https://oli-uat.api.lendlease.com/oli-api',
};

const preprod = {
  baseURL: 'https://oli-pre-prod.api.lendlease.com/oli-api',
};

const prod = {
  baseURL: 'https://oli-prod.api.lendlease.com/oli-api',
};

let axiosConfig = null;
const { hostname } = window.location;
switch (hostname) {
  case 'localhost':
    axiosConfig = local;
    break;
  case 'admin-portal.dev.lendleaseoli.com':
    axiosConfig = dev;
    break;
  case 'admin-portal.sit.lendleaseoli.com':
    axiosConfig = sit;
    break;
  case 'admin-portal.uat.lendleaseoli.com':
    axiosConfig = uat;
    break;
  case 'admin-portal.preprod.lendleaseoli.com':
    axiosConfig = preprod;
    break;
  case 'admin-portal.prod.lendleaseoli.com':
    axiosConfig = prod;
    break;
  default:
    console.error(`Cannot determine environment in window.domain: ${window.location.href}`);
    throw new Error('ERROR: Environment cannot be determined');
}

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use((config) => getToken().then((t) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${t}`;
    return Promise.resolve(config);
  }), (error) => Promise.reject(error));

export default axiosInstance;
