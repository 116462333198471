import api from '../../api/IntelligentAgentServiceApi';

import {
  FETCH_HELPTEXT_MASTERS,
  UI_SHOW_MESSAGE_ERROR,
  UI_SHOW_MESSAGE_SUCCESS,
} from '../../constants/ActionTypes';

import { signOut } from '../../components/auth/AuthUtils';

export const fetchHelpTextMasters = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/field-helpdata');
    dispatch({ type: FETCH_HELPTEXT_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const updateHelpText = (helpText) => async (dispatch) => {
  try {
    const apiObject = {
      id: helpText.id,
      formName: helpText.formName,
      fieldName: helpText.fieldName,
      fieldRef: helpText.fieldRef,
      guidanceText: helpText.guidanceText,
      errorMessages: helpText.errorMessages
    };

    const url = `/admin/field-helpdata/${helpText.id}`;
    await api.put(url, apiObject);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Help Text updated successfully' });
    await dispatch(fetchHelpTextMasters());
  } catch (error) {
     if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Help Text updated failure' });
    }
  }
};
