import {
  NOTIFICATION_MASTERS,
  NOTIFICATION_MASTERS_BY_ELIGIBLE_RECIPIENTS_STRATEGY,
  NOTIFICATION_MASTERS_BY_CATEGORYID,
  NOTIFICATION_LIBRARY,
  NOTIFICATION_LIBRARY_POLICY_CONFIGURATION,
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  notificationMasters: [],
  recipientStrategies: [],
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_MASTERS:
      return { ...state, notificationMasters: action.payload };
    case NOTIFICATION_MASTERS_BY_ELIGIBLE_RECIPIENTS_STRATEGY:
      return { ...state, recipientStrategies: action.payload };
    case NOTIFICATION_LIBRARY:
      return { ...state, notificationLibrary: action.payload };
    case NOTIFICATION_MASTERS_BY_CATEGORYID:
      return {
          ...state,
          notificationMasters: action.payload.masters,
      };
    case NOTIFICATION_LIBRARY_POLICY_CONFIGURATION:
      return {
          ...state,
          notificationMaster: action.payload.notificationMaster,
          policyConfigurations: action.payload.notificationMasterPolicyConfigurations,
      };
    default:
      return state;
  }
};
