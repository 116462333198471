import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from 'react-router-dom';
import { fetchGuidanceFragmentPolicyConfigSummaries } from "../../../../state/actions/GuidanceFragmentActions";
import LeftPanelFilterAndDataList from '../../../../components/shared/builder/LeftPanelFilterAndDataList';
import { createFilterOptionsByRegion, createFilterOptionsByEntityType } from '../../../../util/FilterOptionsUtil';
import { referenceDataTypeSelected } from '../../../../state/actions/ReferenceDataActions';

import history from '../../../../history';

const GuidanceFragmentTypesList = (props) => {
  const selectedFilterMaps = {};
  const params = useParams();
  const selectedGuidanceFragmentId = params.guidanceFragmentId;
  const guidanceFragmentsConfigList = useSelector(
    (state) => (state.guidanceFragmentSummaryData.guidanceFragmentsSummary.records ? state.guidanceFragmentSummaryData.guidanceFragmentsSummary.records : [])
  );

  const regionTypesValues = useSelector(
    (state) => state.refData.referenceDataTypeValues
  );

  const [filterOptions, setFilterOptions] = useState(
    {
      contentFilters:
      [
        { filterType: "Entity", items: [] },
        { filterType: "Region", items: [] },
        { filterType: "Entity Type", items: [] },
      ]
    }
  );
  const [itemData, setItemData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([
    { filterType: "Entity", items: [] },
    { filterType: "Region", items: [] },
    { filterType: "Entity Type", items: [] }]);

  const [filteredItemData, setFilteredItemData] = useState([]);
  let selectedItem = { id: selectedGuidanceFragmentId };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(referenceDataTypeSelected("REGION"));
    dispatch(fetchGuidanceFragmentPolicyConfigSummaries());
  }, [dispatch]);

  useEffect(() => {
    initiateFilterConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guidanceFragmentsConfigList]);

  useEffect(() => {
    constructFilterMap();
    updateFilteredItemData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const constructFilterMap = () => {
    if (selectedFilters.length) {
      selectedFilterMaps.Entity = selectedFilters[0].items;
      selectedFilterMaps.Region = selectedFilters[1].items;
      selectedFilterMaps.EntityType = selectedFilters[2].items;
    }
  };

  const updateFilteredItemData = () => {
    if (selectedFilters.length) {
      const tempDataArray = [];
      const selectedEntityOptions = selectedFilterMaps.Entity.map((a) => a.key);
      const selectedRegionOptions = selectedFilterMaps.Region.map((s) => s.value);
      const selectedEntityTypeOptions = selectedFilterMaps.EntityType.map((s) => s.value);
      for (const item of itemData) {
        if (checkAgainstFiltering(item.entity, selectedEntityOptions)
        && checkAgainstFiltering(item.region, selectedRegionOptions)
        && checkAgainstFiltering(item.entityType, selectedEntityTypeOptions)) {
          tempDataArray.push(item);
        }
      }
      setFilteredItemData(tempDataArray);
    }
  };

  const checkAgainstFiltering = (targetArray, filteringArray) => (filteringArray.length === 0 || targetArray.flatMap((t) => t.split(',')).some((value) => filteringArray.includes(value)));

  const handleListItemClick = (guidanceFragmentId) => {
    const GUIDANCE_FRAGMENT_BUILDER_URL = "/referenceDataConsole/guidanceBuilder/";
    history.push(`${GUIDANCE_FRAGMENT_BUILDER_URL + guidanceFragmentId}`);
  };

  const initiateFilterConfig = () => {
    if (guidanceFragmentsConfigList.length > 0) {
      initiateItemData();
    }
  };

  const initiateItemData = () => {
    if (guidanceFragmentsConfigList.length > 0) {
      const itemList = [];
      for (const [index, configItem] of guidanceFragmentsConfigList.entries()) {
        const itemTemp = {};
        itemTemp.id = configItem.id;
        itemTemp.value = configItem.guidanceFragmentName;
        itemTemp.region = Array.from(getRegionCollection(configItem.policyConfigurations));
        itemTemp.entity = Array.from(getEntityCollection(configItem.policyConfigurations));
        itemTemp.entityType = [];
        itemTemp.entityType.push(configItem.entityType);
        if (itemTemp.id === selectedGuidanceFragmentId) {
          selectedItem = itemTemp;
        }
        itemList.splice(index, 0, itemTemp);
      }
      createFilterOptions(itemList);
      setItemData(itemList);
      setFilteredItemData(itemList);
    }
  };

  const getRegionCollection = (policyConfigurations) =>  {
    const regionSet = new Set();
    for (const config of policyConfigurations) {
      regionSet.add(config.region);
    }
    return regionSet;
  };

  const getEntityCollection = (policyConfigurations) =>  {
    const entitySet = new Set();
    for (const config of policyConfigurations) {
      entitySet.add(config.entity);
    }
    return entitySet;
  };

  const initDefaultEntityOptions = () => {
    const entries = [];
    entries.push({
      key: 'Development',
      value: 'Development',
    });

    entries.push({
      key: 'Construction',
      value: 'Construction',
    });

    const filterEntries = {
      filterType: 'Entity',
      entries: [...entries]
    };
    return filterEntries;
  };

  const createFilterOptions = (itemDataList) => {
    const filterEntity = initDefaultEntityOptions();
    const filterRegion = createFilterOptionsByRegion(regionTypesValues.map((region) => region.value));
    const filterEntityType = createFilterOptionsByEntityType();
    filterEntityType.entries.push({
      key: 'NONE',
      value: 'NONE',
    });
    setFilterOptions({ contentFilters: [filterEntity, filterRegion, filterEntityType] });
  };

  const updateSelectedFilters = (filterSelections) => {
    setSelectedFilters(filterSelections);
  };

  return (
    <div>
      {guidanceFragmentsConfigList.length > 0
        && <LeftPanelFilterAndDataList requestedFilterType="GUIDANCE_FRAGMENT"
          key={123}
          filterOptions={filterOptions}
          filterOnclickHandler={(filterSelections) => { updateSelectedFilters(filterSelections); }}
          selectedFilters={selectedFilters}
          selectedItem={selectedItem}
          itemList={filteredItemData}
          listItemClickHandler={(item) => handleListItemClick(item.id)}
      />}
    </div>
  );
};

export default GuidanceFragmentTypesList;
