import api from '../../api/IntelligentAgentServiceApi';
import {
  FETCH_POLICYLIBRARY_MASTERS,
  FETCH_POLICY_BUILDER_MASTERS,
  FETCH_POLICY_SCHEME_CONFIGURATIONS,
  FETCH_POLICY_BUILDER_MASTERS_FAILED,
  FETCH_POLICYLIBRARY_MASTERS_FAILED,
  SET_POLICY_SCHEME_CONFIGURATIONS,
  FETCH_POLICY_SCHEME_CONFIGURATIONS_NAMES,
  UPDATE_POLICY_MASTER_DETAILS,
  UPDATE_POLICY_SCHEME_PDP_DETAILS,
  CREATE_POLICY_SCHEME_PDP_DETAILS,
  DELETE_POLICY_SCHEME_PDP_DETAILS,
  POLICY_RULE_ACTION_FAILED,
  DUPLICATE_POLICY_SCHEME,
  FETCH_ENTITLEMENT_OPERATION_TYPES,
  CREATE_POLICY_MASTER_DETAILS,
  POLICY_SCHEME_SELECTION_SCOPE_RESOURCE_TYPE_MAPPINGS,
  KEY_SYSTEM_RESOURCE_POLICY_DEFINITION
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchPolicyLibraryMasters = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/policy-library');

    dispatch({ type: FETCH_POLICYLIBRARY_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICYLIBRARY_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const fetchPolicyBuilderMasters = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy/policy-scheme-configuration/${id}`);
    dispatch({ type: FETCH_POLICY_BUILDER_MASTERS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICY_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const fetchPolicySchemeConfiguration = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy/policy-scheme-configuration/${id}`);
    dispatch({ type: FETCH_POLICY_BUILDER_MASTERS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICY_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const fetchPolicySchemeConfigurations = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy/policy-scheme-configurations?policySchemeTypeId=${id}`);
    dispatch({ type: FETCH_POLICY_SCHEME_CONFIGURATIONS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICY_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const setPolicyBuilderConfiguration = (config, param) => async (dispatch) => {
  try {
    const result = config.filter((res1) => param.some((item) => res1.policyScheme.id === item.policyScheme.id));
    dispatch({ type: SET_POLICY_SCHEME_CONFIGURATIONS, payload: result });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchPolicyConfigurationNames = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/policy-engine/policy-attributes-for-validation');
    dispatch({ type: FETCH_POLICY_SCHEME_CONFIGURATIONS_NAMES, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICY_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const updatePolicyMasterDetails = (scheme, id) => async (dispatch) => {
  try {
    const response = await api.put(`/admin/policy-scheme-master/${id}`, scheme);
    dispatch({ type: UPDATE_POLICY_MASTER_DETAILS, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: UPDATE_POLICY_MASTER_DETAILS, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const updatePolicySchemePdpDetails = (pdp, pdpCode) => async (dispatch) => {
  try {
    const response = await api.put(`/admin/policy-engine/pdps/${pdpCode}`, pdp);
    dispatch({ type: UPDATE_POLICY_SCHEME_PDP_DETAILS, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: UPDATE_POLICY_SCHEME_PDP_DETAILS, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const deletePolicySchemePdpDetails = (pdpCode) => async (dispatch) => {
  try {
    const response = await api.delete(`/admin/policy-engine/pdps/${pdpCode}`);
    dispatch({ type: DELETE_POLICY_SCHEME_PDP_DETAILS, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status !== 401)) {
      dispatch({ type: DELETE_POLICY_SCHEME_PDP_DETAILS, payload: { status: error.response.status, message: 'Unexpected error occurred while deleting a pdp.' } });
    } else {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const createNewPolicySchemePdp = (pdp, policySchemeId) => async (dispatch) => {
  try {
    const response = await api.post(`/admin/policy-schemes/${policySchemeId}/pdps`, pdp);
    dispatch({ type: CREATE_POLICY_SCHEME_PDP_DETAILS, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status !== 401)) {
      dispatch({ type: CREATE_POLICY_SCHEME_PDP_DETAILS, payload: { status: error.response.status, message: 'Unexpected error occurred creating a new pdp.' } });
    } else {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const duplicatePolicy = (scheme, id) => async (dispatch) => {
  try {
    const response = await api.post('/admin/policy/duplicate-policy', scheme);
    dispatch({ type: DUPLICATE_POLICY_SCHEME, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: DUPLICATE_POLICY_SCHEME, payload: { status: '409', message: 'Unexpected error occurred while creating a duplicate policy scheme.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const addPolicySchemeDetails = (scheme, id) => async (dispatch) => {
  try {
    const response = await api.post(`/admin/policy-schemes`, scheme);
    dispatch({ type: CREATE_POLICY_MASTER_DETAILS, payload: response.data });
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 409)) {
      dispatch({ type: CREATE_POLICY_MASTER_DETAILS, payload: { status: '409', message: 'Unexpected error occurred while updating composite rules.' } });
    } else if (error.response && error.response.status === 401) {
      dispatch({ type: POLICY_RULE_ACTION_FAILED });
      dispatch(signOut());
    }
  }
};

export const getPolicyEntitlementOperationTypes = () => async (dispatch) => {
  try {
    const response = await api.get(`/admin/policy-engine/policy-entitlement-operation-types`);
    dispatch({ type: FETCH_ENTITLEMENT_OPERATION_TYPES, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchPolicySchemeSelectionScopeMappings = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/policy/policy-scheme-selection-scope-resource-type-mappings');
    dispatch({ type: POLICY_SCHEME_SELECTION_SCOPE_RESOURCE_TYPE_MAPPINGS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICY_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};

export const fetchPolicyKeySystemResourcePolicyDefinition = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/policy/key-system-resource-policy-definition');
    dispatch({ type: KEY_SYSTEM_RESOURCE_POLICY_DEFINITION, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: FETCH_POLICY_BUILDER_MASTERS_FAILED });
      dispatch(signOut());
    }
  }
};
