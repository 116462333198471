/* eslint-disable space-before-function-paren */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
  DefaultButton, PrimaryButton, initializeIcons, IconButton, TextField
} from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { withStyles } from '@material-ui/styles';
import AuthProvider from '../../../components/auth/AuthProvider';
import { addCompositeRuleToPolicy, removeCompositeRuleFromPolicy, modifyCompositeRuleToPolicy } from '../../../state/actions/PolicyRuleActions';
import { PolicySchemePdpDetailsActionTypeLiteral } from '../../../constants/ActionTypeObj';
import { fetchPolicyConfigurationNames } from '../../../state/actions/PolicyLibraryMasterActions';
import './CompositeRuleDetails.css';

class CompositeRuleDetails extends React.Component {
  constructor(props) {
    super(props);
    const literalType = PolicySchemePdpDetailsActionTypeLiteral.filter((value) => value.type === this.props.literalType)[0];
    this.state = {
      literalType,
      isCurrentlyInSelectionMode: true,
      policyTitleIsValid: false,
      policyTextIsValid: false,
      policyText: '',
      policyTitle: '',
      isDeleteSelected: false,
      changeMode: '',
      isInputValid: false,
      showDeleteSelected: false,
      isSaveCompositeRuleButtonClicked: false,
      isDeleteCompositeRuleButtonClicked: false,
    };
    initializeIcons();
  }

  async componentDidMount() {
    if (this.props.mode === 'EDIT') {
      this.setState((prevState) => ({
        ...prevState, policyTitle: this.props.selPolicyCompositeRule.policyTitle, policyText: this.props.selPolicyCompositeRule.policyText, policyTitleIsValid: true, policyTextIsValid: true, changeMode: this.props.mode, isDeleteSelected: false, showDeleteSelected: false, isInputValid: false
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState, policyTitle: '', policyText: '', policyTitleIsValid: false, policyTextIsValid: false, changeMode: this.props.mode, isDeleteSelected: false, showDeleteSelected: false, isInputValid: false
      }));
    }
    await this.props.fetchPolicyConfigurationNames();
  }

  onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  onDismissPolicySchemeCompositeRuleDetailsPanel = () => {
    this.setState({ policyText: '', policyTitle: '' });
    this.props.hidePolicySchemeCompositeRuleDetailsPanelHandler();
  }

  onChangePolicyTitle = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const compositeRuleTitleIsValid = this.validatePolicyTitle(newVal.trim());
    this.setState((prevState) => ({ ...prevState, policyTitle: newVal, policyTitleIsValid: compositeRuleTitleIsValid }));
  }

  onChangePolicyText = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    if (this.state.changeMode === 'EDIT') {
      const compositeRuleTextIsValid = this.validatePolicyText(newVal.trim());
      this.setState((prevState) => ({ ...prevState, policyText: newVal, policyTextIsValid: compositeRuleTextIsValid }));
    } else {
      const compositeRuleTextIsValid = this.validatePolicyText(newVal.trim());
      this.setState((prevState) => ({ ...prevState, policyText: newVal, policyTextIsValid: compositeRuleTextIsValid }));
    }
  }

  onDeleteCompositeRule = () => {
    this.setState((prevState) => ({ ...prevState, isDeleteSelected: true, isCurrentlyInSelectionMode: false }));
  }

  onEditCompositeRule = () => {
    this.setState((prevState) => ({
      ...prevState, changeMode: 'EDIT', isCurrentlyInSelectionMode: true, isDeleteSelected: false, isInputValid: false, isSaveCompositeRuleButtonClicked: false
    }));
  }

  /* Validation handler */
  validatePolicyTitle(polTitle) {
    let valid = true;
    this.props.policySchemeConfigurationsNames.compositeRules.compositeRuleTitles.forEach((crt) => {
      if (crt.trim() === polTitle || polTitle.length === 0) {
        valid = false;
      }
    });

    this.setState((prevState) => ({ ...prevState, isInputValid: valid }));
    return valid;
  }

  validatePolicyText(polText) {
    const valid = true;
    this.setState((prevState) => ({ ...prevState, isInputValid: valid }));
    return valid;
  }

  // eslint-disable-next-line class-methods-use-this
  createValidationStatus(fieldName, status) {
    if (status) {
      return (
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px'
        }}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} styles={{ root: { color: 'green' } }} /><span style={{ fontSize: '12px' }}>This is a valid {fieldName}.</span></div>);
    }
    return (
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px'
      }}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} /><span style={{ fontSize: '12px' }}>This not yet a valid and unique {fieldName}. Update to continue.</span></div>
    );
  }

  /* Action handler */
  saveCompositeRule = async () => {
    if (this.state.changeMode === 'EDIT') {
      const updateCompositeRuleDetails = {
        text: this.state.policyText,
        title: this.state.policyTitle
      };
      await this.props.modifyCompositeRuleToPolicy(updateCompositeRuleDetails, this.props.selPolicyCompositeRule.id || this.props.addCompositeRuleResult.id);
    } else {
      const newCompositeRuleDetails = {
        policySchemeId: this.props.policySchemeId,
        pepId: this.props.pepId,
        pdpId: this.props.pdpId,
        entitlementRulesId: this.props.entitlementRulesId,
        text: this.state.policyText,
        title: this.state.policyTitle
      };
      await this.props.addCompositeRuleToPolicy(newCompositeRuleDetails);
    }
    this.setState((prevState) => ({ ...prevState, isCurrentlyInSelectionMode: false }));
  }

  deleteCompositeRule = async () => {
    await this.props.removeCompositeRuleFromPolicy(this.props.selPolicyCompositeRule.id);
    this.setState((prevState) => ({ ...prevState, isDeleteSelected: false, showDeleteSelected: true }));
  }

  /* Screens */

  buildPolicyCompositeRuleEditor() {
    const { classes, isReadOnly } = this.props;
    const currentTitle = this.state.policyTitle;
    const currentText = this.state.policyText;
    const currentMode = this.state.changeMode;
    const readOnlyVar = !!(!isReadOnly && this.state.isInputValid);
    const btnText = currentMode === 'EDIT' ? 'Save' : 'Add';
    return (
      <>
        {currentMode === 'EDIT' ? <>
          <div className={classes.grid2}>
            <div className={classes.msgPos}>
              <div className={classes.msgText}>{currentText}</div>
              <br />
              <div className={classes.msgText}>{currentTitle}</div>
            </div>
            <div className={classes.grid3}>
              <div className={classes.divider1}></div>
              <div className={classes.msgPos}>
                <IconButton
                  iconProps={{ iconName: "Delete" }}
                  onClick={this.onDeleteCompositeRule}
                  styles={{
                    root: { height: "auto" },
                    icon: { fontSize: "20px", margin: "30px 10px 30px 10px", color: 'grey' },
                    rootHovered: { backgroundColor: "#fff" },
                  }}
                  disabled={isReadOnly}
                ></IconButton>
              </div>
            </div>
          </div>
        </>
          : <></>
        }

        <Stack horizontal tokens={stackTokens} className={classes.actions}>
          <DefaultButton text="Cancel" onClick={this.onDismissPolicySchemeCompositeRuleDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} disabled={this.state.isSaveCompositeRuleButtonClicked} />
          <PrimaryButton text={btnText} onClick={() => { this.setState({ isSaveCompositeRuleButtonClicked: true }); this.saveCompositeRule(); }} disabled={!readOnlyVar || this.state.isSaveCompositeRuleButtonClicked} />
        </Stack>
        <Separator></Separator>
        <Stack>
          <div className={classes.sectionTitle}>COMPOSITE RULE DETAILS</div>
        </Stack>
        <Stack tokens={stackTokens}>
          <Stack className={classes.inputArea}>
            <TextField label="Composite Rule Text" value={currentText} onChange={this.onChangePolicyText} maxLength={500} readOnly={isReadOnly} />
            {currentText !== ''
              ? <Stack horizontal>
                {this.createValidationStatus('Composite Rule Text', this.state.policyTextIsValid)}
              </Stack>
              : ''
            }
          </Stack>
        </Stack>
        <div className={classes.spacer}></div>
        <Stack tokens={stackTokens}>
          <Stack className={classes.inputArea}>
            <TextField label="Composite Rule Title" value={currentTitle} onChange={this.onChangePolicyTitle} maxLength={500} readOnly={isReadOnly} />
            {currentTitle !== ''
              ? <Stack horizontal>
                {this.createValidationStatus('Composite Rule Title', this.state.policyTitleIsValid)}
              </Stack>
              : ''
            }
          </Stack>
        </Stack>
      </>
    );
  }

  buildDeleteConfirmationScreen() {
    const { classes, isReadOnly } = this.props;
    return (
      <div className={classes.card} style={{ textAlign: 'center' }} >
        <Stack>
          <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>Confirm Deletion of Composite Rule</div>
        </Stack>
        <Stack>
          <div className={classes.msg}>Confirm the deletion of:</div><br />
          <div className={classes.polText}>{this.state.policyTitle}</div><br />
          <div className={classes.polText}>{this.state.policyText}</div><br />
        </Stack>
        <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
          <DefaultButton text="Cancel" onClick={this.onDismissPolicySchemeCompositeRuleDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Close')} disabled={this.state.isDeleteCompositeRuleButtonClicked} />
          <DefaultButton text="Delete" onClick={() => { this.setState({ isDeleteCompositeRuleButtonClicked: true }); this.deleteCompositeRule(); }} onRenderText={(p) => this.onRenderButtonText(p, 'Delete')} disabled={isReadOnly || this.state.isDeleteCompositeRuleButtonClicked} />
        </Stack>
      </div>
    );
  }

  buildConfirmationScreen() {
    const { classes } = this.props;
    let msgDesc = '';
    if (this.state.changeMode === 'EDIT') {
      if (this.state.showDeleteSelected) {
        msgDesc = 'You have successfully deleted Composite Rule';
      } else {
        msgDesc = 'You have successfully updated Composite Rule';
      }
    } else if (this.state.changeMode === 'ADD') { msgDesc = 'You have successfully created Composite Rule'; }
    return (
      <div className={classes.card} style={{ textAlign: 'center' }} >
        <Stack>
          <div className={classes.todo}><img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /></div>
        </Stack>
        <Stack>
          <div className={classes.sectionTitle} style={{ fontSize: '16px' }}>Success</div>
        </Stack>
        <Stack>
          <div className={classes.msg}>{msgDesc}</div><br />
          <div className={classes.polText}>{this.state.policyText}</div><br />
          <div className={classes.polText}>{this.state.policyTitle}</div>
        </Stack>
        <Stack horizontal tokens={stackTokens} className={classes.actions} style={{ display: 'flex', justifyContent: 'center' }}>
          <DefaultButton text="Close" onClick={this.onDismissPolicySchemeCompositeRuleDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Close')} />
          {!this.state.showDeleteSelected
            ? <DefaultButton text="Edit Composite Rule" onClick={this.onEditCompositeRule} onRenderText={(p) => this.onRenderButtonText(p, 'Edit Composite Rule')} />
            : ''}
        </Stack>
      </div>
    );
  }

  render() {
    const { literalType } = this.state;
    // eslint-disable-next-line no-return-assign
    return (
      <Panel
        isOpen={this.props.isPolicySchemeCompositeRuleDetailsPanelOpen}
        onDismiss={this.onDismissPolicySchemeCompositeRuleDetailsPanel}
        type={PanelType.custom}
        customWidth={'650px'}
        closeButtonAriaLabel="Close"
        headerText={literalType.title}
      >
        <Separator></Separator>
        {this.state.isCurrentlyInSelectionMode
          ? this.buildPolicyCompositeRuleEditor()
          : <>
            {this.state.isDeleteSelected ? this.buildDeleteConfirmationScreen() : this.buildConfirmationScreen()}
          </>
        }
      </Panel>
    );
  }
}

const OliLogo = require('../../../images/oli-success.svg');

const stackTokens = { childrenGap: 40 };

const styles = (theme) => ({
  rootBackground: {
    height: '100%',
    backgroundColor: '#f3f2f1'
  },
  root: {
    flexGrow: 1
  },
  title: {
    padding: '60px 0 60px 0',
    color: '#605e5c',
    backgroundColor: '#fff',
  },
  inputArea: {
    padding: '0 10px 10px 10px',
    borderRadius: '2px',
  },
  msg: {
    fontSize: '16px'
  },
  msgPos: {
    justifyContent: 'center',
    marginTop: '5px'
  },
  msgPosIcon: {
    fontSize: '16px'
  },
  msgText: {
    fontSize: '18px'
  },
  polText: {
    fontSize: '16px',
    fontWeight: '600'
  },
  spacer: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  grid2: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridTemplateColumns: '7fr 3fr',
    gridGap: '20px',
    padding: '16px 0 0 16px',
  },
  grid3: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 9fr',
  },
  divider1: {
    height: '100px',
    width: '1px',
    border: 'solid 1px #cdcdcd',
    backgroundColor: '#f3f2f1',
  },
  sectionTitle: { // used
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '15px 0 15px 0',
  },
  actions: {
    padding: '20px 0 20px 0'
  },
  card: { // used
    padding: '15px', /* JUST TO LOOK COOL */
    border: '1px solid #eee',  /* JUST TO LOOK COOL */
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
    transition: 'all .3s ease-in-out',
  },
});

CompositeRuleDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  policySchemeConfigurationsNames: state.policyLibraryBuilderData.policySchemeConfigurationsNames,
  updatePolicySchemePdpResult: state.policyLibraryMasterData.updatePolicySchemePdpResult,
  addCompositeRuleResult: state.policyRuleData.addCompositeRuleResult,
});

const connected = connect(mapStateToProps, {
  fetchPolicyConfigurationNames,
  addCompositeRuleToPolicy,
  removeCompositeRuleFromPolicy,
  modifyCompositeRuleToPolicy
})(AuthProvider(CompositeRuleDetails));

export default withStyles(styles)(connected);
