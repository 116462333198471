import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { initializeIcons } from '@fluentui/react';
import ReactHtmlParser from 'react-html-parser';
import './../style.css';

class SuccessfullMessage extends React.Component {
    constructor (props) {
        super(props);     
        initializeIcons();
      }
    render()
    {
       return (
            <div className={'errorDialog'}>
                <Dialog
                    hidden={this.props.hidden}
                    onDismiss={this.props.cancelDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        closeButtonAriaLabel: 'Close',
                        titleProps: { className: 'errorDialogHeader' },
                        title: <div className='errorDialogHeaderInner'>
                        <img className='errorDialogHeaderLogo' src={this.props.OliLogo} alt={'olilogo'} />
                        <h2 className='errorDialogHeaderTitle'>{this.props.errorTitle}</h2></div>
                    }}
                    modalProps={{
                        isBlocking: true,
                        containerClassName: 'errorDialog'
                    }}
                >
                    {ReactHtmlParser(this.props.errorContent)}
                    {this.props.errorContentArray ?
                    <ul className='errorDialogList'>
                    {this.props.errorContentArray.map((item, i) => {
                        return (
                        <li className='errorDialogListItem' key={i}><div dangerouslySetInnerHTML={{ __html: item }} /></li>
                        );
                    })}
                    </ul>: ""}
                    <DialogFooter
                        className='errorDialogFooter'
                    >
                    <DefaultButton className='errorDialogDefaultBtnBlack' text="Okay" onClick={this.props.closeDialog} />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}

export default SuccessfullMessage;

