/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable operator-linebreak */
/* eslint-disable space-before-function-paren */
/* eslint-disable-next-line consistent-return */
import React from "react";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
// eslint-disable-next-line import/no-duplicates
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack, StackItem } from "@fluentui/react/lib/Stack";
import {
  DefaultButton,
  PrimaryButton,
  initializeIcons,
  TextField,
} from "@fluentui/react";
import { Editor } from '@tinymce/tinymce-react';
import {
  addNotificationRoleMappings,
  addNotificationFunctionMappings,
  addNotificationRecipientStrategyMappings,
  addNotificationAssociatedEventTypeMappings,
  addNotificationFunction,
  addChangeTypeEventTypeMapping,
  addRequiredFieldsDecisionPointTypeMapping,
  addManagedFieldsDecisionPointTypeMapping,
  addRequiredFieldsCheckpointTypeMapping,
  addPRRCriteriaCategoryMapping,
  addPRRCriteriaEntityMapping,
  addPRRCriteriaTagMapping,
  addPRRCriteria,
  addCriteriaOption,
  fetchAllChangeTypeEventTypeMappings,
  fetchAllTableMappings,
  fetchAllFunctions,
  fetchNotificationMsaterRoleMappings,
  fetchAllEventTypes,
  fetchAllRequiredFieldsDecisionPointTypeMappings,
  fetchAllManagedFields,
  fetchAllManagedFieldsDecisionPointTypeMappings,
  fetchAllRequiredFieldsCheckpointTypeMappings,
  fetchAllPRRCriteriaCategories,
  fetchAllPRRCriterias,
  fetchAllPRRCriteriaEntities,
  fetchAllPRRCriteriaTags,
} from "../../../state/actions/MappingTablesActions";
import { fetchNotificationMasters } from "../../../state/actions/NotificationMastersAction";
import "../reference.css";
import AuthProvider from "../../../components/auth/AuthProvider";
import SuccessfulMessage from "../../../components/Dialog/SuccessfullMessage";
import { PRR_Criteria_Category, OPTION_VALUES } from "./TableConstants";

export const onRenderCaretDown = () => <Icon iconName="ChevronDownMed" />;

const dropdownStyles = { dropdown: { width: 250 } };
const dropdownStyleCategory = { dropdown: { width: 150 } };

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};
let editorCharLeft = 0;

const stackTokens = {
  childrenGap: 5,
  padding: 14,
};

class AddTableMappings extends React.Component {
  // eslint-disable-next-line space-before-function-paren
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      selectedPipId: "",
      hideDialog: true,
      disableAddValueButton: true,
      selectedPip: null,
      showAddValueSection: false,
      selectedDropdownValues: [],
      isSaved: false,
      tableColNames: [],
      tableMappings: [],
      tblDropDownOptions: [],
      newTblColValues: {},
      isAddFreeText: false,
      isAddNewValue: true,
      isLoading: false,
      isUnique: false,
      isSelectAll: false,
      selectedCategories: [],
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the Summary Box</p>',
      randomIndex: 0
    };
    initializeIcons();
  }

  componentDidMount() {
    const { selectedPip, selectedDropdownValues, tableColNames } = this.props;

    this.setState({ selectedPip, selectedDropdownValues, tableColNames });
  }

  // eslint-disable-next-line space-before-function-paren
  componentDidUpdate(preProps) {
    const { tableColNames, selectedDropdownValues, selectedPip } = this.props;

    if (tableColNames && tableColNames !== preProps.tableColNames) {
      this.setState({
        tableColNames,
        isSelectAll: false,
        selectedDropdownValues,
        selectedPip,
      });
    }
  }

  showAddValue = () => {
    const showAddValueSection = !this.state.showAddValueSection;
    this.setState({ showAddValueSection });
  };

  getSelectedKey = (attrId) => {
    const { selectedDropdownValues, selectedPip } = this.state;

    if (selectedDropdownValues.length === 0) this.props.processSelectedTable(selectedPip);

    const selectedControlIndex = selectedDropdownValues.findIndex(
      (item) => item.key === attrId
    );

    if (selectedControlIndex >= 0) {
      return selectedDropdownValues[selectedControlIndex].value;
    }

    return "";
  };

  handleTextChange = (event) => {
    const controlId = event.target.id.substr(4);
    const newVal = event.target.value ? event.target.value : "";

    const { newTblColValues, selectedDropdownValues, selectedPip } = this.state;

    let isAddNewValue = true;
    // eslint-disable-next-line one-var
    let isUnique,
      isSelectAll = false;
    let isExist;

    const selectedControlIndex = selectedDropdownValues.findIndex(
      (item) => item.key === controlId
    );

    if (selectedControlIndex >= 0) {
      selectedDropdownValues[selectedControlIndex].value = newVal;
    }

    const newCount = selectedDropdownValues.filter((item) => item.value === "");

    switch (controlId) {
      case "code":
        newTblColValues.code = newVal;
        break;
      case "name":
        newTblColValues.name = newVal;
        break;
      case "description":
        newTblColValues.description = newVal;
        break;
      case "ref":
        newTblColValues.prrRef = newVal;
        break;
      case "question":
        newTblColValues.question = newVal;
        break;
      case "order":
        newTblColValues.sortOrder = Number(newVal);
        break;
      case "option":
        newTblColValues.criteriaOption = newVal;
        break;
      case "criteria":
        newTblColValues.title = newVal;
        break;
      default:
        break;
    }

    if (newCount.length === 0) {
      switch (selectedPip.configValue) {
        case "Notification Function":
          isExist = this.props.functions.filter(
            (nM) => nM.code.trim().toLowerCase() ===
              newTblColValues.code.trim().toLowerCase() &&
              nM.name.trim().toLowerCase() ===
              newTblColValues.name.trim().toLowerCase() &&
              nM.description.trim().toLowerCase() ===
              newTblColValues.description.trim().toLowerCase()
          );
          break;
        case "Risk Rating Criteria":
          isExist = this.props.criterias.filter(
            (nM) => nM.prrRef.trim().toLowerCase() ===
              newTblColValues.prrRef.trim().toLowerCase() ||
              (nM.title.trim().toLowerCase() ===
                newTblColValues.title.trim().toLowerCase() &&
                nM.question.trim().toLowerCase() ===
                newTblColValues.question.trim().toLowerCase())
          );
          break;
        case "Risk Rating Criteria Option":
          isExist = this.props.criterias.filter((nM) => {
            if (nM.options) {
              return (
                nM.criteriaId === newTblColValues.criteriaId &&
                nM.options.find(
                  (func) => func.value === newTblColValues.value
                )
              );
            }
          });

          break;
        default:
          break;
      }

      if (isExist && isExist.length === 0) {
        isUnique = true;
        isAddNewValue = false;
      }

      isSelectAll = true;
    }

    this.setState({
      isAddNewValue,
      isUnique,
      isSelectAll,
      newTblColValues,
      selectedDropdownValues,
    });
  };

  changeTblOptionHandler = (e, i, control, index) => {
    const { newTblColValues, selectedDropdownValues, tableColNames } =
      this.state;

    let isAddNewValue = true;
    let isUnique = false;
    let isSelectAll = false;
    let isExist;
    const controlName = control ? control.colName : e.target.id;
    const controlId = control ? control.colId : e.target.id;

    const selectedControlIndex = selectedDropdownValues.findIndex(
      (item) => item.key === controlId
    );

    if (selectedControlIndex >= 0) {
      selectedDropdownValues[selectedControlIndex].value = i.key;
    }

    const newCount = selectedDropdownValues.filter((item) => item.value === "");

    if (newCount.length === 0) {
      isAddNewValue = false;
      isSelectAll = true;
    }

    switch (controlName) {
      case "NotificationMaster":
        newTblColValues.notificationMasterId = i.key;

        if (newCount.length === 0) {
          switch (tableColNames[1]) {
            case "Role Name":
              isExist = this.props.roleMappings.filter(
                (nM) => nM.notificationMasterId === i.key &&
                  nM.notificationRoles.findIndex(
                    (role) => role.id === selectedDropdownValues[1].value
                  ) > -1
              );
              break;
            case "Notification Recipient Strategy":
              isExist = this.props.notificationMasters.filter((nM) => {
                if (nM.recipientStrategies) {
                  return (
                    nM.id === i.key &&
                    nM.recipientStrategies.indexOf(
                      selectedDropdownValues[1].value
                    ) > -1
                  );
                }
              });
              break;
            case "Notification Function":
              isExist = this.props.cpTypeRequiredFields.filter((nM) => {
                if (nM.checkPointTypes) {
                  return (
                    nM.managedFieldId === i.key &&
                    nM.checkPointTypes.findIndex(
                      (func) => func.id === selectedDropdownValues[1].value
                    ) > -1
                  );
                }
              });
              break;
            case "Oli Event Type":
              isExist = this.props.notificationMasters.filter((nM) => {
                if (nM.associatedEventTypes) {
                  return (
                    nM.id === i.key &&
                    nM.associatedEventTypes.indexOf(
                      selectedDropdownValues[1].value
                    ) > -1
                  );
                }
              });
              break;
            default:
              break;
          }
        }
        break;
      case "Role Name":
        newTblColValues.notificationRole = { roleId: i.key };

        if (newCount.length === 0) {
          isExist = this.props.roleMappings.filter(
            (nM) => nM.notificationMasterId === selectedDropdownValues[0].value &&
              nM.notificationRoles.findIndex((role) => role.id === i.key) > -1
          );
        }
        break;
      case "Notification Recipient Strategy":
        newTblColValues.notificationRecipientStrategy = {
          recipientStrategyId: i.key,
        };

        if (newCount.length === 0) {
          isExist = this.props.notificationMasters.filter((nM) => {
            if (nM.recipientStrategies) {
              return (
                nM.id === selectedDropdownValues[0].value &&
                nM.recipientStrategies.indexOf(i.key) > -1
              );
            }
          });
        }
        break;
      case "Notification Function":
        newTblColValues.notificationFunction = { functionId: i.key };
        if (newCount.length === 0) {
          isExist = this.props.notificationMasters.filter((nM) => {
            if (nM.functions) {
              return (
                nM.id === selectedDropdownValues[0].value &&
                nM.functions.findIndex((func) => func.id === i.key) > -1
              );
            }
          });
        }
        break;
      case "Change Type":
        newTblColValues.changeTypeId = i.key;
        if (newCount.length === 0) {
          isExist = this.props.changeEventTypes.filter((nM) => {
            if (nM.associatedEventTypes) {
              return (
                nM.changeTypeId === i.key &&
                nM.associatedEventTypes.indexOf(
                  selectedDropdownValues[1].value
                ) > -1
              );
            }
          });
        }
        break;
      case "Oli Event Type":
        if (tableColNames.includes("Change Type")) {
          newTblColValues.changeAssociatedEventType = { eventType: i.text };

          if (newCount.length === 0) {
            isExist = this.props.changeEventTypes.filter((nM) => {
              if (nM.associatedEventTypes) {
                return (
                  nM.changeTypeId === selectedDropdownValues[0].value &&
                  nM.associatedEventTypes.indexOf(i.text) > -1
                );
              }
            });
          }
        } else {
          newTblColValues.notificationAssociatedEventType = {
            notificationAssociatedEventType: { associatedEventType: i.text },
          };

          if (newCount.length === 0) {
            isExist = this.props.notificationMasters.filter((nM) => {
              if (nM.associatedEventTypes) {
                return (
                  nM.id === selectedDropdownValues[0].value &&
                  nM.associatedEventTypes.indexOf(i.text) > -1
                );
              }
            });
          }
        }
        break;
      case "Managed Field":
        newTblColValues.managedFieldId = i.key;
        if (newCount.length === 0) {
          switch (tableColNames[1]) {
            case "Managed Field":
              isExist = this.props.dpTypeManagedFields.filter((nM) => {
                if (nM.managedFields) {
                  return (
                    nM.decisionPointType.id ===
                    selectedDropdownValues[0].value &&
                    nM.managedFields.findIndex(
                      (func) => func.fieldId === i.key
                    ) > -1
                  );
                }
                return false;
              });
              break;
            case "Decision Point Type":
              isExist = this.props.requiredFieldsDPTypes.filter((nM) => {
                if (nM.decisionPointTypes) {
                  return (
                    nM.managedFieldId === i.key &&
                    nM.decisionPointTypes.findIndex(
                      (func) => func.id === selectedDropdownValues[0].value
                    ) > -1
                  );
                }
                return false;
              });
              break;
            case "Checkpoint Type":
              // eslint-disable-next-line array-callback-return
              isExist = this.props.cpTypeRequiredFields.filter((nM) => {
                if (nM.checkPointTypes) {
                  return (
                    nM.managedFieldId === i.key &&
                    nM.checkPointTypes.findIndex(
                      (func) => func.id === selectedDropdownValues[1].value
                    ) > -1
                  );
                }
                return false;
              });
              break;
            default:
              break;
          }
        }
        break;
      case "Decision Point Type":
        newTblColValues.decisionPointTypeMapping = {
          decisionPointTypeId: i.key,
        };
        if (
          newCount.length === 0 &&
          tableColNames[1] === "Decision Point Type"
        ) {
          isExist = this.props.requiredFieldsDPTypes.filter((nM) => {
            if (nM.decisionPointTypes) {
              return (
                nM.managedFieldId === selectedDropdownValues[0].value &&
                nM.decisionPointTypes.findIndex((func) => func.id === i.key) >
                -1
              );
            }
            return false;
          });
        }
        break;
      case "Checkpoint Type":
        newTblColValues.checkpointTypeMapping = { checkpointTypeId: i.key };
        if (newCount.length === 0) {
          isExist = this.props.cpTypeRequiredFields.filter((nM) => {
            if (nM.checkPointTypes) {
              return (
                nM.managedFieldId === selectedDropdownValues[0].value &&
                nM.checkPointTypes.findIndex((func) => func.id === i.key) > -1
              );
            }
            return false;
          });
        }
        break;
      case "Risk Category Type":
        newTblColValues.riskCategoryType = { categoryType: i.text };
        if (newCount.length === 0) {
          isExist = this.props.criteriaCategories.filter((nM) => {
            if (nM.riskCategories) {
              return (
                nM.criteriaId === selectedDropdownValues[0].value &&
                nM.riskCategories.indexOf(i.text) > -1
              );
            }
            return false;
          });
        }
        break;
      case "Risk Rating Criteria":
        newTblColValues.criteriaId = i.key;

        if (newCount.length === 0) {
          switch (tableColNames[1]) {
            case "Risk Category Type":
              isExist = this.props.criteriaCategories.filter((nM) => {
                if (nM.riskCategories) {
                  return (
                    nM.criteriaId === i.key &&
                    nM.riskCategories.indexOf(selectedDropdownValues[1].value) >
                    -1
                  );
                }
                return false;
              });
              break;
            case "Risk Rating Entity":
              isExist = this.props.criteriasEntities.filter((nM) => {
                if (nM.llEntities) {
                  return (
                    nM.criteriaId === i.key &&
                    nM.llEntities.findIndex(
                      (func) => func.id === selectedDropdownValues[1].value
                    ) > -1
                  );
                }
                return false;
              });
              break;
            case "Tag Name":
              isExist = this.props.criteriasTags.filter((nM) => {
                if (nM.tags) {
                  return (
                    nM.criteriaId === i.key &&
                    nM.tags.filter(
                      (tag) => tag.tagName === selectedDropdownValues[1].value &&
                        tag.tagValues.findIndex(
                          (value) => value.tagValue === selectedDropdownValues[2].value
                        ) > -1
                    ).length > 0
                  );
                }
                return false;
              });
              break;
            default:
              break;
          }
        }
        break;
      case "Criteria":
        newTblColValues.criteriaId = i.key;
        isExist = this.props.criterias.filter((nM) => {
          if (nM.options) {
            return (
              nM.criteriaId === i.key &&
              nM.options.findIndex(
                (func) => func.value === newTblColValues.value
              ) > -1
            );
          }
        });
        break;
      case "Value":
        newTblColValues.value = i.key;
        isExist = this.props.criterias.filter((nM) => {
          if (nM.options) {
            return (
              nM.criteriaId === newTblColValues.criteriaId &&
              nM.options.findIndex(
                (func) => func.value === i.key
              ) > -1
            );
          }
        });
        break;
      case "Risk Rating Entity":
        newTblColValues.riskRatingCriteriaEntity = { entityId: i.key };
        if (newCount.length === 0) {
          isExist = this.props.criteriasEntities.filter((nM) => {
            if (nM.tags) {
              return (
                nM.criteriaId === i.key &&
                nM.tags.findIndex(
                  (func) => func.tagName === selectedDropdownValues[1].value
                ) > -1 &&
                nM.tags.filter(
                  (tag) => tag.findIndex(
                    (value) => value.tagValue === selectedDropdownValues[2].value
                  ) > -1
                ).length > 0
              );
            }
            return false;
          });
        }
        break;
      case "Tag Name":
        newTblColValues.riskRatingCriteriaTag = {
          tagName: i.text,
          ...newTblColValues.riskRatingCriteriaTag,
        };
        if (newCount.length === 0) {
          isExist = this.props.criteriasTags.filter((nM) => {
            if (nM.tags) {
              return (
                nM.criteriaId === selectedDropdownValues[0].value &&
                nM.tags.filter(
                  (tag) => tag.tagName === i.text &&
                    tag.tagValues.findIndex(
                      (value) => value.tagValue === selectedDropdownValues[2].value
                    ) > -1
                ).length > 0
              );
            }
            return false;
          });
        }
        break;
      case "Tag Value":
        newTblColValues.riskRatingCriteriaTag = {
          ...newTblColValues.riskRatingCriteriaTag,
          tagValue: i.text,
        };
        if (newCount.length === 0) {
          isExist = this.props.criteriasTags.filter((nM) => {
            if (nM.tags) {
              return (
                nM.criteriaId === selectedDropdownValues[0].value &&
                nM.tags.filter(
                  (tag) => tag.tagName === selectedDropdownValues[1].value &&
                    tag.tagValues.findIndex(
                      (value) => value.tagValue === i.text
                    ) > -1
                ).length > 0
              );
            }
            return false;
          });
        }
        break;
      case "category":
        // eslint-disable-next-line no-nested-ternary
        newTblColValues.riskCategories = newTblColValues.riskCategories
          ? i.selected && newTblColValues.riskCategories.indexOf(i.key) < 0
            ? [...newTblColValues.riskCategories, i.key]
            : newTblColValues.riskCategories.filter((key) => key !== i.key)
          : [i.key];

        if (newCount.length === 0) {
          isExist = this.props.criterias.filter(
            (nM) => nM.prrRef.trim().toLowerCase() ===
              newTblColValues.prrRef.trim().toLowerCase() ||
              (nM.title.trim().toLowerCase() ===
                newTblColValues.title.trim().toLowerCase() &&
                nM.question.trim().toLowerCase() ===
                newTblColValues.question.trim().toLowerCase())
          );
        }
        break;
      default:
    }

    if (isExist && isExist.length === 0) {
      isUnique = true;
    } else {
      isAddNewValue = true;
    }

    this.setState({
      isAddNewValue,
      isUnique,
      isSelectAll,
      newTblColValues,
      selectedDropdownValues,
    });
  };

  addNewTblValues = async () => {
    const { newTblColValues, selectedPip } = this.state;

    switch (selectedPip.configValue) {
      case "Notification Function":
        await this.props.addNotificationFunction(newTblColValues);
        await this.props.fetchAllFunctions();
        break;
      case "Notification Master Recipient Strategy Mapping":
        await this.props.addNotificationRecipientStrategyMappings(
          newTblColValues.notificationMasterId,
          newTblColValues.notificationRecipientStrategy
        );
        await this.props.fetchNotificationMasters();
        break;
      case "Notification Master Role Mapping":
        await this.props.addNotificationRoleMappings(
          newTblColValues.notificationMasterId,
          newTblColValues.notificationRole
        );
        await this.props.fetchNotificationMsaterRoleMappings();
        break;
      case "Notification Master Function Mapping":
        await this.props.addNotificationFunctionMappings(
          newTblColValues.notificationMasterId,
          newTblColValues.notificationFunction
        );
        await this.props.fetchNotificationMasters();
        break;
      case "Notification Master Event Mapping":
        await this.props.addNotificationAssociatedEventTypeMappings(
          newTblColValues.notificationMasterId,
          newTblColValues.notificationAssociatedEventType
        );
        await this.props.fetchNotificationMasters();
        break;
      case "Admin Change Item Type Event Mapping":
        await this.props.addChangeTypeEventTypeMapping(
          newTblColValues.changeTypeId,
          newTblColValues.changeAssociatedEventType
        );
        await this.props.fetchAllChangeTypeEventTypeMappings();
        break;
      case "Required Field Decision Point Type Mapping":
        await this.props.addRequiredFieldsDecisionPointTypeMapping(
          newTblColValues.managedFieldId,
          newTblColValues.decisionPointTypeMapping
        );
        await this.props.fetchAllRequiredFieldsDecisionPointTypeMappings();
        break;
      case "Managed Field Decision Point Type Mapping":
        await this.props.addManagedFieldsDecisionPointTypeMapping(
          newTblColValues.managedFieldId,
          newTblColValues.decisionPointTypeMapping
        );
        await this.props.fetchAllManagedFieldsDecisionPointTypeMappings();
        break;
      case "Required Field Checkpoint Type Mapping":
        await this.props.addRequiredFieldsCheckpointTypeMapping(
          newTblColValues.managedFieldId,
          newTblColValues.checkpointTypeMapping
        );
        await this.props.fetchAllRequiredFieldsCheckpointTypeMappings();
        break;
      case "Risk Rating Criteria Categories":
        await this.props.addPRRCriteriaCategoryMapping(
          newTblColValues.criteriaId,
          newTblColValues.riskCategoryType
        );
        await this.props.fetchAllPRRCriteriaCategories();
        break;
      case "Risk Rating Criteria Entity Mapping":
        await this.props.addPRRCriteriaEntityMapping(
          newTblColValues.criteriaId,
          newTblColValues.riskRatingCriteriaEntity
        );
        await this.props.fetchAllPRRCriteriaEntities();
        break;
      case "Prr Restriction Tag":
        await this.props.addPRRCriteriaTagMapping(
          newTblColValues.criteriaId,
          newTblColValues.riskRatingCriteriaTag
        );
        await this.props.fetchAllPRRCriteriaTags();
        break;
      case "Risk Rating Criteria":
        await this.props.addPRRCriteria(newTblColValues);
        await this.props.fetchAllPRRCriterias("displayOrder");
        newTblColValues.riskCategory = [];
        break;
      case "Risk Rating Criteria Option":
        await this.props.addCriteriaOption(
          newTblColValues.criteriaId,
          newTblColValues
        );
        await this.props.fetchAllPRRCriterias("title");
        break;
      default:
    }

    this.setState({
      selectedDropdownValues: this.resetSelectedDropDownValues(),
      newTblColValues: {},
      isSaved: true,
      isSelectAll: false,
      isAddNewValue: true,
      newValueControlsCount: 0,
    });

    this.cleanDropDown();
    this.props.processSelectedTable(selectedPip);
  };

  cleanDropDown = () => {
    this.setState({ randomIndex: Math.floor(Math.random() * 6) + 1 });
  }

  cleanUpValues = () => {
    const { selectedPip } = this.state;

    this.setState({
      selectedDropdownValues: this.resetSelectedDropDownValues(),
      disableAddValueButton: true,
      newValueControlsCount: 0,
      newTblColValues: {},
      isSelectAll: false,
      isAddNewValue: true,
    });

    this.cleanDropDown();

    this.props.processSelectedTable(selectedPip);
  };

  resetSelectedDropDownValues = () => {
    const newSelectedDropDownValues = this.state.selectedDropdownValues;
    newSelectedDropDownValues.forEach((item, index) => {
      newSelectedDropDownValues[index].value = "";
    });
    return newSelectedDropDownValues;
  };

  closeDialog = () => {
    this.setState({ isSaved: false });
  };

  render() {
    const {
      selectedPip,
      isSaved,
      newTblColValues,
      isAddNewValue,
      isUnique,
      isSelectAll,
      selectedDropdownValues
    } = this.state;

    const { isReadOnly } = this.props;

    const { tableMappings } = this.props;
    const { tblDropDownOptions } = this.props;
    const { isAddFreeText } = this.props;

    // eslint-disable-next-line global-require
    const OliLogo =
      this.props.result === "SUCCESS"
        ? // eslint-disable-next-line global-require
        require("../../../images/oli-success.svg")
        : // eslint-disable-next-line global-require
        require("../../../images/oli-fail.svg");

    return selectedPip ? (
      <>
        <div className={"addValuesSection"}>
          {!isAddFreeText && tableMappings.length > 0 ? (
            tableMappings[0].map((col, index) => (
              <div className={"addValueControlDiv"} key={`div-${index}`}>
                <Dropdown
                  id={`${index}`}
                  placeholder={`Select ${col.colName}`}
                  label={`Select ${col.colName}`}
                  options={tblDropDownOptions[index]}
                  required
                  selectedKey={this.getSelectedKey(col.colId)}
                  styles={dropdownStyles}
                  onRenderCaretDown={onRenderCaretDown}
                  onChange={(e, i) => this.changeTblOptionHandler(e, i, col, index)
                  }
                />
              </div>
            ))
          ) : tableMappings.length > 0 ? (
            <>
              {selectedPip.configValue === "Risk Rating Criteria Option" ? (
                <>
                  <div className={"addValueControlDiv"}>
                    <Dropdown
                      id={`criteria`}
                      placeholder={`Select criteria`}
                      label={`Select criteria`}
                      options={tblDropDownOptions[0]}
                      required
                      selectedKey={this.getSelectedKey(
                        tableMappings[0][0].colId
                      )}
                      styles={dropdownStyles}
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(e, i) => this.changeTblOptionHandler(e, i, tableMappings[0][0], 0)
                      }
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Option"
                      value={
                        Object.keys(newTblColValues).length > 0 && newTblColValues.criteriaOption
                          ? newTblColValues.criteriaOption
                          : ""
                      }
                      placeHolder={"enter Option"}
                      id={"txt-option"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      multiline={true}
                      style={{ width: "220px", height: "80px" }}
                      maxLength={500}
                      readOnly={isReadOnly}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <Dropdown
                      id={`Value`}
                      placeholder={`Select values`}
                      label={`Select values`}
                      // eslint-disable-next-line camelcase
                      options={OPTION_VALUES}
                      required
                      selectedKey={newTblColValues.value}
                      styles={dropdownStyleCategory}
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(e, i) => this.changeTblOptionHandler(e, i, null, 0)
                      }
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Sort Order"
                      value={
                        Object.keys(newTblColValues).length > 0 && newTblColValues.sortOrder
                          ? newTblColValues.sortOrder
                          : ""
                      }
                      placeHolder={"enter sort order"}
                      id={"txt-order"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      style={{ width: "80px" }}
                      type="number"
                      readOnly={isReadOnly}
                    />
                  </div>
                </>
              ) : selectedPip.configValue === "Risk Rating Criteria" ? (
                <>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Prr Ref"
                      value={
                        Object.keys(newTblColValues).length > 0
                          ? newTblColValues.prrRef
                          : ""
                      }
                      placeHolder={"enter prr ref"}
                      id={"txt-ref"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      maxLength={128}
                      readOnly={isReadOnly}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Criteria"
                      value={
                        Object.keys(newTblColValues).length > 0 && newTblColValues.title
                          ? newTblColValues.title
                          : ""
                      }
                      placeHolder={"enter criteria"}
                      id={"txt-criteria"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      readOnly={isReadOnly}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <Editor
                      apiKey={APIKeys.EditorKey}
                      value={Object.keys(newTblColValues).length > 0
                        ? newTblColValues.question : ""}
                      init={{
                        branding: false,
                        height: 250,
                        menubar: false,
                        plugins: [
                          'wordcount advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help'
                        ],
                        paste_preprocess(plugin, args) {
                          // replace copied text with empty string
                          if (editorCharLeft === 0) {
                            // eslint-disable-next-line no-param-reassign
                            args.content = '';
                          }
                        },
                        toolbar:
                          'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | wordcount | help',
                        init_instance_callback: (editor) => {
                          this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                        }
                      }}
                      onEditorChange={(content, editor) => {
                        newTblColValues.question = content;
                        const selectedControlIndex = selectedDropdownValues.findIndex(
                          (item) => item.key === "question"
                        );

                        if (selectedControlIndex >= 0) {
                          selectedDropdownValues[selectedControlIndex].value = content;
                        }
                        this.setState({ newTblColValues, selectedDropdownValues, editorCount: editor.plugins.wordcount.body.getCharacterCount() });

                      }}
                      onBlur={(event, editor) => {
                        this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
                      }}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Sort Order"
                      value={
                        Object.keys(newTblColValues).length > 0 && newTblColValues.sortOrder
                          ? newTblColValues.sortOrder
                          : ""
                      }
                      placeHolder={"enter sort order"}
                      id={"txt-order"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      style={{ width: "80px" }}
                      type="number"
                      readOnly={isReadOnly}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <Dropdown
                      id={`category`}
                      placeholder={`Select category`}
                      label={`Select category`}
                      // eslint-disable-next-line camelcase
                      options={PRR_Criteria_Category}
                      required
                      selectedKey={newTblColValues.riskCategory}
                      styles={dropdownStyleCategory}
                      multiSelect
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(e, i) => this.changeTblOptionHandler(e, i, null, 0)}
                      key={this.state.randomIndex}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Function code"
                      value={
                        Object.keys(newTblColValues).length > 0
                          ? newTblColValues.code
                          : ""
                      }
                      placeHolder={"enter code"}
                      id={"txt-code"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      maxLength={128}
                      readOnly={isReadOnly}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Function name"
                      value={
                        Object.keys(newTblColValues).length > 0
                          ? newTblColValues.name
                          : ""
                      }
                      placeHolder={"enter name"}
                      id={"txt-name"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      readOnly={isReadOnly}
                    />
                  </div>
                  <div className={"addValueControlDiv"}>
                    <TextField
                      label="Function description"
                      value={
                        Object.keys(newTblColValues).length > 0
                          ? newTblColValues.description
                          : ""
                      }
                      placeHolder={"enter description"}
                      id={"txt-description"}
                      onChange={(event) => {
                        this.handleTextChange(event);
                      }}
                      multiline={true}
                      style={{ width: "220px", height: "80px" }}
                      maxLength={500}
                      readOnly={isReadOnly}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          {isSelectAll ? (
            isUnique ? (
              <>
                <CheckCircleIcon className={"completeIcon"}></CheckCircleIcon>
                <span className={"uniqueSpan"}>
                  These are valid and unique Mapping Table Values.
                </span>
              </>
            ) : (
              <>
                <Cancel className={"inCompleteIcon"}></Cancel>
                <span className={"uniqueSpan"}>
                  These not yet a valid and unique Mapping Table Values. Update
                  to continue.
                </span>
              </>
            )
          ) : (
            ""
          )}
        </div>
        <hr className={"sepLine"} />
        {tableMappings.length > 0 ? (
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={stackTokens}
            className={"actionButtons"}
          >
            <StackItem align="start">
              <div>&nbsp;</div>
            </StackItem>
            <StackItem>&nbsp;</StackItem>
            <StackItem align="end">
              <DefaultButton
                text="Cancel"
                onClick={this.cleanUpValues}
                className={"cancelButton"}
              />
              <PrimaryButton
                text="Add Value"
                onClick={this.addNewTblValues}
                disabled={isAddNewValue}
                className={"addValueButton"}
              />
            </StackItem>
          </Stack>
        ) : (
          ""
        )}
        {isSaved ? (
          <SuccessfulMessage
            dialogState={false}
            errorTitle={this.props.result}
            errorContentArray={[this.props.message, "Click Okay to continue."]}
            hidden={!isSaved}
            closeDialog={this.closeDialog}
            cancelDialog={this.closeDialog}
            OliLogo={OliLogo}
          />
        ) : (
          ""
        )}
      </>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  message: state.ui.message,
  result: state.ui.result,
  eventTypes: state.mappingTables.eventTypes
    ? Object.values(state.mappingTables.eventTypes)
    : [],
  roleMappings: state.mappingTables.roleMappings
    ? Object.values(state.mappingTables.roleMappings)
    : [],
  functions: state.mappingTables.functions
    ? state.mappingTables.functions.masters
    : [],
  notificationMasters: state.notificationMastersData.notificationMasters
    ? state.notificationMastersData.notificationMasters.masters
    : [],
  changeEventTypes: state.mappingTables.changeEventTypes
    ? state.mappingTables.changeEventTypes.associations
    : [],
  requiredFieldsDPTypes: state.mappingTables.requiredFieldsDPTypes
    ? state.mappingTables.requiredFieldsDPTypes.associations
    : [],
  managedFields: state.mappingTables.managedFields
    ? Object.values(state.mappingTables.managedFields.summaries)
    : [],
  dpTypeManagedFields: state.mappingTables.dpTypeManagedFields
    ? state.mappingTables.dpTypeManagedFields.associations
    : [],
  cpTypeRequiredFields: state.mappingTables.cpTypeRequiredFields
    ? state.mappingTables.cpTypeRequiredFields.associations
    : [],
  criteriaCategories: state.mappingTables.criteriaCategories
    ? state.mappingTables.criteriaCategories.criteriaCategories
    : [],
  criterias: state.mappingTables.criterias
    ? state.mappingTables.criterias.criterias
    : [],
  criteriasEntities: state.mappingTables.criteriasEntities
    ? state.mappingTables.criteriasEntities.criteriaEntities
    : [],
  criteriasTags: state.mappingTables.criteriasTags
    ? state.mappingTables.criteriasTags.tags
    : [],
  tagValues: state.mappingTables.tagValues
    ? state.mappingTables.tagValues.summaries
    : [],
});

const connected = connect(mapStateToProps, {
  addNotificationFunctionMappings,
  addNotificationRoleMappings,
  addNotificationRecipientStrategyMappings,
  addNotificationFunction,
  addNotificationAssociatedEventTypeMappings,
  addChangeTypeEventTypeMapping,
  addRequiredFieldsDecisionPointTypeMapping,
  addManagedFieldsDecisionPointTypeMapping,
  addRequiredFieldsCheckpointTypeMapping,
  addPRRCriteriaCategoryMapping,
  addPRRCriteriaEntityMapping,
  addPRRCriteriaTagMapping,
  addPRRCriteria,
  addCriteriaOption,
  fetchNotificationMasters,
  fetchAllChangeTypeEventTypeMappings,
  fetchAllTableMappings,
  fetchAllFunctions,
  fetchNotificationMsaterRoleMappings,
  fetchAllEventTypes,
  fetchAllRequiredFieldsDecisionPointTypeMappings,
  fetchAllManagedFields,
  fetchAllManagedFieldsDecisionPointTypeMappings,
  fetchAllRequiredFieldsCheckpointTypeMappings,
  fetchAllPRRCriteriaCategories,
  fetchAllPRRCriterias,
  fetchAllPRRCriteriaEntities,
  fetchAllPRRCriteriaTags,
})(AuthProvider(AddTableMappings));

export default connected;
