import React from "react";
import { withStyles } from "@material-ui/styles";
import { Separator, Stack } from '@fluentui/react';
import StatusTag from "../StatusTag";

const displayLinkedLibrary = (classes, linkedPolicyLibraries) => {
  if (linkedPolicyLibraries && linkedPolicyLibraries.length > 0) {
    return (<>
      {linkedPolicyLibraries.map((library, index) => <span className={classes.linkedLibrary}>Policy: <span style={{ color: '#006ed2' }}>{library.policySchemeType}</span></span>)}
    </>);
  }
  return <></>;
};

const displayLinkedResourceType = (classes, linkedResourceTypes) => {
  if (linkedResourceTypes && linkedResourceTypes.length > 0) {
    return (<>
      {linkedResourceTypes.map((resource, index) => <span className={classes.linkedLibrary}>Role: <span style={{ color: '#006ed2' }}>{resource.resourceType}</span></span>)}
    </>);
  }
  return <></>;
};

const DescriptionRichContent = ({
 classes, descriptionTitle, descriptionText, status, linkedPolicyLibraries, linkedResourceTypes, summarySection
}) => (
  <Stack horizontal horizontalAlign="start">
    <Stack.Item className={classes.descriptionSection}>
      <div className={classes.status}>
        <StatusTag tagName={status} tagType={status !== "ACTIVE" ? "dark" : ""}/>
      </div>
      <div className={classes.descriptionTitle}>{descriptionTitle}</div>
      <div className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: descriptionText }} />
      <Separator style={{ fontWeight: '600' }}></Separator>
      <div className={classes.subtitle}>LINKED LIBRARIES</div>
      <div className={classes.linkedBox}>
        {displayLinkedLibrary(classes, linkedPolicyLibraries)}
      </div>
      <div className={classes.subtitle}>POLICY LINKING</div>
      <div className={classes.linkedBox}>
        {displayLinkedResourceType(classes, linkedResourceTypes)}
      </div>
    </Stack.Item>
    <Stack.Item className={classes.summarySection}>
      { summarySection }
    </Stack.Item>
  </Stack>
);

const styles = () => ({
  descriptionSection: {
    padding: "16px 16px",
    width: "65%",
    display: "block"
  },
  summarySection: {
    width: "35%"
  },
  descriptionTitle: {
    fontWeight: "600",
    color: "#323130",
  },
  descriptionText: {
    listStylePosition: "inside",
    paddingTop: "16px",
    display: "block",
  },
  status: {
    paddingBottom: "16px",
  },
  subtitle: {
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
  },
  linkedBox: {
    padding: '20px 0',
  },
  linkedLibrary: {
    border: 'solid 1px #979797',
    padding: '6px 13px 6px 16px',
    borderRadius: '23.5px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    marginRight: "16px",
  },
});

export default withStyles(styles)(DescriptionRichContent);
