import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { fetchGuidanceFragmentPolicyConfigSummaries } from '../../../state/actions/GuidanceFragmentActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import GuidanceFragmentDetail from './GuidanceFragmentDetail';
import '../../../components/style.css';
import history from '../../../history';

class GuidanceFragmentItemCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showGuidanceFragmentDetail: false,
      open: false,
      showTrigger: false,
      selectedGuidanceItemDetail: '',
      selectedId: '',
      selectedGuidanceTrigger: ''
    };
  }

  handleListItemClick (selectedGuidance) {
    this.setState({
      showGuidanceFragmentDetail: true,
      selectedGuidanceItemDetail: selectedGuidance
    });
  }

  handleTriggerItemClick (guidance) {
    this.setState({
      showTrigger: true,
      selectedGuidanceTrigger: guidance
    });
  }

  handleTriggerClose = (value) => {
    this.setState({ showTrigger: false });
  };

  handleDetailClose =() => {
    this.setState({ showGuidanceFragmentDetail: false });
    this.props.fetchGuidanceFragmentPolicyConfigSummaries();
  }

  handleListItemClickBuilder (selectedGuidanceFragmentId) {
    const GUIDANCE_FRAGMENT_BUILDER_URL = "/referenceDataConsole/guidanceBuilder/";
    this.setState({ selectedId: selectedGuidanceFragmentId });
    history.push(`${GUIDANCE_FRAGMENT_BUILDER_URL + selectedGuidanceFragmentId}`);
  }

  render () {
    const { isReadOnly } = this.props;
    return (
      <>
        <Box mt={1} container="true" justify-content="space-around"
          flexWrap="wrap" width={1} >
          <>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={this.props.classes.icon}></ExpandMoreIcon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={this.props.classes.accordionHeader}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm container className={this.props.classes.guidanceheader}>
                    <Grid item xs container direction="column" spacing={4}>
                      <Grid item xs={7} className={this.props.classes.accheader} >
                        <Typography gutterBottom variant="subtitle1" className={this.props.classes.subheader}>
                          {this.props.guidance.guidanceFragmentCode.toUpperCase()}
                        </Typography>
                        <button onClick={() => this.handleListItemClick(this.props.guidance)} className={this.props.classes.viewGuidanceLink }>
                          {this.props.guidance.guidanceFragmentName}
                        </button>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2} className={this.props.classes.guidanceDivider}>
                      <Typography variant="subtitle1" className={this.props.classes.subheader}>Guidance Availability</Typography>
                      <Typography variant="body2" gutterBottom>
                        {this.props.guidance.status === 'ACTIVE' ? <>
                        <CheckCircleIcon className={this.props.classes.viewGuidanceCompleteIcon}></CheckCircleIcon><Typography variant="subtitle1">Active</Typography></>
                        :                        <>
                        <Cancel className={this.props.classes.viewGuidanceInCompleteIcon}></Cancel><Typography variant="subtitle1">{this.props.guidance.status}</Typography></>
                        }
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={1}>
                      <EditIcon onClick={() => this.handleListItemClickBuilder(this.props.guidance.id)} className={this.props.classes.viewGuidanceLinkIcon} >
                      </EditIcon>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
              {this.props.guidance.policyConfigurations?.length > 0
                ? <Card variant='outlined' className={this.props.classes.cardBackground}>
                  <CardContent>
                    <Grid container spacing={4} >
                      <Grid item xs={12} sm container className={this.props.classes.guidanceheader}>
                        <Grid item xs={6} >
                          <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
                            Guidance Location
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
                            Entity
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
                            Region
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <hr className={this.props.classes.sepLine} />
                    {this.props.guidance.policyConfigurations.map((guidance, index) => (
                        <>
                          <Grid container spacing={4} >
                            <Grid item xs={12} sm container className={this.props.classes.guidanceheader}>
                              <Grid item xs={6}>
                                <Typography variant="body2" gutterBottom>
                                  {guidance.guidanceLocation}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="body2" gutterBottom>
                                  {guidance.entity}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="body2" gutterBottom>
                                  {guidance.region}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <hr className={this.props.classes.sepLine} />
                      </>
                    ))}
                  </CardContent>
                </Card>
                :                <div className={this.props.classes.norule}>
                  <br/>
                  <Typography variant="body2" gutterBottom>
                        This Guidance Fragment has not yet been configured. Please go to the Guidance Builder to start adding rules to this guidance
                  </Typography>
                  <hr className={this.props.classes.sepLine} />
                </div>
              }
              </AccordionDetails>
            </Accordion>
          </>
        </Box>

        {this.state.showGuidanceFragmentDetail
          ? <GuidanceFragmentDetail guidance={this.state.selectedGuidanceItemDetail} open={this.state.showGuidanceFragmentDetail}
           onClose={this.handleDetailClose}
           isReadOnly={isReadOnly}
           hasArchiveFunctionality={false}
            ></GuidanceFragmentDetail>
          : <></>
        }
        <hr className={this.props.classes.sepLine} />
      </>
    );
  }
}

const styles = (theme) => ({
  norule: {
    marginLeft: '20px'
  },
  cardBackground: {
    height: '100%',
    backgroundColor: 'transparent',
    width: '100%'
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  viewGuidanceLink: {
    border: '0',
    backgroundColor: 'transparent',
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    textAlign: 'left'
  },
  viewGuidanceLinkIcon: {
    border: '1',
    backgroundColor: null,
    color: 'darkgrey',
    fontSize: '14px !important',
    cursor: 'grab',
    float: 'right'
  },
  viewGuidanceCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  viewGuidanceInCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#555555',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  accordionHeader: {
    backgroundColor: '#f1f2f3 !important',
    flexDirection: 'row-reverse',
  },
  subheader: {
    fontWeight: 'bold',
  },
  guidanceheader: {
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#323130',
  },
  accheader: {
    paddingLeft: '1rem'
  },
  heading: {
    fontWeight: 'bold',
  },
  guidanceDivider: {
    marginLeft: '1.5rem'
  },
  icon: {
    fontSize: '20px !important',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  popupdialog: {
    position: 'absolute',
    right: 20,
    top: 50
  }
});

GuidanceFragmentItemCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  fetchGuidanceFragmentPolicyConfigSummaries,
})(AuthProvider(GuidanceFragmentItemCard));

export default withStyles(styles)(connected);
