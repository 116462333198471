import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import '../../components/style.css';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
 DefaultButton, PrimaryButton, initializeIcons, TextField
} from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { fetchTaskMasters, updateTaskMaster } from '../../state/actions/TaskMasterActions';
import AuthProvider from '../../components/auth/AuthProvider';
import SuccessfulMessage from '../../components/Dialog/SuccessfullMessage';
import renderHTMLContent from '../../util/ConvertUtil';

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

const searchBoxStyles = { root: { width: 400 } };
const stackTokens = { childrenGap: 40 };

class TaskMasterDetail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      show: this.props.open,
      currentDesc: '',
      currentName: '',
      hideDialog: true,
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the description Box</p>',
      open: false
    };
  }

  componentDidMount () {
    const { task } = this.props;
    this.setState({ show: this.props.open });
    this.setState({
      currentName: task.label,
      currentDesc: task.description,
      isBtnActivated: true
    });
    initializeIcons();
  }

  handleTaskNameChange = (event) => {
    this.setState({ currentName: event.target.value, isBtnActivated: false });
  }

  closeDialog = () => {
    this.handleTaskMasterEdit();
    this.setState({ hideDialog: true });
  }

  cancelDialog = () => {
    this.setState({ hideDialog: true });
  }

  handleShowTaskMasterEdit = () => {
    this.setState({ hideDialog: false });
  }

  handleCancelTaskMasterEdit = () => {
    const { task } = this.props;
    this.setState({
      currentName: task.label,
      currentDesc: task.description,
      isBtnActivated: true
    });
  }

  handleTaskMasterEdit = () => {
    const { task } = this.props;
    task.description = this.state.currentDesc;
    task.label = this.state.currentName;
    this.props.updateTaskMaster(task);
    this.setState({ isBtnActivated: true, hideDialog: false });
  }

  onDismissTaskDetailPanel = () => {
    this.props.dismissTaskDetailPanel();
}

onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  render () {
    const { classes, isReadOnly, task } = this.props;
    if (!this.props.taskMasterData) return <div></div>;

    const currentDesc = this.state.currentDesc ? this.state.currentDesc : task.description;
    const currentName = this.state.currentName ? this.state.currentName : task.label;
    const OliLogo = (this.props.result === 'SUCCESS') ? require('../../images/oli-success.svg') : require('../../images/oli-fail.svg');  
    let currentThreshold = '';

    if (task?.thresholds?.highThreshold === 1) { currentThreshold = "HIGH"; }
    if (task?.thresholds?.mediumThreshold === 1) { currentThreshold = "MEDIUM"; }
    if (task?.thresholds?.overdueThreshold === 1) { currentThreshold = "OVERDUE"; }
    return (
     <Panel
      isOpen={this.props.open}
      onDismiss={this.props.onClose}
      type={PanelType.custom}
      customWidth={'550px'}
      closeButtonAriaLabel="Close"
      headerText= "Task Master Summary"
      >
        <Separator></Separator>
        <Stack className={classes.taskDetail}>
          <span className={classes.fieldName}>TASK MASTER SEARCH</span>
        </Stack>
        <Stack horizontal >
          <SearchBox placeholder="Search keyword..." styles={searchBoxStyles}/>
          <PrimaryButton >Search</PrimaryButton>
        </Stack>
        <Separator></Separator>
        <Stack className={classes.taskDetail}>
          <span className={classes.fieldName}>Task Code</span>
          <div>{task.code}</div>
        </Stack>
        <Stack horizontal tokens={stackTokens} className={classes.actions}>
          <DefaultButton text="Cancel"  className={classes.inputbox} onClick={this.props.onClose} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
          <PrimaryButton text="Save"  className={classes.inputbox} disabled={isReadOnly} onClick={() => this.handleShowTaskMasterEdit()}/>
        </Stack>
        <Stack className={classes.taskDetail}>
          <TextField label="Task Name" value={currentName} onChange={(event) => this.handleTaskNameChange(event)} readOnly={isReadOnly} />
        </Stack>
        <Stack className={classes.taskDetail}>
          <span className={classes.fieldName}>Task Master Status</span>
          { this.props.taskProperties.isActive
            ? <span><CheckCircleIcon className={this.props.classes.viewTaskCompleteIcon}></CheckCircleIcon>Active</span>
            :            <span><Cancel className={this.props.classes.viewTaskInCompleteIcon}></Cancel>Inactive</span>
          }
        </Stack>
        <Stack className={classes.taskDetail}>
          <span className={classes.fieldName}>Task Master Description</span>
          {!isReadOnly
          ? <Editor
            apiKey={APIKeys.EditorKey}
            value={currentDesc}
            init={{
              branding: false,
              height: 300,
              browser_spellcheck: true,
              content_style: 'body { color:#605e5c; }',
              selector: 'textarea',
              allow_unsafe_link_target: true,
              menubar: 'insert',
              element_format: 'xhtml',
              plugins: [
                'wordcount advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help'
              ],
              paste_preprocess (plugin, args) {
              },
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
              init_instance_callback: (editor) => {
                this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
              }
            }}
            onEditorChange={(content, editor) => {
              if ((content !== task.description) && ReactHtmlParser(content) !== ReactHtmlParser(task.description)) {
                this.setState({
                  isBtnActivated: false,
                  currentDesc: content,
                  editorCount: editor.plugins.wordcount.body.getCharacterCount()
                });
              }
            }}
            onBlur={(event, editor) => {
              this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
            }}
          /> : renderHTMLContent(currentDesc)}
        </Stack>
        <Stack className={classes.taskDetail}>
          <TextField label="Task Priority" value={task.priority} readOnly={isReadOnly} />
        </Stack>

        <Stack className={classes.taskDetail}>
          <TextField label="Requirement" value={task.requirement} readOnly={isReadOnly} />
        </Stack>

        <Stack className={classes.taskDetail}>
          <TextField label="Threshold" value={currentThreshold} readOnly={isReadOnly} />
        </Stack>

        <Separator></Separator>
        <Stack className={classes.taskDetail}>
          <span className={classes.fieldName}>Task Labels</span>
        </Stack>
        <Separator></Separator>
        <Stack className={classes.taskDetail}>
          <span className={classes.fieldName}>RELATED POLICIES</span>
        </Stack>
        {!this.state.hideDialog
            ? <SuccessfulMessage dialogState={false} errorTitle={this.props.result}
              errorContentArray={[this.props.message, 'Click Okay to continue.']}
              hidden={this.state.hideDialog}
              closeDialog={this.closeDialog}
              cancelDialog={this.cancelDialog}
              OliLogo={OliLogo}
            />
            : ''
        }
      </Panel>
    );
  }
}

const styles = (theme) => ({
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  closeButton: {
    backgroundColor: '#fff',
    border: '0',
    color: '#605e5c',
    fontSize: '18px',
    padding: '0 16px',
    marginRight: '16px',
    cursor: 'pointer',
  },
  actions: {
    padding: '20px 0 20px 0'
  },
  taskDetail: {
    padding: '10px 0 10px 0',
  },
  inputbox: {
    border: '1px solid'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  fieldRow: {
    padding: '8px 0',
  },
  labeltext: {
    display: 'inline'
  },
  viewTaskCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  viewTaskInCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#555555',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  fieldName: {
    fontWeight: '600',
    color: '#323130',
    marginBottom: '5px'
  },
  popUpTitle: {
    color: '#323130',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },
  buttonStyle: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#fff',
    color: '#323130',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    cursor: 'pointer'
  },
  headerSection: {
    paddingLeft: '0px'
  },
  buttonSubmit: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#006ed2',
    color: 'white',
    fontWeight: '500',
    orderRadius: '2px',
    border: 'none',
    cursor: 'pointer'
  },
});

TaskMasterDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  taskMasterData: state.taskMastersData.taskMasters.masters,
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  fetchTaskMasters,
  updateTaskMaster
})(AuthProvider(TaskMasterDetail));

export default withStyles(styles)(connected);
