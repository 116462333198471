import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Box, Typography } from "@material-ui/core";
import "../reference.css";
import TableMappingCard from './TableMappingCard';
import FilterAndSearchPageHeader from "../../adminChangeConsole/shared/FilterAndSearchPageHeader";
import { fetchAllTableMappings } from '../../../state/actions/MappingTablesActions';
import { fetchReferenceData } from '../../../state/actions/ReferenceDataActions';

const TableMappingSummaryList = (props) => {
  const tables = useSelector(
    (state) => state.mappingTables.tables
  );

  const refData = useSelector(
      (state) => state.refData.referenceData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllTableMappings());
    dispatch(fetchReferenceData());
  }, [dispatch]);


 const listTables = () => {
    if (tables.length < 1) return;

    const newTables = Object.values(tables).reduce((table, x) => {
      // eslint-disable-next-line no-param-reassign
      (table[x.categoryId] = table[x.categoryId] || []).push(x);
      return table;
    }, {});

    // eslint-disable-next-line consistent-return
    return Object.values(newTables).map((ia, index) => <TableMappingCard key={`tableList-${index}`} subjectMatter={ia[0].categoryId} pips={ia} type="tables" refData={refData} />);
  };

  return (
    <Box className={"boxOutline"}>
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography className={"cardHeader"}>
              Mapping Tables Management
            </Typography>
          }
        />
        <CardContent>

          <FilterAndSearchPageHeader filterOptions={[]} selectedFilters={[]}
            filterOnclickHandler={() => { }}
            clearAllFilterHandler={() => { }}
            removeSingleFilterHandler={() => { }} />
          <div>
            {listTables()}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TableMappingSummaryList;
