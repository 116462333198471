import {
  DELETE_ITEM,
  SET_ITEM,
  GET_ALL_ITEM
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const deleteItem = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ITEM, payload });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const setItem = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_ITEM, payload });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const getAllSelectedItems = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ITEM });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};
