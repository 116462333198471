/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  initializeIcons, Checkbox, Stack
} from '@fluentui/react';

import {
  Box, Button, ButtonGroup, Collapse, List, ListItem, ListItemText
} from '@material-ui/core';

import {
  ExpandLess, ExpandMore
} from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';

import { withStyles } from '@material-ui/styles';

import AuthProvider from '../auth/AuthProvider';

import './filter.css';
import '../style.css';
import { fetchAdminChangeMastersSelectedFilters , fetchAdminChangeMastersWithFilters } from '../../state/actions/AdminChangeMasterActions';

class Filterv2 extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      filterType: this.props.filterType,
      filterOptions: this.props.filterOptions,
      expandPanel: [],
      selectedFilter: [],
    };
    initializeIcons();
  }

  componentDidMount () {
    const expPanel = [];
    const selectedFilter = [];
    const { initialSelections } = this.props;
    this.props.filterOptions.contentFilters.forEach((element, idx) => {
      expPanel.push(idx === 0 ? true : false);
    });
    this.props.filterOptions.contentFilters.forEach((element, index) => {
      const filterType = initialSelections && initialSelections.length > 0 ? initialSelections[index].filterType : element.filterType;
      const items = initialSelections && initialSelections.length > 0 ? [...initialSelections[index].items] : [];
      selectedFilter.push({ filterType, items });
    });

    this.setState((prevState) => ({
      ...prevState,
      expandPanel: [...expPanel],
      selectedFilter: [...selectedFilter]
    }));
  }

  /* Functions used to build section of page */

  buildFilterPanel () {
    const { classes, filterOptions } = this.props;
    const { expandPanel, selectedFilter } = this.state;
    return (
      <React.Fragment>
        {filterOptions.contentFilters.map((option, idx1) => {
          const expand = expandPanel[idx1];
          return (<div key={`filter-option-${idx1}`}>
            <ListItem button key={`ListItem-${idx1}`}  onClick={() => this.expandPanelHandler(idx1)}>
              <ListItemText
                primary={option.filterType}
                className={classes.filterTypeLabel}
              />
              {expand ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse key={`Collapse-${idx1}`} in={expand} timeout="auto" unmountOnExit>
              <List key={`child-${idx1}`}>
                {this.buildListPanelEntry(option, idx1)}
              </List>
            </Collapse>
          </div>);
        })}
        <Box className={classes.selectedFilterGroup}>
          {this.displaySelection() ? (
            <ButtonGroup
              fullWidth
              className={classes.root}
            >
              {selectedFilter.map((flt, idx1) => this.buildSelectedFilter(flt, idx1))}
            </ButtonGroup>
          ) : (
            <div></div>
          )}
        </Box>
        <Box>
          <button className={classes.clearFilter} onClick={this.clearAllFilters}>
            Clear All Filters
          </button>
        </Box>
      </React.Fragment>
    );
  }

  buildListPanelEntry (option, idx1) {
    const { selectedFilter } = this.state;
    const listPanelEntry = option.entries?.map((entry, idx2) => {
      const { key, value } = entry;
      const checked = (selectedFilter.length > 0) ? selectedFilter[idx1].items.some((ch) => ch.key === key) : false;
      return (
        <Stack value={key} style={{ padding: '0 0 5px 15px' }} >
          <Checkbox
            label={value}
            onChange={() => this.applyFilterHandler(idx1, idx2, option)}
            checked={checked}
          />
        </Stack>
      );
    });
    return <div>{listPanelEntry}</div>;
  }

  buildSelectedFilter (flt, idx) {
    const { classes } = this.props;
    const buttonGrp = flt.items.map((item) => (
      <Button
        className={classes.selectedFilterLabels}
        name="selectedTypeValuesBtn"
      >
        {item.value}
        <ClearIcon
          className={classes.buttonClearIcon}
          onClick={() => this.deleteFilterHandler(item, idx)}
        ></ClearIcon>
      </Button>
    ));
    return <div>{buttonGrp}</div>;
  }

  /* Event and Action Handlers */

  applyFilterHandler = (idx1, idx2, option) => {
    const entry = option.entries[idx2];
    const { selectedFilter } = this.state;
    const newselectedFilter = selectedFilter;
    const found = selectedFilter[idx1].items.some((ch) => ch.key === entry.key);
    let newselValues = [];
    if (found) {
      // remove from selectedFilter
      newselValues = selectedFilter[idx1].items.filter((ch) => ch.key !== entry.key);
      newselectedFilter[idx1].items = newselValues;
    } else {
      // add to selectedFilter
      newselValues = [...selectedFilter[idx1].items, { key: entry.key, value: entry.value }];
      newselectedFilter[idx1].items = newselValues;
    }
    this.setState((prevState) => ({ ...prevState, selectedFilter: [...newselectedFilter] }));
    this.props.fetchAdminChangeMastersSelectedFilters([...newselectedFilter]);
    this.props.applyFilterSelectionsHandler([...newselectedFilter]); 
    setTimeout(() => {
      this.props.getMastersWithFilters();
    }, 1000);
    
  }

  expandPanelHandler = (idx) => {
    const val = [...this.state.expandPanel];
    val[idx] = !this.state.expandPanel[idx];
    this.setState((prevState) => ({
      ...prevState,
      expandPanel: val,
    }));
  }

  deleteFilterHandler = (sel, idx) => {
    const { selectedFilter } = this.state;
    const newselValues = selectedFilter[idx].items.filter((ch) => ch.key !== sel.key);
    const newselectedFilter = selectedFilter;
    newselectedFilter[idx].items = newselValues;
    this.setState((prevState) => ({ ...prevState, selectedFilter: [...newselectedFilter] }));
    this.props.applyFilterSelectionsHandler([...newselectedFilter]);
  }

  clearAllFilters = () => {
    const newselectedFilter = [];
    this.props.filterOptions.contentFilters.forEach((element) => {
      newselectedFilter.push({
        filterType: element.filterType,
        items: []
      });
    });

    this.setState((prevState) => ({
      ...prevState,
      selectedFilter: [...newselectedFilter]
    }));
    this.props.applyFilterSelectionsHandler([]);
    setTimeout(() => {
      this.props.getMastersWithFilters();
    }, 1000);
  }

  /* Validation handler */

  /* Processor (Edit/Save/Delete) */

  /* Helper Functions */

  displaySelection () {
    const { selectedFilter } = this.state;
    const { showSelectedItems } = this.props;
    return (Array.isArray(selectedFilter) && selectedFilter.length > 0) && showSelectedItems;
  }

  /* Render */

  render () {
    return (
      <React.Fragment>
        {this.buildFilterPanel()}
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 250,
    margin: '0 auto'
  },
  filterTypeOption: {
    fontSize: '0.75rem'
  },
  selectedFilterGroup: {
    marginLeft: '10px'
  },
  filterTypeLabel: {
    fontweight: 'bold !important'
  },
  clearFilter: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#c8c8c8',
    color: 'black',
    fontWeight: '500',
    orderRadius: '2px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '10px',
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '1rem'
  },
  selectedFilterLabels: {
    textTransform: 'capitalize',
    backgroundColor: '#EAEAEA',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    width: 'auto'
  },
});

Filterv2.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

const connected = connect(mapStateToProps, {
  fetchAdminChangeMastersSelectedFilters,
  fetchAdminChangeMastersWithFilters
})(AuthProvider(Filterv2));

export default withStyles(styles)(connected);
