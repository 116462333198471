import {
  PROJECT_SUMMARIES,
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  projectSummaries: [],
  error: null
};

export default (state = INITIAL_STATE, action) => {
 switch (action.type) {
   case PROJECT_SUMMARIES:
        return {...state, projectSummaries: action.payload };
   default:
      return state;
  }
};
