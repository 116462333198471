import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

const StatusTag = (props) => (
  <span
    className={
      props.tagType === "dark"
        ? props.classes.tagContainerDarkBorder
        : props.classes.tagDefaultContainer
    }
  >
    {props.tagName}
  </span>
);

const styles = (theme) => ({
  tagContainerDarkBorder: {
    height: "20px",
    padding: "2px 6px",
    borderRadius: "2px",
    border: "solid 1px #a6a6a6",
    backgroundColor: "#fff",
    fontSize: "10px",
    fontWeight: "600",
    fontFamily: "SegoeUI",
  },
  tagDefaultContainer: {
    height: "20px",
    padding: "2px 6px",
    borderRadius: "2px",
    border: "solid 1px #006ed2",
    backgroundColor: "#fff",
    fontSize: "10px",
    fontWeight: "600",
    fontFamily: "SegoeUI",
  },
});

StatusTag.defaultProps = {
  tagName: "Tag",
};

StatusTag.propTypes = {
  tagName: PropTypes.string.isRequired,
};

export default withStyles(styles)(StatusTag);
