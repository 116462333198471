import React from 'react';
import { msalApp, GRAPH_REQUESTS, acquireToken } from './AuthUtils';
import authConfig from './AuthConfiguration';

export default (AuthComp) => class AuthProvider extends React.Component {
    constructor (props) {
      super(props);
      this.state = {
        account: null,
        error: null
      };
    }

    // eslint-disable-next-line class-methods-use-this
    async onSignIn () {
      const login = msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
      console.log(`Login returned: ${JSON.stringify(login)}`);
      return login;
    }

    onSignOut = () => {
      msalApp.logout();
    };

    componentDidMount () {
      msalApp.handleRedirectCallback((error) => {
        if (error) {
          const erorMessage = error.errorMessage ? error.errorCode : 'Unable to acquire access token';
          this.setState({ error: erorMessage });
        }
      });
      const account = msalApp.getAccount();
      if (account) {
        this.setState({ account });
        acquireToken({ ...authConfig.requestObject, loginHint: account.userName });
      } else {
        this.onSignIn();
      }
    }

    render () {
      const { account, error } = this.state;
      return (
        <AuthComp
          {...this.props}
          accountInfo={account}
          error={error}
          onSignIn={() => this.onSignIn()}
          onSignOut={() => this.onSignOut()}
        />
      );
    }
  };
