import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { initializeIcons, Stack, IconButton } from '@fluentui/react';
import { Separator } from '@fluentui/react/lib/Separator';
import { connect } from 'react-redux';
import AdminChangeItemDetail from './AdminChangeItemDetail';
import { fetchAdminChangeMasters } from '../../../state/actions/AdminChangeMasterActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import AdminChangeSet from './ChangeSet/AdminChangeSet';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';

const stackStyles = {
  root: {
   width: '100%'
  },
};

const GetPencilIconButton = (handleClick) => (
  <IconButton
    iconProps={{ iconName: "Edit" }}
    onClick={() => handleClick()}
    styles={{
      root: { height: "auto" },
      icon: { fontSize: "16px", margin: "0 0 0 0" },
      rootHovered: { backgroundColor: "#fff" },
    }}
  ></IconButton>
);

const isReadOnly = getReadOnlyFlagPerEnv();
class AdminChangeMasterItem extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      changeItems: this.props.changeItems,
      showChangeItemDetail: false,
      showAddChangeSetPopup: false,
      changeItem: '',
      changeDescription: '',
      selectedChangeItem: ''
    };
    initializeIcons();
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.changeItems !== nextProps.changeItems) {
      return { changeItems: nextProps.changeItems };
    }
    return null;
  }

  onHandleViewItemClick = () => {
    this.setState((state) => ({ showChangeItemDetail: true }));
  };

  onHandleDetailClose=() => {
    this.setState((state) => ({ showChangeItemDetail: false }));
  }

  onHandleAddChangeSetPopup =(changeItem) => {
    this.setState({ showAddChangeSetPopup: true, selectedChangeItem: changeItem });
  }

  onHandleAddChangeSetPopupClose =() => {
    this.setState({ showAddChangeSetPopup: false });
  }

  onHandleUpdateChangeSetPopup =(changeItem) => {
    this.setState((state) => ({ showChangeItemDetail: true, selectedChangeItem: changeItem  }));
  }

  onHandleUpdateChangeSetPopupClose =() => {
    this.setState({ showChangeItemDetail: false });
  }

  formatDate=(submittedDate) => {
    if (submittedDate !== undefined && submittedDate !== '') {
      const newDate = new Date(submittedDate);
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][newDate.getMonth()];
      return `${newDate.getDate()}-${month}-${newDate.getFullYear()}`;
    }
      return '';
  }

  hideAdminChangeSetPopupHandler = () => {
    this.setState((prevState) => ({ ...prevState, showAddChangeSetPopup: false }));
  }

  displayItem=(changeItem) => (
    <>
    <Stack horizontal horizontalAlign="start"  >
      <Stack.Item className={this.props.classes.details}>
        <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
            <span className={this.props.classes.operationheading}>{changeItem.changeType.value}</span>
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {changeItem.changeCategory.value}
        </Typography>
        {changeItem.changeDescription
        ? <Typography gutterBottom variant="subtitle1">
          {changeItem.changeDescription}
        </Typography>
        : ' '}
      </Stack.Item>
      <Stack.Item className={this.props.classes.statusvalue}>
        <span className={this.props.classes.operationheading}>{changeItem.status}</span>
      </Stack.Item>
      <Stack.Item className={this.props.classes.testvalue}>
        {changeItem.testStatus === 'Passed'
          ? <Stack.Item horizontal><CheckCircleIcon className={this.props.classes.verificationCompleteIcon}></CheckCircleIcon><p variant="subtitle1">Passed</p></Stack.Item>
          : <>
              { changeItem.testStatus === 'NOT_APPLICABLE'
                ? <p variant="subtitle1">N/A</p>
              : <Stack.Item horizontal><Cancel className={this.props.classes.verificationInCompleteIcon}></Cancel><p variant="subtitle1">Revising</p></Stack.Item>
              }
            </>
        }
      </Stack.Item>
      <Stack.Item className={this.props.classes.verifiedvalue} >
        { changeItem.verified
              ? <Stack horizontal><CheckCircleIcon className={this.props.classes.verificationCompleteIcon}/>
                  <Typography variant="subtitle1">Verified for publication</Typography></Stack>
              :             <Stack horizontal>
                <Cancel className={this.props.classes.verificationInCompleteIcon}/>
                <Typography variant="subtitle1">To be Verified</Typography></Stack>
        }
      </Stack.Item>
      <Stack.Item className={this.props.classes.changebyvalue}>
        <p variant="subtitle1" > {changeItem?.submittedBy?.displayName}</p>
      </Stack.Item>

      <Stack.Item className={this.props.classes.changedatevalue}>
        <Typography variant="subtitle1" > {this.formatDate(changeItem?.submittedDate)}</Typography>
      </Stack.Item>
      <Stack.Item  className={this.props.classes.changesetvalue} >
        <Stack horizontal >
          {!changeItem.changeSet
            ? <span >
              Add to Set
            </span>
            : <span className={this.props.classes.changesetdata}>
              {changeItem.changeSet.name}
            </span>
            }
            { changeItem.status !== "PUBLISHED"
            ?  GetPencilIconButton(() => this.onHandleAddChangeSetPopup(changeItem))
            : ''
            }
        </Stack>
      </Stack.Item>
      <Stack.Item className={this.props.classes.changedetailsvalue}>
        <button onClick={() => this.onHandleUpdateChangeSetPopup(changeItem)} className={this.props.classes.viewTaskLink }>
          View
        </button>
      </Stack.Item>
    </Stack>
     </>
  )

  findAllByKey=(obj, keyToFind) => Object.entries(obj)
  // eslint-disable-next-line no-nested-ternary
  .reduce((acc, [key, value]) => ((key === keyToFind)
    ? acc.concat(value)
    : (typeof value === 'object')
    ? acc.concat(this.findAllByKey(value, keyToFind))
    : acc),
   [])

  render () {
    let statusList = [];
    let verifyList = [];

    if (this.props.filterSelections && this.props.filterSelections[0]?.items.length > 0) {
      statusList = this.findAllByKey([].concat(...this.props.filterSelections[0].items), 'key');
    }
    if (this.props.filterSelections && this.props.filterSelections[1]?.items.length > 0) {
      verifyList = this.findAllByKey([].concat(...this.props.filterSelections[1].items), 'key');
    }

    return (
      <>
        <Stack horizontal horizontalAlign="start" styles={stackStyles} >
          <Stack.Item className={this.props.classes.details}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>
              Change Details
            </Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.status}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}> Publication Status</Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.test}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>Test Results</Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.verified}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>Verification Status</Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.changeby}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>Changed by</Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.changedate}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>Change Date</Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.changeset}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>Change Set</Typography>
          </Stack.Item>
          <Stack.Item className={this.props.classes.changedetails}>
            <Typography variant="subtitle1" className={this.props.classes.subheader}>Change Details</Typography>
          </Stack.Item>
        </Stack>
        <br/>
        {this.state.changeItems?.map((changeItem) => (
          <>
          { statusList.length > 0 || verifyList.length > 0
           ? <>
            { statusList.includes(changeItem.status) || verifyList.includes(changeItem.verified.toString().toUpperCase())
            ? <>
             {this.displayItem(changeItem)}
             <Separator></Separator>
            </>
            : '' }
           </>
           :            <>{this.displayItem(changeItem)}
            <Separator></Separator>
            </>
           }
          </>
        ))}
        {this.state.showAddChangeSetPopup ? <AdminChangeSet changeItem={this.state.selectedChangeItem} isReadOnly={isReadOnly} hideAdminChangeSetPopupHandler={this.onHandleAddChangeSetPopupClose}
        /> : null}
        {this.state.showChangeItemDetail ? <>
            <AdminChangeItemDetail changeItem={this.state.selectedChangeItem} open={this.state.showChangeItemDetail} onClose={this.onHandleDetailClose} keyResourceTitle={this.props.keyResourceTitle} changeCategoryName={this.props.changeCategoryName}></AdminChangeItemDetail>
          </>
          : <></>
        }
      </>
    );
  }
}

const styles = (theme) => ({
  details: {
   width: '35%',
   fontWeight: 'bold',
  },
  status: {
    width: '10%',
    fontWeight: 'bold',
    paddingRight: '1%',
  },
  test: {
    width: '10%',
    fontWeight: 'bold',
    paddingRight: '1%',
  },
  verified: {
    width: '15%',
    fontWeight: 'bold',
    paddingRight: '1.2%',
  },
  changeby: {
    width: '12%',
    fontWeight: 'bold',
    paddingRight: '1%',
  },
  changedate: {
    width: '12%',
    fontWeight: 'bold',
    paddingRight: '0.6%',
  },
  changeset: {
    width: '10%',
    fontWeight: 'bold',
    paddingRight: '2%',
  },
  changedetails: {
    width: '5%',
    fontWeight: 'bold',
  },
  editIcon: {
    paddingLeft: '1%'
  },
  detailsvalue: {
    width: '35%',
  },
  statusvalue: {
     width: '10%',
  },
  testvalue: {
    width: '10%',
  },
  verifiedvalue: {
    width: '15%',
  },
  changebyvalue: {
    width: '12%',
    paddingLeft: '1%',
  },
  changedatevalue: {
    width: '12%',
    paddingLeft: '1%',
  },
  changesetvalue: {
    width: '12%',
    height: 'auto',
  },
  changesetdata: {
    lineBreak: 'anywhere'
  },
  changedetailsvalue: {
    width: '5%',
  },
  operationheading: {
    border: 'solid 1px #0b8a00',
    fontSize: '12px',
      padding: '3px 8px 3px 8px',
  },
  viewTaskLink: {
    border: '0',
    backgroundColor: 'transparent',
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    textAlign: 'left'
  },
  heading: {
    paddingRight: '10px'
  },
  verificationCompleteIcon: {
    border: '0',
    backgroundColor: 'null',
    color: '#00aa23',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left',
    paddingRight: '3px'
  },
  verificationInCompleteIcon: {
    border: "0",
    backgroundColor: "null",
    color: "black",
    fontSize: "14px",
    cursor: "grab",
    float: "left",
    marginRight: "6px",
  },
  subheader: {
    fontWeight: 'bold',
  },
});

AdminChangeMasterItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  changeMasterData: state.changeMastersData?.changeMasters,
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  fetchAdminChangeMasters
})(AuthProvider(AdminChangeMasterItem));

export default withStyles(styles)(connected);
