/* eslint-disable global-require */
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import '../../components/style.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Grid from '@material-ui/core/Grid';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { Stack } from '@fluentui/react/lib/Stack';

import {
   initializeIcons, IconButton, Checkbox
} from '@fluentui/react';

import Filter from '../../components/shared/filter';
import { fetchReferenceData } from '../../state/actions/ReferenceDataActions';
import { fetchTaskMasters, updateTaskMaster, fetchTaskBuilderMasters, postUserActionToTaskMasterResourceStatus } from '../../state/actions/TaskMasterActions';
import { removeRuleFromCompositeRule } from '../../state/actions/PolicyRuleActions';
import AuthProvider from '../../components/auth/AuthProvider';
import { ActionLiteralType } from '../../constants/ActionTypeObj';
import RulePicker from '../../components/rules/rulePicker/RulePicker';
import RuleConfigValue from '../../components/rules/ruleConfigValue/RuleConfigValue';
import envReadOnlyFlagContext from '../../context/adminContext';
import TaskMasterEditDetail from './TaskMasterEditDetail';
import ActionConfirmationDialog from '../../components/Dialog/ActionConfirmationDialog';
import SuccessfullMessage from '../../components/Dialog/SuccessfullMessage';

class TaskMasterBuilder extends React.Component {
  static contextType = envReadOnlyFlagContext;

  constructor (props) {
    super(props);
    this.state = {
      selectedIndex: this.props.match.params.id,
      currentDesc: '',
      currentName: '',
      taskMasterId: this.props.match.params.id,
      hideDialog: true,
      notificationType: '',

      // used by rule picker
      isRulePickerOpen: false,
      selCompositePolicy: null,
      selExistingRuleIds: null,
      actionForRulePicker: null,
      selRuleForEdit: null,

      selRuleForDeletion: null,
      activeState: false,
      isMasterDetailsPanelOpen: false,
      code: '',

      isArchive: false,
      isArchiveDisable: false,
      isArchiveTaskDialogOpen: false,
      isActionResultDialogOpen: false
    };
    initializeIcons();
  }

  componentDidMount () {
    this.props.fetchTaskMasters();
    document.title = 'Task Masters Library';
    if (this.props.taskMasterFilteredData.totalInPage > 0) {
      const task = this.props.taskMasterFilteredData.masters.filter((item) => item.id === this.props.match.params.id)[0];
      const { tasks } = this.props;

      this.setState({
        selectedIndex: 0,
        code: task.code,
        currentName: task.label,
        currentDesc: task.description,
        taskMasterId: this.props.match.params.id,
        activeState: true,
        isArchive: task.resourceState === "ARCHIVED",
        isArchiveDisable: tasks?.length
      });
      this.props.fetchTaskBuilderMasters(this.props.match.params.id);
    }

    this.props.fetchReferenceData();
  }

  handleListItemClickLP (event, index, rdt) {
    const { code, label, description, resourceState } = this.props.taskMasterFilteredData.masters[index];
    const newTaskMasterSel = this.props.taskMasterFilteredData.masters[index].id;
    this.setState({
      selectedIndex: index,
      code,
      currentName: label,
      currentDesc: description,
      taskMasterId: newTaskMasterSel,
      isArchive: resourceState === "ARCHIVED"
    });

    this.props.fetchTaskBuilderMasters(newTaskMasterSel);
  }

  listTaskMastersLP = () => {
    const taskMastersList = this.props.taskMasterFilteredData.masters ? this.props.taskMasterFilteredData.masters : [];
    return taskMastersList.map((tM, index) => {
      const isSelected = (this.state.taskMasterId === tM.id);
      return (
        <ListItem
          key={tM.code}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(event, index, tM)}
          className={this.props.classes.tmItem}>
          <ListItemText
            primary={<Typography type="body2" className={this.props.classes.tmItemText}>{tM.label}</Typography>}
            disableTypography
          />
        </ListItem>);
    });
  }

  /* Rule Picker */
  toggleRulePickerPanelForAdd = (selCompositePolicy) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: true, selCompositePolicy, selExistingRuleIds, actionForRulePicker: 'add'
    }));
  }

  toggleRulePickerPanelForEdit = (selCompositePolicy, selRuleForEdit) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);

    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: true, selCompositePolicy, selExistingRuleIds, selRuleForEdit, actionForRulePicker: 'edit', selRuleForDeletion: selRuleForEdit
    }));
  }

  /* Rule Picker */
  toggleRulePickerPanelForAdd = (selCompositePolicy) => {
    // get existing IDs for this entitlement
    const selExistingRuleIds = selCompositePolicy.policyRules.map((rule, _index) => rule.rule.id);
    this.setState((prevState) => ({
      ...prevState, isRulePickerOpen: true, selCompositePolicy, selExistingRuleIds, actionForRulePicker: 'add'
    }));
  }

  dismissRulePickerPanelHandler = () => {
    this.refreshSelCompositeRulesHandler();
    this.setState((prevState) => ({ ...prevState, isRulePickerOpen: false }));
  }

  openDeleteModal = (selCompositePolicy, rRule) => {
    this.setState((prevState) => ({ ...prevState, selCompositePolicy, selRuleForDeletion: rRule }));
  }

  closeDeleteModal = () => {
    this.setState((prevState) => ({ ...prevState, selCompositePolicy: null, selRuleForDeletion: null }));
  }

  handleDeleteRuleFromComposite = async () => {
    // build rule object
    const rule = this.adoptSelectionToRule();
    await this.props.removeRuleFromCompositeRule(this.state.selCompositePolicy.id, rule);
    this.refreshSelCompositeRulesHandler();
    this.setState((prevState) => ({
 ...prevState,  isRulePickerOpen: false, selCompositePolicy: null, selRuleForDeletion: null
}));
  }

  handleArchiveChange = () => {
    this.setState((prevState) => ({ ...prevState, isArchiveTaskDialogOpen: true }));
  }

  onDismissArchiveHandler = () => {
    this.setState((prevState) => ({ ...prevState, isArchiveTaskDialogOpen: false }));
  }

  onDismissActionResultHandler = () => {
    this.setState((prevState) => ({ ...prevState, isActionResultDialogOpen: false }));
  }

  onConfirmArchiveHandler = async () => {
    const { taskMasterId, isArchive } = this.state;
    const archive = !isArchive;
    const action = archive ? 'ARCHIVE' : 'UNARCHIVE';
    await this.props.postUserActionToTaskMasterResourceStatus(action, taskMasterId);
    this.setState((prevState) => ({ ...prevState, isArchive: archive, isArchiveTaskDialogOpen: false, isActionResultDialogOpen: true }));
  }

  adoptSelectionToRule () {
    const rule = {
        ruleId: this.state.selRuleForDeletion.rule.id,
    };

    return rule;
  }

  // eslint-disable-next-line class-methods-use-this
  isRuleATaskMasterCode (rule) {
    return rule.rule.ruleCode.includes('TASK_MASTER_CODES');
  }

  async refreshSelCompositeRulesHandler () {
    this.props.fetchTaskBuilderMasters(this.state.taskMasterId);
  }

  showMasterDetails = () => {
       this.setState((prevState) => ({ ...prevState, isMasterDetailsPanelOpen: true }));
  }

  hideMasterDetailsPanelHandler = () => {
    this.props.fetchTaskMasters();
    const selectedTask = this.props.taskMasterFilteredData.masters.filter((item) => item.code === this.state.code);

    this.setState({
      currentName: selectedTask[0].label,
      currentDesc: selectedTask[0].description,
    });

    this.setState((prevState) => ({ ...prevState, isMasterDetailsPanelOpen: false }));
  }

  buildTaskDetail () {
    const { classes } = this.props;
    const { currentDesc, isArchive } = this.state;
    // const currentTask = this.props.taskMasterFilteredData.masters[selectedIndex];
    const currentTask = this.props.taskMasterFilteredData.masters.filter((item) => item.id === this.state.taskMasterId)[0];
    const isReadOnly = this.context.isReadOnly === true || currentTask?.resourceState === 'ACTIVE';

    return (<div className={classes.chgsetDetail}>
      <div>
        <Stack horizontal>
          <div><span className={classes.taskStatus}>{currentTask?.resourceState}</span></div>
          <div><Checkbox className={classes.archiveCheckbox} label="Archive task" onChange={this.handleArchiveChange} disabled={isReadOnly}
                checked={isArchive}/></div>
        </Stack>
        <div className={classes.lblSubtitle}>Description</div>
        <div className={classes.editControlSectionHeader} dangerouslySetInnerHTML={{ __html: currentDesc }}></div>
      </div>
      <div className={classes.pubDetails}>
        <div className={classes.divider1}></div>
        <div>
          <div className={classes.editHeader}>TASK MASTER SUMMARY</div>
        </div>
      </div>
    </div>);
  }

  render () {
    const { classes } = this.props;
    const taskProperties = this.props.tasks;
    const isReadOnly = this.context.isReadOnly === true;
    const { isArchive, isActionResultDialogOpen, currentName, isMasterDetailsPanelOpen, isRulePickerOpen, isArchiveTaskDialogOpen } = this.state;
    const archiveTaskTitleMessage = isArchive ? `Confirm Unarchive` : `Confirm Archive`;
    const actionArchive = isArchive ? `unarchive` : `archive`;
    const actionArchiveResult = isArchive ? `archived` : `unarchived`;
    const archiveTaskMessage = `Do you wish to ${actionArchive} <b>${currentName}?</b>`;
    const actionActionResultTitle = this.props.result === "SUCCESS" ? `Success` : `Failed`;
    const archiveActionResultMessage = `You have successfully ${actionArchiveResult} <b>${currentName}.</b>`;

    // eslint-disable-next-line global-require
    const OliLogo =
      this.props.result === "SUCCESS"
        ? // eslint-disable-next-line global-require
          require("../../images/oli-success.svg")
        : // eslint-disable-next-line operator-linebreak
          // eslint-disable-next-line global-require
          require("../../images/oli-fail.svg");

    return (
      <Box display="flex">
        <Box>
          <Card variant='outlined' className={classes.card}>
            <CardContent>
              <Typography variant="subtitle" className={classes.listHeader}>
                Select Task Master to edit
              </Typography>

              <Accordion className="searchlist">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography > Task Master Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filter source="TASK"></Filter>
                </AccordionDetails>
              </Accordion>
              {this.state.activeState
                 ? this.listTaskMastersLP()
                : <></>}
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={4}>
          <Card variant='outlined'>
            <CardContent>
              <div className={classes.headerSection}>
                <Box display="flex">
                  <Box flexGrow={1} >
                    <div className={classes.tmHeader}>Task Builder</div>
                  </Box>
                </Box>
              </div>
              <div className={`${classes.boxed} ${classes.descBottom}`}>
                 <Box display="flex" className={classes.titleSection}>
                  <Box flexGrow={1} >
                    <div>
                      <div className={classes.titleHeaderRow}>{this.state.currentName}</div>
                      <div className={classes.titleRow}>Last Updated: 19 Aug 2020 </div>
                    </div>
                  </Box>
                  <Box display="flex" flexDirection="column" style={{ alignItems: 'center', justifyContent: 'center', height: '50px' }} >
                    <IconButton iconProps={{ iconName: 'Settings', style: { fontSize: 23 }  }} styles={{ root: { color: 'white' }, rootHovered: { backgroundColor: '#0078d4', color: 'white' } }} onClick={this.showMasterDetails} />
                  </Box>
                </Box>
                { this.buildTaskDetail() }
              </div>

              {taskProperties?.map((task, _index1) => (
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.icon} />} aria-controls="panel1a-content" id="panel1a-header" className={classes.accordionHeader}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.accHeading}>{task.policyScheme.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.viewTaskLink}>{task.entitlementRules[0].policyEnforcementPoint.pepCode}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Card variant='outlined'>
                        {task.entitlementRules.map((entitle, _index2) => (
                          <>
                            <Box>
                              <Grid item xs={12}>
                                <button className={classes.whiteBtn} ><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add Decision Point</button>
                              </Grid>
                            </Box>
                            <Box>
                              <Card variant='outlined' className={classes.policyHeading}>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography className={classes.accHeading2}>Decision: {entitle.policyDecisionPoint.pdpReference}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography className={classes.viewTaskLink}>{entitle.policyDecisionPoint.pdpCode}</Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                              <Card variant='outlined' className={classes.carddetailbkg}>
                                <CardContent>
                                  {entitle.compositePolicyRules.map((pol, _index3) => (
                                    <div >
                                      <Grid item xs={12}>
                                        <div>
                                          <Typography className={classes.compHeader}>Composite Rule: {pol.policyTitle}</Typography>
                                        </div>
                                      </Grid>
                                      {pol.policyRules.map((rule, index) => (
                                        <div className={classes.ruleBox}>
                                          <Grid container>
                                            <Grid item xs={10}>
                                              <div>
                                                <Typography className={classes.accHeading2}>Rule: {rule.rule.ruleTitle}</Typography>
                                              </div>
                                            </Grid>
                                            <Grid item xs={2} >
                                              { !this.isRuleATaskMasterCode(rule)
                                              ? <SettingsRoundedIcon onClick={() => this.toggleRulePickerPanelForEdit(pol, rule)} className={classes.viewMoreLink} ></SettingsRoundedIcon> : null }
                                            </Grid>
                                            <RuleConfigValue mode="read" ruleConfigValues={rule.compositePolicyRuleInstanceConfigurations[0].configurationValue} refdata={this.props.refdata} xxruleVariables={rule.rule.ruleVariables} taskMasterDataRef={this.props.taskMasterData} />
                                          </Grid>
                                          <br />
                                        </div>
                                      ))}
                                      <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                          <div>
                                            <button className={classes.whiteBtn} onClick={() => this.toggleRulePickerPanelForAdd(pol)} ><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add Rule</button>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))} {/* end composite rule */}
                                </CardContent>
                              </Card>
                            </Box>
                          </>
                        ))}

                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <button className={classes.whiteBtn}><AddCircleOutlineIcon className={this.props.classes.addTaskLinkIcon}></AddCircleOutlineIcon>Add Composite Rule</button>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        </Box>

        {isRulePickerOpen
        ? <RulePicker action={this.state.actionForRulePicker}
          isReadOnly={isReadOnly}
          compositePolicy={this.state.selCompositePolicy}
          existingRuleIds={this.state.selExistingRuleIds}
          selRuleForEdit={this.state.selRuleForEdit}
          isRulePickerPanelOpen={isRulePickerOpen}
          dismissRulePickerPanel={this.dismissRulePickerPanelHandler}
          literalType={ActionLiteralType.TASK}
          refreshSelCompositeRules={this.refreshSelCompositeRulesHandler}
          refdata={this.props.refdata}
          taskMasterDataRef={this.props.taskMasterData}
          handleDeleteRuleFromComposite={this.handleDeleteRuleFromComposite}
        /> : null}

        {isMasterDetailsPanelOpen ? <TaskMasterEditDetail isMasterDetailsPanelOpen={isMasterDetailsPanelOpen} hideMasterDetailsPanelHandler={this.hideMasterDetailsPanelHandler}  literalType={ActionLiteralType.TASK} isReadOnly={isReadOnly} task={this.state.taskMasterId} taskProperties={taskProperties}/> : null}

        {isArchiveTaskDialogOpen ? (
          <ActionConfirmationDialog
            isOpen={isArchiveTaskDialogOpen}
            onDissmissHandler={this.onDismissArchiveHandler}
            onConfirmHandler={this.onConfirmArchiveHandler}
            messageTitle={archiveTaskTitleMessage}
            confirmationMessage={archiveTaskMessage}
          />
        ) : null}

        {isActionResultDialogOpen ? (
          <SuccessfullMessage
            dialogState={false}
            errorTitle={actionActionResultTitle}
            errorContentArray={[this.props.message, archiveActionResultMessage]}
            hidden={!isActionResultDialogOpen}
            closeDialog={this.onDismissActionResultHandler}
            cancelDialog={this.onDismissActionResultHandler}
            OliLogo={OliLogo}
          />
        ) : ("")}
      </Box>
    );
  }
}

const styles = (theme) => ({
  card: {
    backgroundColor: '#f3f2f1',
    width: 300,
    margin: 0
  },
  rootBackground: {
    height: '100%',
    backgroundColor: '#f3f2f1'
  },
  root: {
    flexGrow: 1
  },
  cardHeaderSection: {
    padding: '16px 0 0 0'
  },
  cardHeader: {
    fontSize: '27px',
    fontFamily: 'PharmaCond',
    padding: '16px',
    color: '#323130',
    fontWeight: '700',
    titleTypographyProps: { variant: 'h1' },
    title: {
      titleTypographyProps: { variant: 'h1' },
      fontSize: '12px'
    },
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  viewTaskLink: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab'
  },
  viewTaskLinkIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'right'
  },
  addTaskLinkIcon: {
    border: '0',
    backgroundColor: null,
    color: 'black',
    fontSize: '8px',
    cursor: 'grab',
    float: 'left',
    marginRight: '1rem'
  },
  viewTaskCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },

  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },

  policySchemesInUse: {
    width: '200px',
    height: '20px',
    margin: '20px 0 0 0px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#323130',
  },
  subtitle: {
    color: '#323130'
  },
  headerSection: {
    padding: '16px 0'
  },
  buttonSubmit: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#0078d4',
    color: '#fff',
    fontWeight: '600',
    orderRadius: '2px',
    border: 'none',
    cursor: 'pointer'
  },
  tmItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  tmItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
  titleSection: {
    backgroundColor: '#0078d4',
    height: '50px',
    color: '#fff',
    padding: '16px 33px'
  },
  titleHeaderRow: {
    fontSize: '20px',
    fontWeight: '600'
  },

  titleRow: {
    padding: '8px 0'
  },

  tmHeader: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'PharmaCond',
    color: '#323130'
  },
  descAlign: {
    width: '60%',
    float: 'left',
    overflowWrap: 'break-word',
    height: '120px'
  },
  notificationAlign: {
    width: '38%',
    float: 'right',
    height: '120px'
  },

  upperSpace: {
    paddingTop: '10px',
    height: '100%'
  },

  notificationType: {
    marginTop: '12px',
    paddingLeft: '15px',
    fontWeight: 'bold',
  },
  notificationTypevalue: {
    marginTop: '12px',
    paddingLeft: '15px',
  },

  descHeader: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '18px',
    padding: '20px 0 0 10px'
  },
  editControlSectionHeader: {
    color: 'black',
    fontWeight: '200',
    padding: '0 1.5em 1em 1em',
    display: 'inline-block',
    width: '-moz-max-content',
    margin: '1px',
    height: '140px'
  },
  editHeader: {
    width: '700px',
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
    padding: '20px 0 0 0',
  },
  boxed: {
    border: '2px solid lightgrey',
    height: '300px'
  },
  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '10px 0 10px 0',
    color: '#323130',
    paddingRight: '1rem'
  },
  accordionHeader: {
    backgroundColor: '#99c5ed',
    color: 'black',
    flexDirection: 'row-reverse',
    height: '84px'
  },
  icon: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    fontSize: 'large !important'
  },
  accHeading: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
    color: '#323130'
  },
  policyTitle: {
    fontSize: '1rem',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
  },
  policyHeading: {
    width: 'auto',
    height: '74px',
    marginTop: '0rem',
    borderRadius: '2px',
    backgroundColor: '#cce2f6'
  },
  accHeading2: {
    fontSize: '1rem',
    fontFamily: 'SegoeUI',
    fontWeight: 'bold',
  },
  carddetailbkg: {
    backgroundColor: '#f3f2f1',
    color: 'black',
    marginTop: '1rem'
  },
  descBottom: {
    paddingBottom: '10px'
  },

  listHeader: {
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },
  ruleBox: {
    marginTop: '2rem',
    borderBottom: 'solid 1px #bdbdbd',
    marginBottom: '2rem'
  },
  compHeader: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    marginBottom: '1rem'
  },
  taskStatus: {
    border: 'solid 1px #006ed2;',
    padding: '3px 12px 3px 11px',
  },
  divider1: {
    width: '1px',
    border: 'solid 0px #cdcdcd',
    backgroundColor: '#f3f2f1',
  },
  pubDetails: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 9fr',
    borderLeft: "solid 1px #979797",
  },
  chgsetDesc: {
    padding: '5px 0',
  },
  lblSubtitle: {
    width: '700px',
    height: '19px',
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323130',
    padding: '20px 0 0 0',
  },
  chgsetDetail: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridTemplateColumns: '7fr 3fr',
    gridGap: '20px',
    padding: '16px 0 0 16px',
  },
  archiveCheckbox: {
    marginLeft: '1rem'
  },
});

TaskMasterBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    taskMasterData: state.taskMastersData.taskMasters,
    taskMasterFilteredData: state.taskMastersData.taskMastersFiltered,
    tasks: state.taskLibraryBuilderData.tasks.taskMasterPolicyConfigurations,
    refdata: state.refData.referenceData,
    result: state.ui.result
  });

const connected = connect(mapStateToProps, {
  fetchTaskMasters,
  updateTaskMaster,
  postUserActionToTaskMasterResourceStatus,
  fetchTaskBuilderMasters,
  fetchReferenceData,
  removeRuleFromCompositeRule
})(AuthProvider(TaskMasterBuilder));

export default withStyles(styles)(connected);
