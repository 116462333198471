import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import history from '../../history';
import './reference.css';

class ReferenceDataGrid extends React.Component {
  clickMaster = (title) => {
    let targetLibrary;

    switch (title) {
      case 'Reference Data Management':
        targetLibrary = '/referenceDataConsole/referenceDataBuilder';
        break;
      case 'Policy Information Point Management':
        targetLibrary = '/referenceDataConsole/pipList';
        break;
      case 'Help Text Management':
        targetLibrary = '/referenceDataConsole/helpTextMgmBuilder';
        break;
      case 'Guidance Management':
        targetLibrary = '/referenceDataConsole/guidanceMgmt';
        break;
      case 'Mapping Table Management':
        targetLibrary = '/referenceDataConsole/tablesList';
        break;
      default:
        targetLibrary = '/secure';
    }

    history.push(targetLibrary);
  }

  renderCards () {
    return this.props.ReferenceDataSections.map((ia, index) => (<Box m={1} key={index} >
        <Card className='card' variant='outlined' style={{ width: '626px' }}>
          <CardActionArea component="div">
            <CardHeader className='cardHeaderSection'
              title={<Typography gutterBottom className='cardHeader'>
                {ia.title}
              </Typography>} />
            <CardContent className='cardContent'>
              <button className={!ia.enabled ? 'masterButtonDisabled' : 'masterButton'} disabled={!ia.enabled}
                onClick={() => { this.clickMaster(ia.title); }}>View {ia.title}</button>
              <hr className={'sepLine'} />
              <div className='subtitle' > {ia.title} Summary</div>
              <Typography className={"descriptionField"} color="textSecondary" gutterBottom>

              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>));
  }

  render () {
    return (
      this.renderCards()
    );
  }
}

export default ReferenceDataGrid;
