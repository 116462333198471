import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import '../../../components/style.css';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
 DefaultButton, PrimaryButton, initializeIcons, TextField, Checkbox, IconButton
} from '@fluentui/react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { updateGuidanceFragment, fetchGuidanceFragmentPolicyConfigSummaries } from '../../../state/actions/GuidanceFragmentActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import SuccessfulMessage from '../../../components/Dialog/SuccessfullMessage';
import renderHTMLContent from '../../../util/ConvertUtil';

const APIKeys = {
  EditorKey: '9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0'
};

const stackTokens = { childrenGap: 40 };

class GuidanceFragmentDetail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      show: this.props.open,
      currentText: '',
      currentName: '',
      currentCode: '',
      hideDialog: true,
      editorCountReady: false,
      isArchived: null,
      editorCount: 0,
      editorErrorMsg: '<p>Please ensure that you do not have more than 600 characters in the description Box</p>',
      open: false
    };
  }

  componentDidMount () {
    this.setState({ show: this.props.open });
    const selectedGuidance =  this.props.guidance;
    this.setState({
      currentCode: selectedGuidance.guidanceFragmentCode || selectedGuidance.code,
      currentName: selectedGuidance.guidanceFragmentName || selectedGuidance.name,
      currentText: selectedGuidance.text || this.props.guidanceFragmentDataList?.filter((item) => (item.id === this.props.guidance.id))[0]?.text,
      isBtnActivated: true
    });
    initializeIcons();
  }

  handleGuidanceNameChange = (event) => {
    this.setState({ currentName: event.target.value, isBtnActivated: false });
  }

  closeDialog = async () => {
    await this.handleGuidanceFragmentEdit();
    this.setState({ hideDialog: true });
    this.props.onClose();
    this.props.fetchGuidanceFragmentPolicyConfigSummaries();
  }

  cancelDialog = () => {
    this.setState({ hideDialog: true });
  }

  handleShowGuidanceFragmentEdit = () => {
    this.setState({ hideDialog: false });
  }

  handleGuidanceFragmentEdit = async () => {
    const guidanceFragmentUpdateRequest = {};
    guidanceFragmentUpdateRequest.id =  this.props.guidance.id;
    guidanceFragmentUpdateRequest.text = this.state.currentText;
    guidanceFragmentUpdateRequest.name = this.state.currentName;
    if (this.state.isArchived !== null) {
      guidanceFragmentUpdateRequest.archived = this.state.isArchived;
    }
    await this.props.updateGuidanceFragment(guidanceFragmentUpdateRequest);
    this.setState({ isBtnActivated: true, hideDialog: false });
  }

  onDismissGuidanceDetailPanel = () => {
    this.props.dismissGuidanceDetailPanel();
  }

  buildArchiveViewMode = (status) => (status === 'ACTIVE'
    ? <span><CheckCircleIcon className={this.props.classes.viewGuidanceCompleteIcon}></CheckCircleIcon>Active</span>
    : <span><Cancel className={this.props.classes.viewGuidanceInCompleteIcon}></Cancel>{status}</span>)

  buildArchiveEditMode = (status) => (
    <span>
      { status === "ACTIVE"
        ? <Stack horizontal className={this.props.classes.informational}>
            <IconButton iconProps={{ iconName: 'Info' }} />
            <p className={this.props.classes.message}> Please remove all linkages to archive this item </p>
          </Stack>
        : ''}
      <Checkbox key={status} disabled={this.props.isReadOnly || status === "ACTIVE"} label={"Archive"} onChange={(e) => this.handleArchiveChange(e)} defaultChecked={status === "ARCHIVED"}/>
    </span>
  )

  handleArchiveChange = (event) => {
    this.setState({ isArchived: event.target.checked });
  }

  onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  render () {
    const { classes, isReadOnly } = this.props;
    if (!this.props.guidance && !this.props.guidanceFragmentDataList) return <div></div>;
    const selectedGuidance = this.props.guidance;
    const currentCode = this.state.currentCode ? this.state.currentCode : selectedGuidance.guidanceFragmentCode;
    const currentText = this.state.currentText ? this.state.currentText : this.props.guidanceFragmentDataList?.filter((item) => (item.id === this.props.guidance.id))[0]?.text;
    const currentName = this.state.currentName ? this.state.currentName : selectedGuidance.guidanceFragmentName;
    const OliLogo = (this.props.result === 'SUCCESS') ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');

    return (
     <Panel
      isOpen={this.props.open}
      onDismiss={this.props.onClose}
      type={PanelType.custom}
      customWidth={'550px'}
      closeButtonAriaLabel="Close"
      headerText= "Edit Guidance Summary"
      >
        <Separator></Separator>
        <Stack className={classes.guidanceDetail}>
          <span className={classes.fieldName}>Guidance Reference</span>
          <div>{currentCode}</div>
        </Stack>
        <Stack horizontal tokens={stackTokens} className={classes.actions}>
          <DefaultButton text="Cancel"  className={classes.inputbox} onClick={this.props.onClose} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
          <PrimaryButton text="Save"  className={classes.inputbox} disabled={isReadOnly} onClick={() => this.handleShowGuidanceFragmentEdit()}/>
        </Stack>
        <Stack className={classes.guidanceDetail}>
          <TextField label="Guidance Fragment Name" value={currentName} onChange={(event) => this.handleGuidanceNameChange(event)} readOnly={isReadOnly} />
        </Stack>
        <Stack className={classes.guidanceDetail}>
          <span className={classes.fieldName}>Guidance Fragment Status</span>
          { this.props.hasArchiveFunctionality ? this.buildArchiveEditMode(this.props.guidance.status) : this.buildArchiveViewMode(this.props.guidance.status)}
        </Stack>
        <Stack className={classes.guidanceDetail}>
          <span className={classes.fieldName}>Guidance Text</span>
          {!isReadOnly
          ? <Editor
            apiKey={APIKeys.EditorKey}
            value={currentText}
            init={{
              branding: false,
              height: 300,
              browser_spellcheck: true,
              content_style: 'body { color:#605e5c; }',
              selector: 'textarea',
              allow_unsafe_link_target: true,
              menubar: 'insert',
              element_format: 'xhtml',
              plugins: [
                'wordcount advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help'
              ],
              paste_preprocess (plugin, args) {
              },
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | wordcount | help',
              init_instance_callback: (editor) => {
                this.setState({ editorCountReady: true, editorCount: editor.plugins.wordcount.body.getCharacterCount() });
              }
            }}
            onEditorChange={(content, editor) => {
              if ((content !== currentText) && ReactHtmlParser(content) !== ReactHtmlParser(currentText)) {
                this.setState({
                  isBtnActivated: false,
                  currentText: content,
                  editorCount: editor.plugins.wordcount.body.getCharacterCount()
                });
              }
            }}
            onBlur={(event, editor) => {
              this.setState({ editorCount: editor.plugins.wordcount.body.getCharacterCount() });
            }}
          /> : renderHTMLContent(currentText)}
        </Stack>

        {!this.state.hideDialog
            ? <SuccessfulMessage dialogState={false} errorTitle={this.props.result}
              errorContentArray={[this.props.message, 'Click Okay to continue.']}
              hidden={this.state.hideDialog}
              closeDialog={this.closeDialog}
              cancelDialog={this.cancelDialog}
              OliLogo={OliLogo}
            />
            : ''
        }
      </Panel>
    );
  }
}

const styles = (theme) => ({
  actions: {
    padding: '20px 0 20px 0'
  },
  guidanceDetail: {
    padding: '10px 0 10px 0',
  },
  inputbox: {
    border: '1px solid'
  },
  viewGuidanceCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  viewGuidanceInCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#555555',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  fieldName: {
    fontWeight: '600',
    color: '#323130',
    marginBottom: '5px'
  },
  informational: {
    margin: '0 15px 15px 0px',
    borderRadius: '2px',
    backgroundColor: '#f4f4f4'
  },
  message: {
    marginTop: '0.4rem'
  },
});

GuidanceFragmentDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => (
  {
  guidanceFragmentDataList: state.guidanceFragmentSummaryData.guidanceFragments,
  result: state.ui.result
}
);

const connected = connect(mapStateToProps, {
  updateGuidanceFragment,
  fetchGuidanceFragmentPolicyConfigSummaries
})(AuthProvider(GuidanceFragmentDetail));

export default withStyles(styles)(connected);
