import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TableMappingList from './TableMappingList';
import "../reference.css";

const getCategoryValue = (categoryId, refData) => {
   const filterRef = refData.filter((ref) => ref.id === categoryId);

   if (filterRef && filterRef.length > 0) return filterRef[0].value.replaceAll("_", " ");

   return "";
};

const TableMappingCard = ({
 subjectMatter, pips, type, refData
}) => (
    <Accordion defaultExpanded={true} className={'accordionSection'}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={"icon"}></ExpandMoreIcon>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={'accordionHeader'} >
        <Grid container spacing={2} className={"tableListHeaderSection"}>
          <Grid item sm container xs={12} className={"tableListHeaderSectionItem"}>
            <Grid item xs={11} >
              <div className={"tableListCategoryHeader"}>{getCategoryValue(subjectMatter, refData)}</div>
            </Grid>

            <Grid item xs={1} container direction="column" >
              <div className={"tableListCategoryLastUpdated"}>Last Updated:</div>
              <div>19 Sep 2021</div>
            </Grid>
          </Grid>

        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Card>
          <TableMappingList key={`key-${pips.length}`} pipList={pips} type={type} />
        </Card>
      </AccordionDetails>
    </Accordion>
  );


export default TableMappingCard;
