import React from 'react';
import uuid from 'react-uuid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Icon } from '@fluentui/react/lib/Icon';
import { initializeIcons } from '@fluentui/react';

import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../components/auth/AuthProvider';
import { fetchPolicyLibraryMasters } from '../../state/actions/PolicyLibraryMasterActions';

import '../../components/style.css';

class PolicyLibraryPDP extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            entitlementRule: null,
            toggleIcon1: 'ChevronDownMed',
            toggleIcon2: 'ChevronDownMed',
            panelKey: null,
        };
        initializeIcons();
    }

    async componentDidMount () {
        document.title = 'Policy Scheme';

        if (this.props.entitlementRule) {
            this.setState((prevState) => ({
              ...prevState,
              entitlementRule: this.props.entitlementRule,
              panelKey: uuid()
            }));
        }

        this.setInitialPanelDisplay('LOALvl1');
        this.setInitialPanelDisplay('LOALvl2');
    }

    setInitialPanelDisplay (key) {
        if (this.state.entitlementRule && this.state.panelKey) {
            const id = `${key}-${this.state.panelKey}`;
            const doc = document.getElementById(id);
            const panel = doc.nextElementSibling;
            panel.style.display = 'none';
        }
    }

    togglePanel (pref, id) {
        const vid = `${pref}-${id}`;
        const doc = document.getElementById(vid);
        const panel = doc.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }

        if (pref === 'LOALvl1') {
            this.setState((prevState) => ({
                ...prevState,
                toggleIcon1: this.state.toggleIcon1 === 'ChevronDownMed' ? 'ChevronUpMed' : 'ChevronDownMed',
              }));
        } else if (pref === 'LOALvl2') {
            this.setState((prevState) => ({
                ...prevState,
                toggleIcon2: this.state.toggleIcon2 === 'ChevronDownMed' ? 'ChevronUpMed' : 'ChevronDownMed',
              }));
        }
    }

    constructPolicyRulesVariables (key3, policyRules) {
        const { classes } = this.props;
        const list = [];

        policyRules.forEach((item) => {
            list.push(
                <>
                    <div className={classes.schemeHeaderLOALvl3}>
                        <div>
                            <div className={classes.schemeBodyLOALvl3r1}>
                                {item.rule.ruleTitle}:
                            </div>
                            <div className={classes.schemeBodyLOALvl3r2}>
                                {item.rule.ruleVariables.length > 0 ? item.rule.ruleVariables[0].variableName + ' Variable': 'Variable TBA'}
                            </div>
                        </div>
                    </div>
                </>
                );
            });
        return (
            <div id={`LOALvl3-${key3}`}>
                {list}
            </div>
        );
    }

    constructPolicyRules (key2) {
        const { classes } = this.props;
        const list = [];

        if (this.state.entitlementRule) {
            this.state.entitlementRule.compositePolicyRules.forEach((item) => {
                list.push(
                    <>
                        <div className={classes.schemeHeaderLOALvl2} key={`LOALvl2-${item.id}`} id={`LOALvl2-${item.id}`} onClick={() => this.togglePanel('LOALvl2', item.id)}>
                            <div>
                                <div className={classes.schemeBodyLOALvl2r1}>
                                    {item.policyTitle}
                                </div>
                                <div className={classes.schemeBodyLOALvl2r2}>
                                    {item.policyRules.length} Rules
                                </div>
                            </div>
                            <div>
                                <Icon iconName={this.state.toggleIcon2} className={classes.iconStyle1} />
                            </div>
                        </div>

                        {this.constructPolicyRulesVariables(item.id, item.policyRules)}

                    </>
                );
            });
        } else {
            return null;
        }

        return (
            <div id={`LOALvl2-${key2}`}>
                {list}
            </div>
        );
    }

    render () {
        const { classes } = this.props;
        const { entitlementRule, panelKey } = this.state;
        const pdpCode = entitlementRule ? entitlementRule.policyDecisionPoint.pdpCode : '';
        const pdpTitle = entitlementRule ? entitlementRule.policyDecisionPoint.pdpTitle : '';
        const rulesLength = entitlementRule ? entitlementRule.compositePolicyRules.length : 0;

            return (
                <div key={`LOALvl0-${panelKey}`} className={classes.schemeHeaderLOALvl1Holder}>
                    <div className={classes.schemeHeaderLOALvl1} id={`LOALvl1-${panelKey}`} onClick={() => this.togglePanel('LOALvl1', panelKey)}>
                        <div>
                            <div className={classes.schemeBodyLOALvl1r1}>
                                {pdpCode}
                            </div>
                            <div className={classes.schemeBodyLOALvl1r2}>
                                {pdpTitle}
                            </div>
                            <div className={classes.schemeBodyLOALvl1r3}>
                                1 Decision // {rulesLength} Rule(s)
                            </div>
                        </div>
                        <div>
                            <Icon iconName={this.state.toggleIcon1} className={classes.iconStyle1} />
                        </div>
                    </div>
                    {this.constructPolicyRules(panelKey)}
                </div>
            );
    }
}

const styles = (theme) => ({
    cardHeader: {
        fontFamily:'PharmaCond',
        fontSize: '24px',
        color:'#323130',
        fontWeight: '600',
    },

    cardHeaderSection: {
        padding: '16px 0 0 16px',
        //    backgroundColor: '#006ed2'
    },

    card: {
        borderColor: blue[200],
        width: 200,
        height: 320,
        color: '#000',
        margin: '8px 16px'
    },

    schemeHeaderLOALvl1Holder: {
        margin: '10px 0px 0px 0px',
    },

    schemeHeaderLOALvl1: {
        width: '418px',
        height: '70px',
        // margin: '16px 48px 16px 1px',
        padding: '10px 23.8px 12px 24px',
        borderRadius: '2px',
        border: 'solid 1px #979797',
        backgroundColor: '#dcdcdc',
        display: 'grid',
        // width: '100%',
        gridTemplateColumns: '9fr 1fr',
    },

    schemeBodyLOALvl1r1: {
        width: '377px',
        height: '20px',
        margin: '0 21px 2px 0',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#323130',
    },

    schemeBodyLOALvl1r2: {
        width: '377px',
        height: '40px',
        margin: '0 21px 4px 0',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#323130',
    },

    schemeBodyLOALvl1r3: {
        width: '377px',
        height: '20px',
        margin: '0 21px 0 0',
        fontFamily: 'SegoeUI',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        color: '#555555',
    },

    schemeHeaderLOALvl2: {
        width: '418px',
        height: '70px',
        margin: '16px 0px 16px 0px',
        padding: '16px 23.8px 12px 24px',
        borderRadius: '2px',
        border: 'solid 1px #979797',
        backgroundColor: '#f6f6f6',
        display: 'grid',
        // width: '100%',
        gridTemplateColumns: '9fr 1fr',
    },

    schemeBodyLOALvl2r1: {
        width: '377px',
        height: '40px',
        margin: '0 23px 8px 0',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#323130',
    },

    schemeBodyLOALvl2r2: {
        width: '40px',
        height: '16px',
        margin: '10px 23px 0 0',
        fontFamily: 'SegoeUI',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        color: '#555555',
    },

    schemeHeaderLOALvl3: {
        width: '418px',
        height: '40px',
        margin: '0 0 8px',
        padding: '16px 23.8px 12px 24px',
        borderRadius: '2px',
        border: 'solid 1px #979797',
    },

    schemeBodyLOALvl3r1: {
        width: '377px',
        height: '20px',
        margin: '0 48px 0 0',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#323130',
    },

    schemeBodyLOALvl3r2: {
        width: '377px',
        height: '20px',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#323130',
    },

    iconStyle1: {
        width: '24px',
        height: '24px',
        // margin: '24px 0 16px 39px',
        fontSize: '24.2px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },

  });

  PolicyLibraryPDP.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        policyLibraryData: state.policyLibraryMasterData.policyLibraryMasters
    };
};

const connected = connect(mapStateToProps, {
    fetchPolicyLibraryMasters
})(AuthProvider(PolicyLibraryPDP));

export default withStyles(styles)(connected);
