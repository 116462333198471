import React, { useState } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import PropTypes from "prop-types";
import "./SearchBar.css";

const SearchBar = (props) => {
  initializeIcons(/* optional base url */);
  const [searchText, setSearchText] = useState("");
  return (
    <div className="searchBarContainer">
      <TextField
        id="searchBarTextField"
        placeholder={props.placeholderText}
        value={searchText}
        onChange={(ev) => setSearchText(ev.val)}
      />
      <DefaultButton
        iconProps={{ iconName: "Search" }}
        className="searchBtn"
        onClick={props.handleSearch(searchText)}
        text="Search"
      />
    </div>
  );
};

SearchBar.defaultProps = {
  placeholderText: "Enter Search Item",
};

SearchBar.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  handleSearch: PropTypes.func,
};

export default SearchBar;
