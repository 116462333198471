import {
    FETCH_POLICYLIBRARY_MASTER,
    FETCH_POLICYLIBRARY_MASTERS,
    FETCH_POLICYLIBRARY_MASTERS_STARTED,
    FETCH_POLICYLIBRARY_MASTERS_FAILED,
    UPDATE_POLICY_MASTER_DETAILS,
    UPDATE_POLICY_SCHEME_PDP_DETAILS,
    CREATE_POLICY_SCHEME_PDP_DETAILS,
    DELETE_POLICY_SCHEME_PDP_DETAILS,
    DUPLICATE_POLICY_SCHEME,
    FETCH_ENTITLEMENT_OPERATION_TYPES,
    CREATE_POLICY_MASTER_DETAILS
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
    policyLibraryMasters: [],
    policyLibraryFiltered: [],
    error: null,
    isloading: false,
    updatePolicyMasterResponse: {},
    entitlementOperationTypes: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_POLICYLIBRARY_MASTERS:
            return { ...state, policyLibraryMasters: action.payload };
        case FETCH_POLICYLIBRARY_MASTERS_STARTED:
            return { ...state, isloading: true };
        case FETCH_POLICYLIBRARY_MASTERS_FAILED:
            return { ...state, isloading: false };
        case FETCH_POLICYLIBRARY_MASTER:
            return { ...state, [action.payload.code]: action.payload };
        case UPDATE_POLICY_MASTER_DETAILS:
            return { ...state, updatePolicyMasterResult: action.payload };
        case UPDATE_POLICY_SCHEME_PDP_DETAILS:
            return { ...state, updatePolicySchemePdpResult: action.payload };
        case DUPLICATE_POLICY_SCHEME:
            return { ...state, duplicatePolicySchemeResult: action.payload };
        case FETCH_ENTITLEMENT_OPERATION_TYPES:
            return { ...state, entitlementOperationTypes: action.payload };
        case CREATE_POLICY_MASTER_DETAILS:
            return { ...state, newPolicySchemeResult: action.payload };
        case CREATE_POLICY_SCHEME_PDP_DETAILS:
            return { ...state, newPolicySchemePdpResult: action.payload };
        case DELETE_POLICY_SCHEME_PDP_DETAILS:
            return { ...state, deletePolicySchemePdpResult: action.payload };
        default:
            return state;
    }
};
