import {
  FETCH_COMPOSITE_RULES_BY_COMPOSITE_RULE,
  FETCH_POLICY_RULE_VARIABLES_BY_RULE,
  FETCH_POLICY_COMPOSITE_RULE_BY_ID,
  ADD_RULE_TO_COMPOSITE_RULE,
  MODIFY_RULE_TO_COMPOSITE_RULE,
  REMOVE_RULE_TO_COMPOSITE_RULE,
  ADD_COMPOSITE_RULE_TO_POLICY,
  UPDATE_COMPOSITE_RULE_TO_POLICY,
  REMOVE_COMPOSITE_RULE_FROM_POLICY
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPOSITE_RULES_BY_COMPOSITE_RULE:
      return { ...state, compatibleCompositeRules: action.payload };
    case FETCH_POLICY_RULE_VARIABLES_BY_RULE:
      return { ...state, ruleVariables: action.payload };
    case FETCH_POLICY_COMPOSITE_RULE_BY_ID:
      return { ...state, compositeRule: action.payload };
    case ADD_RULE_TO_COMPOSITE_RULE:
      return { ...state, modifyRuleResult: action.payload };
    case MODIFY_RULE_TO_COMPOSITE_RULE:
      return { ...state, modifyRuleResult: action.payload };
    case REMOVE_RULE_TO_COMPOSITE_RULE:
      return { ...state, removeRuleResult: action.payload };
    case ADD_COMPOSITE_RULE_TO_POLICY:
      return { ...state, addCompositeRuleResult: action.payload };
    case UPDATE_COMPOSITE_RULE_TO_POLICY:
      return { ...state, updateCompositeRuleResult: action.payload };
    case REMOVE_COMPOSITE_RULE_FROM_POLICY:
      return { ...state, removeCompositeRuleResult: action.payload };
    default:
      return state;
  }
};
