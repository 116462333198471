import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
  DefaultButton, PrimaryButton, initializeIcons, Text, TextField
} from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { Dropdown } from '@fluentui/react/lib/Dropdown';

import { withStyles } from '@material-ui/styles';
import SuccessfulMessage from '../../../components/Dialog/SuccessfullMessage';
import { updatePipValue } from '../../../state/actions/PIPActions';
import AuthProvider from '../../../components/auth/AuthProvider';


class PiPDetails extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      policyInformationPoint: this.props.selectedPip,
      isCurrentlyInSelectionMode: true, // 2 modes, Editor (true) and Confirmation (false)
      isSubmitInProgress: false,
      withSubmitError: false,
      isUpdated: true,
      selCategory: this.props.selectedPip.categoryId
    };
    initializeIcons();
  }

  // eslint-disable-next-line class-methods-use-this
  async componentDidMount () {
    document.title = "Edit Mapping Table Details";
  }

  onRenderButtonText = ((p, label) => <span id={p.labelId} className="ms-Button-label" style={{ fontWeight: '400' }}>{label}</span>);

  /* Step and Window handler */

  onDismissPolicyMasterDetailsPanel = () => {
    this.props.hideDetailsPanelHandler();
  }

  onChangePolicyName = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.policyInformationPoint;
    val.name = newVal;
    this.setState((prevState) => ({ ...prevState, isUpdated: false, policyInformationPoint: val }));
  }

  onChangePolicyDescription = (event) => {
    const newVal = (event.target.value ? event.target.value : '');
    const val = this.state.policyInformationPoint;
    val.description = newVal;
    this.setState({ isUpdated: false, policyInformationPoint: val });
  }

 handleChangeCategory = (event, item) => {
    const newVal = (item.key ? item.key : '');
    const val = this.state.policyInformationPoint;
    val.categoryId = newVal;
    this.setState({ isUpdated: false, policyInformationPoint: val, selCategory: item.key });
  }

 saveChangesToPolicy = async () => {
    const { id, name, description, categoryId } = this.state.policyInformationPoint;

    const newScheme = {
      pipName: name,
      pipDescription: description,
      pipCategoryId: categoryId
    };

    await this.props.updatePipValue(id, newScheme);
    this.setState((prevState) => ({ ...prevState, isCurrentlyInSelectionMode: false, isSubmitInProgress: false, withSubmitError: false, isUpdated: true }));
  }


  /* Screens */
  closeDialog = () => {
    this.setState({ isCurrentlyInSelectionMode: true });
  }

  buildTableDetailsEditor () {
    const { classes, isReadOnly, selectedPip, dropDownOptions } = this.props;
    const { isUpdated, policyInformationPoint, selCategory } = this.state;

    if (!selectedPip) return;

    const charsLeft = (500 - selectedPip.description.length);

    // eslint-disable-next-line consistent-return
    return (
      <>
        <Stack horizontal tokens={stackTokens} className={classes.actions}>
          <DefaultButton text="Cancel" onClick={this.onDismissPolicyMasterDetailsPanel} onRenderText={(p) => this.onRenderButtonText(p, 'Cancel')} />
          <PrimaryButton text="Save" onClick={this.saveChangesToPolicy} disabled={isUpdated || isReadOnly} />
        </Stack>
        <Separator></Separator>
        <Stack>
          <div className={classes.sectionTitle}>Mapping Data Details</div>
        </Stack>
        <Stack tokens={stackTokens}>
          <Stack className={classes.inputArea}>
            <TextField label="Mapping Table Name" value={policyInformationPoint.name} style={{ color: 'green' }}
              readOnly={isReadOnly}
              onChange={this.onChangePolicyName}
            />
          </Stack>
        </Stack>
        <Stack tokens={stackTokens}>
          <Stack className={classes.inputArea}>
            <TextField label="Mapping Table Description" value={policyInformationPoint.description}
              onChange={this.onChangePolicyDescription} multiline={true} style={{ height: '110px' }}
              maxLength={500} readOnly={isReadOnly} />
            <Text style={{ fontSize: '12px' }}>You have {charsLeft} characters left</Text>
          </Stack>
        </Stack>
         <Stack tokens={stackTokens}>
            <Stack className={classes.inputArea}>
              <span className={classes.taskDetail}>Mapping Category</span>
               <div> <Dropdown label=""
                        selectedKey={selCategory}
                        onChange={this.handleChangeCategory}
                        placeholder="Select Category"
                        options={dropDownOptions}
                        styles={this.dropdownStyles}
                      />
               </div>
            </Stack>
          </Stack>
      </>
    );
  }

  buildConfirmationScreen () {
    // eslint-disable-next-line global-require
    const OliLogo = (this.props.result === "SUCCESS") ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');

    return (
      <SuccessfulMessage dialogState={false} errorTitle={this.props.result}
        errorContentArray={[this.props.message, 'Click Okay to continue.']}
        hidden={this.state.isCurrentlyInSelectionMode}
        closeDialog={this.closeDialog}
        cancelDialog={this.closeDialog}
        OliLogo={OliLogo}
      />
    );
  }

  render () {
    const { isCurrentlyInSelectionMode } = this.state;

    return (
      <Panel
        isOpen={this.props.isTableDetailsPanelOpen}
        onDismiss={this.onDismissPolicyMasterDetailsPanel}
        type={PanelType.custom}
        customWidth={'650px'}
        closeButtonAriaLabel="Close"
        headerText={"Edit PIP Table Details"}
      >
        <Separator></Separator>
        {this.buildTableDetailsEditor()}
        {!isCurrentlyInSelectionMode ? this.buildConfirmationScreen() : ""
        }
      </Panel>
    );
  }
}

const stackTokens = { childrenGap: 40 };

const styles = (theme) => ({
  rootBackground: {
    height: '100%',
    backgroundColor: '#f3f2f1'
  },
  root: {
    flexGrow: 1
  },
  title: {
    padding: '60px 0 60px 0',
    color: '#605e5c',
    backgroundColor: '#fff',
  },
  inputArea: {
    padding: '0 10px 10px 10px',
    borderRadius: '2px',
  },

  instruction: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '16px 0 0 0'
  },
  catStyle: {
    padding: '0 0 0 0'
  },
  basicStyles: {
    maxWidth: '600',
    border: '0px solid  #dcdcdc',
    margin: '0'
  },
  tagPickerClass: {
    inlineSize: '450px',
    overflowWrap: 'break-word',
  },
  compositeTextStyle: {
    fontSize: '16px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '15px 0 0 0'
  },
  compositeTextStyle2: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
  },
  sectionTitle: { // used
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '15px 0 15px 0',
  },
  selectStyle: {
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    fontWeight: '600',
    padding: '0 0 5px 0',
  },
  actions: {
    padding: '20px 0 20px 0'
  },
  selectedRuleBlock: {
    padding: '20px 0 0 0'
  },
  previewPart: {
    backgroundColor: '#f6f6f6'
  },
  stackItemInfo: {
    alignItems: 'center',
    display: 'block',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  stackItemDelete: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    maxWidth: 80,
    minWidth: 80,
    borderLeft: '2px solid lightgrey',
  },
  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '10px 0 10px 0'
  },
  card: { // used
    padding: '15px', /* JUST TO LOOK COOL */
    border: '1px solid #eee',  /* JUST TO LOOK COOL */
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
    transition: 'all .3s ease-in-out',
  },
  withSubmitError: { // used
    color: 'red',
  },
  button: {
    fontWeight: '400',
  }
});

PiPDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.ui.message,
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  updatePipValue
})(AuthProvider(PiPDetails));

export default withStyles(styles)(connected);
