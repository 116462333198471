/* eslint-disable operator-linebreak */
/* eslint-disable space-before-function-paren */
import React from "react";
import { connect } from "react-redux";
import TableRow from "@material-ui/core/TableRow";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Editor } from "@tinymce/tinymce-react";
import { BodyTableCell } from "./TableStyling";
import {
  delNotificationRoleMappings,
  delNotificationFunctionMappings,
  delNotificationRecipientStrategyMappings,
  delNotificationAssociatedEventTypeMappings,
  deleteNotificationFunction,
  delChangeTypeEventTypeMapping,
  delRequiredFieldsDecisionPointTypeMapping,
  delManagedFieldsDecisionPointTypeMapping,
  delRequiredFieldsCheckpointTypeMapping,
  delPRRCriteriaCategoryMapping,
  delPRRCriteriaEntityMapping,
  delPRRCriteriaTagMapping,
  updatePRRCriteria,
  delPRRCriteria,
  updateCriteriaOption,
  delCriteriaOption,
  updateNotificationFunction,
  fetchAllChangeTypeEventTypeMappings,
  fetchAllTableMappings,
  fetchAllFunctions,
  fetchNotificationMsaterRoleMappings,
  fetchAllEventTypes,
  fetchAllRequiredFieldsDecisionPointTypeMappings,
  fetchAllManagedFields,
  fetchAllManagedFieldsDecisionPointTypeMappings,
  fetchAllRequiredFieldsCheckpointTypeMappings,
  fetchAllPRRCriteriaCategories,
  fetchAllPRRCriterias,
  fetchAllPRRCriteriaEntities,
  fetchAllPRRCriteriaTags,
} from "../../../state/actions/MappingTablesActions";
import { fetchNotificationMasters } from "../../../state/actions/NotificationMastersAction";
import "../reference.css";
import AuthProvider from "../../../components/auth/AuthProvider";
import SuccessfulMessage from "../../../components/Dialog/SuccessfullMessage";
import DeleteConfirmDialog from "../../../components/Dialog/DeleteConfirmDialog";
import renderHTMLContent from "../../../util/ConvertUtil";
import { OPTION_VALUES } from "./TableConstants";

const readOnlyFields = ["Prr Ref", "Criteria", "Function Code", "Value"];
const numberFields = ["Sort Order"];
const multilineFields = ["Question", "Option"];

const numberFieldStyles = {
  fieldGroup: { width: 80 },
};
const textFieldStyles = {
  fieldGroup: { width: 250 },
};

const dropdownStyleCategory = { dropdown: { width: 150 } };

export const onRenderCaretDown = () => <Icon iconName="ChevronDownMed" />;

const APIKeys = {
  EditorKey: "9zzajpek29ouo6sfslsivym6nniii2fyhx1noi6w0f30xft0",
};
let editorCharLeft = 0;

class UpdateTableMappings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideDialog: true,
      disableAddValueButton: true,
      selectedPip: null,
      showDelDialog: false,
      delAttrValue: "",
      tableColNames: [],
      tableMappings: [],
      delConfirmedMappingTblValue: "",
      isSaved: false,
      isEditing: [],
      editorCountReady: false,
      editorCount: 0,
      editorErrorMsg:
        "<p>Please ensure that you do not have more than 600 characters in the Summary Box</p>",
    };
  }

  componentDidMount() {
    const {
      selectedPip,
      selectedDropdownValues,
      tableColNames,
      tableMappings,
    } = this.props;
    const isEditing = Array(
      tableMappings.length > 0 ? tableMappings.length - 1 : 0
    ).fill(false);
    this.setState({
      selectedPip,
      selectedDropdownValues,
      tableColNames,
      tableMappings,
      isEditing,
    });
  }

  componentDidUpdate(preProps) {
    const { selectedPip, tableMappings } = this.props;

    if (selectedPip && selectedPip !== preProps.selectedPip) this.setState({ selectedPip });
    if (tableMappings && tableMappings !== preProps.tableMappings) this.setState({ tableMappings });
  }

  deleteTblValue = async () => {
    const { selectedPip, delAttrValue } = this.state;

    switch (selectedPip.configValue) {
      case "Notification Function":
        await this.props.deleteNotificationFunction(delAttrValue.functionId);
        await this.props.fetchAllFunctions();
        this.resetDataAfterUpdate();
        break;
      case "Notification Master Recipient Strategy Mapping":
        await this.props.delNotificationRecipientStrategyMappings(
          delAttrValue.notificationMasterId,
          delAttrValue.recipientStrategyId
        );
        await this.props.fetchNotificationMasters();
        this.resetDataAfterUpdate();
        break;
      case "Notification Master Role Mapping":
        await this.props.delNotificationRoleMappings(
          delAttrValue.notificationMasterId,
          delAttrValue.notificationRoleId
        );
        await this.props.fetchNotificationMsaterRoleMappings();
        this.resetDataAfterUpdate();
        break;
      case "Notification Master Function Mapping":
        await this.props.delNotificationFunctionMappings(
          delAttrValue.notificationMasterId,
          delAttrValue.functionId
        );
        await this.props.fetchNotificationMasters();
        this.resetDataAfterUpdate();
        break;
      case "Notification Master Event Mapping":
        await this.props.delNotificationAssociatedEventTypeMappings(
          delAttrValue.notificationMasterId,
          delAttrValue.eventType
        );
        await this.props.fetchNotificationMasters();
        this.resetDataAfterUpdate();
        break;
      case "Admin Change Item Type Event Mapping":
        await this.props.delChangeTypeEventTypeMapping(
          delAttrValue.changeTypeId,
          delAttrValue.eventType
        );
        await this.props.fetchAllChangeTypeEventTypeMappings();
        this.resetDataAfterUpdate();
        break;
      case "Required Field Decision Point Type Mapping":
        await this.props.delRequiredFieldsDecisionPointTypeMapping(
          delAttrValue.managedFieldId,
          delAttrValue.decisionPointTypeId
        );
        await this.props.fetchAllRequiredFieldsDecisionPointTypeMappings();
        this.resetDataAfterUpdate();
        break;
      case "Managed Field Decision Point Type Mapping":
        await this.props.delManagedFieldsDecisionPointTypeMapping(
          delAttrValue.managedFieldId
        );
        await this.props.fetchAllManagedFieldsDecisionPointTypeMappings();
        this.resetDataAfterUpdate();
        break;
      case "Required Field Checkpoint Type Mapping":
        await this.props.delRequiredFieldsCheckpointTypeMapping(
          delAttrValue.managedFieldId,
          delAttrValue.checkpointTypeId
        );
        await this.props.fetchAllRequiredFieldsCheckpointTypeMappings();
        this.resetDataAfterUpdate();
        break;
      case "Risk Rating Criteria Categories":
        await this.props.delPRRCriteriaCategoryMapping(
          delAttrValue.criteriaId,
          delAttrValue.riskCategoryType
        );
        await this.props.fetchAllPRRCriteriaCategories();
        this.resetDataAfterUpdate();
        break;
      case "Risk Rating Criteria Entity Mapping":
        await this.props.delPRRCriteriaEntityMapping(
          delAttrValue.criteriaId,
          delAttrValue.entityId
        );
        await this.props.fetchAllPRRCriteriaEntities();
        this.resetDataAfterUpdate();
        break;
      case "Prr Restriction Tag":
        await this.props.delPRRCriteriaTagMapping(
          delAttrValue.tagId,
          delAttrValue.tagValue
        );
        await this.props.fetchAllPRRCriteriaTags();
        this.resetDataAfterUpdate();
        break;
      case "Risk Rating Criteria":
        await this.props.delPRRCriteria(
          delAttrValue.criteriaId,
          delAttrValue.criteriaTitle
        );
        await this.props.fetchAllPRRCriterias("displayOrder");
        this.resetDataAfterUpdate();
        break;
      case "Risk Rating Criteria Option":
        await this.props.delCriteriaOption(
          delAttrValue.criteriaOptionId,
          delAttrValue.criteriaOption
        );
        await this.props.fetchAllPRRCriterias("title");
        this.resetDataAfterUpdate();
        break;
      default:
    }
  };

  delTblMapping = (tableMapping) => {
    const { selectedPip } = this.state;
    let delAttrValue;
    let delConfirmText;

    switch (selectedPip.configValue) {
      case "Notification Function":
        delAttrValue = {
          functionId: tableMapping[0].colId,
        };
        break;
      case "Notification Master Recipient Strategy Mapping":
        delAttrValue = {
          notificationMasterId: tableMapping[0].colId,
          recipientStrategyId: tableMapping[1].colId,
        };
        break;
      case "Notification Master Role Mapping":
        delAttrValue = {
          notificationMasterId: tableMapping[0].colId,
          notificationRoleId: tableMapping[1].colId,
        };
        break;
      case "Notification Master Function Mapping":
        delAttrValue = {
          notificationMasterId: tableMapping[0].colId,
          functionId: tableMapping[1].colId,
        };
        break;
      case "Notification Master Event Mapping":
        delAttrValue = {
          notificationMasterId: tableMapping[0].colId,
          eventType: tableMapping[1].colValue,
        };
        break;
      case "Admin Change Item Type Event Mapping":
        delAttrValue = {
          changeTypeId: tableMapping[0].colId,
          eventType: tableMapping[1].colValue,
        };
        break;
      case "Required Field Decision Point Type Mapping":
        delAttrValue = {
          managedFieldId: tableMapping[0].colId,
          decisionPointTypeId: tableMapping[1].colId,
        };
        break;
      case "Managed Field Decision Point Type Mapping":
        delAttrValue = {
          managedFieldId: tableMapping[1].colId,
        };
        break;
      case "Required Field Checkpoint Type Mapping":
        delAttrValue = {
          managedFieldId: tableMapping[0].colId,
          checkpointTypeId: tableMapping[1].colId,
        };
        break;
      case "Risk Rating Criteria Categories":
        delAttrValue = {
          criteriaId: tableMapping[0].colId,
          riskCategoryType: tableMapping[1].colValue,
        };
        break;
      case "Risk Rating Criteria Entity Mapping":
        delAttrValue = {
          criteriaId: tableMapping[0].colId,
          entityId: tableMapping[1].colId,
        };
        break;
      case "Prr Restriction Tag":
        delAttrValue = {
          tagId: tableMapping[1].colId,
          tagValue: tableMapping[2].colValue,
        };
        break;
      case "Risk Rating Criteria":
        delAttrValue = {
          criteriaId: tableMapping[1].colId,
          criteriaTitle: tableMapping[1].colValue,
        };
        delConfirmText = `${tableMapping[0].colValue}, ${tableMapping[1].colValue}`;
        break;
      case "Risk Rating Criteria Option":
        delAttrValue = {
          criteriaOptionId: tableMapping[1].colId,
          criteriaOption: tableMapping[1].colValue,
        };
        break;
      default:
        break;
    }

    this.setState({
      showDelDialog: true,
      delAttrValue,
      delConfirmedMappingTblValue: delConfirmText ? delConfirmText :
        tableMapping.length < 3
          ? `${tableMapping[0].colValue}, ${tableMapping[1].colValue}`
          : `${tableMapping[0].colValue}, ${tableMapping[1].colValue}, ${tableMapping[2].colValue}`,
    });
  };

  editTblMapping = async (item, index) => {
    const { selectedPip, isEditing } = this.state;
    let updateValues;
    const isEditingCols = isEditing;

    if (isEditingCols[index]) {
      switch (selectedPip.configValue) {
        case "Risk Rating Criteria":
          updateValues = {
            prrRef: item[0].colValue,
            title: item[1].colValue,
            question: item[2].colValue,
            sortOrder: Number(item[3].colValue),
          };
          await this.props.updatePRRCriteria(
            item[1].colId,
            item[1].colValue,
            updateValues
          );
          await this.props.fetchAllPRRCriterias("displayOrder");
          break;
        case "Risk Rating Criteria Option":
          updateValues = {
            criteriaOption: item[1].colValue,
            value: item[2].colValue,
            sortOrder: Number(item[3].colValue),
          };
          await this.props.updateCriteriaOption(
            item[1].colId,
            item[1].colValue,
            updateValues
          );
          await this.props.fetchAllPRRCriterias("title");
          break;
        case "Notification Function":
          updateValues = {
            name: item[1].colValue,
            description: item[2].colValue,
          };
          await this.props.updateNotificationFunction(
            item[0].colId,
            item[1].colValue,
            updateValues
          );
          await this.props.fetchAllFunctions();
          break;
        default:
          break;
      }

      isEditingCols[index] = false;

      this.setState({
        newTblColValues: {},
        isSaved: true,
        isEditing: isEditingCols,
      });

      this.props.processSelectedTable(selectedPip);
    } else {
      isEditingCols[index] = true;
      this.setState({ isEditing: isEditingCols });
    }
  };

  handleEditValue = (event, index, colIndex) => {
    const newVal = event.target.value ? event.target.value : "";
    this.updateEditValue(newVal, index, colIndex);
  };

  changeTblOptionHandler = (e, i, index, colIndex) => {
    this.updateEditValue(i.key, index, colIndex);
  };

  updateEditValue = (newValue, index, colIndex) => {
    let { tableMappings } = this.state;

    if (tableMappings.length === 0) {
      tableMappings = this.props.tableMappings;
    }

    tableMappings[index][colIndex].colValue = newValue;

    this.setState({ tableMappings });
  };

  verifyReadOnlyField = (fieldName) => readOnlyFields.findIndex((item) => item === fieldName) >= 0;

  verifyNumberFields = (fieldName) => numberFields.findIndex((item) => item === fieldName) >= 0;

  verifyMultilineFields = (fieldName) => multilineFields.findIndex((item) => item === fieldName) >= 0;

  dismissDeleteDataDialog = () => {
    this.setState({ showDelDialog: false });
  };

  resetDataAfterUpdate = async () => {
    const { selectedPip } = this.state;
    this.setState({ isSaved: true, showDelDialog: false });

    this.props.processSelectedTable(selectedPip);
  };

  closeDialog = () => {
    this.setState({ isSaved: false });
  };

  render() {
    const {
      showDelDialog,
      delConfirmedMappingTblValue,
      isSaved,
      isEditing,
      tableMappings,
    } = this.state;

    const { isReadOnly, selectedPip } = this.props;

    // eslint-disable-next-line global-require
    const OliLogo =
      this.props.result === "SUCCESS"
        ? // eslint-disable-next-line global-require
        require("../../../images/oli-success.svg")
        : // eslint-disable-next-line global-require
        require("../../../images/oli-fail.svg");

    return (
      <>
        {Object.keys(tableMappings).map((key) => (
          <TableRow key={key}>
            {tableMappings[key].map((cell, cellIndex) => {
              const isUpdatedField = this.verifyReadOnlyField(cell.colName);
              const isNumbericField = this.verifyNumberFields(cell.colName);
              const isMultilineField = this.verifyMultilineFields(cell.colName);
              return (
                <BodyTableCell
                  component="th"
                  scope="row"
                  align="left"
                  key={`${cell.colId}-${cellIndex}`}
                >
                  {isEditing[key] && !isUpdatedField ? (
                    <>
                      {cell.colName !== "Value" ? (
                        <>
                          {cell.colName !== "Question" ? (
                            <TextField
                              value={cell.colValue}
                              placeHolder={`enter ${cell.colName}`}
                              id={`txt-${cell.colName}`}
                              onChange={(event) => {
                                this.handleEditValue(event, key, cellIndex);
                              }}
                              multiline={isMultilineField}
                              type={isNumbericField ? "number" : null}
                              readOnly={isReadOnly}
                              styles={
                                isNumbericField
                                  ? numberFieldStyles
                                  : textFieldStyles
                              }
                            />
                          ) : (
                            <Editor
                              apiKey={APIKeys.EditorKey}
                              value={cell.colValue}
                              init={{
                                branding: false,
                                height: 250,
                                menubar: false,
                                plugins: [
                                  "wordcount advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help",
                                ],
                                paste_preprocess(plugin, args) {
                                  // replace copied text with empty string
                                  if (editorCharLeft === 0) {
                                    // eslint-disable-next-line no-param-reassign
                                    args.content = "";
                                  }
                                },
                                toolbar:
                                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | wordcount | help",
                                init_instance_callback: (editor) => {
                                  this.setState({
                                    editorCountReady: true,
                                    editorCount:
                                      editor.plugins.wordcount.body.getCharacterCount(),
                                  });
                                },
                              }}
                              onEditorChange={(content, editor) => {
                                tableMappings[key][cellIndex].colValue =
                                  content;

                                this.setState({
                                  tableMappings,
                                  editorCount:
                                    editor.plugins.wordcount.body.getCharacterCount(),
                                });
                              }}
                              onBlur={(event, editor) => {
                                this.setState({
                                  editorCount:
                                    editor.plugins.wordcount.body.getCharacterCount(),
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <Dropdown
                          id={`Value`}
                          placeholder={`Select values`}
                          label={`Select values`}
                          // eslint-disable-next-line camelcase
                          options={OPTION_VALUES}
                          required
                          selectedKey={cell.colValue}
                          styles={dropdownStyleCategory}
                          onRenderCaretDown={onRenderCaretDown}
                          onChange={(e, i) => this.changeTblOptionHandler(e, i, key, cellIndex)
                          }
                        />
                      )}
                    </>
                  ) : (
                    renderHTMLContent(cell.colValue)
                  )}
                </BodyTableCell>
              );
            })}
            {!isReadOnly ? (
              <BodyTableCell align="left">
                {selectedPip.isUpdatable ? (
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      this.editTblMapping(tableMappings[key], key);
                    }}
                    styles={{
                      root: {
                        color: "#555",
                        height: 24,
                        width: 24,
                        paddingRight: "16px",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    this.delTblMapping(tableMappings[key]);
                  }}
                  styles={{
                    root: {
                      color: "#555",
                      height: 24,
                      width: 24,
                    },
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </BodyTableCell>
            ) : null}
          </TableRow>
        ))}
        {isSaved && this.props.message ? (
          <SuccessfulMessage
            dialogState={false}
            errorTitle={this.props.result}
            errorContentArray={[this.props.message, "Click Okay to continue."]}
            hidden={!isSaved}
            closeDialog={this.closeDialog}
            cancelDialog={this.closeDialog}
            OliLogo={OliLogo}
          />
        ) : (
          ""
        )}
        <DeleteConfirmDialog
          isOpen={showDelDialog}
          onDissmissHandler={this.dismissDeleteDataDialog}
          onDeleteHandler={this.deleteTblValue}
          messageTitle={"Delete a mapping table value"}
          dataValue={delConfirmedMappingTblValue}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  message: state.ui.message,
  result: state.ui.result,
});

const connected = connect(mapStateToProps, {
  delNotificationFunctionMappings,
  delNotificationRoleMappings,
  delNotificationRecipientStrategyMappings,
  deleteNotificationFunction,
  delNotificationAssociatedEventTypeMappings,
  delChangeTypeEventTypeMapping,
  delRequiredFieldsDecisionPointTypeMapping,
  delManagedFieldsDecisionPointTypeMapping,
  delRequiredFieldsCheckpointTypeMapping,
  delPRRCriteriaCategoryMapping,
  delPRRCriteriaEntityMapping,
  delPRRCriteriaTagMapping,
  updatePRRCriteria,
  delPRRCriteria,
  updateCriteriaOption,
  updateNotificationFunction,
  delCriteriaOption,
  fetchAllChangeTypeEventTypeMappings,
  fetchAllTableMappings,
  fetchAllFunctions,
  fetchNotificationMsaterRoleMappings,
  fetchAllEventTypes,
  fetchAllRequiredFieldsDecisionPointTypeMappings,
  fetchAllManagedFields,
  fetchAllManagedFieldsDecisionPointTypeMappings,
  fetchAllRequiredFieldsCheckpointTypeMappings,
  fetchAllPRRCriteriaCategories,
  fetchAllPRRCriterias,
  fetchAllPRRCriteriaEntities,
  fetchAllPRRCriteriaTags,
  fetchNotificationMasters,
})(AuthProvider(UpdateTableMappings));

export default connected;
