import api from '../../api/IntelligentAgentServiceApi';
import {
  FETCH_ADMIN_CHANGE_MASTERS, UI_SHOW_MESSAGE_SUCCESS, UI_SHOW_MESSAGE_ERROR, FETCH_ADMIN_CHANGE_SETS, FETCH_ADMIN_CHANGE_MASTERS_FILTERS, FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS, FETCH_ADMIN_CHANGE_MASTERS_SELECTED_FILTERS
} from '../../constants/ActionTypes';

import { signOut } from '../../components/auth/AuthUtils';
import _ from 'lodash';
const queryStringData = require('query-string');

export const fetchAdminChangeMasters = () => async (dispatch) => {
  try {
    // const response = await api.get('/admin/ccr/change-items');
    const response = await api.get('/admin/ccr/change-items?sort=creationDate&direction=desc&count=250&pageNo=0');

    const m = { masters: response.data, totalRecords: response.data.totalRecords, totalInPage: response.data.totalInPage };
    // dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS, payload: response });
    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS, payload: m });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchAdminChangeMastersScrollData = (alreadyFetched, PageNo) => async (dispatch) => {
  try {

    const response = await api.get(`/admin/ccr/change-items?sort=creationDate&direction=desc&count=250&pageNo=${PageNo}`);
    const totalInPage = alreadyFetched.totalInPage !== undefined ? alreadyFetched.totalInPage + response.data.totalInPage : response.data.totalInPage;

    response.data.changeCategories.forEach((r2) => {
      if (!alreadyFetched.masters.changeCategories.find((r1) => { return r1.changeCategoryName === r2.changeCategoryName }))
        alreadyFetched.masters.changeCategories.push(r2);
    })

    response.data.changeCategories.forEach((r2) => {
      let index = alreadyFetched.masters.changeCategories.findIndex((r1) => {
        return r1.changeCategoryName === r2.changeCategoryName
      });
      if (index > -1) {
        let mergedArray = alreadyFetched.masters.changeCategories[index].keySystemResources.concat(r2.keySystemResources);
        alreadyFetched.masters.changeCategories[index].keySystemResources = _.uniqBy(mergedArray, 'title');

        r2.keySystemResources.forEach((r2ksr) => {
          alreadyFetched.masters.changeCategories[index].keySystemResources.forEach((r1ksr) => {
            if (r2ksr.title === r1ksr.title) {
              let mergedChangeitems = r1ksr.changeItems.concat(r2ksr.changeItems);
              r1ksr.changeItems = _.uniqBy(mergedChangeitems, 'id');
            }
          })
        })
      }
    })

    const m = { masters: alreadyFetched.masters, totalRecords: response.data.totalRecords, totalInPage: totalInPage };
    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS, payload: m });
    // dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchAdminChangeMastersPageRef = (alreadyFetched, pageNo) => async (dispatch) => {
  try {
    const response = await api.get(`/admin/ccr/change-items?sort=creationDate&direction=desc&count=250&pageNo=${pageNo}`);
    const results = response.data.changeCategories;
    const apiObject = {
      data: {
        changeCategories: results
      },
    };

    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS, payload: apiObject });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchAdminChangeMastersFilters = () => async (dispatch) => {
  try {

    const response = await api.get('/admin/ccr/change-items/category');

    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS_FILTERS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};


export const fetchAdminChangeMastersWithFilters = (categoryId) => async (dispatch) => {
  try {

    var queryString = `/admin/ccr/change-items?sort=creationDate&direction=desc&count=250&pageNo=0`;

    if (categoryId)
      queryString = `${queryString}&${queryStringData.stringify(categoryId)}`;

    const response = await api.get(queryString);

    const m = { masters: response.data, totalRecords: response.data.totalRecords, totalInPage: response.data.totalInPage };
    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS, payload: m });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchAdminChangeMastersWithFiltersScrollData = (alreadyFetched, categoryId, PageNo) => async (dispatch) => {
  try {

    // var queryString = `/admin/ccr/change-items?adminCcrItemStatus=PUBLISHED&verificationStatusQuery=true&sort=creationDate&direction=desc&count=250&pageNo=${PageNo}`;
    var queryString = `/admin/ccr/change-items?sort=creationDate&direction=desc&count=250&pageNo=${PageNo}`;
    if (categoryId)

      queryString = `${queryString}&${queryStringData.stringify(categoryId)}`;
    const response = await api.get(queryString);


    const totalInPage = alreadyFetched.totalInPage !== undefined ? alreadyFetched.totalInPage + response.data.totalInPage : response.data.totalInPage;

    response.data.changeCategories.forEach((r2) => {
      if (!alreadyFetched.masters.changeCategories.find((r1) => { return r1.changeCategoryName === r2.changeCategoryName }))
        alreadyFetched.masters.changeCategories.push(r2);
    })

    response.data.changeCategories.forEach((r2) => {
      let index = alreadyFetched.masters.changeCategories.findIndex((r1) => {
        return r1.changeCategoryName === r2.changeCategoryName
      });
      if (index > -1) {
        let mergedArray = alreadyFetched.masters.changeCategories[index].keySystemResources.concat(r2.keySystemResources);
        alreadyFetched.masters.changeCategories[index].keySystemResources = _.uniqBy(mergedArray, 'title');

        r2.keySystemResources.forEach((r2ksr) => {
          alreadyFetched.masters.changeCategories[index].keySystemResources.forEach((r1ksr) => {
            if (r2ksr.title === r1ksr.title) {
              let mergedChangeitems = r1ksr.changeItems.concat(r2ksr.changeItems);
              r1ksr.changeItems = _.uniqBy(mergedChangeitems, 'id');
            }
          })
        })
      }
    })

    const m = { masters: alreadyFetched.masters, totalRecords: response.data.totalRecords, totalInPage: totalInPage };
    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS_WITH_FILTERS, payload: m });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const fetchAdminChangeMastersSelectedFilters = (data) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ADMIN_CHANGE_MASTERS_SELECTED_FILTERS, payload: data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const updateChangeItemDetail = (changeItem) => async (dispatch) => {
  try {
    const apiObject = {
      changeDescription: changeItem.changeDescription,
      verified: changeItem.verified,
      verificationComment: changeItem.verificationComment
    };

    const url = `/admin/ccr/change-item/${changeItem.id}`;
    await api.put(url, apiObject);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Change Item updated successfully' });
    dispatch(fetchAdminChangeMasters());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Change Item updated failure' });
    }
  }
};

export const fetchAdminChangeSet = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/ccr/change-sets');

    dispatch({ type: FETCH_ADMIN_CHANGE_SETS, payload: response });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const addChangeSet = (changeSet) => async (dispatch) => {
  try {
    const url = `/admin/ccr/change-set`;
    await api.post(url, changeSet);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Change Item updated successfully' });
    dispatch(fetchAdminChangeMasters());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Change Item updated failure' });
    }
  }
};

export const addChangeItemToChangeSet = (changeSetId, changeItem) => async (dispatch) => {
  try {
    const url = `/admin/ccr/change-set/${changeSetId}/add-change-item`;
    await api.post(url, changeItem);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Change Item updated successfully' });
    dispatch(fetchAdminChangeMasters());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Change Item updated failure' });
    }
  }
};

export const removeChangeItemFromChangeSet = (changeSetId, changeItem) => async (dispatch) => {
  try {
    const url = `/admin/ccr/change-set/${changeSetId}/remove-change-item`;
    await api.post(url, changeItem);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Change Item updated successfully' });
    dispatch(fetchAdminChangeMasters());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Change Item updated failure' });
    }
  }
};
