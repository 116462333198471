/* eslint no-unused-vars: 0 */  // --> turn rule OFF in favour of clarity
/* eslint no-eval: 0 */  // --> turn rule OFF in favour of clarity

const local = {
  msal: {
    auth: {
      authority: 'https://login.microsoftonline.com/bc9a0103-e59d-4483-946c-e636dc4102e6',
      clientId: '6cfa8e09-cfac-45bd-b757-dc94a87321e7',
      scopes: ['openid', 'profile', 'user.read'],
      redirectUri: 'http://localhost:3000/secure',
      postLogoutRedirectUri: 'http://localhost:3000'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  requestObject: {
    loginHint: '',
    scopes: ['api://agent-api-release.oli-sit.lendleaseoli.com/.default'],
    scope: ['api://agent-api-release.oli-sit.lendleaseoli.com/.default']
    // scopes: ['openid', 'profile', 'user.read']
    // extraQueryParameters: { domain_hint: '8f1c786f-dbee-41c0-b295-a2266b3ffe27' }
  }
};

const dev = {
  msal: {
    auth: {
      authority: 'https://login.microsoftonline.com/bc9a0103-e59d-4483-946c-e636dc4102e6',
      clientId: '6cfa8e09-cfac-45bd-b757-dc94a87321e7',
      scopes: ['openid', 'profile', 'user.read'],
      redirectUri: 'https://admin-portal.dev.lendleaseoli.com/secure',
      postLogoutRedirectUri: 'https://admin-portal.dev.lendleaseoli.com/'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  requestObject: {
    loginHint: '',
    scopes: ['api://agent-api-release.oli-dev.lendleaseoli.com/.default'],
    scope: ['api://agent-api-release.oli-dev.lendleaseoli.com/.default']
    // scopes: ['openid', 'profile', 'user.read']
  }
};

const sit = {
  msal: {
    auth: {
      authority: 'https://login.microsoftonline.com/bc9a0103-e59d-4483-946c-e636dc4102e6',
      clientId: '6cfa8e09-cfac-45bd-b757-dc94a87321e7',
      scopes: ['openid', 'profile', 'user.read'],
      redirectUri: 'https://admin-portal.sit.lendleaseoli.com/secure',
      postLogoutRedirectUri: 'https://admin-portal.sit.lendleaseoli.com/'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  requestObject: {
    loginHint: '',
    scopes: ['api://agent-api-release.oli-sit.lendleaseoli.com/.default'],
    scope: ['api://agent-api-release.oli-sit.lendleaseoli.com/.default']
    // scopes: ['openid', 'profile', 'user.read']
    // extraQueryParameters: { domain_hint: '8f1c786f-dbee-41c0-b295-a2266b3ffe27' }
  }
};

const uat = {
  msal: {
    auth: {
      authority: 'https://login.microsoftonline.com/bc0c325b-6efc-4ca8-9e46-11b50fe2aab5',
      clientId: '63fccefe-f663-4c38-af1e-00f493ded29f',
      scopes: ['openid', 'profile', 'user.read'],
      redirectUri: 'https://admin-portal.uat.lendleaseoli.com/secure',
      postLogoutRedirectUri: 'https://admin-portal.uat.lendleaseoli.com/'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  requestObject: {
    loginHint: '',
    scopes: ['api://agent-api-release.oli-uat.lendleaseoli.com/.default'],
    scope: ['api://agent-api-release.oli-uat.lendleaseoli.com/.default']
    // scopes: ['openid', 'profile', 'user.read']
    // extraQueryParameters: { domain_hint: '8f1c786f-dbee-41c0-b295-a2266b3ffe27' }
  }
};

const preprod = {
  msal: {
    auth: {
      authority: 'https://login.microsoftonline.com/bc0c325b-6efc-4ca8-9e46-11b50fe2aab5',
      clientId: 'a587ab3b-b680-446d-922c-0c115c71206c',
      scopes: ['openid', 'profile', 'user.read'],
      redirectUri: 'https://admin-portal.preprod.lendleaseoli.com/secure',
      postLogoutRedirectUri: 'https://admin-portal.preprod.lendleaseoli.com/'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  requestObject: {
    loginHint: '',
    scopes: ['api://agent-api-release.oli-preprod.lendleaseoli.com/.default'],
    scope: ['api://agent-api-release.oli-preprod.lendleaseoli.com/.default']
    // scopes: ['openid', 'profile', 'user.read']
    // extraQueryParameters: { domain_hint: '8f1c786f-dbee-41c0-b295-a2266b3ffe27' }
  }
};

const prod = {
  msal: {
    auth: {
      authority: 'https://login.microsoftonline.com/bc0c325b-6efc-4ca8-9e46-11b50fe2aab5',
      clientId: 'b864456a-80ae-4643-a14c-755d5620da33',
      scopes: ['openid', 'profile', 'user.read'],
      redirectUri: 'https://admin-portal.prod.lendleaseoli.com/secure',
      postLogoutRedirectUri: 'https://admin-portal.prod.lendleaseoli.com/'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  requestObject: {
    loginHint: '',
    scopes: ['api://agent-api-release.oli.lendleaseoli.com/.default'],
    scope: ['api://agent-api-release.oli.lendleaseoli.com/.default']
    // scopes: ['openid', 'profile', 'user.read']
    // extraQueryParameters: { domain_hint: '8f1c786f-dbee-41c0-b295-a2266b3ffe27' }
  }
};

let letConfig = null;
const { hostname } = window.location;
switch (hostname) {
  case 'localhost':
    letConfig = local;
    break;
  case 'admin-portal.dev.lendleaseoli.com':
    letConfig = dev;
    break;
  case 'admin-portal.sit.lendleaseoli.com':
    letConfig = sit;
    break;
  case 'admin-portal.uat.lendleaseoli.com':
    letConfig = uat;
    break;
  case 'admin-portal.preprod.lendleaseoli.com':
    letConfig = preprod;
    break;
  case 'admin-portal.prod.lendleaseoli.com':
    letConfig = prod;
    break;
  default:
    console.error(`Cannot determine environment in window.domain: ${window.location.href}`);
    throw new Error('ERROR: Environment cannot be determined');
}

// const authConfig = eval(process.env.REACT_APP_ENV);
const authConfig = letConfig;
export default authConfig;
