import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../../components/style.css';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import {
  DefaultButton, PrimaryButton, initializeIcons,
  FontWeights, getTheme, IconButton, Modal, TextField, Text
} from '@fluentui/react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import {
  fetchPolicyLibraryMasters, addPolicySchemeDetails, getPolicyEntitlementOperationTypes, fetchPolicySchemeConfigurations, fetchPolicyConfigurationNames
} from '../../../state/actions/PolicyLibraryMasterActions';
import { referenceDataTypeSelected } from '../../../state/actions/ReferenceDataActions';
import AuthProvider from '../../../components/auth/AuthProvider';
import getReadOnlyFlagPerEnv from '../../../util/EnvUtil';
import history from '../../../history';

const isReadOnly = getReadOnlyFlagPerEnv();

const stackStylesStep2 = {
  root: {
    height: 732,
  },
};
class CreatePolicyScheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inStep1: true,
      inStep2: false,
      inConfirmation: false,
      open: false,
      policyName: '',
      policyDescription: '',
      policyType: '',
      policySchemeTypeId: this.props.policySchemeTypeId,
      policyRequirement: '',
      selectedRequirement: '',
      policyPriority: '',
      policyThreshold: '',
      pdpReference: '',
      pdpDescription: '',
      pdpTitleIsValid: '',
      pdpReferenceIsValid: '',
      pdpTitle: '',
      pdpCode: '',
      newPolicyData: null,
      policyOperationCondition: '',
      activationConditionExpression: '',
      pepRequirement: [],
      pdpActivation: [],
      policyNameIsValid: false,
      policyDescriptionIsValid: false,
      pepTitleIsValid: false,
      pepCodeIsValid: false,
      pdpCodeIsValid: false,
      pepClassIsValid: false,
    };
    initializeIcons();
  }

  async componentDidMount() {
    await this.props.referenceDataTypeSelected('PEP_REQUIRED_CLASS');
    const requirementlist = this.props.referenceDataPolicySchemeTypeId;
    await this.props.getPolicyEntitlementOperationTypes();
    await this.props.fetchPolicyConfigurationNames();

    requirementlist.forEach((item) => {
      let newItem = null;
      newItem = {
        key: item.id,
        text: item.value
      };
      this.state.pepRequirement.push(newItem);
    });

    this.props.entitlementOperationTypes.forEach((item, index) => {
      let newItem = null;
      newItem = {
        key: index,
        text: item
      };
      if (item === "ALL_TRUE") {
        this.setState({ policyOperationCondition: newItem });
      }
      this.state.pdpActivation.push(newItem);
    });

    this.setState({ show: this.props.open });
  }

  handlePEPTitle = (event) => {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pepValues.pepTitles.forEach((pep) => {
      if (pep.trim() === event.target.value || event.target.value.length === 0) {
        valid = false;
      }
    });
    this.setState({ pepTitle: event.target.value, pepTitleIsValid: valid });
  }

  handlePolicyTypeChange = (event) => {
    this.setState({ policyType: event.target.value });
  }

  handlePEPCode = (event) => {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pepValues.pepCodes.forEach((pep) => {
      if (pep.trim() === event.target.value || event.target.value.length === 0) {
        valid = false;
      }
    });
    this.setState({ pepCode: event.target.value, pepCodeIsValid: valid });
  }

  handlePEPRequiredClass = (event, item) => {
    this.setState({ policyRequirement: item, pepClassIsValid: true });
  }

  handleViewPolicyBuilder() {
    const path = `/policyLibraryMasterConsole/editPolicy/${this.props.policySchemeTypeId}`;
    this.setState({ inConfirmation: false });
    this.props.fetchPolicySchemeConfigurations(this.props.policySchemeTypeId);
    history.push(path);
  }

  handlePolicyNameChange = (e) => {
    let valid = true;
    this.props.policySchemeConfigurationsNames.schemeNames.forEach((scheme) => {
      if (scheme.trim() === e.target.value || e.target.value.length === 0) {
        valid = false;
      }
    });
    this.setState({ policyName: e.target.value, policyNameIsValid: valid });
  }

  handlePolicyDescriptionChange = (e) => {
    let valid = true;
    if (e.target.value.length === 0) {
      valid = false;
    }
    this.setState({ policyDescription: e.target.value, policyDescriptionIsValid: valid });
  }

  handlePdpTitle = (e) => {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pdpValues.pdpTitles.forEach((pdp) => {
      if (pdp.trim() === e.target.value || e.target.value.length === 0) {
        valid = false;
      }
    });
    this.setState({ pdpTitle: e.target.value, pdpTitleIsValid: valid });
  }

  handlePdpReference = (e) => {
    let valid = true;
    if (e.target.value.length === 0) {
      valid = false;
    }
    this.setState({ pdpReference: e.target.value, pdpReferenceIsValid: valid });
  }

  handlePdpCode = (e) => {
    let valid = true;
    this.props.policySchemeConfigurationsNames.pdpValues.pdpCodes.forEach((pdp) => {
      if (pdp.trim() === e.target.value || e.target.value.length === 0) {
        valid = false;
      }
    });
    this.setState({ pdpCode: e.target.value, pdpCodeIsValid: valid });
  }

  handlePdpDescription = (e) => {
    this.setState({ pdpDescription: e.target.value });
  }

  handlePolicyOperationCondition = (event, item) => {
    this.setState({ policyOperationCondition: item });
  }

  hideAddPolicySchemeHandler = () => {
    this.props.hideAddPolicySchemeHandler();
    this.setState((prevState) => ({ ...prevState, inStep1: false, inConfirmation: false }));
  }

  static createValidationStatus(fieldName, status) {
    if (status) {
      return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px' }}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} styles={{ root: { color: 'green' } }} /><span style={{ fontSize: '12px' }}>This is a valid {fieldName}.</span></div>);
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px' }}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} /><span style={{ fontSize: '12px' }}>This not yet a valid and unique {fieldName}. Update to continue.</span></div>
    );
  }

  static createValidationForPickList = (status) => {
    if (status) {
      return (<div style={{ display: 'flex', alignItems: 'center', height: '25px' }}><IconButton iconProps={{ iconName: 'SkypeCircleCheck' }} styles={{ root: { color: 'green' } }} /><span style={{ fontSize: '12px' }}>This is valid.</span></div>);
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '25px' }}><IconButton iconProps={{ iconName: 'StatusErrorFull' }} styles={{ root: { color: 'red' } }} /><span style={{ fontSize: '12px' }}>This field is required. Update to continue.</span></div>
    );
  }

  isPdpValuesValid = () => {
    let valid = true;
    if (isReadOnly) return false;
    if (!(this.state.pdpTitleIsValid && this.state.pdpReferenceIsValid && this.state.pdpCodeIsValid)) {
      valid = false;
    }
    return valid;
  }

  moveToPage2Handler = () => {
    this.setState((prevState) => ({ ...prevState, inStep1: false, inStep2: true, inConfirmation: false }));
  }

  moveToPage1Handler = () => {
    this.setState((prevState) => ({ ...prevState, inStep1: true, inStep2: false, inConfirmation: false }));
  }

  submitAddPolicyMasterRequest = async () => {
    const newPolicyData = {
      policySchemeName: this.state.policyName,
      policySchemeDescription: this.state.policyDescription,
      policySchemeType: this.state.policyType,
      policySchemeTypeId: this.props.policySchemeTypeId,
      pepCode: this.state.pepCode,
      pepTitle: this.state.pepTitle,
      pepRequiredClassId: this.state.policyRequirement.key,
      pdpCode: this.state.pdpCode,
      pdpDescription: this.state.pdpDescription,
      pdpReference: this.state.pdpReference,
      pdpTitle: this.state.pdpTitle,
      pdpActivationConditionExpression: this.state.activationConditionExpression,
      policyEntitlementOperation: this.state.policyOperationCondition.text,
    };
    await this.props.addPolicySchemeDetails(newPolicyData);
    this.setState({ inConfirmation: true, inStep1: false });
    this.setState((prevState) => ({
      ...prevState, inStep1: false, inStep2: false, inConfirmation: true
    }));

    await this.props.fetchPolicyLibraryMasters();
  };

  step1() {
    const { classes } = this.props;
    const charsLeft = (100 - this.state.policyDescription.length);
    const nextButtonDisabled = !(this.state.policyNameIsValid && this.state.policyDescriptionIsValid && this.state.pepCodeIsValid && this.state.pepTitleIsValid && this.state.pepClassIsValid);

    return (
      <Modal
        isOpen={this.state.inStep1}
        onDismiss={this.hideAddPolicySchemeHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep1}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Create New Policy - Step 1 out of 2 </span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddPolicySchemeHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack horizontal className={classes.informational}>
                <IconButton iconProps={{ iconName: 'Info' }} />
                <p>
                  Please create a unique names,descriptions and codes for the PEP's and PDP's associated with your new policy. Once finalised you can update the names and descriptions from the policy builder screen if desired.
                </p>
              </Stack>
            </Stack>
            <Stack className={classes.policyDetail}>
              <TextField label="Policy Scheme Name" value={this.state.policyName} onChange={this.handlePolicyNameChange} readOnly={isReadOnly} />
              <Stack horizontal>
                {CreatePolicyScheme.createValidationStatus('Policy Name', this.state.policyNameIsValid)}
              </Stack>
            </Stack>
            <Stack className={classes.inputArea}>
              <TextField label="Policy Description" value={this.state.policyDescription} onChange={this.handlePolicyDescriptionChange} multiline={true} style={{ height: '110px' }} maxLength={100} />
              <Text style={{ fontSize: '12px' }}>You have {charsLeft} characters left</Text>
              <Stack horizontal>
                {CreatePolicyScheme.createValidationStatus('Policy Description', this.state.policyDescriptionIsValid)}
              </Stack>
            </Stack>
            <Stack className={classes.policyDetail}>
              <TextField label="Policy Scheme Type" value={this.state.policyType} onChange={this.handlePolicyTypeChange} readOnly={isReadOnly} />
            </Stack>
            <Stack className={classes.policyDetail}>
              <TextField label="PEP Code" value={this.state.pepCode} onChange={this.handlePEPCode} readOnly={isReadOnly} />
              <Stack horizontal>
                {CreatePolicyScheme.createValidationStatus('PEP Code', this.state.pepCodeIsValid)}
              </Stack>
            </Stack>
            <Stack className={classes.policyDetail}>
              <TextField label="PEP Title" value={this.state.pepTitle} onChange={this.handlePEPTitle} readOnly={isReadOnly} />
              <Stack horizontal>
                {CreatePolicyScheme.createValidationStatus('PEP Title', this.state.pepTitleIsValid)}
              </Stack>
            </Stack>
            <Stack className={classes.policyDetail}>
              <Dropdown label="PEP Required class"
                selectedKey={this.state.policyRequirement ? this.state.policyRequirement.key : undefined}
                onChange={this.handlePEPRequiredClass}
                options={this.state.pepRequirement}
                styles={this.dropdownStyles}
              />
              <Stack>
                {CreatePolicyScheme.createValidationForPickList(this.state.pepClassIsValid)}
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl1}>
              <DefaultButton text="Cancel" onClick={this.hideAddPolicySchemeHandler} />
              <PrimaryButton text="Next" onClick={this.moveToPage2Handler} disabled={nextButtonDisabled} />
            </Stack>
          </Stack>
        </Stack>
        <Separator></Separator>
      </Modal>
    );
  }

  step2() {
    const { classes } = this.props;
    const nextButtonDisabled = !(this.isPdpValuesValid());
    const charsLeftPdpReference = (200 - this.state.pdpReference.length);
    const charsLeftPdpDescription = (200 - this.state.pdpDescription.length);

    return (
      <Modal
        isOpen={this.state.inStep2}
        onDismiss={this.hideAddPolicySchemeHandler}
        isModeless={true}
        containerClassName={classes.containerStep1}
      >
        <Stack verticalAlign="space-between" styles={stackStylesStep2}>
          <Stack>
            <div className={classes.header}>
              <span className={classes.title}>Create New Policy Scheme - Step 2 out of 2</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddPolicySchemeHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack horizontal className={classes.informational}>
                <IconButton iconProps={{ iconName: 'Info' }} />
                <p>
                  Please create a unique names,descriptions and codes for the PEP's and PDP's associated with your new policy. Once finalised you can update the names and descriptions from the policy builder screen if desired.
                </p>
              </Stack>
            </Stack>
            <Stack >
              <Stack tokens={tokens.sectionStack}>
                <Stack className={classes.accorMain}>
                  <Stack>
                    <Stack className={classes.inputArea}>
                      <p className={classes.headerPdp}>PDP 1</p>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Title" value={this.state.pdpTitle} onChange={this.handlePdpTitle} />
                      <Stack horizontal>
                        {CreatePolicyScheme.createValidationStatus('PDP Title', this.state.pdpTitleIsValid)}
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Description" value={this.state.pdpDescription} maxLength={200} multiline={true} style={{ height: '120px' }} onChange={this.handlePdpDescription} />
                      <Stack horizontal>
                        <Text style={{ fontSize: '12px' }}>You have {charsLeftPdpDescription} characters left</Text>
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Code" value={this.state.pdpCode} onChange={this.handlePdpCode} />
                      <Stack horizontal>
                        {CreatePolicyScheme.createValidationStatus('PDP Code', this.state.pdpCodeIsValid)}
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="PDP Reference" value={this.state.pdpReference} maxLength={200} multiline={true} style={{ height: '120px' }} onChange={this.handlePdpReference} />
                      <Stack horizontal>
                        <Text style={{ fontSize: '12px' }}>You have {charsLeftPdpReference} characters left</Text>
                      </Stack>
                      <Stack horizontal>
                        {CreatePolicyScheme.createValidationStatus('PDP Reference', this.state.pdpReferenceIsValid)}
                      </Stack>
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <Dropdown label="Policy Operation Condition"
                        selectedKey={this.state.policyOperationCondition?.key}
                        onChange={this.handlePolicyOperationCondition}
                        options={this.state.pdpActivation}
                        styles={this.dropdownStyles}
                      />
                    </Stack>
                    <Stack className={classes.inputArea}>
                      <TextField label="Activation Condition(Optional)" value={this.state.activationConditionExpression}
                        onChange={(e) => this.setState({ activationConditionExpression: e.target.value })} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack tokens={tokens.sectionStack}>
                <Separator />
                <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl1}>
                  <DefaultButton text="Cancel" onClick={this.hideAddPolicySchemeHandler} />
                  <DefaultButton text="Back" onClick={this.moveToPage1Handler} />
                  <PrimaryButton text="Create Policy" onClick={this.submitAddPolicyMasterRequest} disabled={nextButtonDisabled} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  confirmation() {
    const { classes } = this.props;
    const OliLogo = (this.props.result === 'SUCCESS') ? require('../../../images/oli-success.svg') : require('../../../images/oli-fail.svg');
    let message = '';

    if (this.props.result?.policySchemeId) message = 'You have successfully created a new Policy';
    else message = 'New Policy creation is failed';

    return (
      <Modal
        isOpen={this.state.inConfirmation}
        onDismiss={this.hideAddPolicySchemeHandler}
        isModeless={true}
        containerClassName={classes.containerConfirmation}
      >
        <Stack verticalAlign="space-between" styles={stackStylesConfirmation}>
          <Stack>
            <div className={classes.header}>
              <img className='errorDialogHeaderLogo' src={OliLogo} alt={'olilogo'} /><span className={classes.title}>Success</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={this.hideAddPolicySchemeHandler}
              />
            </div>
            <Stack tokens={tokens.sectionStack}>
              <Stack className={classes.confirmation}>
                <Stack style={{ paddingTop: '15px', fontFamily: 'SegoeUI' }}>
                  <span>{message}: </span>
                  <span className={classes.successmsg}>Policy Scheme - Step 1 - {this.state.policyName}</span>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={tokens.sectionStack}>
            <Separator />
            <Stack horizontal horizontalAlign="space-between" className={classes.btnCtrl}>
              <DefaultButton text="Close" onClick={this.hideAddPolicySchemeHandler} />
              <PrimaryButton text="View my new policy" onClick={() => this.handleViewPolicyBuilder()} />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.step1()}
        {this.step2()}
        {this.confirmation()}
      </>
    );
  }
}

const styles = (theme) => ({
  containerStep1: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '650px',
    height: '1000px',
    paddingBottom: '20px',
  },
  containerConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '550px',
    height: '250px',
  },
  inputArea: {
    padding: '0 25px 10px 25px',
    borderRadius: '2px',
  },
  header: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '15px 12px 14px 24px',
  },
  headerPdp: {
    fontWeight: FontWeights.semibold,
  },
  btnCtrl1: {
    padding: '0 25px',
  },
  informational: {
    margin: '0 25px 25px 25px',
    padding: '16px',
    borderRadius: '2px',
    backgroundColor: '#f4f4f4'
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  statusError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px'
  },
  statusSuccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px'
  },
  iconError: {
    color: 'red'
  },
  iconSuccess: {
    color: 'green'
  },
  statusMsg:
  {
    fontSize: '12px'
  },
  title: {
    fontSize: '20px',
    color: '#323130'
  },
  confirmation: {
    padding: '0 25px 20px 25px',
    borderRadius: '2px',
  },
  successmsg: {
    fontWeight: 'bold'
  },
  btnCtrl: {
    padding: '0 25px 5px',
  },
  dropdownStyles: {
    dropdown: {
      width: 300
    }
  },
  policyDetail: {
    padding: '10px 20px 10px 20px',
    paddingBottom: '0.25rem'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  fieldName: {
    color: '#323130',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: "Segoe UI"
  },
  fieldNameInfo: {
    color: 'red'
  }
});

const theme = getTheme();
const cancelIcon = { iconName: 'Cancel' };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '25px'
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 5,
  },
};

const stackStylesStep1 = {
  root: {
    height: 500,
  },
};

const stackStylesConfirmation = {
  root: {
    height: 250,
  },
};

const mapStateToProps = (state) => ({
  policySchemeConfigurations: state.policyLibraryBuilderData.policySchemeConfigurations,
  referenceDataPolicySchemeTypeId: state.refData.referenceDataTypeValues,
  entitlementOperationTypes: state.policyLibraryMasterData.entitlementOperationTypes.policyEntitlementOperationTypes,
  result: state.policyLibraryMasterData.newPolicySchemeResult,
  policySchemeConfigurationsNames: state.policyLibraryBuilderData.policySchemeConfigurationsNames,
  message: state.ui.message,
});

CreatePolicyScheme.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(mapStateToProps, {
  fetchPolicyLibraryMasters,
  addPolicySchemeDetails,
  referenceDataTypeSelected,
  getPolicyEntitlementOperationTypes,
  fetchPolicySchemeConfigurations,
  fetchPolicyConfigurationNames
})(AuthProvider(CreatePolicyScheme));

export default withStyles(styles)(connected);
