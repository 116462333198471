import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import "../../../components/style.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChangeSetDetails from "./ChangeSetDetails";
import checkIfFiltered from '../shared/FilterUtil';

const ChangeCategoryInfoSection = (classes, title, value) => (
  <span className={classes.changeCategoryInfoSectionContainer}>
    <span>{title}</span>
    <span className={classes.changCategoryInfoSectionValue}>{value}</span>
  </span>
);

const getChangeCategoryPublicationStatus = (changeSets) => (changeSets.some((changeSet) => changeSet.status === "OPEN") ? "Unpublished Changes" : "Published Changes");

const getLastUpdatedTimeForAllChangeSets = (changeSets) => {
  if (changeSets.length === 0) return "N/A";
  const mostRecentlyChangeSet = changeSets.reduce((a, b) => (new Date(a.lastUpdatedDate) > new Date(b.lastUpdatedDate) ? a : b));
  return new Date(mostRecentlyChangeSet.lastUpdatedDate).toLocaleDateString("en-AU", { day: "numeric", month: "short", year: "numeric" });
};

const ChangeSetManagemenChangeCategoryList = (props) => {
  const { classes, changeCategoryItem, selectedFilterOptions } = props;
  return (
    checkIfFiltered(selectedFilterOptions, 1, changeCategoryItem.changeCategoryName) && <Box m={1} className={classes.accordionBoxContainer} >
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionHeader}
        >
          <div className={classes.changeCategoryNameArea}>
            {changeCategoryItem.changeCategoryName}
          </div>
          <div className={classes.changeCategoryInfoArea}>
            {ChangeCategoryInfoSection(classes, "Last Updated", getLastUpdatedTimeForAllChangeSets(changeCategoryItem.changeSets))}
            {ChangeCategoryInfoSection(classes, "Publication Status", getChangeCategoryPublicationStatus(changeCategoryItem.changeSets))}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <ChangeSetDetails
            changeSets={changeCategoryItem.changeSets}
            selectedFilterOptions={selectedFilterOptions}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const styles = (theme) => ({
  accHeading: {
    fontSize: "18px",
    fontFamily: "SegoeUI",
    fontWeight: "bold",
  },
  expandIcon: {
    fontSize: "large !important",
    fontWeight: "normal",
  },
  accordionBoxContainer: {
    "& .Mui-expanded": {
      backgroundColor: "#f1f2f3",
    },
    margin: "0px",
    padding: "16px 16px 0 16px",
    color: "#323130",
  },
  accordionHeader: {
    flexDirection: "row-reverse",
    backgroundColor: "#f1f2f3",
    paddingLeft: "10px",
    height: "80px",
  },
  accordionDetails: {
    backgroundColor: "white",
  },
  changeCategoryNameArea: {
    fontSize: "16px",
    fontWeight: "600",
    paddingLeft: "16px",
    paddingTop: "12px",
    display: "block",
    whiteSpace: "nowrap",
  },
  changeCategoryInfoSectionContainer: {
    height: "48px",
    borderRight: "solid 1px #979797",
    padding: "0 32px",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  changCategoryInfoSectionValue: {
    fontSize: "14px",
    fontWeight: "normal",
    paddingTop: "10px",
  },
  changCategoryInfoBlankSection: {
    width: "56px",
  },
  changeCategoryInfoArea: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  publicationStatusArea: {
    borderRight: "solid 1px #979797",
    padding: "0 32px",
  },
  blankArea: {
    padding: "0 32px",
  },
  subheader: {
    fontFamily: "SegoeUI",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal",
  },
});

ChangeSetManagemenChangeCategoryList.defaultProps = {
  changeCategoryItem: { changeCategoryName: "LOA Approvals Australia" },
  selectedFilterOptions: []
};

ChangeSetManagemenChangeCategoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  changeCategoryItem: PropTypes.object,
  selectedFilterOptions: PropTypes.array.isRequired,
};

export default withStyles(styles)(ChangeSetManagemenChangeCategoryList);
