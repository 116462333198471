import React from "react";
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

const BuilderNameBanner = ({ classes, builderName }) => (
  <div className={classes.builderSection}>
    <Box display="flex">
      <Box flexGrow={1}>
        <div className={classes.builderName}>{builderName}</div>
      </Box>
    </Box>
  </div>
);

const styles = () => ({
  builderSection: {
    padding: "16px 0",
  },
  builderName: {
    fontSize: "24px",
    fontWeight: "700",
    fontFamily: "PharmaCond",
    color: "#323130",
  },
});

export default withStyles(styles)(BuilderNameBanner);
