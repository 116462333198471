import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { deleteItem, setItem, getAllSelectedItems } from '../../state/actions/SharedMasterActions';
import {
 fetchTaskMasters, updateTaskMaster, fetchTaskMastersFiltered, fetchTaskMastersSummary
} from '../../state/actions/TaskMasterActions';
import AuthProvider from '../../components/auth/AuthProvider';
// eslint-disable-next-line import/extensions
import TriggerPopup from './TriggerPopup.jsx';
import TaskMasterDetail from './TaskMasterDetail';
import history from '../../history';
import '../../components/style.css';

class TaskMasterItemCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showTaskMasterDetail: false,
      open: false,
      showTrigger: false,
      selectedTaskItemDetail: '',
      selectedCode: '',
      selectedTaskTrigger: '',
      taskConfigurations: [],
      itemsKey: '',
      accordionToggle: false
    };
  }

  componentDidMount () {
   this.setState({ taskConfigurations: [] });
  }

  handleListItemClick (selectedTask) {
    this.setState({
      showTaskMasterDetail: true,
      selectedTaskItemDetail: selectedTask
    });
  }

  handleTriggerItemClick (task) {
    this.setState({
      showTrigger: true,
      selectedTaskTrigger: task
    });
  }

  handleTriggerClose = (value) => {
    this.setState({ showTrigger: false });
  };

  handleDetailClose =() => {
    this.setState({ showTaskMasterDetail: false });
  }

  handleAccordionToggle = () => {
    const { accordionToggle } = this.state;
    if (!accordionToggle) {
      this.props.fetchDataDetail(this.props.task.id);
    }
    this.setState((prevState) => ({ ...prevState, accordionToggle: !accordionToggle }));
  }

  handleListItemClickBuilder (selectedTask) {
    this.setState((prevState) => ({ ...prevState, selectedCode: selectedTask }));
    const path = `/taskLibraryMasterConsole/taskMasterConsole/taskbuilder/${selectedTask.id}`;
    history.push(path);
  }

  getTaskStatus () {
    const { classes, task } = this.props;
    let taskStatus = "";
    if (task.resourceState === 'ARCHIVED') {
      taskStatus = <><CheckCircleIcon color="disabled" className={classes.viewTaskCompleteIcon}></CheckCircleIcon><Typography variant="subtitle1">Archived</Typography></>;
    } else if (task.resourceState === 'INACTIVE') {
      taskStatus = <><Cancel className={classes.viewTaskCompleteIcon}></Cancel><Typography variant="subtitle1">Inactive</Typography></>;
    } else {
      taskStatus = <><CheckCircleIcon color="primary" className={classes.viewTaskCompleteIcon}></CheckCircleIcon><Typography variant="subtitle1">Active</Typography></>;
    }

    return (<Typography variant="body2" gutterBottom>{taskStatus}</Typography>);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.itemsKey !== nextProps.itemsKey) {
      return { itemsKey: nextProps.itemsKey };
    }
    return null;
  }

  render () {
    const { isReadOnly } = this.props;
    const OfNullableValue = (entity) => {
      if (entity === null || entity === undefined || entity.trim() === "") {
        return "All";
      }
      return entity;
    };
    const withRestrictions = () => {
      const { dependencies } = this.props;
      const deplen = dependencies?.length;
      if (deplen > 0) {
        return deplen.restrictions.len > 0;
      }
      return false;
    };
    return (
      <>
        <Box mt={1} container="true" justify-content="space-around"
          flexWrap="wrap" width={1} >
          <>
            <Accordion defaultExpanded={false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={this.props.classes.icon}></ExpandMoreIcon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={this.props.classes.accordionHeader}
                onClick={this.handleAccordionToggle}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm container className={this.props.classes.taskheader}>
                    <Grid item xs container direction="column" spacing={4}>
                      <Grid item xs={7} className={this.props.classes.accheader} >
                        <Typography gutterBottom variant="subtitle1" className={this.props.classes.subheader}>
                          {this.props.task.code.toUpperCase()}
                        </Typography>
                        <button onClick={() => this.handleListItemClick(this.props.task)} className={this.props.classes.viewTaskLink }>
                          {this.props.task.label}
                        </button>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2} className={this.props.classes.taskDivider}>
                      <Typography variant="subtitle1" className={this.props.classes.subheader}>Master Availability</Typography>
                      <Typography variant="body2" gutterBottom>
                        {this.getTaskStatus()}
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={1}>
                      <EditIcon onClick={() => this.handleListItemClickBuilder(this.props.task)} className={this.props.classes.viewTaskLinkIcon} >
                      </EditIcon>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDetails>
                  { this.props.dependencies?.length > 0 && withRestrictions
                    ? <Card variant='outlined' className={this.props.classes.cardBackground}>
                      <CardContent>
                        <Grid container spacing={4} >
                          <Grid item xs={12} sm container className={this.props.classes.taskheader}>
                            <Grid item xs={6} >
                              <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
                                Task Location
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
                                Entity
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography gutterBottom variant="subtitle1" className={this.props.classes.heading}>
                                Region
                              </Typography>
                            </Grid>
                        </Grid>
                        </Grid>
                        <hr className={this.props.classes.sepLine} />
                        {this.props.dependencies.map((task) => task.restrictions.map((config, index) => (
                              <div key={uuid()}>
                                <Grid container spacing={4} >
                                  <Grid item xs={12} sm container className={this.props.classes.taskheader}>
                                    <Grid item xs={6}>
                                      <Typography variant="body2" gutterBottom>
                                        { index === 0 ? task.policyScheme?.description : "" }
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant="body2" gutterBottom>
                                        { index === 0 ? OfNullableValue(config.entity?.value) : "" }
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant="body2" gutterBottom>
                                        { config.region?.value }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <hr className={this.props.classes.sepLine} />
                              </div>
                          ))) }
                      </CardContent>
                    </Card>
                    :  <div className={this.props.classes.norule}>
                      <br/>
                      <Typography variant="body2" gutterBottom>
                            This Task Master has not yet been configured. Please go to the Task Builder to start adding rules to this task
                      </Typography>
                      <hr className={this.props.classes.sepLine} />
                    </div>
                  }
                  </AccordionDetails>
              </AccordionDetails>
            </Accordion>
          </>
        </Box>
        {this.state.showTrigger
          ? <TriggerPopup className={this.props.classes.popupdialog} selectedTask={this.state.selectedTaskTrigger} open={this.state.showTrigger} onClose={this.handleTriggerClose}></TriggerPopup>
          : <></>
        }
        {this.state.showTaskMasterDetail
          ? <TaskMasterDetail task={this.state.selectedTaskItemDetail} open={this.state.showTaskMasterDetail}
           onClose={this.handleDetailClose} taskProperties={this.props.task}
           isReadOnly={isReadOnly}
            ></TaskMasterDetail>
          : <></>
        }
        <hr className={this.props.classes.sepLine} />
      </>
    );
  }
}

const styles = (theme) => ({
  norule: {
    marginLeft: '20px'
  },
  cardBackground: {
    height: '100%',
    backgroundColor: 'transparent',
    width: '100%'
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  viewTaskLink: {
    border: '0',
    backgroundColor: 'transparent',
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    textAlign: 'left'
  },
  viewTaskLinkIcon: {
    border: '1',
    backgroundColor: null,
    color: 'darkgrey',
    fontSize: '14px !important',
    cursor: 'grab',
    float: 'right'
  },
  viewTaskCompleteIcon: {
    border: '0',
    backgroundColor: null,
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  viewTaskInCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#555555',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  accordionHeader: {
    backgroundColor: '#f1f2f3 !important',
    flexDirection: 'row-reverse',
  },
  subheader: {
    fontWeight: 'bold',
  },
  taskheader: {
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#323130',
  },
  accheader: {
    paddingLeft: '1rem'
  },
  heading: {
    fontWeight: 'bold',
  },
  taskDivider: {
    marginLeft: '1.5rem'
  },
  icon: {
    fontSize: '20px !important',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  popupdialog: {
    position: 'absolute',
    right: 20,
    top: 50
  }
});

TaskMasterItemCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  taskMasterData: state.taskMastersData.taskMasters.masters,
  taskMasterFilteredData: state.taskMastersData.taskMastersFiltered.masters,
  result: state.ui.result
});

const connected = connect(mapStateToProps, {
  fetchTaskMasters,
  fetchTaskMastersSummary,
  fetchTaskMastersFiltered,
  updateTaskMaster,
  deleteItem,
  setItem,
  getAllSelectedItems,
})(AuthProvider(TaskMasterItemCard));

export default withStyles(styles)(connected);
