import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import AuthProvider from '../../components/auth/AuthProvider';
import { fetchTaskLibraryMasters } from '../../state/actions/TaskMasterActions';

import '../../components/style.css';
import TaskLibraryMasterGrid from './TaskLibraryMasterGrid';
import { referenceDataTypeSelected } from '../../state/actions/ReferenceDataActions';

class TaskLibraryMasterConsole extends React.Component {
    componentDidMount () {
        this.props.fetchTaskLibraryMasters();
        document.title = 'Task Library Master Console';
        this.props.referenceDataTypeSelected('LL_ENTITY');
    }

    render () {
        return (
            <Card variant='outlined' className={this.props.classes.rootBackground}>
                <CardHeader className={this.props.classes.cardHeaderSection}
                    title={<Typography className={this.props.classes.cardHeader}>
                        Task Master Library
                    </Typography>} />
                <CardContent>
                    <Box mt={1} container="true" display="flex" flexDirection="row" justify-content="space-around"
                        flexWrap="wrap" alignContent="center" width={1} styles={{ alignItem: 'center' }}>
                        <TaskLibraryMasterGrid TaskLibraryData={this.props.refDataTypeValues.filter((item) => item.enabled === true)} />
                    </Box>
                </CardContent>
            </Card>
        );
    }
}

const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: '#f3f2f1'
    },
    root: {
        flexGrow: 1
    },
    cardHeaderSection: {
        padding: '16px 0 0 23px'
    },
    cardHeader: {
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        padding: '16px',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: { variant: 'h1' },
        title: {
            titleTypographyProps: { variant: 'h1' },
            fontSize: '12px'
        },
    },
    breadcrumb: {
        fontSize: '15px'
    }
});

TaskLibraryMasterConsole.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
        TaskLibraryData: state.taskMastersData.taskMastersLibrary,
        refDataTypeValues: state.refData.referenceDataTypeValues,
    });
const connected = connect(mapStateToProps, {
    fetchTaskLibraryMasters,
    referenceDataTypeSelected
})(AuthProvider(TaskLibraryMasterConsole));

export default withStyles(styles)(connected);
