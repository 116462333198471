import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import '../../components/style.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { deleteItem, setItem, getAllSelectedItems } from '../../state/actions/SharedMasterActions';
import FilterDialog from '../../components/shared/filterdialog';
import { fetchTaskMastersWithFilter, fetchTaskMastersFiltered, fetchTaskMastersSummary, fetchTaskMastersWithFilterAndPageRef } from '../../state/actions/TaskMasterActions';
import AuthProvider from '../../components/auth/AuthProvider';
import TaskMasterList from './TaskMasterList';
import CreateTaskMaster from './CreateTaskMaster';
import { referenceDataTypeSelected, referenceDataTypeValueSelected } from '../../state/actions/ReferenceDataActions';
import getReadOnlyFlagPerEnv from  '../../util/EnvUtil';
import LoadingSpinner from '../../components/loading/LoadingSpinner';

const isReadOnly = getReadOnlyFlagPerEnv();

class TaskMasterConsole extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      showAddTaskMaster: false,
      pageView: 'Construction',
      pageNo: 0,
      totalRecords: 0,
    };
  }

  async componentDidMount () {
    this.props.fetchTaskMastersWithFilter();
    this.props.referenceDataTypeSelected('LL_ENTITY');

    const APPLIED_FILTER_RESOURCE_STATE = [
      { id: 'ACTIVE', code: 'rs_ACTIVE', title: 'Active' },
      { id: 'INACTIVE', code: 'rs_INACTIVE', title: 'Inactive' },
    ];

    await this.props.setItem(APPLIED_FILTER_RESOURCE_STATE);

    document.title = 'Task Masters Library';
    const page = this.props.refDataTypeValues.filter((item) => item.id === this.props.referenceDataSelected);
    if (this.props.selectedListValues.length === 0) this.props.getAllSelectedItems();

    if (page.length !== 0) { this.setState({ pageView: page.map((item) => item.value) }); }
  }

  handleChange = (event) => {
    this.setState({ pageView: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (value) => {
    this.setState({ open: false });
  };

  listTaskMasters = () => {
    const taskMastersList = this.props.taskMasterFilteredData ? this.props.taskMasterFilteredData : [];

    return (
      <TaskMasterList key={uuid()} taskMastersList = {taskMastersList} isReadOnly={isReadOnly} fetchOnScroll={this.fetchOnScrollHandler}></TaskMasterList>
    );
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.taskMasterData !== nextProps.taskMasterData) {
      return { taskMasterData: nextProps.taskMasterData };
    }
    return null;
  }

  refreshTaskMaster =() => {
    this.props.fetchTaskMastersWithFilter();
    this.props.referenceDataTypeSelected('LL_ENTITY');
    document.title = 'Task Masters Library';
    const page = this.props.refDataTypeValues.filter((item) => item.id === this.props.referenceDataSelected);
    if (this.props.selectedListValues.length === 0) this.props.getAllSelectedItems();

    if (page.length !== 0) { this.setState({ pageView: page.map((item) => item.value) }); }
  }

  handleSelectedTypeToggle = (value) => () => {
    const col = this.props.selectedListValues;
    const currentIndex = this.props.selectedListValues.indexOf(value);

    if (currentIndex !== -1) {
      col.splice(currentIndex, 1);
      this.props.deleteItem(col);
    }
    this.props.fetchTaskMastersFiltered(this.props.selectedListValues);
  };

  handleAddTaskMasterShow = () => {
    this.setState({ showAddTaskMaster: true });
  };

  handleAddTaskMasterClose = () => {
    this.setState({ showAddTaskMaster: false });
    this.refreshTaskMaster();
  };

  fetchOnScrollHandler = async () => {
    const { pageNo } = this.state;
    const pg = pageNo + 1;
    if (this.props.totalRecordsData > this.props.taskMasterFilteredData.length) {
      await this.props.fetchTaskMastersWithFilterAndPageRef(this.props.taskMasterFilteredData, pg);
      this.setState((prevState) => ({ ...prevState, pageNo: pg }));
    }
  };

  render () {
    const { classes } = this.props;

    if (!this.props.taskMasterFilteredData) return <div><LoadingSpinner loadingText="Task Masters data still loading..." /></div>;
    return (
      <Box display="flex">
        <Card variant='outlined' className={this.props.classes.rootBackground}>
          <CardHeader className={this.props.classes.cardHeaderSection}
            title={
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={5} >
                  <Typography className={this.props.classes.cardHeader}>
                    Task Masters Library - {this.state.pageView}
                  </Typography>
                </Grid>
                <Grid item xs={2} >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="1">Change View</InputLabel>
                    <Select
                      labelId="1"
                      id="1"
                      value={this.state.pageView}
                      onChange={this.handleChange}
                    >
                    {this.props.refDataTypeValues.filter((item) => item.enabled === true).map((item) => (
                      <MenuItem value={item.value}>{item.value}</MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} >
                  <button className={classes.buttonSubmit} disabled={isReadOnly} onClick={() => this.handleAddTaskMasterShow()}>Add Task Master</button>
                </Grid>
              </Grid>
            }
          />

          <CardContent>
            <Box mt={1} container="true" justify-content="space-around"
              flexWrap="wrap" width={1} >
              <>
                <Grid item xs={12} sm container>
                  <Grid item xs={6} >
                    <button className={classes.buttonStyle} variant="outlined" color="primary" onClick={this.handleClickOpen}>
                      Filters
                    </button>
                    <FilterDialog className={classes.dialog} open={this.state.open} onClose={this.handleClose} source="TASK" origin="" selectedValue="" />
                  </Grid>
                </Grid>
                <hr className={this.props.classes.sepLine} />
                <Grid item xs={12} sm container>
                  {this.props.selectedListValues !== ''
                    ? <div>
                      {this.props.selectedListValues?.map((value) => (
                        <Button className={classes.filterButtonLabel} name="selectedTypeValuesBtn" >{value.title}<ClearIcon className={classes.buttonClearIcon} onClick={this.handleSelectedTypeToggle(value)} ></ClearIcon></Button>
                      ))}
                    </div>
                    : <div></div>
                  }
                </Grid>

                <div style={{ marginTop: '1rem' }}>
                  {this.listTaskMasters()}
                </div>
              </>
            </Box>
          </CardContent>
        </Card>
        {
          this.state.showAddTaskMaster ? <CreateTaskMaster isReadOnly={isReadOnly} taskMasterData= {this.props.taskMasterSummaryData} hideAddTaskMasterHandler={this.handleAddTaskMasterClose}
          /> : null
        }
      </Box>
    );
  }
}

const styles = (theme) => ({
  card: {
    backgroundColor: '#f3f2f1',
    width: 300,
    margin: 0
  },

  rootBackground: {
    height: '100%',
    backgroundColor: 'white',
    width: '100%',
    margin: '1rem',
    marginLeft: '5rem',
    marginRight: '5rem'
  },
  cardBackground: {
    height: '100%',
    backgroundColor: 'transparent',
    width: '100%'
  },
  root: {
    flexGrow: 1
  },
  cardHeaderSection: {
    padding: '16px 0 0 0'
  },
  cardHeader: {
    fontSize: '27px',
    fontFamily: 'PharmaCond',
    paddingLeft: '1rem',
    color: '#323130',
    fontWeight: '700',
    titleTypographyProps: { variant: 'h1' },
    title: {
      titleTypographyProps: { variant: 'h1' },
      fontSize: '12px'
    },

  },

  breadcrumb: {
    fontSize: '15px'
  },
  sepLine: {
    color: 'rgba(0, 0, 0, 0.11)',
    margin: '16px 0'
  },
  colHeader: {
    color: '#323130',
    fontWeight: 'bold',
    fontFamily: 'SegoeUI'
  },
  taskItemText: {
    margin: '4px 0',
    fontSize: '14px',
    fontFamily: 'SegoeUI',
    color: '#006ed2'
  },
  viewTaskLink: {
    border: '0',
    backgroundColor: 'transparent',
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab'
  },
  viewTaskLinkIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'right'
  },
  viewTaskCompleteIcon: {
    border: '0',
    backgroundColor: null,
    color: '#006ed2',
    fontSize: '14px',
    cursor: 'grab',
    float: 'left'
  },
  filterButtonLabel: {
    textTransform: 'capitalize',
    backgroundColor: '#EAEAEA',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'auto',
    marginRight: '1rem'
  },
  closeButton: {
    backgroundColor: '#fff',
    border: '0',
    color: '#605e5c',
    fontSize: '18px',
    padding: '0 16px',
    marginRight: '16px',
    cursor: 'pointer'
  },
  modal: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '65%',
    padding: '16px 32px',
    boxShadow: '0 10px 30px 0 rgba(127, 127, 127, 0.3)',
    backgroundColor: '#fff',
    zIndex: '1000',
    overflow: 'auto'
  },
  fieldRow: {
    padding: '8px 0',
  },

  fieldName: {
    fontWeight: '600',
    color: '#323130'
  },
  subtitle: {
    padding: '16px 0',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },
  popUpTitle: {
    color: '#323130',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },
  headerSection: {
    padding: '16px 0'
  },
  buttonStyle: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#fff',
    color: '#323130',
    borderRadius: '2px',
    border: 'solid 1px #8a8886',
    cursor: 'pointer'
  },

  buttonSubmit: {
    padding: '7px 19px',
    margin: '0 16px',
    backgroundColor: '#006ed2',
    color: 'white',
    fontWeight: '500',
    orderRadius: '2px',
    border: 'none',
    cursor: 'pointer',
    float: 'right'
  },

  editorDesc: {
    color: 'red'
  },

  listHeader: {
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'SegoeUI'
  },

  tmItemText: {
    padding: '0 16px',
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'SegoeUI'
  },
  tmItem: {
    color: '#006ed2',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
    margin: '16px 0'
  },
  verticalLine: {
    borderLeft: '1px solid grey',
    width: '1%',
    height: '185px',
    position: 'absolute',
    left: '70%',
    marginLeft: '5px',
    top: '370px'
  },
  cardContainer: {
    backgroundColor: 'transparent'
  },
  accordionHeader: {
    flexDirection: 'row-reverse',
    backgroundColor: '1px solid grey'
  },
  subheader: {
    fontWeight: 'bold',
  },
  taskheader: {
    fontFamily: 'SegoeUI',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#323130',
  },
  accheader: {
    paddingLeft: '1rem'
  },
  heading: {
    fontWeight: 'bold',
  },
  taskDivider: {
    marginLeft: '1.5rem'
  },
  icon: {
    fontSize: '20px',
    paddingRight: '1rem'
  },
  whiteBtn: {
    backgroundColor: '#fff',
    border: '1px',
  },
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50
  },
  popupdialog: {
    position: 'absolute',
    right: 20,
    top: 50
  },
  formControl: {
    minWidth: 120,
  },
  dropdownStyles: {
    dropdown: {
      width: 300
    }
  }
});

TaskMasterConsole.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  taskMasterSummaryData: state.taskMastersSummaryData.taskMastersSummary.masters,
  selectedListValues: state.sharedMasterData.selectedItems,
  taskMasterFilteredData: state.taskMastersData.taskMastersFiltered.masters,
  totalRecordsData: state.taskMastersData.taskMastersFiltered.totalRecords,
  result: state.ui.result,
  refDataTypeValues: state.refData.referenceDataTypeValues,
  referenceDataSelected: state.refData.referenceDataTypesSelectedValues
});

const connected = connect(mapStateToProps, {
  fetchTaskMastersWithFilter,
  fetchTaskMastersSummary,
  fetchTaskMastersFiltered,
  deleteItem,
  setItem,
  getAllSelectedItems,
  referenceDataTypeSelected,
  referenceDataTypeValueSelected,
  fetchTaskMastersWithFilterAndPageRef
})(AuthProvider(TaskMasterConsole));

export default withStyles(styles)(connected);
