import { FETCH_TASK_BUILDER_MASTERS_FAILED, FETCH_TASK_BUILDER_MASTERS } from '../../constants/ActionTypes';

const INITIAL_STATE = {
    entitlementRules: [],
    policyScheme: {},
    tasks: {},
    error: null,
    isloading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TASK_BUILDER_MASTERS:
            return {
                ...state, tasks: action.payload.data, entitlementRules: action.payload.data.taskMasterPolicyConfigurations.entitlementRules, policyScheme: action.payload.data.taskMasterPolicyConfigurations.policyScheme, isloading: false
            };
        case FETCH_TASK_BUILDER_MASTERS_FAILED:
            return { ...state, isloading: true };
        default:
            return state;
    }
};
