import _ from 'lodash';
import { FETCH_PDP, FETCH_PDPS } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  pdps: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PDP:
      return {
 ...state,
pdps: { ...state.pdps, [action.payload.id]: action.payload },
        error: null
};
    case FETCH_PDPS:
      return {
 ...state,
pdps: { ..._.mapKeys(action.payload, 'id') },
        error: null
};
    default:
      return state;
  }
};
