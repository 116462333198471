import api from '../../api/IntelligentAgentServiceApi';
import {
  PROJECT_SUMMARIES,
} from '../../constants/ActionTypes';
import { signOut }  from '../../components/auth/AuthUtils';

// eslint-disable-next-line import/prefer-default-export
export const fetchProjectSummaries = () => async (dispatch) => {
  try {
      const url = '/admin/project-summaries';
      const response = await api.get(url);
      dispatch({ type: PROJECT_SUMMARIES, payload: response.data });
  } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(signOut());
      }
  }
};
