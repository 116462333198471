import api from '../../api/IntelligentAgentServiceApi';
import {
  ELIGIBLE_NOTIFICATION_MASTERS,
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

// eslint-disable-next-line import/prefer-default-export
export const fetchEligibleNotificationMasterByDecisionPoint = (params) => async (dispatch) => {
  try {
    let url = '/admin/eligible-notification-masters';
    url = `${url}?eventType=${params.eventType
       }&eventSubmitterId=${params.eventSubmitterId
       }&projectId=${params.projectId
       }&decisionPointType=${params.decisionPointType
       }&decisionPointApprovalStatus=${params.decisionPointApprovalStatus
       }&decisionPointStatus=${params.decisionPointStatus
       }&decisionPointPreviousStatus=${params.decisionPointPreviousStatus
       }&checkpointApprovalStatus=${params.checkpointApprovalStatus}`;
    // let url = '/admin/eligible-notification-masters';
    const response = await api.get(url);
    dispatch({ type: ELIGIBLE_NOTIFICATION_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};
