import {
 FETCH_POLICY_BUILDER_MASTERS_FAILED, FETCH_POLICY_BUILDER_MASTERS, FETCH_POLICY_SCHEME_CONFIGURATIONS, SET_POLICY_SCHEME_CONFIGURATIONS, FETCH_POLICY_SCHEME_CONFIGURATIONS_NAMES, POLICY_SCHEME_SELECTION_SCOPE_RESOURCE_TYPE_MAPPINGS, KEY_SYSTEM_RESOURCE_POLICY_DEFINITION
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
    entitlementRules: [],
    policyScheme: {},
    error: null,
    isloading: false,
    policySchemeConfigurationsFiltered: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_POLICY_BUILDER_MASTERS:
            return {
                ...state, 
                entitlementRules: action.payload.data.entitlementRules,
                policyScheme: action.payload.data.policyScheme,
                linkedPolicyLibraries: action.payload.data.linkedPolicyLibraries,
                linkedResources: action.payload.data.linkedResources,
                isloading: false
            };
        case FETCH_POLICY_BUILDER_MASTERS_FAILED:
            return {...state, isloading: true};
        case FETCH_POLICY_SCHEME_CONFIGURATIONS:
            return {
                ...state,
                policySchemeConfigurations: action.payload.data.policySchemeConfigurations,
            };
        case SET_POLICY_SCHEME_CONFIGURATIONS:
            return {...state, policySchemeConfigurationsFiltered: action.payload};
        case FETCH_POLICY_SCHEME_CONFIGURATIONS_NAMES:
            return {...state, policySchemeConfigurationsNames: action.payload};
        case POLICY_SCHEME_SELECTION_SCOPE_RESOURCE_TYPE_MAPPINGS:
            return {...state, policySchemeSelectionScopeMappings: action.payload};
        case KEY_SYSTEM_RESOURCE_POLICY_DEFINITION:
            return {...state, keySystemResourcePolicyDefinitionMappings: action.payload};
        default:
            return state;
    }
};
