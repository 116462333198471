import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumb.css";
const Breadcrumbs = ({ crumbs }) => { 
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <div className="Admin-Breadcrumb">      
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <Link className="link-s" key={`bc1-${key}`} to={path}>
            {name} 
          </Link>
        ) : (
          <Link className="link-s" key={`bc2-${key+1}`} to={path}>
            {name} {'>'} 
          </Link>
        )
      )}
    </div>
  );
};
export default Breadcrumbs;