import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Icon } from '@fluentui/react/lib/Icon';
import { initializeIcons } from '@fluentui/react';

import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

import { fetchPolicyBuilderMasters } from '../../state/actions/PolicyLibraryMasterActions';
import AuthProvider from '../../components/auth/AuthProvider';

import PolicyLibraryPDP from './PolicyLibraryPDP';
import '../../components/style.css';

class PolicyLibraryScheme extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            toggleIcon: 'ChevronDownMed',
        };
        initializeIcons();
        document.title = 'Policy Scheme';
    }

    togglePanel (pref, id) {
        const _id = `${pref}-${id}`;
        var doc = document.getElementById(_id);
        var panel = doc.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }

        this.setState({ toggleIcon: this.state.toggleIcon === 'ChevronDownMed' ? 'ChevronUpMed' : 'ChevronDownMed' });
    }

    componentDidMount () {
    }

    render () {
        const { classes } = this.props;

        return (
            this.props.schemes.map((item, idx) => {
            const key1 = item.policyScheme.id;
            const pdps = [];
            if (item.entitlementRules) {
                for (const [index, value] of item.entitlementRules.entries()) {
                    pdps.push(<PolicyLibraryPDP key={`schemehead-${key1}-${index}`} entitlementRule={value} />)
                }
            }

            return (<div key={`sch-${key1}`}>
                <div className={classes.policySchemeHeader} id={`schemehead-${key1}`} onClick={() => this.togglePanel('schemehead', key1)}>
                    <div>
                        <div className={classes.policySchemeStep}>
                            {item.policyScheme.name}
                        </div>
                        <div className={classes.lastUpdated}>
                            Last Updated: 19 Aug 2020
                        </div>
                    </div>
                    <div>
                        <Icon iconName={this.state.toggleIcon} className={classes.iconStyle} />
                    </div>
                </div>
                <div className={classes.policySchemeBody}>
                    {/* <div className={classes.segmentsStats}>28 Segments</div> */}
                    {/* <div className={classes.segmentsStats}>56 Decisions</div> */}
                    <div className={classes.segmentsStats}>{item.entitlementRules.length} Rules</div>
                    <div className={classes.segmentsStats}><>{item.policyScheme.description}</></div>
                </div>
                {pdps}
            </div>);
            })
        );
    }
}

const styles = (theme) => ({
    cardHeader: {
        fontFamily:'PharmaCond',
        fontSize: '24px',
        color:'#323130',
        fontWeight: '600',
    },

    cardHeaderSection: {
        padding: '16px 0 0 16px',
        //    backgroundColor: '#006ed2'
    },

    card: {
        borderColor: blue[200],
        width: 200,
        height: 320,
        color: '#000',
        margin: '8px 16px'
    },

    policySchemeHeader: {
        width: '420px',
        height: '90px',
        margin: '0 40px 0 0',
        padding: '16px 24px',
        borderRadius: '2px',
        backgroundColor: '#0078d4',
        display: 'grid',
        // width: '100%',
        gridTemplateColumns: '9fr 1fr',
    },

    policySchemeBody: {
        width: '418px',
        height: '160px',
        margin: '0 0 16px',
        padding: '16px 24px',
        borderRadius: '2px',
        backgroundColor: '#fffff',
        border: 'solid 1px #a19f9d',
    },

    policySchemeStep: {
        width: '360px',
        height: '56px',
        margin: '0 39px 8px 0',
        fontFamily: 'SegoeUI',
        fontSize: '20px',
        fontWeight: '400',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.4',
        letterSpacing: 'normal',
        color: '#ffffff',
    },

    lastUpdated: {
        width: '192px',
        height: '16px',
        fontFamily: 'SegoeUI',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: 'normal',
        color: '#ffffff',
    },

    segmentsStats: {
        width: '412px',
        height: '20px',
        margin: '0 342px 8px 0',
        fontFamily: 'SegoeUI',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#000000',
    },

    iconStyle: {
        width: '24px',
        height: '24px',
        // margin: '24px 0 16px 39px',
        fontSize: '24.2px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
    },



});

PolicyLibraryScheme.propTypes = {
    classes: PropTypes.object.isRequired,
};

const connected = connect(null, {
    fetchPolicyBuilderMasters,
})(AuthProvider(PolicyLibraryScheme));

export default withStyles(styles)(connected);

