/* eslint-disable arrow-body-style */
/* eslint-disable class-methods-use-this */
import React from 'react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Icon } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton } from "@fluentui/react/lib/Button";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import { RulePickerActionTypeLiteral } from '../../../constants/ActionTypeObj';
import AuthProvider from '../../auth/AuthProvider';
import getReadOnlyFlagPerEnv from  '../../../util/EnvUtil';

import './RuleConfigValue.css';

const isReadOnly = getReadOnlyFlagPerEnv();

const themedSmallStackTokens = {
    childrenGap: 's1',
    padding: 's1',
  };

class RuleConfigMultiValue extends React.Component {
    constructor (props) {
        super(props);
        const literalType = RulePickerActionTypeLiteral.filter((value) => value.type === this.props.literalType)[0];
        this.state = {
            literalType,
            withError: false,
            ruleConfigValues: this.props.ruleConfigValues,
            searchTaskMaster: '',
            searchResultsForDisplay: null,
        };
    }

    componentDidMount () {
        document.title = 'Rule Configuration Value';
    }

    prepareFormContent () {
        const { xxruleVariables, mode } = this.props;
        const ruleVar = xxruleVariables && xxruleVariables.length > 0 ? xxruleVariables[0] : null;

        if (mode === 'read') {
            if (ruleVar) {
                return this.adaptToViewPolicyValue(ruleVar);
            }
            return <div style={{ color: 'red' }}>There is no Rule Configuration Value defined for this rule variable.</div>;
        }
        if (ruleVar) {
            return this.adoptRuleVariableAsMultiSetOptionWithSearch(ruleVar);
        }
        return <div style={{ color: 'red' }}>There is no Rule Configuration Value defined for this rule variable.</div>;
    }

    adaptToViewPolicyValue (ruleVar) {
        const { classes } = this.props;
        const options = [];

        if (ruleVar.variableSource === 'REFERENCE_DATA') {
            const refds = this.props.refdata.filter((node) => node.type === ruleVar.referenceDataType && node.enabled);
            refds.sort((a, b) => a.sortOrder - b.sortOrder);
            const isChecked = (id) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(id) : false);

            refds.forEach((ref) => {
                if (isChecked(ref.id)) {
                    options.push(ref.value);
                }
            });
        } else if (ruleVar.variableSource === 'TASK_MASTER') {
            if (this.props.taskMasterByCodesResult) {
                const sortedOptions = this.props.taskMasterByCodesROResult?.masters.sort((a, b) => (a.code).localeCompare(b.code));
                const filteredOptions = sortedOptions?.filter((val) => val.resourceState !== 'ARCHIVED');
                const values = this.props.ruleConfigValues.split(',').sort();
                const isTaskSelected = (id) => (values ? values.includes(id) : false);
                if (filteredOptions) {
                    filteredOptions.forEach((ref) => {
                        if (isTaskSelected(ref.code)) {
                            options.push(`${ref.code} - ${ref.label}`);
                        }
                    });
                }
            }
        } else if (ruleVar.variableSource === 'RISK_RATING_CRITERIA') {
            const refds = ruleVar.values.sort((a, b) => (a).localeCompare(b));
            const values = this.props.ruleConfigValues.split(',').sort();
            const isTaskSelected = (id) => (values ? values.includes(id) : false);

            refds.forEach((ref) => {
                if (isTaskSelected(ref)) {
                    options.push(`${ref}`);
                }
            });
        } else if (ruleVar.variableType === 'SINGLE_VALUE_NUMBER') {
            const n = parseFloat(this.props.ruleConfigValues).toFixed(5);
            const dspval = Number(n).toLocaleString('en');
            options.push(dspval);
        } else {
            const values = this.props.ruleConfigValues.split(',').sort();
            values.forEach((ref) => {
                options.push(this.formatText(ref));
            });
        }

        if (this.ruleVarWithEmptyValue(options)) {
            options[0] = this.prepareEmptyValueForDisplayOnly();
        }

        return (
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item>
                        {options.map((val, _index) => (
                            <div>
                                <button className={classes.whiteBtn} disabled={isReadOnly}><FiberManualRecordRoundedIcon className={classes.addTaskLinkIcon}></FiberManualRecordRoundedIcon>{val}</button>
                            </div>
                        ))}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        );
    }

    formatText (value) {
        if (value === undefined) {
            return '';
        }
        return value.replaceAll('_', ' ');
    }

    prepareEmptyValueForDisplayOnly () {
        return '[NONE]';
    }

    ruleVarWithEmptyValue (options) {
        if (options?.length && options?.length === 1 && (options[0] === '' || options[0] === 'NONE')) {
            return true;
        }
        return options.length === 0;
    }

    onChangeMultiSet = async (id) => {
        const exists = this.state.ruleConfigValues ? this.state.ruleConfigValues.includes(id) : false;
        let newVal = [];
        if (exists) {
            newVal = this.state.ruleConfigValues.filter((curVal) => curVal !== id);
        } else if (this.state.ruleConfigValues) {
            newVal = [...this.state.ruleConfigValues, id];
        } else {
            newVal = [id];
        }
        await this.props.updateForRuleConfigValues(newVal);
        this.setState((prevState) => ({ ...prevState, ruleConfigValues: newVal, withError: false }));
    }

    adoptRuleVariableAsMultiSetOption (ruleVar) {
        if (ruleVar.variableSource === 'REFERENCE_DATA') {
            const options = this.props.refdata.filter((node) => node.type === ruleVar.referenceDataType && node.enabled);
            options.sort((a, b) => a.sortOrder - b.sortOrder);
            const isChecked = (id) => (this.state.ruleConfigValues ? this.state.ruleConfigValues.includes(id) : false);

            const itemList = options.map((val, _index) => {
                return <div><Checkbox key={val.id} disabled={isReadOnly} label={val.value} onChange={() => this.onChangeMultiSet(val.id)} checked={isChecked(val.id)} /></div>;
              });
            return (
                <Stack tokens={themedSmallStackTokens}>
                    {itemList}
                </Stack>
            );
        }

        if (ruleVar.variableSource === 'TASK_MASTER') {
            const sortedOptions = this.props.taskMasterDataRef.masters.sort((a, b) => (a.code).localeCompare(b.code));
            const filteredOptions = sortedOptions.filter((val) => val.resourceState !== 'ARCHIVED');
            // const values = this.props.ruleConfigValues.split(',').sort();
            const isTaskSelected = (code) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(code) : false);

            const itemList = filteredOptions.map((val, _index) => {
                return <div><Checkbox key={val.code} disabled={isReadOnly} label={`${val.code} - ${val.label}`} onChange={() => this.onChangeMultiSet(val.code)} checked={isTaskSelected(val.code)} /></div>;
              });

            return (
                <Stack tokens={themedSmallStackTokens} style={{ maxHeight: 300, overflow: 'auto' }}>
                    {itemList}
                </Stack>
            );
        }

        if (ruleVar.variableSource === 'RISK_RATING_CRITERIA' || ruleVar.variableSource === 'GUIDANCE_FRAGMENT') {
            const options = ruleVar.values.sort((a, b) => (a).localeCompare(b));
            const isTaskSelected = (code) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(code) : false);

            const itemList = options.map((val, _index) => {
                return <div><Checkbox key={val} label={`${val}`} disabled={isReadOnly} onChange={() => this.onChangeMultiSet(val)} checked={isTaskSelected(val)} /></div>;
              });
            return (
                <Stack tokens={themedSmallStackTokens} style={{ maxHeight: 300, width: 400, overflow: 'auto' }}>
                    {itemList}
                </Stack>
            );
        }
        return null;
    }

    createConfigValueOfTypeMultiSet (ruleVar) {
        const { mode } = this.props;

        switch (mode) {
            case 'view':
                return <div>{this.props.ruleConfigValues}</div>;
            case 'add':
                return this.adoptRuleVariableAsMultiSetOption(ruleVar);
            case 'edit':
                return this.adoptRuleVariableAsMultiSetOption(ruleVar);
            default:
                return <div>MULTI_VALUE_SET</div>;
        }
    }

    onChangeSearchTaskMaster = async (event) => {
        // eslint-disable-next-line no-console
        const value = [event.target.value];
        this.setState((prevState) => ({ ...prevState, searchTaskMaster: value }));
        if (value && value.toString().trim() === "") {
            this.setState((prevState) => ({ ...prevState, searchTaskMaster: value, searchResultsForDisplay: null }));
        }
    }

    onClickSearchTaskMaster = async () => {
        const { searchTaskMaster } = this.state;
        await this.props.fetchTaskMastersBySearchStr(searchTaskMaster);
        this.setState((prevState) => ({ ...prevState, searchResultsForDisplay: this.props.taskMasterBySearchStrResult }));
      }

    adoptRuleVariableAsMultiSetOptionWithSearch (ruleVar) {
        const { classes } = this.props;
        const { searchTaskMaster, searchResultsForDisplay } = this.state;
        const sortedOptions = this.props.taskMasterByCodesResult?.masters.sort((a, b) => (a.code).localeCompare(b.code));
        const filteredOptions = sortedOptions.filter((val) => val.resourceState !== 'ARCHIVED');
        // const values = this.props.ruleConfigValues.split(',').sort();
        const isTaskSelected = (code) => (this.props.ruleConfigValues ? this.props.ruleConfigValues.includes(code) : false);

        const currentValues = filteredOptions.filter((val) => {
            return isTaskSelected(val.code);
        });

        let currentValuesForDisplay = <Stack.Item grow className={classes.stackItemInfo}>
            <div className={classes.compositeTextEmptyRule}>Use the search bar below to find task masters to add to this rule</div>
        </Stack.Item>;
        if (currentValues && currentValues.length > 0) {
            currentValuesForDisplay = currentValues.map((val, _index) => {
                return <Stack.Item grow className={classes.stackItemInfo}>
                    <Stack horizontal tokens={innerStackTokens}>
                        <Stack.Item grow className={classes.stackItemInfo}>
                            <div>{`${val.code} - ${val.label}`}</div>
                        </Stack.Item>
                        <Stack.Item grow disableShrink className={classes.stackItemDelete}>
                            <button className={classes.whiteBtn} disabled={isReadOnly} onClick={() => this.onChangeMultiSet(val.code)} ><Icon iconName="Delete" className={classes.iconStyle} /></button>
                        </Stack.Item>
                    </Stack>
                    {/* <div className={classes.compositeTextStyle2}>{this.state.selRule.ruleDescription}</div> */}
                </Stack.Item>;
            });
        }

        let itemList = "";
        if (searchTaskMaster && searchTaskMaster.toString().trim() !== "" && searchResultsForDisplay) {
            const optionsForDisplay = searchResultsForDisplay?.masters.filter((val) => val.resourceState !== 'ARCHIVED');
            if (optionsForDisplay && optionsForDisplay.length > 0) {
                const first10 = optionsForDisplay.slice(0, 10);
                const result = first10.map((val, _index) => {
                    return <div><Checkbox key={val.code} disabled={isReadOnly} label={`${val.code} - ${val.label}`} onChange={() => this.onChangeMultiSet(val.code)} checked={isTaskSelected(val.code)} /></div>;
                });
                itemList = <>
                    <Stack>
                        <div className={classes.sectionTitleInstruct}>Displaying top 10 results. Refine your search to display further results</div>
                    </Stack>
                    <Stack tokens={themedSmallStackTokens} style={{ maxHeight: 300, overflow: 'auto' }}>
                        {result}
                    </Stack>
                </>;
            }
        }

        return (
            <Stack>
                <Stack>
                    <Stack>
                        <div className={classes.sectionTitle}>CURRENT VALUES</div>
                        <div className={classes.compositeTextStyle2}>Is the Task Master Code in the set</div>
                    </Stack>
                    {currentValuesForDisplay}
                </Stack>
                <Separator></Separator>
                <Stack>
                    <Stack>
                        <div className={classes.sectionTitle}>SEARCH TASK MASTER</div>
                        <div className={classes.sectionTitleInstruct}>Type a task master name or code to see available options</div>
                    </Stack>
                    <Stack>
                        <div className={classes.searchBarContainer}>
                            <TextField placeholder="Please enter text here" className={classes.sectionSearchBox} onChange={this.onChangeSearchTaskMaster}/>
                            <DefaultButton
                                iconProps={{ iconName: "Search" }}
                                className="searchBtn"
                                text="Search"
                                onClick={this.onClickSearchTaskMaster}
                            />
                        </div>
                    </Stack>
                    {itemList}
                </Stack>
            </Stack>
        );
    }

    render () {
        const { classes } = this.props;

        return (
            <>
                {this.props.mode === 'read'
                ? this.prepareFormContent()
                : <Stack horizontal tokens={innerStackTokens}>
                    {this.prepareFormContent()}
                </Stack>
                }

                {this.state.withError
                ? <Stack className={classes.previewPart}>
                    <Stack>
                        <div className={classes.withError}>{this.props.modifyRuleResult.message}</div>
                    </Stack>
                </Stack>
                : null }
            </>
        );
    }
}

const innerStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: '#f3f2f1'
    },
    root: {
        flexGrow: 1
    },
    title: {
        padding: '60px 0 60px 0',
        color: '#605e5c',
        backgroundColor: '#fff',
    },
    instruction: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '16px 0 0 0'
    },
    catStyle: {
        padding: '0 0 0 0'
    },
    basicStyles: {
        maxWidth: '600',
        border: '0px solid  #dcdcdc',
        margin: '0'
    },
    tagPickerClass: {
        inlineSize: '450px',
        overflowWrap: 'break-word',
    },
    compositeTextStyle: {
        fontSize: '16px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '15px 0 0 0'
    },
    compositeTextStyle2: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
    },
    compositeTextEmptyRule: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        padding: '15px 0 0 0'
    },
    sectionTitle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '20px 0 20px 0',
    },
    sectionTitleInstruct: {
        fontSize: '12px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '0px 0 20px 0',
    },
    sectionSearchBox: {
        padding: '0px 0 20px 0',
        flexBasis: "75%",
    },
    selectStyle: {
        fontSize: '14px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        padding: '0 0 5px 0',
    },
    actions: {
        padding: '20px 0 20px 0'
    },
    selectedRuleBlock: {
        padding: '20px 0 0 0'
    },
    previewPart: {
        backgroundColor: '#f6f6f6'
    },
    stackItemInfo: {
        alignItems: 'center',
        display: 'block',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    stackItemDelete: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        maxWidth: 80,
        minWidth: 80,
        borderLeft: '2px solid lightgrey',
    },
    card: {
        padding: '15px', /* JUST TO LOOK COOL */
        border: '1px solid #eee',  /* JUST TO LOOK COOL */
        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
        transition: 'all .3s ease-in-out',
    },
    withError: {
        color: 'red',
    },
    button: {
        fontWeight: '400',
    },
    whiteBtn: {
        backgroundColor: 'transparent',
        border: '0px',
        padding: '2px 0 2px 0',
        color: '#323130',
        paddingRight: '1rem'
    },
    addTaskLinkIcon: {
        border: '0',
        backgroundColor: null,
        color: 'black',
        fontSize: '8px',
        cursor: 'grab',
        float: 'left',
        marginRight: '1rem'
    },
    scwrapper: {
        height: '40vh',
        position: 'relative',
    },
    searchBarContainer: {
        display: "flex",
    },
    searchBtn: {
        flexBasis: "25%",
    },
});

RuleConfigMultiValue.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

const connected = connect(mapStateToProps, null)(AuthProvider(RuleConfigMultiValue));

export default withStyles(styles)(connected);
