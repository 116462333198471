/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// eslint-disable-next-line import/no-duplicates
import { Box, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import "../../../components/style.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line import/no-duplicates
import { createMuiTheme } from "@material-ui/core";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack, StackItem } from "@fluentui/react/lib/Stack";
import {
  DefaultButton,
  PrimaryButton,
  initializeIcons,
  IconButton,
} from "@fluentui/react";

import {
  deletePipValues,
  addPipAttributes,
  fetchAllPIPs,
} from "../../../state/actions/PIPActions";
import { fetchAllTableMappings } from "../../../state/actions/MappingTablesActions";
import {
  fetchReferenceData,
  fetchReferenceDataTypes,
  referenceDataTypeSelected,
} from "../../../state/actions/ReferenceDataActions";
// eslint-disable-next-line import/no-useless-path-segments
import "./../reference.css";
import AuthProvider from "../../../components/auth/AuthProvider";
import PiPDetails from "../policyInfoPointMgm/PiPDetails";
import SuccessfulMessage from "../../../components/Dialog/SuccessfullMessage";
import DeleteConfirmDialog from "../../../components/Dialog/DeleteConfirmDialog";
import FilterOptionTypeLiteral from "../../../constants/FilterLiterals";
import Filterv2 from "../../../components/filterv2/Filterv2";
import envReadOnlyFlagContext from "../../../context/adminContext";

let isReadOnly = false;

const queryString = require("query-string");

const dropdownStyles = { dropdown: { width: 250 } };

const onRenderCaretDown = () => <Icon iconName="ChevronDownMed" />;

const stackTokens = {
  childrenGap: 5,
  padding: 14,
};

class PipTableMappingBuilder extends React.Component {
  static contextType = envReadOnlyFlagContext;

  // eslint-disable-next-line space-before-function-paren
  constructor(props) {
    super(props);

    this.state = {
      filterLiterals: "",
      filterSelections: [],
      filterOptionsKey: uuid(),
      selectedIndex: 0,
      selectedPipId: "",
      hideDialog: true,
      disableAddValueButton: true,
      pipsCategories: [],
      selectedPip: null,
      showAddValueSection: false,
      isTableDetailsPanelOpen: false,
      pipAttrNameReferences: [],
      pipAttrValuePosition: [],
      selectedDropdownValues: [],
      reducedPips: [],
      maxDisplayOrderKey: 0,
      newPipAttrValues: [],
      isSaved: false,
      showDelDialog: false,
      displayOrderRow: 0,
      delAttrValue: "",
      categories: [],
    };
    initializeIcons();
  }

  // eslint-disable-next-line space-before-function-paren
  async componentDidMount() {
    document.title = "Table Mappings Builder";
    const queryParams = queryString.parse(this.props.location.search);
    const { selectedPipId } = queryParams;

    const filterLiterals = FilterOptionTypeLiteral.filter(
      (value) => value.filterType === "PIP_MAPPINGS"
    )[0];

    await this.props.fetchReferenceData();
    await this.props.fetchReferenceDataTypes();
    this.getPipList(selectedPipId);
    this.setState({ filterLiterals });
  }

  getPipList = async (selectedPipId) => {
    await this.props.fetchAllPIPs();

    const selectedPip = this.props.pips.filter(
      (item) => item.id === selectedPipId
    );
    const selectedIndex = this.props.pips.findIndex(
      (item) => item.id === selectedPipId
    );
    const categories = this.getReferenceValueForCategory();

    this.setState({
      selectedPip: selectedPip ? selectedPip[0] : this.props.pips[0],
      selectedIndex,
      categories,
    });

    if (selectedPip && selectedPip.length > 0) {
      this.processPiPAttibuteNameReferenceData(selectedPip[0]);

      this.processPiPAttibutePositions(selectedPip[0]);
    }
  };

  processPiPAttibuteNameReferenceData = (selectedPip) => {
    if (!selectedPip) return;

    const pipAttrNameReferences = [];
    const selectedDropdownValues = [];

    selectedPip.policyInformationPointDefinitionAttributes.forEach((attr) => {
      const list = [];
      const selectedRefValues = this.props.refdata.filter(
        (ref) => ref.type.toUpperCase() === attr.code.toUpperCase()
      );

      selectedRefValues.forEach((item) => {
        list.push({
          key: item.id,
          text: item.value,
        });
      });
      pipAttrNameReferences.push(list);
    });

    selectedPip.policyInformationPointDefinitionAttributes.forEach((attr) => {
      selectedDropdownValues.push({ key: attr.id, value: "" });
    });

    this.setState({ pipAttrNameReferences, selectedDropdownValues });
  };

  processPiPAttibutePositions = (selectedPip) => {
    if (!selectedPip) return;

    const pipAttrValuePosition = [];

    const reducedPips = this.reducePips(this.props.pips, selectedPip);

    const displayOrderKeys = Object.keys(reducedPips);
    const displayOrderKeysLength = displayOrderKeys.length;

    if (displayOrderKeys.length === 0) {
      this.setState({
        reducedPips,
        maxDisplayOrderKey: 0,
      });
      return;
    }

    reducedPips[1].forEach((cell) => {
      const selectedPipAttr = this.props.refdata.filter(
        (ref) => ref.id === cell.attributeValue
      );
      const list = [];

      selectedPipAttr.forEach((item) => {
        list.push({
          key: item.type,
          position: cell.position,
        });
      });

      pipAttrValuePosition.push(list);
    });

    this.setState({
      pipAttrValuePosition,
      reducedPips,
      maxDisplayOrderKey: Number(displayOrderKeys[displayOrderKeysLength - 1]),
    });
  };

  getReferenceValueForAttrValue = (attrValue) => {
    const filterRef = this.props.refdata.filter((ref) => ref.id === attrValue);

    if (filterRef && filterRef.length > 0) return filterRef[0].value;

    return "";
  };

  getReferenceValueForCategory = () => {
    const categories = [];
    const filterRef = this.props.refdata.filter(
      (ref) => ref.type.toUpperCase() === "Admin_Mapping_Tables".toUpperCase()
    );

    if (filterRef && filterRef.length > 0) {
      filterRef.forEach((ref) => {
        categories.push({ text: ref.value.replaceAll("_", " "), key: ref.id });
      });

      return categories;
    }

    return [];
  };

  getRefDatasByType = (type) => this.props.refdata.filter(
      (ref) => ref.type.toUpperCase() === type.toUpperCase()
    );

  /* Filter */

  applyFilterSelectionsHandler = (filterSelections) => {
    this.setState({ filterSelections });
  };

  // eslint-disable-next-line space-before-function-paren
  createFilterOptions() {
    const selectionOptions = [];
    const options = this.getReferenceValueForCategory();

    options.forEach((item) => {
      selectionOptions.push({
        key: item.key,
        value: item.text,
      });
    });

    const filterEntries = {
      contentFilters: [
        {
          // eslint-disable-next-line no-nested-ternary
          filterType: "PiP Mappings",
          entries: [...selectionOptions],
        },
      ],
    };
    return filterEntries;
  }

  // eslint-disable-next-line space-before-function-paren
  reduceByApplyingFilter() {
    const { filterSelections } = this.state;
    let items;

    if (
      filterSelections.length === 0 ||
      filterSelections[0].items.length === 0
    ) {
      items = this.props.pips ? this.props.pips : [];
    } else {
      const compareItemsExists = (categoryId) => filterSelections[0].items.some((ch) => ch.key === categoryId);
      // eslint-disable-next-line no-nested-ternary
      const itemList = this.props.pips;
      items = itemList.filter((item) => compareItemsExists(item.categoryId));
    }

    return items;
  }

  toggleTableDetails = () => {
    const isTableDetailsPanelOpen = !this.state.isTableDetailsPanelOpen;
    this.setState({ isTableDetailsPanelOpen });
  };

  hideDetailsPanelHandler = () => {
    const isTableDetailsPanelOpen = false;
    this.setState({ isTableDetailsPanelOpen });
  };

  reducePips = (pips, selectedPip) => {
    if (!pips || pips.length === 0) {
      return [];
    }

    /* eslint-disable no-param-reassign */
    // eslint-disable-next-line func-names
    const groupBy = function (xs, key) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    return groupBy(
      selectedPip.policyInformationPointAttributeValues,
      "displayRowOrder"
    );
  };

  deleteButtonClicked = async (id, delAttrValue) => {
    this.setState({ showDelDialog: true, displayOrderRow: id, delAttrValue });
  };

  deleteDataValue = async () => {
    const { selectedPip, displayOrderRow } = this.state;

    await this.props.deletePipValues(selectedPip.id, displayOrderRow);
    this.setState({ isSaved: true, showDelDialog: false });

    this.getPipList(selectedPip.id);
  };

  dismissDeleteDataDialog = () => {
    this.setState({ showDelDialog: false });
  };

  listMappingTableLP = () => {
    const mappingList = this.reduceByApplyingFilter();

    return mappingList.map((tM, index) => {
      const isSelected = this.state.selectedPip
        ? this.state.selectedPip.id === tM.id
        : 0;
      return (
        <ListItem
          key={`index-${tM.id}`}
          button
          dense
          selected={isSelected}
          onClick={(event) => this.handleListItemClickLP(event, index, tM)}
          className={"tmItem"}
        >
          <ListItemText
            key={`index-${tM.code}text`}
            primary={
              <Typography type="body2" className={"tmItemText"}>
                {tM.name}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
      );
    });
  };

  handleChange = (event) => {
    this.setState({ pageView: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (value) => {
    this.setState({ open: false });
  };

  handleListItemClickLP = async (event, index, tM) => {
    this.cleanUpValues();

    this.setState({
      selectedPip: tM,
      selectedIndex: index,
      isAddFreeText: false,
    });

    this.processPiPAttibuteNameReferenceData(tM);
    this.processPiPAttibutePositions(tM);
  };

  showAddValue = () => {
    const showAddValueSection = !this.state.showAddValueSection;
    this.setState({ showAddValueSection });
  };

  getSelectedKey = (attrId) => {
    const { selectedDropdownValues } = this.state;

    const selectedControlIndex = selectedDropdownValues.findIndex(
      (item) => item.key === attrId
    );

    if (selectedControlIndex >= 0) {
      return selectedDropdownValues[selectedControlIndex].value;
    }

    return "";
  };

  changeInputHandler = (e, i, control) => {
    const { selectedDropdownValues } = this.state;
    let isAbleToAddNewValueToDB = true;

    const selectedControlIndex = selectedDropdownValues.findIndex(
      (item) => item.key === control.id
    );

    if (selectedControlIndex >= 0) {
      selectedDropdownValues[selectedControlIndex].value = i.key;
    }

    const foundEmptyOptionsSelected = selectedDropdownValues.some(
      (item) => item.value === ""
    );
    if (foundEmptyOptionsSelected) isAbleToAddNewValueToDB = false;

    const newPipAttrValuesDup = selectedDropdownValues.map(
      (item, attrIndex) => ({
        position: attrIndex + 1,
        attributeValue: item.value,
      })
    );

    this.setState({
      disableAddValueButton: !isAbleToAddNewValueToDB,
      newPipAttrValues: newPipAttrValuesDup,
      selectedDropdownValues,
    });
  };

  resetDropDown = () => {
    this.cleanUpValues();
  };

  addNewValues = async () => {
    const { newPipAttrValues, selectedPip } = this.state;

    await this.props.addPipAttributes(selectedPip.id, {
      pipAttributeValue: newPipAttrValues,
    });

    this.cleanUpValues();
    this.setState({ isSaved: true });
    this.getPipList(selectedPip.id);
  };

  cleanUpValues = () => {
    this.setState({
      newPipAttrValues: [],
      selectedDropdownValues: this.resetSelectedDropDownValues(),
      disableAddValueButton: true,
      newValueControlsCount: 0,
    });
  };

  resetSelectedDropDownValues = () => {
    const newSelectedDropDownValues = this.state.selectedDropdownValues;
    newSelectedDropDownValues.forEach((item, index) => {
      newSelectedDropDownValues[index].value = "";
    });
    return newSelectedDropDownValues;
  };

  closeDialog = () => {
    this.setState({ isSaved: false });
  };

  getCategoryValue = (categoryId) => {
    const filterRef = this.props.refdata.filter((ref) => ref.id === categoryId);

    if (filterRef && filterRef.length > 0) return filterRef[0].value.replaceAll("_", " ");

    return "";
  };

  // eslint-disable-next-line space-before-function-paren
  render() {
    const {
      selectedPip,
      showAddValueSection,
      isTableDetailsPanelOpen,
      pipAttrNameReferences,
      disableAddValueButton,
      isSaved,
      reducedPips,
      showDelDialog,
      delAttrValue,
      filterOptionsKey,
      filterSelections,
      categories,
    } = this.state;

    const { filterType, subTitle } = this.state.filterLiterals;

    isReadOnly = this.context.isReadOnly;

    // eslint-disable-next-line global-require
    const OliLogo =
      this.props.result === "SUCCESS"
        ? // eslint-disable-next-line global-require
          require("../../../images/oli-success.svg")
        : // eslint-disable-next-line operator-linebreak
          // eslint-disable-next-line global-require
          require("../../../images/oli-fail.svg");

    return (
      <Box display="flex">
        <Box>
          <Card variant="outlined" className={`${"listCard"} ${"listTable"}`}>
            <CardContent>
              <Typography variant="subtitle1" className={"tbllistHeader"}>
                PIP Table Type
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{subTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filterv2
                    key={filterOptionsKey}
                    filterType={filterType}
                    filterOptions={this.createFilterOptions()}
                    applyFilterSelectionsHandler={
                      this.applyFilterSelectionsHandler
                    }
                    initialSelections={filterSelections}
                    showSelectedItems={true}
                  ></Filterv2>
                </AccordionDetails>
              </Accordion>
              <div style={{ marginTop: "1rem" }}>
                {selectedPip ? this.listMappingTableLP() : ""}
              </div>
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={4} style={{ maxWidth: "1200px" }}>
          <Card variant="outlined">
            <CardContent>
              <div className={"headerSection"}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <div className={"tmHeader"}>PIP Mapping Builder</div>
                  </Box>
                </Box>
                <hr className={"sepLine"} />
              </div>
              <div className={`${"boxed"} ${"descBottom"}`}>
                <Box display="flex" className={"titleSection"}>
                  <Box flexGrow={1}>
                    <div>
                      <div className={"titleHeaderRow"}>selectedPip.name</div>
                      <div className={"titleRow"}>
                        Last Updated: 19 Aug 2021
                      </div>
                    </div>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                    }}
                  >
                    {!isReadOnly ? (
                      <IconButton
                        iconProps={{
                          iconName: "Settings",
                          style: { fontSize: 23 },
                        }}
                        styles={{
                          root: { color: "white" },
                          rootHovered: {
                            backgroundColor: "#0078d4",
                            color: "white",
                          },
                        }}
                        onClick={this.toggleTableDetails}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <div className="builderDescriptionSection">
                  <div className={"descAlign"}>
                    <div className="col-sm-4">
                      <div className={"descHeader"}>PIP TABLE DESCRIPTION</div>
                      <div className={"upperSpace"}>
                        <div
                          className={"editControlSectionHeader"}
                          dangerouslySetInnerHTML={{
                            __html: selectedPip ? selectedPip.description : "",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="mappingDescDivider">
                    <div className="col-sm-4">
                      <div className={"tableSummaryHeader"}>
                        PIP TABLE SUMMARY
                      </div>
                      <div className={"mappingCategory"}>MAPPING CATEGORY</div>
                      <div className={"upperSpace"}>
                        {selectedPip
                          ? this.getCategoryValue(selectedPip.categoryId)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    style={{
                      minHeight: "inherit",
                      color: "black",
                      width: "25px",
                    }}
                  />
                </div>
              </div>

              <Box>
                <Card variant="outlined">
                  <CardContent>
                    <div className={"mappingListSubHeader"}>
                      PIP Table Values
                    </div>
                    <TableContainer component={Paper}>
                      <ThemeProvider theme={theme}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {selectedPip
                                ? selectedPip.policyInformationPointDefinitionAttributes.map(
                                    (attr, attrIndex) => (
                                      <HeaderTableCell
                                        align="left"
                                        key={`${attrIndex}`}
                                      >
                                        {attr.attributeName}
                                      </HeaderTableCell>
                                    )
                                  )
                                : ""}
                              {!isReadOnly ? (
                                <HeaderTableCell align="left">
                                  Remove
                                </HeaderTableCell>
                              ) : null}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedPip
                              ? Object.keys(reducedPips).map((key) => (
                                  <TableRow key={key}>
                                    {reducedPips[key].map((cell) => (
                                      <BodyTableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                        key={cell.id}
                                      >
                                        {this.getReferenceValueForAttrValue(
                                          cell.attributeValue
                                        )}
                                      </BodyTableCell>
                                    ))}

                                    {!isReadOnly ? (
                                      <BodyTableCell align="left">
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            this.deleteButtonClicked(
                                              key,
                                              reducedPips[key][0].attributeValue
                                            );
                                          }}
                                          styles={{
                                            root: {
                                              color: "#555",
                                              height: 24,
                                              width: 24,
                                            },
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </BodyTableCell>
                                    ) : null}
                                  </TableRow>
                                ))
                              : ""}
                          </TableBody>
                        </Table>
                      </ThemeProvider>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Box>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Box>
                {!isReadOnly ? (
                  <div className={"addNewMappingSection"}>
                    <div className={"mappingListSubHeader"}>
                      Add New PIP Table Values
                    </div>
                    <Grid item xs={12}>
                      <button
                        className={"whiteBtn"}
                        onClick={() => {
                          this.showAddValue();
                        }}
                      >
                        {!showAddValueSection ? (
                          <AddCircleOutlineIcon
                            className={"addValueLinkIcon"}
                          ></AddCircleOutlineIcon>
                        ) : (
                          <RemoveCircleOutlineIcon
                            className={"addValueLinkIcon"}
                          ></RemoveCircleOutlineIcon>
                        )}
                        Add New Value
                      </button>
                    </Grid>
                    {showAddValueSection ? (
                      <div>
                        <>
                          <div className={"addValuesSection"}>
                            {selectedPip.policyInformationPointDefinitionAttributes.map(
                              (attr, attrIndex) => (
                                <div
                                  className={"addValueControlDiv"}
                                  key={`div-${attrIndex}`}
                                >
                                  <Dropdown
                                    id={`${attrIndex}`}
                                    placeholder={`Select ${attr.attributeName}`}
                                    label={`Select ${attr.attributeName}`}
                                    options={pipAttrNameReferences[attrIndex]}
                                    required
                                    selectedKey={this.getSelectedKey(attr.id)}
                                    styles={dropdownStyles}
                                    onRenderCaretDown={onRenderCaretDown}
                                    onChange={(e, i) => this.changeInputHandler(e, i, attr)
                                    }
                                  />
                                </div>
                              )
                            )}
                          </div>
                          <hr className={"sepLine"} />
                          <Stack
                            horizontal
                            horizontalAlign="space-between"
                            tokens={stackTokens}
                            className={"actionButtons"}
                          >
                            <StackItem align="start">
                              <div>&nbsp;</div>
                            </StackItem>
                            <StackItem>&nbsp;</StackItem>
                            <StackItem align="end">
                              <DefaultButton
                                text="Cancel"
                                onClick={this.resetDropDown}
                                className={"cancelButton"}
                              />
                              <PrimaryButton
                                text="Add Value"
                                onClick={() => this.addNewValues()}
                                disabled={disableAddValueButton}
                                className={"addValueButton"}
                              />
                            </StackItem>
                          </Stack>
                        </>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        {isSaved ? (
          <SuccessfulMessage
            dialogState={false}
            errorTitle={this.props.result}
            errorContentArray={[this.props.message, "Click Okay to continue."]}
            hidden={!isSaved}
            closeDialog={this.closeDialog}
            cancelDialog={this.closeDialog}
            OliLogo={OliLogo}
          />
        ) : (
          ""
        )}
        {isTableDetailsPanelOpen ? (
          <PiPDetails
            isTableDetailsPanelOpen={isTableDetailsPanelOpen}
            hideDetailsPanelHandler={this.hideDetailsPanelHandler}
            selectedPip={selectedPip}
            isReadOnly={isReadOnly}
            dropDownOptions={categories}
          />
        ) : (
          ""
        )}
        <DeleteConfirmDialog
          isOpen={showDelDialog}
          onDissmissHandler={this.dismissDeleteDataDialog}
          onDeleteHandler={this.deleteDataValue}
          messageTitle={"Delete PiP Attribute Data Value"}
          dataValue={this.getReferenceValueForAttrValue(delAttrValue)}
        />
      </Box>
    );
  }
}

const HeaderTableCell = withStyles((theme) => ({
  head: {
    fontSize: 14,
    fontWeigtht: 600,
    color: "#323130",
    borderStyle: "none",
  },
  body: {
    fontSize: 14,
    borderStyle: "none",
  },
}))(TableCell);

const BodyTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    borderStyle: "none",
    color: "#323130",
  },
}))(TableCell);

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 8px",
      },
    },
  },
});

// eslint-disable-next-line space-before-function-paren
function mapFormData(pipRow) {
  if (!pipRow) {
    return null;
  }
  const { subjectMatter, noOfColumns } = pipRow;
  let attributeNames = [];
  for (let i = 0; i < noOfColumns; i += 1) {
    attributeNames[i] = "Subject Matter";
  }
  attributeNames = [
    ...attributeNames,
    ...pipRow.policyInformationPointDefinitionAttributes.map(
      (at) => at.attributeName
    ),
  ];
  const attributeValues = pipRow.policyInformationPointAttributeValues.map(
    (av) => av.attributeValue
  );
  const attributeValueIds = pipRow.policyInformationPointAttributeValues.map(
    (av) => av.id
  );
  return {
    subjectMatter,
    noOfColumns,
    attributeNames,
    attributeValueIds,
    attributeValues,
  };
}

const mapStateToProps = (state, ownProps) => ({
  pips: state.pips.pips ? Object.values(state.pips.pips) : [],
  refdata: state.refData.referenceData,
  pipRow: state.pips.pipRow,
  formData: mapFormData(state.pips.pipRow),
  enableReinitialize: true,
  backendResult: state.pips.backendResult,
  message: state.ui.message,
  result: state.ui.result,
});

const connected = connect(mapStateToProps, {
  fetchAllPIPs,
  deletePipValues,
  fetchReferenceData,
  fetchReferenceDataTypes,
  referenceDataTypeSelected,
  addPipAttributes,
  fetchAllTableMappings,
})(AuthProvider(PipTableMappingBuilder));

export default connected;
