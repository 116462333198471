export const PRR_Criteria_Category = [
  { key: "GMR", text: "GMR" },
  { key: "RAF", text: "RAF" },
];

export const OPTION_VALUES = [
  { key: "UNKNOWN", text: "UNKNOWN" },
  { key: "GREEN", text: "GREEN" },
  { key: "AMBER", text: "AMBER" },
  { key: "RED", text: "RED" },
  { key: "ALIGNED", text: "ALIGNED" },
  { key: "NOTALIGNED", text: "NOTALIGNED" },
];