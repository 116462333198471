import {
  initializeIcons
} from "@fluentui/react";
import {
 Box, Card, CardContent, Grid
} from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { BuilderNameBanner, DescriptionRichContent, DescriptionHeader } from "../../../../components/shared/builder";
import GeneralPolicySchemeConfigWrapper from "../../../../components/shared/builder/policySchemeConfig/GeneralPolicySchemeConfigWrapper";
import { fetchSingleGuidanceFragmentPolicyConfig } from '../../../../state/actions/GuidanceFragmentActions';
import { ActionLiteralType } from '../../../../constants/ActionTypeObj';
import GuidanceFragmentDetail from '../GuidanceFragmentDetail';
import getReadOnlyFlagPerEnv from  '../../../../util/EnvUtil';

const isReadOnly = getReadOnlyFlagPerEnv();

const GuidanceFragmentPolicyContent = ({ classes }) => {
  const guidanceFragmentCode = "PDP.RULE.GUIDANCE_FRAGMENT_CODES";
  const params = useParams();
  const guidanceFragmentType = useSelector(
    (state) => state.guidanceFragmentBuilderData.guidanceFragmentType
  );
  const policySchemeConfigs = useSelector(
    (state) => state.guidanceFragmentBuilderData.policySchemeConfigs
  );
  const linkedPolicyLibraries = useSelector(
    (state) => state.guidanceFragmentBuilderData.linkedPolicyLibraries
  );
  const linkedResourceTypes = useSelector(
    (state) => state.guidanceFragmentBuilderData.linkedResourceTypes
  );

  const [showGuidanceFragmentDetail, setShowGuidanceFragmentDetail] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleGuidanceFragmentPolicyConfig(params.guidanceFragmentId));
  }, [dispatch, params.guidanceFragmentId]);

  initializeIcons();

  const openGuidanceFragmentDetailsDialog = () => {
    setShowGuidanceFragmentDetail(true);
  };

  const handleDetailClose = () => {
    setShowGuidanceFragmentDetail(false);
    dispatch(fetchSingleGuidanceFragmentPolicyConfig(params.guidanceFragmentId));
  };

  return (
    guidanceFragmentType && (
      <Box flexGrow={4}>
        <Card variant="outlined">
          <CardContent className={classes.cardContentBody}>
            <BuilderNameBanner builderName="Guidance Builder"/>
            <hr className={classes.dividerLine} />
            <div className={classes.headerSection}>
              <DescriptionHeader
                itemName={`${guidanceFragmentType.code} - ${guidanceFragmentType.name}`}
                toggleEditDesciptionDetailsDialogHandler={() => openGuidanceFragmentDetailsDialog()}
                lastUpdatedDate={"19 NOV 2021"}
              />
              <DescriptionRichContent descriptionText={guidanceFragmentType.text} descriptionTitle={"Guidance Text"} status={guidanceFragmentType.status}
                linkedPolicyLibraries={linkedPolicyLibraries} linkedResourceTypes={linkedResourceTypes}/>
            </div>
            <div className={classes.policySchemeConfigSection}>
              <Box>
                <Grid item xs={12}>
                  <button className={classes.whiteBtn} ><AddCircleOutlineIcon className={classes.addNewPDPIcon}></AddCircleOutlineIcon>Add New PDP</button>
                </Grid>
              </Box>
              {policySchemeConfigs?.map((policySchemeConfig, index) => (
                <GeneralPolicySchemeConfigWrapper policySchemeConfig={policySchemeConfig} key={index} resourceCode={guidanceFragmentCode}
                  refreshCompositeRules={() => dispatch(fetchSingleGuidanceFragmentPolicyConfig(params.guidanceFragmentId))}
                  literalType={ActionLiteralType.GUIDANCEFRAGMENT}
                />
              ))}
            </div>
            <div>
            </div>
          </CardContent>
        </Card>
        {showGuidanceFragmentDetail
        && <GuidanceFragmentDetail guidance={guidanceFragmentType} open={showGuidanceFragmentDetail}
           onClose={handleDetailClose}
           isReadOnly={isReadOnly}
           hasArchiveFunctionality={true}
          />
        }
      </Box>
    )
  );
};

const styles = () => ({
  headerSection: {
    border: '2px solid lightgrey',
    minHeight: '230px',
    borderRadius: "2px",
  },
  dividerLine: {
    borderBottom: "solid 1px #bdbdbd",
    borderTop: "solid 0px #bdbdbd",
    borderLeft: "solid 0px #bdbdbd",
    borderRight: "solid 0px #bdbdbd",
    height: "0px",
    marginBottom: "21px",
  },
  policySchemeConfigSection: {
    padding: "18px 0px",
  },
  refDataTypeValuesSectionTitle: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#323130",
    paddingBottom: "16px",
  },
  cardContentBody: {
    padding: "16px 32px",
  },
  addNewPDPIcon: {
    border: '0',
    backgroundColor: null,
    color: '#979797',
    fontSize: '12px !important',
    cursor: 'pointer',
    float: 'left',
    marginRight: '8px'
  },
  whiteBtn: {
    backgroundColor: 'transparent',
    border: '0px',
    padding: '0px 0 13px 0',
    color: '#323130',
    paddingRight: '1rem'
  },
});

export default withStyles(styles)(GuidanceFragmentPolicyContent);
