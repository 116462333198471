import {
  FETCH_HELPTEXT_MASTERS
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  helpTextMasters: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_HELPTEXT_MASTERS:
      return { ...state, helpTextMasters: action.payload };

    default:
      return state;
  }
};
