import api from '../../api/IntelligentAgentServiceApi';
import {
  FETCH_CHECKPOINT_MASTERS,
  UI_SHOW_MESSAGE_ERROR,
  UI_SHOW_MESSAGE_SUCCESS
} from '../../constants/ActionTypes';
import { signOut } from '../../components/auth/AuthUtils';

export const fetchCheckPointMasters = () => async (dispatch) => {
  try {
    const response = await api.get('/admin/checkpoint-masters');
    dispatch({ type: FETCH_CHECKPOINT_MASTERS, payload: response.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    }
  }
};

export const updateCheckPointMasterDesc = (code, description) => async (dispatch) => {
  try {
    const apiObject = { value: description };

    const url = `/admin/checkpoint-masters/${code}/description`;
    await api.put(url, apiObject);

    dispatch({ type: UI_SHOW_MESSAGE_SUCCESS, message: 'Checkpoint master description updated successfully' });
    dispatch(fetchCheckPointMasters());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(signOut());
    } else {
      dispatch({ type: UI_SHOW_MESSAGE_ERROR, message: 'Checkpoint master description updated failure' });
    }
  }
};
