import React from 'react'
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';

import ViewChangeSelector from './ViewChangeSelector';

import NotificationMasterSelectionPolicy from './type/NotificationMasterSelectionPolicy';
import NotificationRecipientResolutionPolicy from './type/NotificationRecipientResolutionPolicy';
import AuthProvider from '../../components/auth/AuthProvider';


const viewTypes = [
  { text: 'Notification Master Selection Policy', key: 'NotificationMasterSelectionPolicy', policy: NotificationMasterSelectionPolicy },
  { text: 'Notification Recipient Resolution Policy', key: 'NotificationRecipientResolutionPolicy', policy: NotificationRecipientResolutionPolicy },
];

class ValidationConsole extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
          filterByViewTypePos: 0,
        }
    }

    componentDidMount() {
    }

    render() {
        var currentLabel = " - " + viewTypes[this.state.filterByViewTypePos].text;
        const policy = viewTypes[this.state.filterByViewTypePos];
        return (
            <>
              <Card variant='outlined' className={this.props.classes.rootBackground}>
                  <div className={this.props.classes.cardHeaderSection}>
                    <div className={this.props.classes.headersideLeft}>
                      <CardHeader title={<Typography className={this.props.classes.cardHeader}>Validation {currentLabel}</Typography>}/>
                    </div>
                    <div className={this.props.classes.headersideRight}>
                      <ViewChangeSelector label="Change View" menuItems={this.menuProps}
                          initialSelectedIndex={this._determineSelectedViewIndex} />
                    </div>
                  </div>
              </Card>
              <policy.policy />
            </>
        );
    }

    
    menuPropsItems = viewTypes.map((item, pos) => {
      return {
        key: item.key,
        text: item.text,
        onClick: () => this._changeViewAction(pos),
      }
    });

    // The menu props are used for the Task Row Icon Button
    menuProps = {
      items: this.menuPropsItems
    };
  
    _determineSelectedViewIndex = () => {
      return this.state.filterByViewTypePos;
    }
  
    _changeViewAction = (viewPos) => {
      // alert(viewPos);
      this.setState((prevState) => ({ ...prevState, filterByViewTypePos: viewPos }));
    }

    _renderViewComponent = () => {
      // alert("todo");
      return viewTypes[this.state.filterByViewTypePos].component;
    }
    

}


const styles = (theme) => ({
    rootBackground: {
        height: '100%',
        backgroundColor: 'white',
    },
    root: {
        flexGrow: 1
    },
    cardHeaderSection: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
    cardHeader: {
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: {variant: 'h1'},
        title: {
            titleTypographyProps: {variant: 'h1'},
            fontSize: '12px'
        },
        left: '0px',
        width: '75%',
        padding: '0 0 0 50px',
    },
    headersideLeft: {
      flex: '75%',
    },
    headersideRight: {
        flex: '25%',
        padding: '25px 0 0 0',        
        position: 'relative',
    },
    cardContentBox: {
        backgroundColor: '#f4f4f4',
        padding: '0 10px 0 30px',
        margin: '0 10px 0 60px',
    },
    cardContent: {
        display: 'flex',
        flexFlow: 'row wrap',
        fontSize: '27px',
        fontFamily: 'PharmaCond',
        padding: '0 0 0 0',
        color: '#323130',
        fontWeight: '700',
        titleTypographyProps: {variant: 'h1'},
        title: {
            titleTypographyProps: {variant: 'h1'},
            fontSize: '12px'
        },
    },
});


ValidationConsole.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const connected = connect(mapStateToProps, {})(AuthProvider(ValidationConsole));

export default withStyles(styles)(connected);  
