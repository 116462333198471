import {
  DELETE_ITEM,
  SET_ITEM,
  GET_ALL_ITEM
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  selectedItems: [],
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_ITEM:
      return { selectedItems: action.payload };
    case SET_ITEM:
      return { selectedItems: action.payload };
    case GET_ALL_ITEM:
      return INITIAL_STATE;
    default:
      return state;
  }
};
