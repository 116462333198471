import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import AuthProvider from '../../components/auth/AuthProvider';
import { fetchCheckPointMasters } from '../../state/actions/CheckPointMasterActions';
import CheckPointMasterGrid from './CheckPointMasterGrid';
import '../../components/style.css';

class CheckPointMasterConsole extends React.Component {

  componentDidMount () {
    this.props.fetchCheckPointMasters();
    document.title = 'Checkpoint Masters';
  }

  render () {

    return (
      <div>

        <Card variant='outlined' className={this.props.classes.rootBackground}>
          <CardHeader className={this.props.classes.cardHeaderSection}
            title={<Typography className={this.props.classes.cardHeader}>
              Checkpoint Masters
            </Typography>} />
          <CardContent>
            <Box mt={1} container="true" display="flex" flexDirection="row" justify-content="space-around"
              flexWrap="wrap" alignContent="center" width={1} styles={{ alignItem: "center" }}>
              <CheckPointMasterGrid checkpointData={this.props.checkPointData} />
            </Box>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styles = (theme) => ({
rootBackground:{
       height: '100%',
        backgroundColor:'#f3f2f1'
    },
  root: {
    // maxWidth: 500,
    flexGrow: 1
  },
    cardHeaderSection:{
       padding: '16px 0 0 23px'
     },
  cardHeader: {
    fontSize: '27px',
    fontFamily: 'PharmaCond',
    padding: '16px',
    color: '#323130',
    fontWeight: '700',
    titleTypographyProps: { variant: 'h1' },
    title: {
      titleTypographyProps: { variant: 'h1' },
      fontSize: '12px'
    },

  },
  breadcrumb: {
    fontSize: '15px'
  }
});

CheckPointMasterConsole.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
//console.log(state.checkpointMasterData.checkpointMasters)
return  {
            checkPointData: state.checkpointMasterData.checkpointMasters
       }

   // error: state.checkpointMasterData.eor,
   // isFetching: state.ui.isFetching

};

const connected = connect(mapStateToProps, {
  fetchCheckPointMasters
 })(AuthProvider(CheckPointMasterConsole));

export default withStyles(styles)(connected);

